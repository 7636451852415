(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.k.i2 === region.h.i2)
	{
		return 'on line ' + region.k.i2;
	}
	return 'on lines ' + region.k.i2 + ' through ' + region.h.i2;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lp,
		impl.nI,
		impl.nw,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		lP: func(record.lP),
		ns: record.ns,
		m2: record.m2
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.lP;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.ns;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.m2) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lp,
		impl.nI,
		impl.nw,
		function(sendToApp, initialModel) {
			var view = impl.nK;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.lp,
		impl.nI,
		impl.nw,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.hi && impl.hi(sendToApp)
			var view = impl.nK;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.getElementById("elmIsolationContainer");
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.fd);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.hE) && (_VirtualDom_doc.title = title = doc.hE);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.mQ;
	var onUrlRequest = impl.mR;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		hi: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.ju === next.ju
							&& curr.iL === next.iL
							&& curr.jp.a === next.jp.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		lp: function(flags)
		{
			return A3(impl.lp, flags, _Browser_getUrl(), key);
		},
		nK: impl.nK,
		nI: impl.nI,
		nw: impl.nw
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { lj: 'hidden', kB: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { lj: 'mozHidden', kB: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { lj: 'msHidden', kB: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { lj: 'webkitHidden', kB: 'webkitvisibilitychange' }
		: { lj: 'hidden', kB: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		jG: _Browser_getScene(),
		j9: {
			kd: _Browser_window.pageXOffset,
			ke: _Browser_window.pageYOffset,
			di: _Browser_doc.documentElement.clientWidth,
			iJ: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		di: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		iJ: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			jG: {
				di: node.scrollWidth,
				iJ: node.scrollHeight
			},
			j9: {
				kd: node.scrollLeft,
				ke: node.scrollTop,
				di: node.clientWidth,
				iJ: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			jG: _Browser_getScene(),
			j9: {
				kd: x,
				ke: y,
				di: _Browser_doc.documentElement.clientWidth,
				iJ: _Browser_doc.documentElement.clientHeight
			},
			kW: {
				kd: x + rect.left,
				ke: y + rect.top,
				di: rect.width,
				iJ: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.k_.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.k_.b, xhr)); });
		$elm$core$Maybe$isJust(request.nG) && _Http_track(router, xhr, request.nG.a);

		try {
			xhr.open(request.d8, request.nJ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.nJ));
		}

		_Http_configureRequest(xhr, request);

		request.fd.a && xhr.setRequestHeader('Content-Type', request.fd.a);
		xhr.send(request.fd.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.li; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.nC.a || 0;
	xhr.responseType = request.k_.d;
	xhr.withCredentials = request.kp;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		nJ: xhr.responseURL,
		nq: xhr.status,
		nr: xhr.statusText,
		li: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			nn: event.loaded,
			ax: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			m8: event.loaded,
			ax: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});


// CREATE

var _Regex_never = /.^/;

var _Regex_fromStringWith = F2(function(options, string)
{
	var flags = 'g';
	if (options.i5) { flags += 'm'; }
	if (options.h$) { flags += 'i'; }

	try
	{
		return $elm$core$Maybe$Just(new RegExp(string, flags));
	}
	catch(error)
	{
		return $elm$core$Maybe$Nothing;
	}
});


// USE

var _Regex_contains = F2(function(re, string)
{
	return string.match(re) !== null;
});


var _Regex_findAtMost = F3(function(n, re, str)
{
	var out = [];
	var number = 0;
	var string = str;
	var lastIndex = re.lastIndex;
	var prevLastIndex = -1;
	var result;
	while (number++ < n && (result = re.exec(string)))
	{
		if (prevLastIndex == re.lastIndex) break;
		var i = result.length - 1;
		var subs = new Array(i);
		while (i > 0)
		{
			var submatch = result[i];
			subs[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		out.push(A4($elm$regex$Regex$Match, result[0], result.index, number, _List_fromArray(subs)));
		prevLastIndex = re.lastIndex;
	}
	re.lastIndex = lastIndex;
	return _List_fromArray(out);
});


var _Regex_replaceAtMost = F4(function(n, re, replacer, string)
{
	var count = 0;
	function jsReplacer(match)
	{
		if (count++ >= n)
		{
			return match;
		}
		var i = arguments.length - 3;
		var submatches = new Array(i);
		while (i > 0)
		{
			var submatch = arguments[i];
			submatches[--i] = submatch
				? $elm$core$Maybe$Just(submatch)
				: $elm$core$Maybe$Nothing;
		}
		return replacer(A4($elm$regex$Regex$Match, match, arguments[arguments.length - 2], count, _List_fromArray(submatches)));
	}
	return string.replace(re, jsReplacer);
});

var _Regex_splitAtMost = F3(function(n, re, str)
{
	var string = str;
	var out = [];
	var start = re.lastIndex;
	var restoreLastIndex = re.lastIndex;
	while (n--)
	{
		var result = re.exec(string);
		if (!result) break;
		out.push(string.slice(start, result.index));
		start = re.lastIndex;
	}
	out.push(string.slice(start));
	re.lastIndex = restoreLastIndex;
	return _List_fromArray(out);
});

var _Regex_infinity = Infinity;


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}



// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});



function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}


// BYTES

function _Bytes_width(bytes)
{
	return bytes.byteLength;
}

var _Bytes_getHostEndianness = F2(function(le, be)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(new Uint8Array(new Uint32Array([1]))[0] === 1 ? le : be));
	});
});


// ENCODERS

function _Bytes_encode(encoder)
{
	var mutableBytes = new DataView(new ArrayBuffer($elm$bytes$Bytes$Encode$getWidth(encoder)));
	$elm$bytes$Bytes$Encode$write(encoder)(mutableBytes)(0);
	return mutableBytes;
}


// SIGNED INTEGERS

var _Bytes_write_i8  = F3(function(mb, i, n) { mb.setInt8(i, n); return i + 1; });
var _Bytes_write_i16 = F4(function(mb, i, n, isLE) { mb.setInt16(i, n, isLE); return i + 2; });
var _Bytes_write_i32 = F4(function(mb, i, n, isLE) { mb.setInt32(i, n, isLE); return i + 4; });


// UNSIGNED INTEGERS

var _Bytes_write_u8  = F3(function(mb, i, n) { mb.setUint8(i, n); return i + 1 ;});
var _Bytes_write_u16 = F4(function(mb, i, n, isLE) { mb.setUint16(i, n, isLE); return i + 2; });
var _Bytes_write_u32 = F4(function(mb, i, n, isLE) { mb.setUint32(i, n, isLE); return i + 4; });


// FLOATS

var _Bytes_write_f32 = F4(function(mb, i, n, isLE) { mb.setFloat32(i, n, isLE); return i + 4; });
var _Bytes_write_f64 = F4(function(mb, i, n, isLE) { mb.setFloat64(i, n, isLE); return i + 8; });


// BYTES

var _Bytes_write_bytes = F3(function(mb, offset, bytes)
{
	for (var i = 0, len = bytes.byteLength, limit = len - 4; i <= limit; i += 4)
	{
		mb.setUint32(offset + i, bytes.getUint32(i));
	}
	for (; i < len; i++)
	{
		mb.setUint8(offset + i, bytes.getUint8(i));
	}
	return offset + len;
});


// STRINGS

function _Bytes_getStringWidth(string)
{
	for (var width = 0, i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		width +=
			(code < 0x80) ? 1 :
			(code < 0x800) ? 2 :
			(code < 0xD800 || 0xDBFF < code) ? 3 : (i++, 4);
	}
	return width;
}

var _Bytes_write_string = F3(function(mb, offset, string)
{
	for (var i = 0; i < string.length; i++)
	{
		var code = string.charCodeAt(i);
		offset +=
			(code < 0x80)
				? (mb.setUint8(offset, code)
				, 1
				)
				:
			(code < 0x800)
				? (mb.setUint16(offset, 0xC080 /* 0b1100000010000000 */
					| (code >>> 6 & 0x1F /* 0b00011111 */) << 8
					| code & 0x3F /* 0b00111111 */)
				, 2
				)
				:
			(code < 0xD800 || 0xDBFF < code)
				? (mb.setUint16(offset, 0xE080 /* 0b1110000010000000 */
					| (code >>> 12 & 0xF /* 0b00001111 */) << 8
					| code >>> 6 & 0x3F /* 0b00111111 */)
				, mb.setUint8(offset + 2, 0x80 /* 0b10000000 */
					| code & 0x3F /* 0b00111111 */)
				, 3
				)
				:
			(code = (code - 0xD800) * 0x400 + string.charCodeAt(++i) - 0xDC00 + 0x10000
			, mb.setUint32(offset, 0xF0808080 /* 0b11110000100000001000000010000000 */
				| (code >>> 18 & 0x7 /* 0b00000111 */) << 24
				| (code >>> 12 & 0x3F /* 0b00111111 */) << 16
				| (code >>> 6 & 0x3F /* 0b00111111 */) << 8
				| code & 0x3F /* 0b00111111 */)
			, 4
			);
	}
	return offset;
});


// DECODER

var _Bytes_decode = F2(function(decoder, bytes)
{
	try {
		return $elm$core$Maybe$Just(A2(decoder, bytes, 0).b);
	} catch(e) {
		return $elm$core$Maybe$Nothing;
	}
});

var _Bytes_read_i8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getInt8(offset)); });
var _Bytes_read_i16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getInt16(offset, isLE)); });
var _Bytes_read_i32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getInt32(offset, isLE)); });
var _Bytes_read_u8  = F2(function(      bytes, offset) { return _Utils_Tuple2(offset + 1, bytes.getUint8(offset)); });
var _Bytes_read_u16 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 2, bytes.getUint16(offset, isLE)); });
var _Bytes_read_u32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getUint32(offset, isLE)); });
var _Bytes_read_f32 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 4, bytes.getFloat32(offset, isLE)); });
var _Bytes_read_f64 = F3(function(isLE, bytes, offset) { return _Utils_Tuple2(offset + 8, bytes.getFloat64(offset, isLE)); });

var _Bytes_read_bytes = F3(function(len, bytes, offset)
{
	return _Utils_Tuple2(offset + len, new DataView(bytes.buffer, bytes.byteOffset + offset, len));
});

var _Bytes_read_string = F3(function(len, bytes, offset)
{
	var string = '';
	var end = offset + len;
	for (; offset < end;)
	{
		var byte = bytes.getUint8(offset++);
		string +=
			(byte < 128)
				? String.fromCharCode(byte)
				:
			((byte & 0xE0 /* 0b11100000 */) === 0xC0 /* 0b11000000 */)
				? String.fromCharCode((byte & 0x1F /* 0b00011111 */) << 6 | bytes.getUint8(offset++) & 0x3F /* 0b00111111 */)
				:
			((byte & 0xF0 /* 0b11110000 */) === 0xE0 /* 0b11100000 */)
				? String.fromCharCode(
					(byte & 0xF /* 0b00001111 */) << 12
					| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
					| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
				)
				:
				(byte =
					((byte & 0x7 /* 0b00000111 */) << 18
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 12
						| (bytes.getUint8(offset++) & 0x3F /* 0b00111111 */) << 6
						| bytes.getUint8(offset++) & 0x3F /* 0b00111111 */
					) - 0x10000
				, String.fromCharCode(Math.floor(byte / 0x400) + 0xD800, byte % 0x400 + 0xDC00)
				);
	}
	return _Utils_Tuple2(offset, string);
});

var _Bytes_decodeFailure = F2(function() { throw 0; });
var $author$project$Msg$OnUrlChange = function (a) {
	return {$: 2, a: a};
};
var $author$project$Msg$OnUrlRequest = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.r) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.t);
		} else {
			var treeLen = builder.r * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.v) : builder.v;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.r);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.t) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.t);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{v: nodeList, r: (len / $elm$core$Array$branchFactor) | 0, t: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {k4: fragment, iL: host, mY: path, jp: port_, ju: protocol, jv: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $author$project$Msg$GotLogoutResult = function (a) {
	return {$: 7, a: a};
};
var $author$project$Msg$Login = function (a) {
	return {$: 5, a: a};
};
var $author$project$Msg$NotAuthorized = function (a) {
	return {$: 4, a: a};
};
var $author$project$Msg$NotFound = function (a) {
	return {$: 3, a: a};
};
var $author$project$Msg$OfferDetails = function (a) {
	return {$: 11, a: a};
};
var $author$project$Msg$Payment = function (a) {
	return {$: 9, a: a};
};
var $author$project$Msg$PolicyDetails = function (a) {
	return {$: 10, a: a};
};
var $author$project$Msg$Search = function (a) {
	return {$: 8, a: a};
};
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$browser$Browser$Navigation$back = F2(
	function (key, n) {
		return A2(_Browser_go, key, -n);
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.nq));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $author$project$Env$logoutPath = function (_v0) {
	var environment = _v0;
	return environment.cH;
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {jC: reqs, jZ: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.nG;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.jC));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.jZ)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					kp: r.kp,
					fd: r.fd,
					k_: A2(_Http_mapExpect, func, r.k_),
					li: r.li,
					d8: r.d8,
					nC: r.nC,
					nG: r.nG,
					nJ: r.nJ
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{kp: true, fd: r.fd, k_: r.k_, li: r.li, d8: r.d8, nC: r.nC, nG: r.nG, nJ: r.nJ}));
};
var $author$project$Utils$executeLogout = F3(
	function (env, urlExtractor, tagger) {
		return $elm$http$Http$riskyRequest(
			{
				fd: $elm$http$Http$emptyBody,
				k_: $elm$http$Http$expectWhatever(tagger),
				li: _List_Nil,
				d8: 'POST',
				nC: $elm$core$Maybe$Nothing,
				nG: $elm$core$Maybe$Nothing,
				nJ: _Utils_ap(
					urlExtractor(env),
					$author$project$Env$logoutPath(env))
			});
	});
var $author$project$Env$externalApiUrl = function (_v0) {
	var environment = _v0;
	return environment.cr;
};
var $author$project$Env$languageOverride = function (_v0) {
	var environment = _v0;
	return environment.cB;
};
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $elm$browser$Browser$Navigation$replaceUrl = _Browser_replaceUrl;
var $author$project$Page$Login$Msg$GotAuthCheckResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Env$apiUrl = function (_v0) {
	var environment = _v0;
	return environment.cb;
};
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Task$onError = _Scheduler_onError;
var $elm$core$Task$attempt = F2(
	function (resultToMessage, task) {
		return $elm$core$Task$command(
			A2(
				$elm$core$Task$onError,
				A2(
					$elm$core$Basics$composeL,
					A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
					$elm$core$Result$Err),
				A2(
					$elm$core$Task$andThen,
					A2(
						$elm$core$Basics$composeL,
						A2($elm$core$Basics$composeL, $elm$core$Task$succeed, resultToMessage),
						$elm$core$Result$Ok),
					task)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$HttpError = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Task$fail = _Scheduler_fail;
var $dillonkearns$elm_graphql$Graphql$Http$failTaskOnHttpSuccessWithErrors = function (successOrError) {
	if (!successOrError.$) {
		var value = successOrError.a;
		return $elm$core$Task$succeed(value);
	} else {
		var _v1 = successOrError.a;
		var possiblyParsedData = _v1.a;
		var graphqlErrorGraphqlErrorList = _v1.b;
		return $elm$core$Task$fail(
			A2($dillonkearns$elm_graphql$Graphql$Http$GraphqlError, possiblyParsedData, graphqlErrorGraphqlErrorList));
	}
};
var $elm$core$Task$mapError = F2(
	function (convert, task) {
		return A2(
			$elm$core$Task$onError,
			A2($elm$core$Basics$composeL, $elm$core$Task$fail, convert),
			task);
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadPayload = function (a) {
	return {$: 4, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$BadStatus = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$NetworkError = {$: 2};
var $dillonkearns$elm_graphql$Graphql$Http$Timeout = {$: 1};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$stringResolver = A2(_Http_expect, '', $elm$core$Basics$identity);
var $dillonkearns$elm_graphql$Graphql$Http$jsonResolver = function (decoder) {
	return $elm$http$Http$stringResolver(
		function (response) {
			switch (response.$) {
				case 0:
					var url = response.a;
					return $elm$core$Result$Err(
						$dillonkearns$elm_graphql$Graphql$Http$BadUrl(url));
				case 1:
					return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$Timeout);
				case 2:
					return $elm$core$Result$Err($dillonkearns$elm_graphql$Graphql$Http$NetworkError);
				case 3:
					var metadata = response.a;
					var body = response.b;
					return $elm$core$Result$Err(
						A2($dillonkearns$elm_graphql$Graphql$Http$BadStatus, metadata, body));
				default:
					var metadata = response.a;
					var body = response.b;
					var _v1 = A2($elm$json$Json$Decode$decodeString, decoder, body);
					if (!_v1.$) {
						var value = _v1.a;
						return $elm$core$Result$Ok(value);
					} else {
						var err = _v1.a;
						return $elm$core$Result$Err(
							$dillonkearns$elm_graphql$Graphql$Http$BadPayload(err));
					}
			}
		});
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Get = 0;
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$Post = 1;
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$Basics$ge = _Utils_ge;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength = 2000;
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $elm$core$Array$bitMask = 4294967295 >>> (32 - $elm$core$Array$shiftStep);
var $elm$core$Elm$JsArray$unsafeGet = _JsArray_unsafeGet;
var $elm$core$Array$getHelp = F3(
	function (shift, index, tree) {
		getHelp:
		while (true) {
			var pos = $elm$core$Array$bitMask & (index >>> shift);
			var _v0 = A2($elm$core$Elm$JsArray$unsafeGet, pos, tree);
			if (!_v0.$) {
				var subTree = _v0.a;
				var $temp$shift = shift - $elm$core$Array$shiftStep,
					$temp$index = index,
					$temp$tree = subTree;
				shift = $temp$shift;
				index = $temp$index;
				tree = $temp$tree;
				continue getHelp;
			} else {
				var values = _v0.a;
				return A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, values);
			}
		}
	});
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Array$tailIndex = function (len) {
	return (len >>> 5) << 5;
};
var $elm$core$Array$get = F2(
	function (index, _v0) {
		var len = _v0.a;
		var startShift = _v0.b;
		var tree = _v0.c;
		var tail = _v0.d;
		return ((index < 0) || (_Utils_cmp(index, len) > -1)) ? $elm$core$Maybe$Nothing : ((_Utils_cmp(
			index,
			$elm$core$Array$tailIndex(len)) > -1) ? $elm$core$Maybe$Just(
			A2($elm$core$Elm$JsArray$unsafeGet, $elm$core$Array$bitMask & index, tail)) : $elm$core$Maybe$Just(
			A3($elm$core$Array$getHelp, startShift, index, tree)));
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation = F2(
	function (replacements, _v0) {
		var match = _v0.cJ;
		var ordinalString = A2(
			$elm$core$Basics$composeL,
			$elm$core$String$dropLeft(1),
			$elm$core$String$dropRight(1))(match);
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$andThen,
				function (value) {
					return A2($elm$core$Array$get, value, replacements);
				},
				$elm$core$String$toInt(ordinalString)));
	});
var $elm$core$Array$fromListHelp = F3(
	function (list, nodeList, nodeListSize) {
		fromListHelp:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, list);
			var jsArray = _v0.a;
			var remainingItems = _v0.b;
			if (_Utils_cmp(
				$elm$core$Elm$JsArray$length(jsArray),
				$elm$core$Array$branchFactor) < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					true,
					{v: nodeList, r: nodeListSize, t: jsArray});
			} else {
				var $temp$list = remainingItems,
					$temp$nodeList = A2(
					$elm$core$List$cons,
					$elm$core$Array$Leaf(jsArray),
					nodeList),
					$temp$nodeListSize = nodeListSize + 1;
				list = $temp$list;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue fromListHelp;
			}
		}
	});
var $elm$core$Array$fromList = function (list) {
	if (!list.b) {
		return $elm$core$Array$empty;
	} else {
		return A3($elm$core$Array$fromListHelp, list, _List_Nil, 0);
	}
};
var $elm$regex$Regex$Match = F4(
	function (match, index, number, submatches) {
		return {d: index, cJ: match, l6: number, jY: submatches};
	});
var $elm$regex$Regex$fromStringWith = _Regex_fromStringWith;
var $elm$regex$Regex$fromString = function (string) {
	return A2(
		$elm$regex$Regex$fromStringWith,
		{h$: false, i5: false},
		string);
};
var $elm$regex$Regex$never = _Regex_never;
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('\\{\\d+\\}'));
var $elm$regex$Regex$replace = _Regex_replaceAtMost(_Regex_infinity);
var $lukewestby$elm_string_interpolate$String$Interpolate$interpolate = F2(
	function (string, args) {
		var asArray = $elm$core$Array$fromList(args);
		return A3(
			$elm$regex$Regex$replace,
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolationRegex,
			$lukewestby$elm_string_interpolate$String$Interpolate$applyInterpolation(asArray),
			string);
	});
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $dillonkearns$elm_graphql$Graphql$Document$Hash$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {bd: charsProcessed, bq: hash, aX: seed, bT: shift};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c1 = 3432918353;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$c2 = 461845907;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Bitwise$or = _Bitwise_or;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize = function (data) {
	var acc = (!(!data.bq)) ? (data.aX ^ A2(
		$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
		A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
			15,
			A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, data.bq)))) : data.aX;
	var h0 = acc ^ data.bd;
	var h1 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $dillonkearns$elm_graphql$Graphql$Document$Hash$mix = F2(
	function (h1, k1) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
			5,
			A2(
				$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
				13,
				h1 ^ A2(
					$dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy,
					$dillonkearns$elm_graphql$Graphql$Document$Hash$c2,
					A2(
						$dillonkearns$elm_graphql$Graphql$Document$Hash$rotlBy,
						15,
						A2($dillonkearns$elm_graphql$Graphql$Document$Hash$multiplyBy, $dillonkearns$elm_graphql$Graphql$Document$Hash$c1, k1))))) + 3864292196;
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold = F2(
	function (c, data) {
		var res = data.bq | ((255 & $elm$core$Char$toCode(c)) << data.bT);
		var _v0 = data.bT;
		if (_v0 === 24) {
			return {
				bd: data.bd + 1,
				bq: 0,
				aX: A2($dillonkearns$elm_graphql$Graphql$Document$Hash$mix, data.aX, res),
				bT: 0
			};
		} else {
			return {bd: data.bd + 1, bq: res, aX: data.aX, bT: data.bT + 8};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Hash$hashString = F2(
	function (seed, str) {
		return $dillonkearns$elm_graphql$Graphql$Document$Hash$finalize(
			A3(
				$elm$core$String$foldl,
				$dillonkearns$elm_graphql$Graphql$Document$Hash$hashFold,
				A4($dillonkearns$elm_graphql$Graphql$Document$Hash$HashData, 0, seed, 0, 0),
				str));
	});
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize = function (value) {
	switch (value.$) {
		case 0:
			var enumValue = value.a;
			return enumValue;
		case 1:
			var json = value.a;
			return A2($elm$json$Json$Encode$encode, 0, json);
		case 2:
			var values = value.a;
			return '[' + (A2(
				$elm$core$String$join,
				', ',
				A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize, values)) + ']');
		default:
			var keyValuePairs = value.a;
			return '{' + (A2(
				$elm$core$String$join,
				', ',
				A2(
					$elm$core$List$map,
					function (_v1) {
						var key = _v1.a;
						var objectValue = _v1.b;
						return key + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(objectValue));
					},
					keyValuePairs)) + '}');
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return name + (': ' + $dillonkearns$elm_graphql$Graphql$Internal$Encode$serialize(value));
};
var $dillonkearns$elm_graphql$Graphql$Document$Argument$serialize = function (args) {
	if (!args.b) {
		return '';
	} else {
		var nonemptyArgs = args;
		return '(' + (A2(
			$elm$core$String$join,
			', ',
			A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Document$Argument$argToString, nonemptyArgs)) + ')');
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash = function (field) {
	return A2(
		$elm$core$Maybe$map,
		$dillonkearns$elm_graphql$Graphql$Document$Hash$hashString(0),
		function () {
			if (!field.$) {
				var name = field.a;
				var _arguments = field.b;
				var children = field.c;
				return $elm$core$List$isEmpty(_arguments) ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments));
			} else {
				var typeString = field.a.nH;
				var fieldName = field.a.iv;
				var _arguments = field.b;
				return (fieldName === '__typename') ? $elm$core$Maybe$Nothing : $elm$core$Maybe$Just(
					$elm$core$String$concat(
						A2(
							$elm$core$List$append,
							_List_fromArray(
								[typeString]),
							$elm$core$List$singleton(
								$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(_arguments)))));
			}
		}());
};
var $dillonkearns$elm_graphql$Graphql$RawField$name = function (field) {
	if (!field.$) {
		var fieldName = field.a;
		var argumentList = field.b;
		var fieldList = field.c;
		return fieldName;
	} else {
		var typeString = field.a.nH;
		var fieldName = field.a.iv;
		var argumentList = field.b;
		return fieldName;
	}
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$alias = function (field) {
	return A2(
		$elm$core$Maybe$map,
		function (aliasHash) {
			return _Utils_ap(
				$dillonkearns$elm_graphql$Graphql$RawField$name(field),
				$elm$core$String$fromInt(aliasHash));
		},
		$dillonkearns$elm_graphql$Graphql$Document$Field$maybeAliasHash(field));
};
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $j_maas$elm_ordered_containers$OrderedDict$empty = {n: $elm$core$Dict$empty, mV: _List_Nil};
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return A2($elm$core$Dict$get, key, orderedDict.n);
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.n) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.mV) : orderedDict.mV;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			n: A3($elm$core$Dict$insert, key, value, orderedDict.n),
			mV: newOrder
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			n: A2($elm$core$Dict$remove, key, orderedDict.n),
			mV: A2($elm$core$Dict$member, key, orderedDict.n) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.mV) : orderedDict.mV
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$update = F3(
	function (key, alter, original) {
		var orderedDict = original;
		var _v0 = A2($elm$core$Dict$get, key, orderedDict.n);
		if (!_v0.$) {
			var oldItem = _v0.a;
			var _v1 = alter(
				$elm$core$Maybe$Just(oldItem));
			if (!_v1.$) {
				var newItem = _v1.a;
				return {
					n: A3(
						$elm$core$Dict$update,
						key,
						$elm$core$Basics$always(
							$elm$core$Maybe$Just(newItem)),
						orderedDict.n),
					mV: orderedDict.mV
				};
			} else {
				return A2($j_maas$elm_ordered_containers$OrderedDict$remove, key, original);
			}
		} else {
			var _v2 = alter($elm$core$Maybe$Nothing);
			if (!_v2.$) {
				var newItem = _v2.a;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, newItem, original);
			} else {
				return original;
			}
		}
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing = function (rawFields) {
	var fieldCounts = A3(
		$elm$core$List$foldl,
		F2(
			function (fld, acc) {
				return A3(
					$j_maas$elm_ordered_containers$OrderedDict$update,
					fld,
					function (val) {
						return $elm$core$Maybe$Just(
							function () {
								if (val.$ === 1) {
									return 0;
								} else {
									var count = val.a;
									return count + 1;
								}
							}());
					},
					acc);
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$RawField$name, rawFields));
	return A2(
		$elm$core$List$map,
		function (field) {
			return _Utils_Tuple2(
				field,
				(!A2(
					$elm$core$Maybe$withDefault,
					0,
					A2(
						$j_maas$elm_ordered_containers$OrderedDict$get,
						$dillonkearns$elm_graphql$Graphql$RawField$name(field),
						fieldCounts))) ? $elm$core$Maybe$Nothing : $dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
		},
		rawFields);
};
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $dillonkearns$elm_graphql$Graphql$Document$Indent$generate = function (indentationLevel) {
	return A2($elm$core$String$repeat, indentationLevel, '  ');
};
var $dillonkearns$elm_graphql$Graphql$RawField$Composite = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName = function (field) {
	return A2(
		$elm$core$Maybe$withDefault,
		$dillonkearns$elm_graphql$Graphql$RawField$name(field),
		$dillonkearns$elm_graphql$Graphql$Document$Field$alias(field));
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields = function (rawFields) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (field, mergedSoFar) {
				if (!field.$) {
					var newChildren = field.c;
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							if (maybeChildrenSoFar.$ === 1) {
								return $elm$core$Maybe$Just(field);
							} else {
								if (!maybeChildrenSoFar.a.$) {
									var _v2 = maybeChildrenSoFar.a;
									var existingFieldName = _v2.a;
									var existingArgs = _v2.b;
									var existingChildren = _v2.c;
									return $elm$core$Maybe$Just(
										A3(
											$dillonkearns$elm_graphql$Graphql$RawField$Composite,
											existingFieldName,
											existingArgs,
											_Utils_ap(existingChildren, newChildren)));
								} else {
									return $elm$core$Maybe$Just(field);
								}
							}
						},
						mergedSoFar);
				} else {
					return A3(
						$j_maas$elm_ordered_containers$OrderedDict$update,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(field),
						function (maybeChildrenSoFar) {
							return $elm$core$Maybe$Just(
								A2($elm$core$Maybe$withDefault, field, maybeChildrenSoFar));
						},
						mergedSoFar);
				}
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		rawFields);
};
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.n);
		},
		orderedDict.mV);
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields = function (children) {
	return $j_maas$elm_ordered_containers$OrderedDict$values(
		$dillonkearns$elm_graphql$Graphql$Document$Field$mergeFields(children));
};
var $dillonkearns$elm_graphql$Graphql$RawField$Leaf = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$RawField$typename = A2(
	$dillonkearns$elm_graphql$Graphql$RawField$Leaf,
	{iv: '__typename', nH: ''},
	_List_Nil);
var $dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren = function (children) {
	return $elm$core$List$isEmpty(children) ? A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, children) : children;
};
var $dillonkearns$elm_graphql$Graphql$Document$Field$serialize = F3(
	function (aliasName, mIndentationLevel, field) {
		var prefix = function () {
			if (!aliasName.$) {
				var aliasName_ = aliasName.a;
				return _Utils_ap(
					aliasName_,
					function () {
						if (!mIndentationLevel.$) {
							return ': ';
						} else {
							return ':';
						}
					}());
			} else {
				return '';
			}
		}();
		return A2(
			$elm$core$Maybe$map,
			function (string) {
				return _Utils_ap(
					$dillonkearns$elm_graphql$Graphql$Document$Indent$generate(
						A2($elm$core$Maybe$withDefault, 0, mIndentationLevel)),
					_Utils_ap(prefix, string));
			},
			function () {
				if (!field.$) {
					var fieldName = field.a;
					var args = field.b;
					var children = field.c;
					if (mIndentationLevel.$ === 1) {
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + ('{' + A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, children)))) + '}');
					} else {
						var indentationLevel = mIndentationLevel.a;
						return $elm$core$Maybe$Just(
							(fieldName + ($dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args) + (' {\n' + A2(
								$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
								$elm$core$Maybe$Just(indentationLevel),
								children)))) + ('\n' + ($dillonkearns$elm_graphql$Graphql$Document$Indent$generate(indentationLevel) + '}')));
					}
				} else {
					var fieldName = field.a.iv;
					var args = field.b;
					return $elm$core$Maybe$Just(
						_Utils_ap(
							fieldName,
							$dillonkearns$elm_graphql$Graphql$Document$Argument$serialize(args)));
				}
			}());
	});
var $dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren = F2(
	function (indentationLevel, children) {
		return A2(
			$elm$core$String$join,
			function () {
				if (!indentationLevel.$) {
					return '\n';
				} else {
					return ' ';
				}
			}(),
			A2(
				$elm$core$List$filterMap,
				$elm$core$Basics$identity,
				A2(
					$elm$core$List$map,
					function (_v0) {
						var field = _v0.a;
						var maybeAlias = _v0.b;
						return A3(
							$dillonkearns$elm_graphql$Graphql$Document$Field$serialize,
							maybeAlias,
							A2(
								$elm$core$Maybe$map,
								$elm$core$Basics$add(1),
								indentationLevel),
							field);
					},
					$dillonkearns$elm_graphql$Graphql$Document$Field$canAllowHashing(
						$dillonkearns$elm_graphql$Graphql$Document$Field$nonemptyChildren(
							$dillonkearns$elm_graphql$Graphql$Document$Field$mergedFields(children))))));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serialize = F2(
	function (operationType, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {\n{1}\n}',
			_List_fromArray(
				[
					operationType,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQuery = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'query', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return '{' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}');
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return 'query ' + (operationName + (' {' + (A2($dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren, $elm$core$Maybe$Nothing, fields) + '}')));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName = F3(
	function (operationType, operationName, queries) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'{0} {1} {\n{2}\n}',
			_List_fromArray(
				[
					operationType,
					operationName,
					A2(
					$dillonkearns$elm_graphql$Graphql$Document$Field$serializeChildren,
					$elm$core$Maybe$Just(0),
					queries)
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'query', operationName, fields);
	});
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace = F2(
	function (old, _new) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$String$split(old),
			$elm$core$String$join(_new));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape = A2(
	$elm$core$Basics$composeR,
	$elm$url$Url$percentEncode,
	A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$replace, '%20', '+'));
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(key) + ('=' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryEscape(value));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded = function (args) {
	return A2(
		$elm$core$String$join,
		'&',
		A2($elm$core$List$map, $dillonkearns$elm_graphql$Graphql$Http$QueryParams$queryPair, args));
};
var $dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams = F2(
	function (queryParams, url) {
		return $elm$core$List$isEmpty(queryParams) ? url : (url + ('?' + $dillonkearns$elm_graphql$Graphql$Http$QueryParams$joinUrlEncoded(queryParams)));
	});
var $dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build = F5(
	function (forceMethod, url, queryParams, maybeOperationName, queryDocument) {
		var _v0 = function () {
			if (!maybeOperationName.$) {
				var operationName = maybeOperationName.a;
				return _Utils_Tuple2(
					A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrlWithOperationName, operationName, queryDocument),
					_List_fromArray(
						[
							_Utils_Tuple2('operationName', operationName)
						]));
			} else {
				return _Utils_Tuple2(
					$dillonkearns$elm_graphql$Graphql$Document$serializeQueryForUrl(queryDocument),
					_List_Nil);
			}
		}();
		var serializedQueryForGetRequest = _v0.a;
		var operationNameParamForGetRequest = _v0.b;
		var urlForGetRequest = A2(
			$dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams,
			_Utils_ap(
				queryParams,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2('query', serializedQueryForGetRequest),
					operationNameParamForGetRequest)),
			url);
		if (_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(1)) || ((_Utils_cmp(
			$elm$core$String$length(urlForGetRequest),
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$maxLength) > -1) && (!_Utils_eq(
			forceMethod,
			$elm$core$Maybe$Just(0))))) {
			var _v2 = function () {
				if (!maybeOperationName.$) {
					var operationName = maybeOperationName.a;
					return _Utils_Tuple2(
						A2($dillonkearns$elm_graphql$Graphql$Document$serializeQueryWithOperationName, operationName, queryDocument),
						_List_fromArray(
							[
								_Utils_Tuple2(
								'operationName',
								$elm$json$Json$Encode$string(operationName))
							]));
				} else {
					return _Utils_Tuple2(
						$dillonkearns$elm_graphql$Graphql$Document$serializeQuery(queryDocument),
						_List_Nil);
				}
			}();
			var serializedQuery = _v2.a;
			var operationNameParamForPostRequest = _v2.b;
			return {
				fd: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$object(
						A2(
							$elm$core$List$cons,
							_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedQuery)),
							operationNameParamForPostRequest))),
				d8: 1,
				nJ: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, queryParams, url)
			};
		} else {
			return {fd: $elm$http$Http$emptyBody, d8: 0, nJ: urlForGetRequest};
		}
	});
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError = F3(
	function (message, locations, details) {
		return {kT: details, lH: locations, lP: message};
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm$json$Json$Decode$dict = function (decoder) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Dict$fromList,
		$elm$json$Json$Decode$keyValuePairs(decoder));
};
var $elm$json$Json$Decode$list = _Json_decodeList;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location = F2(
	function (line, column) {
		return {h7: column, i2: line};
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder = A3(
	$elm$json$Json$Decode$map2,
	$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$Location,
	A2($elm$json$Json$Decode$field, 'line', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'column', $elm$json$Json$Decode$int));
var $elm$json$Json$Decode$map3 = _Json_map3;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $elm$json$Json$Decode$string = _Json_decodeString;
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder = A2(
	$elm$json$Json$Decode$field,
	'errors',
	$elm$json$Json$Decode$list(
		A4(
			$elm$json$Json$Decode$map3,
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$GraphqlError,
			A2($elm$json$Json$Decode$field, 'message', $elm$json$Json$Decode$string),
			$elm$json$Json$Decode$maybe(
				A2(
					$elm$json$Json$Decode$field,
					'locations',
					$elm$json$Json$Decode$list($dillonkearns$elm_graphql$Graphql$Http$GraphqlError$locationDecoder))),
			A2(
				$elm$json$Json$Decode$map,
				$elm$core$Dict$remove('locations'),
				A2(
					$elm$json$Json$Decode$map,
					$elm$core$Dict$remove('message'),
					$elm$json$Json$Decode$dict($elm$json$Json$Decode$value))))));
var $elm$core$Tuple$pair = F2(
	function (a, b) {
		return _Utils_Tuple2(a, b);
	});
var $dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData = function (data) {
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Result$Err,
		A2(
			$elm$json$Json$Decode$map,
			$elm$core$Tuple$pair(data),
			$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$decoder));
};
var $dillonkearns$elm_graphql$Graphql$Http$nullJsonValue = function (a) {
	nullJsonValue:
	while (true) {
		var _v0 = A2($elm$json$Json$Decode$decodeString, $elm$json$Json$Decode$value, 'null');
		if (!_v0.$) {
			var value = _v0.a;
			return value;
		} else {
			var $temp$a = 0;
			a = $temp$a;
			continue nullJsonValue;
		}
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$errorDecoder = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2($elm$json$Json$Decode$map, $dillonkearns$elm_graphql$Graphql$Http$GraphqlError$ParsedData, decoder)),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				A2(
					$elm$json$Json$Decode$map,
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData,
					A2($elm$json$Json$Decode$field, 'data', $elm$json$Json$Decode$value))),
				A2(
				$elm$json$Json$Decode$andThen,
				$dillonkearns$elm_graphql$Graphql$Http$decodeErrorWithData,
				$elm$json$Json$Decode$succeed(
					$dillonkearns$elm_graphql$Graphql$Http$GraphqlError$UnparsedData(
						$dillonkearns$elm_graphql$Graphql$Http$nullJsonValue(0))))
			]));
};
var $dillonkearns$elm_graphql$Graphql$Http$decoderOrError = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$dillonkearns$elm_graphql$Graphql$Http$errorDecoder(decoder),
				A2($elm$json$Json$Decode$map, $elm$core$Result$Ok, decoder)
			]));
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutation = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($dillonkearns$elm_graphql$Graphql$Document$serialize, 'mutation', fields);
};
var $dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName = F2(
	function (operationName, _v0) {
		var fields = _v0.a;
		var decoder_ = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$Document$serializeWithOperationName, 'mutation', operationName, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Http$toReadyRequest = function (_v0) {
	var request = _v0;
	var _v1 = request.kT;
	if (!_v1.$) {
		var forcedRequestMethod = _v1.a;
		var querySelectionSet = _v1.b;
		var queryRequestDetails = A5(
			$dillonkearns$elm_graphql$Graphql$Http$QueryHelper$build,
			function () {
				if (!forcedRequestMethod.$) {
					if (!forcedRequestMethod.a) {
						var _v4 = forcedRequestMethod.a;
						return $elm$core$Maybe$Just(0);
					} else {
						var _v5 = forcedRequestMethod.a;
						return $elm$core$Maybe$Nothing;
					}
				} else {
					return $elm$core$Maybe$Just(1);
				}
			}(),
			request.cc,
			request.aT,
			request.aP,
			querySelectionSet);
		return {
			fd: queryRequestDetails.fd,
			ah: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.k_),
			li: request.li,
			d8: function () {
				var _v2 = queryRequestDetails.d8;
				if (!_v2) {
					return 'GET';
				} else {
					return 'Post';
				}
			}(),
			nC: request.nC,
			nJ: queryRequestDetails.nJ
		};
	} else {
		var mutationSelectionSet = _v1.a;
		var serializedMutation = function () {
			var _v7 = request.aP;
			if (!_v7.$) {
				var operationName = _v7.a;
				return A2($dillonkearns$elm_graphql$Graphql$Document$serializeMutationWithOperationName, operationName, mutationSelectionSet);
			} else {
				return $dillonkearns$elm_graphql$Graphql$Document$serializeMutation(mutationSelectionSet);
			}
		}();
		return {
			fd: $elm$http$Http$jsonBody(
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$append,
						_List_fromArray(
							[
								_Utils_Tuple2(
								'query',
								$elm$json$Json$Encode$string(serializedMutation))
							]),
						function () {
							var _v6 = request.aP;
							if (!_v6.$) {
								var operationName = _v6.a;
								return _List_fromArray(
									[
										_Utils_Tuple2(
										'operationName',
										$elm$json$Json$Encode$string(operationName))
									]);
							} else {
								return _List_Nil;
							}
						}()))),
			ah: $dillonkearns$elm_graphql$Graphql$Http$decoderOrError(request.k_),
			li: request.li,
			d8: 'POST',
			nC: request.nC,
			nJ: A2($dillonkearns$elm_graphql$Graphql$Http$QueryParams$urlWithQueryParams, request.aT, request.cc)
		};
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$resolver = function (request) {
	return $dillonkearns$elm_graphql$Graphql$Http$jsonResolver(
		$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(request).ah);
};
var $elm$http$Http$resultToTask = function (result) {
	if (!result.$) {
		var a = result.a;
		return $elm$core$Task$succeed(a);
	} else {
		var x = result.a;
		return $elm$core$Task$fail(x);
	}
};
var $elm$http$Http$riskyTask = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{kp: true, fd: r.fd, k_: r.nf, li: r.li, d8: r.d8, nC: r.nC, nG: $elm$core$Maybe$Nothing, nJ: r.nJ});
};
var $elm$http$Http$task = function (r) {
	return A3(
		_Http_toTask,
		0,
		$elm$http$Http$resultToTask,
		{kp: false, fd: r.fd, k_: r.nf, li: r.li, d8: r.d8, nC: r.nC, nG: $elm$core$Maybe$Nothing, nJ: r.nJ});
};
var $dillonkearns$elm_graphql$Graphql$Http$toTask = function (fullRequest) {
	var request = fullRequest;
	return A2(
		$elm$core$Task$andThen,
		$dillonkearns$elm_graphql$Graphql$Http$failTaskOnHttpSuccessWithErrors,
		A2(
			$elm$core$Task$mapError,
			$dillonkearns$elm_graphql$Graphql$Http$HttpError,
			function (readyRequest) {
				return (request.a$ ? $elm$http$Http$riskyTask : $elm$http$Http$task)(
					{
						fd: readyRequest.fd,
						li: readyRequest.li,
						d8: readyRequest.d8,
						nf: $dillonkearns$elm_graphql$Graphql$Http$resolver(fullRequest),
						nC: readyRequest.nC,
						nJ: readyRequest.nJ
					});
			}(
				$dillonkearns$elm_graphql$Graphql$Http$toReadyRequest(fullRequest))));
};
var $dillonkearns$elm_graphql$Graphql$Http$Request = $elm$core$Basics$identity;
var $dillonkearns$elm_graphql$Graphql$Http$withCredentials = function (_v0) {
	var request = _v0;
	return _Utils_update(
		request,
		{a$: true});
};
var $author$project$Api$executeGraphqlOperation = F4(
	function (env, operation, selectionSet, tagger) {
		return A2(
			$elm$core$Task$attempt,
			tagger,
			$dillonkearns$elm_graphql$Graphql$Http$toTask(
				$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
					A2(
						operation,
						$author$project$Env$apiUrl(env),
						selectionSet))));
	});
var $krisajenkins$remotedata$RemoteData$Failure = function (a) {
	return {$: 2, a: a};
};
var $krisajenkins$remotedata$RemoteData$Success = function (a) {
	return {$: 3, a: a};
};
var $krisajenkins$remotedata$RemoteData$fromResult = function (result) {
	if (result.$ === 1) {
		var e = result.a;
		return $krisajenkins$remotedata$RemoteData$Failure(e);
	} else {
		var x = result.a;
		return $krisajenkins$remotedata$RemoteData$Success(x);
	}
};
var $dillonkearns$elm_graphql$Graphql$Http$Query = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Document$decoder = function (_v0) {
	var fields = _v0.a;
	var decoder_ = _v0.b;
	return A2($elm$json$Json$Decode$field, 'data', decoder_);
};
var $dillonkearns$elm_graphql$Graphql$Http$queryRequest = F2(
	function (baseUrl, query) {
		return {
			cc: baseUrl,
			kT: A2($dillonkearns$elm_graphql$Graphql$Http$Query, $elm$core$Maybe$Nothing, query),
			k_: $dillonkearns$elm_graphql$Graphql$Document$decoder(query),
			li: _List_Nil,
			aP: $elm$core$Maybe$Nothing,
			aT: _List_Nil,
			nC: $elm$core$Maybe$Nothing,
			a$: false
		};
	});
var $dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite = F3(
	function (fieldName, args, fields) {
		return A3($dillonkearns$elm_graphql$Graphql$RawField$Composite, fieldName, args, fields);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField = F4(
	function (fieldName, args, _v0, decoderTransform) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)
				]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$json$Json$Decode$field,
						fieldName,
						decoderTransform(decoder)),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(
							A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, fieldName, args, fields)),
						decoderTransform(decoder))
					])));
	});
var $author$project$Gql$Platform$Query$userInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'userInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$User$BackofficeUserData = function (permissions) {
	return {cN: permissions};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf = F2(
	function (details, args) {
		return A2($dillonkearns$elm_graphql$Graphql$RawField$Leaf, details, args);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField = F4(
	function (typeString, fieldName, args, decoder) {
		var newLeaf = A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$leaf,
			{iv: fieldName, nH: typeString},
			args);
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_List_fromArray(
				[newLeaf]),
			$elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						A2($elm$json$Json$Decode$field, fieldName, decoder),
						A2(
						$elm$json$Json$Decode$field,
						$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName(newLeaf),
						decoder)
					])));
	});
var $author$project$Gql$Platform$Object$BackofficeUserData$permissions = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(List String)',
	'permissions',
	_List_Nil,
	$elm$json$Json$Decode$list($elm$json$Json$Decode$string));
var $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed = function (constructor) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		_List_Nil,
		$elm$json$Json$Decode$succeed(constructor));
};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$with = F2(
	function (_v0, _v1) {
		var selectionFields1 = _v0.a;
		var selectionDecoder1 = _v0.b;
		var selectionFields2 = _v1.a;
		var selectionDecoder2 = _v1.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			_Utils_ap(selectionFields1, selectionFields2),
			A3($elm$json$Json$Decode$map2, $elm$core$Basics$apR, selectionDecoder1, selectionDecoder2));
	});
var $author$project$Page$Login$Gql$userSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeUserData$permissions,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$User$BackofficeUserData));
var $author$project$Page$Login$Effect$runEffect = F3(
	function (env, _v0, effect) {
		switch (effect.$) {
			case 0:
				return $elm$core$Platform$Cmd$none;
			case 1:
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Gql$Platform$Query$userInfo($author$project$Page$Login$Gql$userSelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$Login$Msg$GotAuthCheckResult));
			default:
				return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Page$NotAuthorized$Effect$runEffect = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Platform$Cmd$none;
	});
var $author$project$Page$NotFound$Effect$runEffect = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Platform$Cmd$none;
	});
var $author$project$Page$OfferDetails$Msg$EmailSent = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$OfferDetails$Msg$FetchedClaimHistory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$OfferDetails$Msg$FetchedClaimHistoryPricingAction = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$OfferDetails$Msg$FetchedCustomerId = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$OfferDetails$Msg$FetchedOffer = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$OfferDetails$Msg$FetchedPaymentMethod = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$OfferDetails$Msg$GotCurrentDate = function (a) {
	return {$: 8, a: a};
};
var $author$project$Page$OfferDetails$Msg$OfferCoverageEffectiveDateAmended = function (a) {
	return {$: 7, a: a};
};
var $author$project$Common$Gql$CustomerId$OfferId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$Gql$PaymentMethod$OfferId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Platform$Scalar$DateTime = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$GqlDecimal = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$Id = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$NaiveDate = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$Uuid = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$YearMonth = $elm$core$Basics$identity;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $elm$json$Json$Decode$float = _Json_decodeFloat;
var $elm$core$String$fromFloat = _String_fromNumber;
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder = $elm$json$Json$Decode$oneOf(
	_List_fromArray(
		[
			$elm$json$Json$Decode$string,
			A2($elm$json$Json$Decode$map, $elm$core$String$fromFloat, $elm$json$Json$Decode$float),
			A2($elm$json$Json$Decode$map, $elm$core$String$fromInt, $elm$json$Json$Decode$int),
			A2(
			$elm$json$Json$Decode$map,
			function (bool) {
				return bool ? 'true' : 'false';
			},
			$elm$json$Json$Decode$bool)
		]));
var $author$project$Gql$Platform$Scalar$defaultCodecs = {
	af: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v0) {
			var raw = _v0;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	cg: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v1) {
			var raw = _v1;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	h4: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v2) {
			var raw = _v2;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	aF: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v3) {
			var raw = _v3;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	I: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v4) {
			var raw = _v4;
			return $elm$json$Json$Encode$string(raw);
		}
	},
	h5: {
		ah: A2($elm$json$Json$Decode$map, $elm$core$Basics$identity, $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$scalarDecoder),
		aI: function (_v5) {
			var raw = _v5;
			return $elm$json$Json$Encode$string(raw);
		}
	}
};
var $author$project$Gql$Platform$Scalar$Codecs = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Scalar$defineCodecs = function (definitions) {
	return definitions;
};
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {h6: col, kH: contextStack, m3: problem, nl: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.nl, s.h6, x, s.kG));
	});
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.e, s.hn);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{h6: 1, kG: s.kG, i: s.i, e: s.e + 1, nl: s.nl + 1, hn: s.hn}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{h6: s.h6 + 1, kG: s.kG, i: s.i, e: newOffset, nl: s.nl, hn: s.hn}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Date$deadEndToString = function (_v0) {
	var problem = _v0.m3;
	if (problem.$ === 12) {
		var message = problem.a;
		return message;
	} else {
		return 'Expected a date in ISO 8601 format';
	}
};
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.hn),
			s.e) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $justinmimbs$date$Date$MonthAndDay = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $justinmimbs$date$Date$OrdinalDay = function (a) {
	return {$: 2, a: a};
};
var $justinmimbs$date$Date$WeekAndWeekday = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$backtrackable = function (_v0) {
	var parse = _v0;
	return function (s0) {
		var _v1 = parse(s0);
		if (_v1.$ === 1) {
			var x = _v1.b;
			return A2($elm$parser$Parser$Advanced$Bad, false, x);
		} else {
			var a = _v1.b;
			var s1 = _v1.c;
			return A3($elm$parser$Parser$Advanced$Good, false, a, s1);
		}
	};
};
var $elm$parser$Parser$backtrackable = $elm$parser$Parser$Advanced$backtrackable;
var $elm$parser$Parser$Advanced$commit = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, true, a, s);
	};
};
var $elm$parser$Parser$commit = $elm$parser$Parser$Advanced$commit;
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.e, s1.e, s0.hn),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$mapChompedString = $elm$parser$Parser$Advanced$mapChompedString;
var $justinmimbs$date$Date$int1 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	$elm$parser$Parser$chompIf($elm$core$Char$isDigit));
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $justinmimbs$date$Date$int2 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			$elm$parser$Parser$succeed(0),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $justinmimbs$date$Date$int3 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$core$Basics$not = _Basics_not;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.e, s.nl, s.h6, s.hn);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{h6: newCol, kG: s.kG, i: s.i, e: newOffset, nl: newRow, hn: s.hn});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Date$dayOfYear = $elm$parser$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed($elm$core$Basics$identity),
				$elm$parser$Parser$token('-')),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$elm$parser$Parser$backtrackable(
						A2(
							$elm$parser$Parser$andThen,
							$elm$parser$Parser$commit,
							A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int2),
									$elm$parser$Parser$succeed(1)
								]))),
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$ignorer,
								$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
								$elm$parser$Parser$token('W')),
							$justinmimbs$date$Date$int2),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$token('-')),
									$justinmimbs$date$Date$int1),
									$elm$parser$Parser$succeed(1)
								])))
					]))),
			$elm$parser$Parser$backtrackable(
			A2(
				$elm$parser$Parser$andThen,
				$elm$parser$Parser$commit,
				A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						$elm$parser$Parser$succeed($justinmimbs$date$Date$MonthAndDay),
						$justinmimbs$date$Date$int2),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								$justinmimbs$date$Date$int2,
								$elm$parser$Parser$succeed(1)
							]))))),
			A2($elm$parser$Parser$map, $justinmimbs$date$Date$OrdinalDay, $justinmimbs$date$Date$int3),
			A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed($justinmimbs$date$Date$WeekAndWeekday),
					$elm$parser$Parser$token('W')),
				$justinmimbs$date$Date$int2),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						$justinmimbs$date$Date$int1,
						$elm$parser$Parser$succeed(1)
					]))),
			$elm$parser$Parser$succeed(
			$justinmimbs$date$Date$OrdinalDay(1))
		]));
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$modBy = _Basics_modBy;
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$isBetweenInt = F3(
	function (a, b, x) {
		return (_Utils_cmp(a, x) < 1) && (_Utils_cmp(x, b) < 1);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$fromCalendarParts = F3(
	function (y, mn, d) {
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, 12, mn)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('month ' + ($elm$core$String$fromInt(mn) + ' is out of range')) + (' (1 to 12)' + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))) : ((!A3(
			$justinmimbs$date$Date$isBetweenInt,
			1,
			A2(
				$justinmimbs$date$Date$daysInMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn)),
			d)) ? $elm$core$Result$Err(
			'Invalid date: ' + (('day ' + ($elm$core$String$fromInt(d) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(
				A2(
					$justinmimbs$date$Date$daysInMonth,
					y,
					$justinmimbs$date$Date$numberToMonth(mn))) + ')')) + ((' for ' + $justinmimbs$date$Date$monthToName(
				$justinmimbs$date$Date$numberToMonth(mn))) + ((((mn === 2) && (d === 29)) ? (' (' + ($elm$core$String$fromInt(y) + ' is not a leap year)')) : '') + ('; received (year ' + ($elm$core$String$fromInt(y) + (', month ' + ($elm$core$String$fromInt(mn) + (', day ' + ($elm$core$String$fromInt(d) + ')'))))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeYear(y) + A2(
				$justinmimbs$date$Date$daysBeforeMonth,
				y,
				$justinmimbs$date$Date$numberToMonth(mn))) + d));
	});
var $justinmimbs$date$Date$fromOrdinalParts = F2(
	function (y, od) {
		var daysInYear = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, daysInYear, od)) ? $elm$core$Result$Err(
			'Invalid ordinal date: ' + (('ordinal-day ' + ($elm$core$String$fromInt(od) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(daysInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(y)) + ('; received (year ' + ($elm$core$String$fromInt(y) + (', ordinal-day ' + ($elm$core$String$fromInt(od) + ')')))))))) : $elm$core$Result$Ok(
			$justinmimbs$date$Date$daysBeforeYear(y) + od);
	});
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $justinmimbs$date$Date$firstOfYear = function (y) {
	return $justinmimbs$date$Date$daysBeforeYear(y) + 1;
};
var $justinmimbs$date$Date$is53WeekYear = function (y) {
	var wdnJan1 = $justinmimbs$date$Date$weekdayNumber(
		$justinmimbs$date$Date$firstOfYear(y));
	return (wdnJan1 === 4) || ((wdnJan1 === 3) && $justinmimbs$date$Date$isLeapYear(y));
};
var $justinmimbs$date$Date$fromWeekParts = F3(
	function (wy, wn, wdn) {
		var weeksInYear = $justinmimbs$date$Date$is53WeekYear(wy) ? 53 : 52;
		return (!A3($justinmimbs$date$Date$isBetweenInt, 1, weeksInYear, wn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('week ' + ($elm$core$String$fromInt(wn) + ' is out of range')) + ((' (1 to ' + ($elm$core$String$fromInt(weeksInYear) + ')')) + ((' for ' + $elm$core$String$fromInt(wy)) + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')')))))))))) : ((!A3($justinmimbs$date$Date$isBetweenInt, 1, 7, wdn)) ? $elm$core$Result$Err(
			'Invalid week date: ' + (('weekday ' + ($elm$core$String$fromInt(wdn) + ' is out of range')) + (' (1 to 7)' + ('; received (year ' + ($elm$core$String$fromInt(wy) + (', week ' + ($elm$core$String$fromInt(wn) + (', weekday ' + ($elm$core$String$fromInt(wdn) + ')'))))))))) : $elm$core$Result$Ok(
			($justinmimbs$date$Date$daysBeforeWeekYear(wy) + ((wn - 1) * 7)) + wdn));
	});
var $justinmimbs$date$Date$fromYearAndDayOfYear = function (_v0) {
	var y = _v0.a;
	var doy = _v0.b;
	switch (doy.$) {
		case 0:
			var mn = doy.a;
			var d = doy.b;
			return A3($justinmimbs$date$Date$fromCalendarParts, y, mn, d);
		case 1:
			var wn = doy.a;
			var wdn = doy.b;
			return A3($justinmimbs$date$Date$fromWeekParts, y, wn, wdn);
		default:
			var od = doy.a;
			return A2($justinmimbs$date$Date$fromOrdinalParts, y, od);
	}
};
var $justinmimbs$date$Date$int4 = A2(
	$elm$parser$Parser$mapChompedString,
	F2(
		function (str, _v0) {
			return A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(str));
		}),
	A2(
		$elm$parser$Parser$ignorer,
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				A2(
					$elm$parser$Parser$ignorer,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed(0),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									$elm$parser$Parser$chompIf(
									function (c) {
										return c === '-';
									}),
									$elm$parser$Parser$succeed(0)
								]))),
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
				$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
			$elm$parser$Parser$chompIf($elm$core$Char$isDigit)),
		$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Date$resultToParser = function (result) {
	if (!result.$) {
		var x = result.a;
		return $elm$parser$Parser$succeed(x);
	} else {
		var message = result.a;
		return $elm$parser$Parser$problem(message);
	}
};
var $justinmimbs$date$Date$parser = A2(
	$elm$parser$Parser$andThen,
	A2($elm$core$Basics$composeR, $justinmimbs$date$Date$fromYearAndDayOfYear, $justinmimbs$date$Date$resultToParser),
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Tuple$pair),
			$justinmimbs$date$Date$int4),
		$justinmimbs$date$Date$dayOfYear));
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {h6: col, m3: problem, nl: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.nl, p.h6, p.m3);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{h6: 1, kG: _List_Nil, i: 1, e: 0, nl: 1, hn: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $justinmimbs$date$Date$fromIsoString = A2(
	$elm$core$Basics$composeR,
	$elm$parser$Parser$run(
		A2(
			$elm$parser$Parser$keeper,
			$elm$parser$Parser$succeed($elm$core$Basics$identity),
			A2(
				$elm$parser$Parser$ignorer,
				$justinmimbs$date$Date$parser,
				A2(
					$elm$parser$Parser$andThen,
					$justinmimbs$date$Date$resultToParser,
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2($elm$parser$Parser$map, $elm$core$Result$Ok, $elm$parser$Parser$end),
								A2(
								$elm$parser$Parser$map,
								$elm$core$Basics$always(
									$elm$core$Result$Err('Expected a date only, not a date and time')),
								$elm$parser$Parser$chompIf(
									$elm$core$Basics$eq('T'))),
								$elm$parser$Parser$succeed(
								$elm$core$Result$Err('Expected a date only'))
							])))))),
	$elm$core$Result$mapError(
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$head,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($justinmimbs$date$Date$deadEndToString),
				$elm$core$Maybe$withDefault('')))));
var $prikhi$decimal$Decimal$Decimal = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$Negative = 1;
var $cmditch$elm_bigint$BigInt$Positive = 0;
var $cmditch$elm_bigint$BigInt$Magnitude = $elm$core$Basics$identity;
var $elm_community$maybe_extra$Maybe$Extra$combineHelp = F2(
	function (list, acc) {
		combineHelp:
		while (true) {
			if (list.b) {
				var head = list.a;
				var tail = list.b;
				if (!head.$) {
					var a = head.a;
					var $temp$list = tail,
						$temp$acc = A2($elm$core$List$cons, a, acc);
					list = $temp$list;
					acc = $temp$acc;
					continue combineHelp;
				} else {
					return $elm$core$Maybe$Nothing;
				}
			} else {
				return $elm$core$Maybe$Just(
					$elm$core$List$reverse(acc));
			}
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$combine = function (list) {
	return A2($elm_community$maybe_extra$Maybe$Extra$combineHelp, list, _List_Nil);
};
var $elm_community$list_extra$List$Extra$dropWhile = F2(
	function (predicate, list) {
		dropWhile:
		while (true) {
			if (!list.b) {
				return _List_Nil;
			} else {
				var x = list.a;
				var xs = list.b;
				if (predicate(x)) {
					var $temp$predicate = predicate,
						$temp$list = xs;
					predicate = $temp$predicate;
					list = $temp$list;
					continue dropWhile;
				} else {
					return list;
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$emptyZero = function (_v0) {
	var xs = _v0;
	var _v1 = A2(
		$elm_community$list_extra$List$Extra$dropWhile,
		$elm$core$Basics$eq(0),
		xs);
	if (!_v1.b) {
		return _List_Nil;
	} else {
		return xs;
	}
};
var $elm$core$String$fromList = _String_fromList;
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOfWithStep = F3(
	function (size, step, list) {
		if ((size <= 0) || (step <= 0)) {
			return _List_Nil;
		} else {
			var go = F2(
				function (xs, acc) {
					go:
					while (true) {
						if ($elm$core$List$isEmpty(xs)) {
							return $elm$core$List$reverse(acc);
						} else {
							var $temp$xs = A2($elm$core$List$drop, step, xs),
								$temp$acc = A2(
								$elm$core$List$cons,
								A2($elm$core$List$take, size, xs),
								acc);
							xs = $temp$xs;
							acc = $temp$acc;
							continue go;
						}
					}
				});
			return A2(go, list, _List_Nil);
		}
	});
var $elm_community$list_extra$List$Extra$greedyGroupsOf = F2(
	function (size, xs) {
		return A3($elm_community$list_extra$List$Extra$greedyGroupsOfWithStep, size, size, xs);
	});
var $cmditch$elm_bigint$Constants$maxDigitMagnitude = 7;
var $cmditch$elm_bigint$BigInt$fromString_ = function (x) {
	return A2(
		$elm$core$Maybe$map,
		A2($elm$core$Basics$composeL, $cmditch$elm_bigint$BigInt$emptyZero, $elm$core$Basics$identity),
		$elm_community$maybe_extra$Maybe$Extra$combine(
			A2(
				$elm$core$List$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$List$reverse,
					A2($elm$core$Basics$composeR, $elm$core$String$fromList, $elm$core$String$toInt)),
				A2(
					$elm_community$list_extra$List$Extra$greedyGroupsOf,
					$cmditch$elm_bigint$Constants$maxDigitMagnitude,
					$elm$core$List$reverse(x)))));
};
var $cmditch$elm_bigint$BigInt$Neg = function (a) {
	return {$: 1, a: a};
};
var $cmditch$elm_bigint$BigInt$Pos = function (a) {
	return {$: 0, a: a};
};
var $cmditch$elm_bigint$BigInt$Zer = {$: 2};
var $cmditch$elm_bigint$BigInt$mkBigInt = F2(
	function (s, mag) {
		var digits = mag;
		if ($elm$core$List$isEmpty(digits)) {
			return $cmditch$elm_bigint$BigInt$Zer;
		} else {
			switch (s) {
				case 2:
					return $cmditch$elm_bigint$BigInt$Zer;
				case 0:
					return $cmditch$elm_bigint$BigInt$Pos(mag);
				default:
					return $cmditch$elm_bigint$BigInt$Neg(mag);
			}
		}
	});
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$String$toLower = _String_toLower;
var $cmditch$elm_bigint$BigInt$fromString = function (x) {
	var _v0 = $elm$core$String$toList(
		$elm$core$String$toLower(x));
	if (!_v0.b) {
		return $elm$core$Maybe$Nothing;
	} else {
		switch (_v0.a) {
			case '-':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(1),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			case '+':
				if (!_v0.b.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var xs = _v0.b;
					return A2(
						$elm$core$Maybe$map,
						$cmditch$elm_bigint$BigInt$mkBigInt(0),
						$cmditch$elm_bigint$BigInt$fromString_(xs));
				}
			default:
				var xs = _v0;
				return A2(
					$elm$core$Maybe$map,
					$cmditch$elm_bigint$BigInt$mkBigInt(0),
					$cmditch$elm_bigint$BigInt$fromString_(xs));
		}
	}
};
var $prikhi$decimal$Decimal$fromString = function (str) {
	var stringToDecimal = function (s) {
		var stringIntToDecimal = F2(
			function (s_, e) {
				var _v8 = $cmditch$elm_bigint$BigInt$fromString(s_);
				if (_v8.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var a = _v8.a;
					return $elm$core$Maybe$Just(
						A2($prikhi$decimal$Decimal$Decimal, a, e));
				}
			});
		var _v6 = A2($elm$core$String$split, '.', s);
		_v6$2:
		while (true) {
			if (_v6.b) {
				if (_v6.b.b) {
					if (!_v6.b.b.b) {
						var a = _v6.a;
						var _v7 = _v6.b;
						var b = _v7.a;
						return A2(
							stringIntToDecimal,
							_Utils_ap(a, b),
							-$elm$core$String$length(b));
					} else {
						break _v6$2;
					}
				} else {
					var a = _v6.a;
					return A2(stringIntToDecimal, a, 0);
				}
			} else {
				break _v6$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var splitMantissaExponent = function (s) {
		var _v4 = A2(
			$elm$core$String$split,
			'e',
			$elm$core$String$toLower(s));
		_v4$2:
		while (true) {
			if (_v4.b) {
				if (!_v4.b.b) {
					var s1 = _v4.a;
					return _Utils_Tuple2(
						stringToDecimal(s1),
						$elm$core$Maybe$Just(0));
				} else {
					if (!_v4.b.b.b) {
						var s1 = _v4.a;
						var _v5 = _v4.b;
						var s2 = _v5.a;
						return _Utils_Tuple2(
							stringToDecimal(s1),
							$elm$core$String$toInt(s2));
					} else {
						break _v4$2;
					}
				}
			} else {
				break _v4$2;
			}
		}
		return _Utils_Tuple2($elm$core$Maybe$Nothing, $elm$core$Maybe$Nothing);
	};
	var makeMantissa = function (s) {
		var _v2 = A2($elm$core$String$split, '.', s);
		_v2$2:
		while (true) {
			if (_v2.b) {
				if (!_v2.b.b) {
					var s1 = _v2.a;
					return stringToDecimal(s1);
				} else {
					if (!_v2.b.b.b) {
						var s1 = _v2.a;
						var _v3 = _v2.b;
						var s2 = _v3.a;
						return stringToDecimal(
							A2(
								$elm$core$String$join,
								'',
								_List_fromArray(
									[s1, s2])));
					} else {
						break _v2$2;
					}
				}
			} else {
				break _v2$2;
			}
		}
		return $elm$core$Maybe$Nothing;
	};
	var _v0 = splitMantissaExponent(str);
	if ((!_v0.a.$) && (!_v0.b.$)) {
		var _v1 = _v0.a.a;
		var m = _v1.a;
		var a = _v1.b;
		var e = _v0.b.a;
		return $elm$core$Maybe$Just(
			A2($prikhi$decimal$Decimal$Decimal, m, e + a));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $danyx23$elm_uuid$Uuid$Uuid = $elm$core$Basics$identity;
var $elm$regex$Regex$contains = _Regex_contains;
var $danyx23$elm_uuid$Uuid$Barebones$uuidRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('^[0-9A-Fa-f]{8,8}-[0-9A-Fa-f]{4,4}-[1-5][0-9A-Fa-f]{3,3}-[8-9A-Ba-b][0-9A-Fa-f]{3,3}-[0-9A-Fa-f]{12,12}$'));
var $danyx23$elm_uuid$Uuid$Barebones$isValidUuid = function (uuidAsString) {
	return A2($elm$regex$Regex$contains, $danyx23$elm_uuid$Uuid$Barebones$uuidRegex, uuidAsString);
};
var $danyx23$elm_uuid$Uuid$fromString = function (text) {
	return $danyx23$elm_uuid$Uuid$Barebones$isValidUuid(text) ? $elm$core$Maybe$Just(
		$elm$core$String$toLower(text)) : $elm$core$Maybe$Nothing;
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth = function (month) {
	switch (month) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.k, posixMinutes) < 0) {
					return posixMinutes + era.e;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		kO: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		lU: month,
		nO: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).kO;
	});
var $elm$time$Time$toHour = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			24,
			A2(
				$elm$time$Time$flooredDiv,
				A2($elm$time$Time$toAdjustedMinutes, zone, time),
				60));
	});
var $elm$time$Time$toMillis = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			1000,
			$elm$time$Time$posixToMillis(time));
	});
var $elm$time$Time$toMinute = F2(
	function (zone, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2($elm$time$Time$toAdjustedMinutes, zone, time));
	});
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).lU;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$core$String$cons = _String_cons;
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString = F2(
	function (digits, time) {
		return A3(
			$elm$core$String$padLeft,
			digits,
			'0',
			$elm$core$String$fromInt(time));
	});
var $elm$time$Time$toSecond = F2(
	function (_v0, time) {
		return A2(
			$elm$core$Basics$modBy,
			60,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				1000));
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).nO;
	});
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime = function (time) {
	return A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		4,
		A2($elm$time$Time$toYear, $elm$time$Time$utc, time)) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		$rtfeldman$elm_iso8601_date_strings$Iso8601$fromMonth(
			A2($elm$time$Time$toMonth, $elm$time$Time$utc, time))) + ('-' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toDay, $elm$time$Time$utc, time)) + ('T' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toHour, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toMinute, $elm$time$Time$utc, time)) + (':' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		2,
		A2($elm$time$Time$toSecond, $elm$time$Time$utc, time)) + ('.' + (A2(
		$rtfeldman$elm_iso8601_date_strings$Iso8601$toPaddedString,
		3,
		A2($elm$time$Time$toMillis, $elm$time$Time$utc, time)) + 'Z'))))))))))));
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {kO: d, lU: m, nO: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		gY: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		nO: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.nO, 0, date.gY);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.kO;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.lU;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.gY;
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		ka: 1 + (((rd - week1Day1) / 7) | 0),
		kb: wy,
		nL: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.ka;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.kb;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.ea(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.gp(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.ea(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.fz(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.a_(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.a_(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.a_(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.hN(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.a_(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.a_(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.hn);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.e, offset) < 0,
					0,
					{h6: col, kG: s0.kG, i: s0.i, e: offset, nl: row, hn: s0.hn});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.e, s.nl, s.h6, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.e, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	fz: $justinmimbs$date$Date$withOrdinalSuffix,
	gp: $justinmimbs$date$Date$monthToName,
	ea: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	hN: $justinmimbs$date$Date$weekdayToName,
	a_: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $justinmimbs$date$Date$toIsoString = $justinmimbs$date$Date$format('yyyy-MM-dd');
var $elm$core$Result$toMaybe = function (result) {
	if (!result.$) {
		var v = result.a;
		return $elm$core$Maybe$Just(v);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cmditch$elm_bigint$BigInt$BigIntNotNormalised = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $cmditch$elm_bigint$BigInt$MagnitudeNotNormalised = $elm$core$Basics$identity;
var $elm_community$list_extra$List$Extra$last = function (items) {
	last:
	while (true) {
		if (!items.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!items.b.b) {
				var x = items.a;
				return $elm$core$Maybe$Just(x);
			} else {
				var rest = items.b;
				var $temp$items = rest;
				items = $temp$items;
				continue last;
			}
		}
	}
};
var $cmditch$elm_bigint$BigInt$isNegativeMagnitude = function (digits) {
	var _v0 = $elm_community$list_extra$List$Extra$last(digits);
	if (_v0.$ === 1) {
		return false;
	} else {
		var x = _v0.a;
		return x < 0;
	}
};
var $cmditch$elm_bigint$BigInt$mkBigIntNotNormalised = F2(
	function (s, digits) {
		return A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, s, digits);
	});
var $elm_community$list_extra$List$Extra$dropWhileRight = function (p) {
	return A2(
		$elm$core$List$foldr,
		F2(
			function (x, xs) {
				return (p(x) && $elm$core$List$isEmpty(xs)) ? _List_Nil : A2($elm$core$List$cons, x, xs);
			}),
		_List_Nil);
};
var $cmditch$elm_bigint$BigInt$dropZeroes = $elm_community$list_extra$List$Extra$dropWhileRight(
	$elm$core$Basics$eq(0));
var $elm$core$Basics$pow = _Basics_pow;
var $cmditch$elm_bigint$Constants$maxDigitValue = (-1) + A2($elm$core$Basics$pow, 10, $cmditch$elm_bigint$Constants$maxDigitMagnitude);
var $cmditch$elm_bigint$BigInt$baseDigit = $cmditch$elm_bigint$Constants$maxDigitValue + 1;
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $cmditch$elm_bigint$BigInt$normaliseDigit = function (x) {
	return (x < 0) ? A2(
		$elm$core$Tuple$mapFirst,
		$elm$core$Basics$add(-1),
		$cmditch$elm_bigint$BigInt$normaliseDigit(x + $cmditch$elm_bigint$BigInt$baseDigit)) : _Utils_Tuple2((x / $cmditch$elm_bigint$BigInt$baseDigit) | 0, x % $cmditch$elm_bigint$BigInt$baseDigit);
};
var $cmditch$elm_bigint$BigInt$normaliseDigitList = F2(
	function (carry, xs) {
		normaliseDigitList:
		while (true) {
			if (!xs.b) {
				if (_Utils_cmp(carry, $cmditch$elm_bigint$BigInt$baseDigit) > 0) {
					var $temp$carry = 0,
						$temp$xs = _List_fromArray(
						[carry]);
					carry = $temp$carry;
					xs = $temp$xs;
					continue normaliseDigitList;
				} else {
					return _List_fromArray(
						[carry]);
				}
			} else {
				var x = xs.a;
				var xs_ = xs.b;
				var _v1 = $cmditch$elm_bigint$BigInt$normaliseDigit(x + carry);
				var newCarry = _v1.a;
				var x_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					x_,
					A2($cmditch$elm_bigint$BigInt$normaliseDigitList, newCarry, xs_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$normaliseMagnitude = function (_v0) {
	var xs = _v0;
	return $cmditch$elm_bigint$BigInt$dropZeroes(
		A2($cmditch$elm_bigint$BigInt$normaliseDigitList, 0, xs));
};
var $cmditch$elm_bigint$BigInt$reverseMagnitude = $elm$core$List$map($elm$core$Basics$negate);
var $cmditch$elm_bigint$BigInt$Zero = 2;
var $cmditch$elm_bigint$BigInt$signNegate = function (sign_) {
	switch (sign_) {
		case 0:
			return 1;
		case 1:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$normalise = function (_v0) {
	normalise:
	while (true) {
		var s = _v0.a;
		var digits = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$normaliseMagnitude(digits);
		var normalisedMag = _v1;
		if ($cmditch$elm_bigint$BigInt$isNegativeMagnitude(normalisedMag)) {
			var $temp$_v0 = A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				$cmditch$elm_bigint$BigInt$signNegate(s),
				$cmditch$elm_bigint$BigInt$reverseMagnitude(normalisedMag));
			_v0 = $temp$_v0;
			continue normalise;
		} else {
			return A2($cmditch$elm_bigint$BigInt$mkBigInt, s, normalisedMag);
		}
	}
};
var $cmditch$elm_bigint$BigInt$signFromInt = function (x) {
	var _v0 = A2($elm$core$Basics$compare, x, 0);
	switch (_v0) {
		case 0:
			return 1;
		case 2:
			return 0;
		default:
			return 2;
	}
};
var $cmditch$elm_bigint$BigInt$fromInt = function (x) {
	return $cmditch$elm_bigint$BigInt$normalise(
		A2(
			$cmditch$elm_bigint$BigInt$BigIntNotNormalised,
			$cmditch$elm_bigint$BigInt$signFromInt(x),
			_List_fromArray(
				[
					$elm$core$Basics$abs(x)
				])));
};
var $cmditch$elm_bigint$BigInt$compareMagnitude = F4(
	function (x, y, xs, ys) {
		compareMagnitude:
		while (true) {
			var _v0 = _Utils_Tuple2(xs, ys);
			if (!_v0.a.b) {
				if (!_v0.b.b) {
					return A2($elm$core$Basics$compare, x, y);
				} else {
					return 0;
				}
			} else {
				if (!_v0.b.b) {
					return 2;
				} else {
					var _v1 = _v0.a;
					var x_ = _v1.a;
					var xss = _v1.b;
					var _v2 = _v0.b;
					var y_ = _v2.a;
					var yss = _v2.b;
					if (_Utils_eq(x_, y_)) {
						var $temp$x = x,
							$temp$y = y,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					} else {
						var $temp$x = x_,
							$temp$y = y_,
							$temp$xs = xss,
							$temp$ys = yss;
						x = $temp$x;
						y = $temp$y;
						xs = $temp$xs;
						ys = $temp$ys;
						continue compareMagnitude;
					}
				}
			}
		}
	});
var $cmditch$elm_bigint$BigInt$orderNegate = function (x) {
	switch (x) {
		case 0:
			return 2;
		case 1:
			return 1;
		default:
			return 0;
	}
};
var $cmditch$elm_bigint$BigInt$compare = F2(
	function (int1, int2) {
		var _v0 = _Utils_Tuple2(int1, int2);
		switch (_v0.a.$) {
			case 0:
				if (!_v0.b.$) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2);
				} else {
					return 2;
				}
			case 1:
				if (_v0.b.$ === 1) {
					var mag1 = _v0.a.a;
					var mag2 = _v0.b.a;
					return $cmditch$elm_bigint$BigInt$orderNegate(
						A4($cmditch$elm_bigint$BigInt$compareMagnitude, 0, 0, mag1, mag2));
				} else {
					return 0;
				}
			default:
				switch (_v0.b.$) {
					case 0:
						var _v1 = _v0.a;
						return 0;
					case 2:
						var _v2 = _v0.a;
						var _v3 = _v0.b;
						return 1;
					default:
						var _v4 = _v0.a;
						return 2;
				}
		}
	});
var $cmditch$elm_bigint$BigInt$lt = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$compare, x, y);
	});
var $cmditch$elm_bigint$BigInt$gte = F2(
	function (x, y) {
		return !A2($cmditch$elm_bigint$BigInt$lt, x, y);
	});
var $prikhi$decimal$Decimal$insert_decimal_period = F2(
	function (pos, s) {
		var extra_zeros = pos - $elm$core$String$length(s);
		var padded_s = (extra_zeros >= 0) ? _Utils_ap(
			A2($elm$core$String$repeat, extra_zeros + 1, '0'),
			s) : s;
		var before = A2($elm$core$String$dropRight, pos, padded_s);
		var after = A2($elm$core$String$right, pos, padded_s);
		return before + ('.' + after);
	});
var $cmditch$elm_bigint$BigInt$negate = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return $cmditch$elm_bigint$BigInt$Zer;
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Neg(mag);
		default:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$Pos(mag);
	}
};
var $cmditch$elm_bigint$BigInt$fillZeroes = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$String$padLeft, $cmditch$elm_bigint$Constants$maxDigitMagnitude, '0'),
	$elm$core$String$fromInt);
var $cmditch$elm_bigint$BigInt$revMagnitudeToString = function (_v0) {
	var digits = _v0;
	var _v1 = $elm$core$List$reverse(digits);
	if (!_v1.b) {
		return '0';
	} else {
		var x = _v1.a;
		var xs = _v1.b;
		return $elm$core$String$concat(
			A2(
				$elm$core$List$cons,
				$elm$core$String$fromInt(x),
				A2($elm$core$List$map, $cmditch$elm_bigint$BigInt$fillZeroes, xs)));
	}
};
var $cmditch$elm_bigint$BigInt$toString = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return '0';
		case 0:
			var mag = bigInt.a;
			return $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
		default:
			var mag = bigInt.a;
			return '-' + $cmditch$elm_bigint$BigInt$revMagnitudeToString(mag);
	}
};
var $prikhi$decimal$Decimal$toString = function (_v0) {
	var m = _v0.a;
	var e = _v0.b;
	var sign = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? '' : '-';
	var add_zeros = function (n) {
		return A2($elm$core$String$repeat, n, '0');
	};
	var abs_m = A2(
		$cmditch$elm_bigint$BigInt$gte,
		m,
		$cmditch$elm_bigint$BigInt$fromInt(0)) ? m : $cmditch$elm_bigint$BigInt$negate(m);
	var s = $cmditch$elm_bigint$BigInt$toString(abs_m);
	var _v1 = A2($elm$core$Basics$compare, e, 0);
	switch (_v1) {
		case 1:
			return _Utils_ap(sign, s);
		case 2:
			return _Utils_ap(
				sign,
				_Utils_ap(
					s,
					add_zeros(e)));
		default:
			return _Utils_ap(
				sign,
				A2($prikhi$decimal$Decimal$insert_decimal_period, 0 - e, s));
	}
};
var $danyx23$elm_uuid$Uuid$toString = function (_v0) {
	var internalString = _v0;
	return internalString;
};
var $elm$core$Basics$round = _Basics_round;
var $elm$core$String$toFloat = _String_toFloat;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs = A2(
	$elm$parser$Parser$andThen,
	function (str) {
		if ($elm$core$String$length(str) <= 9) {
			var _v0 = $elm$core$String$toFloat('0.' + str);
			if (!_v0.$) {
				var floatVal = _v0.a;
				return $elm$parser$Parser$succeed(
					$elm$core$Basics$round(floatVal * 1000));
			} else {
				return $elm$parser$Parser$problem('Invalid float: \"' + (str + '\"'));
			}
		} else {
			return $elm$parser$Parser$problem(
				'Expected at most 9 digits, but got ' + $elm$core$String$fromInt(
					$elm$core$String$length(str)));
		}
	},
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompWhile($elm$core$Char$isDigit)));
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts = F6(
	function (monthYearDayMs, hour, minute, second, ms, utcOffsetMinutes) {
		return $elm$time$Time$millisToPosix((((monthYearDayMs + (((hour * 60) * 60) * 1000)) + (((minute - utcOffsetMinutes) * 60) * 1000)) + (second * 1000)) + ms);
	});
var $elm$parser$Parser$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$core$String$append = _String_append;
var $elm$parser$Parser$Advanced$loopHelp = F4(
	function (p, state, callback, s0) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var parse = _v0;
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p1 = _v1.a;
				var step = _v1.b;
				var s1 = _v1.c;
				if (!step.$) {
					var newState = step.a;
					var $temp$p = p || p1,
						$temp$state = newState,
						$temp$callback = callback,
						$temp$s0 = s1;
					p = $temp$p;
					state = $temp$state;
					callback = $temp$callback;
					s0 = $temp$s0;
					continue loopHelp;
				} else {
					var result = step.a;
					return A3($elm$parser$Parser$Advanced$Good, p || p1, result, s1);
				}
			} else {
				var p1 = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p || p1, x);
			}
		}
	});
var $elm$parser$Parser$Advanced$loop = F2(
	function (state, callback) {
		return function (s) {
			return A4($elm$parser$Parser$Advanced$loopHelp, false, state, callback, s);
		};
	});
var $elm$parser$Parser$Advanced$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$toAdvancedStep = function (step) {
	if (!step.$) {
		var s = step.a;
		return $elm$parser$Parser$Advanced$Loop(s);
	} else {
		var a = step.a;
		return $elm$parser$Parser$Advanced$Done(a);
	}
};
var $elm$parser$Parser$loop = F2(
	function (state, callback) {
		return A2(
			$elm$parser$Parser$Advanced$loop,
			state,
			function (s) {
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$toAdvancedStep,
					callback(s));
			});
	});
var $rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt = function (quantity) {
	var helper = function (str) {
		if (_Utils_eq(
			$elm$core$String$length(str),
			quantity)) {
			var _v0 = $elm$core$String$toInt(str);
			if (!_v0.$) {
				var intVal = _v0.a;
				return A2(
					$elm$parser$Parser$map,
					$elm$parser$Parser$Done,
					$elm$parser$Parser$succeed(intVal));
			} else {
				return $elm$parser$Parser$problem('Invalid integer: \"' + (str + '\"'));
			}
		} else {
			return A2(
				$elm$parser$Parser$map,
				function (nextChar) {
					return $elm$parser$Parser$Loop(
						A2($elm$core$String$append, str, nextChar));
				},
				$elm$parser$Parser$getChompedString(
					$elm$parser$Parser$chompIf($elm$core$Char$isDigit)));
		}
	};
	return A2($elm$parser$Parser$loop, '', helper);
};
var $elm$parser$Parser$ExpectingSymbol = function (a) {
	return {$: 8, a: a};
};
var $elm$parser$Parser$Advanced$symbol = $elm$parser$Parser$Advanced$token;
var $elm$parser$Parser$symbol = function (str) {
	return $elm$parser$Parser$Advanced$symbol(
		A2(
			$elm$parser$Parser$Advanced$Token,
			str,
			$elm$parser$Parser$ExpectingSymbol(str)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear = 1970;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay = function (day) {
	return $elm$parser$Parser$problem(
		'Invalid day: ' + $elm$core$String$fromInt(day));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear = function (year) {
	return (!A2($elm$core$Basics$modBy, 4, year)) && ((!(!A2($elm$core$Basics$modBy, 100, year))) || (!A2($elm$core$Basics$modBy, 400, year)));
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore = function (y1) {
	var y = y1 - 1;
	return (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay = 86400000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear = 31536000000;
var $rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay = function (_v0) {
	var year = _v0.a;
	var month = _v0.b;
	var dayInMonth = _v0.c;
	if (dayInMonth < 0) {
		return $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth);
	} else {
		var succeedWith = function (extraMs) {
			var yearMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerYear * (year - $rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear);
			var days = ((month < 3) || (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year))) ? (dayInMonth - 1) : dayInMonth;
			var dayMs = $rtfeldman$elm_iso8601_date_strings$Iso8601$msPerDay * (days + ($rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore(year) - $rtfeldman$elm_iso8601_date_strings$Iso8601$leapYearsBefore($rtfeldman$elm_iso8601_date_strings$Iso8601$epochYear)));
			return $elm$parser$Parser$succeed((extraMs + yearMs) + dayMs);
		};
		switch (month) {
			case 1:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(0);
			case 2:
				return ((dayInMonth > 29) || ((dayInMonth === 29) && (!$rtfeldman$elm_iso8601_date_strings$Iso8601$isLeapYear(year)))) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(2678400000);
			case 3:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(5097600000);
			case 4:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(7776000000);
			case 5:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(10368000000);
			case 6:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(13046400000);
			case 7:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(15638400000);
			case 8:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(18316800000);
			case 9:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(20995200000);
			case 10:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(23587200000);
			case 11:
				return (dayInMonth > 30) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(26265600000);
			case 12:
				return (dayInMonth > 31) ? $rtfeldman$elm_iso8601_date_strings$Iso8601$invalidDay(dayInMonth) : succeedWith(28857600000);
			default:
				return $elm$parser$Parser$problem(
					'Invalid month: \"' + ($elm$core$String$fromInt(month) + '\"'));
		}
	}
};
var $rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs = A2(
	$elm$parser$Parser$andThen,
	$rtfeldman$elm_iso8601_date_strings$Iso8601$yearMonthDay,
	A2(
		$elm$parser$Parser$keeper,
		A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F3(
						function (year, month, day) {
							return _Utils_Tuple3(year, month, day);
						})),
				$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(4)),
			$elm$parser$Parser$oneOf(
				_List_fromArray(
					[
						A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed($elm$core$Basics$identity),
							$elm$parser$Parser$symbol('-')),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
					]))),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$ignorer,
						$elm$parser$Parser$succeed($elm$core$Basics$identity),
						$elm$parser$Parser$symbol('-')),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
				]))));
var $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes = function () {
	var utcOffsetMinutesFromParts = F3(
		function (multiplier, hours, minutes) {
			return (multiplier * (hours * 60)) + minutes;
		});
	return A2(
		$elm$parser$Parser$keeper,
		$elm$parser$Parser$succeed($elm$core$Basics$identity),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$map,
					function (_v0) {
						return 0;
					},
					$elm$parser$Parser$symbol('Z')),
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							$elm$parser$Parser$succeed(utcOffsetMinutesFromParts),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$map,
										function (_v1) {
											return 1;
										},
										$elm$parser$Parser$symbol('+')),
										A2(
										$elm$parser$Parser$map,
										function (_v2) {
											return -1;
										},
										$elm$parser$Parser$symbol('-'))
									]))),
						$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
					$elm$parser$Parser$oneOf(
						_List_fromArray(
							[
								A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$ignorer,
									$elm$parser$Parser$succeed($elm$core$Basics$identity),
									$elm$parser$Parser$symbol(':')),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
								$elm$parser$Parser$succeed(0)
							]))),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(0),
					$elm$parser$Parser$end)
				])));
}();
var $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601 = A2(
	$elm$parser$Parser$andThen,
	function (datePart) {
		return $elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					A2(
					$elm$parser$Parser$keeper,
					A2(
						$elm$parser$Parser$keeper,
						A2(
							$elm$parser$Parser$keeper,
							A2(
								$elm$parser$Parser$keeper,
								A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed(
											$rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts(datePart)),
										$elm$parser$Parser$symbol('T')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
								$elm$parser$Parser$oneOf(
									_List_fromArray(
										[
											A2(
											$elm$parser$Parser$keeper,
											A2(
												$elm$parser$Parser$ignorer,
												$elm$parser$Parser$succeed($elm$core$Basics$identity),
												$elm$parser$Parser$symbol(':')),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
											$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)
										]))),
							$elm$parser$Parser$oneOf(
								_List_fromArray(
									[
										A2(
										$elm$parser$Parser$keeper,
										A2(
											$elm$parser$Parser$ignorer,
											$elm$parser$Parser$succeed($elm$core$Basics$identity),
											$elm$parser$Parser$symbol(':')),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2)),
										$rtfeldman$elm_iso8601_date_strings$Iso8601$paddedInt(2),
										$elm$parser$Parser$succeed(0)
									]))),
						$elm$parser$Parser$oneOf(
							_List_fromArray(
								[
									A2(
									$elm$parser$Parser$keeper,
									A2(
										$elm$parser$Parser$ignorer,
										$elm$parser$Parser$succeed($elm$core$Basics$identity),
										$elm$parser$Parser$symbol('.')),
									$rtfeldman$elm_iso8601_date_strings$Iso8601$fractionsOfASecondInMs),
									$elm$parser$Parser$succeed(0)
								]))),
					A2($elm$parser$Parser$ignorer, $rtfeldman$elm_iso8601_date_strings$Iso8601$utcOffsetInMinutes, $elm$parser$Parser$end)),
					A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$succeed(
						A6($rtfeldman$elm_iso8601_date_strings$Iso8601$fromParts, datePart, 0, 0, 0, 0, 0)),
					$elm$parser$Parser$end)
				]));
	},
	$rtfeldman$elm_iso8601_date_strings$Iso8601$monthYearDayInMs);
var $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime = function (str) {
	return A2($elm$parser$Parser$run, $rtfeldman$elm_iso8601_date_strings$Iso8601$iso8601, str);
};
var $author$project$ScalarCodecs$codecs = function () {
	var resultDecoder = function (res) {
		if (!res.$) {
			var date = res.a;
			return $elm$json$Json$Decode$succeed(date);
		} else {
			var err = res.a;
			return $elm$json$Json$Decode$fail(
				A2(
					$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
					'wrong format from server: {}',
					_List_fromArray(
						[err])));
		}
	};
	var maybeDecoder = function (res) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$json$Json$Decode$fail('wrong uuid format'),
			A2($elm$core$Maybe$map, $elm$json$Json$Decode$succeed, res));
	};
	return $author$project$Gql$Platform$Scalar$defineCodecs(
		{
			af: {
				ah: A2(
					$elm$json$Json$Decode$andThen,
					function (maybeDateTime) {
						if (!maybeDateTime.$) {
							var dateTime = maybeDateTime.a;
							return $elm$json$Json$Decode$succeed(dateTime);
						} else {
							return $elm$json$Json$Decode$fail('Cannot be converted to POSIX time.');
						}
					},
					A2(
						$elm$json$Json$Decode$map,
						A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$toTime, $elm$core$Result$toMaybe),
						$elm$json$Json$Decode$string)),
				aI: A2($elm$core$Basics$composeR, $rtfeldman$elm_iso8601_date_strings$Iso8601$fromTime, $elm$json$Json$Encode$string)
			},
			cg: {
				ah: A2(
					$elm$json$Json$Decode$andThen,
					function (maybeDecimal) {
						return A2(
							$elm$core$Maybe$withDefault,
							$elm$json$Json$Decode$fail('Cannot be converted to Decimal'),
							A2($elm$core$Maybe$map, $elm$json$Json$Decode$succeed, maybeDecimal));
					},
					A2($elm$json$Json$Decode$map, $prikhi$decimal$Decimal$fromString, $elm$json$Json$Decode$string)),
				aI: A2($elm$core$Basics$composeR, $prikhi$decimal$Decimal$toString, $elm$json$Json$Encode$string)
			},
			h4: $author$project$Gql$Platform$Scalar$defaultCodecs.h4,
			aF: {
				ah: A2(
					$elm$json$Json$Decode$andThen,
					resultDecoder,
					A2($elm$json$Json$Decode$map, $justinmimbs$date$Date$fromIsoString, $elm$json$Json$Decode$string)),
				aI: A2($elm$core$Basics$composeR, $justinmimbs$date$Date$toIsoString, $elm$json$Json$Encode$string)
			},
			I: {
				ah: A2(
					$elm$json$Json$Decode$andThen,
					maybeDecoder,
					A2($elm$json$Json$Decode$map, $danyx23$elm_uuid$Uuid$fromString, $elm$json$Json$Decode$string)),
				aI: A2($elm$core$Basics$composeR, $danyx23$elm_uuid$Uuid$toString, $elm$json$Json$Encode$string)
			},
			h5: $author$project$Gql$Platform$Scalar$defaultCodecs.h5
		});
}();
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required = F3(
	function (fieldName, raw, encode) {
		return A2(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$Argument,
			fieldName,
			encode(raw));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson = function (jsonValue) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(jsonValue);
};
var $author$project$Gql$Platform$Scalar$unwrapEncoder = F2(
	function (getter, _v0) {
		var unwrappedCodecs = _v0;
		return A2(
			$elm$core$Basics$composeR,
			getter(unwrappedCodecs).aI,
			$dillonkearns$elm_graphql$Graphql$Internal$Encode$fromJson);
	});
var $author$project$Gql$Platform$Mutation$amendOfferCoverageEffectiveDate = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'amendOfferCoverageEffectiveDate',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'offerId',
					requiredArgs____.l7,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)),
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'coverageEffectiveDate',
					requiredArgs____.kK,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.aF;
						},
						$author$project$ScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Common$Gql$Offer$amendOfferCoverageEffectiveDateArguments = F2(
	function (offerId, coverageEffectiveDate) {
		return {kK: coverageEffectiveDate, l7: offerId};
	});
var $author$project$Common$AmendOfferCoverageEffectiveDateResult$CurrentDateStillValid = 2;
var $author$project$Common$AmendOfferCoverageEffectiveDateResult$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$AmendOfferCoverageEffectiveDateResult$OutOfFutureRange = 1;
var $author$project$Common$AmendOfferCoverageEffectiveDateResult$PastCoverageEffectiveDate = 0;
var $author$project$Common$AmendOfferCoverageEffectiveDateResult$Success = {$: 0};
var $dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment = F2(
	function (fragmentTypeName, _v0) {
		var fields = _v0.a;
		var decoder = _v0.b;
		return A3($dillonkearns$elm_graphql$Graphql$SelectionSet$FragmentSelectionSet, fragmentTypeName, fields, decoder);
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage = F2(
	function (typeSpecificSelections, typeName) {
		return A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'Unhandled type `{0}` in exhaustive fragment handling. The following types had handlers registered to handle them: [{1}]. This happens if you are parsing either a Union or Interface. Do you need to rerun the `@dillonkearns/elm-graphql` command line tool?',
			_List_fromArray(
				[
					typeName,
					A2(
					$elm$core$String$join,
					', ',
					A2(
						$elm$core$List$map,
						function (_v0) {
							var fragmentType = _v0.a;
							var fields = _v0.b;
							var decoder = _v0.c;
							return fragmentType;
						},
						typeSpecificSelections))
				]));
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection = function (typeSpecificSelections) {
	var selections = A2(
		$elm$core$List$map,
		function (_v1) {
			var typeName = _v1.a;
			var fields = _v1.b;
			var decoder = _v1.c;
			return A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$composite, '...on ' + typeName, _List_Nil, fields);
		},
		typeSpecificSelections);
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
		A2($elm$core$List$cons, $dillonkearns$elm_graphql$Graphql$RawField$typename, selections),
		A2(
			$elm$json$Json$Decode$andThen,
			function (typeName) {
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$json$Json$Decode$fail(
						A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFailureMessage, typeSpecificSelections, typeName)),
					A2(
						$elm$core$Dict$get,
						typeName,
						$elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (_v0) {
									var thisTypeName = _v0.a;
									var fields = _v0.b;
									var decoder = _v0.c;
									return _Utils_Tuple2(thisTypeName, decoder);
								},
								typeSpecificSelections))));
			},
			A2(
				$elm$json$Json$Decode$field,
				$dillonkearns$elm_graphql$Graphql$Document$Field$hashedAliasName($dillonkearns$elm_graphql$Graphql$RawField$typename),
				$elm$json$Json$Decode$string)));
};
var $author$project$Gql$Platform$Union$AmendOfferCoverageEffectiveDateResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'AmendOfferCoverageEffectiveDateOk', selections____.l9),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PastCoverageEffectiveDateErr', selections____.mC),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'OutOfFutureRangeErr', selections____.mB),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'CurrentDateStillValidErr', selections____.mv)
			]));
};
var $author$project$Common$Gql$Offer$amendOfferCoverageEffectiveDateSelectionSet = $author$project$Gql$Platform$Union$AmendOfferCoverageEffectiveDateResponse$fragments(
	{
		l9: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$AmendOfferCoverageEffectiveDateResult$Success),
		mv: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(
			$author$project$Common$AmendOfferCoverageEffectiveDateResult$Failure(2)),
		mB: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(
			$author$project$Common$AmendOfferCoverageEffectiveDateResult$Failure(1)),
		mC: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed(
			$author$project$Common$AmendOfferCoverageEffectiveDateResult$Failure(0))
	});
var $author$project$Gql$Platform$Query$ClaimsHistoryRequiredArguments = function (quoteId) {
	return {m5: quoteId};
};
var $author$project$Common$Gql$ClaimHistory$claimHistoryByQuoteIdRequiredArguments = function (quoteId) {
	return $author$project$Gql$Platform$Query$ClaimsHistoryRequiredArguments(quoteId);
};
var $author$project$Common$ClaimHistory$ClaimHistory = F6(
	function (id, sincoInfo, sincoNumber, claimDeclaredWithFaultByCustomer, claimDeclaredBySinco, actionNeeded) {
		return {eZ: actionNeeded, h2: claimDeclaredBySinco, fh: claimDeclaredWithFaultByCustomer, iO: id, jR: sincoInfo, jS: sincoNumber};
	});
var $author$project$Gql$Platform$Union$SincoReturnCode$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'KnownSincoReturnCode', selections____.mw),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'UnknownSincoReturnCode', selections____.mP)
			]));
};
var $author$project$Gql$Platform$Enum$SincoCode$Code00 = 0;
var $author$project$Gql$Platform$Enum$SincoCode$Code01 = 1;
var $author$project$Gql$Platform$Enum$SincoCode$Code05 = 2;
var $author$project$Gql$Platform$Enum$SincoCode$Code08 = 3;
var $author$project$Gql$Platform$Enum$SincoCode$Code11 = 4;
var $author$project$Gql$Platform$Enum$SincoCode$Code12 = 5;
var $author$project$Gql$Platform$Enum$SincoCode$Code21 = 6;
var $author$project$Gql$Platform$Enum$SincoCode$Code31 = 7;
var $author$project$Gql$Platform$Enum$SincoCode$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'CODE_00':
				return $elm$json$Json$Decode$succeed(0);
			case 'CODE_01':
				return $elm$json$Json$Decode$succeed(1);
			case 'CODE_05':
				return $elm$json$Json$Decode$succeed(2);
			case 'CODE_08':
				return $elm$json$Json$Decode$succeed(3);
			case 'CODE_11':
				return $elm$json$Json$Decode$succeed(4);
			case 'CODE_12':
				return $elm$json$Json$Decode$succeed(5);
			case 'CODE_21':
				return $elm$json$Json$Decode$succeed(6);
			case 'CODE_31':
				return $elm$json$Json$Decode$succeed(7);
			default:
				return $elm$json$Json$Decode$fail('Invalid SincoCode type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$KnownSincoReturnCode$code = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.SincoCode.SincoCode', 'code', _List_Nil, $author$project$Gql$Platform$Enum$SincoCode$decoder);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$map = F2(
	function (mapFunction, _v0) {
		var selectionFields = _v0.a;
		var selectionDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			selectionFields,
			A2($elm$json$Json$Decode$map, mapFunction, selectionDecoder));
	});
var $author$project$Gql$Platform$Enum$SincoCode$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'CODE_00';
		case 1:
			return 'CODE_01';
		case 2:
			return 'CODE_05';
		case 3:
			return 'CODE_08';
		case 4:
			return 'CODE_11';
		case 5:
			return 'CODE_12';
		case 6:
			return 'CODE_21';
		default:
			return 'CODE_31';
	}
};
var $author$project$Common$Gql$ClaimHistory$knownSincoReturnCodeSelectionSet = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Gql$Platform$Enum$SincoCode$toString, $author$project$Gql$Platform$Object$KnownSincoReturnCode$code);
var $author$project$Gql$Platform$Object$UnknownSincoReturnCode$unknownCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'unknownCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$ClaimHistory$unknownSincoReturnCodeSelectionSet = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $elm$core$Basics$identity, $author$project$Gql$Platform$Object$UnknownSincoReturnCode$unknownCode);
var $author$project$Common$Gql$ClaimHistory$decodeSincoReturnCode = $author$project$Gql$Platform$Union$SincoReturnCode$fragments(
	{mw: $author$project$Common$Gql$ClaimHistory$knownSincoReturnCodeSelectionSet, mP: $author$project$Common$Gql$ClaimHistory$unknownSincoReturnCodeSelectionSet});
var $author$project$Common$Count$Unknown = {$: 2};
var $author$project$Common$Count$default = $author$project$Common$Count$Unknown;
var $author$project$Common$PricingAction$PricingAction = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$PricingAction$default = $author$project$Common$PricingAction$PricingAction('');
var $author$project$Gql$Platform$Object$BackofficeClaimsHistory$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded = F2(
	function (constant, _v0) {
		var objectFields = _v0.a;
		var objectDecoder = _v0.b;
		return A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$SelectionSet,
			objectFields,
			A3(
				$elm$json$Json$Decode$map2,
				$elm$core$Basics$apR,
				$elm$json$Json$Decode$succeed(constant),
				objectDecoder));
	});
var $author$project$Gql$Platform$Object$BackofficeClaimsHistory$sincoNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'sincoNumber', _List_Nil, $elm$json$Json$Decode$string);
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$nullable = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder)
			]));
};
var $author$project$Gql$Platform$Object$BackofficeClaimsHistory$sincoReturnCode = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'sincoReturnCode',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$BackofficeClaimsHistory$totalClaimsFound = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'totalClaimsFound',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$Count$count = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'count', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Common$Gql$ClaimHistory$totalClaimsFoundSelectionSet = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $elm$core$Basics$identity, $author$project$Gql$Platform$Object$Count$count);
var $dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault = function (_default) {
	return $dillonkearns$elm_graphql$Graphql$SelectionSet$map(
		$elm$core$Maybe$withDefault(_default));
};
var $author$project$Common$Gql$ClaimHistory$claimHistorySelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$author$project$Common$PricingAction$default,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeClaimsHistory$totalClaimsFound($author$project$Common$Gql$ClaimHistory$totalClaimsFoundSelectionSet),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			$author$project$Common$Count$default,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeClaimsHistory$sincoNumber,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
						'',
						$author$project$Gql$Platform$Object$BackofficeClaimsHistory$sincoReturnCode($author$project$Common$Gql$ClaimHistory$decodeSincoReturnCode)),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Platform$Object$BackofficeClaimsHistory$documentId,
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$ClaimHistory$ClaimHistory)))))));
var $author$project$Gql$Platform$Query$claimsHistory = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'claimsHistory',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'quoteId',
					requiredArgs____.m5,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent = {$: 1};
var $author$project$Gql$Platform$Query$CustomerIdRequiredArguments = function (request) {
	return {ev: request};
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$Gql$CustomerId$buildCustomerIdQueryArg = function (args) {
	if (!args.$) {
		var policyId = args.a;
		return $author$project$Gql$Platform$Query$CustomerIdRequiredArguments(
			{
				iz: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				iA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					{
						m_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(policyId)
					})
			});
	} else {
		var offerId = args.a;
		return $author$project$Gql$Platform$Query$CustomerIdRequiredArguments(
			{
				iz: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					{
						kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
						l7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(offerId)
					}),
				iA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
			});
	}
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object = function (a) {
	return {$: 3, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject = function (maybeValues) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Object(
		A2(
			$elm$core$List$filterMap,
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return A2(
					$elm$core$Maybe$andThen,
					function (actualValue) {
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(key, actualValue));
					},
					value);
			},
			maybeValues));
};
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$null = $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json($elm$json$Json$Encode$null);
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$optional = F2(
	function (optionalValue, toValue) {
		switch (optionalValue.$) {
			case 0:
				var value = optionalValue.a;
				return $elm$core$Maybe$Just(
					toValue(value));
			case 1:
				return $elm$core$Maybe$Nothing;
			default:
				return $elm$core$Maybe$Just($dillonkearns$elm_graphql$Graphql$Internal$Encode$null);
		}
	});
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$string = function (value) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$Json(
		$elm$json$Json$Encode$string(value));
};
var $author$project$Gql$Platform$InputObject$encodeOfferIdentifier = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'customerFacingId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kN, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'offerId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.l7,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)))
			]));
};
var $author$project$Gql$Platform$InputObject$encodePolicyIdentifier = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'policyId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m_,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeCustomerIdRequest = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'forOffer',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.iz, $author$project$Gql$Platform$InputObject$encodeOfferIdentifier)),
				_Utils_Tuple2(
				'forPolicy',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.iA, $author$project$Gql$Platform$InputObject$encodePolicyIdentifier))
			]));
};
var $author$project$Gql$Platform$Query$customerId = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'(Maybe String)',
		'customerId',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'request', requiredArgs____.ev, $author$project$Gql$Platform$InputObject$encodeCustomerIdRequest)
			]),
		$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
};
var $author$project$Common$Gql$CustomerId$getCustomerId = function (customerIdQueryArg) {
	return $author$project$Gql$Platform$Query$customerId(
		$author$project$Common$Gql$CustomerId$buildCustomerIdQueryArg(customerIdQueryArg));
};
var $author$project$Gql$Platform$Query$GetPreviousPaymentMethodRequiredArguments = function (forA) {
	return {iy: forA};
};
var $author$project$Common$Gql$PaymentMethod$buildPaymentMethodArg = function (args) {
	if (!args.$) {
		var policyId = args.a;
		return $author$project$Gql$Platform$Query$GetPreviousPaymentMethodRequiredArguments(
			{
				iA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(policyId),
				iB: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
			});
	} else {
		var offerId = args.a;
		return $author$project$Gql$Platform$Query$GetPreviousPaymentMethodRequiredArguments(
			{
				iA: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				iB: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					{
						kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
						l7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(offerId)
					})
			});
	}
};
var $author$project$Gql$Platform$Union$PaymentMethod$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'CreditCard', selections____.mu)
			]));
};
var $author$project$Common$PaymentMethod$PaymentMethod = F4(
	function (brand, cardNumber, cardValidity, expirationDate) {
		return {a8: brand, ce: cardNumber, dv: cardValidity, cq: expirationDate};
	});
var $author$project$Gql$Platform$Object$CreditCard$brand = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'brand', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$CreditCard$cardNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'cardNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$PaymentMethod$Expired = 1;
var $author$project$Common$PaymentMethod$Valid = 0;
var $author$project$Common$Gql$PaymentMethod$decodeValidity = function (cardValidity) {
	if (cardValidity === 1) {
		return 1;
	} else {
		return 0;
	}
};
var $author$project$Gql$Platform$Object$CreditCard$expirationDate = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'expirationDate', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$PaymentMethod$Maestro = {$: 2};
var $author$project$Common$PaymentMethod$MasterCard = {$: 1};
var $author$project$Common$PaymentMethod$Other = function (a) {
	return {$: 3, a: a};
};
var $author$project$Common$PaymentMethod$Visa = {$: 0};
var $author$project$Gql$Platform$Union$Brand$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Visa', selections____.mS),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Mastercard', selections____.my),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Maestro', selections____.mx),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'OtherCard', selections____.mA)
			]));
};
var $author$project$Gql$Platform$Object$OtherCard$name = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'name', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$PaymentMethod$onBrandSelectionSet = $author$project$Gql$Platform$Union$Brand$fragments(
	{
		mx: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$PaymentMethod$Maestro),
		my: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$PaymentMethod$MasterCard),
		mA: A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$PaymentMethod$Other, $author$project$Gql$Platform$Object$OtherCard$name),
		mS: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$PaymentMethod$Visa)
	});
var $author$project$Gql$Platform$Enum$CreditCardValidity$Expired = 1;
var $author$project$Gql$Platform$Enum$CreditCardValidity$Valid = 0;
var $author$project$Gql$Platform$Enum$CreditCardValidity$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'VALID':
				return $elm$json$Json$Decode$succeed(0);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid CreditCardValidity type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$CreditCard$validity = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.CreditCardValidity.CreditCardValidity', 'validity', _List_Nil, $author$project$Gql$Platform$Enum$CreditCardValidity$decoder);
var $author$project$Common$Gql$PaymentMethod$onCreditCardSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$CreditCard$expirationDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$PaymentMethod$decodeValidity, $author$project$Gql$Platform$Object$CreditCard$validity),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$CreditCard$cardNumber,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$CreditCard$brand($author$project$Common$Gql$PaymentMethod$onBrandSelectionSet),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$PaymentMethod$PaymentMethod)))));
var $author$project$Common$Gql$PaymentMethod$getPaymentMethodSelectionSet = $author$project$Gql$Platform$Union$PaymentMethod$fragments(
	{mu: $author$project$Common$Gql$PaymentMethod$onCreditCardSelectionSet});
var $author$project$Gql$Platform$InputObject$encodePreviousPaymentMethodRequest = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'forPolicy',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.iA,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs))),
				_Utils_Tuple2(
				'forRenewalOffer',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.iB, $author$project$Gql$Platform$InputObject$encodeOfferIdentifier))
			]));
};
var $author$project$Gql$Platform$Query$getPreviousPaymentMethod = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'getPreviousPaymentMethod',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'forA', requiredArgs____.iy, $author$project$Gql$Platform$InputObject$encodePreviousPaymentMethodRequest)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Common$Gql$PaymentMethod$getPaymentMethod = function (paymentMethodArg) {
	return A2(
		$author$project$Gql$Platform$Query$getPreviousPaymentMethod,
		$author$project$Common$Gql$PaymentMethod$buildPaymentMethodArg(paymentMethodArg),
		$author$project$Common$Gql$PaymentMethod$getPaymentMethodSelectionSet);
};
var $dillonkearns$elm_graphql$Graphql$Http$Mutation = function (a) {
	return {$: 1, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Http$mutationRequest = F2(
	function (baseUrl, mutationSelectionSet) {
		return {
			cc: baseUrl,
			kT: $dillonkearns$elm_graphql$Graphql$Http$Mutation(mutationSelectionSet),
			k_: $dillonkearns$elm_graphql$Graphql$Document$decoder(mutationSelectionSet),
			li: _List_Nil,
			aP: $elm$core$Maybe$Nothing,
			aT: _List_Nil,
			nC: $elm$core$Maybe$Nothing,
			a$: false
		};
	});
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$Gql$Platform$Query$OfferV2RequiredArguments = function (identifier) {
	return {bs: identifier};
};
var $author$project$Common$Gql$Offer$offerByCfidRequiredArguments = function (cfid) {
	return $author$project$Gql$Platform$Query$OfferV2RequiredArguments(
		{
			kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(cfid),
			l7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Common$Offer$BlockedType = 3;
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Scalar$unwrapCodecs = function (_v0) {
	var unwrappedCodecs = _v0;
	return unwrappedCodecs;
};
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$blockedQuoteId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'blockedQuoteId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Gql$Platform$Enum$BackofficeBlockedOfferStatus$Blocked = 0;
var $author$project$Gql$Platform$Enum$BackofficeBlockedOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (string === 'BLOCKED') {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			return $elm$json$Json$Decode$fail('Invalid BackofficeBlockedOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$blockedStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeBlockedOfferStatus.BackofficeBlockedOfferStatus', 'blockedStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeBlockedOfferStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Union$BackofficeCoverContinuitySummary$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'NoPreviousCoverage', selections____.mz),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'TercerosBasicoSelected', selections____.mN),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousExternalCoverageWithNoContinuityInformation', selections____.mH),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousExternalCoveragePendingContinuityInformation', selections____.mG),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousExternalCoverageAndSelectedTercerosAmpliado', selections____.mD),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousExternalCoverageAndSelectedTodoRiesgo', selections____.mE),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousPrimaBasicoCoverage', selections____.mJ),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousPrimaAmpliadoCoverage', selections____.mI),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousPrimaTodoRiesgoCoverage', selections____.mK),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'PreviousExternalCoverageGivenOnCheck24', selections____.mF)
			]));
};
var $author$project$Common$CoverContinuity$NoPreviousCoverage = {$: 0};
var $author$project$Common$CoverContinuity$newCoverContinuityWithNoPreviousPolicy = $author$project$Common$CoverContinuity$NoPreviousCoverage;
var $author$project$Common$Gql$CoverContinuity$onNoPreviousCoverageSelectionSet = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newCoverContinuityWithNoPreviousPolicy);
var $author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTercerosAmpliado$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTercerosAmpliado$hadLunas = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadLunas', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Common$CoverContinuity$ExternalCompany = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$CoverContinuity$ExternalCompanyInfo = $elm$core$Basics$identity;
var $author$project$Common$CoverContinuity$SelectedTercerosAmpliado = function (a) {
	return {$: 4, a: a};
};
var $author$project$Common$CoverContinuity$newPreviousExternalCoverContinuityTercerosAmpliado = F2(
	function (companyName, hadLunas) {
		return $author$project$Common$CoverContinuity$SelectedTercerosAmpliado(
			{
				T: $author$project$Common$CoverContinuity$ExternalCompany(companyName),
				as: hadLunas
			});
	});
var $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageAndSelectedTercerosAmpliadoSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTercerosAmpliado$hadLunas,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTercerosAmpliado$companyName,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPreviousExternalCoverContinuityTercerosAmpliado)));
var $author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$hadDanosPropios = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadDanosPropios', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$hadLunas = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadLunas', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Common$CoverContinuity$SelectedTodoRiesgo = function (a) {
	return {$: 5, a: a};
};
var $author$project$Common$CoverContinuity$newPreviousExternalCoverContinuityTodoRiesgo = F3(
	function (companyName, hadLunas, hadDanosProprios) {
		return $author$project$Common$CoverContinuity$SelectedTodoRiesgo(
			{
				T: $author$project$Common$CoverContinuity$ExternalCompany(companyName),
				dN: hadDanosProprios,
				as: hadLunas
			});
	});
var $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageAndSelectedTodoRiesgoSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$hadDanosPropios,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$hadLunas,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$PreviousExternalCoverageAndSelectedTodoRiesgo$companyName,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPreviousExternalCoverContinuityTodoRiesgo))));
var $author$project$Gql$Platform$Object$PreviousExternalCoverageGivenOnCheck24$bundleName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'bundleName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PreviousExternalCoverageGivenOnCheck24$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$CoverContinuity$PreviousCoverageGivenOnCheck24 = function (a) {
	return {$: 6, a: a};
};
var $author$project$Common$CoverContinuity$newPreviousExternalCoverageGivenOnCheck24 = F2(
	function (companyName, bundleName) {
		return $author$project$Common$CoverContinuity$PreviousCoverageGivenOnCheck24(
			{
				fe: bundleName,
				T: $author$project$Common$CoverContinuity$ExternalCompany(companyName)
			});
	});
var $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageGivenOnCheck24SelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousExternalCoverageGivenOnCheck24$bundleName,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PreviousExternalCoverageGivenOnCheck24$companyName,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPreviousExternalCoverageGivenOnCheck24)));
var $author$project$Gql$Platform$Object$PreviousExternalCoveragePendingContinuityInformation$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$CoverContinuity$PreviousExternalCoveragePendingCoverContinuityInformation = function (a) {
	return {$: 3, a: a};
};
var $author$project$Common$CoverContinuity$newCoverContinuityPendingInfo = A2($elm$core$Basics$composeL, $author$project$Common$CoverContinuity$PreviousExternalCoveragePendingCoverContinuityInformation, $elm$core$Basics$identity);
var $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoveragePendingContinuityInformationSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousExternalCoveragePendingContinuityInformation$companyName,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newCoverContinuityPendingInfo));
var $author$project$Gql$Platform$Object$PreviousExternalCoverageWithNoContinuityInformation$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$CoverContinuity$PreviousExternalCoverageWithNoCoverContinuityInformation = function (a) {
	return {$: 2, a: a};
};
var $author$project$Common$CoverContinuity$newPreviousExternalCoverageWithNoCoverContinuityInformation = A2($elm$core$Basics$composeL, $author$project$Common$CoverContinuity$PreviousExternalCoverageWithNoCoverContinuityInformation, $elm$core$Basics$identity);
var $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageWithNoContinuityInformationSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousExternalCoverageWithNoContinuityInformation$companyName,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPreviousExternalCoverageWithNoCoverContinuityInformation));
var $author$project$Gql$Platform$Object$PreviousPrimaAmpliadoCoverage$hadLunas = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadLunas', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Common$CoverContinuity$Prima = {$: 0};
var $author$project$Common$CoverContinuity$newPrimaCoverContinuityTercerosAmpliado = function (hadLunas) {
	return $author$project$Common$CoverContinuity$SelectedTercerosAmpliado(
		{T: $author$project$Common$CoverContinuity$Prima, as: hadLunas});
};
var $author$project$Common$Gql$CoverContinuity$onPreviousPrimaAmpliadoCoverageSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousPrimaAmpliadoCoverage$hadLunas,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPrimaCoverContinuityTercerosAmpliado));
var $author$project$Common$CoverContinuity$TercerosBasicoSelected = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$CoverContinuity$newPrimaTercerosBasicoSelected = $author$project$Common$CoverContinuity$TercerosBasicoSelected($author$project$Common$CoverContinuity$Prima);
var $author$project$Common$Gql$CoverContinuity$onPreviousPrimaBasicoCoverageSelectionSet = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPrimaTercerosBasicoSelected);
var $author$project$Gql$Platform$Object$PreviousPrimaTodoRiesgoCoverage$hadDanosPropios = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadDanosPropios', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Platform$Object$PreviousPrimaTodoRiesgoCoverage$hadLunas = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'hadLunas', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Common$CoverContinuity$newPrimaCoverContinuityTodoRiesgo = F2(
	function (hadLunas, hadDanosProprios) {
		return $author$project$Common$CoverContinuity$SelectedTodoRiesgo(
			{T: $author$project$Common$CoverContinuity$Prima, dN: hadDanosProprios, as: hadLunas});
	});
var $author$project$Common$Gql$CoverContinuity$onPreviousPrimaTodoRiesgoCoverageSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PreviousPrimaTodoRiesgoCoverage$hadDanosPropios,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PreviousPrimaTodoRiesgoCoverage$hadLunas,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPrimaCoverContinuityTodoRiesgo)));
var $author$project$Gql$Platform$Object$TercerosBasicoSelected$companyName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'companyName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$CoverContinuity$newPreviousExternalTercerosBasicoSelected = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$Basics$composeL, $author$project$Common$CoverContinuity$TercerosBasicoSelected, $author$project$Common$CoverContinuity$ExternalCompany),
	$elm$core$Basics$identity);
var $author$project$Common$Gql$CoverContinuity$onTercerosBasicoSelectedSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$TercerosBasicoSelected$companyName,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$CoverContinuity$newPreviousExternalTercerosBasicoSelected));
var $author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet = $author$project$Gql$Platform$Union$BackofficeCoverContinuitySummary$fragments(
	{mz: $author$project$Common$Gql$CoverContinuity$onNoPreviousCoverageSelectionSet, mD: $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageAndSelectedTercerosAmpliadoSelectionSet, mE: $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageAndSelectedTodoRiesgoSelectionSet, mF: $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageGivenOnCheck24SelectionSet, mG: $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoveragePendingContinuityInformationSelectionSet, mH: $author$project$Common$Gql$CoverContinuity$onPreviousExternalCoverageWithNoContinuityInformationSelectionSet, mI: $author$project$Common$Gql$CoverContinuity$onPreviousPrimaAmpliadoCoverageSelectionSet, mJ: $author$project$Common$Gql$CoverContinuity$onPreviousPrimaBasicoCoverageSelectionSet, mK: $author$project$Common$Gql$CoverContinuity$onPreviousPrimaTodoRiesgoCoverageSelectionSet, mN: $author$project$Common$Gql$CoverContinuity$onTercerosBasicoSelectedSelectionSet});
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$applicationId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'applicationId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$ApplicationId$ApplicationId = $elm$core$Basics$identity;
var $author$project$Common$ApplicationId$new = $elm$core$Basics$identity;
var $author$project$Common$Gql$Offer$decodeApplicationId = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$ApplicationId$new, $author$project$Gql$Platform$Object$BackofficeOfferDetails$applicationId);
var $author$project$Gql$Platform$Enum$BackofficeApplicationSource$Acierto = 2;
var $author$project$Gql$Platform$Enum$BackofficeApplicationSource$Check24 = 3;
var $author$project$Gql$Platform$Enum$BackofficeApplicationSource$Prima = 0;
var $author$project$Gql$Platform$Enum$BackofficeApplicationSource$Rastreator = 1;
var $author$project$Gql$Platform$Enum$BackofficeApplicationSource$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'PRIMA':
				return $elm$json$Json$Decode$succeed(0);
			case 'RASTREATOR':
				return $elm$json$Json$Decode$succeed(1);
			case 'ACIERTO':
				return $elm$json$Json$Decode$succeed(2);
			case 'CHECK_24':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeApplicationSource type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$applicationSource = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeApplicationSource.BackofficeApplicationSource', 'applicationSource', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeApplicationSource$decoder);
var $author$project$Common$Source$Acierto = 0;
var $author$project$Common$Source$Check24 = 1;
var $author$project$Common$Source$Prima = 2;
var $author$project$Common$Source$Rastreator = 3;
var $author$project$Common$Gql$ApplicationSource$decodeApplicationSource = function (applicationSource) {
	switch (applicationSource) {
		case 2:
			return 0;
		case 3:
			return 1;
		case 0:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Common$Gql$Offer$decodeApplicationSource = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$ApplicationSource$decodeApplicationSource, $author$project$Gql$Platform$Object$BackofficeOfferDetails$applicationSource);
var $author$project$Common$Offer$AmendStartDate = function (a) {
	return {$: 1, a: a};
};
var $author$project$Gql$Platform$Object$BackofficeAmendOfferStartDate$offerId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'offerId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$decodeAmendStartDateAction = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeAmendOfferStartDate$offerId,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$AmendStartDate));
var $author$project$Common$Offer$Complete = function (a) {
	return {$: 2, a: a};
};
var $author$project$Gql$Platform$Object$BackofficeCompleteOffer$offerId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'offerId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$decodeCompleteAction = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeCompleteOffer$offerId,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$Complete));
var $author$project$Common$Offer$Edit = function (a) {
	return {$: 3, a: a};
};
var $author$project$Gql$Platform$Object$BackofficeEditOffer$offerId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'offerId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$decodeEditAction = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeEditOffer$offerId,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$Edit));
var $author$project$Common$Offer$EditCover = function (a) {
	return {$: 4, a: a};
};
var $author$project$Gql$Platform$Object$BackofficeShowEditCoverLink$link = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'link', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$decodeEditCoverAction = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeShowEditCoverLink$link,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$EditCover));
var $author$project$Common$Offer$Purchase = function (a) {
	return {$: 0, a: a};
};
var $author$project$Gql$Platform$Object$BackofficePurchaseOffer$offerId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'offerId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$decodePurchaseAction = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficePurchaseOffer$offerId,
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$Purchase));
var $author$project$Gql$Platform$Union$BackofficeOfferAction$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficePurchaseOffer', selections____.mk),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeAmendOfferStartDate', selections____.mb),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeCompleteOffer', selections____.md),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeEditOffer', selections____.mf),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeShowEditCoverLink', selections____.mp)
			]));
};
var $author$project$Common$Gql$Offer$decodeAvailableActions = $author$project$Gql$Platform$Union$BackofficeOfferAction$fragments(
	{mb: $author$project$Common$Gql$Offer$decodeAmendStartDateAction, md: $author$project$Common$Gql$Offer$decodeCompleteAction, mf: $author$project$Common$Gql$Offer$decodeEditAction, mk: $author$project$Common$Gql$Offer$decodePurchaseAction, mp: $author$project$Common$Gql$Offer$decodeEditCoverAction});
var $author$project$Common$Offer$Blocked = 5;
var $author$project$Common$Gql$Offer$decodeBlockedOfferStatus = function (_v0) {
	return 5;
};
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$comebackLink = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'comebackLink',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$decodeComebackLink = $author$project$Gql$Platform$Object$BackofficeOfferDetails$comebackLink;
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$creationAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'creationAt',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Common$Gql$Offer$decodeCreationDate = $author$project$Gql$Platform$Object$BackofficeOfferDetails$creationAt;
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$customerFacingId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'customerFacingId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$decodeCustomerFacingId = $author$project$Gql$Platform$Object$BackofficeOfferDetails$customerFacingId;
var $author$project$Common$Person$AdditionalDriver = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$LessThanOne = 0;
var $author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$LessThanTwo = 1;
var $author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$MoreThanTwo = 3;
var $author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$OneToThree = 2;
var $author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'LESS_THAN_ONE':
				return $elm$json$Json$Decode$succeed(0);
			case 'LESS_THAN_TWO':
				return $elm$json$Json$Decode$succeed(1);
			case 'ONE_TO_THREE':
				return $elm$json$Json$Decode$succeed(2);
			case 'MORE_THAN_TWO':
				return $elm$json$Json$Decode$succeed(3);
			default:
				return $elm$json$Json$Decode$fail('Invalid AdditionalDriverDrivingLicenseYears type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$drivingLicenseYears = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.AdditionalDriverDrivingLicenseYears.AdditionalDriverDrivingLicenseYears)',
	'drivingLicenseYears',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$decoder));
var $author$project$Common$Person$PersonData = F4(
	function (firstName, lastName, documentId, dateOfBirth) {
		return {ag: dateOfBirth, ai: documentId, aq: firstName, at: lastName};
	});
var $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $justinmimbs$date$Date$fromRataDie = function (rd) {
	return rd;
};
var $author$project$Common$Locale$defaultDate = $justinmimbs$date$Date$fromRataDie(1);
var $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$documentId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'documentId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$firstName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'firstName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$lastName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'lastName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$fullAdditionalDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$Locale$defaultDate, $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$documentId),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$lastName),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$firstName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$AdditionalDriverDrivingLicenseYears$LessThanOne = 0;
var $author$project$Common$AdditionalDriverDrivingLicenseYears$LessThanTwo = 1;
var $author$project$Common$AdditionalDriverDrivingLicenseYears$MoreThanTwo = 3;
var $author$project$Common$AdditionalDriverDrivingLicenseYears$OneToThree = 2;
var $author$project$Common$Gql$AdditionalDriverDrivingLicenseYears$decodeAdditionalDriverDrivingLicenseYears = function (drivingLicenseYears) {
	switch (drivingLicenseYears) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $author$project$Common$Gql$AdditionalDriverDrivingLicenseYears$maybeDecodeAdditionalDriverDrivingLicenseYears = function (drivingLicenseYears) {
	return A2($elm$core$Maybe$map, $author$project$Common$Gql$AdditionalDriverDrivingLicenseYears$decodeAdditionalDriverDrivingLicenseYears, drivingLicenseYears);
};
var $author$project$Common$Gql$Offer$fullAdditionalDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$AdditionalDriverDrivingLicenseYears$maybeDecodeAdditionalDriverDrivingLicenseYears, $author$project$Gql$Platform$Object$BackofficeFullOccasionalDriverData$drivingLicenseYears),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Offer$fullAdditionalDriverPersonDataSelectionSet,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$AdditionalDriver)));
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$occasionalDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'occasionalDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeFullAdditionalDriver = $author$project$Gql$Platform$Object$BackofficeFullCustomerData$occasionalDriver($author$project$Common$Gql$Offer$fullAdditionalDriverSelectionSet);
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$claimsDeclaredWithFault = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'claimsDeclaredWithFault',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$AtLeast$atLeast = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Int', 'atLeast', _List_Nil, $elm$json$Json$Decode$int);
var $author$project$Common$Count$Minimum = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$Count$minimum = $author$project$Common$Count$Minimum;
var $author$project$Common$Gql$ClaimsCount$atLeastTotalClaimsDeclaredWithFaultSelectionSet = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Count$minimum, $author$project$Gql$Platform$Object$AtLeast$atLeast);
var $author$project$Common$Count$Exact = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$Count$exact = $author$project$Common$Count$Exact;
var $author$project$Common$Gql$ClaimsCount$countTotalClaimsDeclaredWithFaultSelectionSet = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Count$exact, $author$project$Gql$Platform$Object$Count$count);
var $author$project$Gql$Platform$Union$TotalClaimsCount$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'Count', selections____.mt),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'AtLeast', selections____.ma)
			]));
};
var $author$project$Common$Gql$ClaimsCount$claimsDeclaredWithFaultSelectionSet = $author$project$Gql$Platform$Union$TotalClaimsCount$fragments(
	{ma: $author$project$Common$Gql$ClaimsCount$atLeastTotalClaimsDeclaredWithFaultSelectionSet, mt: $author$project$Common$Gql$ClaimsCount$countTotalClaimsDeclaredWithFaultSelectionSet});
var $author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
	$author$project$Common$Count$default,
	$author$project$Gql$Platform$Object$BackofficeFullCustomerData$claimsDeclaredWithFault($author$project$Common$Gql$ClaimsCount$claimsDeclaredWithFaultSelectionSet));
var $author$project$Common$Person$Driver = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$address = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'address', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Address$Address = F4(
	function (streetName, streetNumber, province, postalCode) {
		return {m$: postalCode, m4: province, nt: streetName, nu: streetNumber};
	});
var $author$project$Gql$Platform$Object$BackofficeAddress$postalCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'postalCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeAddress$province = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'province', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeAddress$streetName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficeAddress$streetNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$addressSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeAddress$postalCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeAddress$province,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeAddress$streetNumber),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeAddress$streetName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Address$Address)))));
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$drivingLicenseDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'drivingLicenseDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$isLicenseMoreThanTwoYearsOld = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Bool)',
	'isLicenseMoreThanTwoYearsOld',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool));
var $author$project$Common$Person$MainDriverDrivingLicenseData = F2(
	function (drivingLicenseDate, isMoreThanTwoYearsOld) {
		return {dG: drivingLicenseDate, dZ: isMoreThanTwoYearsOld};
	});
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Common$Person$newMainDriverDrivingLicenseData = F2(
	function (drivingLicenseDate, isMoreThanTwoYearsOld) {
		return A3($elm$core$Maybe$map2, $author$project$Common$Person$MainDriverDrivingLicenseData, drivingLicenseDate, isMoreThanTwoYearsOld);
	});
var $author$project$Common$Gql$Offer$decodeMaybeFullMainDriverDrivingLicenseData = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$isLicenseMoreThanTwoYearsOld,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$drivingLicenseDate,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$newMainDriverDrivingLicenseData)));
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$fullMainDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$recentInsuranceCompanyName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'recentInsuranceCompanyName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$fullMainDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Common$Gql$Offer$decodeMaybeFullMainDriverDrivingLicenseData,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeFullMainDriverData$recentInsuranceCompanyName),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFullMainDriverData$address($author$project$Common$Gql$Offer$addressSelectionSet),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$fullMainDriverPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$mainDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'mainDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeFullMainDriver = $author$project$Gql$Platform$Object$BackofficeFullCustomerData$mainDriver($author$project$Common$Gql$Offer$fullMainDriverSelectionSet);
var $author$project$Common$Person$PolicyHolder = F4(
	function (a, b, c, d) {
		return {$: 2, a: a, b: b, c: c, d: d};
	});
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$address = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'address',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Common$Person$Contacts = F2(
	function (email, phoneNumber) {
		return {cn: email, g$: phoneNumber};
	});
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$email = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'email', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$phoneNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'phoneNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$contactSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$phoneNumber,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$email,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Contacts)));
var $author$project$Common$Person$Dni = 0;
var $author$project$Common$Person$Nie = 1;
var $author$project$Common$Gql$Offer$decodeDocumentKind = function (status) {
	if (!status) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Common$Address$defaultAddress = A4($author$project$Common$Address$Address, '', '', '', '');
var $author$project$Gql$Platform$Enum$DocumentKind$Dni = 0;
var $author$project$Gql$Platform$Enum$DocumentKind$Nie = 1;
var $author$project$Gql$Platform$Enum$DocumentKind$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'DNI':
				return $elm$json$Json$Decode$succeed(0);
			case 'NIE':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid DocumentKind type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$documentKind = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.DocumentKind.DocumentKind', 'documentKind', _List_Nil, $author$project$Gql$Platform$Enum$DocumentKind$decoder);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$fullPolicyHolderPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Offer$fullPolicyHolderSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeDocumentKind, $author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$documentKind),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Offer$contactSelectionSet,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				$author$project$Common$Address$defaultAddress,
				$author$project$Gql$Platform$Object$BackofficeFullPolicyholderData$address($author$project$Common$Gql$Offer$addressSelectionSet)),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$fullPolicyHolderPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PolicyHolder)))));
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$policyholder = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'policyholder', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeFullPolicyHolder = $author$project$Gql$Platform$Object$BackofficeFullCustomerData$policyholder($author$project$Common$Gql$Offer$fullPolicyHolderSelectionSet);
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'TINTED_WINDOWS';
		case 1:
			return 'XENON_HEADLIGHTS';
		case 2:
			return 'TOW_BALL';
		case 3:
			return 'RIMS';
		case 4:
			return 'SKIRTS';
		case 5:
			return 'SPOILERS';
		case 6:
			return 'EXHAUST_PIPE';
		case 7:
			return 'ENGINE';
		default:
			return 'OTHER';
	}
};
var $author$project$Common$Gql$Vehicle$decodeAccessory = function (accessory) {
	return $author$project$Gql$Platform$Enum$BackofficeVehicleModification$toString(accessory);
};
var $author$project$Common$Gql$Vehicle$decodeAccessories = function (accessories) {
	return A2(
		$elm$core$Maybe$withDefault,
		_List_Nil,
		A2(
			$elm$core$Maybe$map,
			function (accs) {
				return A2($elm$core$List$map, $author$project$Common$Gql$Vehicle$decodeAccessory, accs);
			},
			accessories));
};
var $author$project$Gql$Platform$Enum$BackofficeFuelType$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'DIESEL';
		case 1:
			return 'GASOLINE';
		case 2:
			return 'ELECTRIC';
		case 3:
			return 'LIQUID_GAS';
		case 4:
			return 'HYDROGEN';
		case 5:
			return 'HYBRID_ELECTRIC_GASOLINE';
		case 6:
			return 'HYBRID_ELECTRIC_DIESEL';
		case 7:
			return 'HYBRID_PLUGIN_ELECTRIC_GASOLINE';
		case 8:
			return 'HYBRID_PLUGIN_ELECTRIC_DIESEL';
		case 9:
			return 'BIO_ETHANOL';
		case 10:
			return 'ELECTRIC_WITH_GASOLINE_BATTERY';
		case 11:
			return 'ELECTRIC_WITH_HYDROGEN_FUEL_CELL';
		default:
			return 'MILD_HYBRID_ELECTRIC_GASOLINE';
	}
};
var $author$project$Common$Gql$Vehicle$decodeFuelType = function (fuelType) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2($elm$core$Maybe$map, $author$project$Gql$Platform$Enum$BackofficeFuelType$toString, fuelType));
};
var $author$project$Common$Gql$Vehicle$decodeMileage = function (mileage) {
	switch (mileage) {
		case 0:
			return 'Up to 2000';
		case 1:
			return '2000 - 4000';
		case 2:
			return '4000 - 6000';
		case 3:
			return '6000 - 8000';
		case 4:
			return '8000 - 10000';
		case 5:
			return '10000 - 12000';
		case 6:
			return '12000 - 14000';
		case 7:
			return '14000 - 16000';
		case 8:
			return '16000 - 18000';
		case 9:
			return '18000 - 20000';
		case 10:
			return '20000 - 22000';
		case 11:
			return '22000 - 24000';
		case 12:
			return '24000 - 26000';
		case 13:
			return '26000 - 28000';
		case 14:
			return '28000 - 30000';
		case 15:
			return '30000 - 32000';
		case 16:
			return '32000 - 34000';
		case 17:
			return '34000 - 36000';
		case 18:
			return '36000 - 38000';
		case 19:
			return '38000 - 40000';
		case 20:
			return '40000 - 45000';
		case 21:
			return '45000 - 50000';
		case 22:
			return 'More than 50000';
		case 23:
			return 'Missing Data';
		case 24:
			return 'Up to 6000';
		case 25:
			return '6000 - 10000';
		case 26:
			return '10000 - 14000';
		case 27:
			return '14000 - 20000';
		case 28:
			return '20000 - 30000';
		default:
			return '30000 - 50000';
	}
};
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'OCCASIONAL';
		case 1:
			return 'HABITUAL';
		case 2:
			return 'PROFESSIONAL_OCCASIONAL';
		case 3:
			return 'PROFESSIONAL_HABITUAL';
		default:
			return 'MISSING_DATA';
	}
};
var $author$project$Common$Gql$Vehicle$decodeUsage = function (usage) {
	return $author$project$Gql$Platform$Enum$BackofficeVehicleUse$toString(usage);
};
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$enginePower = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'enginePower',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Platform$Enum$BackofficeMileage$From10To12 = 5;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From10To14 = 26;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From12To14 = 6;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From14To16 = 7;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From14To20 = 27;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From16To18 = 8;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From18To20 = 9;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From20To22 = 10;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From20To30 = 28;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From22To24 = 11;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From24To26 = 12;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From26To28 = 13;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From28To30 = 14;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From2To4 = 1;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From30To32 = 15;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From30To50 = 29;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From32To34 = 16;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From34To36 = 17;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From36To38 = 18;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From38To40 = 19;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From40To45 = 20;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From45To50 = 21;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From4To6 = 2;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From6To10 = 25;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From6To8 = 3;
var $author$project$Gql$Platform$Enum$BackofficeMileage$From8To10 = 4;
var $author$project$Gql$Platform$Enum$BackofficeMileage$MissingData = 23;
var $author$project$Gql$Platform$Enum$BackofficeMileage$MoreThan50 = 22;
var $author$project$Gql$Platform$Enum$BackofficeMileage$UpTo2 = 0;
var $author$project$Gql$Platform$Enum$BackofficeMileage$UpTo6 = 24;
var $author$project$Gql$Platform$Enum$BackofficeMileage$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'UP_TO_2':
				return $elm$json$Json$Decode$succeed(0);
			case 'FROM_2_TO_4':
				return $elm$json$Json$Decode$succeed(1);
			case 'FROM_4_TO_6':
				return $elm$json$Json$Decode$succeed(2);
			case 'FROM_6_TO_8':
				return $elm$json$Json$Decode$succeed(3);
			case 'FROM_8_TO_10':
				return $elm$json$Json$Decode$succeed(4);
			case 'FROM_10_TO_12':
				return $elm$json$Json$Decode$succeed(5);
			case 'FROM_12_TO_14':
				return $elm$json$Json$Decode$succeed(6);
			case 'FROM_14_TO_16':
				return $elm$json$Json$Decode$succeed(7);
			case 'FROM_16_TO_18':
				return $elm$json$Json$Decode$succeed(8);
			case 'FROM_18_TO_20':
				return $elm$json$Json$Decode$succeed(9);
			case 'FROM_20_TO_22':
				return $elm$json$Json$Decode$succeed(10);
			case 'FROM_22_TO_24':
				return $elm$json$Json$Decode$succeed(11);
			case 'FROM_24_TO_26':
				return $elm$json$Json$Decode$succeed(12);
			case 'FROM_26_TO_28':
				return $elm$json$Json$Decode$succeed(13);
			case 'FROM_28_TO_30':
				return $elm$json$Json$Decode$succeed(14);
			case 'FROM_30_TO_32':
				return $elm$json$Json$Decode$succeed(15);
			case 'FROM_32_TO_34':
				return $elm$json$Json$Decode$succeed(16);
			case 'FROM_34_TO_36':
				return $elm$json$Json$Decode$succeed(17);
			case 'FROM_36_TO_38':
				return $elm$json$Json$Decode$succeed(18);
			case 'FROM_38_TO_40':
				return $elm$json$Json$Decode$succeed(19);
			case 'FROM_40_TO_45':
				return $elm$json$Json$Decode$succeed(20);
			case 'FROM_45_TO_50':
				return $elm$json$Json$Decode$succeed(21);
			case 'MORE_THAN_50':
				return $elm$json$Json$Decode$succeed(22);
			case 'MISSING_DATA':
				return $elm$json$Json$Decode$succeed(23);
			case 'UP_TO_6':
				return $elm$json$Json$Decode$succeed(24);
			case 'FROM_6_TO_10':
				return $elm$json$Json$Decode$succeed(25);
			case 'FROM_10_TO_14':
				return $elm$json$Json$Decode$succeed(26);
			case 'FROM_14_TO_20':
				return $elm$json$Json$Decode$succeed(27);
			case 'FROM_20_TO_30':
				return $elm$json$Json$Decode$succeed(28);
			case 'FROM_30_TO_50':
				return $elm$json$Json$Decode$succeed(29);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeMileage type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$expectedMileage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeMileage.BackofficeMileage', 'expectedMileage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeMileage$decoder);
var $author$project$Gql$Platform$Enum$BackofficeFuelType$BioEthanol = 9;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$Diesel = 0;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$Electric = 2;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$ElectricWithGasolineBattery = 10;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$ElectricWithHydrogenFuelCell = 11;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$Gasoline = 1;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$HybridElectricDiesel = 6;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$HybridElectricGasoline = 5;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$HybridPluginElectricDiesel = 8;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$HybridPluginElectricGasoline = 7;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$Hydrogen = 4;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$LiquidGas = 3;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$MildHybridElectricGasoline = 12;
var $author$project$Gql$Platform$Enum$BackofficeFuelType$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'DIESEL':
				return $elm$json$Json$Decode$succeed(0);
			case 'GASOLINE':
				return $elm$json$Json$Decode$succeed(1);
			case 'ELECTRIC':
				return $elm$json$Json$Decode$succeed(2);
			case 'LIQUID_GAS':
				return $elm$json$Json$Decode$succeed(3);
			case 'HYDROGEN':
				return $elm$json$Json$Decode$succeed(4);
			case 'HYBRID_ELECTRIC_GASOLINE':
				return $elm$json$Json$Decode$succeed(5);
			case 'HYBRID_ELECTRIC_DIESEL':
				return $elm$json$Json$Decode$succeed(6);
			case 'HYBRID_PLUGIN_ELECTRIC_GASOLINE':
				return $elm$json$Json$Decode$succeed(7);
			case 'HYBRID_PLUGIN_ELECTRIC_DIESEL':
				return $elm$json$Json$Decode$succeed(8);
			case 'BIO_ETHANOL':
				return $elm$json$Json$Decode$succeed(9);
			case 'ELECTRIC_WITH_GASOLINE_BATTERY':
				return $elm$json$Json$Decode$succeed(10);
			case 'ELECTRIC_WITH_HYDROGEN_FUEL_CELL':
				return $elm$json$Json$Decode$succeed(11);
			case 'MILD_HYBRID_ELECTRIC_GASOLINE':
				return $elm$json$Json$Decode$succeed(12);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeFuelType type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$fuelType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.BackofficeFuelType.BackofficeFuelType)',
	'fuelType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$BackofficeFuelType$decoder));
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$maker = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'maker', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$model = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'model', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$Engine = 7;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$ExhaustPipe = 6;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$Other = 8;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$Rims = 3;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$Skirts = 4;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$Spoilers = 5;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$TintedWindows = 0;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$TowBall = 2;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$XenonHeadlights = 1;
var $author$project$Gql$Platform$Enum$BackofficeVehicleModification$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'TINTED_WINDOWS':
				return $elm$json$Json$Decode$succeed(0);
			case 'XENON_HEADLIGHTS':
				return $elm$json$Json$Decode$succeed(1);
			case 'TOW_BALL':
				return $elm$json$Json$Decode$succeed(2);
			case 'RIMS':
				return $elm$json$Json$Decode$succeed(3);
			case 'SKIRTS':
				return $elm$json$Json$Decode$succeed(4);
			case 'SPOILERS':
				return $elm$json$Json$Decode$succeed(5);
			case 'EXHAUST_PIPE':
				return $elm$json$Json$Decode$succeed(6);
			case 'ENGINE':
				return $elm$json$Json$Decode$succeed(7);
			case 'OTHER':
				return $elm$json$Json$Decode$succeed(8);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeVehicleModification type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$modifications = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe (List Enum.BackofficeVehicleModification.BackofficeVehicleModification))',
	'modifications',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$elm$json$Json$Decode$list($author$project$Gql$Platform$Enum$BackofficeVehicleModification$decoder)));
var $author$project$Common$Vehicle$Vehicle = $elm$core$Basics$identity;
var $author$project$Common$Vehicle$new = function (model) {
	return function (maker) {
		return function (plateNumber) {
			return function (mileage) {
				return function (registrationDate) {
					return function (usage) {
						return function (fuelType) {
							return function (version) {
								return function (enginePower) {
									return function (accessories) {
										return {eY: accessories, fJ: enginePower, fT: fuelType, d6: maker, gm: mileage, d9: model, g1: plateNumber, hb: registrationDate, hG: usage, hM: version};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$plateNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'plateNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$registrationDate = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'registrationDate', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$Habitual = 1;
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$MissingData = 4;
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$Occasional = 0;
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$ProfessionalHabitual = 3;
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$ProfessionalOccasional = 2;
var $author$project$Gql$Platform$Enum$BackofficeVehicleUse$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'OCCASIONAL':
				return $elm$json$Json$Decode$succeed(0);
			case 'HABITUAL':
				return $elm$json$Json$Decode$succeed(1);
			case 'PROFESSIONAL_OCCASIONAL':
				return $elm$json$Json$Decode$succeed(2);
			case 'PROFESSIONAL_HABITUAL':
				return $elm$json$Json$Decode$succeed(3);
			case 'MISSING_DATA':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeVehicleUse type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$usage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeVehicleUse.BackofficeVehicleUse', 'usage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeVehicleUse$decoder);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleData$version = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'version', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Vehicle$fullVehicleSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeAccessories, $author$project$Gql$Platform$Object$BackofficeFullVehicleData$modifications),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullVehicleData$enginePower,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFullVehicleData$version,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeFuelType, $author$project$Gql$Platform$Object$BackofficeFullVehicleData$fuelType),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeUsage, $author$project$Gql$Platform$Object$BackofficeFullVehicleData$usage),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Platform$Object$BackofficeFullVehicleData$registrationDate,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeMileage, $author$project$Gql$Platform$Object$BackofficeFullVehicleData$expectedMileage),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeFullVehicleData$plateNumber,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeFullVehicleData$maker,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeFullVehicleData$model,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Vehicle$new)))))))))));
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$vehicle = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicle', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeFullVehicle = $author$project$Gql$Platform$Object$BackofficeFullCustomerData$vehicle($author$project$Common$Gql$Vehicle$fullVehicleSelectionSet);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$address = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'address',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Offer$fullVehicleOwnerPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$Locale$defaultDate, $author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Offer$fullVehicleOwnerSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				$author$project$Common$Address$defaultAddress,
				$author$project$Gql$Platform$Object$BackofficeFullVehicleOwnerData$address($author$project$Common$Gql$Offer$addressSelectionSet)),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$fullVehicleOwnerPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Gql$Platform$Object$BackofficeFullCustomerData$vehicleOwner = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicleOwner', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeFullVehicleOwner = $author$project$Gql$Platform$Object$BackofficeFullCustomerData$vehicleOwner($author$project$Common$Gql$Offer$fullVehicleOwnerSelectionSet);
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$offerId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'offerId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$decodeId = $author$project$Gql$Platform$Object$BackofficeOfferDetails$offerId;
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$isARenewal = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'isARenewal', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Common$Gql$Offer$decodeIsARenewal = $author$project$Gql$Platform$Object$BackofficeOfferDetails$isARenewal;
var $author$project$Common$QuoteId$QuoteId = $elm$core$Basics$identity;
var $author$project$Common$QuoteId$new = $elm$core$Basics$identity;
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$quoteId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Uuid)',
	'quoteId',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah));
var $author$project$Common$Gql$Offer$decodeQuoteId = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
	$elm$core$Maybe$map($author$project$Common$QuoteId$new),
	$author$project$Gql$Platform$Object$BackofficeOfferDetails$quoteId);
var $author$project$Common$Product$TercerosAmpliado = 1;
var $author$project$Common$Product$TercerosBasico = 0;
var $author$project$Common$Product$TodoRiesgoConFranquicia = 2;
var $author$project$Common$Gql$BundleSlug$decodeBackofficeBundleSlug = function (bundleSlug) {
	switch (bundleSlug) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Gql$Platform$Enum$BackofficeBundleSlug$TercerosAmpliado = 1;
var $author$project$Gql$Platform$Enum$BackofficeBundleSlug$TercerosBasico = 0;
var $author$project$Gql$Platform$Enum$BackofficeBundleSlug$TodoRiesgoConFranquicia = 2;
var $author$project$Gql$Platform$Enum$BackofficeBundleSlug$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'TERCEROS_BASICO':
				return $elm$json$Json$Decode$succeed(0);
			case 'TERCEROS_AMPLIADO':
				return $elm$json$Json$Decode$succeed(1);
			case 'TODO_RIESGO_CON_FRANQUICIA':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeBundleSlug type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$selectedBundle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeBundleSlug.BackofficeBundleSlug', 'selectedBundle', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeBundleSlug$decoder);
var $author$project$Common$Gql$Offer$decodeSelectedBundle = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$BundleSlug$decodeBackofficeBundleSlug, $author$project$Gql$Platform$Object$BackofficeOfferDetails$selectedBundle);
var $author$project$Common$Product$OfferProduct = F3(
	function (issuingCompany, bundleSlug, annualPrice) {
		return {kr: annualPrice, kA: bundleSlug, lz: issuingCompany};
	});
var $author$project$Gql$Platform$Object$BackofficeOfferProduct$annualPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'annualPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeOfferProduct$bundleSlug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeBundleSlug.BackofficeBundleSlug', 'bundleSlug', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeBundleSlug$decoder);
var $author$project$Gql$Platform$Object$BackofficeOfferProduct$issuingCompany = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'issuingCompany', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Product$AnnualPrice = F5(
	function (gross, taxes, net, taxIps, taxSurcharges) {
		return {la: gross, l_: net, nz: taxIps, nA: taxSurcharges, hA: taxes};
	});
var $author$project$Common$Price$Euro = 0;
var $author$project$Common$Price$Price = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Common$Price$new = F2(
	function (amount, currency) {
		return A2($author$project$Common$Price$Price, amount, currency);
	});
var $author$project$Common$Price$euro = function (amount) {
	return A2($author$project$Common$Price$new, amount, 0);
};
var $author$project$Gql$Platform$Object$BackofficeAnnualPrice$gross = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'gross',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$BackofficeAnnualPrice$net = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'net',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxIps = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxIps',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxSurcharges = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxSurcharges',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxes = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxes',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Common$Gql$OfferProduct$offerAnnualPriceV2Set = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxSurcharges),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxIps),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeAnnualPrice$net),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeAnnualPrice$taxes),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeAnnualPrice$gross),
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$AnnualPrice))))));
var $author$project$Common$Gql$OfferProduct$offerProductV2SelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeOfferProduct$annualPrice($author$project$Common$Gql$OfferProduct$offerAnnualPriceV2Set),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$BundleSlug$decodeBackofficeBundleSlug, $author$project$Gql$Platform$Object$BackofficeOfferProduct$bundleSlug),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeOfferProduct$issuingCompany,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$OfferProduct))));
var $author$project$Common$Gql$Offer$offerProductSelectionSet = $author$project$Common$Gql$OfferProduct$offerProductV2SelectionSet;
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$selectedProducts = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'selectedProducts',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Common$Gql$Offer$decodeSelectedProducts = $author$project$Gql$Platform$Object$BackofficeOfferDetails$selectedProducts($author$project$Common$Gql$Offer$offerProductSelectionSet);
var $author$project$Gql$Platform$Object$BackofficeOfferDetails$startAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'startAt',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Common$Gql$Offer$decodeStartDate = $author$project$Gql$Platform$Object$BackofficeOfferDetails$startAt;
var $author$project$Common$Offer$Offer = $elm$core$Basics$identity;
var $author$project$Common$Offer$new = function (id) {
	return function (customerFacingId) {
		return function (applicationId) {
			return function (quoteId) {
				return function (creationDate) {
					return function (startDate) {
						return function (status) {
							return function (quoteIdForClaimsHistoryQuery) {
								return function (mainDriver) {
									return function (policyHolder) {
										return function (additionalDriver) {
											return function (vehicleOwner) {
												return function (vehicle) {
													return function (claimsDeclaredWithFault) {
														return function (availableActions) {
															return function (products) {
																return function (offerType) {
																	return function (coverContinuity) {
																		return function (selectedBundle) {
																			return function (isRenewal) {
																				return function (source) {
																					return function (comebackLink) {
																						return {hS: additionalDriver, e1: applicationId, aB: availableActions, fi: claimsDeclaredWithFault, fo: comebackLink, $8: coverContinuity, fs: creationDate, kN: customerFacingId, iO: id, d$: isRenewal, d5: mainDriver, ec: offerType, g2: policyHolder, ep: products, m5: quoteId, g7: quoteIdForClaimsHistoryQuery, hg: selectedBundle, hm: source, np: startDate, c1: status, hL: vehicle, j7: vehicleOwner};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$backofficeBlockedOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeBlockedOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						3,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeBlockedOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeBlockedOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$Just,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$QuoteId$new, $author$project$Gql$Platform$Object$BackofficeBlockedOffer$blockedQuoteId)),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeBlockedOfferStatus, $author$project$Gql$Platform$Object$BackofficeBlockedOffer$blockedStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeBlockedOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$CompletedProvisionalType = 1;
var $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Enum$BackofficeCompletedProvisionalOfferStatus$Available = 0;
var $author$project$Gql$Platform$Enum$BackofficeCompletedProvisionalOfferStatus$Expired = 1;
var $author$project$Gql$Platform$Enum$BackofficeCompletedProvisionalOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AVAILABLE':
				return $elm$json$Json$Decode$succeed(0);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeCompletedProvisionalOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$completedProvisionalStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeCompletedProvisionalOfferStatus.BackofficeCompletedProvisionalOfferStatus', 'completedProvisionalStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeCompletedProvisionalOfferStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Offer$Available = 8;
var $author$project$Common$Offer$Expired = 4;
var $author$project$Common$Gql$Offer$decodeCompletedProvisionalOfferStatus = function (status) {
	if (!status) {
		return 8;
	} else {
		return 4;
	}
};
var $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$backofficeCompletedProvisionalOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						1,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
															$elm$core$Maybe$Nothing,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeCompletedProvisionalOfferStatus, $author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$completedProvisionalStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeCompletedProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$FinalizedType = 5;
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Offer$MissingDocuments = 2;
var $author$project$Common$Offer$NewPrice = 0;
var $author$project$Common$Offer$NewProduct = 1;
var $author$project$Common$Offer$StartDateExpired = 3;
var $author$project$Common$Gql$Offer$decodeFinalizedOfferStatus = function (status) {
	switch (status) {
		case 2:
			return 8;
		case 5:
			return 4;
		case 4:
			return 2;
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 3;
	}
};
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$Available = 2;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$Expired = 5;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$MissingDocuments = 4;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$NewPrice = 0;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$NewProduct = 1;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$StartDateExpired = 3;
var $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'NEW_PRICE':
				return $elm$json$Json$Decode$succeed(0);
			case 'NEW_PRODUCT':
				return $elm$json$Json$Decode$succeed(1);
			case 'AVAILABLE':
				return $elm$json$Json$Decode$succeed(2);
			case 'START_DATE_EXPIRED':
				return $elm$json$Json$Decode$succeed(3);
			case 'MISSING_DOCUMENTS':
				return $elm$json$Json$Decode$succeed(4);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(5);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeFinalizedOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$finalizedStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeFinalizedOfferStatus.BackofficeFinalizedOfferStatus', 'finalizedStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeFinalizedOfferStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$quoteIdForClaimsHistory = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'quoteIdForClaimsHistory',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Gql$Offer$backofficeFinalizedOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						5,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$Just,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$QuoteId$new, $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$quoteIdForClaimsHistory)),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeFinalizedOfferStatus, $author$project$Gql$Platform$Object$BackofficeFinalizedOffer$finalizedStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeFinalizedOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$ProvisionalType = 0;
var $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$occasionalDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'occasionalDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePartialOccasionalDriverData$drivingLicenseYears = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.AdditionalDriverDrivingLicenseYears.AdditionalDriverDrivingLicenseYears)',
	'drivingLicenseYears',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$AdditionalDriverDrivingLicenseYears$decoder));
var $author$project$Gql$Platform$Object$BackofficePartialOccasionalDriverData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $author$project$Common$Gql$Offer$partialAdditionalDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$Locale$defaultDate, $author$project$Gql$Platform$Object$BackofficePartialOccasionalDriverData$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			'',
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				'',
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Offer$partialOccasionalDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$AdditionalDriverDrivingLicenseYears$maybeDecodeAdditionalDriverDrivingLicenseYears, $author$project$Gql$Platform$Object$BackofficePartialOccasionalDriverData$drivingLicenseYears),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Offer$partialAdditionalDriverPersonDataSelectionSet,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$AdditionalDriver)));
var $author$project$Common$Gql$Offer$decodePartialAdditionalDriver = $author$project$Gql$Platform$Object$BackofficePartialCustomerData$occasionalDriver($author$project$Common$Gql$Offer$partialOccasionalDriverSelectionSet);
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$claimsDeclaredWithFault = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'claimsDeclaredWithFault',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Common$Gql$Offer$decodePartialClaimsDeclaredWithFault = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
	$author$project$Common$Count$default,
	$author$project$Gql$Platform$Object$BackofficePartialCustomerData$claimsDeclaredWithFault($author$project$Common$Gql$ClaimsCount$claimsDeclaredWithFaultSelectionSet));
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$mainDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'mainDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$address = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'address', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$drivingLicenseDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'drivingLicenseDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$isLicenseMoreThanTwoYearsOld = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Bool)',
	'isLicenseMoreThanTwoYearsOld',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$bool));
var $author$project$Common$Gql$Offer$decodeMaybePartialMainDriverDrivingLicenseData = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficePartialMainDriverData$isLicenseMoreThanTwoYearsOld,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficePartialMainDriverData$drivingLicenseDate,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$newMainDriverDrivingLicenseData)));
var $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Common$Gql$Offer$partialMainDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficePartialMainDriverData$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			'',
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				'',
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$recentInsuranceCompanyName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'recentInsuranceCompanyName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$partialMainDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Common$Gql$Offer$decodeMaybePartialMainDriverDrivingLicenseData,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficePartialMainDriverData$recentInsuranceCompanyName),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficePartialMainDriverData$address($author$project$Common$Gql$Offer$addressSelectionSet),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$partialMainDriverPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Common$Gql$Offer$decodePartialMainDriver = $author$project$Gql$Platform$Object$BackofficePartialCustomerData$mainDriver($author$project$Common$Gql$Offer$partialMainDriverSelectionSet);
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$address = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'address',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$documentKind = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.DocumentKind.DocumentKind', 'documentKind', _List_Nil, $author$project$Gql$Platform$Enum$DocumentKind$decoder);
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$email = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'email',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$partialPolicyHolderContactDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	'',
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$email),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Contacts)));
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$firstName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'firstName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$lastName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'lastName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Offer$partialPolicyHolderPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$Locale$defaultDate, $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$lastName),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$firstName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Offer$partialPolicyHolderSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeDocumentKind, $author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$documentKind),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Offer$partialPolicyHolderContactDataSelectionSet,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				$author$project$Common$Address$defaultAddress,
				$author$project$Gql$Platform$Object$BackofficePartialPolicyholderData$address($author$project$Common$Gql$Offer$addressSelectionSet)),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$partialPolicyHolderPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PolicyHolder)))));
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$policyholder = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'policyholder', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodePartialPolicyHolder = $author$project$Gql$Platform$Object$BackofficePartialCustomerData$policyholder($author$project$Common$Gql$Offer$partialPolicyHolderSelectionSet);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$enginePower = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'enginePower',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$expectedMileage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeMileage.BackofficeMileage', 'expectedMileage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeMileage$decoder);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$fuelType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.BackofficeFuelType.BackofficeFuelType)',
	'fuelType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$BackofficeFuelType$decoder));
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$maker = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'maker', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$model = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'model', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$plateNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'plateNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$registrationDate = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'registrationDate', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$usage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeVehicleUse.BackofficeVehicleUse', 'usage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeVehicleUse$decoder);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleData$version = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'version', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Vehicle$partialVehicleOwnerSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	_List_Nil,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficePartialVehicleData$enginePower,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficePartialVehicleData$version,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeFuelType, $author$project$Gql$Platform$Object$BackofficePartialVehicleData$fuelType),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeUsage, $author$project$Gql$Platform$Object$BackofficePartialVehicleData$usage),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Platform$Object$BackofficePartialVehicleData$registrationDate,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeMileage, $author$project$Gql$Platform$Object$BackofficePartialVehicleData$expectedMileage),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficePartialVehicleData$plateNumber),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficePartialVehicleData$maker,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficePartialVehicleData$model,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Vehicle$new)))))))))));
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$vehicle = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicle', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodePartialVehicle = $author$project$Gql$Platform$Object$BackofficePartialCustomerData$vehicle($author$project$Common$Gql$Vehicle$partialVehicleOwnerSelectionSet);
var $author$project$Gql$Platform$Object$BackofficePartialVehicleOwnerData$address = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'address',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$BackofficePartialVehicleOwnerData$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $author$project$Common$Gql$Offer$partialVehicleOwnerPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$Locale$defaultDate, $author$project$Gql$Platform$Object$BackofficePartialVehicleOwnerData$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			'',
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				'',
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Offer$partialVehicleOwnerSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
				$author$project$Common$Address$defaultAddress,
				$author$project$Gql$Platform$Object$BackofficePartialVehicleOwnerData$address($author$project$Common$Gql$Offer$addressSelectionSet)),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Offer$partialVehicleOwnerPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Gql$Platform$Object$BackofficePartialCustomerData$vehicleOwner = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicleOwner', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodePartialVehicleOwner = $author$project$Gql$Platform$Object$BackofficePartialCustomerData$vehicleOwner($author$project$Common$Gql$Offer$partialVehicleOwnerSelectionSet);
var $author$project$Common$Gql$Offer$decodeProvisionalOfferStatus = function (status) {
	if (!status) {
		return 8;
	} else {
		return 4;
	}
};
var $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'partialCustomerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Enum$BackofficeProvisionalOfferStatus$Available = 0;
var $author$project$Gql$Platform$Enum$BackofficeProvisionalOfferStatus$Expired = 1;
var $author$project$Gql$Platform$Enum$BackofficeProvisionalOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'AVAILABLE':
				return $elm$json$Json$Decode$succeed(0);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeProvisionalOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$provisionalStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeProvisionalOfferStatus.BackofficeProvisionalOfferStatus', 'provisionalStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeProvisionalOfferStatus$decoder);
var $author$project$Common$Gql$Offer$backofficeProvisionalOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						0,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$partialCustomerData($author$project$Common$Gql$Offer$decodePartialMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
															$elm$core$Maybe$Nothing,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeProvisionalOfferStatus, $author$project$Gql$Platform$Object$BackofficeProvisionalOffer$provisionalStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeProvisionalOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$PurchasedType = 6;
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Offer$Purchased = 7;
var $author$project$Common$Gql$Offer$decodePurchasedOfferStatus = function (_v0) {
	return 7;
};
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Enum$BackofficePurchasedOfferStatus$Purchased = 0;
var $author$project$Gql$Platform$Enum$BackofficePurchasedOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (string === 'PURCHASED') {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			return $elm$json$Json$Decode$fail('Invalid BackofficePurchasedOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$purchasedStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficePurchasedOfferStatus.BackofficePurchasedOfferStatus', 'purchasedStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficePurchasedOfferStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficePurchasedOffer$quoteIdForClaimsHistoryPurchased = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Uuid)',
	'quoteIdForClaimsHistoryPurchased',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah));
var $author$project$Common$Gql$Offer$backofficePurchasedOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficePurchasedOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						6,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficePurchasedOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficePurchasedOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$map($author$project$Common$QuoteId$new),
																$author$project$Gql$Platform$Object$BackofficePurchasedOffer$quoteIdForClaimsHistoryPurchased),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodePurchasedOfferStatus, $author$project$Gql$Platform$Object$BackofficePurchasedOffer$purchasedStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficePurchasedOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$RenewalType = 7;
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeRenewalOfferStatus = function (status) {
	switch (status) {
		case 2:
			return 8;
		case 0:
			return 4;
		default:
			return 3;
	}
};
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$quoteIdForClaimsHistory = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'quoteIdForClaimsHistory',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Gql$Platform$Enum$BackofficeRenewalOfferStatus$Available = 2;
var $author$project$Gql$Platform$Enum$BackofficeRenewalOfferStatus$Expired = 0;
var $author$project$Gql$Platform$Enum$BackofficeRenewalOfferStatus$StartDateExpired = 1;
var $author$project$Gql$Platform$Enum$BackofficeRenewalOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(0);
			case 'START_DATE_EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			case 'AVAILABLE':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeRenewalOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeRenewalOffer$renewalStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeRenewalOfferStatus.BackofficeRenewalOfferStatus', 'renewalStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeRenewalOfferStatus$decoder);
var $author$project$Common$Gql$Offer$backofficeRenewalOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeRenewalOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						7,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeRenewalOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeRenewalOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$Just,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$QuoteId$new, $author$project$Gql$Platform$Object$BackofficeRenewalOffer$quoteIdForClaimsHistory)),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeRenewalOfferStatus, $author$project$Gql$Platform$Object$BackofficeRenewalOffer$renewalStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeRenewalOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$RequoteValidationFailedType = 2;
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Offer$decodeRequoteValidationFailedOfferStatus = function (status) {
	if (status === 1) {
		return 4;
	} else {
		return 2;
	}
};
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$failedQuoteId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'failedQuoteId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Enum$BackofficeRequoteValidationFailedOfferStatus$Expired = 1;
var $author$project$Gql$Platform$Enum$BackofficeRequoteValidationFailedOfferStatus$MissingDocuments = 0;
var $author$project$Gql$Platform$Enum$BackofficeRequoteValidationFailedOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'MISSING_DOCUMENTS':
				return $elm$json$Json$Decode$succeed(0);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeRequoteValidationFailedOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$requotedValidationFailedStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeRequoteValidationFailedOfferStatus.BackofficeRequoteValidationFailedOfferStatus', 'requotedValidationFailedStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeRequoteValidationFailedOfferStatus$decoder);
var $author$project$Common$Gql$Offer$backofficeRequoteValidationFailedOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						2,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$Just,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$QuoteId$new, $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$failedQuoteId)),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeRequoteValidationFailedOfferStatus, $author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$requotedValidationFailedStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeRequoteValidationFailedOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Common$Offer$RequotedType = 4;
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$availableActions = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'availableActions',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$continuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'continuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'customerData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Offer$Requoted = 6;
var $author$project$Common$Gql$Offer$decodeRequotedOfferStatus = function (_v0) {
	return 6;
};
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$newQuoteId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Uuid)',
	'newQuoteId',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah));
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'offerDetails', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Enum$BackofficeRequotedOfferStatus$Requoted = 0;
var $author$project$Gql$Platform$Enum$BackofficeRequotedOfferStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		if (string === 'REQUOTED') {
			return $elm$json$Json$Decode$succeed(0);
		} else {
			return $elm$json$Json$Decode$fail('Invalid BackofficeRequotedOfferStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeRequotedOffer$requotedStatus = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeRequotedOfferStatus.BackofficeRequotedOfferStatus', 'requotedStatus', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeRequotedOfferStatus$decoder);
var $author$project$Common$Gql$Offer$backofficeRequotedOfferSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeComebackLink),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeIsARenewal),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedBundle),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficeRequotedOffer$continuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						4,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeSelectedProducts),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeRequotedOffer$availableActions($author$project$Common$Gql$Offer$decodeAvailableActions),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullClaimsDeclaredWithFault),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicle),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullVehicleOwner),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullAdditionalDriver),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullPolicyHolder),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficeRequotedOffer$customerData($author$project$Common$Gql$Offer$decodeFullMainDriver),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																$elm$core$Maybe$map($author$project$Common$QuoteId$new),
																$author$project$Gql$Platform$Object$BackofficeRequotedOffer$newQuoteId),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeRequotedOfferStatus, $author$project$Gql$Platform$Object$BackofficeRequotedOffer$requotedStatus),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeStartDate),
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCreationDate),
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeQuoteId),
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeApplicationId),
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeCustomerFacingId),
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficeRequotedOffer$offerDetails($author$project$Common$Gql$Offer$decodeId),
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Offer$new)))))))))))))))))))))));
var $author$project$Gql$Platform$Union$BackofficeOfferStateMachine$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeProvisionalOffer', selections____.mj),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeCompletedProvisionalOffer', selections____.me),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeRequoteValidationFailedOffer', selections____.mn),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeBlockedOffer', selections____.mc),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeRequotedOffer', selections____.mo),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeFinalizedOffer', selections____.mh),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeRenewalOffer', selections____.mm),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficePurchasedOffer', selections____.ml)
			]));
};
var $author$project$Common$Gql$Offer$offerSelectionSet = $author$project$Gql$Platform$Union$BackofficeOfferStateMachine$fragments(
	{mc: $author$project$Common$Gql$Offer$backofficeBlockedOfferSelectionSet, me: $author$project$Common$Gql$Offer$backofficeCompletedProvisionalOfferSelectionSet, mh: $author$project$Common$Gql$Offer$backofficeFinalizedOfferSelectionSet, mj: $author$project$Common$Gql$Offer$backofficeProvisionalOfferSelectionSet, ml: $author$project$Common$Gql$Offer$backofficePurchasedOfferSelectionSet, mm: $author$project$Common$Gql$Offer$backofficeRenewalOfferSelectionSet, mn: $author$project$Common$Gql$Offer$backofficeRequoteValidationFailedOfferSelectionSet, mo: $author$project$Common$Gql$Offer$backofficeRequotedOfferSelectionSet});
var $author$project$Gql$Platform$Query$offerV2 = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'offerV2',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'identifier', requiredArgs____.bs, $author$project$Gql$Platform$InputObject$encodeOfferIdentifier)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Gql$Platform$Query$PricingActionRequiredArguments = function (requiredFor) {
	return {jD: requiredFor};
};
var $author$project$Common$Gql$ClaimHistory$pricingActionByOfferIdRequiredArguments = function (offerId) {
	return $author$project$Gql$Platform$Query$PricingActionRequiredArguments(
		{
			C: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				{
					kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
					l7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(offerId)
				}),
			j: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $elm_community$string_extra$String$Extra$changeCase = F2(
	function (mutator, word) {
		return A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				function (_v0) {
					var head = _v0.a;
					var tail = _v0.b;
					return A2(
						$elm$core$String$cons,
						mutator(head),
						tail);
				},
				$elm$core$String$uncons(word)));
	});
var $elm$core$Char$toUpper = _Char_toUpper;
var $elm_community$string_extra$String$Extra$toSentenceCase = function (word) {
	return A2($elm_community$string_extra$String$Extra$changeCase, $elm$core$Char$toUpper, word);
};
var $author$project$Gql$Platform$Enum$PricingAction$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'NONE';
		case 1:
			return 'ASK_FOR_SEVERITY_CERTIFICATE_POST_SALE';
		default:
			return 'REQUEST_CORRECT_5_DIGITS';
	}
};
var $author$project$Common$Gql$ClaimHistory$decodePricingAction = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$Basics$composeL, $elm_community$string_extra$String$Extra$toSentenceCase, $elm$core$String$toLower),
	$author$project$Gql$Platform$Enum$PricingAction$toString);
var $author$project$Gql$Platform$Enum$PricingAction$AskForSeverityCertificatePostSale = 1;
var $author$project$Gql$Platform$Enum$PricingAction$None = 0;
var $author$project$Gql$Platform$Enum$PricingAction$RequestCorrect5Digits = 2;
var $author$project$Gql$Platform$Enum$PricingAction$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'NONE':
				return $elm$json$Json$Decode$succeed(0);
			case 'ASK_FOR_SEVERITY_CERTIFICATE_POST_SALE':
				return $elm$json$Json$Decode$succeed(1);
			case 'REQUEST_CORRECT_5_DIGITS':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid PricingAction type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$InputObject$encodeBackofficeEntityIdentifier = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'offer',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.C, $author$project$Gql$Platform$InputObject$encodeOfferIdentifier)),
				_Utils_Tuple2(
				'policy',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.j, $author$project$Gql$Platform$InputObject$encodePolicyIdentifier))
			]));
};
var $author$project$Gql$Platform$Query$pricingAction = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'(Maybe Enum.PricingAction.PricingAction)',
		'pricingAction',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'requiredFor', requiredArgs____.jD, $author$project$Gql$Platform$InputObject$encodeBackofficeEntityIdentifier)
			]),
		$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$PricingAction$decoder));
};
var $author$project$Common$Gql$ClaimHistory$pricingActionQuery = function (queryArguments) {
	return A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
		$elm$core$Maybe$map($author$project$Common$Gql$ClaimHistory$decodePricingAction),
		$author$project$Gql$Platform$Query$pricingAction(queryArguments));
};
var $author$project$Common$Gql$ClaimHistory$pricingActionByOfferIdQuery = function (offerId) {
	return $author$project$Common$Gql$ClaimHistory$pricingActionQuery(
		$author$project$Common$Gql$ClaimHistory$pricingActionByOfferIdRequiredArguments(offerId));
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$Success = {$: 0};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$Failure = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$MissingEmail = 5;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferBlocked = 3;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferExpired = 0;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferIsRenewal = 6;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferPurchased = 2;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferRequoted = 4;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$OfferStartDateExpired = 1;
var $author$project$Common$Gql$Offer$decodeReason = function (reason) {
	switch (reason) {
		case 3:
			return 3;
		case 5:
			return 5;
		case 0:
			return 0;
		case 2:
			return 2;
		case 4:
			return 4;
		case 1:
			return 1;
		default:
			return 6;
	}
};
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$MissingEmail = 5;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferBlocked = 3;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferExpired = 0;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferIsRenewal = 6;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferPurchased = 2;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferRequoted = 4;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$OfferStartDateExpired = 1;
var $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'OFFER_EXPIRED':
				return $elm$json$Json$Decode$succeed(0);
			case 'OFFER_START_DATE_EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			case 'OFFER_PURCHASED':
				return $elm$json$Json$Decode$succeed(2);
			case 'OFFER_BLOCKED':
				return $elm$json$Json$Decode$succeed(3);
			case 'OFFER_REQUOTED':
				return $elm$json$Json$Decode$succeed(4);
			case 'MISSING_EMAIL':
				return $elm$json$Json$Decode$succeed(5);
			case 'OFFER_IS_RENEWAL':
				return $elm$json$Json$Decode$succeed(6);
			default:
				return $elm$json$Json$Decode$fail('Invalid SendNewBusinessOfferEmailFailureReason type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$SendNewBusinessOfferEmailFailure$reason = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.SendNewBusinessOfferEmailFailureReason.SendNewBusinessOfferEmailFailureReason', 'reason', _List_Nil, $author$project$Gql$Platform$Enum$SendNewBusinessOfferEmailFailureReason$decoder);
var $author$project$Common$Gql$Offer$decodeSendNewBusinessOfferEmailError = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Offer$decodeReason, $author$project$Gql$Platform$Object$SendNewBusinessOfferEmailFailure$reason),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$ComponentUI$Modal$SendOfferEmail$Failure));
var $author$project$Gql$Platform$Union$SendNewBusinessOfferEmailResponse$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SendNewBusinessOfferEmailSuccess', selections____.mM),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'SendNewBusinessOfferEmailFailure', selections____.mL)
			]));
};
var $author$project$Common$Gql$Offer$sendNewBusinessEmailSelectionSet = $author$project$Gql$Platform$Union$SendNewBusinessOfferEmailResponse$fragments(
	{
		mL: $author$project$Common$Gql$Offer$decodeSendNewBusinessOfferEmailError,
		mM: $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$ComponentUI$Modal$SendOfferEmail$Success)
	});
var $author$project$Gql$Platform$Mutation$sendNewBusinessOfferEmail = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'sendNewBusinessOfferEmail',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'offerId',
					requiredArgs____.l7,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Common$Gql$Offer$sendNewBusinessEmail = function (offerId) {
	return A2(
		$author$project$Gql$Platform$Mutation$sendNewBusinessOfferEmail,
		{l7: offerId},
		$author$project$Common$Gql$Offer$sendNewBusinessEmailSelectionSet);
};
var $author$project$Common$Gql$Offer$sendOfferEmailMutation = function (option) {
	var offerId = option;
	return $author$project$Common$Gql$Offer$sendNewBusinessEmail(offerId);
};
var $author$project$Common$QuoteId$toUuid = function (_v0) {
	var quoteId = _v0;
	return quoteId;
};
var $author$project$Page$OfferDetails$Effect$runEffect = F3(
	function (env, model, effect) {
		switch (effect.$) {
			case 11:
				return $elm$core$Platform$Cmd$none;
			case 0:
				var id = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2(
						$author$project$Gql$Platform$Query$offerV2,
						$author$project$Common$Gql$Offer$offerByCfidRequiredArguments(id),
						$author$project$Common$Gql$Offer$offerSelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$FetchedOffer));
			case 5:
				var cmd = effect.a;
				return cmd;
			case 1:
				var quoteId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2(
						$author$project$Gql$Platform$Query$claimsHistory,
						$author$project$Common$Gql$ClaimHistory$claimHistoryByQuoteIdRequiredArguments(
							$author$project$Common$QuoteId$toUuid(quoteId)),
						$author$project$Common$Gql$ClaimHistory$claimHistorySelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$FetchedClaimHistory));
			case 4:
				var offerId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$ClaimHistory$pricingActionByOfferIdQuery(offerId),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$FetchedClaimHistoryPricingAction));
			case 2:
				var offerId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$CustomerId$getCustomerId(
						$author$project$Common$Gql$CustomerId$OfferId(offerId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$FetchedCustomerId));
			case 7:
				var id = effect.a;
				var coverageEffectiveDate = effect.b;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2(
						$author$project$Gql$Platform$Mutation$amendOfferCoverageEffectiveDate,
						A2($author$project$Common$Gql$Offer$amendOfferCoverageEffectiveDateArguments, id, coverageEffectiveDate),
						$author$project$Common$Gql$Offer$amendOfferCoverageEffectiveDateSelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$OfferCoverageEffectiveDateAmended));
			case 6:
				return $elm$core$Platform$Cmd$none;
			case 8:
				return A2(
					$elm$core$Task$perform,
					$author$project$Page$OfferDetails$Msg$GotCurrentDate,
					A2(
						$elm$core$Task$map,
						$justinmimbs$date$Date$fromPosix($elm$time$Time$utc),
						$elm$time$Time$now));
			case 10:
				var effects = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						A2($author$project$Page$OfferDetails$Effect$runEffect, env, model),
						effects));
			case 3:
				var offerId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$PaymentMethod$getPaymentMethod(
						$author$project$Common$Gql$PaymentMethod$OfferId(offerId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$FetchedPaymentMethod));
			default:
				var option = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Common$Gql$Offer$sendOfferEmailMutation(option),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$OfferDetails$Msg$EmailSent));
		}
	});
var $author$project$Page$Payment$Msg$GotBraintreeToken = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Payment$Msg$GotOfferProducts = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Payment$Msg$GotPolicy = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Payment$Ports$braintreeSetup = _Platform_outgoingPort('braintreeSetup', $elm$json$Json$Encode$string);
var $author$project$Utils$executeGraphqlOperation = F5(
	function (envData, urlExtractor, operation, selectionSet, tagger) {
		var effectRunner = function (env) {
			return $dillonkearns$elm_graphql$Graphql$Http$toTask(
				$dillonkearns$elm_graphql$Graphql$Http$withCredentials(
					A2(
						operation,
						urlExtractor(env),
						selectionSet)));
		};
		return A2(
			$elm$core$Task$attempt,
			tagger,
			effectRunner(envData));
	});
var $author$project$Gql$Platform$Object$ClientConfiguration$braintreeClientToken = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'braintreeClientToken', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Query$clientConfiguration = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'clientConfiguration', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Page$Payment$Gql$fetchBraintreeToken = $author$project$Gql$Platform$Query$clientConfiguration($author$project$Gql$Platform$Object$ClientConfiguration$braintreeClientToken);
var $author$project$Gql$Platform$Query$ProductsRequiredArguments = function (offerId) {
	return {l7: offerId};
};
var $author$project$Page$Payment$Gql$findProductsByOfferIdRequiredArguments = function (offerId) {
	return $author$project$Gql$Platform$Query$ProductsRequiredArguments(offerId);
};
var $author$project$Gql$Platform$Object$BackofficeProduct$annualPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'annualPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Enum$BundleSlug$TercerosAmpliado = 1;
var $author$project$Gql$Platform$Enum$BundleSlug$TercerosBasico = 0;
var $author$project$Gql$Platform$Enum$BundleSlug$TodoRiesgoConFranquicia = 2;
var $author$project$Gql$Platform$Enum$BundleSlug$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'TERCEROS_BASICO':
				return $elm$json$Json$Decode$succeed(0);
			case 'TERCEROS_AMPLIADO':
				return $elm$json$Json$Decode$succeed(1);
			case 'TODO_RIESGO_CON_FRANQUICIA':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid BundleSlug type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeProduct$bundleSlug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BundleSlug.BundleSlug', 'bundleSlug', _List_Nil, $author$project$Gql$Platform$Enum$BundleSlug$decoder);
var $author$project$Common$Gql$BundleSlug$decodeBundleSlug = function (bundleSlug) {
	switch (bundleSlug) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Gql$Platform$Object$BackofficeProduct$issuingCompany = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'issuingCompany', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$OfferAnnualPrice$gross = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'gross',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$OfferAnnualPrice$net = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'net',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$OfferAnnualPrice$taxIps = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxIps',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$OfferAnnualPrice$taxSurcharges = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxSurcharges',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Gql$Platform$Object$OfferAnnualPrice$taxes = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'taxes',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Common$Gql$OfferProduct$offerAnnualPriceSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxSurcharges),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxIps),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$net),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxes),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$gross),
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$AnnualPrice))))));
var $author$project$Common$Gql$OfferProduct$offerProductSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeProduct$annualPrice($author$project$Common$Gql$OfferProduct$offerAnnualPriceSet),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$BundleSlug$decodeBundleSlug, $author$project$Gql$Platform$Object$BackofficeProduct$bundleSlug),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeProduct$issuingCompany,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$OfferProduct))));
var $author$project$Gql$Platform$Query$products = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'products',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'offerId',
					requiredArgs____.l7,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs))
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Page$Payment$Gql$findProductsByOfferId = function (offerId) {
	return A2(
		$author$project$Gql$Platform$Query$products,
		$author$project$Page$Payment$Gql$findProductsByOfferIdRequiredArguments(offerId),
		$author$project$Common$Gql$OfferProduct$offerProductSelectionSet);
};
var $author$project$Page$Payment$Ports$payWithCreditCard = _Platform_outgoingPort(
	'payWithCreditCard',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Gql$Platform$InputObject$MotoPaymentInfo = function (token) {
	return {ae: token};
};
var $author$project$Gql$Platform$Mutation$PurchaseOfferRequiredArguments = F2(
	function (offerId, paymentInfo) {
		return {l7: offerId, em: paymentInfo};
	});
var $author$project$Gql$Platform$InputObject$encodeMotoPaymentInfo = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'token',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.ae)))
			]));
};
var $author$project$Gql$Platform$Mutation$purchaseOffer = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'purchaseOffer',
		_List_fromArray(
			[
				A3(
				$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
				'offerId',
				requiredArgs____.l7,
				A2(
					$author$project$Gql$Platform$Scalar$unwrapEncoder,
					function ($) {
						return $.I;
					},
					$author$project$ScalarCodecs$codecs)),
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'paymentInfo', requiredArgs____.em, $author$project$Gql$Platform$InputObject$encodeMotoPaymentInfo)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Page$Payment$Gql$purchaseOffer = F2(
	function (nonce, offerId) {
		var paymentInfo = $author$project$Gql$Platform$InputObject$MotoPaymentInfo(nonce);
		return $author$project$Gql$Platform$Mutation$purchaseOffer(
			A2($author$project$Gql$Platform$Mutation$PurchaseOfferRequiredArguments, offerId, paymentInfo));
	});
var $author$project$Page$Payment$Effect$runEffect = F3(
	function (env, model, effect) {
		switch (effect.$) {
			case 3:
				var token = effect.a;
				return $author$project$Page$Payment$Ports$braintreeSetup(token);
			case 4:
				return A5(
					$author$project$Utils$executeGraphqlOperation,
					env,
					$author$project$Env$apiUrl,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Page$Payment$Gql$fetchBraintreeToken,
					A2($elm$core$Basics$composeL, $author$project$Page$Payment$Msg$GotBraintreeToken, $krisajenkins$remotedata$RemoteData$fromResult));
			case 6:
				var offerId = effect.a;
				return A5(
					$author$project$Utils$executeGraphqlOperation,
					env,
					$author$project$Env$apiUrl,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Page$Payment$Gql$findProductsByOfferId(offerId),
					A2($elm$core$Basics$composeL, $author$project$Page$Payment$Msg$GotOfferProducts, $krisajenkins$remotedata$RemoteData$fromResult));
			case 5:
				return $author$project$Page$Payment$Ports$payWithCreditCard(0);
			case 7:
				var nonce = effect.a;
				return A5(
					$author$project$Utils$executeGraphqlOperation,
					env,
					$author$project$Env$apiUrl,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					A2($author$project$Page$Payment$Gql$purchaseOffer, nonce, model.l7),
					A2($elm$core$Basics$composeL, $author$project$Page$Payment$Msg$GotPolicy, $krisajenkins$remotedata$RemoteData$fromResult));
			case 0:
				var effects = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Payment$Effect$runEffect, env, model),
						effects));
			case 8:
				return $elm$core$Platform$Cmd$none;
			case 1:
				return $elm$core$Platform$Cmd$none;
			default:
				return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Page$PolicyDetails$Msg$CreateRefundResponseReceived = function (a) {
	return {$: 25, a: a};
};
var $author$project$Page$PolicyDetails$Msg$FetchedClaimHistory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyDetails$Msg$FetchedClaimHistoryPricingAction = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PolicyDetails$Msg$FetchedCustomerId = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PolicyDetails$Msg$FetchedPaymentMethod = function (a) {
	return {$: 37, a: a};
};
var $author$project$Page$PolicyDetails$Msg$FetchedPolicy = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PolicyDetails$Msg$GotChangeEmailResponse = function (a) {
	return {$: 47, a: a};
};
var $author$project$Page$PolicyDetails$Msg$GotCurrentTime = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$PolicyDetails$Msg$GotRefund = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PolicyDetails$Msg$InterruptionResponseReceived = function (a) {
	return {$: 20, a: a};
};
var $author$project$Page$PolicyDetails$Msg$IssueRefundResponseReceived = function (a) {
	return {$: 26, a: a};
};
var $author$project$Common$Gql$CustomerId$PolicyId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$Gql$PaymentMethod$PolicyId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationResponseReceived = function (a) {
	return {$: 34, a: a};
};
var $author$project$Page$PolicyDetails$Msg$SendPolicyContractEmailResponseReceived = function (a) {
	return {$: 39, a: a};
};
var $author$project$Common$Policy$pickId = function (_v0) {
	var policy = _v0;
	return policy.iO;
};
var $author$project$Common$Gql$PolicyCheckEligibility$CheckEligibilityCommonData = F2(
	function (policyId, interruptionAt) {
		return {aL: interruptionAt, m_: policyId};
	});
var $author$project$Common$Gql$PolicyCheckEligibility$TerminationByCustomer = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$Common$Gql$PolicyCheckEligibility$terminationByCustomer = F3(
	function (policyId, interruptionDate, reason) {
		return A2(
			$author$project$Common$Gql$PolicyCheckEligibility$TerminationByCustomer,
			A2($author$project$Common$Gql$PolicyCheckEligibility$CheckEligibilityCommonData, policyId, interruptionDate),
			reason);
	});
var $author$project$Common$Gql$PolicyCheckEligibility$TerminationByPrima = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Common$Gql$PolicyCheckEligibility$terminationByPrima = F3(
	function (policyId, interruptionDate, reason) {
		return A2(
			$author$project$Common$Gql$PolicyCheckEligibility$TerminationByPrima,
			A2($author$project$Common$Gql$PolicyCheckEligibility$CheckEligibilityCommonData, policyId, interruptionDate),
			reason);
	});
var $author$project$Common$Gql$PolicyCheckEligibility$Withdrawal = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$Gql$PolicyCheckEligibility$withdrawal = F2(
	function (policyId, interruptionDate) {
		return $author$project$Common$Gql$PolicyCheckEligibility$Withdrawal(
			A2($author$project$Common$Gql$PolicyCheckEligibility$CheckEligibilityCommonData, policyId, interruptionDate));
	});
var $author$project$Page$PolicyDetails$Effect$createCheckEligibilityArgumentFromModel = F2(
	function (policy, kindData) {
		switch (kindData.$) {
			case 0:
				var data = kindData.a;
				return $elm$core$Maybe$Just(
					A2(
						$author$project$Common$Gql$PolicyCheckEligibility$withdrawal,
						$author$project$Common$Policy$pickId(policy),
						data.nc));
			case 1:
				var data = kindData.a;
				return A2(
					$elm$core$Maybe$map,
					A2(
						$author$project$Common$Gql$PolicyCheckEligibility$terminationByCustomer,
						$author$project$Common$Policy$pickId(policy),
						data.aL),
					data.i3);
			default:
				var data = kindData.a;
				return A2(
					$elm$core$Maybe$map,
					A2(
						$author$project$Common$Gql$PolicyCheckEligibility$terminationByPrima,
						$author$project$Common$Policy$pickId(policy),
						data.aL),
					data.i3);
		}
	});
var $elm_community$maybe_extra$Maybe$Extra$join = function (mx) {
	if (!mx.$) {
		var x = mx.a;
		return x;
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickKindData = function (_v0) {
	var model = _v0;
	return model.aj;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickPolicy = function (_v0) {
	var model = _v0;
	return model.j;
};
var $author$project$Page$PolicyDetails$Effect$createCheckEligibilityArgument = function (_v0) {
	var interruptionModal = _v0.o;
	return $elm_community$maybe_extra$Maybe$Extra$join(
		A3(
			$elm$core$Maybe$map2,
			$author$project$Page$PolicyDetails$Effect$createCheckEligibilityArgumentFromModel,
			$author$project$Page$PolicyDetails$InterruptionModal$Model$pickPolicy(interruptionModal),
			$author$project$Page$PolicyDetails$InterruptionModal$Model$pickKindData(interruptionModal)));
};
var $author$project$Gql$Platform$InputObject$encodeBackofficeCreateRefundRequest = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'policyId',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.m_)))
			]));
};
var $author$project$Gql$Platform$Mutation$createRefund = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'createRefund',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'request', requiredArgs____.ev, $author$project$Gql$Platform$InputObject$encodeBackofficeCreateRefundRequest)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Page$PolicyDetails$Gql$createRefund = function (policyId) {
	return {
		ev: {m_: policyId}
	};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue = function (a) {
	return {$: 0, a: a};
};
var $dillonkearns$elm_graphql$Graphql$Internal$Encode$enum = F2(
	function (enumToString, value) {
		return $dillonkearns$elm_graphql$Graphql$Internal$Encode$EnumValue(
			enumToString(value));
	});
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'COMPETITOR_OFFER';
		case 1:
			return 'VEHICLE_SOLD';
		case 2:
			return 'VEHICLE_NOT_PURCHASED';
		case 3:
			return 'VEHICLE_DEREGISTRATION';
		case 4:
			return 'NO_INSURANCE_WANTED';
		case 5:
			return 'DOES_NOT_KNOW';
		case 6:
			return 'WANTS_GREEN_CARD';
		case 7:
			return 'INCORRECT_EFFECTIVE_DATE';
		case 8:
			return 'INCORRECT_PERSONAL_DATA';
		case 9:
			return 'INCORRECT_DATA_OTHER';
		case 10:
			return 'PAYMENT_METHOD_OR_DATE';
		case 11:
			return 'DECEASED_POLICY_HOLDER';
		case 12:
			return 'COVERAGE_CHANGE';
		case 13:
			return 'DISSATISFACTION_SERVICE';
		case 14:
			return 'UNDERWRITING_RULES';
		case 15:
			return 'MOVING_TO_ANOTHER_COUNTRY';
		case 16:
			return 'PRODUCT_SERVICE';
		case 17:
			return 'POLICY_HOLDER_CHANGE';
		case 18:
			return 'PRICE_INCREASE';
		default:
			return 'OTHER';
	}
};
var $author$project$Gql$Platform$InputObject$encodeCustomerDeclineRenewalReasonInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kind',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString, input____.lB))),
				_Utils_Tuple2(
				'description',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kR, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeCustomerDeclineRenewalInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.iO))),
				_Utils_Tuple2(
				'requestedAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.p))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					$author$project$Gql$Platform$InputObject$encodeCustomerDeclineRenewalReasonInput(input____.g8)))
			]));
};
var $author$project$Gql$Platform$Mutation$customerDeclineRenewal = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'customerDeclineRenewal',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'input', requiredArgs____.bt, $author$project$Gql$Platform$InputObject$encodeCustomerDeclineRenewalInput)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'COMPETITOR_OFFER';
		case 1:
			return 'VEHICLE_SOLD';
		case 2:
			return 'VEHICLE_NOT_PURCHASED';
		case 3:
			return 'VEHICLE_DEREGISTRATION';
		case 4:
			return 'NO_INSURANCE_WANTED';
		case 5:
			return 'DOES_NOT_KNOW';
		case 6:
			return 'WANTS_GREEN_CARD';
		case 7:
			return 'INCORRECT_EFFECTIVE_DATE';
		case 8:
			return 'INCORRECT_PERSONAL_DATA';
		case 9:
			return 'INCORRECT_DATA_OTHER';
		case 10:
			return 'PAYMENT_METHOD_OR_DATE';
		case 11:
			return 'DECEASED_POLICY_HOLDER';
		case 12:
			return 'COVERAGE_CHANGE';
		case 13:
			return 'DISSATISFACTION_SERVICE';
		case 14:
			return 'UNDERWRITING_RULES';
		case 15:
			return 'MOVING_TO_ANOTHER_COUNTRY';
		case 16:
			return 'PRODUCT_SERVICE';
		case 17:
			return 'POLICY_HOLDER_CHANGE';
		default:
			return 'OTHER';
	}
};
var $author$project$Gql$Platform$InputObject$encodeCustomerTerminateReasonInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kind',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString, input____.lB))),
				_Utils_Tuple2(
				'description',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kR, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeCustomerTerminateInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.iO))),
				_Utils_Tuple2(
				'requestedAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.p))),
				_Utils_Tuple2(
				'interruptionAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.aL))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					$author$project$Gql$Platform$InputObject$encodeCustomerTerminateReasonInput(input____.g8)))
			]));
};
var $author$project$Gql$Platform$Mutation$customerTerminatePolicy = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'customerTerminatePolicy',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'interruption', requiredArgs____.cw, $author$project$Gql$Platform$InputObject$encodeCustomerTerminateInput)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe = function (maybeValue) {
	if (!maybeValue.$) {
		var value = maybeValue.a;
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(value);
	} else {
		return $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent;
	}
};
var $author$project$Page$PolicyDetails$Gql$customerTerminatePolicy = function (_v0) {
	var id = _v0.iO;
	var requestedAt = _v0.p;
	var interruptionAt = _v0.aL;
	var reason = _v0.g8;
	var description = _v0.kR;
	return {
		cw: {
			iO: id,
			aL: interruptionAt,
			g8: {
				kR: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(description),
				lB: reason
			},
			p: requestedAt
		}
	};
};
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'COMPETITOR_OFFER';
		case 1:
			return 'VEHICLE_SOLD';
		case 2:
			return 'VEHICLE_NOT_PURCHASED';
		case 3:
			return 'VEHICLE_DEREGISTRATION';
		case 4:
			return 'NO_INSURANCE_WANTED';
		case 5:
			return 'DOES_NOT_KNOW';
		case 6:
			return 'WANTS_GREEN_CARD';
		case 7:
			return 'INCORRECT_EFFECTIVE_DATE';
		case 8:
			return 'INCORRECT_DATA_OTHER';
		case 9:
			return 'INCORRECT_PERSONAL_DATA';
		case 10:
			return 'PAYMENT_METHOD_OR_DATE';
		case 11:
			return 'DECEASED_POLICY_HOLDER';
		case 12:
			return 'COVERAGE_CHANGE';
		case 13:
			return 'DISSATISFACTION_SERVICE';
		case 14:
			return 'UNDERWRITING_RULES';
		case 15:
			return 'MOVING_TO_ANOTHER_COUNTRY';
		default:
			return 'OTHER';
	}
};
var $author$project$Gql$Platform$InputObject$encodeCustomerWithdrawReasonInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kind',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$CustomerWithdrawReason$toString, input____.lB))),
				_Utils_Tuple2(
				'description',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kR, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeCustomerWithdrawInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.iO))),
				_Utils_Tuple2(
				'requestedAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.p))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					$author$project$Gql$Platform$InputObject$encodeCustomerWithdrawReasonInput(input____.g8)))
			]));
};
var $author$project$Gql$Platform$Mutation$customerWithdrawPolicy = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'customerWithdrawPolicy',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'interruption', requiredArgs____.cw, $author$project$Gql$Platform$InputObject$encodeCustomerWithdrawInput)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Page$PolicyDetails$Msg$GotExpectedRefund = function (a) {
	return {$: 8, a: a};
};
var $author$project$Gql$Platform$InputObject$encodeBackofficePotentialCustomerTermination = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'interruptionAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.aL))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString, input____.g8)))
			]));
};
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString = function (enum____) {
	switch (enum____) {
		case 0:
			return 'INEXACT_DECLARATION_SINCO';
		case 1:
			return 'FRAUD';
		case 2:
			return 'CHARGEBACK';
		case 3:
			return 'INEXACT_DECLARATION_DNI';
		case 4:
			return 'RISK_AGGRAVATION';
		default:
			return 'PHOTO_VERIFICATION_NOT_PROVIDED';
	}
};
var $author$project$Gql$Platform$InputObject$encodeBackofficePotentialPrimaTermination = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'interruptionAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.aL))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString, input____.g8)))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeBackofficePotentialWithdrawal = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'interruptionAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.aL)))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeBackofficePotentialInterruptionDetails = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'withdrawal',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.hO, $author$project$Gql$Platform$InputObject$encodeBackofficePotentialWithdrawal)),
				_Utils_Tuple2(
				'customerTermination',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.fx, $author$project$Gql$Platform$InputObject$encodeBackofficePotentialCustomerTermination)),
				_Utils_Tuple2(
				'primaTermination',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g5, $author$project$Gql$Platform$InputObject$encodeBackofficePotentialPrimaTermination))
			]));
};
var $author$project$Gql$Platform$InputObject$encodeBackofficeEligibilityRequest = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'policyId',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.m_))),
				_Utils_Tuple2(
				'interruptionDetails',
				$elm$core$Maybe$Just(
					$author$project$Gql$Platform$InputObject$encodeBackofficePotentialInterruptionDetails(input____.ls)))
			]));
};
var $author$project$Gql$Platform$Query$checkEligibility = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'checkEligibility',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'request', requiredArgs____.ev, $author$project$Gql$Platform$InputObject$encodeBackofficeEligibilityRequest)
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Gql$Platform$Query$CheckEligibilityRequiredArguments = function (request) {
	return {ev: request};
};
var $author$project$Common$Gql$PolicyCheckEligibility$customerTerminateInputRequiredFields = F2(
	function (interruptionAt, reason) {
		return {aL: interruptionAt, g8: reason};
	});
var $author$project$Common$Gql$PolicyCheckEligibility$customerWithdrawInputRequiredFields = function (interruptionAt) {
	return {aL: interruptionAt};
};
var $author$project$Common$Gql$PolicyCheckEligibility$primaTerminateInputRequiredFields = F2(
	function (interruptionAt, reason) {
		return {aL: interruptionAt, g8: reason};
	});
var $author$project$Common$Gql$PolicyCheckEligibility$backofficeInterruptionDetails = function (claimEligibilityArg) {
	switch (claimEligibilityArg.$) {
		case 0:
			var commonData = claimEligibilityArg.a;
			return {
				fx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				g5: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				hO: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					$author$project$Common$Gql$PolicyCheckEligibility$customerWithdrawInputRequiredFields(commonData.aL))
			};
		case 1:
			var commonData = claimEligibilityArg.a;
			var reason = claimEligibilityArg.b;
			return {
				fx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					A2($author$project$Common$Gql$PolicyCheckEligibility$customerTerminateInputRequiredFields, commonData.aL, reason)),
				g5: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				hO: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
			};
		default:
			var commonData = claimEligibilityArg.a;
			var reason = claimEligibilityArg.b;
			return {
				fx: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
				g5: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
					A2($author$project$Common$Gql$PolicyCheckEligibility$primaTerminateInputRequiredFields, commonData.aL, reason)),
				hO: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
			};
	}
};
var $author$project$Common$Gql$PolicyCheckEligibility$pickPolicyId = function (argument) {
	switch (argument.$) {
		case 0:
			var policyId = argument.a.m_;
			return policyId;
		case 1:
			var policyId = argument.a.m_;
			return policyId;
		default:
			var policyId = argument.a.m_;
			return policyId;
	}
};
var $author$project$Common$Gql$PolicyCheckEligibility$checkEligibilityRequiredArguments = function (claimEligibilityArg) {
	return $author$project$Gql$Platform$Query$CheckEligibilityRequiredArguments(
		{
			ls: $author$project$Common$Gql$PolicyCheckEligibility$backofficeInterruptionDetails(claimEligibilityArg),
			m_: $author$project$Common$Gql$PolicyCheckEligibility$pickPolicyId(claimEligibilityArg)
		});
};
var $author$project$Common$ExpectedRefund$ExpectedRefund = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$ExpectedRefund$refund = function (price) {
	return $author$project$Common$ExpectedRefund$ExpectedRefund(price);
};
var $author$project$Gql$Platform$Object$BackofficeEligible$refund = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'refund',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Common$Gql$PolicyCheckEligibility$decodeEligible = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeEligible$refund),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$ExpectedRefund$refund));
var $author$project$Common$ExpectedRefund$NoExpectedRefund = function (a) {
	return {$: 0, a: a};
};
var $author$project$Common$ExpectedRefund$noRefund = function (price) {
	return $author$project$Common$ExpectedRefund$NoExpectedRefund(price);
};
var $author$project$Gql$Platform$Object$BackofficeIneligible$refund = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.GqlDecimal',
	'refund',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).cg.ah);
var $author$project$Common$Gql$PolicyCheckEligibility$decodeIneligible = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$BackofficeIneligible$refund),
	$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$ExpectedRefund$noRefund));
var $author$project$Gql$Platform$Union$BackofficeEligibility$fragments = function (selections____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$exhaustiveFragmentSelection(
		_List_fromArray(
			[
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeEligible', selections____.mg),
				A2($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$buildFragment, 'BackofficeIneligible', selections____.mi)
			]));
};
var $author$project$Common$Gql$PolicyCheckEligibility$eligibilitySelectionSet = $author$project$Gql$Platform$Union$BackofficeEligibility$fragments(
	{mg: $author$project$Common$Gql$PolicyCheckEligibility$decodeEligible, mi: $author$project$Common$Gql$PolicyCheckEligibility$decodeIneligible});
var $author$project$Common$Gql$PolicyCheckEligibility$checkEligibilityQuery = function (claimEligibilityArg) {
	return A2(
		$author$project$Gql$Platform$Query$checkEligibility,
		$author$project$Common$Gql$PolicyCheckEligibility$checkEligibilityRequiredArguments(claimEligibilityArg),
		$author$project$Common$Gql$PolicyCheckEligibility$eligibilitySelectionSet);
};
var $author$project$Page$PolicyDetails$Effect$executeCheckEligibilityQuery = F2(
	function (env, checkEligibilityArgument) {
		return A4(
			$author$project$Api$executeGraphqlOperation,
			env,
			$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
			$author$project$Common$Gql$PolicyCheckEligibility$checkEligibilityQuery(checkEligibilityArgument),
			A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$GotExpectedRefund));
	});
var $author$project$Gql$Platform$Query$GetRefundRequiredArguments = function (policyId) {
	return {m_: policyId};
};
var $author$project$Gql$Platform$Query$getRefund = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'getRefund',
			_List_fromArray(
				[
					A3(
					$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
					'policyId',
					requiredArgs____.m_,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs))
				]),
			object____,
			$elm$core$Basics$identity);
	});
var $author$project$Common$Refund$Completed = 2;
var $author$project$Common$Refund$Failed = 3;
var $author$project$Common$Refund$NotRequired = 4;
var $author$project$Common$Refund$Pending = 0;
var $author$project$Common$Refund$ToBePaid = 1;
var $author$project$Common$Gql$GetRefund$decodeStatus = function (status) {
	switch (status) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		case 3:
			return 3;
		default:
			return 4;
	}
};
var $author$project$Gql$Platform$Object$BackofficeRefund$eligibility = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'eligibility', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeRefund$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Common$Refund$Refund = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Common$Refund$new = F3(
	function (id, expectedRefund, status) {
		return A2(
			$author$project$Common$Refund$Refund,
			{k0: expectedRefund, iO: id},
			status);
	});
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$Completed = 2;
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$Failed = 3;
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$NotRequired = 4;
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$Pending = 0;
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$ToBePaid = 1;
var $author$project$Gql$Platform$Enum$BackofficeRefundStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'PENDING':
				return $elm$json$Json$Decode$succeed(0);
			case 'TO_BE_PAID':
				return $elm$json$Json$Decode$succeed(1);
			case 'COMPLETED':
				return $elm$json$Json$Decode$succeed(2);
			case 'FAILED':
				return $elm$json$Json$Decode$succeed(3);
			case 'NOT_REQUIRED':
				return $elm$json$Json$Decode$succeed(4);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeRefundStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeRefund$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeRefundStatus.BackofficeRefundStatus', 'status', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeRefundStatus$decoder);
var $author$project$Common$Gql$GetRefund$getRefundSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$GetRefund$decodeStatus, $author$project$Gql$Platform$Object$BackofficeRefund$status),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeRefund$eligibility($author$project$Common$Gql$PolicyCheckEligibility$eligibilitySelectionSet),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeRefund$id,
			$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Refund$new))));
var $author$project$Common$Gql$GetRefund$getRefundQuery = function (policyId) {
	return A2(
		$author$project$Gql$Platform$Query$getRefund,
		$author$project$Gql$Platform$Query$GetRefundRequiredArguments(policyId),
		$author$project$Common$Gql$GetRefund$getRefundSelectionSet);
};
var $author$project$Gql$Platform$Enum$BackofficeIssueReason$toString = function (enum____) {
	return 'OTHER';
};
var $author$project$Gql$Platform$InputObject$encodeBackofficeIssueRefundRequest = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'refundId',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.cY))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$BackofficeIssueReason$toString, input____.g8)))
			]));
};
var $author$project$Gql$Platform$Mutation$issueRefund = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'issueRefund',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'request', requiredArgs____.ev, $author$project$Gql$Platform$InputObject$encodeBackofficeIssueRefundRequest)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Gql$Platform$Enum$BackofficeIssueReason$Other = 0;
var $author$project$Page$PolicyDetails$Gql$issueRefund = function (refundId) {
	return {
		ev: {g8: 0, cY: refundId}
	};
};
var $author$project$Common$Refund$pickId = function (_v0) {
	var refundData = _v0.a;
	return refundData.iO;
};
var $author$project$Gql$Platform$Query$policy = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'policy',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'identifier', requiredArgs____.bs, $author$project$Gql$Platform$InputObject$encodePolicyIdentifier)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Gql$Platform$Query$PolicyRequiredArguments = function (identifier) {
	return {bs: identifier};
};
var $author$project$Common$Gql$Policy$policyByIdRequiredArguments = function (id) {
	return $author$project$Gql$Platform$Query$PolicyRequiredArguments(
		{
			m_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(id)
		});
};
var $author$project$Gql$Platform$Object$BackofficePolicy$applicationId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'applicationId',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Gql$Platform$Object$BackofficePolicy$applicationSource = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeApplicationSource.BackofficeApplicationSource', 'applicationSource', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeApplicationSource$decoder);
var $author$project$Gql$Platform$Object$BackofficePolicy$claimsDeclaredWithFault = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'claimsDeclaredWithFault',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Gql$Platform$Object$BackofficePolicy$coolingOffPeriodEndAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.DateTime)',
	'coolingOffPeriodEndAt',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah));
var $author$project$Gql$Platform$Object$BackofficePolicy$coverContinuitySummary = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'coverContinuitySummary', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePolicy$customerFacingId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'customerFacingId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Policy$Active = 1;
var $author$project$Common$Policy$Expired = 3;
var $author$project$Common$Policy$GracePeriod = 2;
var $author$project$Common$Policy$Issued = 0;
var $author$project$Common$Policy$NotRenewed = 4;
var $author$project$Common$Policy$TerminatedByCustomer = 6;
var $author$project$Common$Policy$TerminatedByPrima = 7;
var $author$project$Common$Policy$Withdrawn = 5;
var $author$project$Common$Gql$Policy$decodePolicyStatus = function (status) {
	switch (status) {
		case 1:
			return 1;
		case 2:
			return 3;
		case 0:
			return 0;
		case 4:
			return 6;
		case 5:
			return 7;
		case 3:
			return 5;
		case 6:
			return 2;
		default:
			return 4;
	}
};
var $author$project$Gql$Platform$Object$BackofficePolicy$endDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'endDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$BackofficePolicy$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $author$project$Gql$Platform$Object$BackofficePolicy$interruption = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'interruption',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Common$Policy$Interruption = F4(
	function (at, kind, reason, description) {
		return {kt: at, kR: description, lB: kind, g8: reason};
	});
var $author$project$Gql$Platform$Object$PolicyInterruption$at = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'at',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Common$InterruptionKind$TerminationByCustomer = 1;
var $author$project$Common$InterruptionKind$TerminationByPrima = 2;
var $author$project$Common$InterruptionKind$Withdrawal = 0;
var $author$project$Common$Gql$PolicyInterruption$decodePolicyInterruptionKind = function (kind) {
	switch (kind) {
		case 0:
			return 0;
		case 1:
			return 1;
		default:
			return 2;
	}
};
var $author$project$Common$InterruptionReason$Chargeback = 0;
var $author$project$Common$InterruptionReason$CompetitorOffer = 1;
var $author$project$Common$InterruptionReason$CoverageChange = 2;
var $author$project$Common$InterruptionReason$DeceasedPolicyHolder = 3;
var $author$project$Common$InterruptionReason$DissatisfactionDocsRequest = 4;
var $author$project$Common$InterruptionReason$DissatisfactionService = 5;
var $author$project$Common$InterruptionReason$DoesNotKnow = 6;
var $author$project$Common$InterruptionReason$Fraud = 7;
var $author$project$Common$InterruptionReason$IncorrectDataOther = 8;
var $author$project$Common$InterruptionReason$IncorrectEffectiveDate = 9;
var $author$project$Common$InterruptionReason$IncorrectPersonalData = 10;
var $author$project$Common$InterruptionReason$IncorrectVehicleInfo = 11;
var $author$project$Common$InterruptionReason$InexactDeclaration = 12;
var $author$project$Common$InterruptionReason$InexactDeclarationDni = 13;
var $author$project$Common$InterruptionReason$InexactDeclarationSinco = 14;
var $author$project$Common$InterruptionReason$MovingToAnotherCountry = 15;
var $author$project$Common$InterruptionReason$NoInsuranceWanted = 16;
var $author$project$Common$InterruptionReason$NoReason = 17;
var $author$project$Common$InterruptionReason$Other = 18;
var $author$project$Common$InterruptionReason$PaymentMethodOrDate = 19;
var $author$project$Common$InterruptionReason$PhotoVerificationNotProvided = 32;
var $author$project$Common$InterruptionReason$PolicyHolderChange = 20;
var $author$project$Common$InterruptionReason$PriceIncrease = 31;
var $author$project$Common$InterruptionReason$ProductService = 21;
var $author$project$Common$InterruptionReason$RenewalNotProvided = 22;
var $author$project$Common$InterruptionReason$RenewalRejected = 23;
var $author$project$Common$InterruptionReason$RiskAggravation = 24;
var $author$project$Common$InterruptionReason$UnderwritingRules = 25;
var $author$project$Common$InterruptionReason$VehicleDeregistration = 26;
var $author$project$Common$InterruptionReason$VehicleNotPurchased = 27;
var $author$project$Common$InterruptionReason$VehicleScrapped = 28;
var $author$project$Common$InterruptionReason$VehicleSold = 29;
var $author$project$Common$InterruptionReason$WantsGreenCard = 30;
var $author$project$Common$Gql$PolicyInterruption$decodePolicyInterruptionReason = function (reasonKind) {
	switch (reasonKind) {
		case 0:
			return 17;
		case 1:
			return 9;
		case 2:
			return 1;
		case 3:
			return 11;
		case 4:
			return 30;
		case 5:
			return 10;
		case 6:
			return 4;
		case 7:
			return 29;
		case 8:
			return 28;
		case 9:
			return 3;
		case 10:
			return 15;
		case 11:
			return 5;
		case 12:
			return 16;
		case 13:
			return 7;
		case 14:
			return 24;
		case 15:
			return 12;
		case 16:
			return 18;
		case 17:
			return 27;
		case 18:
			return 26;
		case 19:
			return 6;
		case 21:
			return 19;
		case 22:
			return 2;
		case 23:
			return 25;
		case 24:
			return 14;
		case 25:
			return 0;
		case 26:
			return 13;
		case 20:
			return 8;
		case 28:
			return 22;
		case 27:
			return 23;
		case 30:
			return 20;
		case 31:
			return 21;
		case 32:
			return 31;
		default:
			return 32;
	}
};
var $author$project$Gql$Platform$Object$PolicyInterruption$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Enum$PolicyInterruptionKind$TerminatedByCustomer = 1;
var $author$project$Gql$Platform$Enum$PolicyInterruptionKind$TerminatedByPrima = 2;
var $author$project$Gql$Platform$Enum$PolicyInterruptionKind$Withdrawn = 0;
var $author$project$Gql$Platform$Enum$PolicyInterruptionKind$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'WITHDRAWN':
				return $elm$json$Json$Decode$succeed(0);
			case 'TERMINATED_BY_CUSTOMER':
				return $elm$json$Json$Decode$succeed(1);
			case 'TERMINATED_BY_PRIMA':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid PolicyInterruptionKind type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyInterruption$kind = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PolicyInterruptionKind.PolicyInterruptionKind', 'kind', _List_Nil, $author$project$Gql$Platform$Enum$PolicyInterruptionKind$decoder);
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$Chargeback = 25;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$CompetitorOffer = 2;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$CoverageChange = 22;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$DeceasedPolicyHolder = 9;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$DissatisfactionDocsRequest = 6;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$DissatisfactionService = 11;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$DoesNotKnow = 19;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$Fraud = 13;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$IncorrectDataOther = 20;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$IncorrectEffectiveDate = 1;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$IncorrectPersonalData = 5;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$IncorrectVehicleInfo = 3;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$InexactDeclaration = 15;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$InexactDeclarationDni = 26;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$InexactDeclarationSinco = 24;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$MovingToAnotherCountry = 10;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$NoInsuranceWanted = 12;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$NoReason = 0;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$Other = 16;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$PaymentMethodOrDate = 21;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$PhotoVerificationNotProvided = 29;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$PolicyHolderChange = 30;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$PriceIncrease = 32;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$ProductService = 31;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$RenewalNotProvided = 28;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$RenewalRejected = 27;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$RiskAggravation = 14;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$UnderwritingRules = 23;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$VehicleDeregistration = 18;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$VehicleNotPurchased = 17;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$VehicleScrapped = 8;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$VehicleSold = 7;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$WantsGreenCard = 4;
var $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'NO_REASON':
				return $elm$json$Json$Decode$succeed(0);
			case 'INCORRECT_EFFECTIVE_DATE':
				return $elm$json$Json$Decode$succeed(1);
			case 'COMPETITOR_OFFER':
				return $elm$json$Json$Decode$succeed(2);
			case 'INCORRECT_VEHICLE_INFO':
				return $elm$json$Json$Decode$succeed(3);
			case 'WANTS_GREEN_CARD':
				return $elm$json$Json$Decode$succeed(4);
			case 'INCORRECT_PERSONAL_DATA':
				return $elm$json$Json$Decode$succeed(5);
			case 'DISSATISFACTION_DOCS_REQUEST':
				return $elm$json$Json$Decode$succeed(6);
			case 'VEHICLE_SOLD':
				return $elm$json$Json$Decode$succeed(7);
			case 'VEHICLE_SCRAPPED':
				return $elm$json$Json$Decode$succeed(8);
			case 'DECEASED_POLICY_HOLDER':
				return $elm$json$Json$Decode$succeed(9);
			case 'MOVING_TO_ANOTHER_COUNTRY':
				return $elm$json$Json$Decode$succeed(10);
			case 'DISSATISFACTION_SERVICE':
				return $elm$json$Json$Decode$succeed(11);
			case 'NO_INSURANCE_WANTED':
				return $elm$json$Json$Decode$succeed(12);
			case 'FRAUD':
				return $elm$json$Json$Decode$succeed(13);
			case 'RISK_AGGRAVATION':
				return $elm$json$Json$Decode$succeed(14);
			case 'INEXACT_DECLARATION':
				return $elm$json$Json$Decode$succeed(15);
			case 'OTHER':
				return $elm$json$Json$Decode$succeed(16);
			case 'VEHICLE_NOT_PURCHASED':
				return $elm$json$Json$Decode$succeed(17);
			case 'VEHICLE_DEREGISTRATION':
				return $elm$json$Json$Decode$succeed(18);
			case 'DOES_NOT_KNOW':
				return $elm$json$Json$Decode$succeed(19);
			case 'INCORRECT_DATA_OTHER':
				return $elm$json$Json$Decode$succeed(20);
			case 'PAYMENT_METHOD_OR_DATE':
				return $elm$json$Json$Decode$succeed(21);
			case 'COVERAGE_CHANGE':
				return $elm$json$Json$Decode$succeed(22);
			case 'UNDERWRITING_RULES':
				return $elm$json$Json$Decode$succeed(23);
			case 'INEXACT_DECLARATION_SINCO':
				return $elm$json$Json$Decode$succeed(24);
			case 'CHARGEBACK':
				return $elm$json$Json$Decode$succeed(25);
			case 'INEXACT_DECLARATION_DNI':
				return $elm$json$Json$Decode$succeed(26);
			case 'RENEWAL_REJECTED':
				return $elm$json$Json$Decode$succeed(27);
			case 'RENEWAL_NOT_PROVIDED':
				return $elm$json$Json$Decode$succeed(28);
			case 'PHOTO_VERIFICATION_NOT_PROVIDED':
				return $elm$json$Json$Decode$succeed(29);
			case 'POLICY_HOLDER_CHANGE':
				return $elm$json$Json$Decode$succeed(30);
			case 'PRODUCT_SERVICE':
				return $elm$json$Json$Decode$succeed(31);
			case 'PRICE_INCREASE':
				return $elm$json$Json$Decode$succeed(32);
			default:
				return $elm$json$Json$Decode$fail('Invalid PolicyInterruptionReasonKind type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyInterruption$reason = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PolicyInterruptionReasonKind.PolicyInterruptionReasonKind', 'reason', _List_Nil, $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$decoder);
var $author$project$Common$Gql$Policy$interruptionSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PolicyInterruption$description,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$PolicyInterruption$decodePolicyInterruptionReason, $author$project$Gql$Platform$Object$PolicyInterruption$reason),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$PolicyInterruption$decodePolicyInterruptionKind, $author$project$Gql$Platform$Object$PolicyInterruption$kind),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$PolicyInterruption$at,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Policy$Interruption)))));
var $author$project$Gql$Platform$Object$BackofficePolicy$isARenewal = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Bool', 'isARenewal', _List_Nil, $elm$json$Json$Decode$bool);
var $author$project$Gql$Platform$Object$BackofficePolicy$latestPolicyHolderEmail = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'latestPolicyHolderEmail', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePolicy$mainDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'mainDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$PolicyMainDriver$postalCode = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'postalCode', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyMainDriver$province = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'province', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyMainDriver$streetName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyMainDriver$streetNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'streetNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$mainDriverAddressSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PolicyMainDriver$postalCode,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PolicyMainDriver$province,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$PolicyMainDriver$streetNumber,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$PolicyMainDriver$streetName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Address$Address)))));
var $author$project$Gql$Platform$Object$PolicyMainDriver$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$PolicyMainDriver$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyMainDriver$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyMainDriver$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$mainDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PolicyMainDriver$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PolicyMainDriver$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$PolicyMainDriver$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$PolicyMainDriver$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Policy$mainDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Common$Gql$Policy$mainDriverAddressSelectionSet,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Policy$mainDriverPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Common$Policy$Policy = $elm$core$Basics$identity;
var $author$project$Common$Policy$new = function (id) {
	return function (applicationId) {
		return function (quoteId) {
			return function (customerFacingId) {
				return function (startDate) {
					return function (endDate) {
						return function (purchasedAt) {
							return function (coolingOffPeriodEndAt) {
								return function (vehicleModel) {
									return function (vehicleMaker) {
										return function (vehiclePlateNumber) {
											return function (status) {
												return function (interruption) {
													return function (products) {
														return function (mainDriver) {
															return function (policyHolder) {
																return function (vehicleOwner) {
																	return function (additionalDriver) {
																		return function (claimsDeclaredWithFault) {
																			return function (vehicle) {
																				return function (coverContinuity) {
																					return function (selectedBundle) {
																						return function (isARenewal) {
																							return function (renewalPolicyId) {
																								return function (renewalCancellation) {
																									return function (source) {
																										return function (latestPolicyHolderEmail) {
																											return {hS: additionalDriver, e1: applicationId, fi: claimsDeclaredWithFault, ci: coolingOffPeriodEndAt, $8: coverContinuity, kN: customerFacingId, dI: endDate, iO: id, cw: interruption, f7: isARenewal, gc: latestPolicyHolderEmail, d5: mainDriver, g2: policyHolder, ep: products, g6: purchasedAt, m5: quoteId, hc: renewalCancellation, hd: renewalPolicyId, hg: selectedBundle, hm: source, np: startDate, c1: status, hL: vehicle, j5: vehicleMaker, j6: vehicleModel, j7: vehicleOwner, j8: vehiclePlateNumber};
																										};
																									};
																								};
																							};
																						};
																					};
																				};
																			};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Gql$Platform$Object$BackofficePolicy$occasionalDriver = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'occasionalDriver', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$PolicyOccasionalDriver$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.NaiveDate)',
	'birthDate',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah));
var $author$project$Common$DefaultValues$date = $justinmimbs$date$Date$fromRataDie(1);
var $author$project$Gql$Platform$Object$PolicyOccasionalDriver$documentId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'documentId',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyOccasionalDriver$firstName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'firstName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyOccasionalDriver$lastName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'lastName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$DefaultValues$string = '';
var $author$project$Common$Gql$Policy$occasionalDriverPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$DefaultValues$date, $author$project$Gql$Platform$Object$PolicyOccasionalDriver$birthDate),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$DefaultValues$string, $author$project$Gql$Platform$Object$PolicyOccasionalDriver$documentId),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$DefaultValues$string, $author$project$Gql$Platform$Object$PolicyOccasionalDriver$lastName),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, $author$project$Common$DefaultValues$string, $author$project$Gql$Platform$Object$PolicyOccasionalDriver$firstName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Policy$occasionalDriverSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Policy$occasionalDriverPersonDataSelectionSet,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$AdditionalDriver)));
var $author$project$Gql$Platform$Object$BackofficePolicy$plateNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'plateNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$decodeDocumentKind = function (status) {
	if (!status) {
		return 0;
	} else {
		return 1;
	}
};
var $author$project$Gql$Platform$Object$Policyholder$documentKind = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.DocumentKind.DocumentKind', 'documentKind', _List_Nil, $author$project$Gql$Platform$Enum$DocumentKind$decoder);
var $author$project$Gql$Platform$Object$Policyholder$postalCode = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'postalCode',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$Policyholder$province = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'province',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$Policyholder$streetName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$Policyholder$streetNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Policy$policyHolderAddressSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$Policyholder$postalCode),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$Policyholder$province),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$Policyholder$streetNumber),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$Policyholder$streetName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Address$Address)))));
var $author$project$Gql$Platform$Object$Policyholder$emailAddress = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'emailAddress', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$Policyholder$phoneNumber = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'phoneNumber', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$policyHolderContactsSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$Policyholder$phoneNumber,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$Policyholder$emailAddress,
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Contacts)));
var $author$project$Gql$Platform$Object$Policyholder$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$Policyholder$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$Policyholder$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$Policyholder$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$policyHolderPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$Policyholder$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$Policyholder$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$Policyholder$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$Policyholder$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Policy$policyHolderSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Policy$decodeDocumentKind, $author$project$Gql$Platform$Object$Policyholder$documentKind),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Policy$policyHolderContactsSelectionSet,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Common$Gql$Policy$policyHolderAddressSelectionSet,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Policy$policyHolderPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PolicyHolder)))));
var $author$project$Gql$Platform$Object$BackofficePolicy$policyholder = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'policyholder', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Product$PolicyProduct = F2(
	function (bundleSlug, annualPrice) {
		return {kr: annualPrice, kA: bundleSlug};
	});
var $author$project$Gql$Platform$Object$BackofficeProductInfo$annualPrice = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'annualPrice', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficeProductInfo$bundleSlug = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BundleSlug.BundleSlug', 'bundleSlug', _List_Nil, $author$project$Gql$Platform$Enum$BundleSlug$decoder);
var $author$project$Common$Gql$PolicyProduct$offerAnnualPriceSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxSurcharges),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxIps),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$net),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$taxes),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Price$euro, $author$project$Gql$Platform$Object$OfferAnnualPrice$gross),
					$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$AnnualPrice))))));
var $author$project$Common$Gql$PolicyProduct$policyProductSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeProductInfo$annualPrice($author$project$Common$Gql$PolicyProduct$offerAnnualPriceSet),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$BundleSlug$decodeBundleSlug, $author$project$Gql$Platform$Object$BackofficeProductInfo$bundleSlug),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Product$PolicyProduct)));
var $author$project$Common$Gql$Policy$productSelectionSet = $author$project$Common$Gql$PolicyProduct$policyProductSelectionSet;
var $author$project$Gql$Platform$Object$BackofficePolicy$products = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'products',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
};
var $author$project$Gql$Platform$Object$BackofficePolicy$purchasedAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'purchasedAt',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Gql$Platform$Object$BackofficePolicy$quoteId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Uuid)',
	'quoteId',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah));
var $author$project$Gql$Platform$Object$BackofficePolicy$renewalCancellation = function (object____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
		'renewalCancellation',
		_List_Nil,
		object____,
		A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
};
var $author$project$Common$Policy$RenewalCancellation = F2(
	function (reason, description) {
		return {kR: description, g8: reason};
	});
var $author$project$Gql$Platform$Object$PolicyRenewalCancellation$description = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'description',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyRenewalCancellation$reason = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PolicyInterruptionReasonKind.PolicyInterruptionReasonKind', 'reason', _List_Nil, $author$project$Gql$Platform$Enum$PolicyInterruptionReasonKind$decoder);
var $author$project$Common$Gql$Policy$renewalCancellationSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PolicyRenewalCancellation$description,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$PolicyInterruption$decodePolicyInterruptionReason, $author$project$Gql$Platform$Object$PolicyRenewalCancellation$reason),
		$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Policy$RenewalCancellation)));
var $author$project$Gql$Platform$Object$BackofficePolicy$renewalPolicyId = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe ScalarCodecs.Uuid)',
	'renewalPolicyId',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah));
var $author$project$Gql$Platform$Object$BackofficePolicy$selectedBundle = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeBundleSlug.BackofficeBundleSlug', 'selectedBundle', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeBundleSlug$decoder);
var $author$project$Gql$Platform$Object$BackofficePolicy$startDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'startDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Enum$PolicyStatus$Active = 1;
var $author$project$Gql$Platform$Enum$PolicyStatus$Expired = 2;
var $author$project$Gql$Platform$Enum$PolicyStatus$GracePeriod = 6;
var $author$project$Gql$Platform$Enum$PolicyStatus$Issued = 0;
var $author$project$Gql$Platform$Enum$PolicyStatus$NotRenewed = 7;
var $author$project$Gql$Platform$Enum$PolicyStatus$TerminatedByCustomer = 4;
var $author$project$Gql$Platform$Enum$PolicyStatus$TerminatedByPrima = 5;
var $author$project$Gql$Platform$Enum$PolicyStatus$Withdrawn = 3;
var $author$project$Gql$Platform$Enum$PolicyStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'ISSUED':
				return $elm$json$Json$Decode$succeed(0);
			case 'ACTIVE':
				return $elm$json$Json$Decode$succeed(1);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(2);
			case 'WITHDRAWN':
				return $elm$json$Json$Decode$succeed(3);
			case 'TERMINATED_BY_CUSTOMER':
				return $elm$json$Json$Decode$succeed(4);
			case 'TERMINATED_BY_PRIMA':
				return $elm$json$Json$Decode$succeed(5);
			case 'GRACE_PERIOD':
				return $elm$json$Json$Decode$succeed(6);
			case 'NOT_RENEWED':
				return $elm$json$Json$Decode$succeed(7);
			default:
				return $elm$json$Json$Decode$fail('Invalid PolicyStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePolicy$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.PolicyStatus.PolicyStatus', 'status', _List_Nil, $author$project$Gql$Platform$Enum$PolicyStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficePolicy$vehicleInfo = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicleInfo', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$BackofficePolicy$vehicleMaker = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'vehicleMaker', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePolicy$vehicleModel = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'vehicleModel', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficePolicy$vehicleOwner = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicleOwner', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$postalCode = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'postalCode',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$province = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'province',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$streetName = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetName',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$streetNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'streetNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Common$Gql$Policy$vehicleOwnerAddressSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$PolicyVehicleOwner$postalCode),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$PolicyVehicleOwner$province),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$PolicyVehicleOwner$streetNumber),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$PolicyVehicleOwner$streetName),
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Address$Address)))));
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$birthDate = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.NaiveDate',
	'birthDate',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).aF.ah);
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$documentId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'documentId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$firstName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'firstName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$PolicyVehicleOwner$lastName = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'lastName', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Policy$vehicleOwnerPersonDataSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$PolicyVehicleOwner$birthDate,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$PolicyVehicleOwner$documentId,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$PolicyVehicleOwner$lastName,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$PolicyVehicleOwner$firstName,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$PersonData)))));
var $author$project$Common$Gql$Policy$vehicleOwnerSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
	$elm$core$Maybe$Nothing,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		'',
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Common$Gql$Policy$vehicleOwnerAddressSelectionSet,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Common$Gql$Policy$vehicleOwnerPersonDataSelectionSet,
				$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Person$Driver)))));
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$accessories = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe (List Enum.BackofficeVehicleModification.BackofficeVehicleModification))',
	'accessories',
	_List_Nil,
	$elm$json$Json$Decode$nullable(
		$elm$json$Json$Decode$list($author$project$Gql$Platform$Enum$BackofficeVehicleModification$decoder)));
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$enginePower = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Int)',
	'enginePower',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$int));
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$expectedMileage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeMileage.BackofficeMileage', 'expectedMileage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeMileage$decoder);
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$fuelType = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe Enum.BackofficeFuelType.BackofficeFuelType)',
	'fuelType',
	_List_Nil,
	$elm$json$Json$Decode$nullable($author$project$Gql$Platform$Enum$BackofficeFuelType$decoder));
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$maker = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'maker', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$model = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'model', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$plateNumber = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'(Maybe String)',
	'plateNumber',
	_List_Nil,
	$elm$json$Json$Decode$nullable($elm$json$Json$Decode$string));
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$registrationDate = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'registrationDate', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$usage = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeVehicleUse.BackofficeVehicleUse', 'usage', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeVehicleUse$decoder);
var $author$project$Gql$Platform$Object$BackofficeVehicleInfo$version = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'version', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Common$Gql$Vehicle$vehicleSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeAccessories, $author$project$Gql$Platform$Object$BackofficeVehicleInfo$accessories),
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Gql$Platform$Object$BackofficeVehicleInfo$enginePower,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficeVehicleInfo$version,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeFuelType, $author$project$Gql$Platform$Object$BackofficeVehicleInfo$fuelType),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeUsage, $author$project$Gql$Platform$Object$BackofficeVehicleInfo$usage),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						$author$project$Gql$Platform$Object$BackofficeVehicleInfo$registrationDate,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Vehicle$decodeMileage, $author$project$Gql$Platform$Object$BackofficeVehicleInfo$expectedMileage),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2($dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault, '', $author$project$Gql$Platform$Object$BackofficeVehicleInfo$plateNumber),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									$author$project$Gql$Platform$Object$BackofficeVehicleInfo$maker,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficeVehicleInfo$model,
										$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Vehicle$new)))))))))));
var $author$project$Common$Gql$Policy$policySelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficePolicy$latestPolicyHolderEmail,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$ApplicationSource$decodeApplicationSource, $author$project$Gql$Platform$Object$BackofficePolicy$applicationSource),
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Gql$Platform$Object$BackofficePolicy$renewalCancellation($author$project$Common$Gql$Policy$renewalCancellationSelectionSet),
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				$author$project$Gql$Platform$Object$BackofficePolicy$renewalPolicyId,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					$author$project$Gql$Platform$Object$BackofficePolicy$isARenewal,
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$BundleSlug$decodeBackofficeBundleSlug, $author$project$Gql$Platform$Object$BackofficePolicy$selectedBundle),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficePolicy$coverContinuitySummary($author$project$Common$Gql$CoverContinuity$coverContinuitySelectionSet),
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficePolicy$vehicleInfo($author$project$Common$Gql$Vehicle$vehicleSelectionSet),
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$withDefault,
										$author$project$Common$Count$default,
										$author$project$Gql$Platform$Object$BackofficePolicy$claimsDeclaredWithFault($author$project$Common$Gql$ClaimsCount$claimsDeclaredWithFaultSelectionSet)),
									A2(
										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
										$author$project$Gql$Platform$Object$BackofficePolicy$occasionalDriver($author$project$Common$Gql$Policy$occasionalDriverSelectionSet),
										A2(
											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
											$author$project$Gql$Platform$Object$BackofficePolicy$vehicleOwner($author$project$Common$Gql$Policy$vehicleOwnerSelectionSet),
											A2(
												$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
												$author$project$Gql$Platform$Object$BackofficePolicy$policyholder($author$project$Common$Gql$Policy$policyHolderSelectionSet),
												A2(
													$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
													$author$project$Gql$Platform$Object$BackofficePolicy$mainDriver($author$project$Common$Gql$Policy$mainDriverSelectionSet),
													A2(
														$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
														$author$project$Gql$Platform$Object$BackofficePolicy$products($author$project$Common$Gql$Policy$productSelectionSet),
														A2(
															$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
															$author$project$Gql$Platform$Object$BackofficePolicy$interruption($author$project$Common$Gql$Policy$interruptionSelectionSet),
															A2(
																$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Policy$decodePolicyStatus, $author$project$Gql$Platform$Object$BackofficePolicy$status),
																A2(
																	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																	$author$project$Gql$Platform$Object$BackofficePolicy$plateNumber,
																	A2(
																		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																		$author$project$Gql$Platform$Object$BackofficePolicy$vehicleMaker,
																		A2(
																			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																			$author$project$Gql$Platform$Object$BackofficePolicy$vehicleModel,
																			A2(
																				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																				$author$project$Gql$Platform$Object$BackofficePolicy$coolingOffPeriodEndAt,
																				A2(
																					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																					$author$project$Gql$Platform$Object$BackofficePolicy$purchasedAt,
																					A2(
																						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																						$author$project$Gql$Platform$Object$BackofficePolicy$endDate,
																						A2(
																							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																							$author$project$Gql$Platform$Object$BackofficePolicy$startDate,
																							A2(
																								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																								$author$project$Gql$Platform$Object$BackofficePolicy$customerFacingId,
																								A2(
																									$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																									A2(
																										$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
																										$elm$core$Maybe$map($author$project$Common$QuoteId$new),
																										$author$project$Gql$Platform$Object$BackofficePolicy$quoteId),
																									A2(
																										$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																										A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$ApplicationId$new, $author$project$Gql$Platform$Object$BackofficePolicy$applicationId),
																										A2(
																											$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
																											$author$project$Gql$Platform$Object$BackofficePolicy$id,
																											$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Policy$new))))))))))))))))))))))))))));
var $author$project$Common$Gql$ClaimHistory$pricingActionByPolicyIdRequiredArguments = function (policyId) {
	return $author$project$Gql$Platform$Query$PricingActionRequiredArguments(
		{
			C: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			j: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(
				{
					m_: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(policyId)
				})
		});
};
var $author$project$Common$Gql$ClaimHistory$pricingActionByPolicyIdQuery = function (policyId) {
	return $author$project$Common$Gql$ClaimHistory$pricingActionQuery(
		$author$project$Common$Gql$ClaimHistory$pricingActionByPolicyIdRequiredArguments(policyId));
};
var $author$project$Gql$Platform$InputObject$encodePrimaTerminateReasonInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'kind',
				$elm$core$Maybe$Just(
					A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$enum, $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString, input____.lB))),
				_Utils_Tuple2(
				'description',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kR, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$InputObject$encodePrimaTerminateInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'id',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)(input____.iO))),
				_Utils_Tuple2(
				'requestedAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.p))),
				_Utils_Tuple2(
				'interruptionAt',
				$elm$core$Maybe$Just(
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.af;
						},
						$author$project$ScalarCodecs$codecs)(input____.aL))),
				_Utils_Tuple2(
				'reason',
				$elm$core$Maybe$Just(
					$author$project$Gql$Platform$InputObject$encodePrimaTerminateReasonInput(input____.g8)))
			]));
};
var $author$project$Gql$Platform$Mutation$primaTerminatePolicy = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'ScalarCodecs.Uuid',
		'primaTerminatePolicy',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'interruption', requiredArgs____.cw, $author$project$Gql$Platform$InputObject$encodePrimaTerminateInput)
			]),
		$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
};
var $author$project$Page$PolicyDetails$Gql$primaTerminatePolicy = function (_v0) {
	var id = _v0.iO;
	var requestedAt = _v0.p;
	var interruptionAt = _v0.aL;
	var reason = _v0.g8;
	var description = _v0.kR;
	return {
		cw: {
			iO: id,
			aL: interruptionAt,
			g8: {
				kR: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(description),
				lB: reason
			},
			p: requestedAt
		}
	};
};
var $author$project$Page$PolicyDetails$Gql$renewalCancellation = function (_v0) {
	var id = _v0.iO;
	var requestedAt = _v0.p;
	var reason = _v0.g8;
	var additionalNotes = _v0.kn;
	return {
		bt: {
			iO: id,
			g8: {
				kR: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(additionalNotes),
				lB: reason
			},
			p: requestedAt
		}
	};
};
var $author$project$Page$PolicyDetails$Ports$requestIssueRefundReleaseDate = _Platform_outgoingPort(
	'requestIssueRefundReleaseDate',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Page$PolicyDetails$Gql$sendPolicyContract = function (policyId) {
	return {m_: policyId};
};
var $author$project$Gql$Platform$Mutation$sendPolicyContractEmail = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'sendPolicyContractEmail',
		_List_fromArray(
			[
				A3(
				$dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required,
				'policyId',
				requiredArgs____.m_,
				A2(
					$author$project$Gql$Platform$Scalar$unwrapEncoder,
					function ($) {
						return $.I;
					},
					$author$project$ScalarCodecs$codecs))
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Gql$Platform$InputObject$BackofficeUpdatePolicyholderEmailInput = F2(
	function (policyCustomerFacingId, newEmailAddress) {
		return {cK: newEmailAddress, cO: policyCustomerFacingId};
	});
var $author$project$Page$PolicyDetails$Gql$updateEmail = F2(
	function (policyCfid, email) {
		return {
			bt: A2($author$project$Gql$Platform$InputObject$BackofficeUpdatePolicyholderEmailInput, policyCfid, email)
		};
	});
var $author$project$Gql$Platform$InputObject$encodeBackofficeUpdatePolicyholderEmailInput = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'policyCustomerFacingId',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.cO))),
				_Utils_Tuple2(
				'newEmailAddress',
				$elm$core$Maybe$Just(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$string(input____.cK)))
			]));
};
var $author$project$Gql$Platform$Mutation$updatePolicyholderEmail = function (requiredArgs____) {
	return A4(
		$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
		'Bool',
		'updatePolicyholderEmail',
		_List_fromArray(
			[
				A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'input', requiredArgs____.bt, $author$project$Gql$Platform$InputObject$encodeBackofficeUpdatePolicyholderEmailInput)
			]),
		$elm$json$Json$Decode$bool);
};
var $author$project$Page$PolicyDetails$Gql$withdrawPolicy = function (_v0) {
	var id = _v0.iO;
	var requestedAt = _v0.p;
	var reason = _v0.g8;
	var description = _v0.kR;
	return {
		cw: {
			iO: id,
			g8: {
				kR: $dillonkearns$elm_graphql$Graphql$OptionalArgument$fromMaybe(description),
				lB: reason
			},
			p: requestedAt
		}
	};
};
var $author$project$Page$PolicyDetails$Effect$runEffect = F3(
	function (env, model, effect) {
		switch (effect.$) {
			case 20:
				return $elm$core$Platform$Cmd$none;
			case 6:
				var cmd = effect.a;
				return cmd;
			case 0:
				var id = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2(
						$author$project$Gql$Platform$Query$policy,
						$author$project$Common$Gql$Policy$policyByIdRequiredArguments(id),
						$author$project$Common$Gql$Policy$policySelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$FetchedPolicy));
			case 1:
				var quoteId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					A2(
						$author$project$Gql$Platform$Query$claimsHistory,
						$author$project$Common$Gql$ClaimHistory$claimHistoryByQuoteIdRequiredArguments(
							$author$project$Common$QuoteId$toUuid(quoteId)),
						$author$project$Common$Gql$ClaimHistory$claimHistorySelectionSet),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$FetchedClaimHistory));
			case 2:
				var offerId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$CustomerId$getCustomerId(
						$author$project$Common$Gql$CustomerId$PolicyId(offerId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$FetchedCustomerId));
			case 3:
				var policyId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$ClaimHistory$pricingActionByPolicyIdQuery(policyId),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$FetchedClaimHistoryPricingAction));
			case 10:
				var _arguments = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$customerWithdrawPolicy(
						$author$project$Page$PolicyDetails$Gql$withdrawPolicy(_arguments)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$InterruptionResponseReceived));
			case 11:
				var _arguments = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$customerTerminatePolicy(
						$author$project$Page$PolicyDetails$Gql$customerTerminatePolicy(_arguments)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$InterruptionResponseReceived));
			case 12:
				var _arguments = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$primaTerminatePolicy(
						$author$project$Page$PolicyDetails$Gql$primaTerminatePolicy(_arguments)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$InterruptionResponseReceived));
			case 7:
				return A2($elm$core$Task$perform, $author$project$Page$PolicyDetails$Msg$GotCurrentTime, $elm$time$Time$now);
			case 8:
				return A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$elm$core$Maybe$map,
						$author$project$Page$PolicyDetails$Effect$executeCheckEligibilityQuery(env),
						$author$project$Page$PolicyDetails$Effect$createCheckEligibilityArgument(model)));
			case 9:
				var policyId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$GetRefund$getRefundQuery(policyId),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$GotRefund));
			case 13:
				var policyId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$createRefund(
						$author$project$Page$PolicyDetails$Gql$createRefund(policyId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$CreateRefundResponseReceived));
			case 14:
				var refund = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$issueRefund(
						$author$project$Page$PolicyDetails$Gql$issueRefund(
							$author$project$Common$Refund$pickId(refund))),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$IssueRefundResponseReceived));
			case 15:
				return $author$project$Page$PolicyDetails$Ports$requestIssueRefundReleaseDate(0);
			case 17:
				var policyId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Common$Gql$PaymentMethod$getPaymentMethod(
						$author$project$Common$Gql$PaymentMethod$PolicyId(policyId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$FetchedPaymentMethod));
			case 16:
				var _arguments = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$customerDeclineRenewal(
						$author$project$Page$PolicyDetails$Gql$renewalCancellation(_arguments)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$RenewalCancellationResponseReceived));
			case 18:
				var policyId = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$sendPolicyContractEmail(
						$author$project$Page$PolicyDetails$Gql$sendPolicyContract(policyId)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$SendPolicyContractEmailResponseReceived));
			case 19:
				var email = effect.a;
				var policyCfid = effect.b;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$mutationRequest,
					$author$project$Gql$Platform$Mutation$updatePolicyholderEmail(
						A2($author$project$Page$PolicyDetails$Gql$updateEmail, policyCfid, email)),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$PolicyDetails$Msg$GotChangeEmailResponse));
			case 4:
				return $elm$core$Platform$Cmd$none;
			default:
				var effects = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						A2($author$project$Page$PolicyDetails$Effect$runEffect, env, model),
						effects));
		}
	});
var $author$project$Page$Search$Msg$GotCurrentDate = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Search$Msg$GotResults = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Search$Msg$RewriteUrl = function (a) {
	return {$: 5, a: a};
};
var $primait$prima_elm_extra$PrimaCmd$fromMsg = A2(
	$elm$core$Basics$composeL,
	$elm$core$Task$perform($elm$core$Basics$identity),
	$elm$core$Task$succeed);
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $author$project$Tracker$encodeUserEvent = function (_v0) {
	var type_ = _v0.eT;
	var payload = _v0.jl;
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'type',
				$elm$json$Json$Encode$string(type_)),
				_Utils_Tuple2(
				'payload',
				$elm$json$Json$Encode$object(
					A2(
						$elm$core$List$map,
						$elm$core$Tuple$mapSecond($elm$json$Json$Encode$string),
						payload)))
			]));
};
var $author$project$Tracker$EncodedUserEvent = $elm$core$Basics$identity;
var $author$project$Tracker$encodedUserEvent = F2(
	function (type_, payload) {
		return {jl: payload, eT: type_};
	});
var $author$project$Tracker$userSearchEventName = 'userSearch';
var $author$project$Tracker$userSearchPayloadToKeyValueList = function (_v0) {
	var searchType = _v0.jI;
	return _List_fromArray(
		[
			_Utils_Tuple2('searchType', searchType)
		]);
};
var $author$project$Tracker$mapper = function (userEvent) {
	var data = userEvent;
	return A2(
		$author$project$Tracker$encodedUserEvent,
		$author$project$Tracker$userSearchEventName,
		$author$project$Tracker$userSearchPayloadToKeyValueList(data));
};
var $author$project$Tracker$trackUserEvent = _Platform_outgoingPort('trackUserEvent', $elm$core$Basics$identity);
var $author$project$Tracker$publishUserEvent = function (userEvent) {
	return $author$project$Tracker$trackUserEvent(
		$author$project$Tracker$encodeUserEvent(
			$author$project$Tracker$mapper(userEvent)));
};
var $author$project$Page$Search$SearchResult$SearchResult = F3(
	function (offers, policies, quotes) {
		return {l8: offers, mZ: policies, m6: quotes};
	});
var $author$project$Gql$Platform$Query$QuoteRequiredArguments = function (identifier) {
	return {bs: identifier};
};
var $author$project$Common$Gql$Quote$findQuoteArguments = function (customerFacingId) {
	return $author$project$Gql$Platform$Query$QuoteRequiredArguments(
		{
			kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(customerFacingId),
			m5: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Common$Quote$Quote = F8(
	function (id, customerFacingId, creationDate, endDate, status, vehicle, source, funnelGuaranteeSelectionUrl) {
		return {fs: creationDate, kN: customerFacingId, dI: endDate, k8: funnelGuaranteeSelectionUrl, iO: id, hm: source, c1: status, hL: vehicle};
	});
var $author$project$Gql$Platform$Object$BackofficeQuote$customerFacingId = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'customerFacingId', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeQuote$applicationSource = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeApplicationSource.BackofficeApplicationSource', 'applicationSource', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeApplicationSource$decoder);
var $author$project$Common$Gql$Quote$decodeApplicationSource = A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$ApplicationSource$decodeApplicationSource, $author$project$Gql$Platform$Object$BackofficeQuote$applicationSource);
var $author$project$Gql$Platform$Object$BackofficeQuote$vehicleData = function (object____) {
	return A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField, 'vehicleData', _List_Nil, object____, $elm$core$Basics$identity);
};
var $author$project$Common$Gql$Quote$decodePartialVehicle = $author$project$Gql$Platform$Object$BackofficeQuote$vehicleData($author$project$Common$Gql$Vehicle$partialVehicleOwnerSelectionSet);
var $author$project$Common$Quote$Active = 0;
var $author$project$Common$Quote$Blocked = 2;
var $author$project$Common$Quote$Expired = 1;
var $author$project$Common$Gql$Quote$decodeQuoteStatus = function (status) {
	switch (status) {
		case 0:
			return 0;
		case 2:
			return 2;
		default:
			return 1;
	}
};
var $author$project$Gql$Platform$Object$BackofficeQuote$funnelGuaranteeSelectionUrl = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'String', 'funnelGuaranteeSelectionUrl', _List_Nil, $elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeQuote$id = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.Uuid',
	'id',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).I.ah);
var $justinmimbs$timezone_data$TimeZone$Specification$DateTime = F5(
	function (year, month, day, time, clock) {
		return {ap: clock, kO: day, lU: month, hD: time, nO: year};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rules = function (a) {
	return {$: 1, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$WallClock = 2;
var $justinmimbs$timezone_data$TimeZone$Specification$Zone = F2(
	function (history, current) {
		return {ft: current, U: history};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$ZoneState = F2(
	function (standardOffset, zoneRules) {
		return {ho: standardOffset, hQ: zoneRules};
	});
var $justinmimbs$timezone_data$TimeZone$maxYear = 2037;
var $justinmimbs$timezone_data$TimeZone$minYear = 1970;
var $justinmimbs$timezone_data$TimeZone$Specification$Universal = 0;
var $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch = function (_v0) {
	dropChangesBeforeEpoch:
	while (true) {
		var initial = _v0.a;
		var changes = _v0.b;
		if (changes.b) {
			var change = changes.a;
			var rest = changes.b;
			if (change.k <= 0) {
				var $temp$_v0 = _Utils_Tuple2(change.e, rest);
				_v0 = $temp$_v0;
				continue dropChangesBeforeEpoch;
			} else {
				return _Utils_Tuple2(initial, changes);
			}
		} else {
			return _Utils_Tuple2(initial, _List_Nil);
		}
	}
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $justinmimbs$timezone_data$RataDie$weekdayNumber = function (rd) {
	var _v0 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v0) {
		return 7;
	} else {
		var n = _v0;
		return n;
	}
};
var $justinmimbs$timezone_data$RataDie$weekdayToNumber = function (wd) {
	switch (wd) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		default:
			return 7;
	}
};
var $justinmimbs$timezone_data$RataDie$floorWeekday = F2(
	function (weekday, rd) {
		var daysSincePreviousWeekday = A2(
			$elm$core$Basics$modBy,
			7,
			($justinmimbs$timezone_data$RataDie$weekdayNumber(rd) + 7) - $justinmimbs$timezone_data$RataDie$weekdayToNumber(weekday));
		return rd - daysSincePreviousWeekday;
	});
var $justinmimbs$timezone_data$RataDie$ceilingWeekday = F2(
	function (weekday, rd) {
		var floored = A2($justinmimbs$timezone_data$RataDie$floorWeekday, weekday, rd);
		return _Utils_eq(rd, floored) ? rd : (floored + 7);
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $justinmimbs$timezone_data$RataDie$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$timezone_data$RataDie$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$timezone_data$RataDie$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (((y / 4) | 0) - ((y / 100) | 0)) + ((y / 400) | 0);
	return (365 * y) + leapYears;
};
var $justinmimbs$timezone_data$RataDie$dayOfMonth = F3(
	function (y, m, d) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + d;
	});
var $justinmimbs$timezone_data$RataDie$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$timezone_data$RataDie$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$timezone_data$RataDie$lastOfMonth = F2(
	function (y, m) {
		return ($justinmimbs$timezone_data$RataDie$daysBeforeYear(y) + A2($justinmimbs$timezone_data$RataDie$daysBeforeMonth, y, m)) + A2($justinmimbs$timezone_data$RataDie$daysInMonth, y, m);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie = function (rd) {
	return (rd - 719163) * 1440;
};
var $elm$core$List$sortBy = _List_sortBy;
var $justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment = F2(
	function (clock, _v0) {
		var standard = _v0.ay;
		var save = _v0.F;
		switch (clock) {
			case 0:
				return 0;
			case 1:
				return 0 - standard;
			default:
				return 0 - (standard + save);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime = function (_v0) {
	var year = _v0.nO;
	var month = _v0.lU;
	var day = _v0.kO;
	var time = _v0.hD;
	return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
		A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, month, day)) + time;
};
var $justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime = F2(
	function (offset, datetime) {
		return $justinmimbs$timezone_data$TimeZone$Specification$minutesFromDateTime(datetime) + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, datetime.ap, offset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges = F5(
	function (previousOffset, start, until, standardOffset, rules) {
		var transitions = A2(
			$elm$core$List$concatMap,
			function (year) {
				return A2(
					$elm$core$List$sortBy,
					function ($) {
						return $.k;
					},
					A2(
						$elm$core$List$map,
						function (rule) {
							return {
								ap: rule.ap,
								F: rule.F,
								k: $justinmimbs$timezone_data$TimeZone$Specification$minutesFromRataDie(
									function () {
										var _v2 = rule.kO;
										switch (_v2.$) {
											case 0:
												var day = _v2.a;
												return A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lU, day);
											case 1:
												var weekday = _v2.a;
												var after = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$ceilingWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lU, after));
											case 2:
												var weekday = _v2.a;
												var before = _v2.b;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A3($justinmimbs$timezone_data$RataDie$dayOfMonth, year, rule.lU, before));
											default:
												var weekday = _v2.a;
												return A2(
													$justinmimbs$timezone_data$RataDie$floorWeekday,
													weekday,
													A2($justinmimbs$timezone_data$RataDie$lastOfMonth, year, rule.lU));
										}
									}()) + rule.hD
							};
						},
						A2(
							$elm$core$List$filter,
							function (rule) {
								return (_Utils_cmp(rule.iC, year) < 1) && (_Utils_cmp(year, rule.j$) < 1);
							},
							rules)));
			},
			A2($elm$core$List$range, start.nO - 1, until.nO));
		var initialOffset = {F: 0, ay: standardOffset};
		var initialChange = {
			e: standardOffset,
			k: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
		};
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (transition, _v1) {
					var currentOffset = _v1.a;
					var changes = _v1.b;
					var newOffset = {F: transition.F, ay: standardOffset};
					if (_Utils_cmp(
						transition.k + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ap, previousOffset),
						initialChange.k) < 1) {
						var updatedInitialChange = {e: standardOffset + transition.F, k: initialChange.k};
						return _Utils_Tuple2(
							newOffset,
							_List_fromArray(
								[updatedInitialChange]));
					} else {
						if (_Utils_cmp(
							transition.k + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ap, currentOffset),
							A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, currentOffset, until)) < 0) {
							var change = {
								e: standardOffset + transition.F,
								k: transition.k + A2($justinmimbs$timezone_data$TimeZone$Specification$utcAdjustment, transition.ap, currentOffset)
							};
							return _Utils_Tuple2(
								newOffset,
								A2($elm$core$List$cons, change, changes));
						} else {
							return _Utils_Tuple2(currentOffset, changes);
						}
					}
				}),
			_Utils_Tuple2(
				initialOffset,
				_List_fromArray(
					[initialChange])),
			transitions);
		var nextOffset = _v0.a;
		var descendingChanges = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(descendingChanges),
			nextOffset);
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges = F4(
	function (previousOffset, start, until, _v0) {
		var standardOffset = _v0.ho;
		var zoneRules = _v0.hQ;
		if (!zoneRules.$) {
			var save = zoneRules.a;
			return _Utils_Tuple2(
				_List_fromArray(
					[
						{
						e: standardOffset + save,
						k: A2($justinmimbs$timezone_data$TimeZone$Specification$utcMinutesFromDateTime, previousOffset, start)
					}
					]),
				{F: save, ay: standardOffset});
		} else {
			var rules = zoneRules.a;
			return A5($justinmimbs$timezone_data$TimeZone$Specification$rulesToOffsetChanges, previousOffset, start, until, standardOffset, rules);
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp = F4(
	function (f, a, rev, list) {
		stripDuplicatesByHelp:
		while (true) {
			if (!list.b) {
				return $elm$core$List$reverse(rev);
			} else {
				var x = list.a;
				var xs = list.b;
				var b = f(x);
				var rev_ = (!_Utils_eq(a, b)) ? A2($elm$core$List$cons, x, rev) : rev;
				var $temp$f = f,
					$temp$a = b,
					$temp$rev = rev_,
					$temp$list = xs;
				f = $temp$f;
				a = $temp$a;
				rev = $temp$rev;
				list = $temp$list;
				continue stripDuplicatesByHelp;
			}
		}
	});
var $justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges = F3(
	function (zoneStart, zoneUntil, zone) {
		var _v0 = A3(
			$elm$core$List$foldl,
			F2(
				function (_v1, _v2) {
					var state = _v1.a;
					var nextStart = _v1.b;
					var start = _v2.a;
					var ranges = _v2.b;
					return _Utils_Tuple2(
						nextStart,
						A2(
							$elm$core$List$cons,
							_Utils_Tuple3(start, state, nextStart),
							ranges));
				}),
			_Utils_Tuple2(zoneStart, _List_Nil),
			zone.U);
		var currentStart = _v0.a;
		var historyRanges = _v0.b;
		return $elm$core$List$reverse(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple3(currentStart, zone.ft, zoneUntil),
				historyRanges));
	});
var $justinmimbs$timezone_data$TimeZone$Specification$toOffsets = F3(
	function (minYear, maxYear, zone) {
		var initialState = function () {
			var _v5 = zone.U;
			if (_v5.b) {
				var _v6 = _v5.a;
				var earliest = _v6.a;
				return earliest;
			} else {
				return zone.ft;
			}
		}();
		var initialOffset = {
			F: function () {
				var _v4 = initialState.hQ;
				if (!_v4.$) {
					var save = _v4.a;
					return save;
				} else {
					return 0;
				}
			}(),
			ay: initialState.ho
		};
		var ascendingChanges = A4(
			$justinmimbs$timezone_data$TimeZone$Specification$stripDuplicatesByHelp,
			function ($) {
				return $.e;
			},
			initialOffset.ay + initialOffset.F,
			_List_Nil,
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v1, _v2) {
						var start = _v1.a;
						var state = _v1.b;
						var until = _v1.c;
						var prevOffset = _v2.a;
						var prevChanges = _v2.b;
						var _v3 = A4($justinmimbs$timezone_data$TimeZone$Specification$stateToOffsetChanges, prevOffset, start, until, state);
						var nextChanges = _v3.a;
						var nextOffset = _v3.b;
						return _Utils_Tuple2(
							nextOffset,
							_Utils_ap(prevChanges, nextChanges));
					}),
				_Utils_Tuple2(initialOffset, _List_Nil),
				A3(
					$justinmimbs$timezone_data$TimeZone$Specification$zoneToRanges,
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, minYear, 0, 1, 0, 0),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, maxYear + 1, 0, 1, 0, 0),
					zone)).b);
		var _v0 = $justinmimbs$timezone_data$TimeZone$Specification$dropChangesBeforeEpoch(
			_Utils_Tuple2(initialOffset.ay + initialOffset.F, ascendingChanges));
		var initial = _v0.a;
		var ascending = _v0.b;
		return _Utils_Tuple2(
			$elm$core$List$reverse(ascending),
			initial);
	});
var $justinmimbs$timezone_data$TimeZone$fromSpecification = function (zone) {
	var _v0 = A3($justinmimbs$timezone_data$TimeZone$Specification$toOffsets, $justinmimbs$timezone_data$TimeZone$minYear, $justinmimbs$timezone_data$TimeZone$maxYear, zone);
	var descending = _v0.a;
	var bottom = _v0.b;
	return A2($elm$time$Time$customZone, bottom, descending);
};
var $justinmimbs$timezone_data$TimeZone$Specification$Day = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Last = function (a) {
	return {$: 3, a: a};
};
var $justinmimbs$timezone_data$TimeZone$Specification$Next = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Rule = F7(
	function (from, to, month, day, time, clock, save) {
		return {ap: clock, kO: day, iC: from, lU: month, F: save, hD: time, j$: to};
	});
var $justinmimbs$timezone_data$TimeZone$rules_EU = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$Specification$Standard = 1;
var $justinmimbs$timezone_data$TimeZone$rules_Spain = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1975,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 12),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1975,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__madrid = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Spain)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $author$project$Common$Locale$customerTimeZone = $justinmimbs$timezone_data$TimeZone$europe__madrid(0);
var $author$project$Common$Locale$posixToCustomerDate = function (time) {
	return A2($justinmimbs$date$Date$fromPosix, $author$project$Common$Locale$customerTimeZone, time);
};
var $author$project$Gql$Platform$Object$BackofficeQuote$quotedAt = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'quotedAt',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Gql$Platform$Enum$BackofficeQuoteStatus$Active = 0;
var $author$project$Gql$Platform$Enum$BackofficeQuoteStatus$Blocked = 2;
var $author$project$Gql$Platform$Enum$BackofficeQuoteStatus$Expired = 1;
var $author$project$Gql$Platform$Enum$BackofficeQuoteStatus$decoder = A2(
	$elm$json$Json$Decode$andThen,
	function (string) {
		switch (string) {
			case 'ACTIVE':
				return $elm$json$Json$Decode$succeed(0);
			case 'EXPIRED':
				return $elm$json$Json$Decode$succeed(1);
			case 'BLOCKED':
				return $elm$json$Json$Decode$succeed(2);
			default:
				return $elm$json$Json$Decode$fail('Invalid BackofficeQuoteStatus type, ' + (string + ' try re-running the @dillonkearns/elm-graphql CLI '));
		}
	},
	$elm$json$Json$Decode$string);
var $author$project$Gql$Platform$Object$BackofficeQuote$status = A4($dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField, 'Enum.BackofficeQuoteStatus.BackofficeQuoteStatus', 'status', _List_Nil, $author$project$Gql$Platform$Enum$BackofficeQuoteStatus$decoder);
var $author$project$Gql$Platform$Object$BackofficeQuote$validUntil = A4(
	$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForField,
	'ScalarCodecs.DateTime',
	'validUntil',
	_List_Nil,
	$author$project$Gql$Platform$Scalar$unwrapCodecs($author$project$ScalarCodecs$codecs).af.ah);
var $author$project$Common$Gql$Quote$findQuoteSelectionSet = A2(
	$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
	$author$project$Gql$Platform$Object$BackofficeQuote$funnelGuaranteeSelectionUrl,
	A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
		$author$project$Common$Gql$Quote$decodeApplicationSource,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
			$author$project$Common$Gql$Quote$decodePartialVehicle,
			A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
				A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Gql$Quote$decodeQuoteStatus, $author$project$Gql$Platform$Object$BackofficeQuote$status),
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Locale$posixToCustomerDate, $author$project$Gql$Platform$Object$BackofficeQuote$validUntil),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2($dillonkearns$elm_graphql$Graphql$SelectionSet$map, $author$project$Common$Locale$posixToCustomerDate, $author$project$Gql$Platform$Object$BackofficeQuote$quotedAt),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							$author$project$Gql$Platform$Object$BackofficeQuote$customerFacingId,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								$author$project$Gql$Platform$Object$BackofficeQuote$id,
								$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Common$Quote$Quote)))))))));
var $author$project$Gql$Platform$InputObject$encodeQuoteIdentifier = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'customerFacingId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.kN, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'quoteId',
				A2(
					$dillonkearns$elm_graphql$Graphql$Internal$Encode$optional,
					input____.m5,
					A2(
						$author$project$Gql$Platform$Scalar$unwrapEncoder,
						function ($) {
							return $.I;
						},
						$author$project$ScalarCodecs$codecs)))
			]));
};
var $author$project$Gql$Platform$Query$quote = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'quote',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'identifier', requiredArgs____.bs, $author$project$Gql$Platform$InputObject$encodeQuoteIdentifier)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$nullable));
	});
var $author$project$Common$Gql$Quote$findQuoteQuery = function (findQuoteArg) {
	return A2(
		$author$project$Gql$Platform$Query$quote,
		$author$project$Common$Gql$Quote$findQuoteArguments(findQuoteArg),
		$author$project$Common$Gql$Quote$findQuoteSelectionSet);
};
var $author$project$Page$Search$Gql$offerRequiredArguments = function (cfid) {
	return $author$project$Gql$Platform$Query$OfferV2RequiredArguments(
		{
			kN: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(cfid),
			l7: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Gql$Platform$Query$OffersV2RequiredArguments = function (by) {
	return {ds: by};
};
var $author$project$Page$Search$Gql$offersByDocumentIdArgument = function (documentId) {
	return $author$project$Gql$Platform$Query$OffersV2RequiredArguments(
		{
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(documentId),
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$offersByEmailArgument = function (email) {
	return $author$project$Gql$Platform$Query$OffersV2RequiredArguments(
		{
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(email),
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$offersByPhoneNumberArgument = function (phone) {
	return $author$project$Gql$Platform$Query$OffersV2RequiredArguments(
		{
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(phone),
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$offersByPlateNumberArgument = function (plateNumber) {
	return $author$project$Gql$Platform$Query$OffersV2RequiredArguments(
		{
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(plateNumber)
		});
};
var $author$project$Gql$Platform$InputObject$encodeFindOfferCriteria = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'documentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ai, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'email',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cn, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'phoneNumber',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g$, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'plateNumber',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g1, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$Query$offersV2 = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'offersV2',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'by', requiredArgs____.ds, $author$project$Gql$Platform$InputObject$encodeFindOfferCriteria)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Gql$Platform$InputObject$encodeFindPolicyCriteria = function (input____) {
	return $dillonkearns$elm_graphql$Graphql$Internal$Encode$maybeObject(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'documentId',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.ai, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'cfid',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.a9, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'email',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.cn, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'phoneNumber',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g$, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string)),
				_Utils_Tuple2(
				'plateNumber',
				A2($dillonkearns$elm_graphql$Graphql$Internal$Encode$optional, input____.g1, $dillonkearns$elm_graphql$Graphql$Internal$Encode$string))
			]));
};
var $author$project$Gql$Platform$Query$policies = F2(
	function (requiredArgs____, object____) {
		return A4(
			$dillonkearns$elm_graphql$Graphql$Internal$Builder$Object$selectionForCompositeField,
			'policies',
			_List_fromArray(
				[
					A3($dillonkearns$elm_graphql$Graphql$Internal$Builder$Argument$required, 'by', requiredArgs____.ds, $author$project$Gql$Platform$InputObject$encodeFindPolicyCriteria)
				]),
			object____,
			A2($elm$core$Basics$composeR, $elm$core$Basics$identity, $elm$json$Json$Decode$list));
	});
var $author$project$Gql$Platform$Query$PoliciesRequiredArguments = function (by) {
	return {ds: by};
};
var $author$project$Page$Search$Gql$policiesByCfidArgument = function (cfid) {
	return $author$project$Gql$Platform$Query$PoliciesRequiredArguments(
		{
			a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(cfid),
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$policiesByDocumentIdArgument = function (documentId) {
	return $author$project$Gql$Platform$Query$PoliciesRequiredArguments(
		{
			a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(documentId),
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$policiesByEmailArgument = function (email) {
	return $author$project$Gql$Platform$Query$PoliciesRequiredArguments(
		{
			a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(email),
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent
		});
};
var $author$project$Page$Search$Gql$policiesByPhoneNumberArgument = function (phone) {
	return $author$project$Gql$Platform$Query$PoliciesRequiredArguments(
		{
			a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(phone)
		});
};
var $author$project$Page$Search$Gql$policiesByPlateNumberArgument = function (plateNumber) {
	return $author$project$Gql$Platform$Query$PoliciesRequiredArguments(
		{
			a9: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			ai: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			cn: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g$: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Absent,
			g1: $dillonkearns$elm_graphql$Graphql$OptionalArgument$Present(plateNumber)
		});
};
var $author$project$Page$Search$Gql$searchQuery = function (searchInputString) {
	var searchResult = $dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Search$SearchResult$SearchResult);
	var noSearchQuery = A2(
		$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
		_List_Nil,
		A2(
			$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
			_List_Nil,
			A2($dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded, _List_Nil, searchResult)));
	switch (searchInputString.$) {
		case 0:
			var documentId = searchInputString.a;
			return A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				_List_Nil,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2(
						$author$project$Gql$Platform$Query$policies,
						$author$project$Page$Search$Gql$policiesByDocumentIdArgument(documentId),
						$author$project$Common$Gql$Policy$policySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$author$project$Gql$Platform$Query$offersV2,
							$author$project$Page$Search$Gql$offersByDocumentIdArgument(documentId),
							$author$project$Common$Gql$Offer$offerSelectionSet),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Search$SearchResult$SearchResult))));
		case 1:
			switch (searchInputString.a.$) {
				case 0:
					var pId = searchInputString.a.a;
					return A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						_List_Nil,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
							A2(
								$author$project$Gql$Platform$Query$policies,
								$author$project$Page$Search$Gql$policiesByCfidArgument(pId),
								$author$project$Common$Gql$Policy$policySelectionSet),
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded, _List_Nil, searchResult)));
				case 1:
					var oId = searchInputString.a.a;
					return A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
						_List_Nil,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
							_List_Nil,
							A2(
								$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
								A2(
									$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
									A2(
										$elm$core$Basics$composeR,
										$elm$core$Maybe$map($elm$core$List$singleton),
										$elm$core$Maybe$withDefault(_List_Nil)),
									A2(
										$author$project$Gql$Platform$Query$offerV2,
										$author$project$Page$Search$Gql$offerRequiredArguments(oId),
										$author$project$Common$Gql$Offer$offerSelectionSet)),
								searchResult)));
				default:
					var qId = searchInputString.a.a;
					return A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$map,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$Maybe$map($elm$core$List$singleton),
								$elm$core$Maybe$withDefault(_List_Nil)),
							$author$project$Common$Gql$Quote$findQuoteQuery(qId)),
						A2(
							$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
							_List_Nil,
							A2($dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded, _List_Nil, searchResult)));
			}
		case 3:
			return noSearchQuery;
		case 2:
			var email = searchInputString.a;
			return A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				_List_Nil,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2(
						$author$project$Gql$Platform$Query$policies,
						$author$project$Page$Search$Gql$policiesByEmailArgument(email),
						$author$project$Common$Gql$Policy$policySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$author$project$Gql$Platform$Query$offersV2,
							$author$project$Page$Search$Gql$offersByEmailArgument(email),
							$author$project$Common$Gql$Offer$offerSelectionSet),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Search$SearchResult$SearchResult))));
		case 4:
			var plateNumber = searchInputString.a;
			return A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				_List_Nil,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2(
						$author$project$Gql$Platform$Query$policies,
						$author$project$Page$Search$Gql$policiesByPlateNumberArgument(plateNumber),
						$author$project$Common$Gql$Policy$policySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$author$project$Gql$Platform$Query$offersV2,
							$author$project$Page$Search$Gql$offersByPlateNumberArgument(plateNumber),
							$author$project$Common$Gql$Offer$offerSelectionSet),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Search$SearchResult$SearchResult))));
		default:
			var phone = searchInputString.a;
			return A2(
				$dillonkearns$elm_graphql$Graphql$SelectionSet$hardcoded,
				_List_Nil,
				A2(
					$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
					A2(
						$author$project$Gql$Platform$Query$policies,
						$author$project$Page$Search$Gql$policiesByPhoneNumberArgument(phone),
						$author$project$Common$Gql$Policy$policySelectionSet),
					A2(
						$dillonkearns$elm_graphql$Graphql$SelectionSet$with,
						A2(
							$author$project$Gql$Platform$Query$offersV2,
							$author$project$Page$Search$Gql$offersByPhoneNumberArgument(phone),
							$author$project$Common$Gql$Offer$offerSelectionSet),
						$dillonkearns$elm_graphql$Graphql$SelectionSet$succeed($author$project$Page$Search$SearchResult$SearchResult))));
	}
};
var $author$project$Tracker$UserSearch = $elm$core$Basics$identity;
var $author$project$Tracker$userSearchEvent = $elm$core$Basics$identity;
var $author$project$Tracker$UserSearchPayload = $elm$core$Basics$identity;
var $author$project$Tracker$userSearchPayload = function (searchType) {
	return {jI: searchType};
};
var $author$project$Page$Search$Effect$runEffect = F3(
	function (env, model, effect) {
		switch (effect.$) {
			case 0:
				return $elm$core$Platform$Cmd$none;
			case 2:
				return $elm$core$Platform$Cmd$none;
			case 7:
				return $elm$core$Platform$Cmd$none;
			case 3:
				var cmd = effect.a;
				return cmd;
			case 4:
				var searchInputString = effect.a;
				return A4(
					$author$project$Api$executeGraphqlOperation,
					env,
					$dillonkearns$elm_graphql$Graphql$Http$queryRequest,
					$author$project$Page$Search$Gql$searchQuery(searchInputString),
					A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$fromResult, $author$project$Page$Search$Msg$GotResults));
			case 5:
				return A2(
					$elm$core$Task$perform,
					$author$project$Page$Search$Msg$GotCurrentDate,
					A2(
						$elm$core$Task$map,
						$justinmimbs$date$Date$fromPosix($elm$time$Time$utc),
						$elm$time$Time$now));
			case 6:
				var searchInputString = effect.a;
				return $primait$prima_elm_extra$PrimaCmd$fromMsg(
					$author$project$Page$Search$Msg$RewriteUrl(searchInputString));
			case 8:
				var searchType = effect.a;
				return $author$project$Tracker$publishUserEvent(
					$author$project$Tracker$userSearchEvent(
						$author$project$Tracker$userSearchPayload(searchType)));
			default:
				var effects = effect.a;
				return $elm$core$Platform$Cmd$batch(
					A2(
						$elm$core$List$map,
						A2($author$project$Page$Search$Effect$runEffect, env, model),
						effects));
		}
	});
var $author$project$Page$Search$SearchQueryParam$pickCustomerFacingId = function (customerFId) {
	switch (customerFId.$) {
		case 0:
			var policyId = customerFId.a;
			return policyId;
		case 1:
			var offerId = customerFId.a;
			return offerId;
		default:
			var quoteId = customerFId.a;
			return quoteId;
	}
};
var $author$project$Referer$toString = function (_v0) {
	return 'payment-page';
};
var $author$project$Route$routeToPieces = function (page) {
	switch (page.$) {
		case 0:
			return _List_fromArray(
				['not-found']);
		case 1:
			return _List_fromArray(
				['not-authorized']);
		case 2:
			return _List_fromArray(
				['login']);
		case 4:
			var offerId = page.a;
			return _List_fromArray(
				[
					'payment',
					$danyx23$elm_uuid$Uuid$toString(offerId)
				]);
		case 3:
			var searchQuery = page.a;
			if (!searchQuery.$) {
				switch (searchQuery.a.$) {
					case 0:
						var documentId = searchQuery.a.a;
						return _List_fromArray(
							['?search=' + documentId]);
					case 1:
						var cfid = searchQuery.a.a;
						return _List_fromArray(
							[
								'?search=' + $author$project$Page$Search$SearchQueryParam$pickCustomerFacingId(cfid)
							]);
					case 4:
						var plateNumber = searchQuery.a.a;
						return _List_fromArray(
							['?search=' + plateNumber]);
					case 2:
						var email = searchQuery.a.a;
						return _List_fromArray(
							['?search=' + email]);
					case 5:
						var phoneNumber = searchQuery.a.a;
						return _List_fromArray(
							['?search=' + phoneNumber]);
					default:
						var _v2 = searchQuery.a;
						return _List_Nil;
				}
			} else {
				return _List_Nil;
			}
		case 5:
			if (!page.b.$) {
				var policyId = page.a;
				var referer = page.b.a;
				return _List_fromArray(
					[
						'policy-details',
						$danyx23$elm_uuid$Uuid$toString(policyId),
						'?referer=' + $author$project$Referer$toString(referer)
					]);
			} else {
				var policyId = page.a;
				var _v3 = page.b;
				return _List_fromArray(
					[
						'policy-details',
						$danyx23$elm_uuid$Uuid$toString(policyId)
					]);
			}
		default:
			var cfid = page.a;
			return _List_fromArray(
				['offer-details', cfid]);
	}
};
var $author$project$Route$toString = function (page) {
	return '/' + A2(
		$elm$core$String$join,
		'/',
		$author$project$Route$routeToPieces(page));
};
var $author$project$Translations$languageToString = function (lang) {
	if (!lang) {
		return 'en';
	} else {
		return 'es';
	}
};
var $author$project$Route$toStringWithLanguageOverride = F2(
	function (language, page) {
		return '/' + ($author$project$Translations$languageToString(language) + ('/' + A2(
			$elm$core$String$join,
			'/',
			$author$project$Route$routeToPieces(page))));
	});
var $author$project$Effect$runEffect = F3(
	function (env, model, effect) {
		var _v0 = _Utils_Tuple2(model.mX, effect);
		_v0$15:
		while (true) {
			_v0$16:
			while (true) {
				_v0$17:
				while (true) {
					_v0$18:
					while (true) {
						_v0$19:
						while (true) {
							_v0$20:
							while (true) {
								_v0$21:
								while (true) {
									switch (_v0.b.$) {
										case 0:
											var _v1 = _v0.b;
											return $elm$core$Platform$Cmd$none;
										case 1:
											var effects = _v0.b.a;
											return $elm$core$Platform$Cmd$batch(
												A2(
													$elm$core$List$map,
													A2($author$project$Effect$runEffect, env, model),
													effects));
										case 2:
											var cmd = _v0.b.a;
											return cmd;
										case 3:
											var route = _v0.b.a;
											var fullRoute = function () {
												var _v2 = $author$project$Env$languageOverride(model.kY);
												if (!_v2.$) {
													var languageOverride = _v2.a;
													return A2($author$project$Route$toStringWithLanguageOverride, languageOverride, route);
												} else {
													return $author$project$Route$toString(route);
												}
											}();
											return A2($elm$browser$Browser$Navigation$pushUrl, model.gq, fullRoute);
										case 4:
											var pageNumber = _v0.b.a;
											return A2($elm$browser$Browser$Navigation$back, model.gq, pageNumber);
										case 6:
											var queryParams = _v0.b.a;
											return A2($elm$browser$Browser$Navigation$replaceUrl, model.gq, queryParams);
										case 5:
											var address = _v0.b.a;
											return $elm$browser$Browser$Navigation$load(address);
										case 10:
											var _v3 = _v0.b;
											return A3($author$project$Utils$executeLogout, env, $author$project$Env$externalApiUrl, $author$project$Msg$GotLogoutResult);
										case 9:
											switch (_v0.a.$) {
												case 2:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$Login,
														A3($author$project$Page$Login$Effect$runEffect, env, subModel, subEffect));
												case 0:
													break _v0$16;
												case 1:
													break _v0$17;
												case 3:
													break _v0$18;
												case 4:
													break _v0$19;
												case 5:
													break _v0$20;
												default:
													break _v0$21;
											}
										case 7:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$NotFound,
														A3($author$project$Page$NotFound$Effect$runEffect, env, subModel, subEffect));
												case 1:
													break _v0$17;
												case 3:
													break _v0$18;
												case 4:
													break _v0$19;
												case 5:
													break _v0$20;
												default:
													break _v0$21;
											}
										case 8:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													break _v0$16;
												case 1:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$NotAuthorized,
														A3($author$project$Page$NotAuthorized$Effect$runEffect, env, subModel, subEffect));
												case 3:
													break _v0$18;
												case 4:
													break _v0$19;
												case 5:
													break _v0$20;
												default:
													break _v0$21;
											}
										case 11:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													break _v0$16;
												case 1:
													break _v0$17;
												case 3:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$Search,
														A3($author$project$Page$Search$Effect$runEffect, env, subModel, subEffect));
												case 4:
													break _v0$19;
												case 5:
													break _v0$20;
												default:
													break _v0$21;
											}
										case 12:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													break _v0$16;
												case 1:
													break _v0$17;
												case 3:
													break _v0$18;
												case 4:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$Payment,
														A3($author$project$Page$Payment$Effect$runEffect, env, subModel, subEffect));
												case 5:
													break _v0$20;
												default:
													break _v0$21;
											}
										case 13:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													break _v0$16;
												case 1:
													break _v0$17;
												case 3:
													break _v0$18;
												case 4:
													break _v0$19;
												case 5:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$PolicyDetails,
														A3($author$project$Page$PolicyDetails$Effect$runEffect, env, subModel, subEffect));
												default:
													break _v0$21;
											}
										default:
											switch (_v0.a.$) {
												case 2:
													break _v0$15;
												case 0:
													break _v0$16;
												case 1:
													break _v0$17;
												case 3:
													break _v0$18;
												case 4:
													break _v0$19;
												case 5:
													break _v0$20;
												default:
													var subModel = _v0.a.a;
													var subEffect = _v0.b.a;
													return A2(
														$elm$core$Platform$Cmd$map,
														$author$project$Msg$OfferDetails,
														A3($author$project$Page$OfferDetails$Effect$runEffect, env, subModel, subEffect));
											}
									}
								}
								return $elm$core$Platform$Cmd$none;
							}
							return $elm$core$Platform$Cmd$none;
						}
						return $elm$core$Platform$Cmd$none;
					}
					return $elm$core$Platform$Cmd$none;
				}
				return $elm$core$Platform$Cmd$none;
			}
			return $elm$core$Platform$Cmd$none;
		}
		return $elm$core$Platform$Cmd$none;
	});
var $author$project$Common$User$Anonymous = {$: 0};
var $author$project$Common$User$Backoffice = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$ChangeRoute = function (a) {
	return {$: 3, a: a};
};
var $author$project$Effect$Effects = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$GoBack = function (a) {
	return {$: 4, a: a};
};
var $author$project$Effect$LoadExternalPage = function (a) {
	return {$: 5, a: a};
};
var $author$project$Effect$Login = function (a) {
	return {$: 9, a: a};
};
var $author$project$Model$Login = function (a) {
	return {$: 2, a: a};
};
var $author$project$Route$Login = {$: 2};
var $author$project$Effect$Logout = {$: 10};
var $author$project$Effect$None = {$: 0};
var $author$project$Effect$NotAuthorized = function (a) {
	return {$: 8, a: a};
};
var $author$project$Model$NotAuthorized = function (a) {
	return {$: 1, a: a};
};
var $author$project$Effect$NotFound = function (a) {
	return {$: 7, a: a};
};
var $author$project$Model$NotFound = function (a) {
	return {$: 0, a: a};
};
var $author$project$Effect$OfferDetails = function (a) {
	return {$: 14, a: a};
};
var $author$project$Model$OfferDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$Effect$Payment = function (a) {
	return {$: 12, a: a};
};
var $author$project$Model$Payment = function (a) {
	return {$: 4, a: a};
};
var $author$project$Effect$PolicyDetails = function (a) {
	return {$: 13, a: a};
};
var $author$project$Model$PolicyDetails = function (a) {
	return {$: 5, a: a};
};
var $author$project$Effect$RewriteUrl = function (a) {
	return {$: 6, a: a};
};
var $author$project$Effect$Search = function (a) {
	return {$: 11, a: a};
};
var $author$project$Model$Search = function (a) {
	return {$: 3, a: a};
};
var $author$project$Route$NotFound = {$: 0};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aJ: frag, aQ: params, aA: unvisited, eW: value, aZ: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.aA;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.eW);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.eW);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.mY),
					$elm$url$Url$Parser$prepareQuery(url.jv),
					url.k4,
					$elm$core$Basics$identity)));
	});
var $author$project$Route$NotAuthorized = {$: 1};
var $author$project$Route$OfferDetails = function (a) {
	return {$: 6, a: a};
};
var $author$project$Route$Payment = function (a) {
	return {$: 4, a: a};
};
var $author$project$Route$PolicyDetails = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Route$Search = function (a) {
	return {$: 3, a: a};
};
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aZ;
		var unvisited = _v0.aA;
		var params = _v0.aQ;
		var frag = _v0.aJ;
		var value = _v0.eW;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aZ;
			var unvisited = _v1.aA;
			var params = _v1.aQ;
			var frag = _v1.aJ;
			var value = _v1.eW;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $author$project$Referer$PaymentPage = 0;
var $author$project$Referer$fromString = function (str) {
	if (str === 'payment-page') {
		return $elm$core$Result$Ok(0);
	} else {
		return $elm$core$Result$Err(0);
	}
};
var $author$project$Route$parseRefererQueryParam = A2(
	$elm$url$Url$Parser$Query$custom,
	'referer',
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$head,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				A2($elm$core$Basics$composeR, $author$project$Referer$fromString, $elm$core$Result$toMaybe)),
			$elm_community$maybe_extra$Maybe$Extra$join)));
var $author$project$Page$Search$SearchQueryParam$Empty = {$: 3};
var $author$project$Page$Search$SearchQueryParam$CustomerFacingId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Search$SearchQueryParam$OfferId = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Search$SearchQueryParam$PolicyId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Search$SearchQueryParam$QuoteId = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Search$SearchQueryParam$attemptIdentifyCustomerFacingId = function (value) {
	var _v0 = A2($elm$core$String$left, 1, value);
	switch (_v0) {
		case 'F':
			return $elm$core$Result$Ok(
				$author$project$Page$Search$SearchQueryParam$OfferId(value));
		case 'P':
			return $elm$core$Result$Ok(
				$author$project$Page$Search$SearchQueryParam$PolicyId(value));
		case 'Q':
			return $elm$core$Result$Ok(
				$author$project$Page$Search$SearchQueryParam$QuoteId(value));
		default:
			return $elm$core$Result$Err(0);
	}
};
var $author$project$Page$Search$SearchQueryParam$fromStringToRegex = function (pattern) {
	return A2(
		$elm$core$Maybe$withDefault,
		$elm$regex$Regex$never,
		$elm$regex$Regex$fromString(pattern));
};
var $author$project$Page$Search$SearchQueryParam$NotRecognised = 0;
var $author$project$Page$Search$SearchQueryParam$inputRecogniser = F2(
	function (recognizer, value) {
		return A2(
			$elm$core$Result$mapError,
			$elm$core$Basics$always(0),
			recognizer(value));
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $author$project$Page$Search$SearchQueryParam$cfIdRecogniser = function (value) {
	var pattern = '^[FPQ]\\d+$';
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex(pattern);
	var recogniser = function (cfid) {
		return A2($elm$regex$Regex$contains, regex, cfid) ? A2(
			$elm$core$Result$map,
			$author$project$Page$Search$SearchQueryParam$CustomerFacingId,
			$author$project$Page$Search$SearchQueryParam$attemptIdentifyCustomerFacingId(cfid)) : $elm$core$Result$Err(0);
	};
	return A2($author$project$Page$Search$SearchQueryParam$inputRecogniser, recogniser, value);
};
var $author$project$Page$Search$SearchQueryParam$PlateNumber = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Search$SearchQueryParam$defaultWhenNotRecognised = function (inputValue) {
	return $author$project$Page$Search$SearchQueryParam$PlateNumber(inputValue);
};
var $author$project$Page$Search$SearchQueryParam$DocumentId = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Search$SearchQueryParam$dniRecogniser = function (value) {
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex('^[X,x,Y,y, Z,z, 0-9]*[0-9][a-zA-Z]$');
	var recogniser = function (dni) {
		return A2($elm$regex$Regex$contains, regex, dni) ? $elm$core$Result$Ok(
			$author$project$Page$Search$SearchQueryParam$DocumentId(dni)) : $elm$core$Result$Err(0);
	};
	return A2($author$project$Page$Search$SearchQueryParam$inputRecogniser, recogniser, value);
};
var $author$project$Page$Search$SearchQueryParam$emptyStringRecogniser = function (value) {
	var recogniser = function (v) {
		return $elm$core$String$isEmpty(v) ? $elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty) : $elm$core$Result$Err(0);
	};
	return A2($author$project$Page$Search$SearchQueryParam$inputRecogniser, recogniser, value);
};
var $author$project$Page$Search$SearchQueryParam$Email = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Search$SearchQueryParam$mailRecogniser = function (value) {
	var pattern = '^[a-z]?.*@';
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex(pattern);
	var recogniser = function (mail) {
		return A2($elm$regex$Regex$contains, regex, mail) ? $elm$core$Result$Ok(
			$author$project$Page$Search$SearchQueryParam$Email(mail)) : $elm$core$Result$Err(0);
	};
	return A2($author$project$Page$Search$SearchQueryParam$inputRecogniser, recogniser, value);
};
var $elm_community$result_extra$Result$Extra$or = F2(
	function (ra, rb) {
		if (ra.$ === 1) {
			return rb;
		} else {
			return ra;
		}
	});
var $author$project$Page$Search$SearchQueryParam$PhoneNumber = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$Search$SearchQueryParam$phoneNumberRecogniser = function (value) {
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex('^[0-9]*[0-9]$');
	var recogniser = function (phone) {
		return A2($elm$regex$Regex$contains, regex, phone) ? $elm$core$Result$Ok(
			$author$project$Page$Search$SearchQueryParam$PhoneNumber(phone)) : $elm$core$Result$Err(0);
	};
	return A2($author$project$Page$Search$SearchQueryParam$inputRecogniser, recogniser, value);
};
var $elm_community$result_extra$Result$Extra$unwrap = F3(
	function (defaultValue, okFunc, result) {
		if (!result.$) {
			var ok = result.a;
			return okFunc(ok);
		} else {
			return defaultValue;
		}
	});
var $author$project$Page$Search$SearchQueryParam$fromStringInputToSearchInput = function (inputValue) {
	return A3(
		$elm_community$result_extra$Result$Extra$unwrap,
		$author$project$Page$Search$SearchQueryParam$defaultWhenNotRecognised(inputValue),
		$elm$core$Basics$identity,
		A2(
			$elm_community$result_extra$Result$Extra$or,
			$author$project$Page$Search$SearchQueryParam$phoneNumberRecogniser(inputValue),
			A2(
				$elm_community$result_extra$Result$Extra$or,
				$author$project$Page$Search$SearchQueryParam$dniRecogniser(inputValue),
				A2(
					$elm_community$result_extra$Result$Extra$or,
					$author$project$Page$Search$SearchQueryParam$emptyStringRecogniser(inputValue),
					A2(
						$elm_community$result_extra$Result$Extra$or,
						$author$project$Page$Search$SearchQueryParam$cfIdRecogniser(inputValue),
						$author$project$Page$Search$SearchQueryParam$mailRecogniser(inputValue))))));
};
var $author$project$Route$parseSearchQueryParam = function () {
	var composeSearchQuery = function (queryParamList) {
		var searchQueryValue = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(queryParamList));
		return $elm$core$String$isEmpty(searchQueryValue) ? $elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty) : $elm$core$Result$Ok(
			$author$project$Page$Search$SearchQueryParam$fromStringInputToSearchInput(searchQueryValue));
	};
	return A2($elm$url$Url$Parser$Query$custom, 'search', composeSearchQuery);
}();
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aZ;
		var unvisited = _v1.aA;
		var params = _v1.aQ;
		var frag = _v1.aJ;
		var value = _v1.eW;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aZ;
		var unvisited = _v0.aA;
		var params = _v0.aQ;
		var frag = _v0.aJ;
		var value = _v0.eW;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aZ;
			var unvisited = _v0.aA;
			var params = _v0.aQ;
			var frag = _v0.aJ;
			var value = _v0.eW;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Route$uuid = A2($elm$url$Url$Parser$custom, 'uuid', $danyx23$elm_uuid$Uuid$fromString);
var $author$project$Route$parser = function () {
	var routeParser = $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Search,
				A2($elm$url$Url$Parser$questionMark, $elm$url$Url$Parser$top, $author$project$Route$parseSearchQueryParam)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Payment,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('payment'),
					$author$project$Route$uuid)),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$Login,
				$elm$url$Url$Parser$s('login')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$NotAuthorized,
				$elm$url$Url$Parser$s('not-authorized')),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$PolicyDetails,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('policy-details'),
					A2($elm$url$Url$Parser$questionMark, $author$project$Route$uuid, $author$project$Route$parseRefererQueryParam))),
				A2(
				$elm$url$Url$Parser$map,
				$author$project$Route$OfferDetails,
				A2(
					$elm$url$Url$Parser$slash,
					$elm$url$Url$Parser$s('offer-details'),
					$elm$url$Url$Parser$string))
			]));
	var routeWithLanguage = A2(
		$elm$url$Url$Parser$slash,
		$elm$url$Url$Parser$oneOf(
			_List_fromArray(
				[
					$elm$url$Url$Parser$s('es'),
					$elm$url$Url$Parser$s('en')
				])),
		routeParser);
	return $elm$url$Url$Parser$oneOf(
		_List_fromArray(
			[routeParser, routeWithLanguage]));
}();
var $author$project$Route$fromUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Route$NotFound,
		A2($elm$url$Url$Parser$parse, $author$project$Route$parser, url));
};
var $author$project$Common$User$hasAnyPermissions = function (user) {
	if (!user.$) {
		return false;
	} else {
		var permissions = user.a.cN;
		return !$elm$core$List$isEmpty(permissions);
	}
};
var $author$project$Main$canAccess = F2(
	function (user, route) {
		switch (route.$) {
			case 2:
				return true;
			case 0:
				return true;
			case 1:
				return true;
			case 3:
				return $author$project$Common$User$hasAnyPermissions(user);
			case 4:
				return $author$project$Common$User$hasAnyPermissions(user);
			case 5:
				return $author$project$Common$User$hasAnyPermissions(user);
			default:
				return $author$project$Common$User$hasAnyPermissions(user);
		}
	});
var $author$project$Page$Login$Effect$CheckIfAuthed = {$: 1};
var $krisajenkins$remotedata$RemoteData$Loading = {$: 1};
var $author$project$Page$Login$Model$init = F2(
	function (externalApiUrl, maybeTargetRoute) {
		var targetRoute = function () {
			if (maybeTargetRoute.$ === 1) {
				return $author$project$Route$Search(
					$elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty));
			} else {
				if (maybeTargetRoute.a.$ === 2) {
					var _v1 = maybeTargetRoute.a;
					return $author$project$Route$Search(
						$elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty));
				} else {
					var route = maybeTargetRoute.a;
					return route;
				}
			}
		}();
		return {ku: $krisajenkins$remotedata$RemoteData$Loading, cr: externalApiUrl, ny: targetRoute};
	});
var $author$project$Page$Login$init = F2(
	function (apiUrl, targetRoute) {
		return _Utils_Tuple2(
			A2($author$project$Page$Login$Model$init, apiUrl, targetRoute),
			$author$project$Page$Login$Effect$CheckIfAuthed);
	});
var $author$project$Page$NotAuthorized$Effect$None = 0;
var $author$project$Page$NotAuthorized$Model$init = {};
var $author$project$Page$NotAuthorized$init = _Utils_Tuple2($author$project$Page$NotAuthorized$Model$init, 0);
var $author$project$Page$NotFound$Effect$None = 0;
var $author$project$Page$NotFound$Model$init = {};
var $author$project$Page$NotFound$init = _Utils_Tuple2($author$project$Page$NotFound$Model$init, 0);
var $author$project$Page$OfferDetails$Effect$Effects = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$OfferDetails$Effect$FetchOffer = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$OfferDetails$Effect$GetCurrentDate = {$: 8};
var $krisajenkins$remotedata$RemoteData$NotAsked = {$: 0};
var $author$project$Page$OfferDetails$Msg$PortfolioMsg = function (a) {
	return {$: 11, a: a};
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$Model = F4(
	function (popUp, isSendEmailSubmitted, sendEmailCheckBoxGroup, recipientEmail) {
		return {ga: isSendEmailSubmitted, g3: popUp, g9: recipientEmail, bR: sendEmailCheckBoxGroup};
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$FieldStatus$FieldStatus = F2(
	function (isBlurred, isDirty) {
		return {lu: isBlurred, lw: isDirty};
	});
var $author$project$Pyxis$Components$Field$FieldStatus$init = A2($author$project$Pyxis$Components$Field$FieldStatus$FieldStatus, false, false);
var $author$project$Pyxis$Components$Field$CheckboxGroup$init = {ao: _List_Nil, dK: $author$project$Pyxis$Components$Field$FieldStatus$init, gE: $elm$core$Maybe$Nothing, ed: $elm$core$Maybe$Nothing, gL: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Modal$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$init = function (id) {
	return {fB: $elm$core$Maybe$Nothing, fI: $elm$core$Maybe$Nothing, iO: id, f9: false, eE: $elm$core$Maybe$Nothing};
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$init = A4(
	$author$project$Common$ComponentUI$Modal$SendOfferEmail$Model,
	$author$project$Pyxis$Components$Modal$init('send-offer-email-modal'),
	false,
	$author$project$Pyxis$Components$Field$CheckboxGroup$init,
	'');
var $author$project$Common$Portfolio$Portfolio = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$init = {
	P: 0,
	ej: $elm$core$Maybe$Nothing,
	gW: $elm$core$Maybe$Nothing,
	bI: A3($elm$core$Dict$insert, 0, 1, $elm$core$Dict$empty)
};
var $author$project$Common$Portfolio$init = function (tagger) {
	return {fE: $elm$core$Maybe$Nothing, fF: $elm$core$Maybe$Nothing, dQ: $author$project$Pyxis$Components$Tabs$init, jd: $elm$core$Maybe$Nothing, bJ: $author$project$Pyxis$Components$Tabs$init, hz: tagger};
};
var $author$project$Pyxis$Components$Toasts$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Toasts$init = {dB: 0, O: $elm$core$Dict$empty};
var $author$project$Page$OfferDetails$Model$init = {
	kq: $krisajenkins$remotedata$RemoteData$NotAsked,
	cf: $krisajenkins$remotedata$RemoteData$NotAsked,
	dA: $author$project$Pyxis$Components$Modal$init('legal-recording-disclaimer-modal'),
	fu: $elm$core$Maybe$Nothing,
	fw: $krisajenkins$remotedata$RemoteData$NotAsked,
	C: $krisajenkins$remotedata$RemoteData$Loading,
	jm: $krisajenkins$remotedata$RemoteData$NotAsked,
	cW: $author$project$Common$Portfolio$init($author$project$Page$OfferDetails$Msg$PortfolioMsg),
	y: $author$project$Common$ComponentUI$Modal$SendOfferEmail$init,
	eF: $author$project$Pyxis$Components$Modal$init('start-recording-disclaimer-modal'),
	O: $author$project$Pyxis$Components$Toasts$init
};
var $author$project$Page$OfferDetails$init = function (offerCFID) {
	return _Utils_Tuple2(
		$author$project$Page$OfferDetails$Model$init,
		$author$project$Page$OfferDetails$Effect$Effects(
			_List_fromArray(
				[
					$author$project$Page$OfferDetails$Effect$FetchOffer(offerCFID),
					$author$project$Page$OfferDetails$Effect$GetCurrentDate
				])));
};
var $author$project$Page$Payment$Effect$Effects = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payment$Effect$FetchBraintreeToken = {$: 4};
var $author$project$Page$Payment$Effect$FetchProducts = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Payment$Model$Initializing = 0;
var $author$project$Page$Payment$Model$initCreditCardField = function (id) {
	return {iO: id, dV: false, d_: true, by: false};
};
var $author$project$Page$Payment$Model$initCreditCardFields = {
	ce: $author$project$Page$Payment$Model$initCreditCardField('credit-card-number'),
	cq: $author$project$Page$Payment$Model$initCreditCardField('credit-card-expiration-date')
};
var $author$project$Page$Payment$Model$init = function (offerId) {
	return {du: $author$project$Page$Payment$Model$initCreditCardFields, fV: $krisajenkins$remotedata$RemoteData$NotAsked, fW: $krisajenkins$remotedata$RemoteData$NotAsked, fX: $krisajenkins$remotedata$RemoteData$NotAsked, l7: offerId, g_: $elm$core$Maybe$Nothing, c1: 0};
};
var $author$project$Page$Payment$init = function (offerId) {
	return _Utils_Tuple2(
		$author$project$Page$Payment$Model$init(offerId),
		$author$project$Page$Payment$Effect$Effects(
			_List_fromArray(
				[
					$author$project$Page$Payment$Effect$FetchBraintreeToken,
					$author$project$Page$Payment$Effect$FetchProducts(offerId)
				])));
};
var $author$project$Page$PolicyDetails$Effect$Effects = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$PolicyDetails$Effect$FetchPolicy = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PolicyDetails$Effect$GetCurrentTime = {$: 7};
var $author$project$Page$PolicyDetails$Effect$RequestIssueRefundReleaseData = {$: 15};
var $author$project$Page$PolicyDetails$Msg$ChangeEmailModalMsg = function (a) {
	return {$: 45, a: a};
};
var $author$project$Page$PolicyDetails$Msg$ChangeEmailSubmitted = function (a) {
	return {$: 46, a: a};
};
var $author$project$Page$PolicyDetails$Msg$PortfolioMsg = function (a) {
	return {$: 36, a: a};
};
var $author$project$Page$PolicyDetails$Msg$SendEmailConfirmed = function (a) {
	return {$: 41, a: a};
};
var $author$project$Page$PolicyDetails$Msg$SendEmailModalMsg = function (a) {
	return {$: 42, a: a};
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$Closed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$Model = $elm$core$Basics$identity;
var $author$project$Page$PolicyDetails$ChangeEmailModal$ResetSubmission = {$: 4};
var $author$project$Pyxis$Components$Field$Input$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Input$init = {dK: $author$project$Pyxis$Components$Field$FieldStatus$init, gE: $elm$core$Maybe$Nothing, gL: $elm$core$Maybe$Nothing, gN: $elm$core$Maybe$Nothing, eW: ''};
var $author$project$Pyxis$Components$Field$Input$setOnFocus = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gL: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$init = F2(
	function (tagger, onConfirm) {
		return {
			aH: A2(
				$author$project$Pyxis$Components$Field$Input$setOnFocus,
				tagger($author$project$Page$PolicyDetails$ChangeEmailModal$ResetSubmission),
				$author$project$Pyxis$Components$Field$Input$init),
			cz: false,
			jc: onConfirm,
			hz: tagger,
			eX: $author$project$Page$PolicyDetails$ChangeEmailModal$Closed(
				$author$project$Pyxis$Components$Modal$init(''))
		};
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$DateInput$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$DateInput$init = {dK: $author$project$Pyxis$Components$Field$FieldStatus$init, gE: $elm$core$Maybe$Nothing, gL: $elm$core$Maybe$Nothing, gN: $elm$core$Maybe$Nothing, eW: ''};
var $author$project$Pyxis$Components$Field$Textarea$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Textarea$init = {dK: $author$project$Pyxis$Components$Field$FieldStatus$init, gE: $elm$core$Maybe$Nothing, gL: $elm$core$Maybe$Nothing, gN: $elm$core$Maybe$Nothing, eW: ''};
var $author$project$Page$PolicyDetails$Msg$InterruptionReasonConfirmed = {$: 12};
var $author$project$Page$PolicyDetails$Msg$InterruptionReasonReset = {$: 13};
var $author$project$Pyxis$Components$Field$Autocomplete$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$Basics$ternaryOperator = F3(
	function (condition, functionOnTrue, functionOnFalse) {
		return condition ? functionOnTrue : functionOnFalse;
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getFilterFunction = F4(
	function (isCaseSensitive, isStartWith, filteringValue, value) {
		var filterFunction = A3($author$project$Pyxis$Commons$Basics$ternaryOperator, isStartWith, $elm$core$String$startsWith, $elm$core$String$contains);
		var convertForCaseSensitive = A3($author$project$Pyxis$Commons$Basics$ternaryOperator, isCaseSensitive, $elm$core$Basics$identity, $elm$core$String$toLower);
		return A2(
			filterFunction,
			convertForCaseSensitive(filteringValue),
			convertForCaseSensitive(value));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$init = {
	E: $elm$core$Maybe$Nothing,
	dK: $author$project$Pyxis$Components$Field$FieldStatus$init,
	bm: A2($author$project$Pyxis$Components$Field$Autocomplete$getFilterFunction, false, true),
	B: '',
	dO: false,
	bv: false,
	bw: false,
	gE: $elm$core$Maybe$Nothing,
	gL: $elm$core$Maybe$Nothing,
	gM: $elm$core$Maybe$Nothing,
	gN: $elm$core$Maybe$Nothing,
	gV: $elm$core$Maybe$Nothing,
	eh: $elm$core$Maybe$Nothing,
	hh: $elm$core$Maybe$Nothing,
	eW: $elm$core$Maybe$Nothing,
	ak: $krisajenkins$remotedata$RemoteData$NotAsked
};
var $author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyContains = F2(
	function (isCaseSensitive, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				bm: A2($author$project$Pyxis$Components$Field$Autocomplete$getFilterFunction, isCaseSensitive, false)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOnReset = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gV: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOnSelect = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eh: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$initReasonInput = A2(
	$author$project$Pyxis$Components$Field$Autocomplete$setOnReset,
	$author$project$Page$PolicyDetails$Msg$InterruptionReasonReset,
	A2(
		$author$project$Pyxis$Components$Field$Autocomplete$setOnSelect,
		$author$project$Page$PolicyDetails$Msg$InterruptionReasonConfirmed,
		A2($author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyContains, false, $author$project$Pyxis$Components$Field$Autocomplete$init)));
var $author$project$Page$PolicyDetails$InterruptionModal$Model$init = {
	dF: $author$project$Pyxis$Components$Field$Textarea$init,
	k0: $krisajenkins$remotedata$RemoteData$NotAsked,
	dR: $author$project$Pyxis$Components$Field$DateInput$init,
	aj: $elm$core$Maybe$Nothing,
	gy: $elm$core$Maybe$Nothing,
	j: $elm$core$Maybe$Nothing,
	bN: $author$project$Page$PolicyDetails$InterruptionModal$Model$initReasonInput,
	eX: $author$project$Pyxis$Components$Modal$init('policy-interruption-modal')
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$Model = $elm$core$Basics$identity;
var $author$project$Page$PolicyDetails$RefundIssuingModal$init = {
	er: $elm$core$Maybe$Nothing,
	eX: $author$project$Pyxis$Components$Modal$init('policy-refund-modal')
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$Model = $elm$core$Basics$identity;
var $author$project$Common$ReasonDescriptionStatus$NotSet = 3;
var $author$project$Page$PolicyDetails$RenewalCancellationModal$initReasonInput = A2($author$project$Pyxis$Components$Field$Autocomplete$setFilterStrategyContains, false, $author$project$Pyxis$Components$Field$Autocomplete$init);
var $author$project$Page$PolicyDetails$RenewalCancellationModal$init = {
	kn: $elm$core$Maybe$Nothing,
	dF: $author$project$Pyxis$Components$Field$Textarea$init,
	z: 3,
	j: $elm$core$Maybe$Nothing,
	bN: $author$project$Page$PolicyDetails$RenewalCancellationModal$initReasonInput,
	eC: $elm$core$Maybe$Nothing,
	eX: $author$project$Pyxis$Components$Modal$init('renewal-cancellation-modal')
};
var $author$project$Page$PolicyDetails$SendEmailModal$Model = $elm$core$Basics$identity;
var $author$project$Page$PolicyDetails$SendEmailModal$OptionChecked = {$: 3};
var $author$project$Pyxis$Components$Field$CheckboxGroup$setOnCheck = F2(
	function (msg, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				ed: $elm$core$Maybe$Just(msg)
			});
	});
var $author$project$Page$PolicyDetails$SendEmailModal$init = F3(
	function (policyId, confirmMsg, tagger) {
		return {
			aE: A2(
				$author$project$Pyxis$Components$Field$CheckboxGroup$setOnCheck,
				tagger($author$project$Page$PolicyDetails$SendEmailModal$OptionChecked),
				$author$project$Pyxis$Components$Field$CheckboxGroup$init),
			ia: confirmMsg(policyId),
			fG: $elm$core$Maybe$Nothing,
			cz: false,
			m_: policyId,
			hz: tagger,
			eX: $author$project$Pyxis$Components$Modal$init('policy-email-send-modal')
		};
	});
var $author$project$Page$PolicyDetails$Model$init = F2(
	function (referer, policyId) {
		return {
			ba: A2($author$project$Page$PolicyDetails$ChangeEmailModal$init, $author$project$Page$PolicyDetails$Msg$ChangeEmailModalMsg, $author$project$Page$PolicyDetails$Msg$ChangeEmailSubmitted),
			cf: $krisajenkins$remotedata$RemoteData$NotAsked,
			ii: $krisajenkins$remotedata$RemoteData$NotAsked,
			fr: $krisajenkins$remotedata$RemoteData$NotAsked,
			ik: $elm$core$Maybe$Nothing,
			fw: $krisajenkins$remotedata$RemoteData$NotAsked,
			o: $author$project$Page$PolicyDetails$InterruptionModal$Model$init,
			dT: $krisajenkins$remotedata$RemoteData$NotAsked,
			lx: $elm$core$Maybe$Nothing,
			ly: $krisajenkins$remotedata$RemoteData$NotAsked,
			jm: $krisajenkins$remotedata$RemoteData$NotAsked,
			jn: $author$project$Pyxis$Components$Tabs$init,
			j: $krisajenkins$remotedata$RemoteData$Loading,
			m_: policyId,
			cW: $author$project$Common$Portfolio$init($author$project$Page$PolicyDetails$Msg$PortfolioMsg),
			m9: referer,
			er: $krisajenkins$remotedata$RemoteData$NotAsked,
			bO: $author$project$Page$PolicyDetails$RefundIssuingModal$init,
			ab: $author$project$Page$PolicyDetails$RenewalCancellationModal$init,
			jB: $krisajenkins$remotedata$RemoteData$NotAsked,
			bS: A3($author$project$Page$PolicyDetails$SendEmailModal$init, policyId, $author$project$Page$PolicyDetails$Msg$SendEmailConfirmed, $author$project$Page$PolicyDetails$Msg$SendEmailModalMsg),
			eD: $krisajenkins$remotedata$RemoteData$NotAsked,
			O: $author$project$Pyxis$Components$Toasts$init
		};
	});
var $author$project$Page$PolicyDetails$init = F2(
	function (referer, policyId) {
		return _Utils_Tuple2(
			A2($author$project$Page$PolicyDetails$Model$init, referer, policyId),
			$author$project$Page$PolicyDetails$Effect$Effects(
				_List_fromArray(
					[
						$author$project$Page$PolicyDetails$Effect$FetchPolicy(policyId),
						$author$project$Page$PolicyDetails$Effect$GetCurrentTime,
						$author$project$Page$PolicyDetails$Effect$RequestIssueRefundReleaseData
					])));
	});
var $author$project$Page$Search$Effect$Effects = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Search$Effect$FetchResults = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$Search$Effect$GetCurrentDate = {$: 5};
var $author$project$Page$Search$Msg$NoOp = {$: 12};
var $author$project$Page$Search$Effect$None = {$: 0};
var $author$project$Page$Search$Effect$WithCmd = function (a) {
	return {$: 3, a: a};
};
var $elm$browser$Browser$Dom$focus = _Browser_call('focus');
var $author$project$Page$Search$Model$init = {
	dA: $author$project$Pyxis$Components$Modal$init('legal-recording-disclaimer-modal'),
	fu: $elm$core$Maybe$Nothing,
	d0: false,
	c_: $author$project$Pyxis$Components$Field$Input$init,
	jH: 'personal-id-number',
	bQ: $elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty),
	aW: $krisajenkins$remotedata$RemoteData$NotAsked,
	eB: $elm$core$Maybe$Nothing,
	eF: $author$project$Pyxis$Components$Modal$init('stop-recording-disclaimer-modal'),
	O: $author$project$Pyxis$Components$Toasts$init
};
var $author$project$Pyxis$Components$Field$Input$setValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{eW: value});
	});
var $author$project$Page$Search$Model$setSearchInputField = F2(
	function (searchTerm, model) {
		return _Utils_update(
			model,
			{
				c_: A2($author$project$Pyxis$Components$Field$Input$setValue, searchTerm, $author$project$Pyxis$Components$Field$Input$init)
			});
	});
var $author$project$Page$Search$init = function (searchQuery) {
	var initialModel = $author$project$Page$Search$Model$init;
	var searchModel = function () {
		if (!searchQuery.$) {
			switch (searchQuery.a.$) {
				case 3:
					var _v3 = searchQuery.a;
					return initialModel;
				case 0:
					var documentId = searchQuery.a.a;
					return A2(
						$author$project$Page$Search$Model$setSearchInputField,
						documentId,
						_Utils_update(
							initialModel,
							{bQ: searchQuery, aW: $krisajenkins$remotedata$RemoteData$Loading}));
				case 1:
					var customerFacingId = searchQuery.a.a;
					return A2(
						$author$project$Page$Search$Model$setSearchInputField,
						$author$project$Page$Search$SearchQueryParam$pickCustomerFacingId(customerFacingId),
						_Utils_update(
							initialModel,
							{bQ: searchQuery, aW: $krisajenkins$remotedata$RemoteData$Loading}));
				case 2:
					var email = searchQuery.a.a;
					return A2(
						$author$project$Page$Search$Model$setSearchInputField,
						email,
						_Utils_update(
							initialModel,
							{bQ: searchQuery, aW: $krisajenkins$remotedata$RemoteData$Loading}));
				case 4:
					var plateNumber = searchQuery.a.a;
					return A2(
						$author$project$Page$Search$Model$setSearchInputField,
						plateNumber,
						_Utils_update(
							initialModel,
							{bQ: searchQuery, aW: $krisajenkins$remotedata$RemoteData$Loading}));
				default:
					var phoneNumber = searchQuery.a.a;
					return A2(
						$author$project$Page$Search$Model$setSearchInputField,
						phoneNumber,
						_Utils_update(
							initialModel,
							{bQ: searchQuery, aW: $krisajenkins$remotedata$RemoteData$Loading}));
			}
		} else {
			return _Utils_update(
				initialModel,
				{bQ: searchQuery});
		}
	}();
	var additionalEffect = function () {
		if (!searchQuery.$) {
			if (searchQuery.a.$ === 3) {
				var _v1 = searchQuery.a;
				return $author$project$Page$Search$Effect$WithCmd(
					A2(
						$elm$core$Task$attempt,
						$elm$core$Basics$always($author$project$Page$Search$Msg$NoOp),
						$elm$browser$Browser$Dom$focus(initialModel.jH)));
			} else {
				var searchInputString = searchQuery.a;
				return $author$project$Page$Search$Effect$FetchResults(searchInputString);
			}
		} else {
			return $author$project$Page$Search$Effect$None;
		}
	}();
	return _Utils_Tuple2(
		searchModel,
		$author$project$Page$Search$Effect$Effects(
			_List_fromArray(
				[additionalEffect, $author$project$Page$Search$Effect$GetCurrentDate])));
};
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $author$project$Main$initPage = F2(
	function (env, route) {
		switch (route.$) {
			case 0:
				return A3($elm$core$Tuple$mapBoth, $author$project$Model$NotFound, $author$project$Effect$NotFound, $author$project$Page$NotFound$init);
			case 1:
				return A3($elm$core$Tuple$mapBoth, $author$project$Model$NotAuthorized, $author$project$Effect$NotAuthorized, $author$project$Page$NotAuthorized$init);
			case 2:
				return A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Model$Login,
					$author$project$Effect$Login,
					A2(
						$author$project$Page$Login$init,
						$author$project$Env$externalApiUrl(env),
						$elm$core$Maybe$Nothing));
			case 3:
				var searchQuery = route.a;
				return A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Model$Search,
					$author$project$Effect$Search,
					$author$project$Page$Search$init(searchQuery));
			case 4:
				var offerId = route.a;
				return A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Model$Payment,
					$author$project$Effect$Payment,
					$author$project$Page$Payment$init(offerId));
			case 5:
				var policyId = route.a;
				var referer = route.b;
				return A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Model$PolicyDetails,
					$author$project$Effect$PolicyDetails,
					A2($author$project$Page$PolicyDetails$init, referer, policyId));
			default:
				var cfid = route.a;
				return A3(
					$elm$core$Tuple$mapBoth,
					$author$project$Model$OfferDetails,
					$author$project$Effect$OfferDetails,
					$author$project$Page$OfferDetails$init(cfid));
		}
	});
var $author$project$Common$User$isBackoffice = function (user) {
	if (!user.$) {
		return false;
	} else {
		return true;
	}
};
var $author$project$Main$onRouteChange = F2(
	function (model, route) {
		if (A2($author$project$Main$canAccess, model.eV, route)) {
			var _v0 = A2($author$project$Main$initPage, model.kY, route);
			var pageModel = _v0.a;
			var effect = _v0.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{mX: pageModel}),
				effect);
		} else {
			return _Utils_Tuple2(
				model,
				$author$project$Common$User$isBackoffice(model.eV) ? $author$project$Effect$ChangeRoute($author$project$Route$NotAuthorized) : $author$project$Effect$ChangeRoute($author$project$Route$Login));
		}
	});
var $author$project$Page$Login$Effect$None = {$: 0};
var $author$project$Page$Login$Effect$TryRouteUserTo = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Login$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var result = msg.a;
			switch (result.$) {
				case 3:
					var user = result.a;
					return _Utils_Tuple2(
						model,
						$author$project$Page$Login$Effect$TryRouteUserTo(
							_Utils_Tuple2(user, model.ny)));
				case 2:
					var failure = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ku: $krisajenkins$remotedata$RemoteData$Failure(failure)
							}),
						$author$project$Page$Login$Effect$None);
				default:
					return _Utils_Tuple2(model, $author$project$Page$Login$Effect$None);
			}
		} else {
			return _Utils_Tuple2(model, $author$project$Page$Login$Effect$None);
		}
	});
var $author$project$Page$NotAuthorized$update = F2(
	function (_v0, model) {
		return _Utils_Tuple2(model, 0);
	});
var $author$project$Page$NotFound$update = F2(
	function (_v0, model) {
		return _Utils_Tuple2(model, 0);
	});
var $author$project$Page$OfferDetails$Effect$AmendOfferCoverageEffectiveDate = F2(
	function (a, b) {
		return {$: 7, a: a, b: b};
	});
var $author$project$Page$OfferDetails$Effect$ChangeRoute = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$OfferDetails$Effect$FetchPaymentMethod = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$OfferDetails$Effect$FetchPricingActionClaimHistory = function (a) {
	return {$: 4, a: a};
};
var $author$project$ExternalUrl$FunnelOffer = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Page$OfferDetails$Effect$None = {$: 11};
var $author$project$Page$OfferDetails$Effect$SendOfferEmail = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$OfferDetails$Effect$WithCmd = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pyxis$Components$Toasts$Add = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$deletingTimed = function (timed) {
	switch (timed.$) {
		case 0:
			var wrappedEl = timed.b;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl);
		case 1:
			var wrappedEl = timed.a;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl);
		case 2:
			var wrappedEl = timed.a;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl);
		default:
			var wrappedEl = timed.b;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl);
	}
};
var $author$project$Pyxis$Components$Toasts$updateModelDeletingToast = F2(
	function (toastUUID, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				O: A3(
					$elm$core$Dict$update,
					toastUUID,
					$elm$core$Maybe$map($author$project$Pyxis$Components$Toasts$ToastsHelpers$deletingTimed),
					model.O)
			});
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$Idle = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$Paused = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$deltaIntervalInMilliseconds = 500;
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$pausingTimed = function (timed) {
	switch (timed.$) {
		case 0:
			var visibilityTime = timed.a;
			var wrappedEl = timed.b;
			return A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Paused, visibilityTime, wrappedEl);
		case 1:
			var wrappedEl = timed.a;
			return A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Paused, $author$project$Pyxis$Components$Toasts$ToastsHelpers$deltaIntervalInMilliseconds, wrappedEl);
		case 2:
			var wrappedEl = timed.a;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Idle(wrappedEl);
		default:
			var visibilityTime = timed.a;
			var wrappedEl = timed.b;
			return A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Paused, visibilityTime, wrappedEl);
	}
};
var $author$project$Pyxis$Components$Toasts$updateModelPausedToast = F2(
	function (toastUUID, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				O: A3(
					$elm$core$Dict$update,
					toastUUID,
					$elm$core$Maybe$map($author$project$Pyxis$Components$Toasts$ToastsHelpers$pausingTimed),
					model.O)
			});
	});
var $author$project$Pyxis$Components$Toasts$updateModelRemoveToast = F2(
	function (toastUUID, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				O: A2($elm$core$Dict$remove, toastUUID, model.O)
			});
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$Running = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$resumingTimed = function (timed) {
	switch (timed.$) {
		case 3:
			var visibilityTime = timed.a;
			var wrappedEl = timed.b;
			return _Utils_eq(visibilityTime, $author$project$Pyxis$Components$Toasts$ToastsHelpers$deltaIntervalInMilliseconds) ? $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl) : A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Running, visibilityTime, wrappedEl);
		case 1:
			var wrappedEl = timed.a;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedEl);
		case 2:
			var wrappedEl = timed.a;
			return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Idle(wrappedEl);
		default:
			var visibilityTime = timed.a;
			var wrappedEl = timed.b;
			return A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Running, visibilityTime, wrappedEl);
	}
};
var $author$project$Pyxis$Components$Toasts$updateModelResumeToast = F2(
	function (toastUUID, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				O: A3(
					$elm$core$Dict$update,
					toastUUID,
					$elm$core$Maybe$map($author$project$Pyxis$Components$Toasts$ToastsHelpers$resumingTimed),
					model.O)
			});
	});
var $author$project$Pyxis$Components$Toasts$updateModelWithIncreasedCounter = function (_v0) {
	var model = _v0;
	return _Utils_update(
		model,
		{dB: model.dB + 1});
};
var $primait$prima_elm_extra$PrimaFunction$flip = F3(
	function (mapper, b, a) {
		return A2(mapper, a, b);
	});
var $elm$bytes$Bytes$Encode$getWidth = function (builder) {
	switch (builder.$) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 4;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 4;
		case 6:
			return 4;
		case 7:
			return 8;
		case 8:
			var w = builder.a;
			return w;
		case 9:
			var w = builder.a;
			return w;
		default:
			var bs = builder.a;
			return _Bytes_width(bs);
	}
};
var $elm$bytes$Bytes$LE = 0;
var $elm$bytes$Bytes$Encode$write = F3(
	function (builder, mb, offset) {
		switch (builder.$) {
			case 0:
				var n = builder.a;
				return A3(_Bytes_write_i8, mb, offset, n);
			case 1:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i16, mb, offset, n, !e);
			case 2:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_i32, mb, offset, n, !e);
			case 3:
				var n = builder.a;
				return A3(_Bytes_write_u8, mb, offset, n);
			case 4:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u16, mb, offset, n, !e);
			case 5:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_u32, mb, offset, n, !e);
			case 6:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f32, mb, offset, n, !e);
			case 7:
				var e = builder.a;
				var n = builder.b;
				return A4(_Bytes_write_f64, mb, offset, n, !e);
			case 8:
				var bs = builder.b;
				return A3($elm$bytes$Bytes$Encode$writeSequence, bs, mb, offset);
			case 9:
				var s = builder.b;
				return A3(_Bytes_write_string, mb, offset, s);
			default:
				var bs = builder.a;
				return A3(_Bytes_write_bytes, mb, offset, bs);
		}
	});
var $elm$bytes$Bytes$Encode$writeSequence = F3(
	function (builders, mb, offset) {
		writeSequence:
		while (true) {
			if (!builders.b) {
				return offset;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$builders = bs,
					$temp$mb = mb,
					$temp$offset = A3($elm$bytes$Bytes$Encode$write, b, mb, offset);
				builders = $temp$builders;
				mb = $temp$mb;
				offset = $temp$offset;
				continue writeSequence;
			}
		}
	});
var $elm$bytes$Bytes$Encode$encode = _Bytes_encode;
var $TSFoster$elm_uuid$UUID$UUID = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$bytes$Bytes$Encode$Bytes = function (a) {
	return {$: 10, a: a};
};
var $elm$bytes$Bytes$Encode$bytes = $elm$bytes$Bytes$Encode$Bytes;
var $elm$bytes$Bytes$BE = 1;
var $elm$bytes$Bytes$Encode$Seq = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$getWidths = F2(
	function (width, builders) {
		getWidths:
		while (true) {
			if (!builders.b) {
				return width;
			} else {
				var b = builders.a;
				var bs = builders.b;
				var $temp$width = width + $elm$bytes$Bytes$Encode$getWidth(b),
					$temp$builders = bs;
				width = $temp$width;
				builders = $temp$builders;
				continue getWidths;
			}
		}
	});
var $elm$bytes$Bytes$Encode$sequence = function (builders) {
	return A2(
		$elm$bytes$Bytes$Encode$Seq,
		A2($elm$bytes$Bytes$Encode$getWidths, 0, builders),
		builders);
};
var $elm$bytes$Bytes$Encode$U32 = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$unsignedInt32 = $elm$bytes$Bytes$Encode$U32;
var $TSFoster$elm_uuid$UUID$encoder = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var d = _v0.d;
	return $elm$bytes$Bytes$Encode$sequence(
		_List_fromArray(
			[
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, a),
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, b),
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, c),
				A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, d)
			]));
};
var $elm$bytes$Bytes$Decode$decode = F2(
	function (_v0, bs) {
		var decoder = _v0;
		return A2(_Bytes_decode, decoder, bs);
	});
var $elm$bytes$Bytes$Decode$Decoder = $elm$core$Basics$identity;
var $elm$bytes$Bytes$Decode$loopHelp = F4(
	function (state, callback, bites, offset) {
		loopHelp:
		while (true) {
			var _v0 = callback(state);
			var decoder = _v0;
			var _v1 = A2(decoder, bites, offset);
			var newOffset = _v1.a;
			var step = _v1.b;
			if (!step.$) {
				var newState = step.a;
				var $temp$state = newState,
					$temp$callback = callback,
					$temp$bites = bites,
					$temp$offset = newOffset;
				state = $temp$state;
				callback = $temp$callback;
				bites = $temp$bites;
				offset = $temp$offset;
				continue loopHelp;
			} else {
				var result = step.a;
				return _Utils_Tuple2(newOffset, result);
			}
		}
	});
var $elm$bytes$Bytes$Decode$loop = F2(
	function (state, callback) {
		return A2($elm$bytes$Bytes$Decode$loopHelp, state, callback);
	});
var $elm$bytes$Bytes$Decode$Done = function (a) {
	return {$: 1, a: a};
};
var $elm$bytes$Bytes$Decode$Loop = function (a) {
	return {$: 0, a: a};
};
var $elm$bytes$Bytes$Decode$map = F2(
	function (func, _v0) {
		var decodeA = _v0;
		return F2(
			function (bites, offset) {
				var _v1 = A2(decodeA, bites, offset);
				var aOffset = _v1.a;
				var a = _v1.b;
				return _Utils_Tuple2(
					aOffset,
					func(a));
			});
	});
var $elm$bytes$Bytes$Decode$succeed = function (a) {
	return F2(
		function (_v0, offset) {
			return _Utils_Tuple2(offset, a);
		});
};
var $TSFoster$elm_sha1$SHA1$loopHelp = F2(
	function (step, _v0) {
		var n = _v0.a;
		var state = _v0.b;
		return (n > 0) ? A2(
			$elm$bytes$Bytes$Decode$map,
			function (_new) {
				return $elm$bytes$Bytes$Decode$Loop(
					_Utils_Tuple2(n - 1, _new));
			},
			step(state)) : $elm$bytes$Bytes$Decode$succeed(
			$elm$bytes$Bytes$Decode$Done(state));
	});
var $TSFoster$elm_sha1$SHA1$iterate = F3(
	function (n, step, initial) {
		return A2(
			$elm$bytes$Bytes$Decode$loop,
			_Utils_Tuple2(n, initial),
			$TSFoster$elm_sha1$SHA1$loopHelp(step));
	});
var $elm$core$List$repeatHelp = F3(
	function (result, n, value) {
		repeatHelp:
		while (true) {
			if (n <= 0) {
				return result;
			} else {
				var $temp$result = A2($elm$core$List$cons, value, result),
					$temp$n = n - 1,
					$temp$value = value;
				result = $temp$result;
				n = $temp$n;
				value = $temp$value;
				continue repeatHelp;
			}
		}
	});
var $elm$core$List$repeat = F2(
	function (n, value) {
		return A3($elm$core$List$repeatHelp, _List_Nil, n, value);
	});
var $elm$bytes$Bytes$Encode$U8 = function (a) {
	return {$: 3, a: a};
};
var $elm$bytes$Bytes$Encode$unsignedInt8 = $elm$bytes$Bytes$Encode$U8;
var $elm$bytes$Bytes$width = _Bytes_width;
var $TSFoster$elm_sha1$SHA1$padBuffer = function (bytes) {
	var byteCount = $elm$bytes$Bytes$width(bytes);
	var paddingSize = 4 + A2(
		$elm$core$Basics$modBy,
		64,
		56 - A2($elm$core$Basics$modBy, 64, byteCount + 1));
	var message = $elm$bytes$Bytes$Encode$encode(
		$elm$bytes$Bytes$Encode$sequence(
			_List_fromArray(
				[
					$elm$bytes$Bytes$Encode$bytes(bytes),
					$elm$bytes$Bytes$Encode$unsignedInt8(128),
					$elm$bytes$Bytes$Encode$sequence(
					A2(
						$elm$core$List$repeat,
						paddingSize,
						$elm$bytes$Bytes$Encode$unsignedInt8(0))),
					A2($elm$bytes$Bytes$Encode$unsignedInt32, 1, byteCount << 3)
				])));
	return message;
};
var $elm$bytes$Bytes$Decode$map4 = F5(
	function (func, _v0, _v1, _v2, _v3) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		return F2(
			function (bites, offset) {
				var _v4 = A2(decodeA, bites, offset);
				var aOffset = _v4.a;
				var a = _v4.b;
				var _v5 = A2(decodeB, bites, aOffset);
				var bOffset = _v5.a;
				var b = _v5.b;
				var _v6 = A2(decodeC, bites, bOffset);
				var cOffset = _v6.a;
				var c = _v6.b;
				var _v7 = A2(decodeD, bites, cOffset);
				var dOffset = _v7.a;
				var d = _v7.b;
				return _Utils_Tuple2(
					dOffset,
					A4(func, a, b, c, d));
			});
	});
var $elm$bytes$Bytes$Decode$map5 = F6(
	function (func, _v0, _v1, _v2, _v3, _v4) {
		var decodeA = _v0;
		var decodeB = _v1;
		var decodeC = _v2;
		var decodeD = _v3;
		var decodeE = _v4;
		return F2(
			function (bites, offset) {
				var _v5 = A2(decodeA, bites, offset);
				var aOffset = _v5.a;
				var a = _v5.b;
				var _v6 = A2(decodeB, bites, aOffset);
				var bOffset = _v6.a;
				var b = _v6.b;
				var _v7 = A2(decodeC, bites, bOffset);
				var cOffset = _v7.a;
				var c = _v7.b;
				var _v8 = A2(decodeD, bites, cOffset);
				var dOffset = _v8.a;
				var d = _v8.b;
				var _v9 = A2(decodeE, bites, dOffset);
				var eOffset = _v9.a;
				var e = _v9.b;
				return _Utils_Tuple2(
					eOffset,
					A5(func, a, b, c, d, e));
			});
	});
var $TSFoster$elm_sha1$SHA1$map16 = function (f) {
	return function (b1) {
		return function (b2) {
			return function (b3) {
				return function (b4) {
					return function (b5) {
						return function (b6) {
							return function (b7) {
								return function (b8) {
									return function (b9) {
										return function (b10) {
											return function (b11) {
												return function (b12) {
													return function (b13) {
														return function (b14) {
															return function (b15) {
																return function (b16) {
																	var d1 = A5(
																		$elm$bytes$Bytes$Decode$map4,
																		F4(
																			function (a, b, c, d) {
																				return A4(f, a, b, c, d);
																			}),
																		b1,
																		b2,
																		b3,
																		b4);
																	var d2 = A6(
																		$elm$bytes$Bytes$Decode$map5,
																		F5(
																			function (h, a, b, c, d) {
																				return A4(h, a, b, c, d);
																			}),
																		d1,
																		b5,
																		b6,
																		b7,
																		b8);
																	var d3 = A6(
																		$elm$bytes$Bytes$Decode$map5,
																		F5(
																			function (h, a, b, c, d) {
																				return A4(h, a, b, c, d);
																			}),
																		d2,
																		b9,
																		b10,
																		b11,
																		b12);
																	var d4 = A6(
																		$elm$bytes$Bytes$Decode$map5,
																		F5(
																			function (h, a, b, c, d) {
																				return A4(h, a, b, c, d);
																			}),
																		d3,
																		b13,
																		b14,
																		b15,
																		b16);
																	return d4;
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $TSFoster$elm_sha1$SHA1$DeltaState = $elm$core$Basics$identity;
var $TSFoster$elm_sha1$SHA1$State = $elm$core$Basics$identity;
var $elm$core$Bitwise$complement = _Bitwise_complement;
var $TSFoster$elm_sha1$SHA1$rotateLeftBy = F2(
	function (amount, i) {
		return ((i >>> (32 - amount)) | (i << amount)) >>> 0;
	});
var $TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk = F3(
	function (index, _int, _v0) {
		var a = _v0.dj;
		var b = _v0.$7;
		var c = _v0.dt;
		var d = _v0.dE;
		var e = _v0.J;
		var shiftedA = (a >>> (32 - 5)) | (a << 5);
		var f = function () {
			var _v1 = (index / 20) | 0;
			switch (_v1) {
				case 0:
					return ((b & c) | ((~b) & d)) + 1518500249;
				case 1:
					return (b ^ (c ^ d)) + 1859775393;
				case 2:
					return ((b & (c | d)) | (c & d)) + 2400959708;
				default:
					return (b ^ (c ^ d)) + 3395469782;
			}
		}();
		var newA = (((shiftedA + f) + e) + _int) >>> 0;
		return {
			dj: newA,
			$7: a,
			dt: A2($TSFoster$elm_sha1$SHA1$rotateLeftBy, 30, b),
			dE: c,
			J: d
		};
	});
var $TSFoster$elm_sha1$SHA1$calculateDigestDeltas = function (remaining) {
	return function (index) {
		return function (a) {
			return function (b) {
				return function (c) {
					return function (d) {
						return function (e) {
							return function (v1) {
								return function (v2) {
									return function (v3) {
										return function (v4) {
											return function (v5) {
												return function (v6) {
													return function (v7) {
														return function (v8) {
															calculateDigestDeltas:
															while (true) {
																if (!remaining) {
																	return {dj: a, $7: b, dt: c, dE: d, J: e};
																} else {
																	var shiftedA = (a >>> (32 - 5)) | (a << 5);
																	var _int = v1;
																	var f = function () {
																		var _v0 = (index / 20) | 0;
																		switch (_v0) {
																			case 0:
																				return ((b & c) | ((~b) & d)) + 1518500249;
																			case 1:
																				return (b ^ (c ^ d)) + 1859775393;
																			case 2:
																				return ((b & (c | d)) | (c & d)) + 2400959708;
																			default:
																				return (b ^ (c ^ d)) + 3395469782;
																		}
																	}();
																	var newA = (((shiftedA + f) + e) + _int) >>> 0;
																	var $temp$remaining = remaining - 1,
																		$temp$index = index + 1,
																		$temp$a = newA,
																		$temp$b = a,
																		$temp$c = A2($TSFoster$elm_sha1$SHA1$rotateLeftBy, 30, b),
																		$temp$d = c,
																		$temp$e = d,
																		$temp$v1 = v2,
																		$temp$v2 = v3,
																		$temp$v3 = v4,
																		$temp$v4 = v5,
																		$temp$v5 = v6,
																		$temp$v6 = v7,
																		$temp$v7 = v8,
																		$temp$v8 = 0;
																	remaining = $temp$remaining;
																	index = $temp$index;
																	a = $temp$a;
																	b = $temp$b;
																	c = $temp$c;
																	d = $temp$d;
																	e = $temp$e;
																	v1 = $temp$v1;
																	v2 = $temp$v2;
																	v3 = $temp$v3;
																	v4 = $temp$v4;
																	v5 = $temp$v5;
																	v6 = $temp$v6;
																	v7 = $temp$v7;
																	v8 = $temp$v8;
																	continue calculateDigestDeltas;
																}
															}
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $TSFoster$elm_sha1$SHA1$rotateLeftBy1 = function (i) {
	return (i >>> 31) | (i << 1);
};
var $TSFoster$elm_sha1$SHA1$reduceWords = function (i) {
	return function (deltaState) {
		return function (b16) {
			return function (b15) {
				return function (b14) {
					return function (b13) {
						return function (b12) {
							return function (b11) {
								return function (b10) {
									return function (b9) {
										return function (b8) {
											return function (b7) {
												return function (b6) {
													return function (b5) {
														return function (b4) {
															return function (b3) {
																return function (b2) {
																	return function (b1) {
																		reduceWords:
																		while (true) {
																			var a = deltaState.dj;
																			var b = deltaState.$7;
																			var c = deltaState.dt;
																			var d = deltaState.dE;
																			var e = deltaState.J;
																			if (i !== 64) {
																				var value3 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b14 ^ (b12 ^ (b6 ^ b1)));
																				var value6 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b11 ^ (b9 ^ (b3 ^ value3)));
																				var value2 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b15 ^ (b13 ^ (b7 ^ b2)));
																				var value5 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b12 ^ (b10 ^ (b4 ^ value2)));
																				var value8 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b9 ^ (b7 ^ (b1 ^ value5)));
																				var value1 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b16 ^ (b14 ^ (b8 ^ b3)));
																				var value4 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b13 ^ (b11 ^ (b5 ^ value1)));
																				var value7 = $TSFoster$elm_sha1$SHA1$rotateLeftBy1(b10 ^ (b8 ^ (b2 ^ value4)));
																				var newState = $TSFoster$elm_sha1$SHA1$calculateDigestDeltas(8)(i + 16)(a)(b)(c)(d)(e)(value1)(value2)(value3)(value4)(value5)(value6)(value7)(value8);
																				var $temp$i = i + 8,
																					$temp$deltaState = newState,
																					$temp$b16 = b8,
																					$temp$b15 = b7,
																					$temp$b14 = b6,
																					$temp$b13 = b5,
																					$temp$b12 = b4,
																					$temp$b11 = b3,
																					$temp$b10 = b2,
																					$temp$b9 = b1,
																					$temp$b8 = value1,
																					$temp$b7 = value2,
																					$temp$b6 = value3,
																					$temp$b5 = value4,
																					$temp$b4 = value5,
																					$temp$b3 = value6,
																					$temp$b2 = value7,
																					$temp$b1 = value8;
																				i = $temp$i;
																				deltaState = $temp$deltaState;
																				b16 = $temp$b16;
																				b15 = $temp$b15;
																				b14 = $temp$b14;
																				b13 = $temp$b13;
																				b12 = $temp$b12;
																				b11 = $temp$b11;
																				b10 = $temp$b10;
																				b9 = $temp$b9;
																				b8 = $temp$b8;
																				b7 = $temp$b7;
																				b6 = $temp$b6;
																				b5 = $temp$b5;
																				b4 = $temp$b4;
																				b3 = $temp$b3;
																				b2 = $temp$b2;
																				b1 = $temp$b1;
																				continue reduceWords;
																			} else {
																				return deltaState;
																			}
																		}
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $TSFoster$elm_sha1$SHA1$reduceChunkHelp = function (_v0) {
	return function (b1) {
		return function (b2) {
			return function (b3) {
				return function (b4) {
					return function (b5) {
						return function (b6) {
							return function (b7) {
								return function (b8) {
									return function (b9) {
										return function (b10) {
											return function (b11) {
												return function (b12) {
													return function (b13) {
														return function (b14) {
															return function (b15) {
																return function (b16) {
																	var initial = _v0;
																	var initialDeltaState = A3(
																		$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																		15,
																		b16,
																		A3(
																			$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																			14,
																			b15,
																			A3(
																				$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																				13,
																				b14,
																				A3(
																					$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																					12,
																					b13,
																					A3(
																						$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																						11,
																						b12,
																						A3(
																							$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																							10,
																							b11,
																							A3(
																								$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																								9,
																								b10,
																								A3(
																									$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																									8,
																									b9,
																									A3(
																										$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																										7,
																										b8,
																										A3(
																											$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																											6,
																											b7,
																											A3(
																												$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																												5,
																												b6,
																												A3(
																													$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																													4,
																													b5,
																													A3(
																														$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																														3,
																														b4,
																														A3(
																															$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																															2,
																															b3,
																															A3(
																																$TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk,
																																1,
																																b2,
																																A3($TSFoster$elm_sha1$SHA1$calculateDigestDeltasChunk, 0, b1, initial))))))))))))))));
																	var _v1 = $TSFoster$elm_sha1$SHA1$reduceWords(0)(initialDeltaState)(b1)(b2)(b3)(b4)(b5)(b6)(b7)(b8)(b9)(b10)(b11)(b12)(b13)(b14)(b15)(b16);
																	var a = _v1.dj;
																	var b = _v1.$7;
																	var c = _v1.dt;
																	var d = _v1.dE;
																	var e = _v1.J;
																	return {dj: initial.dj + a, $7: initial.$7 + b, dt: initial.dt + c, dE: initial.dE + d, J: initial.J + e};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $elm$bytes$Bytes$Decode$unsignedInt32 = function (endianness) {
	return _Bytes_read_u32(!endianness);
};
var $TSFoster$elm_sha1$SHA1$u32 = $elm$bytes$Bytes$Decode$unsignedInt32(1);
var $TSFoster$elm_sha1$SHA1$reduceChunk = function (state) {
	return $TSFoster$elm_sha1$SHA1$map16(
		$TSFoster$elm_sha1$SHA1$reduceChunkHelp(state))($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32)($TSFoster$elm_sha1$SHA1$u32);
};
var $TSFoster$elm_sha1$SHA1$Digest = $elm$core$Basics$identity;
var $TSFoster$elm_sha1$SHA1$stateToDigest = function (_v0) {
	var a = _v0.dj;
	var b = _v0.$7;
	var c = _v0.dt;
	var d = _v0.dE;
	var e = _v0.J;
	return {dj: a >>> 0, $7: b >>> 0, dt: c >>> 0, dE: d >>> 0, J: e >>> 0};
};
var $TSFoster$elm_sha1$SHA1$hashBytes = F2(
	function (state, bytes) {
		var message = $TSFoster$elm_sha1$SHA1$padBuffer(bytes);
		var numberOfChunks = ($elm$bytes$Bytes$width(message) / 64) | 0;
		var hashState = A3($TSFoster$elm_sha1$SHA1$iterate, numberOfChunks, $TSFoster$elm_sha1$SHA1$reduceChunk, state);
		return $TSFoster$elm_sha1$SHA1$stateToDigest(
			A2(
				$elm$core$Maybe$withDefault,
				state,
				A2($elm$bytes$Bytes$Decode$decode, hashState, message)));
	});
var $TSFoster$elm_sha1$SHA1$Tuple5 = F5(
	function (a, b, c, d, e) {
		return {dj: a, $7: b, dt: c, dE: d, J: e};
	});
var $TSFoster$elm_sha1$SHA1$initialState = A5($TSFoster$elm_sha1$SHA1$Tuple5, 1732584193, 4023233417, 2562383102, 271733878, 3285377520);
var $TSFoster$elm_sha1$SHA1$fromBytes = $TSFoster$elm_sha1$SHA1$hashBytes($TSFoster$elm_sha1$SHA1$initialState);
var $TSFoster$elm_sha1$SHA1$toInt32s = function (_v0) {
	var digest = _v0;
	return digest;
};
var $TSFoster$elm_uuid$UUID$forceUnsigned = $elm$core$Bitwise$shiftRightZfBy(0);
var $TSFoster$elm_uuid$UUID$toVariant1 = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var c = _v0.c;
	var d = _v0.d;
	return A4(
		$TSFoster$elm_uuid$UUID$UUID,
		a,
		b,
		$TSFoster$elm_uuid$UUID$forceUnsigned(2147483648 | (1073741823 & c)),
		d);
};
var $TSFoster$elm_uuid$UUID$toVersion = F2(
	function (v, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return A4(
			$TSFoster$elm_uuid$UUID$UUID,
			a,
			$TSFoster$elm_uuid$UUID$forceUnsigned((v << 12) | (4294905855 & b)),
			c,
			d);
	});
var $TSFoster$elm_uuid$UUID$forBytes = F2(
	function (bytes, namespace) {
		return $TSFoster$elm_uuid$UUID$toVariant1(
			A2(
				$TSFoster$elm_uuid$UUID$toVersion,
				5,
				function (_v0) {
					var a = _v0.dj;
					var b = _v0.$7;
					var c = _v0.dt;
					var d = _v0.dE;
					return A4($TSFoster$elm_uuid$UUID$UUID, a, b, c, d);
				}(
					$TSFoster$elm_sha1$SHA1$toInt32s(
						$TSFoster$elm_sha1$SHA1$fromBytes(
							$elm$bytes$Bytes$Encode$encode(
								$elm$bytes$Bytes$Encode$sequence(
									_List_fromArray(
										[
											$TSFoster$elm_uuid$UUID$encoder(namespace),
											$elm$bytes$Bytes$Encode$bytes(bytes)
										]))))))));
	});
var $elm$bytes$Bytes$Encode$Utf8 = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $elm$bytes$Bytes$Encode$string = function (str) {
	return A2(
		$elm$bytes$Bytes$Encode$Utf8,
		_Bytes_getStringWidth(str),
		str);
};
var $TSFoster$elm_uuid$UUID$forName = A2(
	$elm$core$Basics$composeL,
	A2($elm$core$Basics$composeL, $TSFoster$elm_uuid$UUID$forBytes, $elm$bytes$Bytes$Encode$encode),
	$elm$bytes$Bytes$Encode$string);
var $TSFoster$elm_uuid$UUID$toHex = F2(
	function (acc, _int) {
		toHex:
		while (true) {
			if (!_int) {
				return $elm$core$String$fromList(acc);
			} else {
				var _char = function () {
					var _v0 = 15 & _int;
					switch (_v0) {
						case 0:
							return '0';
						case 1:
							return '1';
						case 2:
							return '2';
						case 3:
							return '3';
						case 4:
							return '4';
						case 5:
							return '5';
						case 6:
							return '6';
						case 7:
							return '7';
						case 8:
							return '8';
						case 9:
							return '9';
						case 10:
							return 'a';
						case 11:
							return 'b';
						case 12:
							return 'c';
						case 13:
							return 'd';
						case 14:
							return 'e';
						default:
							return 'f';
					}
				}();
				var $temp$acc = A2($elm$core$List$cons, _char, acc),
					$temp$int = _int >>> 4;
				acc = $temp$acc;
				_int = $temp$int;
				continue toHex;
			}
		}
	});
var $TSFoster$elm_uuid$UUID$toStringWith = F2(
	function (sep, _v0) {
		var a = _v0.a;
		var b = _v0.b;
		var c = _v0.c;
		var d = _v0.d;
		return _Utils_ap(
			A3(
				$elm$core$String$padLeft,
				8,
				'0',
				A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, a)),
			_Utils_ap(
				sep,
				_Utils_ap(
					A3(
						$elm$core$String$padLeft,
						4,
						'0',
						A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, b >>> 16)),
					_Utils_ap(
						sep,
						_Utils_ap(
							A3(
								$elm$core$String$padLeft,
								4,
								'0',
								A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & b)),
							_Utils_ap(
								sep,
								_Utils_ap(
									A3(
										$elm$core$String$padLeft,
										4,
										'0',
										A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, c >>> 16)),
									_Utils_ap(
										sep,
										_Utils_ap(
											A3(
												$elm$core$String$padLeft,
												4,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, 65535 & c)),
											A3(
												$elm$core$String$padLeft,
												8,
												'0',
												A2($TSFoster$elm_uuid$UUID$toHex, _List_Nil, d)))))))))));
	});
var $TSFoster$elm_uuid$UUID$toString = $TSFoster$elm_uuid$UUID$toStringWith('-');
var $TSFoster$elm_uuid$UUID$urlNamespace = A4($TSFoster$elm_uuid$UUID$UUID, 1806153745, 2645365201, 2159280320, 1339306184);
var $author$project$Pyxis$Components$Toasts$counterToUUID = function (counter) {
	return $TSFoster$elm_uuid$UUID$toString(
		A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$TSFoster$elm_uuid$UUID$forName,
			$TSFoster$elm_uuid$UUID$urlNamespace,
			$elm$core$String$fromInt(counter)));
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$idle = $author$project$Pyxis$Components$Toasts$ToastsHelpers$Idle;
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$visibilityTimeInMilliseconds = 20000;
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$running = $author$project$Pyxis$Components$Toasts$ToastsHelpers$Running($author$project$Pyxis$Components$Toasts$ToastsHelpers$visibilityTimeInMilliseconds);
var $author$project$Pyxis$Components$Toasts$Toast = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Toasts$withInternalId = F2(
	function (internalId, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{iO: internalId});
	});
var $author$project$Pyxis$Components$Toasts$updateModelWithNewToast = F2(
	function (toastToAdd, _v0) {
		var isSticky = toastToAdd.d2;
		var model = _v0;
		var internalId = $author$project$Pyxis$Components$Toasts$counterToUUID(model.dB);
		var toastToAddWithId = A2($author$project$Pyxis$Components$Toasts$withInternalId, internalId, toastToAdd);
		var timedToast = isSticky ? $author$project$Pyxis$Components$Toasts$ToastsHelpers$idle(toastToAddWithId) : $author$project$Pyxis$Components$Toasts$ToastsHelpers$running(toastToAddWithId);
		return _Utils_update(
			model,
			{
				O: A3($elm$core$Dict$insert, internalId, timedToast, model.O)
			});
	});
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$isDeleting = function (timed) {
	if (timed.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Dict$map = F2(
	function (func, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				A2(func, key, value),
				A2($elm$core$Dict$map, func, left),
				A2($elm$core$Dict$map, func, right));
		}
	});
var $elm$core$Basics$truncate = _Basics_truncate;
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$tickDownVisibilityTimeForToastsDict = F3(
	function (tick, _v0, timedToast) {
		var deltaInTimeAsInt = tick | 0;
		switch (timedToast.$) {
			case 0:
				var visibilityTime = timedToast.a;
				var wrappedToast = timedToast.b;
				return _Utils_eq(visibilityTime - deltaInTimeAsInt, $author$project$Pyxis$Components$Toasts$ToastsHelpers$deltaIntervalInMilliseconds) ? $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedToast) : A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Running, visibilityTime - deltaInTimeAsInt, wrappedToast);
			case 1:
				var wrappedToast = timedToast.a;
				return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Deleting(wrappedToast);
			case 2:
				var wrappedToast = timedToast.a;
				return $author$project$Pyxis$Components$Toasts$ToastsHelpers$Idle(wrappedToast);
			default:
				var visibilityTime = timedToast.a;
				var wrappedToast = timedToast.b;
				return A2($author$project$Pyxis$Components$Toasts$ToastsHelpers$Paused, visibilityTime, wrappedToast);
		}
	});
var $author$project$Pyxis$Components$Toasts$updateModelWithTickDown = F2(
	function (deltaTime, _v0) {
		var model = _v0;
		var isNotDeleting = F2(
			function (_v1, timed) {
				return !$author$project$Pyxis$Components$Toasts$ToastsHelpers$isDeleting(timed);
			});
		return _Utils_update(
			model,
			{
				O: A2(
					$elm$core$Dict$map,
					$author$project$Pyxis$Components$Toasts$ToastsHelpers$tickDownVisibilityTimeForToastsDict(deltaTime),
					A2($elm$core$Dict$filter, isNotDeleting, model.O))
			});
	});
var $primait$prima_elm_extra$PrimaUpdate$withoutCmds = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $author$project$Pyxis$Components$Toasts$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 1:
				var delta = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2($author$project$Pyxis$Components$Toasts$updateModelWithTickDown, delta, model));
			case 0:
				var toastToAdd = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2(
						$author$project$Pyxis$Components$Toasts$updateModelWithNewToast,
						toastToAdd,
						$author$project$Pyxis$Components$Toasts$updateModelWithIncreasedCounter(model)));
			case 2:
				var toastUUID = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2($author$project$Pyxis$Components$Toasts$updateModelDeletingToast, toastUUID, model));
			case 3:
				var toastUUID = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2($author$project$Pyxis$Components$Toasts$updateModelRemoveToast, toastUUID, model));
			case 4:
				var toastUUID = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2($author$project$Pyxis$Components$Toasts$updateModelPausedToast, toastUUID, model));
			default:
				var toastUUID = msg.a;
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(
					A2($author$project$Pyxis$Components$Toasts$updateModelResumeToast, toastUUID, model));
		}
	});
var $author$project$Pyxis$Components$Toasts$addNewToast = function (toastToAdd) {
	return $author$project$Pyxis$Components$Toasts$update(
		$author$project$Pyxis$Components$Toasts$Add(toastToAdd));
};
var $author$project$Page$OfferDetails$Effect$FetchClaimHistory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$Offer$pickQuoteIdForClaimsHistory = function (_v0) {
	var offer = _v0;
	return offer.g7;
};
var $author$project$Page$OfferDetails$chooseEffectForClaimHistory = function (maybeOffer) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$OfferDetails$Effect$None,
		A2(
			$elm$core$Maybe$map,
			$author$project$Page$OfferDetails$Effect$FetchClaimHistory,
			A2($elm$core$Maybe$andThen, $author$project$Common$Offer$pickQuoteIdForClaimsHistory, maybeOffer)));
};
var $author$project$Page$OfferDetails$Effect$FetchCustomerId = function (a) {
	return {$: 2, a: a};
};
var $author$project$Common$Offer$pickId = function (_v0) {
	var offer = _v0;
	return offer.iO;
};
var $author$project$Page$OfferDetails$chooseEffectForCustomerId = function (maybeOffer) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$OfferDetails$Effect$None,
		A2(
			$elm$core$Maybe$map,
			$author$project$Page$OfferDetails$Effect$FetchCustomerId,
			A2($elm$core$Maybe$map, $author$project$Common$Offer$pickId, maybeOffer)));
};
var $author$project$Common$Offer$pickIsRenewal = function (_v0) {
	var offer = _v0;
	return offer.d$;
};
var $author$project$Page$OfferDetails$choseEffectForRenewal = function (offer) {
	return $author$project$Common$Offer$pickIsRenewal(offer) ? $author$project$Page$OfferDetails$Effect$FetchPaymentMethod(
		$author$project$Common$Offer$pickId(offer)) : $author$project$Page$OfferDetails$Effect$None;
};
var $author$project$Page$OfferDetails$chooseEffectForPaymentMethod = function (maybeOffer) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$OfferDetails$Effect$None,
		A2($elm$core$Maybe$map, $author$project$Page$OfferDetails$choseEffectForRenewal, maybeOffer));
};
var $author$project$Pyxis$Components$Modal$Close = 1;
var $author$project$Pyxis$Commons$Basics$forwardValue = F2(
	function (value, _function) {
		return _function(value);
	});
var $author$project$Pyxis$Commons$Basics$maybeForwardValue = function (value) {
	return $elm$core$Maybe$map(
		$author$project$Pyxis$Commons$Basics$forwardValue(value));
};
var $author$project$Pyxis$Components$Modal$updateIsOpen = F2(
	function (isOpen, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{f9: isOpen});
	});
var $primait$prima_elm_extra$PrimaUpdate$withCmd = F2(
	function (cmd, model) {
		return _Utils_Tuple2(model, cmd);
	});
var $author$project$Pyxis$Components$Modal$update = F2(
	function (msg, model) {
		var enableFocusTrap = model.fI;
		var disableFocusTrap = model.fB;
		var id = model.iO;
		var startingElementId = model.eE;
		if (!msg) {
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmd,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2(
						$author$project$Pyxis$Commons$Basics$maybeForwardValue,
						{eE: startingElementId, nN: id},
						enableFocusTrap)),
				A2($author$project$Pyxis$Components$Modal$updateIsOpen, true, model));
		} else {
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmd,
				A2(
					$elm$core$Maybe$withDefault,
					$elm$core$Platform$Cmd$none,
					A2($author$project$Pyxis$Commons$Basics$maybeForwardValue, 0, disableFocusTrap)),
				A2($author$project$Pyxis$Components$Modal$updateIsOpen, false, model));
		}
	});
var $author$project$Pyxis$Components$Modal$close = $author$project$Pyxis$Components$Modal$update(1);
var $author$project$Translations$detailsToastAmendDateErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The start date has not been correctly updated. Please try again later.';
	} else {
		return 'La fecha de inicio no se ha actualizado correctamente. Por favor, inténtelo de nuevo más tarde.';
	}
};
var $author$project$Translations$detailsToastAmendDateSuccessMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The start date has been correctly updated.';
	} else {
		return 'La fecha de inicio se ha actualizado correctamente.';
	}
};
var $author$project$Pyxis$Components$Toasts$Error = 4;
var $author$project$Pyxis$Components$Toasts$SimpleContent = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Commons$Properties$Theme$Default = 0;
var $author$project$Pyxis$Commons$Properties$Theme$default = 0;
var $author$project$Pyxis$Components$IconSet$CheckCircle = 23;
var $author$project$Pyxis$Components$IconSet$checkCircle = 23;
var $author$project$Pyxis$Components$IconSet$ExclamationCircle = 52;
var $author$project$Pyxis$Components$IconSet$exclamationCircle = 52;
var $author$project$Pyxis$Components$IconSet$ExclamationTriangle = 53;
var $author$project$Pyxis$Components$IconSet$exclamationTriangle = 53;
var $author$project$Pyxis$Components$IconSet$InfoCircle = 86;
var $author$project$Pyxis$Components$IconSet$infoCircle = 86;
var $author$project$Pyxis$Components$IconSet$ThumbUp = 139;
var $author$project$Pyxis$Components$IconSet$thumbUp = 139;
var $author$project$Pyxis$Components$Toasts$variantToDefaultIcon = function (variant) {
	switch (variant) {
		case 0:
			return $author$project$Pyxis$Components$IconSet$infoCircle;
		case 1:
			return $author$project$Pyxis$Components$IconSet$thumbUp;
		case 2:
			return $author$project$Pyxis$Components$IconSet$exclamationTriangle;
		case 3:
			return $author$project$Pyxis$Components$IconSet$checkCircle;
		default:
			return $author$project$Pyxis$Components$IconSet$exclamationCircle;
	}
};
var $author$project$Pyxis$Components$Toasts$toast = function (variant) {
	return {
		al: $elm$core$Maybe$Nothing,
		fj: _List_Nil,
		fk: 'Close',
		iN: $elm$core$Maybe$Just(
			$author$project$Pyxis$Components$Toasts$variantToDefaultIcon(variant)),
		iO: '',
		d2: false,
		gD: $elm$core$Maybe$Nothing,
		eO: $elm$core$Maybe$Nothing,
		nB: $author$project$Pyxis$Components$Toasts$SimpleContent(''),
		eP: $author$project$Pyxis$Commons$Properties$Theme$default,
		hK: variant
	};
};
var $author$project$Pyxis$Components$Toasts$errorToast = $author$project$Pyxis$Components$Toasts$toast(4);
var $elm_community$maybe_extra$Maybe$Extra$unwrap = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default;
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Page$OfferDetails$fromMaybeOfferIdToString = function (maybeUuid) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		'',
		function (uuid) {
			return uuid;
		},
		maybeUuid);
};
var $author$project$Common$Offer$pickEditCoverAction = function (action) {
	if (action.$ === 4) {
		var url = action.a;
		return $elm$core$Maybe$Just(url);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Common$Offer$mayEditCover = function (_v0) {
	var offer = _v0;
	return $elm$core$List$head(
		A2($elm$core$List$filterMap, $author$project$Common$Offer$pickEditCoverAction, offer.aB));
};
var $author$project$Common$Portfolio$setEditCoverageUrl = F2(
	function (coverageUrl, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				fE: $elm$core$Maybe$Just(coverageUrl)
			});
	});
var $author$project$Page$OfferDetails$Model$maybeAddSetEditCoverUrlToPortfolio = F2(
	function (maybeOffer, model) {
		var updatePortfolioEditCoverUrl = function (link) {
			return _Utils_update(
				model,
				{
					cW: A2($author$project$Common$Portfolio$setEditCoverageUrl, link, model.cW)
				});
		};
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				updatePortfolioEditCoverUrl,
				$elm_community$maybe_extra$Maybe$Extra$join(
					A2($elm$core$Maybe$map, $author$project$Common$Offer$mayEditCover, maybeOffer))));
	});
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $author$project$Common$Offer$isEditAction = function (action) {
	if (action.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Common$Offer$mayBeEdited = function (_v0) {
	var offer = _v0;
	return A2($elm$core$List$any, $author$project$Common$Offer$isEditAction, offer.aB);
};
var $author$project$Common$Portfolio$setEditRedirectUrl = F2(
	function (redirectUrl, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				fF: $elm$core$Maybe$Just(redirectUrl)
			});
	});
var $author$project$Page$OfferDetails$Model$maybeAddSetOnEditToPortfolio = F3(
	function (maybeFunnelUrl, maybeOffer, model) {
		var isEditable = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2($elm$core$Maybe$map, $author$project$Common$Offer$mayBeEdited, maybeOffer));
		var _v0 = _Utils_Tuple2(isEditable, maybeFunnelUrl);
		if (_v0.a && (!_v0.b.$)) {
			var funnelUrl = _v0.b.a;
			return _Utils_update(
				model,
				{
					cW: A2($author$project$Common$Portfolio$setEditRedirectUrl, funnelUrl, model.cW)
				});
		} else {
			return model;
		}
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$updateEmailAddress = F2(
	function (email, sendEmailModalModel) {
		return _Utils_update(
			sendEmailModalModel,
			{g9: email});
	});
var $author$project$Page$OfferDetails$setPolicyHolderEmailAddress = F2(
	function (email, model) {
		return _Utils_update(
			model,
			{
				y: A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$updateEmailAddress, email, model.y)
			});
	});
var $author$project$Page$OfferDetails$maybeSetPolicyHolderEmailAddress = F2(
	function (email, model) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$unwrap,
			model,
			A2($primait$prima_elm_extra$PrimaFunction$flip, $author$project$Page$OfferDetails$setPolicyHolderEmailAddress, model),
			email);
	});
var $author$project$Page$OfferDetails$Model$setPaymentMethod = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{jm: result});
	});
var $author$project$Page$OfferDetails$updatePaymentMethodIfRenewal = F2(
	function (model, offer) {
		return $author$project$Common$Offer$pickIsRenewal(offer) ? A2($author$project$Page$OfferDetails$Model$setPaymentMethod, $krisajenkins$remotedata$RemoteData$Loading, model) : model;
	});
var $author$project$Page$OfferDetails$maybeUpdatePaymentMethodIfRenewal = F2(
	function (maybeOffer, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				$author$project$Page$OfferDetails$updatePaymentMethodIfRenewal(model),
				maybeOffer));
	});
var $author$project$Pyxis$Components$Modal$Open = 0;
var $author$project$Pyxis$Components$Modal$open = $author$project$Pyxis$Components$Modal$update(0);
var $author$project$Common$Person$pickContacts = function (person) {
	switch (person.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 0:
			return $elm$core$Maybe$Nothing;
		default:
			var contacts = person.c;
			return $elm$core$Maybe$Just(contacts);
	}
};
var $krisajenkins$remotedata$RemoteData$map = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var value = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(
					f(value));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			case 0:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
			default:
				var error = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(error);
		}
	});
var $author$project$Common$Offer$pickCustomerFacingId = function (_v0) {
	var offer = _v0;
	return offer.kN;
};
var $krisajenkins$remotedata$RemoteData$withDefault = F2(
	function (_default, data) {
		if (data.$ === 3) {
			var x = data.a;
			return x;
		} else {
			return _default;
		}
	});
var $author$project$Page$OfferDetails$Model$pickCustomerFacingId = function (model) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$elm$core$Maybe$map($author$project$Common$Offer$pickCustomerFacingId),
			model.C));
};
var $author$project$Page$OfferDetails$Model$pickOfferId = function (model) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$elm$core$Maybe$map($author$project$Common$Offer$pickId),
			model.C));
};
var $author$project$Common$Offer$pickPolicyHolder = function (_v0) {
	var offer = _v0;
	return offer.g2;
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$resetValue = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{ao: _List_Nil});
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$resetCheckBoxGropup = function (sendEmailModalModel) {
	return _Utils_update(
		sendEmailModalModel,
		{
			bR: $author$project$Pyxis$Components$Field$CheckboxGroup$resetValue(sendEmailModalModel.bR)
		});
};
var $author$project$Translations$sendOfferEmailsBlockedOffer = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The offer is not available for recruitment, so it is not possible to send emails.';
	} else {
		return 'La oferta no está disponible para contratación, por lo que no es posible enviar emails.';
	}
};
var $author$project$Translations$sendOfferEmailsMissingEmailAddress = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'There is no email address associated with this offer, please complete it to be able to send emails.';
	} else {
		return 'No hay una dirección de correo electrónico asociada a esta oferta, complétala para poder enviar emails.';
	}
};
var $author$project$Translations$sendOfferEmailsOfferExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The offer has expired, so it is not possible to send emails.';
	} else {
		return 'La oferta ha caducado, por lo que no es posible enviar emails.';
	}
};
var $author$project$Translations$sendOfferEmailsOfferIsRenewal = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'This offer is a renewal, so it is not possible to send email';
	} else {
		return 'Esta oferta es una renovación, por lo que no es posible enviar emails';
	}
};
var $author$project$Translations$sendOfferEmailsOfferPurchased = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The offer has been contracted, so it is not possible to send emails.';
	} else {
		return 'La oferta ha sido contratada, por lo que no es posible enviar emails.';
	}
};
var $author$project$Translations$sendOfferEmailsOfferRequoted = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The offer has been re-quoted, so it is not possible to send emails.';
	} else {
		return 'La oferta ha sido cotizada nuevamente, por lo que no es posible enviar emails.';
	}
};
var $author$project$Translations$sendOfferEmailsStartDateExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'You need to change the start date to be able to send emails.';
	} else {
		return 'Necesitas cambiar la fecha de inicio para poder enviar emails.';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailError = F2(
	function (i18n, error) {
		switch (error) {
			case 0:
				return $author$project$Translations$sendOfferEmailsOfferExpired(i18n);
			case 1:
				return $author$project$Translations$sendOfferEmailsStartDateExpired(i18n);
			case 2:
				return $author$project$Translations$sendOfferEmailsOfferPurchased(i18n);
			case 3:
				return $author$project$Translations$sendOfferEmailsBlockedOffer(i18n);
			case 4:
				return $author$project$Translations$sendOfferEmailsOfferRequoted(i18n);
			case 5:
				return $author$project$Translations$sendOfferEmailsMissingEmailAddress(i18n);
			default:
				return $author$project$Translations$sendOfferEmailsOfferIsRenewal(i18n);
		}
	});
var $author$project$Translations$sendOfferEmailsGenericErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'It was not possible to send the email. Please refresh the page to try again.';
	} else {
		return 'No ha sido posible enviar el email. Actualiza la página para intentarlo nuevamente.';
	}
};
var $author$project$Translations$sendOfferEmailsSuccessMessage = F2(
	function (email, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'Email successfully sent to ' + email;
		} else {
			return 'Envío realizado a ' + email;
		}
	});
var $author$project$Common$PricingAction$new = function (pricingAction) {
	return $author$project$Common$PricingAction$PricingAction(pricingAction);
};
var $author$project$Common$PricingAction$Unknown = {$: 1};
var $author$project$Common$PricingAction$unknown = $author$project$Common$PricingAction$Unknown;
var $author$project$Page$OfferDetails$Model$pickPricingAction = function (response) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$author$project$Common$PricingAction$unknown,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($author$project$Common$PricingAction$new),
				$elm$core$Maybe$withDefault($author$project$Common$PricingAction$default)),
			response));
};
var $author$project$Common$ClaimHistory$default = A6($author$project$Common$ClaimHistory$ClaimHistory, '', '', '', $author$project$Common$Count$default, $elm$core$Maybe$Nothing, $author$project$Common$PricingAction$default);
var $elm_community$maybe_extra$Maybe$Extra$orElseLazy = F2(
	function (fma, mb) {
		if (mb.$ === 1) {
			return fma(0);
		} else {
			return mb;
		}
	});
var $author$project$Common$ClaimHistory$setActionNeeded = F2(
	function (actionNeeded, claimHistory) {
		return _Utils_update(
			claimHistory,
			{eZ: actionNeeded});
	});
var $author$project$Common$ClaimHistory$setPricingActionToClaimHistoryOrDefault = F2(
	function (action, maybeClaimHistory) {
		return A2(
			$elm_community$maybe_extra$Maybe$Extra$orElseLazy,
			function (_v0) {
				return $elm$core$Maybe$Just(
					A2($author$project$Common$ClaimHistory$setActionNeeded, action, $author$project$Common$ClaimHistory$default));
			},
			A2(
				$elm$core$Maybe$map,
				$author$project$Common$ClaimHistory$setActionNeeded(action),
				maybeClaimHistory));
	});
var $author$project$Page$OfferDetails$Model$setPricingActionToClaimHistoryResponse = F2(
	function (model, action) {
		return A2(
			$krisajenkins$remotedata$RemoteData$map,
			$author$project$Common$ClaimHistory$setPricingActionToClaimHistoryOrDefault(action),
			model.cf);
	});
var $author$project$Page$OfferDetails$Model$setClaimHistoryPricingActionResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{
				cf: A2(
					$author$project$Page$OfferDetails$Model$setPricingActionToClaimHistoryResponse,
					model,
					$author$project$Page$OfferDetails$Model$pickPricingAction(result))
			});
	});
var $author$project$Common$Offer$pickClaimsDeclaredWithFault = function (_v0) {
	var offer = _v0;
	return offer.fi;
};
var $krisajenkins$remotedata$RemoteData$toMaybe = A2(
	$elm$core$Basics$composeR,
	$krisajenkins$remotedata$RemoteData$map($elm$core$Maybe$Just),
	$krisajenkins$remotedata$RemoteData$withDefault($elm$core$Maybe$Nothing));
var $author$project$Page$OfferDetails$Model$pickClaimsDeclaredWithFaultByCustomer = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.C;
	},
	A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm_community$maybe_extra$Maybe$Extra$join,
			A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $author$project$Common$Count$default, $author$project$Common$Offer$pickClaimsDeclaredWithFault))));
var $author$project$Common$ClaimHistory$setClaimDeclaredWithFaultByCustomer = F2(
	function (claims, claimHistory) {
		return _Utils_update(
			claimHistory,
			{fh: claims});
	});
var $author$project$Page$OfferDetails$Model$setClaimDeclaredToClaimHistory = function (model) {
	return $author$project$Common$ClaimHistory$setClaimDeclaredWithFaultByCustomer(
		$author$project$Page$OfferDetails$Model$pickClaimsDeclaredWithFaultByCustomer(model));
};
var $author$project$Page$OfferDetails$Model$setClaimsDeclaredToResponse = function (model) {
	return $krisajenkins$remotedata$RemoteData$map(
		$elm$core$Maybe$map(
			$author$project$Page$OfferDetails$Model$setClaimDeclaredToClaimHistory(model)));
};
var $author$project$Page$OfferDetails$Model$setClaimHistoryResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{
				cf: A2($author$project$Page$OfferDetails$Model$setClaimsDeclaredToResponse, model, result)
			});
	});
var $author$project$Page$OfferDetails$Model$setCustomerId = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{fw: result});
	});
var $author$project$Page$OfferDetails$Model$setOfferResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{C: result});
	});
var $author$project$Pyxis$Components$Toasts$Success = 3;
var $author$project$Pyxis$Components$Toasts$successToast = $author$project$Pyxis$Components$Toasts$toast(3);
var $author$project$Env$funnelUrl = function (_v0) {
	var environment = _v0;
	return environment.cu;
};
var $author$project$ExternalUrl$toString = function (link) {
	var env = link.a;
	var offerId = link.b;
	return $elm$core$String$concat(
		_List_fromArray(
			[
				$author$project$Env$funnelUrl(env),
				'/offer/',
				$danyx23$elm_uuid$Uuid$toString(offerId)
			]));
};
var $author$project$Commands$dispatch = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(msg),
		$elm$core$Task$succeed(
			$elm$core$Basics$always(msg)));
};
var $author$project$Commands$dispatchFromMaybe = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Commands$dispatch),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $author$project$Pyxis$Commons$Commands$dispatch = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$always(msg),
		$elm$core$Task$succeed(
			$elm$core$Basics$always(msg)));
};
var $author$project$Pyxis$Commons$Commands$dispatchFromMaybe = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($author$project$Pyxis$Commons$Commands$dispatch),
	$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none));
var $author$project$Pyxis$Components$Tabs$dispatchOnTabActivation = function (_v0) {
	var activeIndex = _v0.P;
	var onTabActivation = _v0.ej;
	return $author$project$Pyxis$Commons$Commands$dispatchFromMaybe(
		A2(
			$elm$core$Maybe$map,
			function (theMsg) {
				return theMsg(activeIndex);
			},
			onTabActivation));
};
var $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation = function (_v0) {
	var activeIndex = _v0.P;
	var onTabFirstActivation = _v0.gW;
	return $author$project$Pyxis$Commons$Commands$dispatchFromMaybe(
		A2(
			$elm$core$Maybe$map,
			function (theMsg) {
				return theMsg(activeIndex);
			},
			onTabFirstActivation));
};
var $primait$prima_elm_extra$PrimaCmd$ifThenCmd = F2(
	function (condition, cmd) {
		return condition ? cmd : $elm$core$Platform$Cmd$none;
	});
var $primait$prima_elm_extra$PrimaCmd$ifThenCmdMap = F3(
	function (condition, cmd, a) {
		return A2(
			$primait$prima_elm_extra$PrimaCmd$ifThenCmd,
			condition(a),
			cmd(a));
	});
var $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation = function (_v0) {
	var activeIndex = _v0.P;
	var openedTabs = _v0.bI;
	return 1 === A2(
		$elm$core$Maybe$withDefault,
		1,
		A2($elm$core$Dict$get, activeIndex, openedTabs));
};
var $author$project$Pyxis$Components$Tabs$setActiveTabIndex = F2(
	function (index, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				P: index,
				bI: A3(
					$elm$core$Dict$insert,
					index,
					1 + A2(
						$elm$core$Maybe$withDefault,
						0,
						A2($elm$core$Dict$get, index, modelData.bI)),
					modelData.bI)
			});
	});
var $primait$prima_elm_extra$PrimaCmd$fromMaybeMap = F2(
	function (command, maybe) {
		if (!maybe.$) {
			var a = maybe.a;
			return command(a);
		} else {
			return $elm$core$Platform$Cmd$none;
		}
	});
var $author$project$Pyxis$Components$Tabs$getTabId = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		$elm$core$Basics$append(id + '-tab-'));
};
var $author$project$Pyxis$Components$Tabs$attemptTabFocus = F2(
	function (id, _v0) {
		var modelData = _v0;
		return A2(
			$primait$prima_elm_extra$PrimaCmd$fromMaybeMap,
			function (msg) {
				return A2(
					$elm$core$Task$attempt,
					$elm$core$Basics$always(msg),
					$elm$browser$Browser$Dom$focus(
						A2($author$project$Pyxis$Components$Tabs$getTabId, id, modelData.P)));
			},
			A2(
				$elm$core$Maybe$map,
				function (msg) {
					return msg(modelData.P);
				},
				modelData.ej));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode = F2(
	function (match, _v0) {
		var keyCode = _v0.i_;
		return _Utils_eq(keyCode, match);
	});
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(37);
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowRight = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(39);
var $author$project$Pyxis$Commons$Events$Keyboard$isEnd = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(35);
var $author$project$Pyxis$Commons$Events$Keyboard$isHome = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(36);
var $author$project$Pyxis$Components$Tabs$updateIndexToPrevious = F2(
	function (numberOfTabs, model) {
		var activeIndex = model.P;
		return ((activeIndex - 1) >= 0) ? A2($author$project$Pyxis$Components$Tabs$setActiveTabIndex, activeIndex - 1, model) : A2($author$project$Pyxis$Components$Tabs$setActiveTabIndex, numberOfTabs - 1, model);
	});
var $author$project$Pyxis$Components$Tabs$updateToNextIndex = F2(
	function (numberOfTabs, model) {
		var activeIndex = model.P;
		return (_Utils_cmp(activeIndex + 1, numberOfTabs - 1) < 1) ? A2($author$project$Pyxis$Components$Tabs$setActiveTabIndex, activeIndex + 1, model) : A2($author$project$Pyxis$Components$Tabs$setActiveTabIndex, 0, model);
	});
var $primait$prima_elm_extra$PrimaUpdate$withCmdsMap = F2(
	function (cmdFunctions, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(
				A2(
					$elm$core$List$map,
					function (f) {
						return f(model);
					},
					cmdFunctions)));
	});
var $author$project$Pyxis$Components$Tabs$updateOnKeyEvent = F3(
	function (id, numberOfTabs, event) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft(event) ? $author$project$Pyxis$Components$Tabs$updateIndexToPrevious(numberOfTabs) : ($author$project$Pyxis$Commons$Events$Keyboard$isArrowRight(event) ? $author$project$Pyxis$Components$Tabs$updateToNextIndex(numberOfTabs) : ($author$project$Pyxis$Commons$Events$Keyboard$isHome(event) ? $author$project$Pyxis$Components$Tabs$setActiveTabIndex(0) : ($author$project$Pyxis$Commons$Events$Keyboard$isEnd(event) ? $author$project$Pyxis$Components$Tabs$setActiveTabIndex(numberOfTabs - 1) : $elm$core$Basics$identity))),
			$primait$prima_elm_extra$PrimaUpdate$withCmdsMap(
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Tabs$attemptTabFocus(id),
						$author$project$Pyxis$Components$Tabs$dispatchOnTabActivation,
						A2($primait$prima_elm_extra$PrimaCmd$ifThenCmdMap, $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation, $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation)
					])));
	});
var $author$project$Pyxis$Components$Tabs$update = F2(
	function (msg, model) {
		if (!msg.$) {
			var index = msg.a;
			return A2(
				$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Tabs$dispatchOnTabActivation,
						A2($primait$prima_elm_extra$PrimaCmd$ifThenCmdMap, $author$project$Pyxis$Components$Tabs$isCurrentTabFirstActivation, $author$project$Pyxis$Components$Tabs$dispatchOnTabFirstActivation)
					]),
				A2($author$project$Pyxis$Components$Tabs$setActiveTabIndex, index, model));
		} else {
			var numberOfTabs = msg.a;
			var id = msg.b;
			var event = msg.c;
			return A4($author$project$Pyxis$Components$Tabs$updateOnKeyEvent, id, numberOfTabs, event, model);
		}
	});
var $author$project$Common$Portfolio$updateInnerTabsModel = F2(
	function (newTabModel, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{dQ: newTabModel});
	});
var $author$project$Common$Portfolio$updateOuterTabsModel = F2(
	function (newTabModel, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{bJ: newTabModel});
	});
var $author$project$Common$Portfolio$update = F2(
	function (msg, portfolio) {
		var model = portfolio;
		switch (msg.$) {
			case 0:
				var subMsg = msg.a;
				var _v1 = A2($author$project$Pyxis$Components$Tabs$update, subMsg, model.bJ);
				var newTabModel = _v1.a;
				var tabCmd = _v1.b;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmd,
					tabCmd,
					A2($author$project$Common$Portfolio$updateOuterTabsModel, newTabModel, portfolio));
			case 1:
				var subMsg = msg.a;
				var _v2 = A2($author$project$Pyxis$Components$Tabs$update, subMsg, model.dQ);
				var newTabModel = _v2.a;
				var tabCmd = _v2.b;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmd,
					tabCmd,
					A2($author$project$Common$Portfolio$updateInnerTabsModel, newTabModel, portfolio));
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmd,
					$author$project$Commands$dispatchFromMaybe(model.jd),
					portfolio);
			default:
				return $primait$prima_elm_extra$PrimaUpdate$withoutCmds(portfolio);
		}
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				ao: mapper(modelData.ao)
			});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$checkValue = function (value) {
	return $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues(
		$elm$core$List$cons(value));
};
var $primait$prima_elm_extra$PrimaFunction$ifThenElseMap = F4(
	function (condition, mapper1, mapper2, m) {
		return condition(m) ? mapper1(m) : mapper2(m);
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dK: mapper(model.dK)
			});
	});
var $author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred = F2(
	function (isBlurred, fieldStatus) {
		return _Utils_update(
			fieldStatus,
			{lu: isBlurred});
	});
var $author$project$Pyxis$Components$Field$FieldStatus$setIsDirty = F2(
	function (isDirty, fieldStatus) {
		return _Utils_update(
			fieldStatus,
			{lw: isDirty});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$uncheckValue = function (value) {
	return $author$project$Pyxis$Components$Field$CheckboxGroup$mapCheckedValues(
		$elm$core$List$filter(
			$elm$core$Basics$neq(value)));
};
var $primait$prima_elm_extra$PrimaUpdate$withCmds = F2(
	function (cmds, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(cmds));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gE)
						]),
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					model);
			default:
				var value = msg.a;
				var check = msg.b;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.ed)
						]),
					A2(
						$author$project$Pyxis$Components$Field$CheckboxGroup$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A4(
							$primait$prima_elm_extra$PrimaFunction$ifThenElseMap,
							$elm$core$Basics$always(check),
							$author$project$Pyxis$Components$Field$CheckboxGroup$checkValue(value),
							$author$project$Pyxis$Components$Field$CheckboxGroup$uncheckValue(value),
							model)));
		}
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$updateCheckBoxGroup = F2(
	function (newCheckBoxModel, sendEmailModalModel) {
		return _Utils_update(
			sendEmailModalModel,
			{bR: newCheckBoxModel});
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$updateIsSentEmailSubmitted = F2(
	function (isSubmitted, sendEmailModalModel) {
		return _Utils_update(
			sendEmailModalModel,
			{ga: isSubmitted});
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$updateModalModel = F2(
	function (newSendEmailModel, sendEmailModalModel) {
		return _Utils_update(
			sendEmailModalModel,
			{g3: newSendEmailModel});
	});
var $author$project$Pyxis$Components$Toasts$withToastText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nB: $author$project$Pyxis$Components$Toasts$SimpleContent(text)
			});
	});
var $author$project$Page$OfferDetails$update = F4(
	function (i18n, env, msg, model) {
		switch (msg.$) {
			case 0:
				var remoteData = msg.a;
				var maybeOffer = $elm_community$maybe_extra$Maybe$Extra$join(
					$krisajenkins$remotedata$RemoteData$toMaybe(remoteData));
				var policyHolderEmailAddress = A2(
					$elm$core$Maybe$map,
					function ($) {
						return $.cn;
					},
					A2(
						$elm$core$Maybe$andThen,
						A2($elm$core$Basics$composeR, $author$project$Common$Offer$pickPolicyHolder, $author$project$Common$Person$pickContacts),
						maybeOffer));
				var maybeFunneUrl = A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Common$Offer$pickId,
						A2(
							$elm$core$Basics$composeR,
							$author$project$ExternalUrl$FunnelOffer(env),
							$author$project$ExternalUrl$toString)),
					maybeOffer);
				return _Utils_Tuple2(
					A2(
						$author$project$Page$OfferDetails$maybeSetPolicyHolderEmailAddress,
						policyHolderEmailAddress,
						A2(
							$author$project$Page$OfferDetails$maybeUpdatePaymentMethodIfRenewal,
							maybeOffer,
							A2(
								$author$project$Page$OfferDetails$Model$maybeAddSetEditCoverUrlToPortfolio,
								maybeOffer,
								A3(
									$author$project$Page$OfferDetails$Model$maybeAddSetOnEditToPortfolio,
									maybeFunneUrl,
									maybeOffer,
									A2($author$project$Page$OfferDetails$Model$setOfferResult, remoteData, model))))),
					$author$project$Page$OfferDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$OfferDetails$chooseEffectForCustomerId(maybeOffer),
								$author$project$Page$OfferDetails$chooseEffectForClaimHistory(maybeOffer),
								$author$project$Page$OfferDetails$chooseEffectForPaymentMethod(maybeOffer)
							])));
			case 1:
				if (msg.a.$ === 3) {
					var remoteData = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$OfferDetails$Model$setClaimHistoryResult, remoteData, model),
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Page$OfferDetails$Effect$None,
							A2(
								$elm$core$Maybe$map,
								$author$project$Page$OfferDetails$Effect$FetchPricingActionClaimHistory,
								$author$project$Page$OfferDetails$Model$pickOfferId(model))));
				} else {
					var remoteData = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$OfferDetails$Model$setClaimHistoryResult, remoteData, model),
						$author$project$Page$OfferDetails$Effect$None);
				}
			case 2:
				var remoteData = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$OfferDetails$Model$setClaimHistoryPricingActionResult, remoteData, model),
					$author$project$Page$OfferDetails$Effect$None);
			case 3:
				var remotedata = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$OfferDetails$Model$setCustomerId, remotedata, model),
					$author$project$Page$OfferDetails$Effect$None);
			case 12:
				return _Utils_Tuple2(model, $author$project$Page$OfferDetails$Effect$None);
			case 4:
				var offer = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$OfferDetails$Effect$ChangeRoute(
						$author$project$Route$Payment(
							$author$project$Common$Offer$pickId(offer))));
			case 5:
				var searchQuery = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$OfferDetails$Effect$ChangeRoute(
						$author$project$Route$Search(searchQuery)));
			case 17:
				if (msg.a) {
					var _v1 = $author$project$Pyxis$Components$Modal$open(model.y.g3);
					var newModalState = _v1.a;
					var cmd = _v1.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								y: A2(
									$author$project$Common$ComponentUI$Modal$SendOfferEmail$updateIsSentEmailSubmitted,
									false,
									A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$updateModalModel, newModalState, model.y))
							}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				} else {
					var _v2 = $author$project$Pyxis$Components$Modal$close(model.y.g3);
					var newModalState = _v2.a;
					var cmd = _v2.b;
					var newSendOfferEmailModel = $author$project$Common$ComponentUI$Modal$SendOfferEmail$resetCheckBoxGropup(
						A2(
							$author$project$Common$ComponentUI$Modal$SendOfferEmail$updateIsSentEmailSubmitted,
							false,
							A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$updateModalModel, newModalState, model.y)));
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{y: newSendOfferEmailModel}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				}
			case 18:
				var value = msg.a;
				var _v3 = A2($author$project$Pyxis$Components$Field$CheckboxGroup$update, value, model.y.bR);
				var newModel = _v3.a;
				var cmd = _v3.b;
				var newSendOfferEmailModel = A2(
					$author$project$Common$ComponentUI$Modal$SendOfferEmail$updateIsSentEmailSubmitted,
					false,
					A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$updateCheckBoxGroup, newModel, model.y));
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{y: newSendOfferEmailModel}),
					$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
			case 19:
				var options = msg.a;
				var sendEmailEffects = A2($elm$core$List$map, $author$project$Page$OfferDetails$Effect$SendOfferEmail, options);
				var _v4 = function () {
					if (!options.b) {
						return _Utils_Tuple2(model.y.g3, $elm$core$Platform$Cmd$none);
					} else {
						return $author$project$Pyxis$Components$Modal$close(model.y.g3);
					}
				}();
				var newModalState = _v4.a;
				var cmd = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							y: $author$project$Common$ComponentUI$Modal$SendOfferEmail$resetCheckBoxGropup(
								A2(
									$author$project$Common$ComponentUI$Modal$SendOfferEmail$updateIsSentEmailSubmitted,
									true,
									A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$updateModalModel, newModalState, model.y)))
						}),
					$author$project$Page$OfferDetails$Effect$Effects(
						A2(
							$elm$core$List$cons,
							$author$project$Page$OfferDetails$Effect$WithCmd(cmd),
							sendEmailEffects)));
			case 10:
				if (msg.a) {
					var _v6 = $author$project$Pyxis$Components$Modal$open(model.eF);
					var newModalState = _v6.a;
					var cmd = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eF: newModalState}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				} else {
					var _v7 = $author$project$Pyxis$Components$Modal$close(model.eF);
					var newModalState = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eF: newModalState}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				}
			case 6:
				var offer = msg.a;
				var newCoverageEffectiveDate = msg.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{kq: $krisajenkins$remotedata$RemoteData$Loading}),
					A2(
						$author$project$Page$OfferDetails$Effect$AmendOfferCoverageEffectiveDate,
						$author$project$Common$Offer$pickId(offer),
						newCoverageEffectiveDate));
			case 7:
				if ((msg.a.$ === 3) && (!msg.a.a.$)) {
					var _v8 = msg.a.a;
					var offerId = $author$project$Page$OfferDetails$fromMaybeOfferIdToString(
						$author$project$Page$OfferDetails$Model$pickCustomerFacingId(model));
					var _v9 = A2(
						$author$project$Pyxis$Components$Toasts$addNewToast,
						A2(
							$author$project$Pyxis$Components$Toasts$withToastText,
							$author$project$Translations$detailsToastAmendDateSuccessMessage(i18n),
							$author$project$Pyxis$Components$Toasts$successToast),
						model.O);
					var newToastsModel = _v9.a;
					var toastCmd = _v9.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{O: newToastsModel}),
						$author$project$Page$OfferDetails$Effect$Effects(
							_List_fromArray(
								[
									$author$project$Page$OfferDetails$Effect$WithCmd(toastCmd),
									$author$project$Page$OfferDetails$Effect$FetchOffer(offerId)
								])));
				} else {
					var _v10 = A2(
						$author$project$Pyxis$Components$Toasts$addNewToast,
						A2(
							$author$project$Pyxis$Components$Toasts$withToastText,
							$author$project$Translations$detailsToastAmendDateErrorMessage(i18n),
							$author$project$Pyxis$Components$Toasts$errorToast),
						model.O);
					var newToastsModel = _v10.a;
					var toastCmd = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{O: newToastsModel}),
						$author$project$Page$OfferDetails$Effect$WithCmd(toastCmd));
				}
			case 8:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fu: $elm$core$Maybe$Just(date)
						}),
					$author$project$Page$OfferDetails$Effect$None);
			case 9:
				if (msg.a) {
					var _v11 = $author$project$Pyxis$Components$Modal$open(model.dA);
					var newModalModel = _v11.a;
					var cmd = _v11.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dA: newModalModel}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				} else {
					var _v12 = $author$project$Pyxis$Components$Modal$close(model.dA);
					var newModalModel = _v12.a;
					var cmd = _v12.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dA: newModalModel}),
						$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
				}
			case 11:
				var toastCounterSubMsg = msg.a;
				var _v13 = A2($author$project$Common$Portfolio$update, toastCounterSubMsg, model.cW);
				var newPortFolioModel = _v13.a;
				var cmd = _v13.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cW: newPortFolioModel}),
					$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
			case 15:
				var offerId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jm: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$OfferDetails$Effect$FetchPaymentMethod(offerId));
			case 16:
				var response = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jm: response}),
					$author$project$Page$OfferDetails$Effect$None);
			case 13:
				var toastCounterSubMsg = msg.a;
				var _v14 = A2($author$project$Pyxis$Components$Toasts$update, toastCounterSubMsg, model.O);
				var newToastsModel = _v14.a;
				var cmd = _v14.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: newToastsModel}),
					$author$project$Page$OfferDetails$Effect$WithCmd(cmd));
			default:
				switch (msg.a.$) {
					case 2:
						var _v15 = A2(
							$author$project$Pyxis$Components$Toasts$addNewToast,
							A2(
								$author$project$Pyxis$Components$Toasts$withToastText,
								$author$project$Translations$sendOfferEmailsGenericErrorMessage(i18n),
								$author$project$Pyxis$Components$Toasts$errorToast),
							model.O);
						var newToastsModel = _v15.a;
						var toastCmd = _v15.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{O: newToastsModel}),
							$author$project$Page$OfferDetails$Effect$WithCmd(toastCmd));
					case 3:
						if (!msg.a.a.$) {
							var _v16 = msg.a.a;
							var _v17 = A2(
								$author$project$Pyxis$Components$Toasts$addNewToast,
								A2(
									$author$project$Pyxis$Components$Toasts$withToastText,
									A2($author$project$Translations$sendOfferEmailsSuccessMessage, model.y.g9, i18n),
									$author$project$Pyxis$Components$Toasts$successToast),
								model.O);
							var newToastsModel = _v17.a;
							var toastCmd = _v17.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{O: newToastsModel}),
								$author$project$Page$OfferDetails$Effect$Effects(
									_List_fromArray(
										[
											$author$project$Page$OfferDetails$Effect$WithCmd(toastCmd)
										])));
						} else {
							var reason = msg.a.a.a;
							var _v18 = A2(
								$author$project$Pyxis$Components$Toasts$addNewToast,
								A2(
									$author$project$Pyxis$Components$Toasts$withToastText,
									A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailError, i18n, reason),
									$author$project$Pyxis$Components$Toasts$errorToast),
								model.O);
							var newToastsModel = _v18.a;
							var toastCmd = _v18.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{O: newToastsModel}),
								$author$project$Page$OfferDetails$Effect$WithCmd(toastCmd));
						}
					default:
						return _Utils_Tuple2(model, $author$project$Page$OfferDetails$Effect$None);
				}
		}
	});
var $author$project$Page$Payment$Effect$ChangeRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payment$Effect$GoBack = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Payment$Effect$None = {$: 8};
var $author$project$Page$Payment$Effect$PayWithCreditCard = {$: 5};
var $author$project$Page$Payment$Effect$SetupBraintreeClient = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Payment$Effect$Purchase = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Payment$Model$ClientSideError = $elm$core$Basics$identity;
var $author$project$Page$Payment$Model$setPaymentClientError = F2(
	function (error, model) {
		return _Utils_update(
			model,
			{
				g_: $elm$core$Maybe$Just(error)
			});
	});
var $author$project$Page$Payment$Model$setClientError = F2(
	function (_v0, model) {
		var error = _v0.ip;
		var clientSideError = error;
		return A2($author$project$Page$Payment$Model$setPaymentClientError, clientSideError, model);
	});
var $author$project$Page$Payment$Model$setCreditCardFields = F2(
	function (fieldsValidation, model) {
		var cardFields = model.du;
		var updateField = F2(
			function (field, _v1) {
				var id = _v1.iO;
				var isValid = _v1.by;
				var isPristine = _v1.d_;
				var isFocused = _v1.dV;
				return _Utils_update(
					field,
					{iO: id, dV: isFocused, d_: isPristine, by: isValid});
			});
		var updatedFields = A3(
			$elm$core$List$foldl,
			F2(
				function (field, current) {
					var _v0 = field.iO;
					switch (_v0) {
						case 'credit-card-number':
							return _Utils_update(
								current,
								{
									ce: A2(updateField, current.ce, field)
								});
						case 'credit-card-expiration-date':
							return _Utils_update(
								current,
								{
									cq: A2(updateField, current.cq, field)
								});
						default:
							return current;
					}
				}),
			cardFields,
			fieldsValidation);
		return _Utils_update(
			model,
			{du: updatedFields});
	});
var $author$project$Page$Payment$Model$PaymentNotReady = 2;
var $author$project$Page$Payment$Model$PaymentReady = 1;
var $author$project$Page$Payment$Model$setStatusCreditCardReady = F2(
	function (isReady, model) {
		var newStatus = isReady ? 1 : 2;
		return _Utils_update(
			model,
			{c1: newStatus});
	});
var $author$project$Page$Payment$handlePaymentEvent = F2(
	function (paymentMsg, model) {
		switch (paymentMsg.$) {
			case 0:
				var creditCardFieldValidations = paymentMsg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Payment$Model$setCreditCardFields, creditCardFieldValidations, model),
					$author$project$Page$Payment$Effect$None);
			case 1:
				var clientErrorEvent = paymentMsg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Payment$Model$setClientError, clientErrorEvent, model),
					$author$project$Page$Payment$Effect$None);
			case 2:
				var isReady = paymentMsg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Payment$Model$setStatusCreditCardReady, isReady, model),
					$author$project$Page$Payment$Effect$None);
			default:
				var nonce = paymentMsg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$Payment$Effect$Purchase(nonce));
		}
	});
var $author$project$Referer$paymentPage = 0;
var $author$project$Page$Payment$Model$setGotBraintreeTokenResult = F2(
	function (remoteData, model) {
		return _Utils_update(
			model,
			{fV: remoteData});
	});
var $author$project$Page$Payment$Model$setGotOfferProductResult = F2(
	function (remoteData, model) {
		return _Utils_update(
			model,
			{fW: remoteData});
	});
var $author$project$Page$Payment$Model$setGotPolicyResult = F2(
	function (remoteData, model) {
		return _Utils_update(
			model,
			{fX: remoteData});
	});
var $author$project$Page$Payment$Model$PayingWithCreditCard = 3;
var $author$project$Page$Payment$Model$setStatusPayingWithCreditCard = function (model) {
	return _Utils_update(
		model,
		{c1: 3});
};
var $author$project$Page$Payment$Model$PaymentFailed = 4;
var $author$project$Page$Payment$Model$PaymentSuccess = 5;
var $author$project$Page$Payment$Model$setStatusPayment = F2(
	function (remoteData, model) {
		switch (remoteData.$) {
			case 2:
				return _Utils_update(
					model,
					{c1: 4});
			case 3:
				return _Utils_update(
					model,
					{c1: 5});
			default:
				return model;
		}
	});
var $krisajenkins$remotedata$RemoteData$unwrap = F3(
	function (_default, _function, remoteData) {
		if (remoteData.$ === 3) {
			var data = remoteData.a;
			return _function(data);
		} else {
			return _default;
		}
	});
var $author$project$Page$Payment$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 3:
				var remoteData = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Payment$Model$setGotBraintreeTokenResult, remoteData, model),
					A3($krisajenkins$remotedata$RemoteData$unwrap, $author$project$Page$Payment$Effect$None, $author$project$Page$Payment$Effect$SetupBraintreeClient, remoteData));
			case 4:
				var remoteData = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$Payment$Model$setGotOfferProductResult, remoteData, model),
					$author$project$Page$Payment$Effect$None);
			case 5:
				var remoteData = msg.a;
				var updatedModel = A2(
					$author$project$Page$Payment$Model$setStatusPayment,
					remoteData,
					A2($author$project$Page$Payment$Model$setGotPolicyResult, remoteData, model));
				var newEffect = A3(
					$krisajenkins$remotedata$RemoteData$unwrap,
					$author$project$Page$Payment$Effect$None,
					function (policyId) {
						return $author$project$Page$Payment$Effect$ChangeRoute(
							A2(
								$author$project$Route$PolicyDetails,
								policyId,
								$elm$core$Maybe$Just($author$project$Referer$paymentPage)));
					},
					remoteData);
				return _Utils_Tuple2(updatedModel, newEffect);
			case 1:
				var paymentMsg = msg.a;
				return A2($author$project$Page$Payment$handlePaymentEvent, paymentMsg, model);
			case 6:
				return _Utils_Tuple2(
					$author$project$Page$Payment$Model$setStatusPayingWithCreditCard(model),
					$author$project$Page$Payment$Effect$PayWithCreditCard);
			case 0:
				return _Utils_Tuple2(model, $author$project$Page$Payment$Effect$None);
			default:
				return _Utils_Tuple2(
					model,
					$author$project$Page$Payment$Effect$GoBack(1));
		}
	});
var $author$project$Page$PolicyDetails$Effect$ChangeRoute = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$PolicyDetails$Effect$CreateRefund = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$PolicyDetails$Effect$CustomerTerminatePolicy = function (a) {
	return {$: 11, a: a};
};
var $author$project$Page$PolicyDetails$Effect$FetchCustomerId = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PolicyDetails$Effect$FetchPaymentMethod = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$PolicyDetails$Effect$FetchPricingActionClaimHistory = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$PolicyDetails$Effect$GetExpectedRefund = {$: 8};
var $author$project$Page$PolicyDetails$Effect$GetRefund = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$PolicyDetails$Effect$IssueRefund = function (a) {
	return {$: 14, a: a};
};
var $author$project$Page$PolicyDetails$Effect$None = {$: 20};
var $author$project$Page$PolicyDetails$Effect$PrimaTerminatePolicy = function (a) {
	return {$: 12, a: a};
};
var $author$project$Page$PolicyDetails$Effect$RenewalCancellation = function (a) {
	return {$: 16, a: a};
};
var $author$project$Page$PolicyDetails$Effect$SendPolicyContractEmail = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$PolicyDetails$Effect$UpdateEmail = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Page$PolicyDetails$Effect$WithCmd = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$PolicyDetails$Effect$WithdrawPolicy = function (a) {
	return {$: 10, a: a};
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$isDifferent = F2(
	function (modelA, modelB) {
		return !_Utils_eq(modelA, modelB);
	});
var $author$project$Page$PolicyDetails$applyIfInterruptionModelChanged = F3(
	function (model, interruptionModal, effect) {
		return A2($author$project$Page$PolicyDetails$InterruptionModal$Model$isDifferent, interruptionModal, model.o) ? _Utils_Tuple2(
			_Utils_update(
				model,
				{o: interruptionModal}),
			effect) : _Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None);
	});
var $author$project$Translations$changeEmailModalErrorToastMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'It was not possible to change the email address. Please refresh the page to try again.';
	} else {
		return 'No ha sido posible cambiar el dirección de email. Actualiza la página para intentarlo nuevamente.';
	}
};
var $author$project$Translations$changeEmailModalSuccessToastMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The email address was changed successfully.';
	} else {
		return 'El dirección de email ha cambiado correctamente.';
	}
};
var $author$project$Page$PolicyDetails$Effect$FetchClaimHistory = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$Policy$pickQuoteId = function (_v0) {
	var policy = _v0;
	return policy.m5;
};
var $author$project$Page$PolicyDetails$chooseClaimHistoryEffectByFetchedPolicy = function (policy) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Page$PolicyDetails$Effect$None,
		A2(
			$elm$core$Maybe$map,
			$author$project$Page$PolicyDetails$Effect$FetchClaimHistory,
			$author$project$Common$Policy$pickQuoteId(policy)));
};
var $author$project$Page$PolicyDetails$chooseEffectForInterruptedPolicy = F3(
	function (policyId, issueRefundReleaseDate, interruption) {
		return (_Utils_cmp(
			$elm$time$Time$posixToMillis(issueRefundReleaseDate),
			$elm$time$Time$posixToMillis(interruption.kt)) < 0) ? $author$project$Page$PolicyDetails$Effect$GetRefund(policyId) : $author$project$Page$PolicyDetails$Effect$None;
	});
var $author$project$Common$Policy$pickInterruption = function (_v0) {
	var policy = _v0;
	return policy.cw;
};
var $author$project$Page$PolicyDetails$chooseGetRefundEffectByFetchedPolicy = F2(
	function (issueRefundReleaseDate, policy) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Page$PolicyDetails$Effect$None,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Page$PolicyDetails$chooseEffectForInterruptedPolicy,
					$author$project$Common$Policy$pickId(policy),
					issueRefundReleaseDate),
				$author$project$Common$Policy$pickInterruption(policy)));
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$Dismiss = {$: 0};
var $author$project$Page$PolicyDetails$ChangeEmailModal$Opened = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pyxis$Components$Field$Input$getValue = function (_v0) {
	var value = _v0.eW;
	return value;
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$emailRegex = A2(
	$elm$core$Maybe$withDefault,
	$elm$regex$Regex$never,
	$elm$regex$Regex$fromString('[^@\\s]+@[^@\\s]+'));
var $author$project$Page$PolicyDetails$ChangeEmailModal$isInputValid = function (emailInput) {
	return (!$elm$core$String$isEmpty(emailInput)) && A2($elm$regex$Regex$contains, $author$project$Page$PolicyDetails$ChangeEmailModal$emailRegex, emailInput);
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$modalVisibility = function (_v0) {
	var visibility = _v0.eX;
	if (!visibility.$) {
		var visibilityStatus = visibility.a;
		return visibilityStatus;
	} else {
		var visibilityStatus = visibility.a;
		return visibilityStatus;
	}
};
var $author$project$Pyxis$Components$Field$Input$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dK: mapper(model.dK)
			});
	});
var $author$project$Pyxis$Components$Field$Input$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gE)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Input$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					model);
			default:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gN)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Input$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{eW: value})));
		}
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 0:
				var _v1 = $author$project$Pyxis$Components$Modal$close(
					$author$project$Page$PolicyDetails$ChangeEmailModal$modalVisibility(model));
				var visibility = _v1.a;
				var cmd = _v1.b;
				return _Utils_Tuple2(
					_Utils_update(
						modelData,
						{
							eX: $author$project$Page$PolicyDetails$ChangeEmailModal$Closed(visibility)
						}),
					cmd);
			case 1:
				var email = msg.a;
				var newInputModel = A2($author$project$Pyxis$Components$Field$Input$setValue, email, modelData.aH);
				var _v2 = $author$project$Pyxis$Components$Modal$open(
					$author$project$Page$PolicyDetails$ChangeEmailModal$modalVisibility(model));
				var visibility = _v2.a;
				var cmd = _v2.b;
				var newModalVisibility = A2($author$project$Page$PolicyDetails$ChangeEmailModal$Opened, visibility, email);
				return _Utils_Tuple2(
					_Utils_update(
						modelData,
						{aH: newInputModel, eX: newModalVisibility}),
					cmd);
			case 2:
				var subMsg = msg.a;
				var _v3 = A2($author$project$Pyxis$Components$Field$Input$update, subMsg, modelData.aH);
				var newInputModel = _v3.a;
				var cmd = _v3.b;
				return _Utils_Tuple2(
					_Utils_update(
						modelData,
						{aH: newInputModel}),
					cmd);
			case 3:
				var inputValue = $author$project$Pyxis$Components$Field$Input$getValue(modelData.aH);
				return $author$project$Page$PolicyDetails$ChangeEmailModal$isInputValid(inputValue) ? _Utils_Tuple2(
					_Utils_update(
						modelData,
						{cz: true}),
					$author$project$Pyxis$Commons$Commands$dispatch(
						modelData.jc(inputValue))) : _Utils_Tuple2(
					_Utils_update(
						modelData,
						{cz: true}),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(
					_Utils_update(
						modelData,
						{cz: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$closeModal = $author$project$Page$PolicyDetails$ChangeEmailModal$update($author$project$Page$PolicyDetails$ChangeEmailModal$Dismiss);
var $author$project$Page$PolicyDetails$InterruptionModal$Model$closeModal = function (_v0) {
	var model = _v0;
	var _v1 = $author$project$Pyxis$Components$Modal$close(model.eX);
	var visibilityModel = _v1.a;
	var visibilityCmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eX: visibilityModel}),
		visibilityCmd);
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$closeModal = function (_v0) {
	var model = _v0;
	var _v1 = $author$project$Pyxis$Components$Modal$close(model.eX);
	var newVisibility = _v1.a;
	var cmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eX: newVisibility}),
		cmd);
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$closeModal = function (_v0) {
	var model = _v0;
	var _v1 = $author$project$Pyxis$Components$Modal$close(model.eX);
	var visibilityModel = _v1.a;
	var visibilityCmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eX: visibilityModel}),
		visibilityCmd);
};
var $author$project$Page$PolicyDetails$SendEmailModal$closeModal = function (_v0) {
	var model = _v0;
	var _v1 = $author$project$Pyxis$Components$Modal$close(model.eX);
	var newVisibility = _v1.a;
	var cmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eX: newVisibility}),
		cmd);
};
var $author$project$Referer$isPaymentPage = function (_v0) {
	return true;
};
var $author$project$Translations$paymentAfterPurchaseText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Thank you! The policy has been successfully purchased';
	} else {
		return 'Gracias! La contratación de la póliza se ha completado.';
	}
};
var $author$project$Page$PolicyDetails$showToastIfRefererIsPaymentPage = F3(
	function (i18n, model, referer) {
		if ($author$project$Referer$isPaymentPage(referer)) {
			var _v0 = A2(
				$author$project$Pyxis$Components$Toasts$addNewToast,
				A2(
					$author$project$Pyxis$Components$Toasts$withToastText,
					$author$project$Translations$paymentAfterPurchaseText(i18n),
					$author$project$Pyxis$Components$Toasts$successToast),
				model.O);
			var newToastModel = _v0.a;
			var newCmd = _v0.b;
			return _Utils_Tuple2(
				_Utils_update(
					model,
					{O: newToastModel}),
				$author$project$Page$PolicyDetails$Effect$WithCmd(newCmd));
		} else {
			return _Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None);
		}
	});
var $author$project$Page$PolicyDetails$maybeUpdateModelIfRefererIsPaymentPage = F2(
	function (i18n, model) {
		return A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None),
			A2(
				$elm$core$Maybe$map,
				A2($author$project$Page$PolicyDetails$showToastIfRefererIsPaymentPage, i18n, model),
				model.m9));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$openModal = function (_v0) {
	var model = _v0;
	var _v1 = $author$project$Pyxis$Components$Modal$open(model.eX);
	var visibilityModel = _v1.a;
	var visibilityCmd = _v1.b;
	return _Utils_Tuple2(
		_Utils_update(
			model,
			{eX: visibilityModel}),
		visibilityCmd);
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$setActualTime = F2(
	function (now, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				gy: $elm$core$Maybe$Just(now)
			});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$CustomerTerminationInput = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$initCustomerTermination = F2(
	function (now, interruptionAt) {
		return $author$project$Page$PolicyDetails$InterruptionModal$Data$CustomerTerminationInput(
			{kR: $elm$core$Maybe$Nothing, z: 3, aL: interruptionAt, i3: $elm$core$Maybe$Nothing, nc: now});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$PrimaTerminationInput = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$initPrimaTermination = F2(
	function (now, interruptionAt) {
		return $author$project$Page$PolicyDetails$InterruptionModal$Data$PrimaTerminationInput(
			{kR: $elm$core$Maybe$Nothing, z: 3, aL: interruptionAt, i3: $elm$core$Maybe$Nothing, nc: now});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$WithdrawalInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$initWithdrawal = function (now) {
	return $author$project$Page$PolicyDetails$InterruptionModal$Data$WithdrawalInput(
		{kR: $elm$core$Maybe$Nothing, z: 3, i3: $elm$core$Maybe$Nothing, nc: now});
};
var $author$project$Common$Locale$maxPosix = F2(
	function (l, r) {
		var rMillis = $elm$time$Time$posixToMillis(r);
		var lMillis = $elm$time$Time$posixToMillis(l);
		return $elm$time$Time$millisToPosix(
			A2($elm$core$Basics$max, lMillis, rMillis));
	});
var $justinmimbs$date$Date$toRataDie = function (_v0) {
	var rd = _v0;
	return rd;
};
var $justinmimbs$time_extra$Time$Extra$dateToMillis = function (date) {
	var daysSinceEpoch = $justinmimbs$date$Date$toRataDie(date) - 719163;
	return daysSinceEpoch * 86400000;
};
var $justinmimbs$time_extra$Time$Extra$timeFromClock = F4(
	function (hour, minute, second, millisecond) {
		return (((hour * 3600000) + (minute * 60000)) + (second * 1000)) + millisecond;
	});
var $justinmimbs$time_extra$Time$Extra$timeFromPosix = F2(
	function (zone, posix) {
		return A4(
			$justinmimbs$time_extra$Time$Extra$timeFromClock,
			A2($elm$time$Time$toHour, zone, posix),
			A2($elm$time$Time$toMinute, zone, posix),
			A2($elm$time$Time$toSecond, zone, posix),
			A2($elm$time$Time$toMillis, zone, posix));
	});
var $justinmimbs$time_extra$Time$Extra$toOffset = F2(
	function (zone, posix) {
		var millis = $elm$time$Time$posixToMillis(posix);
		var localMillis = $justinmimbs$time_extra$Time$Extra$dateToMillis(
			A2($justinmimbs$date$Date$fromPosix, zone, posix)) + A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix);
		return ((localMillis - millis) / 60000) | 0;
	});
var $justinmimbs$time_extra$Time$Extra$posixFromDateTime = F3(
	function (zone, date, time) {
		var millis = $justinmimbs$time_extra$Time$Extra$dateToMillis(date) + time;
		var offset0 = A2(
			$justinmimbs$time_extra$Time$Extra$toOffset,
			zone,
			$elm$time$Time$millisToPosix(millis));
		var posix1 = $elm$time$Time$millisToPosix(millis - (offset0 * 60000));
		var offset1 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix1);
		if (_Utils_eq(offset0, offset1)) {
			return posix1;
		} else {
			var posix2 = $elm$time$Time$millisToPosix(millis - (offset1 * 60000));
			var offset2 = A2($justinmimbs$time_extra$Time$Extra$toOffset, zone, posix2);
			return _Utils_eq(offset1, offset2) ? posix2 : posix1;
		}
	});
var $justinmimbs$time_extra$Time$Extra$partsToPosix = F2(
	function (zone, _v0) {
		var year = _v0.nO;
		var month = _v0.lU;
		var day = _v0.kO;
		var hour = _v0.lm;
		var minute = _v0.lS;
		var second = _v0.nm;
		var millisecond = _v0.lQ;
		return A3(
			$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
			zone,
			A3($justinmimbs$date$Date$fromCalendarDate, year, month, day),
			A4(
				$justinmimbs$time_extra$Time$Extra$timeFromClock,
				A3($elm$core$Basics$clamp, 0, 23, hour),
				A3($elm$core$Basics$clamp, 0, 59, minute),
				A3($elm$core$Basics$clamp, 0, 59, second),
				A3($elm$core$Basics$clamp, 0, 999, millisecond)));
	});
var $author$project$Common$Locale$midnightAtCustomerDate = function (date) {
	return A2(
		$justinmimbs$time_extra$Time$Extra$partsToPosix,
		$author$project$Common$Locale$customerTimeZone,
		{
			kO: $justinmimbs$date$Date$day(date),
			lm: 0,
			lQ: 0,
			lS: 0,
			lU: $justinmimbs$date$Date$month(date),
			nm: 0,
			nO: $justinmimbs$date$Date$year(date)
		});
};
var $author$project$Common$Policy$earliestCustomerTerminationDate = function (_v0) {
	var policy = _v0;
	var startAt = $author$project$Common$Locale$midnightAtCustomerDate(policy.np);
	return A2(
		$elm$core$Maybe$withDefault,
		startAt,
		A2(
			$elm$core$Maybe$map,
			$author$project$Common$Locale$maxPosix(startAt),
			policy.ci));
};
var $author$project$Common$Policy$earliestPrimaTerminationDate = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Locale$midnightAtCustomerDate(policy.np);
};
var $justinmimbs$date$Date$Days = 3;
var $justinmimbs$date$Date$Months = 1;
var $justinmimbs$date$Date$add = F3(
	function (unit, n, _v0) {
		var rd = _v0;
		switch (unit) {
			case 0:
				return A3($justinmimbs$date$Date$add, 1, 12 * n, rd);
			case 1:
				var date = $justinmimbs$date$Date$toCalendarDate(rd);
				var wholeMonths = ((12 * (date.nO - 1)) + ($justinmimbs$date$Date$monthToNumber(date.lU) - 1)) + n;
				var m = $justinmimbs$date$Date$numberToMonth(
					A2($elm$core$Basics$modBy, 12, wholeMonths) + 1);
				var y = A2($justinmimbs$date$Date$floorDiv, wholeMonths, 12) + 1;
				return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A2(
					$elm$core$Basics$min,
					date.kO,
					A2($justinmimbs$date$Date$daysInMonth, y, m));
			case 2:
				return rd + (7 * n);
			default:
				return rd + n;
		}
	});
var $author$project$Common$Locale$nextCustomerMidnight = function (now) {
	return $author$project$Common$Locale$midnightAtCustomerDate(
		A3(
			$justinmimbs$date$Date$add,
			3,
			1,
			$author$project$Common$Locale$posixToCustomerDate(now)));
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$initialInterruptionAt = F3(
	function (time, policy, kind) {
		switch (kind) {
			case 0:
				return time;
			case 1:
				return (_Utils_cmp(
					$elm$time$Time$posixToMillis(time),
					$elm$time$Time$posixToMillis(
						$author$project$Common$Policy$earliestCustomerTerminationDate(policy))) < 0) ? $author$project$Common$Policy$earliestCustomerTerminationDate(policy) : $author$project$Common$Locale$nextCustomerMidnight(time);
			default:
				return (_Utils_cmp(
					$elm$time$Time$posixToMillis(time),
					$elm$time$Time$posixToMillis(
						$author$project$Common$Policy$earliestPrimaTerminationDate(policy))) < 0) ? $author$project$Common$Policy$earliestPrimaTerminationDate(policy) : $author$project$Common$Locale$nextCustomerMidnight(time);
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$kindDataFromInterruptionKind = F3(
	function (time, policy, kind) {
		switch (kind) {
			case 0:
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$initWithdrawal(time);
			case 1:
				return A2(
					$author$project$Page$PolicyDetails$InterruptionModal$Data$initCustomerTermination,
					time,
					A3($author$project$Page$PolicyDetails$InterruptionModal$Model$initialInterruptionAt, time, policy, kind));
			default:
				return A2(
					$author$project$Page$PolicyDetails$InterruptionModal$Data$initPrimaTermination,
					time,
					A3($author$project$Page$PolicyDetails$InterruptionModal$Model$initialInterruptionAt, time, policy, kind));
		}
	});
var $elm$core$Maybe$map3 = F4(
	function (func, ma, mb, mc) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				if (mc.$ === 1) {
					return $elm$core$Maybe$Nothing;
				} else {
					var c = mc.a;
					return $elm$core$Maybe$Just(
						A3(func, a, b, c));
				}
			}
		}
	});
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$CompetitorOffer = 0;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$CoverageChange = 12;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$DeceasedPolicyHolder = 11;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$DissatisfactionService = 13;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$DoesNotKnow = 5;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$IncorrectDataOther = 9;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$IncorrectEffectiveDate = 7;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$IncorrectPersonalData = 8;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$MovingToAnotherCountry = 15;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$NoInsuranceWanted = 4;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$Other = 18;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$PaymentMethodOrDate = 10;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$PolicyHolderChange = 17;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$ProductService = 16;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$UnderwritingRules = 14;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$VehicleDeregistration = 3;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$VehicleNotPurchased = 2;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$VehicleSold = 1;
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$WantsGreenCard = 6;
var $author$project$Translations$interruptionCompetitorOffer = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Competitor offer';
	} else {
		return 'Competencia';
	}
};
var $author$project$Translations$interruptionCoverageChange = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Coverage change';
	} else {
		return 'Cambio cobertura';
	}
};
var $author$project$Translations$interruptionDeceasedPolicyHolder = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Deceased policy holder';
	} else {
		return 'Fallecimiento';
	}
};
var $author$project$Translations$interruptionDissatisfactionService = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Unsatisfied';
	} else {
		return 'Insatisfacción';
	}
};
var $author$project$Translations$interruptionDoesNotKnow = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Doesn\'t know / doesn\'t reply';
	} else {
		return 'NS/NC';
	}
};
var $author$project$Translations$interruptionIncorrectDataOther = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Incorrect data - Other';
	} else {
		return 'Datos incorrectos - Otro';
	}
};
var $author$project$Translations$interruptionIncorrectEffectiveDate = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Incorrect start date';
	} else {
		return 'Fecha de inicio incorrecta';
	}
};
var $author$project$Translations$interruptionIncorrectPersonalData = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Incorrect personal data';
	} else {
		return 'Datos personales incorrectos';
	}
};
var $author$project$Translations$interruptionMovingToAnotherCountry = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Moving To another country';
	} else {
		return 'Mudanza al extranjero';
	}
};
var $author$project$Translations$interruptionNoInsuranceWanted = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Leaves vehicle uninsured';
	} else {
		return 'Deja sin seguro';
	}
};
var $author$project$Translations$interruptionOther = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Other';
	} else {
		return 'Otro';
	}
};
var $author$project$Translations$interruptionPaymentMethodOrDate = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Payment method / date';
	} else {
		return 'Fecha de pago';
	}
};
var $author$project$Translations$interruptionPolicyHolderChange = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy holder change';
	} else {
		return 'Cambio de titular';
	}
};
var $author$project$Translations$interruptionProductService = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Product service';
	} else {
		return 'Producto/servicio';
	}
};
var $author$project$Translations$interruptionUnderwritingRules = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Underwriting rules';
	} else {
		return 'Normas de suscripción';
	}
};
var $author$project$Translations$interruptionVehicleDeregistration = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle deregistration';
	} else {
		return 'Baja del vehículo';
	}
};
var $author$project$Translations$interruptionVehicleNotPurchased = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle not purchased';
	} else {
		return 'No compra vehículo';
	}
};
var $author$project$Translations$interruptionVehicleSold = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle sold';
	} else {
		return 'Venta';
	}
};
var $author$project$Translations$interruptionWantsGreenCard = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Needs Carta Verde';
	} else {
		return 'Necesidad de Carta Verde';
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Autocomplete$option = function (_v0) {
	var value = _v0.eW;
	var label = _v0.d3;
	return {iO: 'id-' + label, d3: label, eW: value};
};
var $author$project$Page$PolicyDetails$TerminationReasonList$initCustomerCancellationReasonOptionList = function (i18n) {
	return A2(
		$elm$core$List$map,
		$author$project$Pyxis$Components$Field$Autocomplete$option,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.d3;
			},
			_List_fromArray(
				[
					{
					d3: $author$project$Translations$interruptionCompetitorOffer(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(0)
				},
					{
					d3: $author$project$Translations$interruptionCoverageChange(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(12)
				},
					{
					d3: $author$project$Translations$interruptionDeceasedPolicyHolder(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(11)
				},
					{
					d3: $author$project$Translations$interruptionDissatisfactionService(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(13)
				},
					{
					d3: $author$project$Translations$interruptionDoesNotKnow(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(5)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectDataOther(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(9)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectEffectiveDate(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(7)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectPersonalData(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(8)
				},
					{
					d3: $author$project$Translations$interruptionMovingToAnotherCountry(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(15)
				},
					{
					d3: $author$project$Translations$interruptionNoInsuranceWanted(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(4)
				},
					{
					d3: $author$project$Translations$interruptionOther(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(18)
				},
					{
					d3: $author$project$Translations$interruptionPaymentMethodOrDate(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(10)
				},
					{
					d3: $author$project$Translations$interruptionUnderwritingRules(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(14)
				},
					{
					d3: $author$project$Translations$interruptionVehicleDeregistration(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(3)
				},
					{
					d3: $author$project$Translations$interruptionVehicleNotPurchased(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(2)
				},
					{
					d3: $author$project$Translations$interruptionVehicleSold(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(1)
				},
					{
					d3: $author$project$Translations$interruptionWantsGreenCard(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(6)
				},
					{
					d3: $author$project$Translations$interruptionProductService(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(16)
				},
					{
					d3: $author$project$Translations$interruptionPolicyHolderChange(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerTerminateReason$toString(17)
				}
				])));
};
var $elm_community$list_extra$List$Extra$find = F2(
	function (predicate, list) {
		find:
		while (true) {
			if (!list.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var first = list.a;
				var rest = list.b;
				if (predicate(first)) {
					return $elm$core$Maybe$Just(first);
				} else {
					var $temp$predicate = predicate,
						$temp$list = rest;
					predicate = $temp$predicate;
					list = $temp$list;
					continue find;
				}
			}
		}
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getOptionData = function (_v0) {
	var optionData = _v0;
	return optionData;
};
var $author$project$Pyxis$Components$Field$Autocomplete$getOptions = function (_v0) {
	var modelData = _v0;
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
			A2(
				$elm$core$Basics$composeR,
				function ($) {
					return $.d3;
				},
				modelData.bm(modelData.B))),
		A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			$krisajenkins$remotedata$RemoteData$toMaybe(modelData.ak)));
};
var $author$project$Pyxis$Components$Field$Autocomplete$setActiveOption = F2(
	function (option_, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{E: option_});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption = function (model) {
	var value = model.eW;
	if (!value.$) {
		var currentValue = value.a;
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
			model,
			A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.eW;
						},
						$elm$core$Basics$eq(currentValue))),
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	} else {
		return A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
			model,
			$elm$core$List$head(
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	}
};
var $krisajenkins$remotedata$RemoteData$isSuccess = function (data) {
	if (data.$ === 3) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $krisajenkins$remotedata$RemoteData$mapError = F2(
	function (f, data) {
		switch (data.$) {
			case 3:
				var x = data.a;
				return $krisajenkins$remotedata$RemoteData$Success(x);
			case 2:
				var e = data.a;
				return $krisajenkins$remotedata$RemoteData$Failure(
					f(e));
			case 1:
				return $krisajenkins$remotedata$RemoteData$Loading;
			default:
				return $krisajenkins$remotedata$RemoteData$NotAsked;
		}
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setOptions = F2(
	function (optionsRemoteData, _v0) {
		var modelData = _v0;
		var values = A2(
			$krisajenkins$remotedata$RemoteData$mapError,
			$elm$core$Basics$always(0),
			optionsRemoteData);
		return $author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption(
			_Utils_update(
				modelData,
				{
					bv: $krisajenkins$remotedata$RemoteData$isSuccess(optionsRemoteData),
					ak: values
				}));
	});
var $author$project$Page$PolicyDetails$TerminationReasonList$updateAutoCompleteModel = F2(
	function (autoCompleteModel, optionList) {
		return A2(
			$author$project$Pyxis$Components$Field$Autocomplete$setOptions,
			$krisajenkins$remotedata$RemoteData$Success(optionList),
			autoCompleteModel);
	});
var $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithCustomerTerminationReasonList = F2(
	function (i18n, autoCompleteModel) {
		return A2(
			$author$project$Page$PolicyDetails$TerminationReasonList$updateAutoCompleteModel,
			autoCompleteModel,
			$author$project$Page$PolicyDetails$TerminationReasonList$initCustomerCancellationReasonOptionList(i18n));
	});
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$Chargeback = 2;
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$Fraud = 1;
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$InexactDeclarationDni = 3;
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$InexactDeclarationSinco = 0;
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$PhotoVerificationNotProvided = 5;
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$RiskAggravation = 4;
var $author$project$Translations$interruptionChargeback = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Chargeback';
	} else {
		return 'Impago';
	}
};
var $author$project$Translations$interruptionFraud = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Fraud';
	} else {
		return 'Fraude';
	}
};
var $author$project$Translations$interruptionInexactDeclarationDni = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Inexact declaration - DNI';
	} else {
		return 'Declaración inexacta - DNI';
	}
};
var $author$project$Translations$interruptionInexactDeclarationSinco = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Inexact declaration - SINCO';
	} else {
		return 'Declaración inexacta - SINCO';
	}
};
var $author$project$Translations$interruptionPhotoVericiationNotProvided = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Photo verification not provided';
	} else {
		return 'Fotoverificación no enviada';
	}
};
var $author$project$Translations$interruptionRiskAggravation = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Risk aggravation';
	} else {
		return 'Agravación del riesgo';
	}
};
var $author$project$Page$PolicyDetails$TerminationReasonList$initPrimaTerminateReasonOptionList = function (i18n) {
	return A2(
		$elm$core$List$map,
		$author$project$Pyxis$Components$Field$Autocomplete$option,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.d3;
			},
			_List_fromArray(
				[
					{
					d3: $author$project$Translations$interruptionInexactDeclarationSinco(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(0)
				},
					{
					d3: $author$project$Translations$interruptionFraud(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(1)
				},
					{
					d3: $author$project$Translations$interruptionChargeback(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(2)
				},
					{
					d3: $author$project$Translations$interruptionInexactDeclarationDni(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(3)
				},
					{
					d3: $author$project$Translations$interruptionRiskAggravation(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(4)
				},
					{
					d3: $author$project$Translations$interruptionPhotoVericiationNotProvided(i18n),
					eW: $author$project$Gql$Platform$Enum$PrimaTerminateReason$toString(5)
				}
				])));
};
var $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithPrimaTerminationReasonList = F2(
	function (i18n, autoCompleteModel) {
		return A2(
			$author$project$Page$PolicyDetails$TerminationReasonList$updateAutoCompleteModel,
			autoCompleteModel,
			$author$project$Page$PolicyDetails$TerminationReasonList$initPrimaTerminateReasonOptionList(i18n));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonInputOptionsWhenKindIsSet = function (kind) {
	switch (kind) {
		case 0:
			return $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithCustomerTerminationReasonList;
		case 1:
			return $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithCustomerTerminationReasonList;
		default:
			return $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithPrimaTerminationReasonList;
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$setInterruptionKind = F3(
	function (i18n, kind, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				aj: A4(
					$elm$core$Maybe$map3,
					$author$project$Page$PolicyDetails$InterruptionModal$Model$kindDataFromInterruptionKind,
					model.gy,
					model.j,
					$elm$core$Maybe$Just(kind)),
				bN: A3($author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonInputOptionsWhenKindIsSet, kind, i18n, model.bN)
			});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$setPolicy = F2(
	function (policy, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				j: $elm$core$Maybe$Just(policy)
			});
	});
var $author$project$Page$PolicyDetails$openAndInitInterruptionModal = F5(
	function (i18n, kind, model, policy, time) {
		var _v0 = $author$project$Page$PolicyDetails$InterruptionModal$Model$openModal(
			A3(
				$author$project$Page$PolicyDetails$InterruptionModal$Model$setInterruptionKind,
				i18n,
				kind,
				A2(
					$author$project$Page$PolicyDetails$InterruptionModal$Model$setPolicy,
					policy,
					A2($author$project$Page$PolicyDetails$InterruptionModal$Model$setActualTime, time, model.o))));
		var interruptionModelModal = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{o: interruptionModelModal}),
			$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
	});
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$CompetitorOffer = 0;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$CoverageChange = 12;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$DeceasedPolicyHolder = 11;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$DissatisfactionService = 13;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$DoesNotKnow = 5;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$IncorrectDataOther = 9;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$IncorrectEffectiveDate = 7;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$IncorrectPersonalData = 8;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$MovingToAnotherCountry = 15;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$NoInsuranceWanted = 4;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$Other = 19;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$PaymentMethodOrDate = 10;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$PolicyHolderChange = 17;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$PriceIncrease = 18;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$ProductService = 16;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$UnderwritingRules = 14;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$VehicleDeregistration = 3;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$VehicleNotPurchased = 2;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$VehicleSold = 1;
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$WantsGreenCard = 6;
var $author$project$Translations$interruptionPriceIncrease = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Price increased';
	} else {
		return 'Aumento de precio';
	}
};
var $author$project$Page$PolicyDetails$TerminationReasonList$initCustomerDeclineRenewalOptionList = function (i18n) {
	return A2(
		$elm$core$List$map,
		$author$project$Pyxis$Components$Field$Autocomplete$option,
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.d3;
			},
			_List_fromArray(
				[
					{
					d3: $author$project$Translations$interruptionCompetitorOffer(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(0)
				},
					{
					d3: $author$project$Translations$interruptionCoverageChange(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(12)
				},
					{
					d3: $author$project$Translations$interruptionDeceasedPolicyHolder(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(11)
				},
					{
					d3: $author$project$Translations$interruptionDissatisfactionService(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(13)
				},
					{
					d3: $author$project$Translations$interruptionDoesNotKnow(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(5)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectDataOther(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(9)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectEffectiveDate(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(7)
				},
					{
					d3: $author$project$Translations$interruptionIncorrectPersonalData(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(8)
				},
					{
					d3: $author$project$Translations$interruptionMovingToAnotherCountry(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(15)
				},
					{
					d3: $author$project$Translations$interruptionNoInsuranceWanted(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(4)
				},
					{
					d3: $author$project$Translations$interruptionOther(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(19)
				},
					{
					d3: $author$project$Translations$interruptionPaymentMethodOrDate(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(10)
				},
					{
					d3: $author$project$Translations$interruptionUnderwritingRules(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(14)
				},
					{
					d3: $author$project$Translations$interruptionVehicleDeregistration(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(3)
				},
					{
					d3: $author$project$Translations$interruptionVehicleNotPurchased(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(2)
				},
					{
					d3: $author$project$Translations$interruptionVehicleSold(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(1)
				},
					{
					d3: $author$project$Translations$interruptionWantsGreenCard(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(6)
				},
					{
					d3: $author$project$Translations$interruptionProductService(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(16)
				},
					{
					d3: $author$project$Translations$interruptionPolicyHolderChange(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(17)
				},
					{
					d3: $author$project$Translations$interruptionPriceIncrease(i18n),
					eW: $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$toString(18)
				}
				])));
};
var $author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithCustomerDeclineRenewalList = F2(
	function (i18n, autoCompleteModel) {
		return A2(
			$author$project$Page$PolicyDetails$TerminationReasonList$updateAutoCompleteModel,
			autoCompleteModel,
			$author$project$Page$PolicyDetails$TerminationReasonList$initCustomerDeclineRenewalOptionList(i18n));
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$openModal = F2(
	function (i18n, _v0) {
		var model = _v0;
		var _v1 = $author$project$Pyxis$Components$Modal$open(model.eX);
		var visibilityModel = _v1.a;
		var visibilityCmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					bN: A2($author$project$Page$PolicyDetails$TerminationReasonList$updateModelWithCustomerDeclineRenewalList, i18n, model.bN),
					eX: visibilityModel
				}),
			visibilityCmd);
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$setPolicy = F2(
	function (policy, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				j: $elm$core$Maybe$Just(policy)
			});
	});
var $author$project$Page$PolicyDetails$openAndInitRenewalCancellationModal = F3(
	function (i18n, model, policy) {
		var _v0 = A2(
			$author$project$Page$PolicyDetails$RenewalCancellationModal$openModal,
			i18n,
			A2($author$project$Page$PolicyDetails$RenewalCancellationModal$setPolicy, policy, model.ab));
		var renewalCancellationModal = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{ab: renewalCancellationModal}),
			$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$Open = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$openModal = A2($elm$core$Basics$composeL, $author$project$Page$PolicyDetails$ChangeEmailModal$update, $author$project$Page$PolicyDetails$ChangeEmailModal$Open);
var $author$project$Page$PolicyDetails$RefundIssuingModal$openModal = F2(
	function (refund, _v0) {
		var model = _v0;
		var _v1 = $author$project$Pyxis$Components$Modal$open(model.eX);
		var newVisibility = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					er: $elm$core$Maybe$Just(refund),
					eX: newVisibility
				}),
			cmd);
	});
var $author$project$Page$PolicyDetails$SendEmailModal$openModal = F3(
	function (policyId, emailRecipient, _v0) {
		var model = _v0;
		var _v1 = $author$project$Pyxis$Components$Modal$open(model.eX);
		var newVisibility = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					aE: $author$project$Pyxis$Components$Field$CheckboxGroup$resetValue(model.aE),
					fG: emailRecipient,
					cz: false,
					m_: policyId,
					eX: newVisibility
				}),
			cmd);
	});
var $author$project$Page$PolicyDetails$openSendEmailModal = F2(
	function (newModalModel, model) {
		return _Utils_update(
			model,
			{bS: newModalModel});
	});
var $author$project$Common$Person$overridePolicyHolderEmail = F2(
	function (latestPolicyHolderEmail, person) {
		if (person.$ === 2) {
			var data = person.a;
			var address = person.b;
			var contacts = person.c;
			var documentKind = person.d;
			return A4(
				$author$project$Common$Person$PolicyHolder,
				data,
				address,
				_Utils_update(
					contacts,
					{cn: latestPolicyHolderEmail}),
				documentKind);
		} else {
			var otherPerson = person;
			return otherPerson;
		}
	});
var $author$project$Common$Policy$overridePolicyHolderEmail = function (_v0) {
	var policyData = _v0;
	return _Utils_update(
		policyData,
		{
			g2: A2($author$project$Common$Person$overridePolicyHolderEmail, policyData.gc, policyData.g2)
		});
};
var $author$project$Common$Policy$pickCustomerFacingId = function (_v0) {
	var policy = _v0;
	return policy.kN;
};
var $author$project$Common$Person$pickEmail = function (person) {
	switch (person.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 0:
			return $elm$core$Maybe$Nothing;
		default:
			var email = person.c.cn;
			return $elm$core$Maybe$Just(email);
	}
};
var $author$project$Common$Policy$pickPolicyHolder = function (_v0) {
	var policy = _v0;
	return policy.g2;
};
var $author$project$Page$PolicyDetails$Model$pickEmailRecipient = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.j;
	},
	A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm_community$maybe_extra$Maybe$Extra$join,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($author$project$Common$Policy$pickPolicyHolder),
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Maybe$map($author$project$Common$Person$pickEmail),
					$elm_community$maybe_extra$Maybe$Extra$join)))));
var $author$project$Fixtures$epoch = $elm$time$Time$millisToPosix(0);
var $author$project$Page$PolicyDetails$Model$pickIssueRefundReleaseDate = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.lx;
	},
	$elm$core$Maybe$withDefault($author$project$Fixtures$epoch));
var $author$project$Page$PolicyDetails$Model$pickPolicyId = function (model) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$elm$core$Maybe$Nothing,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			$elm$core$Maybe$map($author$project$Common$Policy$pickId),
			model.j));
};
var $author$project$Translations$policySendEmailFailureMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'It was not possible to send the email. Please refresh the page to try again.';
	} else {
		return 'No ha sido posible enviar el email. Actualiza la página para intentarlo nuevamente.';
	}
};
var $author$project$Translations$policySendEmailSuccessMessage = F2(
	function (email, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'The email has been sent to ' + email;
		} else {
			return 'El email se ha enviado a ' + email;
		}
	});
var $author$project$Page$PolicyDetails$resetSendEmailResult = function (model) {
	return _Utils_update(
		model,
		{eD: $krisajenkins$remotedata$RemoteData$NotAsked});
};
var $author$project$Page$PolicyDetails$Model$pickPricingAction = function (response) {
	return A2(
		$krisajenkins$remotedata$RemoteData$withDefault,
		$author$project$Common$PricingAction$unknown,
		A2(
			$krisajenkins$remotedata$RemoteData$map,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$Maybe$map($author$project$Common$PricingAction$new),
				$elm$core$Maybe$withDefault($author$project$Common$PricingAction$default)),
			response));
};
var $author$project$Page$PolicyDetails$Model$setPricingActionToClaimHistoryResponse = F2(
	function (model, action) {
		return A2(
			$krisajenkins$remotedata$RemoteData$map,
			$author$project$Common$ClaimHistory$setPricingActionToClaimHistoryOrDefault(action),
			model.cf);
	});
var $author$project$Page$PolicyDetails$Model$setClaimHistoryPricingActionResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{
				cf: A2(
					$author$project$Page$PolicyDetails$Model$setPricingActionToClaimHistoryResponse,
					model,
					$author$project$Page$PolicyDetails$Model$pickPricingAction(result))
			});
	});
var $author$project$Common$Policy$pickClaimsDeclaredWithFault = function (_v0) {
	var policy = _v0;
	return policy.fi;
};
var $author$project$Page$PolicyDetails$Model$pickClaimsDeclaredWithFaultByCustomer = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.j;
	},
	A2(
		$elm$core$Basics$composeR,
		$krisajenkins$remotedata$RemoteData$toMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm_community$maybe_extra$Maybe$Extra$join,
			A2($elm_community$maybe_extra$Maybe$Extra$unwrap, $author$project$Common$Count$default, $author$project$Common$Policy$pickClaimsDeclaredWithFault))));
var $author$project$Page$PolicyDetails$Model$setClaimDeclaredToClaimHistory = function (model) {
	return $author$project$Common$ClaimHistory$setClaimDeclaredWithFaultByCustomer(
		$author$project$Page$PolicyDetails$Model$pickClaimsDeclaredWithFaultByCustomer(model));
};
var $author$project$Page$PolicyDetails$Model$setClaimsDeclaredToResponse = function (model) {
	return $krisajenkins$remotedata$RemoteData$map(
		$elm$core$Maybe$map(
			$author$project$Page$PolicyDetails$Model$setClaimDeclaredToClaimHistory(model)));
};
var $author$project$Page$PolicyDetails$Model$setClaimHistoryResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{
				cf: A2($author$project$Page$PolicyDetails$Model$setClaimsDeclaredToResponse, model, result)
			});
	});
var $author$project$Page$PolicyDetails$Model$setCustomerId = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{fw: result});
	});
var $author$project$Page$PolicyDetails$Model$setPaymentMethod = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{jm: result});
	});
var $author$project$Page$PolicyDetails$Model$setPolicyResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{j: result});
	});
var $author$project$Page$PolicyDetails$Model$setRefundResult = F2(
	function (result, model) {
		return _Utils_update(
			model,
			{er: result});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$getValue = function (_v0) {
	var modelData = _v0;
	return modelData.ao;
};
var $author$project$Page$PolicyDetails$SendEmailModal$updateCheckboxGroup = F2(
	function (checkboxMsg, _v0) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$CheckboxGroup$update, checkboxMsg, model.aE);
		var newCheckboxModel = _v1.a;
		var cmd = _v1.b;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{aE: newCheckboxModel}),
			cmd);
	});
var $author$project$Page$PolicyDetails$SendEmailModal$update = F2(
	function (modalMsg, model) {
		var innerModel = model;
		switch (modalMsg.$) {
			case 0:
				var checkboxMsg = modalMsg.a;
				return A2($author$project$Page$PolicyDetails$SendEmailModal$updateCheckboxGroup, checkboxMsg, model);
			case 1:
				return $author$project$Page$PolicyDetails$SendEmailModal$closeModal(model);
			case 2:
				return $elm$core$List$isEmpty(
					$author$project$Pyxis$Components$Field$CheckboxGroup$getValue(innerModel.aE)) ? _Utils_Tuple2(
					_Utils_update(
						innerModel,
						{cz: true}),
					$elm$core$Platform$Cmd$none) : _Utils_Tuple2(
					model,
					$author$project$Pyxis$Commons$Commands$dispatch(innerModel.ia));
			default:
				return _Utils_Tuple2(
					_Utils_update(
						innerModel,
						{cz: false}),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$updateExpectedRefundAmount = F2(
	function (_v0, amount) {
		var model = _v0;
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{k0: amount}),
			$elm$core$Platform$Cmd$none);
	});
var $author$project$Pyxis$Components$Field$DateInput$getValue = function (_v0) {
	var value = _v0.eW;
	return value;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$setInterruptionDate = F2(
	function (dateInput, data) {
		var maybeDate = $elm$core$Result$toMaybe(
			$justinmimbs$date$Date$fromIsoString(dateInput));
		if (!maybeDate.$) {
			var selectedDate = maybeDate.a;
			var interruptionAt = $author$project$Common$Locale$midnightAtCustomerDate(selectedDate);
			switch (data.$) {
				case 1:
					var customerTerminateData = data.a;
					return $author$project$Page$PolicyDetails$InterruptionModal$Data$CustomerTerminationInput(
						_Utils_update(
							customerTerminateData,
							{aL: interruptionAt}));
				case 2:
					var primaTerminateData = data.a;
					return $author$project$Page$PolicyDetails$InterruptionModal$Data$PrimaTerminationInput(
						_Utils_update(
							primaTerminateData,
							{aL: interruptionAt}));
				default:
					return data;
			}
		} else {
			return data;
		}
	});
var $author$project$Pyxis$Components$Field$DateInput$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dK: mapper(model.dK)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gE)
						]),
					A2(
						$author$project$Pyxis$Components$Field$DateInput$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					model);
			default:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gN)
						]),
					A2(
						$author$project$Pyxis$Components$Field$DateInput$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{eW: value})));
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$updateInterruptionDateInput = F2(
	function (_v0, inputMsg) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$DateInput$update, inputMsg, model.dR);
		var newInterruptionDateModel = _v1.a;
		var selectCmd = _v1.b;
		var date = $author$project$Pyxis$Components$Field$DateInput$getValue(newInterruptionDateModel);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dR: newInterruptionDateModel,
					aj: A2(
						$elm$core$Maybe$map,
						$author$project$Page$PolicyDetails$InterruptionModal$Data$setInterruptionDate(date),
						model.aj)
				}),
			selectCmd);
	});
var $author$project$Pyxis$Components$Field$Textarea$getValue = function (_v0) {
	var value = _v0.eW;
	return value;
};
var $author$project$Pyxis$Components$Field$Textarea$mapFieldStatus = F2(
	function (mapper, _v0) {
		var model = _v0;
		return _Utils_update(
			model,
			{
				dK: mapper(model.dK)
			});
	});
var $author$project$Pyxis$Components$Field$Textarea$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 2:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gE)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Textarea$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						model));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					model);
			default:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gN)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Textarea$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						_Utils_update(
							modelData,
							{eW: value})));
		}
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$updateReasonAdditionalNotes = F2(
	function (_v0, textareaMsg) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$Textarea$update, textareaMsg, model.dF);
		var newDescriptionModel = _v1.a;
		var textareaCmd = _v1.b;
		var description = $author$project$Pyxis$Components$Field$Textarea$getValue(newDescriptionModel);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					kn: $elm$core$Maybe$Just(description),
					dF: newDescriptionModel
				}),
			textareaCmd);
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$setDescription = F2(
	function (description, data) {
		switch (data.$) {
			case 0:
				var withdrawalData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$WithdrawalInput(
					_Utils_update(
						withdrawalData,
						{kR: description}));
			case 1:
				var customerTerminationData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$CustomerTerminationInput(
					_Utils_update(
						customerTerminationData,
						{kR: description}));
			default:
				var primaTerminationData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$PrimaTerminationInput(
					_Utils_update(
						primaTerminationData,
						{kR: description}));
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonDescription = F2(
	function (_v0, textareaMsg) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$Textarea$update, textareaMsg, model.dF);
		var newDescriptionModel = _v1.a;
		var textareaCmd = _v1.b;
		var description = $author$project$Pyxis$Components$Field$Textarea$getValue(newDescriptionModel);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					dF: newDescriptionModel,
					aj: A2(
						$elm$core$Maybe$map,
						$author$project$Page$PolicyDetails$InterruptionModal$Data$setDescription(
							$elm$core$Maybe$Just(description)),
						model.aj)
				}),
			textareaCmd);
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getValue = function (_v0) {
	var value = _v0.eW;
	return value;
};
var $author$project$Common$ReasonDescriptionStatus$Mandatory = 0;
var $author$project$Common$ReasonDescriptionStatus$NotRequired = 2;
var $author$project$Common$ReasonDescriptionStatus$Optional = 1;
var $author$project$Common$ReasonDescriptionStatus$fromCustomerTerminationReason = function (reason) {
	switch (reason) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 2;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 2;
		case 6:
			return 2;
		case 8:
			return 1;
		case 9:
			return 1;
		case 7:
			return 2;
		case 10:
			return 2;
		case 11:
			return 2;
		case 12:
			return 1;
		case 13:
			return 1;
		case 14:
			return 2;
		case 15:
			return 2;
		case 18:
			return 0;
		case 16:
			return 1;
		default:
			return 1;
	}
};
var $author$project$Common$ReasonDescriptionStatus$fromCustomerWithdrawReason = function (reason) {
	switch (reason) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 2;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 2;
		case 6:
			return 2;
		case 7:
			return 2;
		case 10:
			return 2;
		case 11:
			return 2;
		case 12:
			return 1;
		case 13:
			return 1;
		case 14:
			return 2;
		case 15:
			return 2;
		case 16:
			return 0;
		case 9:
			return 1;
		default:
			return 1;
	}
};
var $author$project$Common$ReasonDescriptionStatus$fromPrimaTerminationReason = function (reason) {
	switch (reason) {
		case 2:
			return 2;
		case 0:
			return 2;
		case 1:
			return 1;
		case 3:
			return 2;
		case 4:
			return 1;
		default:
			return 1;
	}
};
var $author$project$Gql$Platform$Enum$CustomerTerminateReason$fromString = function (enumString____) {
	switch (enumString____) {
		case 'COMPETITOR_OFFER':
			return $elm$core$Maybe$Just(0);
		case 'VEHICLE_SOLD':
			return $elm$core$Maybe$Just(1);
		case 'VEHICLE_NOT_PURCHASED':
			return $elm$core$Maybe$Just(2);
		case 'VEHICLE_DEREGISTRATION':
			return $elm$core$Maybe$Just(3);
		case 'NO_INSURANCE_WANTED':
			return $elm$core$Maybe$Just(4);
		case 'DOES_NOT_KNOW':
			return $elm$core$Maybe$Just(5);
		case 'WANTS_GREEN_CARD':
			return $elm$core$Maybe$Just(6);
		case 'INCORRECT_EFFECTIVE_DATE':
			return $elm$core$Maybe$Just(7);
		case 'INCORRECT_PERSONAL_DATA':
			return $elm$core$Maybe$Just(8);
		case 'INCORRECT_DATA_OTHER':
			return $elm$core$Maybe$Just(9);
		case 'PAYMENT_METHOD_OR_DATE':
			return $elm$core$Maybe$Just(10);
		case 'DECEASED_POLICY_HOLDER':
			return $elm$core$Maybe$Just(11);
		case 'COVERAGE_CHANGE':
			return $elm$core$Maybe$Just(12);
		case 'DISSATISFACTION_SERVICE':
			return $elm$core$Maybe$Just(13);
		case 'UNDERWRITING_RULES':
			return $elm$core$Maybe$Just(14);
		case 'MOVING_TO_ANOTHER_COUNTRY':
			return $elm$core$Maybe$Just(15);
		case 'PRODUCT_SERVICE':
			return $elm$core$Maybe$Just(16);
		case 'POLICY_HOLDER_CHANGE':
			return $elm$core$Maybe$Just(17);
		case 'OTHER':
			return $elm$core$Maybe$Just(18);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$CompetitorOffer = 0;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$CoverageChange = 12;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$DeceasedPolicyHolder = 11;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$DissatisfactionService = 13;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$DoesNotKnow = 5;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$IncorrectDataOther = 8;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$IncorrectEffectiveDate = 7;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$IncorrectPersonalData = 9;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$MovingToAnotherCountry = 15;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$NoInsuranceWanted = 4;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$Other = 16;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$PaymentMethodOrDate = 10;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$UnderwritingRules = 14;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$VehicleDeregistration = 3;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$VehicleNotPurchased = 2;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$VehicleSold = 1;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$WantsGreenCard = 6;
var $author$project$Gql$Platform$Enum$CustomerWithdrawReason$fromString = function (enumString____) {
	switch (enumString____) {
		case 'COMPETITOR_OFFER':
			return $elm$core$Maybe$Just(0);
		case 'VEHICLE_SOLD':
			return $elm$core$Maybe$Just(1);
		case 'VEHICLE_NOT_PURCHASED':
			return $elm$core$Maybe$Just(2);
		case 'VEHICLE_DEREGISTRATION':
			return $elm$core$Maybe$Just(3);
		case 'NO_INSURANCE_WANTED':
			return $elm$core$Maybe$Just(4);
		case 'DOES_NOT_KNOW':
			return $elm$core$Maybe$Just(5);
		case 'WANTS_GREEN_CARD':
			return $elm$core$Maybe$Just(6);
		case 'INCORRECT_EFFECTIVE_DATE':
			return $elm$core$Maybe$Just(7);
		case 'INCORRECT_DATA_OTHER':
			return $elm$core$Maybe$Just(8);
		case 'INCORRECT_PERSONAL_DATA':
			return $elm$core$Maybe$Just(9);
		case 'PAYMENT_METHOD_OR_DATE':
			return $elm$core$Maybe$Just(10);
		case 'DECEASED_POLICY_HOLDER':
			return $elm$core$Maybe$Just(11);
		case 'COVERAGE_CHANGE':
			return $elm$core$Maybe$Just(12);
		case 'DISSATISFACTION_SERVICE':
			return $elm$core$Maybe$Just(13);
		case 'UNDERWRITING_RULES':
			return $elm$core$Maybe$Just(14);
		case 'MOVING_TO_ANOTHER_COUNTRY':
			return $elm$core$Maybe$Just(15);
		case 'OTHER':
			return $elm$core$Maybe$Just(16);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Gql$Platform$Enum$PrimaTerminateReason$fromString = function (enumString____) {
	switch (enumString____) {
		case 'INEXACT_DECLARATION_SINCO':
			return $elm$core$Maybe$Just(0);
		case 'FRAUD':
			return $elm$core$Maybe$Just(1);
		case 'CHARGEBACK':
			return $elm$core$Maybe$Just(2);
		case 'INEXACT_DECLARATION_DNI':
			return $elm$core$Maybe$Just(3);
		case 'RISK_AGGRAVATION':
			return $elm$core$Maybe$Just(4);
		case 'PHOTO_VERIFICATION_NOT_PROVIDED':
			return $elm$core$Maybe$Just(5);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$setReason = F2(
	function (maybeReason, data) {
		switch (data.$) {
			case 0:
				var withdrawalData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$WithdrawalInput(
					_Utils_update(
						withdrawalData,
						{
							z: A2(
								$elm$core$Maybe$withDefault,
								3,
								A2(
									$elm$core$Maybe$map,
									$author$project$Common$ReasonDescriptionStatus$fromCustomerWithdrawReason,
									A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerWithdrawReason$fromString, maybeReason))),
							i3: A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerWithdrawReason$fromString, maybeReason)
						}));
			case 1:
				var customerTerminationData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$CustomerTerminationInput(
					_Utils_update(
						customerTerminationData,
						{
							z: A2(
								$elm$core$Maybe$withDefault,
								3,
								A2(
									$elm$core$Maybe$map,
									$author$project$Common$ReasonDescriptionStatus$fromCustomerTerminationReason,
									A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerTerminateReason$fromString, maybeReason))),
							i3: A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerTerminateReason$fromString, maybeReason)
						}));
			default:
				var primaTerminationData = data.a;
				return $author$project$Page$PolicyDetails$InterruptionModal$Data$PrimaTerminationInput(
					_Utils_update(
						primaTerminationData,
						{
							z: A2(
								$elm$core$Maybe$withDefault,
								3,
								A2(
									$elm$core$Maybe$map,
									$author$project$Common$ReasonDescriptionStatus$fromPrimaTerminationReason,
									A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$PrimaTerminateReason$fromString, maybeReason))),
							i3: A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$PrimaTerminateReason$fromString, maybeReason)
						}));
		}
	});
var $author$project$Pyxis$Commons$Events$Keyboard$isEnter = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(13);
var $author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus = F2(
	function (mapper, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				dK: mapper(modelData.dK)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange = F2(
	function (_v0, _v1) {
		var oldModelData = _v0;
		var newModelData = _v1;
		return (!_Utils_eq(oldModelData.E, newModelData.E)) ? A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Platform$Cmd$none,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (_v2, onHoveredOptionChange) {
						var id = _v2.iO;
						return $author$project$Pyxis$Commons$Commands$dispatch(
							onHoveredOptionChange(id));
					}),
				newModelData.E,
				newModelData.gM)) : $elm$core$Platform$Cmd$none;
	});
var $author$project$Pyxis$Components$Field$Autocomplete$getSelectedOption = function (_v0) {
	var modelData = _v0;
	return modelData.hh;
};
var $author$project$Pyxis$Components$Field$Autocomplete$setFilteringValueToSelectedValueLabel = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{
			B: A2(
				$elm$core$Maybe$withDefault,
				'',
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
						function ($) {
							return $.d3;
						}),
					$author$project$Pyxis$Components$Field$Autocomplete$getSelectedOption(modelData)))
		});
};
var $author$project$Pyxis$Components$Field$Autocomplete$setHasFocus = F2(
	function (hasFocus, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{dO: hasFocus});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen = F2(
	function (isOpen, _v0) {
		var modelData = _v0;
		return A4(
			$author$project$Pyxis$Commons$Basics$ternaryOperator,
			isOpen,
			$author$project$Pyxis$Components$Field$Autocomplete$initializeActiveOption,
			$elm$core$Basics$identity,
			_Utils_update(
				modelData,
				{bv: isOpen}));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setSelectedOption = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				hh: A2(
					$elm_community$list_extra$List$Extra$find,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.eW;
							},
							$elm$core$Basics$eq(value))),
					$author$project$Pyxis$Components$Field$Autocomplete$getOptions(modelData))
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{E: $elm$core$Maybe$Nothing, dO: false, bv: false, bw: false});
};
var $author$project$Pyxis$Components$Field$Autocomplete$getLabelByValue = F2(
	function (model, value) {
		return A2(
			$elm$core$Maybe$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
				function ($) {
					return $.d3;
				}),
			A2(
				$elm_community$list_extra$List$Extra$find,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.eW;
						},
						$elm$core$Basics$eq(value))),
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue = function (model) {
	var modelData = model;
	var filteringValue = A2(
		$elm$core$Maybe$withDefault,
		modelData.B,
		A2(
			$elm$core$Maybe$andThen,
			$author$project$Pyxis$Components$Field$Autocomplete$getLabelByValue(model),
			modelData.eW));
	return _Utils_update(
		modelData,
		{B: filteringValue});
};
var $author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue = F2(
	function (value, _v0) {
		var modelData = _v0;
		return _Utils_update(
			modelData,
			{
				eW: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$setValue = F2(
	function (value, model) {
		return $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed(
			$author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue(
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$setSelectedOption,
					value,
					A2($author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue, value, model))));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$handleOnSelect = function (value) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Autocomplete$updateSelectedValue(value),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Field$Autocomplete$updateFilteringValue,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed,
				$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus(
					$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true)))));
};
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(40);
var $author$project$Pyxis$Commons$Events$Keyboard$isArrowUp = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(38);
var $author$project$Pyxis$Commons$Events$Keyboard$isEsc = $author$project$Pyxis$Commons$Events$Keyboard$matchKeyCode(27);
var $elm$core$Array$toIndexedList = function (array) {
	var len = array.a;
	var helper = F2(
		function (entry, _v0) {
			var index = _v0.a;
			var list = _v0.b;
			return _Utils_Tuple2(
				index - 1,
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(index, entry),
					list));
		});
	return A3(
		$elm$core$Array$foldr,
		helper,
		_Utils_Tuple2(len - 1, _List_Nil),
		array).b;
};
var $author$project$Pyxis$Components$Field$Autocomplete$getActiveOptionWithIndex = function (model) {
	var modelData = model;
	var findOption = function (_v0) {
		var value = _v0.b.eW;
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.eW;
						},
						$elm$core$Basics$eq(value))),
				modelData.E));
	};
	return A2(
		$elm_community$list_extra$List$Extra$find,
		findOption,
		$elm$core$Array$toIndexedList(
			$elm$core$Array$fromList(
				$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model))));
};
var $author$project$Pyxis$Components$Field$Autocomplete$getValuesLength = function (_v0) {
	var modelData = _v0;
	return $elm$core$List$length(
		A2(
			$elm$core$List$filter,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.d3;
					},
					modelData.bm(modelData.B))),
			A2($krisajenkins$remotedata$RemoteData$withDefault, _List_Nil, modelData.ak)));
};
var $author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption = F2(
	function (moveByPositions, model) {
		var modelData = model;
		var newActiveOptionIndex = moveByPositions + A2(
			$elm$core$Maybe$withDefault,
			-1,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Tuple$first,
				$author$project$Pyxis$Components$Field$Autocomplete$getActiveOptionWithIndex(model)));
		return _Utils_update(
			modelData,
			{
				E: ((newActiveOptionIndex < 0) || (_Utils_cmp(
					newActiveOptionIndex,
					$author$project$Pyxis$Components$Field$Autocomplete$getValuesLength(model)) > -1)) ? modelData.E : A2(
					$elm$core$Array$get,
					newActiveOptionIndex,
					$elm$core$Array$fromList(
						$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model)))
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$updateOnKeyEvent = F2(
	function (event, model) {
		var modelData = model;
		return $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown(event) ? A2($author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption, 1, model) : ($author$project$Pyxis$Commons$Events$Keyboard$isArrowUp(event) ? A2($author$project$Pyxis$Components$Field$Autocomplete$updateActiveOption, -1, model) : ($author$project$Pyxis$Commons$Events$Keyboard$isEsc(event) ? $author$project$Pyxis$Components$Field$Autocomplete$setDropdownClosed(model) : ($author$project$Pyxis$Commons$Events$Keyboard$isEnter(event) ? A2(
			$elm$core$Maybe$withDefault,
			model,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.eW;
						},
						A2($primait$prima_elm_extra$PrimaFunction$flip, $author$project$Pyxis$Components$Field$Autocomplete$handleOnSelect, model))),
				modelData.E)) : model)));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$update = F2(
	function (msg, model) {
		var modelData = model;
		switch (msg.$) {
			case 0:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gE)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(true),
						$author$project$Pyxis$Components$Field$Autocomplete$setFilteringValueToSelectedValueLabel(
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
								false,
								A2($author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen, false, model)))));
			case 1:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gL)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
						true,
						A2($author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen, true, model)));
			case 3:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$elm$core$Basics$always(
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gN)),
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$setHasFocus,
							true,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$setIsDropdownOpen,
								$krisajenkins$remotedata$RemoteData$isSuccess(modelData.ak),
								_Utils_update(
									modelData,
									{B: value, bw: true})))));
			case 4:
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.gV)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsBlurred(false),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
							$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(false),
							_Utils_update(
								modelData,
								{E: $elm$core$Maybe$Nothing, B: '', bw: false, eW: $elm$core$Maybe$Nothing}))));
			case 5:
				var value = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmds,
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.eh)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$mapFieldStatus,
						$author$project$Pyxis$Components$Field$FieldStatus$setIsDirty(true),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$setSelectedOption,
							value,
							A2($author$project$Pyxis$Components$Field$Autocomplete$setValue, value, model))));
			case 6:
				var event = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$elm$core$Basics$always(
							A3(
								$author$project$Pyxis$Commons$Basics$ternaryOperator,
								$author$project$Pyxis$Commons$Events$Keyboard$isEnter(event) && (!_Utils_eq(modelData.E, $elm$core$Maybe$Nothing)),
								$author$project$Pyxis$Commons$Commands$dispatchFromMaybe(modelData.eh),
								$elm$core$Platform$Cmd$none)),
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2($author$project$Pyxis$Components$Field$Autocomplete$updateOnKeyEvent, event, model));
			default:
				var option_ = msg.a;
				return A2(
					$primait$prima_elm_extra$PrimaUpdate$withCmdsMap,
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Field$Autocomplete$maybeDispatchOnHoveredOptionChange(model)
						]),
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$setActiveOption,
						$elm$core$Maybe$Just(option_),
						model));
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonKindInput = F2(
	function (_v0, autoCompleteMessage) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$Autocomplete$update, autoCompleteMessage, model.bN);
		var autoCompleteReasonModel = _v1.a;
		var selectCmd = _v1.b;
		var reason = $author$project$Pyxis$Components$Field$Autocomplete$getValue(autoCompleteReasonModel);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					aj: A2(
						$elm$core$Maybe$map,
						$author$project$Page$PolicyDetails$InterruptionModal$Data$setReason(reason),
						model.aj),
					bN: autoCompleteReasonModel
				}),
			selectCmd);
	});
var $author$project$Common$ReasonDescriptionStatus$fromCustomerDeclineRenewalReason = function (reason) {
	switch (reason) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 2;
		case 3:
			return 1;
		case 4:
			return 2;
		case 5:
			return 2;
		case 6:
			return 2;
		case 8:
			return 1;
		case 9:
			return 1;
		case 7:
			return 2;
		case 10:
			return 2;
		case 11:
			return 2;
		case 12:
			return 1;
		case 13:
			return 1;
		case 14:
			return 2;
		case 15:
			return 2;
		case 19:
			return 0;
		case 16:
			return 1;
		case 17:
			return 1;
		default:
			return 1;
	}
};
var $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$fromString = function (enumString____) {
	switch (enumString____) {
		case 'COMPETITOR_OFFER':
			return $elm$core$Maybe$Just(0);
		case 'VEHICLE_SOLD':
			return $elm$core$Maybe$Just(1);
		case 'VEHICLE_NOT_PURCHASED':
			return $elm$core$Maybe$Just(2);
		case 'VEHICLE_DEREGISTRATION':
			return $elm$core$Maybe$Just(3);
		case 'NO_INSURANCE_WANTED':
			return $elm$core$Maybe$Just(4);
		case 'DOES_NOT_KNOW':
			return $elm$core$Maybe$Just(5);
		case 'WANTS_GREEN_CARD':
			return $elm$core$Maybe$Just(6);
		case 'INCORRECT_EFFECTIVE_DATE':
			return $elm$core$Maybe$Just(7);
		case 'INCORRECT_PERSONAL_DATA':
			return $elm$core$Maybe$Just(8);
		case 'INCORRECT_DATA_OTHER':
			return $elm$core$Maybe$Just(9);
		case 'PAYMENT_METHOD_OR_DATE':
			return $elm$core$Maybe$Just(10);
		case 'DECEASED_POLICY_HOLDER':
			return $elm$core$Maybe$Just(11);
		case 'COVERAGE_CHANGE':
			return $elm$core$Maybe$Just(12);
		case 'DISSATISFACTION_SERVICE':
			return $elm$core$Maybe$Just(13);
		case 'UNDERWRITING_RULES':
			return $elm$core$Maybe$Just(14);
		case 'MOVING_TO_ANOTHER_COUNTRY':
			return $elm$core$Maybe$Just(15);
		case 'PRODUCT_SERVICE':
			return $elm$core$Maybe$Just(16);
		case 'POLICY_HOLDER_CHANGE':
			return $elm$core$Maybe$Just(17);
		case 'PRICE_INCREASE':
			return $elm$core$Maybe$Just(18);
		case 'OTHER':
			return $elm$core$Maybe$Just(19);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$updateReasonKindInput = F2(
	function (_v0, autoCompleteMessage) {
		var model = _v0;
		var _v1 = A2($author$project$Pyxis$Components$Field$Autocomplete$update, autoCompleteMessage, model.bN);
		var autoCompleteReasonModel = _v1.a;
		var selectCmd = _v1.b;
		var maybeReason = $author$project$Pyxis$Components$Field$Autocomplete$getValue(autoCompleteReasonModel);
		return _Utils_Tuple2(
			_Utils_update(
				model,
				{
					kn: $elm$core$Maybe$Nothing,
					z: A2(
						$elm$core$Maybe$withDefault,
						3,
						A2(
							$elm$core$Maybe$map,
							$author$project$Common$ReasonDescriptionStatus$fromCustomerDeclineRenewalReason,
							A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$fromString, maybeReason))),
					bN: autoCompleteReasonModel,
					eC: A2($elm$core$Maybe$andThen, $author$project$Gql$Platform$Enum$CustomerDeclineRenewalReason$fromString, maybeReason)
				}),
			selectCmd);
	});
var $author$project$Page$PolicyDetails$update = F3(
	function (i18n, msg, model) {
		switch (msg.$) {
			case 0:
				if ((msg.a.$ === 3) && (!msg.a.a.$)) {
					var policy = msg.a.a.a;
					var policyOverriden = $author$project$Common$Policy$overridePolicyHolderEmail(policy);
					var _v1 = A2($author$project$Page$PolicyDetails$maybeUpdateModelIfRefererIsPaymentPage, i18n, model);
					var updatedModel = _v1.a;
					var toastEffect = _v1.b;
					return _Utils_Tuple2(
						A2(
							$author$project$Page$PolicyDetails$Model$setPaymentMethod,
							$krisajenkins$remotedata$RemoteData$Loading,
							A2(
								$author$project$Page$PolicyDetails$Model$setPolicyResult,
								$krisajenkins$remotedata$RemoteData$Success(
									$elm$core$Maybe$Just(policyOverriden)),
								updatedModel)),
						$author$project$Page$PolicyDetails$Effect$Effects(
							_List_fromArray(
								[
									$author$project$Page$PolicyDetails$Effect$FetchCustomerId(
									$author$project$Common$Policy$pickId(policyOverriden)),
									$author$project$Page$PolicyDetails$chooseClaimHistoryEffectByFetchedPolicy(policyOverriden),
									A2(
									$author$project$Page$PolicyDetails$chooseGetRefundEffectByFetchedPolicy,
									$author$project$Page$PolicyDetails$Model$pickIssueRefundReleaseDate(model),
									policyOverriden),
									$author$project$Page$PolicyDetails$Effect$FetchPaymentMethod(
									$author$project$Common$Policy$pickId(policyOverriden)),
									toastEffect
								])));
				} else {
					var remoteData = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$PolicyDetails$Model$setPolicyResult, remoteData, model),
						$author$project$Page$PolicyDetails$Effect$None);
				}
			case 1:
				if (msg.a.$ === 3) {
					var remoteData = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$PolicyDetails$Model$setClaimHistoryResult, remoteData, model),
						A2(
							$elm$core$Maybe$withDefault,
							$author$project$Page$PolicyDetails$Effect$None,
							A2(
								$elm$core$Maybe$map,
								$author$project$Page$PolicyDetails$Effect$FetchPricingActionClaimHistory,
								$author$project$Page$PolicyDetails$Model$pickPolicyId(model))));
				} else {
					var remoteData = msg.a;
					return _Utils_Tuple2(
						A2($author$project$Page$PolicyDetails$Model$setClaimHistoryResult, remoteData, model),
						$author$project$Page$PolicyDetails$Effect$None);
				}
			case 2:
				var remoteData = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$PolicyDetails$Model$setClaimHistoryPricingActionResult, remoteData, model),
					$author$project$Page$PolicyDetails$Effect$None);
			case 3:
				var remotedata = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$PolicyDetails$Model$setCustomerId, remotedata, model),
					$author$project$Page$PolicyDetails$Effect$None);
			case 4:
				var searchTerm = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$PolicyDetails$Effect$ChangeRoute(
						$author$project$Route$Search(
							$elm$core$Result$Ok(searchTerm))));
			case 7:
				var time = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							ik: $elm$core$Maybe$Just(time)
						}),
					$author$project$Page$PolicyDetails$Effect$None);
			case 5:
				return _Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None);
			case 6:
				var subMsg = msg.a;
				var _v2 = A2($author$project$Pyxis$Components$Tabs$update, subMsg, model.jn);
				var newModel = _v2.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jn: newModel}),
					$author$project$Page$PolicyDetails$Effect$None);
			case 10:
				var inputMsg = msg.a;
				var _v3 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateInterruptionDateInput, model.o, inputMsg);
				var modalModel = _v3.a;
				var subCmd = _v3.b;
				return A3(
					$author$project$Page$PolicyDetails$applyIfInterruptionModelChanged,
					model,
					modalModel,
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd),
								$author$project$Page$PolicyDetails$Effect$GetExpectedRefund
							])));
			case 11:
				var selectMsg = msg.a;
				var _v4 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonKindInput, model.o, selectMsg);
				var modalModel = _v4.a;
				var subCmd = _v4.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: modalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 13:
				var _v5 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateExpectedRefundAmount, model.o, $krisajenkins$remotedata$RemoteData$NotAsked);
				var newModalState = _v5.a;
				var subCmd = _v5.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newModalState}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 12:
				var _v6 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateExpectedRefundAmount, model.o, $krisajenkins$remotedata$RemoteData$Loading);
				var newModalState = _v6.a;
				return A3($author$project$Page$PolicyDetails$applyIfInterruptionModelChanged, model, newModalState, $author$project$Page$PolicyDetails$Effect$GetExpectedRefund);
			case 14:
				var textareaMsg = msg.a;
				var _v7 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateReasonDescription, model.o, textareaMsg);
				var modalModel = _v7.a;
				var subCmd = _v7.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: modalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 15:
				var kind = msg.a;
				return A2(
					$elm$core$Maybe$withDefault,
					_Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None),
					A3(
						$elm$core$Maybe$map2,
						A3($author$project$Page$PolicyDetails$openAndInitInterruptionModal, i18n, kind, model),
						$elm_community$maybe_extra$Maybe$Extra$join(
							$krisajenkins$remotedata$RemoteData$toMaybe(model.j)),
						model.ik));
			case 16:
				var _v8 = $author$project$Page$PolicyDetails$InterruptionModal$Model$closeModal(model.o);
				var newInterruptionDateModel = _v8.a;
				var cmd = _v8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newInterruptionDateModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 20:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{dT: result}),
					$author$project$Page$PolicyDetails$Effect$FetchPolicy(model.m_));
			case 17:
				var _arguments = msg.a;
				var _v9 = $author$project$Page$PolicyDetails$InterruptionModal$Model$closeModal(model.o);
				var newInterruptionDateModel = _v9.a;
				var cmd = _v9.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newInterruptionDateModel, dT: $krisajenkins$remotedata$RemoteData$Loading, j: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$WithdrawPolicy(_arguments),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 18:
				var _arguments = msg.a;
				var _v10 = $author$project$Page$PolicyDetails$InterruptionModal$Model$closeModal(model.o);
				var newInterruptionDateModel = _v10.a;
				var cmd = _v10.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newInterruptionDateModel, dT: $krisajenkins$remotedata$RemoteData$Loading, j: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$CustomerTerminatePolicy(_arguments),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 19:
				var _arguments = msg.a;
				var _v11 = $author$project$Page$PolicyDetails$InterruptionModal$Model$closeModal(model.o);
				var newInterruptionDateModel = _v11.a;
				var cmd = _v11.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newInterruptionDateModel, dT: $krisajenkins$remotedata$RemoteData$Loading, j: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$PrimaTerminatePolicy(_arguments),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 8:
				var amount = msg.a;
				var _v12 = A2($author$project$Page$PolicyDetails$InterruptionModal$Model$updateExpectedRefundAmount, model.o, amount);
				var newModalState = _v12.a;
				var subCmd = _v12.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{o: newModalState}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 9:
				var remoteData = msg.a;
				return _Utils_Tuple2(
					A2($author$project$Page$PolicyDetails$Model$setRefundResult, remoteData, model),
					$author$project$Page$PolicyDetails$Effect$None);
			case 21:
				var policyId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{fr: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$CreateRefund(policyId));
			case 22:
				var refund = msg.a;
				var _v13 = A2($author$project$Page$PolicyDetails$RefundIssuingModal$openModal, refund, model.bO);
				var newRefundModalState = _v13.a;
				var cmd = _v13.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bO: newRefundModalState}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 23:
				var _v14 = $author$project$Page$PolicyDetails$RefundIssuingModal$closeModal(model.bO);
				var newRefundModalState = _v14.a;
				var cmd = _v14.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bO: newRefundModalState}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 24:
				var refund = msg.a;
				var _v15 = $author$project$Page$PolicyDetails$RefundIssuingModal$closeModal(model.bO);
				var newRefundModalState = _v15.a;
				var cmd = _v15.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bO: newRefundModalState}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$IssueRefund(refund),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 25:
				if (msg.a.$ === 3) {
					var result = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fr: result}),
						$author$project$Page$PolicyDetails$Effect$FetchPolicy(model.m_));
				} else {
					var result = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{fr: result}),
						$author$project$Page$PolicyDetails$Effect$None);
				}
			case 26:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ly: result}),
					A2(
						$elm$core$Maybe$withDefault,
						$author$project$Page$PolicyDetails$Effect$None,
						A2(
							$elm$core$Maybe$map,
							$author$project$Page$PolicyDetails$Effect$GetRefund,
							$author$project$Page$PolicyDetails$Model$pickPolicyId(model))));
			case 27:
				var timestampMillis = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							lx: $elm$core$Maybe$Just(
								$elm$time$Time$millisToPosix(timestampMillis))
						}),
					$author$project$Page$PolicyDetails$Effect$None);
			case 28:
				return _Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None);
			case 29:
				var policy = msg.a;
				return A3($author$project$Page$PolicyDetails$openAndInitRenewalCancellationModal, i18n, model, policy);
			case 30:
				var _v16 = $author$project$Page$PolicyDetails$RenewalCancellationModal$closeModal(model.ab);
				var newRenewalCancellationModel = _v16.a;
				var cmd = _v16.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: newRenewalCancellationModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 31:
				var selectMsg = msg.a;
				var _v17 = A2($author$project$Page$PolicyDetails$RenewalCancellationModal$updateReasonKindInput, model.ab, selectMsg);
				var modalModel = _v17.a;
				var subCmd = _v17.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: modalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 32:
				var textareaMsg = msg.a;
				var _v18 = A2($author$project$Page$PolicyDetails$RenewalCancellationModal$updateReasonAdditionalNotes, model.ab, textareaMsg);
				var modalModel = _v18.a;
				var subCmd = _v18.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ab: modalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(subCmd));
			case 33:
				var args = msg.a;
				var _v19 = $author$project$Page$PolicyDetails$RenewalCancellationModal$closeModal(model.ab);
				var newRenewalCancellationModal = _v19.a;
				var cmd = _v19.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{j: $krisajenkins$remotedata$RemoteData$Loading, ab: newRenewalCancellationModal, jB: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$RenewalCancellation(args),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 34:
				var result = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jB: result}),
					$author$project$Page$PolicyDetails$Effect$FetchPolicy(model.m_));
			case 39:
				switch (msg.a.$) {
					case 3:
						var result = msg.a;
						var _v20 = A2(
							$author$project$Pyxis$Components$Toasts$addNewToast,
							A2(
								$author$project$Pyxis$Components$Toasts$withToastText,
								A2(
									$author$project$Translations$policySendEmailSuccessMessage,
									A2(
										$elm$core$Maybe$withDefault,
										'',
										$author$project$Page$PolicyDetails$Model$pickEmailRecipient(model)),
									i18n),
								$author$project$Pyxis$Components$Toasts$successToast),
							model.O);
						var newToastsModel = _v20.a;
						var toastCmd = _v20.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{eD: result, O: newToastsModel}),
							$author$project$Page$PolicyDetails$Effect$WithCmd(toastCmd));
					case 2:
						var result = msg.a;
						var _v21 = A2(
							$author$project$Pyxis$Components$Toasts$addNewToast,
							A2(
								$author$project$Pyxis$Components$Toasts$withToastText,
								$author$project$Translations$policySendEmailFailureMessage(i18n),
								$author$project$Pyxis$Components$Toasts$errorToast),
							model.O);
						var newToastsModel = _v21.a;
						var toastCmd = _v21.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{eD: result, O: newToastsModel}),
							$author$project$Page$PolicyDetails$Effect$WithCmd(toastCmd));
					default:
						var result = msg.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{eD: result}),
							$author$project$Page$PolicyDetails$Effect$None);
				}
			case 35:
				var renewalPolicyId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$PolicyDetails$Effect$ChangeRoute(
						A2(
							$author$project$Route$PolicyDetails,
							renewalPolicyId,
							$elm$core$Maybe$Just($author$project$Referer$paymentPage))));
			case 36:
				var message = msg.a;
				var _v22 = A2($author$project$Common$Portfolio$update, message, model.cW);
				var newModel = _v22.a;
				var cmd = _v22.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{cW: newModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 37:
				var response = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jm: response}),
					$author$project$Page$PolicyDetails$Effect$None);
			case 38:
				var policyId = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{jm: $krisajenkins$remotedata$RemoteData$Loading}),
					$author$project$Page$PolicyDetails$Effect$FetchPaymentMethod(policyId));
			case 40:
				var policyId = msg.a;
				var email = msg.b;
				var _v23 = A3($author$project$Page$PolicyDetails$SendEmailModal$openModal, policyId, email, model.bS);
				var newModalModel = _v23.a;
				var cmd = _v23.b;
				return _Utils_Tuple2(
					$author$project$Page$PolicyDetails$resetSendEmailResult(
						A2($author$project$Page$PolicyDetails$openSendEmailModal, newModalModel, model)),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 42:
				var sendEmailMsg = msg.a;
				var _v24 = A2($author$project$Page$PolicyDetails$SendEmailModal$update, sendEmailMsg, model.bS);
				var newModalModel = _v24.a;
				var cmd = _v24.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bS: newModalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 41:
				var id = msg.a;
				var _v25 = $author$project$Page$PolicyDetails$SendEmailModal$closeModal(model.bS);
				var newModalModel = _v25.a;
				var cmd = _v25.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{bS: newModalModel}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								$author$project$Page$PolicyDetails$Effect$SendPolicyContractEmail(id),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			case 43:
				var toastCounterSubMsg = msg.a;
				var _v26 = A2($author$project$Pyxis$Components$Toasts$update, toastCounterSubMsg, model.O);
				var newToastsModel = _v26.a;
				var cmd = _v26.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: newToastsModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 44:
				var originalEmail = msg.a;
				var _v27 = A2($author$project$Page$PolicyDetails$ChangeEmailModal$openModal, originalEmail, model.ba);
				var newModalModel = _v27.a;
				var cmd = _v27.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newModalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 45:
				var subMsg = msg.a;
				var _v28 = A2($author$project$Page$PolicyDetails$ChangeEmailModal$update, subMsg, model.ba);
				var newModalModel = _v28.a;
				var cmd = _v28.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newModalModel}),
					$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
			case 46:
				var newEmail = msg.a;
				var _v29 = $author$project$Page$PolicyDetails$ChangeEmailModal$closeModal(model.ba);
				var newModalModel = _v29.a;
				var cmd = _v29.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{ba: newModalModel}),
					$author$project$Page$PolicyDetails$Effect$Effects(
						_List_fromArray(
							[
								A2(
								$elm$core$Maybe$withDefault,
								$author$project$Page$PolicyDetails$Effect$None,
								A2(
									$elm$core$Maybe$map,
									$author$project$Page$PolicyDetails$Effect$UpdateEmail(newEmail),
									A2(
										$elm$core$Maybe$map,
										$author$project$Common$Policy$pickCustomerFacingId,
										$elm_community$maybe_extra$Maybe$Extra$join(
											$krisajenkins$remotedata$RemoteData$toMaybe(model.j))))),
								$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
							])));
			default:
				switch (msg.a.$) {
					case 2:
						var _v30 = A2(
							$author$project$Pyxis$Components$Toasts$addNewToast,
							A2(
								$author$project$Pyxis$Components$Toasts$withToastText,
								$author$project$Translations$changeEmailModalErrorToastMessage(i18n),
								$author$project$Pyxis$Components$Toasts$errorToast),
							model.O);
						var newToastModel = _v30.a;
						var cmd = _v30.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{O: newToastModel}),
							$author$project$Page$PolicyDetails$Effect$WithCmd(cmd));
					case 3:
						var _v31 = A2(
							$author$project$Pyxis$Components$Toasts$addNewToast,
							A2(
								$author$project$Pyxis$Components$Toasts$withToastText,
								$author$project$Translations$changeEmailModalSuccessToastMessage(i18n),
								$author$project$Pyxis$Components$Toasts$successToast),
							model.O);
						var newToastModel = _v31.a;
						var cmd = _v31.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{O: newToastModel}),
							$author$project$Page$PolicyDetails$Effect$Effects(
								_List_fromArray(
									[
										$author$project$Page$PolicyDetails$Effect$FetchPolicy(model.m_),
										$author$project$Page$PolicyDetails$Effect$WithCmd(cmd)
									])));
					default:
						return _Utils_Tuple2(model, $author$project$Page$PolicyDetails$Effect$None);
				}
		}
	});
var $author$project$Page$Search$Effect$ChangeRoute = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Search$Effect$RewriteUrlRequest = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Search$Effect$RewrittenUrl = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Search$Effect$TrackUserSearch = function (a) {
	return {$: 8, a: a};
};
var $author$project$Pyxis$Components$Field$Input$resetValue = function (_v0) {
	var modelData = _v0;
	return _Utils_update(
		modelData,
		{eW: ''});
};
var $author$project$Page$Search$SearchQueryParam$searchInputToString = function (searchInput) {
	switch (searchInput.$) {
		case 0:
			return 'documentId';
		case 1:
			switch (searchInput.a.$) {
				case 1:
					return 'offerId';
				case 0:
					return 'policyId';
				default:
					return 'quoteId';
			}
		case 2:
			return 'email';
		case 3:
			return 'empty';
		case 4:
			return 'plateNumber';
		default:
			return 'phoneNumber';
	}
};
var $author$project$Translations$searchResultsToastErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'An unexpected error occurred. Please try again later.';
	} else {
		return 'Se ha producido un error inesperado. Vuelve a intentarlo más tarde.';
	}
};
var $author$project$Page$Search$SearchQueryParam$EmptyError = {$: 8};
var $author$project$Page$Search$SearchQueryParam$CustomerFacingIdError = {$: 0};
var $author$project$Page$Search$SearchQueryParam$OfferError = {$: 2};
var $author$project$Page$Search$SearchQueryParam$isValidOfferId = function (offerId) {
	return A2(
		$elm$regex$Regex$contains,
		$author$project$Page$Search$SearchQueryParam$fromStringToRegex('^F\\d{9}$'),
		offerId) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$CustomerFacingId(
			$author$project$Page$Search$SearchQueryParam$OfferId(offerId))) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$OfferError);
};
var $author$project$Page$Search$SearchQueryParam$PolicyError = {$: 1};
var $author$project$Page$Search$SearchQueryParam$isValidPolicyId = function (policyId) {
	return A2(
		$elm$regex$Regex$contains,
		$author$project$Page$Search$SearchQueryParam$fromStringToRegex('^P\\d{9}$'),
		policyId) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$CustomerFacingId(
			$author$project$Page$Search$SearchQueryParam$PolicyId(policyId))) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$PolicyError);
};
var $author$project$Page$Search$SearchQueryParam$QuoteError = {$: 3};
var $author$project$Page$Search$SearchQueryParam$isValidQuoteId = function (quoteId) {
	return A2(
		$elm$regex$Regex$contains,
		$author$project$Page$Search$SearchQueryParam$fromStringToRegex('^Q\\d{8}$'),
		quoteId) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$CustomerFacingId(
			$author$project$Page$Search$SearchQueryParam$QuoteId(quoteId))) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$QuoteError);
};
var $author$project$Page$Search$SearchQueryParam$isValidCustomerFacingId = function (cfid) {
	var _v0 = $author$project$Page$Search$SearchQueryParam$attemptIdentifyCustomerFacingId(cfid);
	if (!_v0.$) {
		switch (_v0.a.$) {
			case 0:
				var value = _v0.a.a;
				return $author$project$Page$Search$SearchQueryParam$isValidPolicyId(value);
			case 1:
				var value = _v0.a.a;
				return $author$project$Page$Search$SearchQueryParam$isValidOfferId(value);
			default:
				var value = _v0.a.a;
				return $author$project$Page$Search$SearchQueryParam$isValidQuoteId(value);
		}
	} else {
		return $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$CustomerFacingIdError);
	}
};
var $author$project$Page$Search$SearchQueryParam$DocumentIdError = {$: 5};
var $author$project$Page$Search$SearchQueryParam$isValidDocumentId = function (value) {
	var controlLetters = 'TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke';
	var dniPattern = '\\d{8}[' + (controlLetters + ']');
	var niePattern = '[KLMXYZklmxyz]\\d{7,8}[' + (controlLetters + ']');
	var documentIdPattern = A2(
		$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
		'^({0}|{1})$',
		_List_fromArray(
			[dniPattern, niePattern]));
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex(documentIdPattern);
	return A2($elm$regex$Regex$contains, regex, value) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$DocumentId(value)) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$DocumentIdError);
};
var $author$project$Page$Search$SearchQueryParam$EmailError = {$: 4};
var $author$project$Page$Search$SearchQueryParam$isValidEmail = function (email) {
	var emailPattern = '^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$';
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex(emailPattern);
	return A2($elm$regex$Regex$contains, regex, email) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$Email(email)) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$EmailError);
};
var $author$project$Page$Search$SearchQueryParam$PhoneNumberError = {$: 7};
var $author$project$Page$Search$SearchQueryParam$isValidPhoneNumber = function (phoneNumber) {
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex('^(\\d{9}|\\d{10})$');
	return A2($elm$regex$Regex$contains, regex, phoneNumber) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$PhoneNumber(phoneNumber)) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$PhoneNumberError);
};
var $author$project$Page$Search$SearchQueryParam$PlateNumberError = {$: 6};
var $author$project$Page$Search$SearchQueryParam$isValidPlateNumber = function (value) {
	var oldProvincialCodes = '(A|ALB|AB|AL|AOE|AV|B|BA|BI|BU|C|CA|CAC|CC|CAS|CS|CE|CO|CR|CU|FP|GC|GE|GI|GR|GU|H|HU|I|IF|PM|IB|J|L|LE|LO|LR|LU|M|MA|ME|ML|MU|O|OR|OU|P|PA|NA|PO|RM|S|SA|SE|SEG|SG|SH|SO|SS|T|TE|TER|TE|TEG|TF|TG|TO|V|VA|VI|Z|ZA)';
	var previousPlateNumberPattern = oldProvincialCodes + '\\d{4}[A-Z]{1,2}';
	var oldPlateNumberPattern = oldProvincialCodes + '\\d{1,6}';
	var currentPlateNumberPattern = '\\d{4}[BCDFGHJKLMNPRSTVWXYZ]{3}';
	var regex = $author$project$Page$Search$SearchQueryParam$fromStringToRegex(
		A2(
			$lukewestby$elm_string_interpolate$String$Interpolate$interpolate,
			'^({0}|{1}|{2})$',
			_List_fromArray(
				[currentPlateNumberPattern, previousPlateNumberPattern, oldPlateNumberPattern])));
	return A2($elm$regex$Regex$contains, regex, value) ? $elm$core$Result$Ok(
		$author$project$Page$Search$SearchQueryParam$PlateNumber(value)) : $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$PlateNumberError);
};
var $author$project$Page$Search$SearchQueryParam$validateSearchInput = function (searchInput) {
	switch (searchInput.$) {
		case 0:
			var docId = searchInput.a;
			return $author$project$Page$Search$SearchQueryParam$isValidDocumentId(docId);
		case 1:
			var cfid = searchInput.a;
			return $author$project$Page$Search$SearchQueryParam$isValidCustomerFacingId(
				$author$project$Page$Search$SearchQueryParam$pickCustomerFacingId(cfid));
		case 2:
			var email = searchInput.a;
			return $author$project$Page$Search$SearchQueryParam$isValidEmail(email);
		case 4:
			var plateNumber = searchInput.a;
			return $author$project$Page$Search$SearchQueryParam$isValidPlateNumber(plateNumber);
		case 5:
			var phone = searchInput.a;
			return $author$project$Page$Search$SearchQueryParam$isValidPhoneNumber(phone);
		default:
			return $elm$core$Result$Err($author$project$Page$Search$SearchQueryParam$EmptyError);
	}
};
var $author$project$Page$Search$update = F3(
	function (i18n, msg, model) {
		switch (msg.$) {
			case 0:
				var inputValue = $author$project$Page$Search$SearchQueryParam$validateSearchInput(
					$author$project$Page$Search$SearchQueryParam$fromStringInputToSearchInput(
						$author$project$Pyxis$Components$Field$Input$getValue(model.c_)));
				if (!inputValue.$) {
					var value = inputValue.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d0: true}),
						$author$project$Page$Search$Effect$Effects(
							_List_fromArray(
								[
									$author$project$Page$Search$Effect$RewriteUrlRequest(value),
									$author$project$Page$Search$Effect$TrackUserSearch(
									$author$project$Page$Search$SearchQueryParam$searchInputToString(value))
								])));
				} else {
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{d0: true}),
						$author$project$Page$Search$Effect$None);
				}
			case 1:
				var id = msg.a;
				var _v2 = A2($author$project$Pyxis$Components$Field$Input$update, id, model.c_);
				var newFieldModel = _v2.a;
				var subCmd = _v2.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{d0: false, c_: newFieldModel}),
					$author$project$Page$Search$Effect$WithCmd(subCmd));
			case 2:
				if (msg.a.$ === 2) {
					var result = msg.a;
					var _v3 = A2(
						$author$project$Pyxis$Components$Toasts$addNewToast,
						A2(
							$author$project$Pyxis$Components$Toasts$withToastText,
							$author$project$Translations$searchResultsToastErrorMessage(i18n),
							$author$project$Pyxis$Components$Toasts$errorToast),
						model.O);
					var newToastsModel = _v3.a;
					var toastsCmd = _v3.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aW: result, O: newToastsModel}),
						$author$project$Page$Search$Effect$WithCmd(toastsCmd));
				} else {
					var result = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aW: result}),
						$author$project$Page$Search$Effect$None);
				}
			case 3:
				var offer = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$Search$Effect$ChangeRoute(
						$author$project$Route$Payment(
							$author$project$Common$Offer$pickId(offer))));
			case 4:
				var date = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							fu: $elm$core$Maybe$Just(date)
						}),
					$author$project$Page$Search$Effect$None);
			case 5:
				var searchTerm = msg.a;
				var queryParams = $author$project$Route$toString(
					$author$project$Route$Search(
						$elm$core$Result$Ok(searchTerm)));
				return _Utils_Tuple2(
					model,
					$author$project$Page$Search$Effect$RewrittenUrl(queryParams));
			case 6:
				var customerFacingId = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$Search$Effect$ChangeRoute(
						A2($author$project$Route$PolicyDetails, customerFacingId, $elm$core$Maybe$Nothing)));
			case 7:
				var offerCfid = msg.a;
				return _Utils_Tuple2(
					model,
					$author$project$Page$Search$Effect$ChangeRoute(
						$author$project$Route$OfferDetails(offerCfid)));
			case 8:
				if (!msg.a) {
					var _v4 = $author$project$Pyxis$Components$Modal$close(model.eF);
					var newModalModel = _v4.a;
					var cmd = _v4.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{eB: $elm$core$Maybe$Nothing, eF: newModalModel}),
						$author$project$Page$Search$Effect$WithCmd(cmd));
				} else {
					var selectedOffer = msg.b;
					var _v5 = $author$project$Pyxis$Components$Modal$open(model.eF);
					var newModalModel = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								eB: $elm$core$Maybe$Just(selectedOffer),
								eF: newModalModel
							}),
						$author$project$Page$Search$Effect$WithCmd(cmd));
				}
			case 9:
				if (msg.a) {
					var selectedOffer = msg.b;
					var _v6 = $author$project$Pyxis$Components$Modal$open(model.dA);
					var newModalModel = _v6.a;
					var cmd = _v6.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								dA: newModalModel,
								eB: $elm$core$Maybe$Just(selectedOffer)
							}),
						$author$project$Page$Search$Effect$WithCmd(cmd));
				} else {
					var _v7 = $author$project$Pyxis$Components$Modal$close(model.dA);
					var newModalModel = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{dA: newModalModel, eB: $elm$core$Maybe$Nothing}),
						$author$project$Page$Search$Effect$WithCmd(cmd));
				}
			case 10:
				var subMsg = msg.a;
				var _v8 = A2($author$project$Pyxis$Components$Toasts$update, subMsg, model.O);
				var newToastsModel = _v8.a;
				var cmd = _v8.b;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{O: newToastsModel}),
					$author$project$Page$Search$Effect$WithCmd(cmd));
			case 11:
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{
							d0: false,
							c_: $author$project$Pyxis$Components$Field$Input$resetValue(model.c_)
						}),
					$author$project$Page$Search$Effect$WithCmd(
						A2(
							$elm$core$Task$attempt,
							$elm$core$Basics$always($author$project$Page$Search$Msg$NoOp),
							$elm$browser$Browser$Dom$focus(model.jH))));
			default:
				return _Utils_Tuple2(model, $author$project$Page$Search$Effect$None);
		}
	});
var $author$project$Main$update = F2(
	function (model, msg) {
		var _v0 = _Utils_Tuple2(msg, model.mX);
		_v0$0:
		while (true) {
			_v0$1:
			while (true) {
				_v0$2:
				while (true) {
					_v0$10:
					while (true) {
						_v0$11:
						while (true) {
							switch (_v0.b.$) {
								case 0:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 3:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v5 = A2($author$project$Page$NotFound$update, subMsg, subModel);
											var newSubModel = _v5.a;
											var subEffect = _v5.b;
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$NotFound(newSubModel)
													}),
												$author$project$Effect$NotFound(subEffect));
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								case 1:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 4:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v6 = A2($author$project$Page$NotAuthorized$update, subMsg, subModel);
											var newSubModel = _v6.a;
											var subEffect = _v6.b;
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$NotAuthorized(newSubModel)
													}),
												$author$project$Effect$NotAuthorized(subEffect));
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								case 2:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 5:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v2 = A2($author$project$Page$Login$update, subMsg, subModel);
											var newSubModel = _v2.a;
											var subEffect = _v2.b;
											if (subEffect.$ === 2) {
												var _v4 = subEffect.a;
												var user = _v4.a;
												var route = _v4.b;
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															mX: $author$project$Model$Login(newSubModel),
															eV: $author$project$Common$User$Backoffice(user)
														}),
													$author$project$Effect$ChangeRoute(route));
											} else {
												return _Utils_Tuple2(
													_Utils_update(
														model,
														{
															mX: $author$project$Model$Login(newSubModel)
														}),
													$author$project$Effect$Login(subEffect));
											}
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								case 3:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 8:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v7 = A3($author$project$Page$Search$update, model.ln, subMsg, subModel);
											var newSubModel = _v7.a;
											var subEffect = _v7.b;
											var subEffectToMainEffect = function () {
												switch (subEffect.$) {
													case 2:
														var route = subEffect.a;
														return $author$project$Effect$ChangeRoute(route);
													case 7:
														var queryParams = subEffect.a;
														return $author$project$Effect$RewriteUrl(queryParams);
													default:
														return $author$project$Effect$Search(subEffect);
												}
											}();
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$Search(newSubModel)
													}),
												subEffectToMainEffect);
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								case 4:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 9:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v9 = A2($author$project$Page$Payment$update, subMsg, subModel);
											var newSubModel = _v9.a;
											var subEffect = _v9.b;
											var subEffectToMainEffect = function () {
												switch (subEffect.$) {
													case 1:
														var route = subEffect.a;
														return $author$project$Effect$ChangeRoute(route);
													case 2:
														var pageNumber = subEffect.a;
														return $author$project$Effect$GoBack(pageNumber);
													default:
														return $author$project$Effect$Payment(subEffect);
												}
											}();
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$Payment(newSubModel)
													}),
												subEffectToMainEffect);
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								case 5:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 10:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v11 = A3($author$project$Page$PolicyDetails$update, model.ln, subMsg, subModel);
											var newSubModel = _v11.a;
											var subEffect = _v11.b;
											var subEffectToMainEffect = function () {
												if (subEffect.$ === 4) {
													var route = subEffect.a;
													return $author$project$Effect$ChangeRoute(route);
												} else {
													return $author$project$Effect$PolicyDetails(subEffect);
												}
											}();
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$PolicyDetails(newSubModel)
													}),
												subEffectToMainEffect);
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
								default:
									switch (_v0.a.$) {
										case 1:
											break _v0$0;
										case 2:
											break _v0$1;
										case 12:
											break _v0$2;
										case 11:
											var subMsg = _v0.a.a;
											var subModel = _v0.b.a;
											var _v13 = A4($author$project$Page$OfferDetails$update, model.ln, model.kY, subMsg, subModel);
											var newSubModel = _v13.a;
											var subEffect = _v13.b;
											var subEffectToMainEffect = function () {
												if (subEffect.$ === 6) {
													var route = subEffect.a;
													return $author$project$Effect$ChangeRoute(route);
												} else {
													return $author$project$Effect$OfferDetails(subEffect);
												}
											}();
											return _Utils_Tuple2(
												_Utils_update(
													model,
													{
														mX: $author$project$Model$OfferDetails(newSubModel)
													}),
												subEffectToMainEffect);
										case 6:
											break _v0$10;
										case 7:
											break _v0$11;
										default:
											return _Utils_Tuple2(model, $author$project$Effect$None);
									}
							}
						}
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{eV: $author$project$Common$User$Anonymous}),
							$author$project$Effect$ChangeRoute($author$project$Route$Login));
					}
					var _v15 = _v0.a;
					return _Utils_Tuple2(model, $author$project$Effect$Logout);
				}
				return _Utils_Tuple2(model, $author$project$Effect$None);
			}
			var url = _v0.a.a;
			return A2(
				$author$project$Main$onRouteChange,
				model,
				$author$project$Route$fromUrl(url));
		}
		var urlRequest = _v0.a.a;
		if (!urlRequest.$) {
			var url = urlRequest.a;
			return A2(
				$elm$core$Tuple$mapSecond,
				function (effect) {
					return $author$project$Effect$Effects(
						_List_fromArray(
							[
								effect,
								$author$project$Effect$ChangeRoute(
								$author$project$Route$fromUrl(url))
							]));
				},
				A2(
					$author$project$Main$onRouteChange,
					model,
					$author$project$Route$fromUrl(url)));
		} else {
			var address = urlRequest.a;
			return _Utils_Tuple2(
				model,
				$author$project$Effect$LoadExternalPage(address));
		}
	});
var $author$project$Main$handle = F2(
	function (msg, model) {
		var _v0 = A2($author$project$Main$update, model, msg);
		var newModel = _v0.a;
		var effect = _v0.b;
		return _Utils_Tuple2(
			newModel,
			A3($author$project$Effect$runEffect, model.kY, newModel, effect));
	});
var $author$project$Translations$Es = 1;
var $author$project$Env$Env = $elm$core$Basics$identity;
var $author$project$Env$env = {cb: '', cr: '', cu: '', cB: $elm$core$Maybe$Nothing, cF: $author$project$Common$Locale$customerTimeZone, cG: '', cH: ''};
var $author$project$Translations$En = 0;
var $author$project$Translations$languages = _List_fromArray(
	[0, 1]);
var $author$project$Translations$languageFromString = function (lang) {
	var helper = function (langs) {
		helper:
		while (true) {
			if (!langs.b) {
				return $elm$core$Maybe$Nothing;
			} else {
				var l = langs.a;
				var ls = langs.b;
				if (A2(
					$elm$core$String$startsWith,
					$author$project$Translations$languageToString(l),
					lang)) {
					return $elm$core$Maybe$Just(l);
				} else {
					var $temp$langs = ls;
					langs = $temp$langs;
					continue helper;
				}
			}
		}
	};
	return helper(
		$elm$core$List$reverse($author$project$Translations$languages));
};
var $author$project$Main$extractPreferredLanguage = function (languages) {
	return $elm$core$List$head(
		A2($elm$core$List$filterMap, $author$project$Translations$languageFromString, languages));
};
var $author$project$Translations$I18n = $elm$core$Basics$identity;
var $author$project$Translations$init = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$NavigationSidebar$Collapsed = 1;
var $author$project$Pyxis$Components$NavigationSidebar$Model = $elm$core$Basics$identity;
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $author$project$Pyxis$Components$NavigationSidebar$initCollapsed = {b6: '', cp: 1, ar: $elm$core$Set$empty, ef: $elm$core$Maybe$Nothing};
var $author$project$Model$init = F4(
	function (key, env, pageModel, language) {
		return {
			kY: env,
			ln: $author$project$Translations$init(language),
			gq: key,
			lZ: $author$project$Pyxis$Components$NavigationSidebar$initCollapsed,
			mX: pageModel,
			eV: $author$project$Common$User$Anonymous
		};
	});
var $author$project$Main$languageFromUrl = function (url) {
	return $elm_community$maybe_extra$Maybe$Extra$join(
		A2(
			$elm$core$Maybe$map,
			$author$project$Translations$languageFromString,
			A2(
				$elm$core$Array$get,
				1,
				$elm$core$Array$fromList(
					A2($elm$core$String$split, '/', url.mY)))));
};
var $elm_community$maybe_extra$Maybe$Extra$or = F2(
	function (ma, mb) {
		if (ma.$ === 1) {
			return mb;
		} else {
			return ma;
		}
	});
var $author$project$Env$withApiUrl = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cb: value});
	});
var $author$project$Env$withExternalApiUrl = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cr: value});
	});
var $author$project$Env$withFunnelUrl = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cu: value});
	});
var $author$project$Env$withLanguageOverride = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{
				cB: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Env$withLocalTimeZone = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cF: value});
	});
var $author$project$Env$withLoginPath = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cG: value});
	});
var $author$project$Env$withLogoutPath = F2(
	function (value, _v0) {
		var environment = _v0;
		return _Utils_update(
			environment,
			{cH: value});
	});
var $justinmimbs$timezone_data$TimeZone$Specification$Save = function (a) {
	return {$: 0, a: a};
};
var $justinmimbs$timezone_data$TimeZone$africa__abidjan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Algeria = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		1380,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		1380,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__algiers = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Algeria)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 9, 21, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Algeria)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 9, 26, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Algeria)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 4, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__bissau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Egypt = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1959,
		1981,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1966,
		1994,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1988,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1994,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2010,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2005,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 3, 1),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__cairo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Egypt))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Morocco = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2013,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2018,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2018,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2015,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2015,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2016,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2016,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		2017,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		2017,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2018,
		2018,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2018,
		2018,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2019,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2019,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2021,
		2021,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2021,
		2021,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2022,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2022,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		2023,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		2023,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2024,
		2024,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2024,
		2024,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2025,
		2025,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2025,
		2025,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2026,
		2026,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2026,
		2026,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2027,
		2027,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2027,
		2027,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2028,
		2028,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2028,
		2028,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2029,
		2029,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2029,
		2029,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2029,
		2029,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2030,
		2030,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2030,
		2030,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2031,
		2031,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2031,
		2031,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2032,
		2032,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2032,
		2032,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2033,
		2033,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2033,
		2033,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2033,
		2033,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2034,
		2034,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2034,
		2034,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2035,
		2035,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2035,
		2035,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2036,
		2036,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2036,
		2036,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2037,
		2037,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		180,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2037,
		2037,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__casablanca = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Morocco)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 2, 16, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Morocco)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 9, 28, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Morocco))));
};
var $justinmimbs$timezone_data$TimeZone$rules_SpainAfrica = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__ceuta = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_SpainAfrica)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 2, 16, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$africa__el_aaiun = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 3, 14, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Morocco)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 9, 28, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Morocco))));
};
var $justinmimbs$timezone_data$TimeZone$africa__johannesburg = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Sudan = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__juba = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Sudan)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 0, 15, 720, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2021, 1, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__khartoum = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Sudan)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 0, 15, 720, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2017, 10, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__lagos = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__maputo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__monrovia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-45,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 0, 7, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__nairobi = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__ndjamena = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 9, 14, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 2, 8, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$africa__sao_tome = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 0, 1, 60, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2019, 0, 1, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Libya = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1989,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1989,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__tripoli = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Libya)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 4, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Libya)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 9, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 10, 10, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Libya)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2013, 9, 25, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Tunisia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1990,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2008,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2008,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__tunis = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Tunisia))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Namibia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		2017,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2017,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		-60)
	]);
var $justinmimbs$timezone_data$TimeZone$africa__windhoek = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 2, 21, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Namibia))));
};
var $justinmimbs$timezone_data$TimeZone$rules_US = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1973,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__adak = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__anchorage = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Brazil = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1995,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 11),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1995,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2001,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2006,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2002,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2003,
		2003,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2017,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2011,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2014,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2015,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2019,
		1,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2018,
		2018,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__araguaina = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 8, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1995, 8, 14, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2003, 8, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 9, 21, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2013, 8, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Arg = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1968,
		1969,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1993,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1992,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2009,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__argentina__buenos_aires = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__catamarca = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__cordoba = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__jujuy = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 2, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 9, 28, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 6, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__la_rioja = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 4, 7, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__mendoza = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 2, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 9, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 9, 18, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 4, 23, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 8, 26, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__rio_gallegos = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__salta = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__san_juan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 4, 7, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 4, 31, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 6, 25, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_SanLuis = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2009,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2008,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__argentina__san_luis = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 2, 14, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 9, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 5, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 4, 31, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 6, 25, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 0, 21, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_SanLuis)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2009, 9, 11, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__tucuman = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 13, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg))));
};
var $justinmimbs$timezone_data$TimeZone$america__argentina__ushuaia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 2, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 4, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 9, 18, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Para = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1988,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1978,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1991,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1995,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1995,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2001,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		2001,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2004,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2003,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2009,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2009,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2012,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__asuncion = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 3, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Para))));
};
var $justinmimbs$timezone_data$TimeZone$america__bahia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2003, 8, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 9, 16, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 9, 21, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Mexico = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2000,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2000,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2001,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2001,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2022,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2022,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__bahia_banderas = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1970, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 3, 4, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Barb = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__barbados = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Barb))));
};
var $justinmimbs$timezone_data$TimeZone$america__belem = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Belize = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__belize = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Belize))));
};
var $justinmimbs$timezone_data$TimeZone$america__boa_vista = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 15, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_CO = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		1440,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__bogota = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_CO))));
};
var $justinmimbs$timezone_data$TimeZone$america__boise = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 1, 3, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Canada = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_NT_YK = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__cambridge_bay = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 31, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 10, 5, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 3, 1, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__campo_grande = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil))));
};
var $justinmimbs$timezone_data$TimeZone$america__cancun = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 11, 26, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 0, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 9, 26, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 7, 2, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 1, 1, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__caracas = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 11, 9, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-270,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 4, 1, 150, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__cayenne = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__chicago = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__chihuahua = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 3, 5, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 30, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__ciudad_juarez = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 3, 5, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_CR = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		5,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1992,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__costa_rica = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_CR))));
};
var $justinmimbs$timezone_data$TimeZone$america__cuiaba = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2003, 8, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil))));
};
var $justinmimbs$timezone_data$TimeZone$america__danmarkshavn = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__dawson = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 9, 28, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2020, 10, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Vanc = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1946,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1962,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__dawson_creek = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Vanc)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 7, 30, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__denver = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__detroit = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 3, 27, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Edm = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__edmonton = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Edm)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1987, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__eirunepe = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 8, 28, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 8, 22, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 5, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2013, 10, 10, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Salv = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__el_salvador = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Salv))));
};
var $justinmimbs$timezone_data$TimeZone$america__fort_nelson = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Vanc)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1987, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 2, 8, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__fortaleza = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 8, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 22, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 8, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Halifax = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1962,
		1973,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1962,
		1973,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__glace_bay = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Halifax)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$rules_StJohns = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1951,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1960,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		1,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		1,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		1,
		2,
		120),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		1,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2011,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		1,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2010,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		1,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__goose_bay = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_StJohns)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 10, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__grand_turk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 2, 8, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 2, 11, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Guat = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__guatemala = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Guat))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Ecuador = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__guayaquil = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Ecuador))));
};
var $justinmimbs$timezone_data$TimeZone$america__guyana = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-225,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 7, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 60, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__halifax = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Halifax)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Cuba = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1977,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1971,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1974,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1990,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1985,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 5),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1989,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 14),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1997,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1995,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1999,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		2003,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2003,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2010,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2010,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		0,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__havana = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Cuba))));
};
var $justinmimbs$timezone_data$TimeZone$america__hermosillo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__indianapolis = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__knox = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 27, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 2, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__marengo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 9, 27, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__petersburg = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 2, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 10, 4, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__tell_city = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 2, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__vevay = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__vincennes = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 2, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 10, 4, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__indiana__winamac = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 2, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 2, 11, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__inuvik = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 3, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__iqaluit = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 31, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__jamaica = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__juneau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 27, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 9, 26, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__kentucky__louisville = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 9, 27, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__kentucky__monticello = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__la_paz = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Peru = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1987,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1987,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__lima = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Peru))));
};
var $justinmimbs$timezone_data$TimeZone$america__los_angeles = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__maceio = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 8, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1995, 9, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 8, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 22, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 8, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Nic = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		5,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 0, 23),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__managua = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 4, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 1, 16, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Nic)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 240, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 8, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Nic))));
};
var $justinmimbs$timezone_data$TimeZone$america__manaus = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 8, 28, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 8, 22, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__martinique = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 6, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 8, 28, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__matamoros = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__mazatlan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1970, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico))));
};
var $justinmimbs$timezone_data$TimeZone$america__menominee = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 3, 29, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__merida = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 11, 26, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 10, 2, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico))));
};
var $justinmimbs$timezone_data$TimeZone$america__metlakatla = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 10, 1, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 10, 4, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2019, 0, 20, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__mexico_city = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 8, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 1, 20, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico))));
};
var $justinmimbs$timezone_data$TimeZone$america__miquelon = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 4, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1987, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Moncton = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1946,
		1972,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1957,
		1972,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		1,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		1,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__moncton = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Moncton)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Moncton)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__monterrey = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Uruguay = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		0,
		2,
		90),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		0,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1979,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1991,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 21),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1992,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2015,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2014,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__montevideo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Uruguay)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1970, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Uruguay)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Uruguay)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 2, 10, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Uruguay)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 11, 22, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Uruguay))));
};
var $justinmimbs$timezone_data$TimeZone$america__new_york = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__nome = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__noronha = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 8, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 8, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__north_dakota__beulah = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 10, 7, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__north_dakota__center = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 9, 25, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__north_dakota__new_salem = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2003, 9, 26, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__nuuk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2023, 2, 26, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2023, 9, 29, 60, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$america__ojinaga = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 3, 5, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__panama = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__paramaribo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-210,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__phoenix = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Haiti = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1987,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1987,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1997,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1997,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2015,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2015,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__port_au_prince = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Haiti))));
};
var $justinmimbs$timezone_data$TimeZone$america__porto_velho = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__puerto_rico = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Chile = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1972,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1986,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1987,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1990,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1989,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1996,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1997,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2010,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2007,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		7,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2014,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 23),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2014,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2018,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2018,
		7,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		180,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2021,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2022,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		240,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		240,
		0,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__punta_arenas = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chile)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 11, 4, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__rankin_inlet = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 3, 1, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__recife = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 8, 17, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 8, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 8, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__regina = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__resolute = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NT_YK)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 3, 1, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 9, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 2, 11, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__rio_branco = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 5, 24, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2013, 10, 10, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__santarem = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 8, 12, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 5, 24, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__santiago = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chile))));
};
var $justinmimbs$timezone_data$TimeZone$rules_DR = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1973,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1974,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__santo_domingo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_DR)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 9, 27, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 9, 29, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 11, 3, 60, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$america__sao_paulo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Brazil))));
};
var $justinmimbs$timezone_data$TimeZone$rules_C_Eur = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__scoresbysund = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 2, 29, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2024, 2, 31, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$america__sitka = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 9, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$america__st_johns = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-210,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_StJohns)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 10, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-210,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__swift_current = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 3, 30, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Hond = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		7,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 0, 1),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__tegucigalpa = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Hond))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Thule = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1992,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1992,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__thule = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Thule))));
};
var $justinmimbs$timezone_data$TimeZone$america__tijuana = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 1, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mexico)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Toronto = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1946,
		1973,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1957,
		1973,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$america__toronto = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Toronto)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__vancouver = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Vanc)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1987, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__whitehorse = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2020, 10, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Winn = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1966,
		1986,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1966,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2005,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$america__winnipeg = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Winn)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada))));
};
var $justinmimbs$timezone_data$TimeZone$america__yakutat = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 10, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__casey = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2009, 9, 18, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 5, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 9, 28, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 1, 21, 1020, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 9, 22, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 2, 11, 240, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 9, 7, 240, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2019, 2, 17, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2019, 9, 4, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2020, 2, 8, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2020, 9, 4, 1, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2021, 2, 14, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2021, 9, 3, 1, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 2, 13, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 2, 1, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2023, 2, 9, 180, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__davis = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2009, 9, 18, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 10, 1200, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 9, 28, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 1, 21, 1200, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AT = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1968,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1971,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1981,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1983,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1986,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1990,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1990,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1999,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$antarctica__macquarie = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AT)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AT))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__mawson = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2009, 9, 18, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__palmer = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Arg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 4, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chile)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 11, 4, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__rothera = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 11, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Troll = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		120),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$antarctica__troll = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2005, 1, 12, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Troll))));
};
var $justinmimbs$timezone_data$TimeZone$antarctica__vostok = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 1, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 10, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2023, 11, 18, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_RussiaAsia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		2010,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2010,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__almaty = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2024, 2, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Jordan = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1975,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1988,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1990,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1993,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1998,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		1998,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 15),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2002,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2001,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2012,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2003,
		2003,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2011,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2021,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2022,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2022,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__amman = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Jordan)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 28, 0, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Russia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		2010,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2010,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__anadyr = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						780,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__aqtau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 8, 25, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__aqtobe = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__ashgabat = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__atyrau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 2, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Iraq = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1984,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1990,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1990,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		2007,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		180,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		2007,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		180,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__baghdad = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 4, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Iraq))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Azer = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		2015,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		240,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		2015,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		300,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_EUAsia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__baku = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 8, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EUAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Azer))));
};
var $justinmimbs$timezone_data$TimeZone$asia__bangkok = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__barnaul = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1995, 4, 28, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Lebanon = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1987,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1991,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1992,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1998,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__beirut = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Lebanon))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Kyrgyz = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1996,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 7),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1996,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		150,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		2004,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		150,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__bishkek = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 7, 31, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Kyrgyz)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2005, 7, 12, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__chita = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 2, 27, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__colombo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						330,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 4, 25, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						390,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 9, 26, 30, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2006, 3, 15, 30, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				330,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Syria = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1966,
		1976,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1978,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1984,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1992,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1996,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1998,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(0),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2006,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2011,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2022,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2022,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__damascus = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Syria)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2022, 9, 28, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Dhaka = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		1440,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__dhaka = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2009, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Dhaka))));
};
var $justinmimbs$timezone_data$TimeZone$asia__dili = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 4, 3, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 8, 17, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__dubai = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__dushanbe = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 8, 9, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Cyprus = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1997,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1998,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__famagusta = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Cyprus)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 8, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EUAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 8, 8, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2017, 9, 29, 60, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EUAsia))));
};
var $justinmimbs$timezone_data$TimeZone$Specification$Prev = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $justinmimbs$timezone_data$TimeZone$rules_Palestine = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2005,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 15),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2003,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 15),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2007,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2009,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		1,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2014,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(3),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2013,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2015,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2015,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2018,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2018,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2019,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2019,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2021,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2021,
		2021,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2022,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2022,
		2035,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2023,
		2023,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2024,
		2024,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2025,
		2025,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2026,
		2054,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 5, 30),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2036,
		2036,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2037,
		2037,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_Zion = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		1440,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		1440,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1984,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		1440,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1984,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		1440,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1987,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1988,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1990,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(25),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		1995,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1998,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(14),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2001,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2001,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2002,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2002,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2003,
		2003,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2003,
		2003,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2012,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Prev, 4, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(9),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(23),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 4, 23),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__gaza = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Zion)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Jordan)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Palestine)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 7, 29, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2008, 8, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Palestine)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 27, 1, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Palestine)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 7, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2012, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Palestine))));
};
var $justinmimbs$timezone_data$TimeZone$asia__hebron = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Zion)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Jordan)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Palestine))));
};
var $justinmimbs$timezone_data$TimeZone$asia__ho_chi_minh = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 5, 13, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_HK = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1965,
		1976,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1965,
		1976,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		210,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__hong_kong = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_HK))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Mongol = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1998,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1998,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2001,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2006,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2006,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2016,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2016,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__hovd = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1978, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mongol))));
};
var $justinmimbs$timezone_data$TimeZone$asia__irkutsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__jakarta = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__jayapura = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__jerusalem = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Zion))));
};
var $justinmimbs$timezone_data$TimeZone$asia__kabul = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				270,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__kamchatka = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Pakistan = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2002,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2002,
		2002,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 2),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2009,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(15),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__karachi = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 2, 26, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Pakistan))));
};
var $justinmimbs$timezone_data$TimeZone$asia__kathmandu = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						330,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				345,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__khandyga = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 8, 13, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__kolkata = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				330,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__krasnoyarsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__kuching = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Macau = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1965,
		1973,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1976,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1976,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(13),
		210,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		210,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__macau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Macau))));
};
var $justinmimbs$timezone_data$TimeZone$asia__magadan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 3, 24, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__makassar = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Phil = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__manila = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Phil))));
};
var $justinmimbs$timezone_data$TimeZone$asia__nicosia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Cyprus)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 8, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EUAsia))));
};
var $justinmimbs$timezone_data$TimeZone$asia__novokuznetsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__novosibirsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 4, 23, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 6, 24, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__omsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__oral = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__pontianak = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__pyongyang = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 7, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						510,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 4, 4, 1410, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__qatar = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1972, 5, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__qostanay = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2024, 2, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__qyzylorda = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 8, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 9, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 11, 21, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__riyadh = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__sakhalin = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 2, 30, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__samarkand = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_ROK = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1988,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		180,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__seoul = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_ROK))));
};
var $justinmimbs$timezone_data$TimeZone$rules_PRC = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1991,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 11),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1991,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 11),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__shanghai = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_PRC))));
};
var $justinmimbs$timezone_data$TimeZone$asia__singapore = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						450,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 11, 31, 960, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__srednekolymsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Taiwan = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1975,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1975,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__taipei = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Taiwan))));
};
var $justinmimbs$timezone_data$TimeZone$asia__tashkent = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_E_EurAsia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__tbilisi = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_EurAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 8, 25, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_EurAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 9, 27, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 2, 30, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_EurAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2004, 5, 27, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2005, 2, 27, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Iran = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1995,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1999,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1999,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2003,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2003,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2004,
		2004,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2005,
		2005,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2011,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2011,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2012,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2015,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2013,
		2015,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2016,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2016,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		2019,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		2019,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2021,
		2022,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2021,
		2022,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		1440,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__tehran = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						210,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Iran)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 9, 20, 1440, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Iran)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				210,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Iran))));
};
var $justinmimbs$timezone_data$TimeZone$asia__thimphu = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						330,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1987, 9, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__tokyo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__tomsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 4, 1, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 4, 29, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				420,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__ulaanbaatar = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						420,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1978, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mongol))));
};
var $justinmimbs$timezone_data$TimeZone$asia__urumqi = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__ust_nera = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 3, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 8, 13, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__vladivostok = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__yakutsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						480,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						540,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__yangon = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				390,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$asia__yekaterinburg = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						360,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Armenia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$asia__yerevan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1995, 8, 24, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_RussiaAsia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Armenia))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Port = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1986,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1985,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$atlantic__azores = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 2, 28, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Port)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 11, 27, 60, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 5, 17, 60, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__bermuda = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 3, 28, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Canada)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_US))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__canary = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 3, 6, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 8, 28, 60, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__cape_verde = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 10, 25, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-60,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__faroe = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__madeira = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 3, 4, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Port)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 6, 31, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$atlantic__south_georgia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Falk = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1984,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		2000,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 9),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		2000,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2010,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2010,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$atlantic__stanley = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Falk)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 4, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Falk)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1985, 8, 15, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Falk)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 8, 5, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AS = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		2007,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1985,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1990,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1993,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__adelaide = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						570,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				570,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AS))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AQ = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1991,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1992,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__brisbane = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AQ))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AN = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1981,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1985,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1989,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1999,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1995,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2007,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__broken_hill = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						570,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						570,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AN)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				570,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AS))));
};
var $justinmimbs$timezone_data$TimeZone$australia__darwin = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				570,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AW = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1984,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1991,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(17),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2009,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2008,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__eucla = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				525,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AW))));
};
var $justinmimbs$timezone_data$TimeZone$australia__hobart = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AT))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Holiday = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1993,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1993,
		1994,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__lindeman = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AQ)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 6, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Holiday))));
};
var $justinmimbs$timezone_data$TimeZone$rules_LH = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1984,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1985,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1989,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1986,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		120,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1987,
		1999,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1995,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2007,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		30)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__lord_howe = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 2, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						630,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_LH)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1985, 6, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				630,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_LH))));
};
var $justinmimbs$timezone_data$TimeZone$rules_AV = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1985,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1985,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1990,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1987,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1988,
		1999,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1994,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2005,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2007,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2006,
		2006,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		2007,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$australia__melbourne = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AV))));
};
var $justinmimbs$timezone_data$TimeZone$australia__perth = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				480,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AW))));
};
var $justinmimbs$timezone_data$TimeZone$australia__sydney = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AN))));
};
var $justinmimbs$timezone_data$TimeZone$europe__andorra = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1985, 2, 31, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__astrakhan = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Greece = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(11),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		240,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		540,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__athens = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Greece)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__belgrade = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 10, 27, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__berlin = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__brussels = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_E_Eur = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_Romania = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1993,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1991,
		1993,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__bucharest = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Romania)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Romania)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Hungary = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1983,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1983,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__budapest = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Hungary)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Moldova = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__chisinau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 4, 6, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Moldova))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Eire = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		0,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1980,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		120,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1980,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 23),
		120,
		0,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1989,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 23),
		60,
		0,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1995,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		60,
		0,
		-60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		-60)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__dublin = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Eire))));
};
var $justinmimbs$timezone_data$TimeZone$europe__gibraltar = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Finland = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1982,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1982,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__helsinki = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Finland)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1983, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Turkey = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1976,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 31),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		6,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1985,
		1985,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1993,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1986,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1994,
		1994,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1995,
		2006,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		2006,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__istanbul = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Turkey)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1978, 5, 29, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Turkey)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 10, 1, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Turkey)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2007, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 28, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 2, 30, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 2, 31, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 9, 25, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 10, 8, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 8, 7, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__kaliningrad = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__kirov = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__kyiv = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 6, 1, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 8, 29, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 4, 13, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__lisbon = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1976, 8, 26, 60, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Port)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 8, 27, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 2, 31, 60, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_GB_Eire = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1980,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 16),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1980,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 23),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1995,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1989,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 23),
		60,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		1995,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		60,
		0,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__london = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1971, 9, 31, 120, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						0,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_GB_Eire)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				0,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Italy = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1967,
		1969,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1970,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1972,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1971,
		1971,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1972,
		1972,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1979,
		4,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 22),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$rules_Malta = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		0,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1979,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1980,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__malta = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Italy)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1973, 2, 31, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Malta)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__minsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__moscow = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_France = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		60,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		60,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__paris = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_France)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__prague = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Latvia = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1996,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1996,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__riga = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 8, 24, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Latvia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 21, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 1, 29, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2001, 0, 2, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__rome = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Italy)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1980, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__samara = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 8, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 9, 20, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2010, 2, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__saratov = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 11, 4, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$europe__simferopol = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1990, 6, 1, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 20, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 4, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 2, 31, 0, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 9, 27, 180, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 2, 30, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 2, 30, 120, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Bulg = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1982,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 1),
		1380,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		60,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1981,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__sofia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 2, 31, 1380, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Bulg)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 8, 26, 180, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_E_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1997, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__tallinn = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 8, 24, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 8, 22, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 31, 240, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2002, 1, 21, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Albania = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(8),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(5),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1979,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(4),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1981,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		1981,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(3),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(18),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1984,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		0,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__tirane = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Albania)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1984, 6, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__ulyanovsk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 0, 19, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2016, 2, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Austria = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1980,
		1980,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		0,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__vienna = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Austria)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__vilnius = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1989, 2, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 8, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_C_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 2, 29, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 9, 31, 60, 0)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						120,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2003, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				120,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__volgograd = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1991, 2, 31, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1992, 2, 29, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Russia)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 2, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 9, 26, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						180,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2018, 9, 28, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						240,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2020, 11, 27, 120, 1))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				180,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_W_Eur = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1980,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1995,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1981,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		60,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$europe__warsaw = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1977, 0, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_W_Eur)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1988, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$europe__zurich = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						60,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1981, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				60,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_EU))));
};
var $justinmimbs$timezone_data$TimeZone$indian__chagos = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1996, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				360,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$indian__maldives = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				300,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Mauritius = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1982,
		1982,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(10),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1983,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(21),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		2008,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$indian__mauritius = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				240,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Mauritius))));
};
var $justinmimbs$timezone_data$TimeZone$rules_WS = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 1),
		240,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(5),
		180,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2021,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		240,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2020,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__apia = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-660,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_WS)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 11, 29, 1440, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				780,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_WS))));
};
var $justinmimbs$timezone_data$TimeZone$rules_NZ = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1988,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1989,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		2006,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		2007,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__auckland = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NZ))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__bougainville = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2014, 11, 28, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Chatham = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		165,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1975,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		165,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1975,
		1988,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		165,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1989,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		165,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1989,
		1989,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		165,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		2006,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		165,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1990,
		2007,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		165,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2007,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		8,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		165,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2008,
		$justinmimbs$timezone_data$TimeZone$maxYear,
		3,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		165,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__chatham = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				765,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chatham))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__easter = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-420,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chile)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1982, 2, 14, 180, 0))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Chile))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Vanuatu = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		720,
		0,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(30),
		720,
		0,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1983,
		1991,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 22),
		1440,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1984,
		1991,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 22),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1993,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 22),
		1440,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1992,
		1992,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 5, 22),
		1440,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__efate = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Vanuatu))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__fakaofo = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2011, 11, 30, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				780,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Fiji = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1998,
		1999,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		2000,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2009,
		2009,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(29),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2010,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2010,
		2013,
		9,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 21),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2011,
		2011,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2012,
		2013,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 18),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2014,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 18),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2014,
		2018,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2015,
		2021,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 12),
		180,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2019,
		2019,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 8),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2020,
		2020,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(20),
		120,
		2,
		60)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__fiji = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Fiji))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__galapagos = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-300,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1986, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-360,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Ecuador))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__gambier = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__guadalcanal = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Guam = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		1,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		5,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1969,
		1969,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(31),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1971,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1970,
		1971,
		8,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1973,
		1973,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(16),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1974,
		1974,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		4,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(26),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1976,
		1976,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(22),
		121,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		3,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(24),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1977,
		7,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(28),
		120,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__guam = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						600,
						$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Guam)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2000, 11, 23, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__honolulu = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__kanton = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 11, 31, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				780,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__kiritimati = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-640,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 9, 1, 0, 2)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-600,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1994, 11, 31, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				840,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__kosrae = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__kwajalein = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-720,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1993, 7, 20, 1440, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__marquesas = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-570,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__nauru = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						690,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1979, 1, 10, 120, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__niue = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__norfolk = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						690,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1974, 9, 27, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						690,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(60)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1975, 2, 2, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						690,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2015, 9, 4, 120, 1)),
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						660,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 2019, 6, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_AN))));
};
var $justinmimbs$timezone_data$TimeZone$rules_NC = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1977,
		1978,
		11,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1979,
		1,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(27),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1996,
		1996,
		11,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(1),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1997,
		1997,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(2),
		120,
		1,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__noumea = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				660,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_NC))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__pago_pago = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-660,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__palau = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				540,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__pitcairn = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-510,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1998, 3, 27, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-480,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__port_moresby = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Cook = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1978,
		1978,
		10,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(12),
		0,
		2,
		30),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1991,
		2,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		0,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1979,
		1990,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		0,
		2,
		30)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__rarotonga = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						-630,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1978, 10, 12, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-600,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Cook))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__tahiti = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				-600,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$pacific__tarawa = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_Nil,
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				720,
				$justinmimbs$timezone_data$TimeZone$Specification$Save(0))));
};
var $justinmimbs$timezone_data$TimeZone$rules_Tonga = _List_fromArray(
	[
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		1999,
		1999,
		9,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(7),
		120,
		1,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2000,
		2,
		$justinmimbs$timezone_data$TimeZone$Specification$Day(19),
		120,
		1,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2000,
		2001,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2001,
		2002,
		0,
		$justinmimbs$timezone_data$TimeZone$Specification$Last(6),
		120,
		2,
		0),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2016,
		2016,
		10,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 1),
		120,
		2,
		60),
		A7(
		$justinmimbs$timezone_data$TimeZone$Specification$Rule,
		2017,
		2017,
		0,
		A2($justinmimbs$timezone_data$TimeZone$Specification$Next, 6, 15),
		180,
		2,
		0)
	]);
var $justinmimbs$timezone_data$TimeZone$pacific__tongatapu = function (_v0) {
	return $justinmimbs$timezone_data$TimeZone$fromSpecification(
		A2(
			$justinmimbs$timezone_data$TimeZone$Specification$Zone,
			_List_fromArray(
				[
					_Utils_Tuple2(
					A2(
						$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
						780,
						$justinmimbs$timezone_data$TimeZone$Specification$Save(0)),
					A5($justinmimbs$timezone_data$TimeZone$Specification$DateTime, 1999, 0, 1, 0, 2))
				]),
			A2(
				$justinmimbs$timezone_data$TimeZone$Specification$ZoneState,
				780,
				$justinmimbs$timezone_data$TimeZone$Specification$Rules($justinmimbs$timezone_data$TimeZone$rules_Tonga))));
};
var $justinmimbs$timezone_data$TimeZone$zones = $elm$core$Dict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2('Africa/Abidjan', $justinmimbs$timezone_data$TimeZone$africa__abidjan),
			_Utils_Tuple2('Africa/Algiers', $justinmimbs$timezone_data$TimeZone$africa__algiers),
			_Utils_Tuple2('Africa/Bissau', $justinmimbs$timezone_data$TimeZone$africa__bissau),
			_Utils_Tuple2('Africa/Cairo', $justinmimbs$timezone_data$TimeZone$africa__cairo),
			_Utils_Tuple2('Africa/Casablanca', $justinmimbs$timezone_data$TimeZone$africa__casablanca),
			_Utils_Tuple2('Africa/Ceuta', $justinmimbs$timezone_data$TimeZone$africa__ceuta),
			_Utils_Tuple2('Africa/El_Aaiun', $justinmimbs$timezone_data$TimeZone$africa__el_aaiun),
			_Utils_Tuple2('Africa/Johannesburg', $justinmimbs$timezone_data$TimeZone$africa__johannesburg),
			_Utils_Tuple2('Africa/Juba', $justinmimbs$timezone_data$TimeZone$africa__juba),
			_Utils_Tuple2('Africa/Khartoum', $justinmimbs$timezone_data$TimeZone$africa__khartoum),
			_Utils_Tuple2('Africa/Lagos', $justinmimbs$timezone_data$TimeZone$africa__lagos),
			_Utils_Tuple2('Africa/Maputo', $justinmimbs$timezone_data$TimeZone$africa__maputo),
			_Utils_Tuple2('Africa/Monrovia', $justinmimbs$timezone_data$TimeZone$africa__monrovia),
			_Utils_Tuple2('Africa/Nairobi', $justinmimbs$timezone_data$TimeZone$africa__nairobi),
			_Utils_Tuple2('Africa/Ndjamena', $justinmimbs$timezone_data$TimeZone$africa__ndjamena),
			_Utils_Tuple2('Africa/Sao_Tome', $justinmimbs$timezone_data$TimeZone$africa__sao_tome),
			_Utils_Tuple2('Africa/Tripoli', $justinmimbs$timezone_data$TimeZone$africa__tripoli),
			_Utils_Tuple2('Africa/Tunis', $justinmimbs$timezone_data$TimeZone$africa__tunis),
			_Utils_Tuple2('Africa/Windhoek', $justinmimbs$timezone_data$TimeZone$africa__windhoek),
			_Utils_Tuple2('America/Adak', $justinmimbs$timezone_data$TimeZone$america__adak),
			_Utils_Tuple2('America/Anchorage', $justinmimbs$timezone_data$TimeZone$america__anchorage),
			_Utils_Tuple2('America/Araguaina', $justinmimbs$timezone_data$TimeZone$america__araguaina),
			_Utils_Tuple2('America/Argentina/Buenos_Aires', $justinmimbs$timezone_data$TimeZone$america__argentina__buenos_aires),
			_Utils_Tuple2('America/Argentina/Catamarca', $justinmimbs$timezone_data$TimeZone$america__argentina__catamarca),
			_Utils_Tuple2('America/Argentina/Cordoba', $justinmimbs$timezone_data$TimeZone$america__argentina__cordoba),
			_Utils_Tuple2('America/Argentina/Jujuy', $justinmimbs$timezone_data$TimeZone$america__argentina__jujuy),
			_Utils_Tuple2('America/Argentina/La_Rioja', $justinmimbs$timezone_data$TimeZone$america__argentina__la_rioja),
			_Utils_Tuple2('America/Argentina/Mendoza', $justinmimbs$timezone_data$TimeZone$america__argentina__mendoza),
			_Utils_Tuple2('America/Argentina/Rio_Gallegos', $justinmimbs$timezone_data$TimeZone$america__argentina__rio_gallegos),
			_Utils_Tuple2('America/Argentina/Salta', $justinmimbs$timezone_data$TimeZone$america__argentina__salta),
			_Utils_Tuple2('America/Argentina/San_Juan', $justinmimbs$timezone_data$TimeZone$america__argentina__san_juan),
			_Utils_Tuple2('America/Argentina/San_Luis', $justinmimbs$timezone_data$TimeZone$america__argentina__san_luis),
			_Utils_Tuple2('America/Argentina/Tucuman', $justinmimbs$timezone_data$TimeZone$america__argentina__tucuman),
			_Utils_Tuple2('America/Argentina/Ushuaia', $justinmimbs$timezone_data$TimeZone$america__argentina__ushuaia),
			_Utils_Tuple2('America/Asuncion', $justinmimbs$timezone_data$TimeZone$america__asuncion),
			_Utils_Tuple2('America/Bahia', $justinmimbs$timezone_data$TimeZone$america__bahia),
			_Utils_Tuple2('America/Bahia_Banderas', $justinmimbs$timezone_data$TimeZone$america__bahia_banderas),
			_Utils_Tuple2('America/Barbados', $justinmimbs$timezone_data$TimeZone$america__barbados),
			_Utils_Tuple2('America/Belem', $justinmimbs$timezone_data$TimeZone$america__belem),
			_Utils_Tuple2('America/Belize', $justinmimbs$timezone_data$TimeZone$america__belize),
			_Utils_Tuple2('America/Boa_Vista', $justinmimbs$timezone_data$TimeZone$america__boa_vista),
			_Utils_Tuple2('America/Bogota', $justinmimbs$timezone_data$TimeZone$america__bogota),
			_Utils_Tuple2('America/Boise', $justinmimbs$timezone_data$TimeZone$america__boise),
			_Utils_Tuple2('America/Cambridge_Bay', $justinmimbs$timezone_data$TimeZone$america__cambridge_bay),
			_Utils_Tuple2('America/Campo_Grande', $justinmimbs$timezone_data$TimeZone$america__campo_grande),
			_Utils_Tuple2('America/Cancun', $justinmimbs$timezone_data$TimeZone$america__cancun),
			_Utils_Tuple2('America/Caracas', $justinmimbs$timezone_data$TimeZone$america__caracas),
			_Utils_Tuple2('America/Cayenne', $justinmimbs$timezone_data$TimeZone$america__cayenne),
			_Utils_Tuple2('America/Chicago', $justinmimbs$timezone_data$TimeZone$america__chicago),
			_Utils_Tuple2('America/Chihuahua', $justinmimbs$timezone_data$TimeZone$america__chihuahua),
			_Utils_Tuple2('America/Ciudad_Juarez', $justinmimbs$timezone_data$TimeZone$america__ciudad_juarez),
			_Utils_Tuple2('America/Costa_Rica', $justinmimbs$timezone_data$TimeZone$america__costa_rica),
			_Utils_Tuple2('America/Cuiaba', $justinmimbs$timezone_data$TimeZone$america__cuiaba),
			_Utils_Tuple2('America/Danmarkshavn', $justinmimbs$timezone_data$TimeZone$america__danmarkshavn),
			_Utils_Tuple2('America/Dawson', $justinmimbs$timezone_data$TimeZone$america__dawson),
			_Utils_Tuple2('America/Dawson_Creek', $justinmimbs$timezone_data$TimeZone$america__dawson_creek),
			_Utils_Tuple2('America/Denver', $justinmimbs$timezone_data$TimeZone$america__denver),
			_Utils_Tuple2('America/Detroit', $justinmimbs$timezone_data$TimeZone$america__detroit),
			_Utils_Tuple2('America/Edmonton', $justinmimbs$timezone_data$TimeZone$america__edmonton),
			_Utils_Tuple2('America/Eirunepe', $justinmimbs$timezone_data$TimeZone$america__eirunepe),
			_Utils_Tuple2('America/El_Salvador', $justinmimbs$timezone_data$TimeZone$america__el_salvador),
			_Utils_Tuple2('America/Fort_Nelson', $justinmimbs$timezone_data$TimeZone$america__fort_nelson),
			_Utils_Tuple2('America/Fortaleza', $justinmimbs$timezone_data$TimeZone$america__fortaleza),
			_Utils_Tuple2('America/Glace_Bay', $justinmimbs$timezone_data$TimeZone$america__glace_bay),
			_Utils_Tuple2('America/Goose_Bay', $justinmimbs$timezone_data$TimeZone$america__goose_bay),
			_Utils_Tuple2('America/Grand_Turk', $justinmimbs$timezone_data$TimeZone$america__grand_turk),
			_Utils_Tuple2('America/Guatemala', $justinmimbs$timezone_data$TimeZone$america__guatemala),
			_Utils_Tuple2('America/Guayaquil', $justinmimbs$timezone_data$TimeZone$america__guayaquil),
			_Utils_Tuple2('America/Guyana', $justinmimbs$timezone_data$TimeZone$america__guyana),
			_Utils_Tuple2('America/Halifax', $justinmimbs$timezone_data$TimeZone$america__halifax),
			_Utils_Tuple2('America/Havana', $justinmimbs$timezone_data$TimeZone$america__havana),
			_Utils_Tuple2('America/Hermosillo', $justinmimbs$timezone_data$TimeZone$america__hermosillo),
			_Utils_Tuple2('America/Indiana/Indianapolis', $justinmimbs$timezone_data$TimeZone$america__indiana__indianapolis),
			_Utils_Tuple2('America/Indiana/Knox', $justinmimbs$timezone_data$TimeZone$america__indiana__knox),
			_Utils_Tuple2('America/Indiana/Marengo', $justinmimbs$timezone_data$TimeZone$america__indiana__marengo),
			_Utils_Tuple2('America/Indiana/Petersburg', $justinmimbs$timezone_data$TimeZone$america__indiana__petersburg),
			_Utils_Tuple2('America/Indiana/Tell_City', $justinmimbs$timezone_data$TimeZone$america__indiana__tell_city),
			_Utils_Tuple2('America/Indiana/Vevay', $justinmimbs$timezone_data$TimeZone$america__indiana__vevay),
			_Utils_Tuple2('America/Indiana/Vincennes', $justinmimbs$timezone_data$TimeZone$america__indiana__vincennes),
			_Utils_Tuple2('America/Indiana/Winamac', $justinmimbs$timezone_data$TimeZone$america__indiana__winamac),
			_Utils_Tuple2('America/Inuvik', $justinmimbs$timezone_data$TimeZone$america__inuvik),
			_Utils_Tuple2('America/Iqaluit', $justinmimbs$timezone_data$TimeZone$america__iqaluit),
			_Utils_Tuple2('America/Jamaica', $justinmimbs$timezone_data$TimeZone$america__jamaica),
			_Utils_Tuple2('America/Juneau', $justinmimbs$timezone_data$TimeZone$america__juneau),
			_Utils_Tuple2('America/Kentucky/Louisville', $justinmimbs$timezone_data$TimeZone$america__kentucky__louisville),
			_Utils_Tuple2('America/Kentucky/Monticello', $justinmimbs$timezone_data$TimeZone$america__kentucky__monticello),
			_Utils_Tuple2('America/La_Paz', $justinmimbs$timezone_data$TimeZone$america__la_paz),
			_Utils_Tuple2('America/Lima', $justinmimbs$timezone_data$TimeZone$america__lima),
			_Utils_Tuple2('America/Los_Angeles', $justinmimbs$timezone_data$TimeZone$america__los_angeles),
			_Utils_Tuple2('America/Maceio', $justinmimbs$timezone_data$TimeZone$america__maceio),
			_Utils_Tuple2('America/Managua', $justinmimbs$timezone_data$TimeZone$america__managua),
			_Utils_Tuple2('America/Manaus', $justinmimbs$timezone_data$TimeZone$america__manaus),
			_Utils_Tuple2('America/Martinique', $justinmimbs$timezone_data$TimeZone$america__martinique),
			_Utils_Tuple2('America/Matamoros', $justinmimbs$timezone_data$TimeZone$america__matamoros),
			_Utils_Tuple2('America/Mazatlan', $justinmimbs$timezone_data$TimeZone$america__mazatlan),
			_Utils_Tuple2('America/Menominee', $justinmimbs$timezone_data$TimeZone$america__menominee),
			_Utils_Tuple2('America/Merida', $justinmimbs$timezone_data$TimeZone$america__merida),
			_Utils_Tuple2('America/Metlakatla', $justinmimbs$timezone_data$TimeZone$america__metlakatla),
			_Utils_Tuple2('America/Mexico_City', $justinmimbs$timezone_data$TimeZone$america__mexico_city),
			_Utils_Tuple2('America/Miquelon', $justinmimbs$timezone_data$TimeZone$america__miquelon),
			_Utils_Tuple2('America/Moncton', $justinmimbs$timezone_data$TimeZone$america__moncton),
			_Utils_Tuple2('America/Monterrey', $justinmimbs$timezone_data$TimeZone$america__monterrey),
			_Utils_Tuple2('America/Montevideo', $justinmimbs$timezone_data$TimeZone$america__montevideo),
			_Utils_Tuple2('America/New_York', $justinmimbs$timezone_data$TimeZone$america__new_york),
			_Utils_Tuple2('America/Nome', $justinmimbs$timezone_data$TimeZone$america__nome),
			_Utils_Tuple2('America/Noronha', $justinmimbs$timezone_data$TimeZone$america__noronha),
			_Utils_Tuple2('America/North_Dakota/Beulah', $justinmimbs$timezone_data$TimeZone$america__north_dakota__beulah),
			_Utils_Tuple2('America/North_Dakota/Center', $justinmimbs$timezone_data$TimeZone$america__north_dakota__center),
			_Utils_Tuple2('America/North_Dakota/New_Salem', $justinmimbs$timezone_data$TimeZone$america__north_dakota__new_salem),
			_Utils_Tuple2('America/Nuuk', $justinmimbs$timezone_data$TimeZone$america__nuuk),
			_Utils_Tuple2('America/Ojinaga', $justinmimbs$timezone_data$TimeZone$america__ojinaga),
			_Utils_Tuple2('America/Panama', $justinmimbs$timezone_data$TimeZone$america__panama),
			_Utils_Tuple2('America/Paramaribo', $justinmimbs$timezone_data$TimeZone$america__paramaribo),
			_Utils_Tuple2('America/Phoenix', $justinmimbs$timezone_data$TimeZone$america__phoenix),
			_Utils_Tuple2('America/Port-au-Prince', $justinmimbs$timezone_data$TimeZone$america__port_au_prince),
			_Utils_Tuple2('America/Porto_Velho', $justinmimbs$timezone_data$TimeZone$america__porto_velho),
			_Utils_Tuple2('America/Puerto_Rico', $justinmimbs$timezone_data$TimeZone$america__puerto_rico),
			_Utils_Tuple2('America/Punta_Arenas', $justinmimbs$timezone_data$TimeZone$america__punta_arenas),
			_Utils_Tuple2('America/Rankin_Inlet', $justinmimbs$timezone_data$TimeZone$america__rankin_inlet),
			_Utils_Tuple2('America/Recife', $justinmimbs$timezone_data$TimeZone$america__recife),
			_Utils_Tuple2('America/Regina', $justinmimbs$timezone_data$TimeZone$america__regina),
			_Utils_Tuple2('America/Resolute', $justinmimbs$timezone_data$TimeZone$america__resolute),
			_Utils_Tuple2('America/Rio_Branco', $justinmimbs$timezone_data$TimeZone$america__rio_branco),
			_Utils_Tuple2('America/Santarem', $justinmimbs$timezone_data$TimeZone$america__santarem),
			_Utils_Tuple2('America/Santiago', $justinmimbs$timezone_data$TimeZone$america__santiago),
			_Utils_Tuple2('America/Santo_Domingo', $justinmimbs$timezone_data$TimeZone$america__santo_domingo),
			_Utils_Tuple2('America/Sao_Paulo', $justinmimbs$timezone_data$TimeZone$america__sao_paulo),
			_Utils_Tuple2('America/Scoresbysund', $justinmimbs$timezone_data$TimeZone$america__scoresbysund),
			_Utils_Tuple2('America/Sitka', $justinmimbs$timezone_data$TimeZone$america__sitka),
			_Utils_Tuple2('America/St_Johns', $justinmimbs$timezone_data$TimeZone$america__st_johns),
			_Utils_Tuple2('America/Swift_Current', $justinmimbs$timezone_data$TimeZone$america__swift_current),
			_Utils_Tuple2('America/Tegucigalpa', $justinmimbs$timezone_data$TimeZone$america__tegucigalpa),
			_Utils_Tuple2('America/Thule', $justinmimbs$timezone_data$TimeZone$america__thule),
			_Utils_Tuple2('America/Tijuana', $justinmimbs$timezone_data$TimeZone$america__tijuana),
			_Utils_Tuple2('America/Toronto', $justinmimbs$timezone_data$TimeZone$america__toronto),
			_Utils_Tuple2('America/Vancouver', $justinmimbs$timezone_data$TimeZone$america__vancouver),
			_Utils_Tuple2('America/Whitehorse', $justinmimbs$timezone_data$TimeZone$america__whitehorse),
			_Utils_Tuple2('America/Winnipeg', $justinmimbs$timezone_data$TimeZone$america__winnipeg),
			_Utils_Tuple2('America/Yakutat', $justinmimbs$timezone_data$TimeZone$america__yakutat),
			_Utils_Tuple2('Antarctica/Casey', $justinmimbs$timezone_data$TimeZone$antarctica__casey),
			_Utils_Tuple2('Antarctica/Davis', $justinmimbs$timezone_data$TimeZone$antarctica__davis),
			_Utils_Tuple2('Antarctica/Macquarie', $justinmimbs$timezone_data$TimeZone$antarctica__macquarie),
			_Utils_Tuple2('Antarctica/Mawson', $justinmimbs$timezone_data$TimeZone$antarctica__mawson),
			_Utils_Tuple2('Antarctica/Palmer', $justinmimbs$timezone_data$TimeZone$antarctica__palmer),
			_Utils_Tuple2('Antarctica/Rothera', $justinmimbs$timezone_data$TimeZone$antarctica__rothera),
			_Utils_Tuple2('Antarctica/Troll', $justinmimbs$timezone_data$TimeZone$antarctica__troll),
			_Utils_Tuple2('Antarctica/Vostok', $justinmimbs$timezone_data$TimeZone$antarctica__vostok),
			_Utils_Tuple2('Asia/Almaty', $justinmimbs$timezone_data$TimeZone$asia__almaty),
			_Utils_Tuple2('Asia/Amman', $justinmimbs$timezone_data$TimeZone$asia__amman),
			_Utils_Tuple2('Asia/Anadyr', $justinmimbs$timezone_data$TimeZone$asia__anadyr),
			_Utils_Tuple2('Asia/Aqtau', $justinmimbs$timezone_data$TimeZone$asia__aqtau),
			_Utils_Tuple2('Asia/Aqtobe', $justinmimbs$timezone_data$TimeZone$asia__aqtobe),
			_Utils_Tuple2('Asia/Ashgabat', $justinmimbs$timezone_data$TimeZone$asia__ashgabat),
			_Utils_Tuple2('Asia/Atyrau', $justinmimbs$timezone_data$TimeZone$asia__atyrau),
			_Utils_Tuple2('Asia/Baghdad', $justinmimbs$timezone_data$TimeZone$asia__baghdad),
			_Utils_Tuple2('Asia/Baku', $justinmimbs$timezone_data$TimeZone$asia__baku),
			_Utils_Tuple2('Asia/Bangkok', $justinmimbs$timezone_data$TimeZone$asia__bangkok),
			_Utils_Tuple2('Asia/Barnaul', $justinmimbs$timezone_data$TimeZone$asia__barnaul),
			_Utils_Tuple2('Asia/Beirut', $justinmimbs$timezone_data$TimeZone$asia__beirut),
			_Utils_Tuple2('Asia/Bishkek', $justinmimbs$timezone_data$TimeZone$asia__bishkek),
			_Utils_Tuple2('Asia/Chita', $justinmimbs$timezone_data$TimeZone$asia__chita),
			_Utils_Tuple2('Asia/Colombo', $justinmimbs$timezone_data$TimeZone$asia__colombo),
			_Utils_Tuple2('Asia/Damascus', $justinmimbs$timezone_data$TimeZone$asia__damascus),
			_Utils_Tuple2('Asia/Dhaka', $justinmimbs$timezone_data$TimeZone$asia__dhaka),
			_Utils_Tuple2('Asia/Dili', $justinmimbs$timezone_data$TimeZone$asia__dili),
			_Utils_Tuple2('Asia/Dubai', $justinmimbs$timezone_data$TimeZone$asia__dubai),
			_Utils_Tuple2('Asia/Dushanbe', $justinmimbs$timezone_data$TimeZone$asia__dushanbe),
			_Utils_Tuple2('Asia/Famagusta', $justinmimbs$timezone_data$TimeZone$asia__famagusta),
			_Utils_Tuple2('Asia/Gaza', $justinmimbs$timezone_data$TimeZone$asia__gaza),
			_Utils_Tuple2('Asia/Hebron', $justinmimbs$timezone_data$TimeZone$asia__hebron),
			_Utils_Tuple2('Asia/Ho_Chi_Minh', $justinmimbs$timezone_data$TimeZone$asia__ho_chi_minh),
			_Utils_Tuple2('Asia/Hong_Kong', $justinmimbs$timezone_data$TimeZone$asia__hong_kong),
			_Utils_Tuple2('Asia/Hovd', $justinmimbs$timezone_data$TimeZone$asia__hovd),
			_Utils_Tuple2('Asia/Irkutsk', $justinmimbs$timezone_data$TimeZone$asia__irkutsk),
			_Utils_Tuple2('Asia/Jakarta', $justinmimbs$timezone_data$TimeZone$asia__jakarta),
			_Utils_Tuple2('Asia/Jayapura', $justinmimbs$timezone_data$TimeZone$asia__jayapura),
			_Utils_Tuple2('Asia/Jerusalem', $justinmimbs$timezone_data$TimeZone$asia__jerusalem),
			_Utils_Tuple2('Asia/Kabul', $justinmimbs$timezone_data$TimeZone$asia__kabul),
			_Utils_Tuple2('Asia/Kamchatka', $justinmimbs$timezone_data$TimeZone$asia__kamchatka),
			_Utils_Tuple2('Asia/Karachi', $justinmimbs$timezone_data$TimeZone$asia__karachi),
			_Utils_Tuple2('Asia/Kathmandu', $justinmimbs$timezone_data$TimeZone$asia__kathmandu),
			_Utils_Tuple2('Asia/Khandyga', $justinmimbs$timezone_data$TimeZone$asia__khandyga),
			_Utils_Tuple2('Asia/Kolkata', $justinmimbs$timezone_data$TimeZone$asia__kolkata),
			_Utils_Tuple2('Asia/Krasnoyarsk', $justinmimbs$timezone_data$TimeZone$asia__krasnoyarsk),
			_Utils_Tuple2('Asia/Kuching', $justinmimbs$timezone_data$TimeZone$asia__kuching),
			_Utils_Tuple2('Asia/Macau', $justinmimbs$timezone_data$TimeZone$asia__macau),
			_Utils_Tuple2('Asia/Magadan', $justinmimbs$timezone_data$TimeZone$asia__magadan),
			_Utils_Tuple2('Asia/Makassar', $justinmimbs$timezone_data$TimeZone$asia__makassar),
			_Utils_Tuple2('Asia/Manila', $justinmimbs$timezone_data$TimeZone$asia__manila),
			_Utils_Tuple2('Asia/Nicosia', $justinmimbs$timezone_data$TimeZone$asia__nicosia),
			_Utils_Tuple2('Asia/Novokuznetsk', $justinmimbs$timezone_data$TimeZone$asia__novokuznetsk),
			_Utils_Tuple2('Asia/Novosibirsk', $justinmimbs$timezone_data$TimeZone$asia__novosibirsk),
			_Utils_Tuple2('Asia/Omsk', $justinmimbs$timezone_data$TimeZone$asia__omsk),
			_Utils_Tuple2('Asia/Oral', $justinmimbs$timezone_data$TimeZone$asia__oral),
			_Utils_Tuple2('Asia/Pontianak', $justinmimbs$timezone_data$TimeZone$asia__pontianak),
			_Utils_Tuple2('Asia/Pyongyang', $justinmimbs$timezone_data$TimeZone$asia__pyongyang),
			_Utils_Tuple2('Asia/Qatar', $justinmimbs$timezone_data$TimeZone$asia__qatar),
			_Utils_Tuple2('Asia/Qostanay', $justinmimbs$timezone_data$TimeZone$asia__qostanay),
			_Utils_Tuple2('Asia/Qyzylorda', $justinmimbs$timezone_data$TimeZone$asia__qyzylorda),
			_Utils_Tuple2('Asia/Riyadh', $justinmimbs$timezone_data$TimeZone$asia__riyadh),
			_Utils_Tuple2('Asia/Sakhalin', $justinmimbs$timezone_data$TimeZone$asia__sakhalin),
			_Utils_Tuple2('Asia/Samarkand', $justinmimbs$timezone_data$TimeZone$asia__samarkand),
			_Utils_Tuple2('Asia/Seoul', $justinmimbs$timezone_data$TimeZone$asia__seoul),
			_Utils_Tuple2('Asia/Shanghai', $justinmimbs$timezone_data$TimeZone$asia__shanghai),
			_Utils_Tuple2('Asia/Singapore', $justinmimbs$timezone_data$TimeZone$asia__singapore),
			_Utils_Tuple2('Asia/Srednekolymsk', $justinmimbs$timezone_data$TimeZone$asia__srednekolymsk),
			_Utils_Tuple2('Asia/Taipei', $justinmimbs$timezone_data$TimeZone$asia__taipei),
			_Utils_Tuple2('Asia/Tashkent', $justinmimbs$timezone_data$TimeZone$asia__tashkent),
			_Utils_Tuple2('Asia/Tbilisi', $justinmimbs$timezone_data$TimeZone$asia__tbilisi),
			_Utils_Tuple2('Asia/Tehran', $justinmimbs$timezone_data$TimeZone$asia__tehran),
			_Utils_Tuple2('Asia/Thimphu', $justinmimbs$timezone_data$TimeZone$asia__thimphu),
			_Utils_Tuple2('Asia/Tokyo', $justinmimbs$timezone_data$TimeZone$asia__tokyo),
			_Utils_Tuple2('Asia/Tomsk', $justinmimbs$timezone_data$TimeZone$asia__tomsk),
			_Utils_Tuple2('Asia/Ulaanbaatar', $justinmimbs$timezone_data$TimeZone$asia__ulaanbaatar),
			_Utils_Tuple2('Asia/Urumqi', $justinmimbs$timezone_data$TimeZone$asia__urumqi),
			_Utils_Tuple2('Asia/Ust-Nera', $justinmimbs$timezone_data$TimeZone$asia__ust_nera),
			_Utils_Tuple2('Asia/Vladivostok', $justinmimbs$timezone_data$TimeZone$asia__vladivostok),
			_Utils_Tuple2('Asia/Yakutsk', $justinmimbs$timezone_data$TimeZone$asia__yakutsk),
			_Utils_Tuple2('Asia/Yangon', $justinmimbs$timezone_data$TimeZone$asia__yangon),
			_Utils_Tuple2('Asia/Yekaterinburg', $justinmimbs$timezone_data$TimeZone$asia__yekaterinburg),
			_Utils_Tuple2('Asia/Yerevan', $justinmimbs$timezone_data$TimeZone$asia__yerevan),
			_Utils_Tuple2('Atlantic/Azores', $justinmimbs$timezone_data$TimeZone$atlantic__azores),
			_Utils_Tuple2('Atlantic/Bermuda', $justinmimbs$timezone_data$TimeZone$atlantic__bermuda),
			_Utils_Tuple2('Atlantic/Canary', $justinmimbs$timezone_data$TimeZone$atlantic__canary),
			_Utils_Tuple2('Atlantic/Cape_Verde', $justinmimbs$timezone_data$TimeZone$atlantic__cape_verde),
			_Utils_Tuple2('Atlantic/Faroe', $justinmimbs$timezone_data$TimeZone$atlantic__faroe),
			_Utils_Tuple2('Atlantic/Madeira', $justinmimbs$timezone_data$TimeZone$atlantic__madeira),
			_Utils_Tuple2('Atlantic/South_Georgia', $justinmimbs$timezone_data$TimeZone$atlantic__south_georgia),
			_Utils_Tuple2('Atlantic/Stanley', $justinmimbs$timezone_data$TimeZone$atlantic__stanley),
			_Utils_Tuple2('Australia/Adelaide', $justinmimbs$timezone_data$TimeZone$australia__adelaide),
			_Utils_Tuple2('Australia/Brisbane', $justinmimbs$timezone_data$TimeZone$australia__brisbane),
			_Utils_Tuple2('Australia/Broken_Hill', $justinmimbs$timezone_data$TimeZone$australia__broken_hill),
			_Utils_Tuple2('Australia/Darwin', $justinmimbs$timezone_data$TimeZone$australia__darwin),
			_Utils_Tuple2('Australia/Eucla', $justinmimbs$timezone_data$TimeZone$australia__eucla),
			_Utils_Tuple2('Australia/Hobart', $justinmimbs$timezone_data$TimeZone$australia__hobart),
			_Utils_Tuple2('Australia/Lindeman', $justinmimbs$timezone_data$TimeZone$australia__lindeman),
			_Utils_Tuple2('Australia/Lord_Howe', $justinmimbs$timezone_data$TimeZone$australia__lord_howe),
			_Utils_Tuple2('Australia/Melbourne', $justinmimbs$timezone_data$TimeZone$australia__melbourne),
			_Utils_Tuple2('Australia/Perth', $justinmimbs$timezone_data$TimeZone$australia__perth),
			_Utils_Tuple2('Australia/Sydney', $justinmimbs$timezone_data$TimeZone$australia__sydney),
			_Utils_Tuple2('Europe/Andorra', $justinmimbs$timezone_data$TimeZone$europe__andorra),
			_Utils_Tuple2('Europe/Astrakhan', $justinmimbs$timezone_data$TimeZone$europe__astrakhan),
			_Utils_Tuple2('Europe/Athens', $justinmimbs$timezone_data$TimeZone$europe__athens),
			_Utils_Tuple2('Europe/Belgrade', $justinmimbs$timezone_data$TimeZone$europe__belgrade),
			_Utils_Tuple2('Europe/Berlin', $justinmimbs$timezone_data$TimeZone$europe__berlin),
			_Utils_Tuple2('Europe/Brussels', $justinmimbs$timezone_data$TimeZone$europe__brussels),
			_Utils_Tuple2('Europe/Bucharest', $justinmimbs$timezone_data$TimeZone$europe__bucharest),
			_Utils_Tuple2('Europe/Budapest', $justinmimbs$timezone_data$TimeZone$europe__budapest),
			_Utils_Tuple2('Europe/Chisinau', $justinmimbs$timezone_data$TimeZone$europe__chisinau),
			_Utils_Tuple2('Europe/Dublin', $justinmimbs$timezone_data$TimeZone$europe__dublin),
			_Utils_Tuple2('Europe/Gibraltar', $justinmimbs$timezone_data$TimeZone$europe__gibraltar),
			_Utils_Tuple2('Europe/Helsinki', $justinmimbs$timezone_data$TimeZone$europe__helsinki),
			_Utils_Tuple2('Europe/Istanbul', $justinmimbs$timezone_data$TimeZone$europe__istanbul),
			_Utils_Tuple2('Europe/Kaliningrad', $justinmimbs$timezone_data$TimeZone$europe__kaliningrad),
			_Utils_Tuple2('Europe/Kirov', $justinmimbs$timezone_data$TimeZone$europe__kirov),
			_Utils_Tuple2('Europe/Kyiv', $justinmimbs$timezone_data$TimeZone$europe__kyiv),
			_Utils_Tuple2('Europe/Lisbon', $justinmimbs$timezone_data$TimeZone$europe__lisbon),
			_Utils_Tuple2('Europe/London', $justinmimbs$timezone_data$TimeZone$europe__london),
			_Utils_Tuple2('Europe/Madrid', $justinmimbs$timezone_data$TimeZone$europe__madrid),
			_Utils_Tuple2('Europe/Malta', $justinmimbs$timezone_data$TimeZone$europe__malta),
			_Utils_Tuple2('Europe/Minsk', $justinmimbs$timezone_data$TimeZone$europe__minsk),
			_Utils_Tuple2('Europe/Moscow', $justinmimbs$timezone_data$TimeZone$europe__moscow),
			_Utils_Tuple2('Europe/Paris', $justinmimbs$timezone_data$TimeZone$europe__paris),
			_Utils_Tuple2('Europe/Prague', $justinmimbs$timezone_data$TimeZone$europe__prague),
			_Utils_Tuple2('Europe/Riga', $justinmimbs$timezone_data$TimeZone$europe__riga),
			_Utils_Tuple2('Europe/Rome', $justinmimbs$timezone_data$TimeZone$europe__rome),
			_Utils_Tuple2('Europe/Samara', $justinmimbs$timezone_data$TimeZone$europe__samara),
			_Utils_Tuple2('Europe/Saratov', $justinmimbs$timezone_data$TimeZone$europe__saratov),
			_Utils_Tuple2('Europe/Simferopol', $justinmimbs$timezone_data$TimeZone$europe__simferopol),
			_Utils_Tuple2('Europe/Sofia', $justinmimbs$timezone_data$TimeZone$europe__sofia),
			_Utils_Tuple2('Europe/Tallinn', $justinmimbs$timezone_data$TimeZone$europe__tallinn),
			_Utils_Tuple2('Europe/Tirane', $justinmimbs$timezone_data$TimeZone$europe__tirane),
			_Utils_Tuple2('Europe/Ulyanovsk', $justinmimbs$timezone_data$TimeZone$europe__ulyanovsk),
			_Utils_Tuple2('Europe/Vienna', $justinmimbs$timezone_data$TimeZone$europe__vienna),
			_Utils_Tuple2('Europe/Vilnius', $justinmimbs$timezone_data$TimeZone$europe__vilnius),
			_Utils_Tuple2('Europe/Volgograd', $justinmimbs$timezone_data$TimeZone$europe__volgograd),
			_Utils_Tuple2('Europe/Warsaw', $justinmimbs$timezone_data$TimeZone$europe__warsaw),
			_Utils_Tuple2('Europe/Zurich', $justinmimbs$timezone_data$TimeZone$europe__zurich),
			_Utils_Tuple2('Indian/Chagos', $justinmimbs$timezone_data$TimeZone$indian__chagos),
			_Utils_Tuple2('Indian/Maldives', $justinmimbs$timezone_data$TimeZone$indian__maldives),
			_Utils_Tuple2('Indian/Mauritius', $justinmimbs$timezone_data$TimeZone$indian__mauritius),
			_Utils_Tuple2('Pacific/Apia', $justinmimbs$timezone_data$TimeZone$pacific__apia),
			_Utils_Tuple2('Pacific/Auckland', $justinmimbs$timezone_data$TimeZone$pacific__auckland),
			_Utils_Tuple2('Pacific/Bougainville', $justinmimbs$timezone_data$TimeZone$pacific__bougainville),
			_Utils_Tuple2('Pacific/Chatham', $justinmimbs$timezone_data$TimeZone$pacific__chatham),
			_Utils_Tuple2('Pacific/Easter', $justinmimbs$timezone_data$TimeZone$pacific__easter),
			_Utils_Tuple2('Pacific/Efate', $justinmimbs$timezone_data$TimeZone$pacific__efate),
			_Utils_Tuple2('Pacific/Fakaofo', $justinmimbs$timezone_data$TimeZone$pacific__fakaofo),
			_Utils_Tuple2('Pacific/Fiji', $justinmimbs$timezone_data$TimeZone$pacific__fiji),
			_Utils_Tuple2('Pacific/Galapagos', $justinmimbs$timezone_data$TimeZone$pacific__galapagos),
			_Utils_Tuple2('Pacific/Gambier', $justinmimbs$timezone_data$TimeZone$pacific__gambier),
			_Utils_Tuple2('Pacific/Guadalcanal', $justinmimbs$timezone_data$TimeZone$pacific__guadalcanal),
			_Utils_Tuple2('Pacific/Guam', $justinmimbs$timezone_data$TimeZone$pacific__guam),
			_Utils_Tuple2('Pacific/Honolulu', $justinmimbs$timezone_data$TimeZone$pacific__honolulu),
			_Utils_Tuple2('Pacific/Kanton', $justinmimbs$timezone_data$TimeZone$pacific__kanton),
			_Utils_Tuple2('Pacific/Kiritimati', $justinmimbs$timezone_data$TimeZone$pacific__kiritimati),
			_Utils_Tuple2('Pacific/Kosrae', $justinmimbs$timezone_data$TimeZone$pacific__kosrae),
			_Utils_Tuple2('Pacific/Kwajalein', $justinmimbs$timezone_data$TimeZone$pacific__kwajalein),
			_Utils_Tuple2('Pacific/Marquesas', $justinmimbs$timezone_data$TimeZone$pacific__marquesas),
			_Utils_Tuple2('Pacific/Nauru', $justinmimbs$timezone_data$TimeZone$pacific__nauru),
			_Utils_Tuple2('Pacific/Niue', $justinmimbs$timezone_data$TimeZone$pacific__niue),
			_Utils_Tuple2('Pacific/Norfolk', $justinmimbs$timezone_data$TimeZone$pacific__norfolk),
			_Utils_Tuple2('Pacific/Noumea', $justinmimbs$timezone_data$TimeZone$pacific__noumea),
			_Utils_Tuple2('Pacific/Pago_Pago', $justinmimbs$timezone_data$TimeZone$pacific__pago_pago),
			_Utils_Tuple2('Pacific/Palau', $justinmimbs$timezone_data$TimeZone$pacific__palau),
			_Utils_Tuple2('Pacific/Pitcairn', $justinmimbs$timezone_data$TimeZone$pacific__pitcairn),
			_Utils_Tuple2('Pacific/Port_Moresby', $justinmimbs$timezone_data$TimeZone$pacific__port_moresby),
			_Utils_Tuple2('Pacific/Rarotonga', $justinmimbs$timezone_data$TimeZone$pacific__rarotonga),
			_Utils_Tuple2('Pacific/Tahiti', $justinmimbs$timezone_data$TimeZone$pacific__tahiti),
			_Utils_Tuple2('Pacific/Tarawa', $justinmimbs$timezone_data$TimeZone$pacific__tarawa),
			_Utils_Tuple2('Pacific/Tongatapu', $justinmimbs$timezone_data$TimeZone$pacific__tongatapu)
		]));
var $author$project$Main$init = F3(
	function (flags, url, key) {
		var route = $author$project$Route$fromUrl(url);
		var localTimeZone = A2(
			$elm$core$Maybe$withDefault,
			$elm$time$Time$utc,
			A2(
				$elm$core$Maybe$map,
				function (f) {
					return f(0);
				},
				A2($elm$core$Dict$get, flags.cF, $justinmimbs$timezone_data$TimeZone$zones)));
		var languageOverride = $author$project$Main$languageFromUrl(url);
		var language = A2(
			$elm$core$Maybe$withDefault,
			1,
			A2(
				$elm_community$maybe_extra$Maybe$Extra$or,
				languageOverride,
				$author$project$Main$extractPreferredLanguage(flags.gb)));
		var env = A2(
			$author$project$Env$withLogoutPath,
			flags.cH,
			A2(
				$author$project$Env$withLoginPath,
				flags.cG,
				A2(
					$author$project$Env$withLanguageOverride,
					language,
					A2(
						$author$project$Env$withLocalTimeZone,
						localTimeZone,
						A2(
							$author$project$Env$withFunnelUrl,
							flags.cu,
							A2(
								$author$project$Env$withExternalApiUrl,
								flags.cr,
								A2($author$project$Env$withApiUrl, flags.cb + '/graphql', $author$project$Env$env)))))));
		var _v0 = A3(
			$elm$core$Tuple$mapBoth,
			$author$project$Model$Login,
			$author$project$Effect$Login,
			A2(
				$author$project$Page$Login$init,
				$author$project$Env$externalApiUrl(env),
				$elm$core$Maybe$Just(route)));
		var pageModel = _v0.a;
		var checkAuthEffect = _v0.b;
		var model = A4($author$project$Model$init, key, env, pageModel, language);
		return _Utils_Tuple2(
			model,
			A3($author$project$Effect$runEffect, env, model, checkAuthEffect));
	});
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$core$Platform$Sub$map = _Platform_map;
var $elm$core$Platform$Sub$none = $elm$core$Platform$Sub$batch(_List_Nil);
var $author$project$Page$Search$Msg$ToastMsg = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pyxis$Components$Toasts$Tick = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Every = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$State = F2(
	function (taggers, processes) {
		return {jt: processes, j_: taggers};
	});
var $elm$time$Time$init = $elm$core$Task$succeed(
	A2($elm$time$Time$State, $elm$core$Dict$empty, $elm$core$Dict$empty));
var $elm$time$Time$addMySub = F2(
	function (_v0, state) {
		var interval = _v0.a;
		var tagger = _v0.b;
		var _v1 = A2($elm$core$Dict$get, interval, state);
		if (_v1.$ === 1) {
			return A3(
				$elm$core$Dict$insert,
				interval,
				_List_fromArray(
					[tagger]),
				state);
		} else {
			var taggers = _v1.a;
			return A3(
				$elm$core$Dict$insert,
				interval,
				A2($elm$core$List$cons, tagger, taggers),
				state);
		}
	});
var $elm$core$Dict$merge = F6(
	function (leftStep, bothStep, rightStep, leftDict, rightDict, initialResult) {
		var stepState = F3(
			function (rKey, rValue, _v0) {
				stepState:
				while (true) {
					var list = _v0.a;
					var result = _v0.b;
					if (!list.b) {
						return _Utils_Tuple2(
							list,
							A3(rightStep, rKey, rValue, result));
					} else {
						var _v2 = list.a;
						var lKey = _v2.a;
						var lValue = _v2.b;
						var rest = list.b;
						if (_Utils_cmp(lKey, rKey) < 0) {
							var $temp$rKey = rKey,
								$temp$rValue = rValue,
								$temp$_v0 = _Utils_Tuple2(
								rest,
								A3(leftStep, lKey, lValue, result));
							rKey = $temp$rKey;
							rValue = $temp$rValue;
							_v0 = $temp$_v0;
							continue stepState;
						} else {
							if (_Utils_cmp(lKey, rKey) > 0) {
								return _Utils_Tuple2(
									list,
									A3(rightStep, rKey, rValue, result));
							} else {
								return _Utils_Tuple2(
									rest,
									A4(bothStep, lKey, lValue, rValue, result));
							}
						}
					}
				}
			});
		var _v3 = A3(
			$elm$core$Dict$foldl,
			stepState,
			_Utils_Tuple2(
				$elm$core$Dict$toList(leftDict),
				initialResult),
			rightDict);
		var leftovers = _v3.a;
		var intermediateResult = _v3.b;
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v4, result) {
					var k = _v4.a;
					var v = _v4.b;
					return A3(leftStep, k, v, result);
				}),
			intermediateResult,
			leftovers);
	});
var $elm$time$Time$setInterval = _Time_setInterval;
var $elm$time$Time$spawnHelp = F3(
	function (router, intervals, processes) {
		if (!intervals.b) {
			return $elm$core$Task$succeed(processes);
		} else {
			var interval = intervals.a;
			var rest = intervals.b;
			var spawnTimer = $elm$core$Process$spawn(
				A2(
					$elm$time$Time$setInterval,
					interval,
					A2($elm$core$Platform$sendToSelf, router, interval)));
			var spawnRest = function (id) {
				return A3(
					$elm$time$Time$spawnHelp,
					router,
					rest,
					A3($elm$core$Dict$insert, interval, id, processes));
			};
			return A2($elm$core$Task$andThen, spawnRest, spawnTimer);
		}
	});
var $elm$time$Time$onEffects = F3(
	function (router, subs, _v0) {
		var processes = _v0.jt;
		var rightStep = F3(
			function (_v6, id, _v7) {
				var spawns = _v7.a;
				var existing = _v7.b;
				var kills = _v7.c;
				return _Utils_Tuple3(
					spawns,
					existing,
					A2(
						$elm$core$Task$andThen,
						function (_v5) {
							return kills;
						},
						$elm$core$Process$kill(id)));
			});
		var newTaggers = A3($elm$core$List$foldl, $elm$time$Time$addMySub, $elm$core$Dict$empty, subs);
		var leftStep = F3(
			function (interval, taggers, _v4) {
				var spawns = _v4.a;
				var existing = _v4.b;
				var kills = _v4.c;
				return _Utils_Tuple3(
					A2($elm$core$List$cons, interval, spawns),
					existing,
					kills);
			});
		var bothStep = F4(
			function (interval, taggers, id, _v3) {
				var spawns = _v3.a;
				var existing = _v3.b;
				var kills = _v3.c;
				return _Utils_Tuple3(
					spawns,
					A3($elm$core$Dict$insert, interval, id, existing),
					kills);
			});
		var _v1 = A6(
			$elm$core$Dict$merge,
			leftStep,
			bothStep,
			rightStep,
			newTaggers,
			processes,
			_Utils_Tuple3(
				_List_Nil,
				$elm$core$Dict$empty,
				$elm$core$Task$succeed(0)));
		var spawnList = _v1.a;
		var existingDict = _v1.b;
		var killTask = _v1.c;
		return A2(
			$elm$core$Task$andThen,
			function (newProcesses) {
				return $elm$core$Task$succeed(
					A2($elm$time$Time$State, newTaggers, newProcesses));
			},
			A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$time$Time$spawnHelp, router, spawnList, existingDict);
				},
				killTask));
	});
var $elm$time$Time$onSelfMsg = F3(
	function (router, interval, state) {
		var _v0 = A2($elm$core$Dict$get, interval, state.j_);
		if (_v0.$ === 1) {
			return $elm$core$Task$succeed(state);
		} else {
			var taggers = _v0.a;
			var tellTaggers = function (time) {
				return $elm$core$Task$sequence(
					A2(
						$elm$core$List$map,
						function (tagger) {
							return A2(
								$elm$core$Platform$sendToApp,
								router,
								tagger(time));
						},
						taggers));
			};
			return A2(
				$elm$core$Task$andThen,
				function (_v1) {
					return $elm$core$Task$succeed(state);
				},
				A2($elm$core$Task$andThen, tellTaggers, $elm$time$Time$now));
		}
	});
var $elm$time$Time$subMap = F2(
	function (f, _v0) {
		var interval = _v0.a;
		var tagger = _v0.b;
		return A2(
			$elm$time$Time$Every,
			interval,
			A2($elm$core$Basics$composeL, f, tagger));
	});
_Platform_effectManagers['Time'] = _Platform_createManager($elm$time$Time$init, $elm$time$Time$onEffects, $elm$time$Time$onSelfMsg, 0, $elm$time$Time$subMap);
var $elm$time$Time$subscription = _Platform_leaf('Time');
var $elm$time$Time$every = F2(
	function (interval, tagger) {
		return $elm$time$Time$subscription(
			A2($elm$time$Time$Every, interval, tagger));
	});
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$isTimeBlocked = function (timedEl) {
	switch (timedEl.$) {
		case 2:
			return true;
		case 0:
			return false;
		case 1:
			return false;
		default:
			return true;
	}
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$haveAllStopped = function (listTimed) {
	if (!listTimed.b) {
		return true;
	} else {
		var nonEmptylist = listTimed;
		return A2($elm$core$List$all, $author$project$Pyxis$Components$Toasts$ToastsHelpers$isTimeBlocked, nonEmptylist);
	}
};
var $author$project$Pyxis$Components$Toasts$subscription = function (_v0) {
	var model = _v0;
	var listOfTimedToast = A2(
		$elm$core$List$map,
		$elm$core$Tuple$second,
		$elm$core$Dict$toList(model.O));
	var deltaTickAsFloat = $author$project$Pyxis$Components$Toasts$ToastsHelpers$deltaIntervalInMilliseconds;
	return $author$project$Pyxis$Components$Toasts$ToastsHelpers$haveAllStopped(listOfTimedToast) ? $elm$core$Platform$Sub$none : A2(
		$elm$time$Time$every,
		deltaTickAsFloat,
		$elm$core$Basics$always(
			$author$project$Pyxis$Components$Toasts$Tick(deltaTickAsFloat)));
};
var $author$project$Page$Search$subscription = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Page$Search$Msg$ToastMsg,
		$author$project$Pyxis$Components$Toasts$subscription(model.O));
};
var $author$project$Page$OfferDetails$Msg$ToastMsg = function (a) {
	return {$: 13, a: a};
};
var $author$project$Page$OfferDetails$toastCounterSubscription = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Page$OfferDetails$Msg$ToastMsg,
		$author$project$Pyxis$Components$Toasts$subscription(model.O));
};
var $author$project$Page$PolicyDetails$Msg$ToastMsg = function (a) {
	return {$: 43, a: a};
};
var $author$project$Page$PolicyDetails$toastCounterSubscription = function (model) {
	return A2(
		$elm$core$Platform$Sub$map,
		$author$project$Page$PolicyDetails$Msg$ToastMsg,
		$author$project$Pyxis$Components$Toasts$subscription(model.O));
};
var $author$project$Main$subscribeToToastCounter = function (model) {
	var _v0 = model.mX;
	switch (_v0.$) {
		case 3:
			var searchModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Msg$Search,
				$author$project$Page$Search$subscription(searchModel));
		case 6:
			var offerDetailModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Msg$OfferDetails,
				$author$project$Page$OfferDetails$toastCounterSubscription(offerDetailModel));
		case 5:
			var policyDetailsModel = _v0.a;
			return A2(
				$elm$core$Platform$Sub$map,
				$author$project$Msg$PolicyDetails,
				$author$project$Page$PolicyDetails$toastCounterSubscription(policyDetailsModel));
		default:
			return $elm$core$Platform$Sub$none;
	}
};
var $author$project$Page$Payment$Msg$CreditCardReady = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$Payment$Msg$NonceReceive = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Payment$Msg$NotifyErrorEvent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payment$Msg$PaymentEvent = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Payment$Msg$ValidateCreditCardFields = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$Payment$Ports$creditCardReady = _Platform_incomingPort('creditCardReady', $elm$json$Json$Decode$bool);
var $author$project$Page$Payment$Ports$nonceReceived = _Platform_incomingPort('nonceReceived', $elm$json$Json$Decode$string);
var $author$project$Page$Payment$Ports$notifyErrorEvent = _Platform_incomingPort(
	'notifyErrorEvent',
	A2(
		$elm$json$Json$Decode$andThen,
		function (error) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (context) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (code) {
							return $elm$json$Json$Decode$succeed(
								{kE: code, kG: context, ip: error});
						},
						A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'context', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'error', $elm$json$Json$Decode$string)));
var $author$project$Page$Payment$Ports$validateCreditCardFields = _Platform_incomingPort(
	'validateCreditCardFields',
	$elm$json$Json$Decode$list(
		A2(
			$elm$json$Json$Decode$andThen,
			function (isValid) {
				return A2(
					$elm$json$Json$Decode$andThen,
					function (isPristine) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (isFocused) {
								return A2(
									$elm$json$Json$Decode$andThen,
									function (id) {
										return $elm$json$Json$Decode$succeed(
											{iO: id, dV: isFocused, d_: isPristine, by: isValid});
									},
									A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
							},
							A2($elm$json$Json$Decode$field, 'isFocused', $elm$json$Json$Decode$bool));
					},
					A2($elm$json$Json$Decode$field, 'isPristine', $elm$json$Json$Decode$bool));
			},
			A2($elm$json$Json$Decode$field, 'isValid', $elm$json$Json$Decode$bool))));
var $author$project$Page$Payment$Subscriptions$subscriptions = $elm$core$Platform$Sub$batch(
	_List_fromArray(
		[
			$author$project$Page$Payment$Ports$creditCardReady(
			A2($elm$core$Basics$composeR, $author$project$Page$Payment$Msg$CreditCardReady, $author$project$Page$Payment$Msg$PaymentEvent)),
			$author$project$Page$Payment$Ports$notifyErrorEvent(
			A2($elm$core$Basics$composeR, $author$project$Page$Payment$Msg$NotifyErrorEvent, $author$project$Page$Payment$Msg$PaymentEvent)),
			$author$project$Page$Payment$Ports$validateCreditCardFields(
			A2($elm$core$Basics$composeR, $author$project$Page$Payment$Msg$ValidateCreditCardFields, $author$project$Page$Payment$Msg$PaymentEvent)),
			$author$project$Page$Payment$Ports$nonceReceived(
			A2($elm$core$Basics$composeR, $author$project$Page$Payment$Msg$NonceReceive, $author$project$Page$Payment$Msg$PaymentEvent))
		]));
var $author$project$Page$PolicyDetails$Msg$ReceivedIssueRefundReleaseDate = function (a) {
	return {$: 27, a: a};
};
var $author$project$Page$PolicyDetails$Ports$receiveManualRefundCutoff = _Platform_incomingPort('receiveManualRefundCutoff', $elm$json$Json$Decode$int);
var $author$project$Page$PolicyDetails$subscriptions = $author$project$Page$PolicyDetails$Ports$receiveManualRefundCutoff($author$project$Page$PolicyDetails$Msg$ReceivedIssueRefundReleaseDate);
var $author$project$Main$subscriptions = function (model) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				A2($elm$core$Platform$Sub$map, $author$project$Msg$PolicyDetails, $author$project$Page$PolicyDetails$subscriptions),
				A2($elm$core$Platform$Sub$map, $author$project$Msg$Payment, $author$project$Page$Payment$Subscriptions$subscriptions),
				$author$project$Main$subscribeToToastCounter(model)
			]));
};
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $elm$html$Html$main_ = _VirtualDom_node('main');
var $author$project$Msg$Logout = {$: 6};
var $author$project$Msg$NavigationSidebarMsg = function (a) {
	return {$: 12, a: a};
};
var $author$project$Pyxis$Components$NavigationSidebar$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$NavigationSidebar$Content = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$NavigationSidebar$content = {fj: _List_Nil, iO: $elm$core$Maybe$Nothing, gr: $elm$core$Maybe$Nothing, gs: 'Navigation', hk: $elm$core$Maybe$Nothing, hl: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$NavigationSidebar$Header = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$NavigationSidebar$header = {fj: _List_Nil, iO: $elm$core$Maybe$Nothing, gg: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$NavigationSidebar$config = {fj: _List_Nil, fq: $author$project$Pyxis$Components$NavigationSidebar$content, fQ: $elm$core$Maybe$Nothing, f$: $author$project$Pyxis$Components$NavigationSidebar$header, iO: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default};
var $author$project$Pyxis$Components$NavigationSidebar$Default = {$: 0};
var $author$project$Pyxis$Components$NavigationSidebar$Footer = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$NavigationSidebar$UserInfo = F2(
	function (email, name) {
		return {cn: email, i6: name};
	});
var $author$project$Pyxis$Components$NavigationSidebar$footer = F3(
	function (email, name, onLogout) {
		return {
			a0: $elm$core$Maybe$Nothing,
			fj: _List_Nil,
			iO: $elm$core$Maybe$Nothing,
			jh: onLogout,
			eO: $elm$core$Maybe$Nothing,
			hH: $author$project$Pyxis$Components$NavigationSidebar$Default,
			j4: A2($author$project$Pyxis$Components$NavigationSidebar$UserInfo, email, name)
		};
	});
var $author$project$Pyxis$Components$NavigationSidebar$Prima = {$: 0};
var $author$project$Pyxis$Components$NavigationSidebar$primaLogo = $author$project$Pyxis$Components$NavigationSidebar$Prima;
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$html$Html$header = _VirtualDom_node('header');
var $elm$html$Html$Attributes$id = $elm$html$Html$Attributes$stringProperty('id');
var $author$project$Pyxis$Commons$Properties$Theme$Alternative = 1;
var $author$project$Pyxis$Commons$Properties$Theme$isAlternative = $elm$core$Basics$eq(1);
var $author$project$Pyxis$Components$NavigationSidebar$Expanded = 0;
var $author$project$Pyxis$Components$NavigationSidebar$isExpanded = function (_v0) {
	var expandState = _v0.cp;
	return !expandState;
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$property = $elm$virtual_dom$VirtualDom$property;
var $author$project$Pyxis$Commons$Attributes$none = A2($elm$html$Html$Attributes$property, 'none@pyxis-elm', $elm$json$Json$Encode$null);
var $author$project$Pyxis$Commons$Attributes$maybe = function (f) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(f),
		$elm$core$Maybe$withDefault($author$project$Pyxis$Commons$Attributes$none));
};
var $author$project$Pyxis$Components$NavigationSidebar$Collapse = {$: 2};
var $elm$html$Html$button = _VirtualDom_node('button');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pyxis$Components$NavigationSidebar$overlay = function (tagger) {
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-panel__overlay'),
				$elm$html$Html$Events$onClick(
				tagger($author$project$Pyxis$Components$NavigationSidebar$Collapse))
			]),
		_List_Nil);
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $author$project$Pyxis$Commons$Render$empty = $elm$html$Html$text('');
var $author$project$Pyxis$Commons$Render$renderMaybe = $elm$core$Maybe$withDefault($author$project$Pyxis$Commons$Render$empty);
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$nav = _VirtualDom_node('nav');
var $author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey = function (index) {
	return $TSFoster$elm_uuid$UUID$toString(
		A3(
			$primait$prima_elm_extra$PrimaFunction$flip,
			$TSFoster$elm_uuid$UUID$forName,
			$TSFoster$elm_uuid$UUID$urlNamespace,
			$elm$core$String$fromInt(index)));
};
var $author$project$Pyxis$Components$NavigationSidebar$ToggleGroupExpand = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$IconSet$ChevronDown = 30;
var $author$project$Pyxis$Components$IconSet$chevronDown = 30;
var $author$project$Pyxis$Components$Icon$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Icon$Default = {$: 0};
var $author$project$Pyxis$Components$Icon$M = 1;
var $author$project$Pyxis$Components$Icon$config = function (icon) {
	return {e7: $elm$core$Maybe$Nothing, e9: $elm$core$Maybe$Nothing, fj: _List_Nil, fn: $elm$core$Maybe$Nothing, iN: icon, ax: 1, hr: $author$project$Pyxis$Components$Icon$Default, eP: $author$project$Pyxis$Commons$Properties$Theme$default};
};
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $author$project$Pyxis$Components$NavigationSidebar$isGroupExpanded = F2(
	function (groupId, _v0) {
		var model = _v0;
		return A2($elm$core$Set$member, groupId, model.ar);
	});
var $elm$html$Html$li = _VirtualDom_node('li');
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Pyxis$Commons$NonEmptyList$toList = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	return A2($elm$core$List$cons, head, tail);
};
var $author$project$Pyxis$Components$NavigationSidebar$pickNavItemGroupLinkHref = function (groupItemList_) {
	return A2(
		$elm$core$List$filterMap,
		function (navItemGroupLink) {
			if (!navItemGroupLink.$) {
				var c = navItemGroupLink.a;
				return $elm$core$Maybe$Just(c.V);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		},
		$author$project$Pyxis$Commons$NonEmptyList$toList(groupItemList_));
};
var $author$project$Pyxis$Commons$String$fromBool = function (bool) {
	return bool ? 'true' : 'false';
};
var $author$project$Pyxis$Commons$Attributes$ariaHidden = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-hidden'));
var $author$project$Pyxis$Commons$Attributes$ariaLabel = $elm$html$Html$Attributes$attribute('aria-label');
var $author$project$Pyxis$Commons$Attributes$ariaLabelledBy = $elm$html$Html$Attributes$attribute('aria-labelledby');
var $author$project$Pyxis$Components$Icon$getSizeClass = function (size) {
	switch (size) {
		case 2:
			return 'icon--size-l';
		case 1:
			return 'icon--size-m';
		default:
			return 'icon--size-s';
	}
};
var $author$project$Pyxis$Components$Icon$getVariantClass = function (variant) {
	switch (variant) {
		case 0:
			return 'icon--boxed';
		case 1:
			return 'icon--boxed icon--brand';
		case 2:
			return 'icon--boxed icon--success';
		case 3:
			return 'icon--boxed icon--alert';
		default:
			return 'icon--boxed icon--error';
	}
};
var $author$project$Pyxis$Components$Icon$getStyleClass = function (style) {
	if (!style.$) {
		return '';
	} else {
		var variant = style.a;
		return $author$project$Pyxis$Components$Icon$getVariantClass(variant);
	}
};
var $author$project$Pyxis$Components$Icon$getClassList = function (_v0) {
	var style = _v0.hr;
	var size = _v0.ax;
	var classList = _v0.fj;
	return A2(
		$elm$core$List$filter,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Tuple$first,
			A2($elm$core$Basics$composeR, $elm$core$String$isEmpty, $elm$core$Basics$not)),
		_Utils_ap(
			_List_fromArray(
				[
					_Utils_Tuple2('icon', true),
					_Utils_Tuple2(
					$author$project$Pyxis$Components$Icon$getSizeClass(size),
					true),
					_Utils_Tuple2(
					$author$project$Pyxis$Components$Icon$getStyleClass(style),
					true)
				]),
			classList));
};
var $author$project$Pyxis$Tokens$Color$toString = function (color) {
	switch (color) {
		case 0:
			return 'action-base';
		case 1:
			return 'action-base-opacity-20';
		case 2:
			return 'action-dark';
		case 3:
			return 'action-light';
		case 4:
			return 'alert-base';
		case 5:
			return 'alert-dark';
		case 6:
			return 'alert-light';
		case 7:
			return 'brand-base';
		case 8:
			return 'brand-base-opacity-40';
		case 9:
			return 'brand-dark';
		case 10:
			return 'brand-light';
		case 11:
			return 'error-base';
		case 12:
			return 'error-base-opacity-20';
		case 13:
			return 'error-dark';
		case 14:
			return 'error-dark-opacity-20';
		case 15:
			return 'error-light';
		case 16:
			return 'neutral-25';
		case 17:
			return 'neutral-50';
		case 18:
			return 'neutral-75';
		case 19:
			return 'neutral-85';
		case 20:
			return 'neutral-95';
		case 21:
			return 'neutral-95-opacity-15';
		case 22:
			return 'neutral-base';
		case 23:
			return 'neutral-base-opacity-0';
		case 24:
			return 'neutral-base-opacity-5';
		case 25:
			return 'neutral-base-opacity-15';
		case 26:
			return 'neutral-base-opacity-40';
		case 27:
			return 'neutral-base-opacity-50';
		case 28:
			return 'neutral-base-opacity-80';
		case 29:
			return 'neutral-light';
		case 30:
			return 'neutral-light-opacity-5';
		case 31:
			return 'neutral-light-opacity-15';
		case 32:
			return 'neutral-light-opacity-40';
		case 33:
			return 'neutral-light-opacity-75';
		case 34:
			return 'success-base';
		case 35:
			return 'success-dark';
		default:
			return 'success-light';
	}
};
var $author$project$Pyxis$Components$Icon$inlineColorVariable = function (color) {
	return A2(
		$elm$html$Html$Attributes$attribute,
		'style',
		'--icon-color: var(--color-' + ($author$project$Pyxis$Tokens$Color$toString(color) + ')'));
};
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Result$andThen = F2(
	function (callback, result) {
		if (!result.$) {
			var value = result.a;
			return callback(value);
		} else {
			var msg = result.a;
			return $elm$core$Result$Err(msg);
		}
	});
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $elm$svg$Svg$node = $elm$virtual_dom$VirtualDom$nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$text = $elm$virtual_dom$VirtualDom$text;
var $elm$svg$Svg$Attributes$xlinkHref = function (value) {
	return A3(
		_VirtualDom_attributeNS,
		'http://www.w3.org/1999/xlink',
		'xlink:href',
		_VirtualDom_noJavaScriptUri(value));
};
var $Garados007$elm_svg_parser$SvgParser$toAttribute = function (_v0) {
	var name = _v0.a;
	var value = _v0.b;
	return (name === 'xlink:href') ? $elm$svg$Svg$Attributes$xlinkHref(value) : A2($elm$virtual_dom$VirtualDom$attribute, name, value);
};
var $Garados007$elm_svg_parser$SvgParser$elementToSvg = function (element) {
	return A3(
		$elm$svg$Svg$node,
		element.i6,
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.fa),
		A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.X));
};
var $Garados007$elm_svg_parser$SvgParser$nodeToSvg = function (svgNode) {
	switch (svgNode.$) {
		case 0:
			var element = svgNode.a;
			return $Garados007$elm_svg_parser$SvgParser$elementToSvg(element);
		case 1:
			var content = svgNode.a;
			return $elm$svg$Svg$text(content);
		default:
			var content = svgNode.a;
			return $elm$svg$Svg$text('');
	}
};
var $andre_dietrich$parser_combinators$Combine$Parser = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$app = function (_v0) {
	var inner = _v0;
	return inner;
};
var $andre_dietrich$parser_combinators$Combine$andThen = F2(
	function (f, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return A3(
						$andre_dietrich$parser_combinators$Combine$app,
						f(res),
						rstate,
						rstream);
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(ms));
				}
			});
	});
var $pilatch$flip$Flip$flip = F3(
	function (_function, argB, argA) {
		return A2(_function, argA, argB);
	});
var $andre_dietrich$parser_combinators$Combine$bimap = F3(
	function (fok, ferr, p) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					return _Utils_Tuple3(
						rstate,
						rstream,
						$elm$core$Result$Ok(
							fok(res)));
				} else {
					var estate = _v0.a;
					var estream = _v0.b;
					var ms = _v0.c.a;
					return _Utils_Tuple3(
						estate,
						estream,
						$elm$core$Result$Err(
							ferr(ms)));
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$map = F2(
	function (f, p) {
		return A3($andre_dietrich$parser_combinators$Combine$bimap, f, $elm$core$Basics$identity, p);
	});
var $andre_dietrich$parser_combinators$Combine$andMap = F2(
	function (rp, lp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andThen,
			A2($pilatch$flip$Flip$flip, $andre_dietrich$parser_combinators$Combine$map, rp),
			lp);
	});
var $Garados007$elm_svg_parser$SvgParser$flip = F3(
	function (func, b, a) {
		return A2(func, a, b);
	});
var $Garados007$elm_svg_parser$SvgParser$andMapRight = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
				lp));
	});
var $andre_dietrich$parser_combinators$Combine$many = function (p) {
	var accumulate = F3(
		function (acc, state, stream) {
			accumulate:
			while (true) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
				if (!_v0.c.$) {
					var rstate = _v0.a;
					var rstream = _v0.b;
					var res = _v0.c.a;
					if (_Utils_eq(stream, rstream)) {
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$List$reverse(acc));
					} else {
						var $temp$acc = A2($elm$core$List$cons, res, acc),
							$temp$state = rstate,
							$temp$stream = rstream;
						acc = $temp$acc;
						state = $temp$state;
						stream = $temp$stream;
						continue accumulate;
					}
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$List$reverse(acc));
				}
			}
		});
	return F2(
		function (state, stream) {
			var _v1 = A3(accumulate, _List_Nil, state, stream);
			var rstate = _v1.a;
			var rstream = _v1.b;
			var res = _v1.c;
			return _Utils_Tuple3(
				rstate,
				rstream,
				$elm$core$Result$Ok(res));
		});
};
var $Garados007$elm_svg_parser$SvgParser$SvgElement = function (a) {
	return {$: 0, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$andMapLeft = F2(
	function (lp, rp) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			rp,
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$Basics$always, lp));
	});
var $andre_dietrich$parser_combinators$Combine$emptyErr = F2(
	function (state, stream) {
		return _Utils_Tuple3(
			state,
			stream,
			$elm$core$Result$Err(_List_Nil));
	});
var $andre_dietrich$parser_combinators$Combine$or = F2(
	function (lp, rp) {
		return F2(
			function (state, stream) {
				var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, lp, state, stream);
				if (!_v0.c.$) {
					var res = _v0;
					return res;
				} else {
					var lms = _v0.c.a;
					var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, rp, state, stream);
					if (!_v1.c.$) {
						var res = _v1;
						return res;
					} else {
						var rms = _v1.c.a;
						return _Utils_Tuple3(
							state,
							stream,
							$elm$core$Result$Err(
								_Utils_ap(lms, rms)));
					}
				}
			});
	});
var $andre_dietrich$parser_combinators$Combine$choice = function (xs) {
	return A3($elm$core$List$foldr, $andre_dietrich$parser_combinators$Combine$or, $andre_dietrich$parser_combinators$Combine$emptyErr, xs);
};
var $Garados007$elm_svg_parser$SvgParser$SvgComment = function (a) {
	return {$: 2, a: a};
};
var $andre_dietrich$parser_combinators$Combine$mapError = $andre_dietrich$parser_combinators$Combine$bimap($elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$onerror = F2(
	function (m, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$mapError,
			$elm$core$Basics$always(
				_List_fromArray(
					[m])),
			p);
	});
var $andre_dietrich$parser_combinators$Combine$primitive = $elm$core$Basics$identity;
var $andre_dietrich$parser_combinators$Combine$Char$satisfy = function (pred) {
	return $andre_dietrich$parser_combinators$Combine$primitive(
		F2(
			function (state, stream) {
				var message = 'could not satisfy predicate';
				var _v0 = $elm$core$String$uncons(stream.bt);
				if (!_v0.$) {
					var _v1 = _v0.a;
					var h = _v1.a;
					var rest = _v1.b;
					return pred(h) ? _Utils_Tuple3(
						state,
						_Utils_update(
							stream,
							{bt: rest, en: stream.en + 1}),
						$elm$core$Result$Ok(h)) : _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				} else {
					return _Utils_Tuple3(
						state,
						stream,
						$elm$core$Result$Err(
							_List_fromArray(
								[message])));
				}
			}));
};
var $andre_dietrich$parser_combinators$Combine$Char$anyChar = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'expected any character',
	$andre_dietrich$parser_combinators$Combine$Char$satisfy(
		$elm$core$Basics$always(true)));
var $andre_dietrich$parser_combinators$Combine$succeed = function (res) {
	return F2(
		function (state, stream) {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Ok(res));
		});
};
var $andre_dietrich$parser_combinators$Combine$lazy = function (t) {
	return A2(
		$andre_dietrich$parser_combinators$Combine$andThen,
		t,
		$andre_dietrich$parser_combinators$Combine$succeed(0));
};
var $andre_dietrich$parser_combinators$Combine$manyTill = F2(
	function (p, end_) {
		var accumulate = F3(
			function (acc, state, stream) {
				accumulate:
				while (true) {
					var _v0 = A3($andre_dietrich$parser_combinators$Combine$app, end_, state, stream);
					if (!_v0.c.$) {
						var rstate = _v0.a;
						var rstream = _v0.b;
						return _Utils_Tuple3(
							rstate,
							rstream,
							$elm$core$Result$Ok(
								$elm$core$List$reverse(acc)));
					} else {
						var estate = _v0.a;
						var estream = _v0.b;
						var ms = _v0.c.a;
						var _v1 = A3($andre_dietrich$parser_combinators$Combine$app, p, state, stream);
						if (!_v1.c.$) {
							var rstate = _v1.a;
							var rstream = _v1.b;
							var res = _v1.c.a;
							var $temp$acc = A2($elm$core$List$cons, res, acc),
								$temp$state = rstate,
								$temp$stream = rstream;
							acc = $temp$acc;
							state = $temp$state;
							stream = $temp$stream;
							continue accumulate;
						} else {
							return _Utils_Tuple3(
								estate,
								estream,
								$elm$core$Result$Err(ms));
						}
					}
				}
			});
		return accumulate(_List_Nil);
	});
var $andre_dietrich$parser_combinators$Combine$string = function (s) {
	return F2(
		function (state, stream) {
			if (A2($elm$core$String$startsWith, s, stream.bt)) {
				var len = $elm$core$String$length(s);
				var pos = stream.en + len;
				var rem = A2($elm$core$String$dropLeft, len, stream.bt);
				return _Utils_Tuple3(
					state,
					_Utils_update(
						stream,
						{bt: rem, en: pos}),
					$elm$core$Result$Ok(s));
			} else {
				return _Utils_Tuple3(
					state,
					stream,
					$elm$core$Result$Err(
						_List_fromArray(
							['expected \"' + (s + '\"')])));
			}
		});
};
var $elm$regex$Regex$findAtMost = _Regex_findAtMost;
var $andre_dietrich$parser_combinators$Combine$regexer = F5(
	function (input, output, pat, state, stream) {
		var pattern = A2($elm$core$String$startsWith, '^', pat) ? pat : ('^' + pat);
		var _v0 = A3(
			$elm$regex$Regex$findAtMost,
			1,
			A2(
				$elm$core$Maybe$withDefault,
				$elm$regex$Regex$never,
				input(pattern)),
			stream.bt);
		if (_v0.b && (!_v0.b.b)) {
			var match = _v0.a;
			var len = $elm$core$String$length(match.cJ);
			var pos = stream.en + len;
			var rem = A2($elm$core$String$dropLeft, len, stream.bt);
			return _Utils_Tuple3(
				state,
				_Utils_update(
					stream,
					{bt: rem, en: pos}),
				$elm$core$Result$Ok(
					output(match)));
		} else {
			return _Utils_Tuple3(
				state,
				stream,
				$elm$core$Result$Err(
					_List_fromArray(
						['expected input matching Regexp /' + (pattern + '/')])));
		}
	});
var $andre_dietrich$parser_combinators$Combine$regex = A2(
	$elm$core$Basics$composeR,
	A2(
		$andre_dietrich$parser_combinators$Combine$regexer,
		$elm$regex$Regex$fromString,
		function ($) {
			return $.cJ;
		}),
	$elm$core$Basics$identity);
var $andre_dietrich$parser_combinators$Combine$whitespace = A2(
	$andre_dietrich$parser_combinators$Combine$onerror,
	'optional whitespace',
	$andre_dietrich$parser_combinators$Combine$regex('\\s*'));
var $Garados007$elm_svg_parser$SvgParser$commentParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			A2($elm$core$Basics$composeL, $Garados007$elm_svg_parser$SvgParser$SvgComment, $elm$core$String$fromList),
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					$andre_dietrich$parser_combinators$Combine$whitespace,
					$andre_dietrich$parser_combinators$Combine$string('<!--')),
				A2(
					$andre_dietrich$parser_combinators$Combine$manyTill,
					$andre_dietrich$parser_combinators$Combine$Char$anyChar,
					$andre_dietrich$parser_combinators$Combine$string('-->'))));
	});
var $Garados007$elm_svg_parser$SvgParser$Element = F3(
	function (name, attributes, children) {
		return {fa: attributes, X: children, i6: name};
	});
var $andre_dietrich$parser_combinators$Combine$optional = F2(
	function (res, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			p,
			$andre_dietrich$parser_combinators$Combine$succeed(res));
	});
var $Garados007$elm_svg_parser$SvgParser$attributeParser = A2(
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$optional,
		'',
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$string('=\"'),
				$andre_dietrich$parser_combinators$Combine$regex('[^\"]*')),
			$andre_dietrich$parser_combinators$Combine$string('\"'))),
	A2(
		$andre_dietrich$parser_combinators$Combine$map,
		$elm$core$Tuple$pair,
		$andre_dietrich$parser_combinators$Combine$regex('[^=>/]+')));
var $andre_dietrich$parser_combinators$Combine$keep = F2(
	function (p1, p2) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			p1,
			A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$pilatch$flip$Flip$flip($elm$core$Basics$always),
				p2));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy1 = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$andMap,
			$andre_dietrich$parser_combinators$Combine$many(
				A2($andre_dietrich$parser_combinators$Combine$keep, p, sep)),
			A2($andre_dietrich$parser_combinators$Combine$map, $elm$core$List$cons, p));
	});
var $andre_dietrich$parser_combinators$Combine$sepBy = F2(
	function (sep, p) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$or,
			A2($andre_dietrich$parser_combinators$Combine$sepBy1, sep, p),
			$andre_dietrich$parser_combinators$Combine$succeed(_List_Nil));
	});
var $Garados007$elm_svg_parser$SvgParser$openingParser = A3(
	$Garados007$elm_svg_parser$SvgParser$flip,
	$andre_dietrich$parser_combinators$Combine$andMap,
	A2(
		$andre_dietrich$parser_combinators$Combine$andMap,
		$andre_dietrich$parser_combinators$Combine$regex('[^/>\\s]+'),
		A2(
			$andre_dietrich$parser_combinators$Combine$map,
			F3(
				function (_v0, tagName, attributes) {
					return A3($Garados007$elm_svg_parser$SvgParser$Element, tagName, attributes, _List_Nil);
				}),
			$andre_dietrich$parser_combinators$Combine$string('<'))),
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapLeft,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			A2($andre_dietrich$parser_combinators$Combine$sepBy, $andre_dietrich$parser_combinators$Combine$whitespace, $Garados007$elm_svg_parser$SvgParser$attributeParser)),
		$andre_dietrich$parser_combinators$Combine$whitespace));
var $Garados007$elm_svg_parser$SvgParser$SvgText = function (a) {
	return {$: 1, a: a};
};
var $Garados007$elm_svg_parser$SvgParser$textParser = $andre_dietrich$parser_combinators$Combine$lazy(
	function (_v0) {
		return A2(
			$andre_dietrich$parser_combinators$Combine$map,
			$Garados007$elm_svg_parser$SvgParser$SvgText,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapRight,
				$andre_dietrich$parser_combinators$Combine$whitespace,
				$andre_dietrich$parser_combinators$Combine$regex('[^<]+')));
	});
var $Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser = function (element) {
	var childrenParser = A2(
		$andre_dietrich$parser_combinators$Combine$map,
		function (children) {
			return _Utils_update(
				element,
				{X: children});
		},
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapLeft,
			A2(
				$Garados007$elm_svg_parser$SvgParser$andMapLeft,
				A2(
					$Garados007$elm_svg_parser$SvgParser$andMapRight,
					A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						$andre_dietrich$parser_combinators$Combine$whitespace,
						$andre_dietrich$parser_combinators$Combine$string('>')),
					$andre_dietrich$parser_combinators$Combine$many(
						$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser())),
				$andre_dietrich$parser_combinators$Combine$whitespace),
			$andre_dietrich$parser_combinators$Combine$string('</' + (element.i6 + '>'))));
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v2) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						A2(
						$Garados007$elm_svg_parser$SvgParser$andMapRight,
						A2(
							$Garados007$elm_svg_parser$SvgParser$andMapRight,
							$andre_dietrich$parser_combinators$Combine$whitespace,
							$andre_dietrich$parser_combinators$Combine$string('/>')),
						$andre_dietrich$parser_combinators$Combine$succeed(element)),
						childrenParser
					]));
		});
};
function $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v1) {
			return A2(
				$andre_dietrich$parser_combinators$Combine$map,
				$Garados007$elm_svg_parser$SvgParser$SvgElement,
				A2(
					$andre_dietrich$parser_combinators$Combine$andThen,
					$Garados007$elm_svg_parser$SvgParser$closingOrChildrenParser,
					A2(
						$andre_dietrich$parser_combinators$Combine$andMap,
						$Garados007$elm_svg_parser$SvgParser$openingParser,
						A2(
							$andre_dietrich$parser_combinators$Combine$map,
							$Garados007$elm_svg_parser$SvgParser$flip($elm$core$Basics$always),
							$andre_dietrich$parser_combinators$Combine$whitespace))));
		});
}
function $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser() {
	return $andre_dietrich$parser_combinators$Combine$lazy(
		function (_v0) {
			return $andre_dietrich$parser_combinators$Combine$choice(
				_List_fromArray(
					[
						$Garados007$elm_svg_parser$SvgParser$textParser,
						$Garados007$elm_svg_parser$SvgParser$commentParser,
						$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser()
					]));
		});
}
var $Garados007$elm_svg_parser$SvgParser$elementParser = $Garados007$elm_svg_parser$SvgParser$cyclic$elementParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$elementParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$elementParser;
};
var $Garados007$elm_svg_parser$SvgParser$nodeParser = $Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser();
$Garados007$elm_svg_parser$SvgParser$cyclic$nodeParser = function () {
	return $Garados007$elm_svg_parser$SvgParser$nodeParser;
};
var $andre_dietrich$parser_combinators$Combine$InputStream = F3(
	function (data, input, position) {
		return {il: data, bt: input, en: position};
	});
var $andre_dietrich$parser_combinators$Combine$initStream = function (s) {
	return A3($andre_dietrich$parser_combinators$Combine$InputStream, s, s, 0);
};
var $andre_dietrich$parser_combinators$Combine$runParser = F3(
	function (p, st, s) {
		var _v0 = A3(
			$andre_dietrich$parser_combinators$Combine$app,
			p,
			st,
			$andre_dietrich$parser_combinators$Combine$initStream(s));
		if (!_v0.c.$) {
			var state = _v0.a;
			var stream = _v0.b;
			var res = _v0.c.a;
			return $elm$core$Result$Ok(
				_Utils_Tuple3(state, stream, res));
		} else {
			var state = _v0.a;
			var stream = _v0.b;
			var ms = _v0.c.a;
			return $elm$core$Result$Err(
				_Utils_Tuple3(state, stream, ms));
		}
	});
var $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser = A2(
	$andre_dietrich$parser_combinators$Combine$map,
	$elm$core$String$fromList,
	A2(
		$Garados007$elm_svg_parser$SvgParser$andMapRight,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			$andre_dietrich$parser_combinators$Combine$whitespace,
			$andre_dietrich$parser_combinators$Combine$string('<?xml')),
		A2(
			$andre_dietrich$parser_combinators$Combine$manyTill,
			$andre_dietrich$parser_combinators$Combine$Char$anyChar,
			$andre_dietrich$parser_combinators$Combine$string('?>'))));
var $Garados007$elm_svg_parser$SvgParser$parseToNodes = function (input) {
	var _v0 = A3(
		$andre_dietrich$parser_combinators$Combine$runParser,
		A2(
			$Garados007$elm_svg_parser$SvgParser$andMapRight,
			A2($andre_dietrich$parser_combinators$Combine$optional, '', $Garados007$elm_svg_parser$SvgParser$xmlDeclarationParser),
			$andre_dietrich$parser_combinators$Combine$many($Garados007$elm_svg_parser$SvgParser$nodeParser)),
		_List_Nil,
		input);
	if (!_v0.$) {
		var _v1 = _v0.a;
		var svgNodes = _v1.c;
		return $elm$core$Result$Ok(svgNodes);
	} else {
		var _v2 = _v0.a;
		var errors = _v2.c;
		return $elm$core$Result$Err(
			A2($elm$core$String$join, ' or ', errors));
	}
};
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $Garados007$elm_svg_parser$SvgParser$parse = function (input) {
	var toHtml = function (svgNodes) {
		toHtml:
		while (true) {
			if (svgNodes.b) {
				if (!svgNodes.a.$) {
					var element = svgNodes.a.a;
					var tl = svgNodes.b;
					if (element.i6 === 'svg') {
						return $elm$core$Result$Ok(
							A2(
								$elm$svg$Svg$svg,
								A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$toAttribute, element.fa),
								A2($elm$core$List$map, $Garados007$elm_svg_parser$SvgParser$nodeToSvg, element.X)));
					} else {
						var $temp$svgNodes = tl;
						svgNodes = $temp$svgNodes;
						continue toHtml;
					}
				} else {
					var tl = svgNodes.b;
					var $temp$svgNodes = tl;
					svgNodes = $temp$svgNodes;
					continue toHtml;
				}
			} else {
				return $elm$core$Result$Err('No svg found');
			}
		}
	};
	return A2(
		$elm$core$Result$andThen,
		toHtml,
		$Garados007$elm_svg_parser$SvgParser$parseToNodes(input));
};
var $author$project$Pyxis$Commons$Attributes$renderIf = F2(
	function (bool, attr) {
		return bool ? attr : $author$project$Pyxis$Commons$Attributes$none;
	});
var $author$project$Pyxis$Commons$Attributes$role = $elm$html$Html$Attributes$attribute('role');
var $elm$html$Html$span = _VirtualDom_node('span');
var $author$project$Pyxis$Commons$Properties$Theme$altThemeString = 'alt';
var $author$project$Pyxis$Commons$Properties$Theme$lightThemeString = 'light';
var $author$project$Pyxis$Commons$Properties$Theme$toString = function (theme) {
	return $author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme) ? $author$project$Pyxis$Commons$Properties$Theme$altThemeString : $author$project$Pyxis$Commons$Properties$Theme$lightThemeString;
};
var $author$project$Pyxis$Commons$Attributes$theme = function (theme_) {
	return A2(
		$elm$html$Html$Attributes$attribute,
		'data-theme',
		$author$project$Pyxis$Commons$Properties$Theme$toString(theme_));
};
var $author$project$Pyxis$Components$IconSet$toLabel = function (icon) {
	switch (icon) {
		case 0:
			return 'activity';
		case 1:
			return 'alarm';
		case 2:
			return 'android';
		case 3:
			return 'apple';
		case 4:
			return 'arrow-down';
		case 5:
			return 'arrow-left';
		case 6:
			return 'arrow-right';
		case 7:
			return 'arrow-up';
		case 8:
			return 'baloon';
		case 9:
			return 'bank';
		case 10:
			return 'bell';
		case 11:
			return 'book';
		case 12:
			return 'buildings';
		case 13:
			return 'burger';
		case 14:
			return 'calendar-clock';
		case 15:
			return 'calendar-mail';
		case 16:
			return 'calendar';
		case 17:
			return 'camera';
		case 18:
			return 'car';
		case 19:
			return 'cards-overlap';
		case 20:
			return 'cart';
		case 21:
			return 'chart';
		case 22:
			return 'chat';
		case 23:
			return 'check-circle';
		case 24:
			return 'check-square';
		case 25:
			return 'check';
		case 26:
			return 'chevron-circle-down';
		case 27:
			return 'chevron-circle-left';
		case 28:
			return 'chevron-circle-right';
		case 29:
			return 'chevron-circle-up';
		case 30:
			return 'chevron-down';
		case 31:
			return 'chevron-left';
		case 32:
			return 'chevron-right';
		case 33:
			return 'chevron-up';
		case 34:
			return 'clock-24';
		case 35:
			return 'clock';
		case 36:
			return 'computer';
		case 37:
			return 'credit-card';
		case 38:
			return 'cross-circle';
		case 39:
			return 'cross-square';
		case 40:
			return 'cross';
		case 41:
			return 'damage-third-parties';
		case 42:
			return 'dashboard';
		case 43:
			return 'devices';
		case 44:
			return 'diamond';
		case 45:
			return 'direction';
		case 46:
			return 'document-alert';
		case 47:
			return 'document-plus';
		case 48:
			return 'document-signed';
		case 49:
			return 'document';
		case 50:
			return 'download';
		case 51:
			return 'euro';
		case 52:
			return 'exclamation-circle';
		case 53:
			return 'exclamation-triangle';
		case 54:
			return 'external-link';
		case 55:
			return 'eye-slashed';
		case 56:
			return 'eye';
		case 57:
			return 'facebook';
		case 58:
			return 'family-rc';
		case 59:
			return 'fax';
		case 60:
			return 'floppy-disk';
		case 61:
			return 'folder';
		case 62:
			return 'gear';
		case 63:
			return 'gift';
		case 64:
			return 'gps-disabled';
		case 65:
			return 'gps-enabled';
		case 66:
			return 'grid';
		case 67:
			return 'home-damage-ebike';
		case 68:
			return 'home-damage-family';
		case 69:
			return 'home-damage-pets';
		case 70:
			return 'home-damage-third-parties';
		case 71:
			return 'home-domestic-thefts';
		case 72:
			return 'home-emergencies-repairs';
		case 73:
			return 'home-fire-explosion';
		case 74:
			return 'home-furniture-content';
		case 75:
			return 'home-injuries-family';
		case 76:
			return 'home-injuries-freetime';
		case 77:
			return 'home-insurance-home';
		case 78:
			return 'home-legal-protection';
		case 79:
			return 'home-natural-events';
		case 80:
			return 'home-robberies-outside';
		case 81:
			return 'home-telephone-legal-advice';
		case 82:
			return 'home-theft-valuable';
		case 83:
			return 'home';
		case 84:
			return 'hourglass';
		case 85:
			return 'id-card';
		case 86:
			return 'info-circle';
		case 87:
			return 'instagram';
		case 88:
			return 'insurance-home';
		case 89:
			return 'jobs';
		case 90:
			return 'key';
		case 91:
			return 'keyhole';
		case 92:
			return 'link';
		case 93:
			return 'linkedin';
		case 94:
			return 'list-circle';
		case 95:
			return 'list';
		case 96:
			return 'loader';
		case 97:
			return 'local-protection';
		case 98:
			return 'lock-open';
		case 99:
			return 'lock';
		case 100:
			return 'logout';
		case 101:
			return 'magic-wand';
		case 102:
			return 'magnifying-glass';
		case 103:
			return 'mail';
		case 104:
			return 'map-marker';
		case 105:
			return 'medal';
		case 106:
			return 'minus-circle';
		case 107:
			return 'minus-square';
		case 108:
			return 'minus';
		case 109:
			return 'moped';
		case 110:
			return 'more-horizontal-circle';
		case 111:
			return 'more-horizontal';
		case 112:
			return 'more-vertical';
		case 113:
			return 'motorcycle';
		case 114:
			return 'odometer';
		case 115:
			return 'paper-plane';
		case 116:
			return 'paperclip';
		case 117:
			return 'pec';
		case 118:
			return 'pen-form';
		case 119:
			return 'pen';
		case 120:
			return 'phone-24';
		case 121:
			return 'phone';
		case 122:
			return 'plate';
		case 123:
			return 'plus-circle';
		case 124:
			return 'plus-square';
		case 125:
			return 'plus';
		case 126:
			return 'pound';
		case 127:
			return 'prima';
		case 128:
			return 'question-circle';
		case 129:
			return 'refund';
		case 130:
			return 'reload';
		case 131:
			return 'shield-check';
		case 132:
			return 'ski';
		case 133:
			return 'smartphone';
		case 134:
			return 'star-filled';
		case 135:
			return 'star';
		case 136:
			return 'steering-wheel';
		case 137:
			return 'switch';
		case 138:
			return 'tablet';
		case 139:
			return 'thumb-up';
		case 140:
			return 'trash-bin';
		case 141:
			return 'tshirt-sport';
		case 142:
			return 'twitter';
		case 143:
			return 'umbrella';
		case 144:
			return 'upload';
		case 145:
			return 'user-check';
		case 146:
			return 'user-circle';
		case 147:
			return 'user-cross';
		case 148:
			return 'user-minus';
		case 149:
			return 'user-plus';
		case 150:
			return 'user';
		case 151:
			return 'users';
		case 152:
			return 'van';
		case 153:
			return 'vehicle-collision-kasko';
		case 154:
			return 'vehicle-driver-injuries';
		case 155:
			return 'vehicle-full-kasko';
		case 156:
			return 'vehicle-glasses';
		case 157:
			return 'vehicle-legal-protection';
		case 158:
			return 'vehicle-natural-events';
		case 159:
			return 'vehicle-ricourse-waiver';
		case 160:
			return 'vehicle-roadside-assistance';
		case 161:
			return 'vehicle-secured-bonus';
		case 162:
			return 'vehicle-theft-fire';
		case 163:
			return 'vehicle-vandalism';
		case 164:
			return 'wallet';
		case 165:
			return 'whatsapp';
		case 166:
			return 'wrench';
		case 167:
			return 'x';
		default:
			return 'youtube';
	}
};
var $author$project$Pyxis$Components$IconSet$svgActivity = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 7H12c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h10c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-7 7h-3c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h3c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm4 7h-7c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h7c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/><circle cx=\"4.25\" cy=\"13\" r=\"1.25\"/><circle cx=\"4.25\" cy=\"20\" r=\"1.25\"/><path d=\"M4 8c-.27 0-.53-.11-.72-.3l-2-2.05C.9 5.24.91 4.61 1.3 4.23c.4-.38 1.03-.38 1.41.02L4 5.57l3.28-3.36c.39-.39 1.02-.4 1.41-.02.4.39.4 1.02.02 1.41l-4 4.09c-.18.2-.44.31-.71.31z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgAlarm = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.99 19.22l-2.08-7.78C18.2 8.82 15.82 7 13.11 7h-2.22a5.99 5.99 0 0 0-5.79 4.45l-2.08 7.78A2.99 2.99 0 0 0 5.91 23h12.18c1.97 0 3.41-1.87 2.9-3.78zM7.03 11.96C7.5 10.22 9.08 9 10.89 9h2.22c1.81 0 3.4 1.22 3.86 2.96L18.32 17H5.68l1.35-5.04zm11.85 8.65c-.11.14-.36.39-.79.39H5.91c-.43 0-.68-.25-.79-.39-.12-.15-.29-.45-.18-.87l.2-.74h13.72l.2.74c.11.42-.06.72-.18.87zM5.64 6.05a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29.39-.39.39-1.02 0-1.41L5.64 3.22c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l1.41 1.42zM12 4c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1s-1 .45-1 1v2c0 .55.45 1 1 1zm5.66 2.34c.26 0 .51-.1.71-.29l1.41-1.41c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-1.41 1.41c-.39.39-.39 1.02 0 1.41.18.2.44.29.7.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgAndroid = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.06 15.06a.92.92 0 1 1 0-1.84.92.92 0 1 1 0 1.84m-10.12 0a.92.92 0 0 1 0-1.84.92.92 0 0 1 .92.92.93.93 0 0 1-.92.92m10.45-5.52l1.83-3.17c.1-.18.04-.42-.14-.52s-.42-.04-.52.14L16.71 9.2c-1.42-.64-3.01-1-4.71-1s-3.29.36-4.71 1.01L5.44 5.99a.39.39 0 0 0-.52-.14c-.18.11-.25.34-.14.52l1.83 3.17c-3.15 1.71-5.3 4.9-5.61 8.66h22c-.31-3.76-2.47-6.95-5.61-8.66\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgApple = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 12.7a4.98 4.98 0 0 1 2.38-4.19c-.93-1.32-2.42-2.13-4.03-2.18-1.7-.18-3.34 1.02-4.21 1.02-.88 0-2.21-1-3.65-.97-1.88.05-3.59 1.1-4.51 2.75-1.95 3.38-.5 8.35 1.38 11.09.94 1.34 2.03 2.83 3.46 2.78 1.4-.06 1.92-.89 3.62-.89 1.68 0 2.17.89 3.63.86 1.5-.02 2.45-1.34 3.35-2.7.67-.95 1.19-2.01 1.53-3.12-1.79-.76-2.95-2.51-2.95-4.45zm-2.76-8.17A4.96 4.96 0 0 0 16.37 1a4.99 4.99 0 0 0-3.24 1.68 4.7 4.7 0 0 0-1.16 3.4c1.27.01 2.47-.56 3.27-1.55z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.18 15.82c-.39-.39-1.02-.39-1.41 0L13 19.59V2c0-.55-.45-1-1-1s-1 .45-1 1v17.59l-3.76-3.76c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l5.47 5.47a.99.99 0 0 0 .7.29c.27 0 .52-.11.71-.29l5.47-5.47a1.01 1.01 0 0 0 0-1.42z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8.18 18.18c.39-.39.39-1.02 0-1.41L4.41 13H22c.55 0 1-.45 1-1s-.45-1-1-1H4.41l3.76-3.76c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0L1.29 11.3A.99.99 0 0 0 1 12c0 .27.11.52.29.71l5.47 5.47a1.01 1.01 0 0 0 1.42 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.82 5.82c-.39.39-.39 1.02 0 1.41L19.59 11H2c-.55 0-1 .45-1 1s.45 1 1 1h17.59l-3.76 3.76c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l5.47-5.47A.99.99 0 0 0 23 12c0-.27-.11-.52-.29-.71l-5.47-5.47a1.01 1.01 0 0 0-1.42 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgArrowUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5.82 8.18c.39.39 1.02.39 1.41 0L11 4.41V22c0 .55.45 1 1 1s1-.45 1-1V4.41l3.76 3.76c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41L12.7 1.29A.99.99 0 0 0 12 1c-.27 0-.52.11-.71.29L5.82 6.76a1.01 1.01 0 0 0 0 1.42z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBaloon = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16 12H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-4-4H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h4c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm8.25-5c.41 0 .75.34.75.75v11c0 .41-.34.75-.75.75H7.33c-1.07 0-2.07.42-2.83 1.17L3 18.17V3.75c0-.41.34-.75.75-.75h16.5m0-2H3.75A2.75 2.75 0 0 0 1 3.75v16.84c0 .6.49 1 1.01 1 .25 0 .5-.09.7-.29l3.21-3.21c.38-.38.88-.59 1.41-.59h12.92A2.75 2.75 0 0 0 23 14.75v-11A2.75 2.75 0 0 0 20.25 1h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBank = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 10V8.57c0-1.07-.57-2.06-1.5-2.6L14 2.22c-1.23-.71-2.77-.71-4 0L3.5 5.97C2.57 6.51 2 7.5 2 8.57V10c0 .74.41 1.37 1 1.72V20c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1s-.45-1-1-1v-8.28c.59-.35 1-.98 1-1.72zM4 8.57c0-.36.19-.69.5-.87L11 3.95c.31-.18.65-.27 1-.27s.69.09 1 .27l6.5 3.75c.31.18.5.51.5.87V10H4V8.57zM11 12v8H9v-8h2zm2 0h2v8h-2v-8zm-8 0h2v8H5v-8zm12 8v-8h2v8h-2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBell = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.13 19l-1.73-3c-.26-.46-.4-.97-.4-1.5V10c0-3.74-2.56-6.86-6.03-7.74.02-.09.03-.17.03-.26 0-1.1-.9-2-2-2s-2 .9-2 2c0 .09.01.17.03.26C6.56 3.14 4 6.26 4 10v4.5c0 .53-.14 1.04-.4 1.5l-1.73 3c-.38.67.1 1.5.87 1.5h5.32A3.97 3.97 0 0 0 12 24c2.04 0 3.7-1.53 3.95-3.5h5.32c.77 0 1.25-.83.86-1.5zM12 22c-.93 0-1.71-.64-1.93-1.5h3.86c-.22.86-1 1.5-1.93 1.5zm3.7-3.5h-2.39-2.62H8.3 4.46l.87-1.5A4.98 4.98 0 0 0 6 14.5V10c0-3.31 2.69-6 6-6s6 2.69 6 6v4.5a4.98 4.98 0 0 0 .67 2.5l.87 1.5H15.7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBook = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 2.39a1.86 1.86 0 0 0-.52.07L12 5 2.52 2.46A1.91 1.91 0 0 0 2 2.39c-1.07 0-2 .87-2 2v12.09a3 3 0 0 0 2.22 2.9L12 22l9.78-2.62a3 3 0 0 0 2.22-2.9V4.39c0-1.13-.93-2-2-2zM2 16.48V4.39l9 2.41v12.86l-8.26-2.21c-.44-.12-.74-.52-.74-.97zm20 0c0 .45-.3.85-.74.97L13 19.66V6.8l9-2.41v12.09zM16 10c.09 0 .17-.01.26-.03l3-.8c.53-.14.85-.69.71-1.22s-.69-.85-1.22-.71l-3 .8c-.53.14-.85.69-.71 1.22.11.45.52.74.96.74zm2.74 1.23l-3 .8c-.53.14-.85.69-.71 1.22A1 1 0 0 0 16 14c.09 0 .17-.01.26-.03l3-.8c.53-.14.85-.69.71-1.22s-.69-.86-1.23-.72z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBuildings = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5 21c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v3.38c.6-.35 1.28-.55 2-.55V4c0-1.66-1.34-3-3-3H5C3.34 1 2 2.34 2 4v16c0 1.66 1.34 3 3 3h3.56c-.35-.59-.56-1.27-.56-2H5zM7 8c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm0 5c-.55 0-1-.45-1-1v-1c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm4-5c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v1c0 .55-.45 1-1 1zm10.12 5.12l-3.71-3.71c-.39-.39-.9-.59-1.41-.59s-1.02.2-1.41.59l-3.71 3.71c-.56.56-.88 1.33-.88 2.12V21c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-5.76c0-.79-.32-1.56-.88-2.12zM20 21h-3v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-3v-5.76a.99.99 0 0 1 .29-.71l3.71-3.7h0l3.71 3.71c.19.18.29.44.29.7V21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgBurger = '<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><use xlink:href=\"#B\"/><use xlink:href=\"#B\" y=\"8\"/><use xlink:href=\"#B\" y=\"16\"/><defs ><path id=\"B\" d=\"M21 5H3c-.55 0-1-.45-1-1s.45-1 1-1h18c.55 0 1 .45 1 1s-.45 1-1 1z\"/></defs></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3zM4 5h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2H3V6c0-.55.45-1 1-1zm16 16H4c-.55 0-1-.45-1-1V10h18v10c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendarClock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h6.36c-.71-.57-1.33-1.24-1.83-2H4c-.55 0-1-.45-1-1V10h6.31c.73-.81 1.6-1.49 2.58-2H3V6c0-.55.45-1 1-1h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2.52c.76.51 1.43 1.13 2 1.83V6c0-1.66-1.34-3-3-3zm-4 6c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7zm0 12c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm1-5.41v-2.67c0-.55-.45-1-1-1s-1 .45-1 1v3.5l1.63 1.63a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29.39-.39.39-1.02 0-1.41L17 15.59z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCalendarMail = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3h-1V2c0-.55-.45-1-1-1s-1 .45-1 1v1H7V2c0-.55-.45-1-1-1s-1 .45-1 1v1H4C2.34 3 1 4.34 1 6v14c0 1.66 1.34 3 3 3h2.97c-.36-.59-.58-1.27-.62-2H4c-.55 0-1-.45-1-1V10h18v.02c.73.04 1.41.25 2 .62V6c0-1.66-1.34-3-3-3zM3 8V6c0-.55.45-1 1-1h1v1c0 .55.45 1 1 1s1-.45 1-1V5h10v1c0 .55.45 1 1 1s1-.45 1-1V5h1c.55 0 1 .45 1 1v2H3zm17.8 4H10.53c-1.22 0-2.2.98-2.2 2.2v6.6c0 1.22.98 2.2 2.2 2.2H20.8c1.22 0 2.2-.98 2.2-2.2v-6.6c0-1.22-.98-2.2-2.2-2.2zm-.01 2l-5.12 2.96L10.55 14h10.24zm.01 7H10.53a.2.2 0 0 1-.2-.2v-4.61l4.83 2.79c.15.09.33.13.5.13s.35-.04.5-.13L21 16.19v4.61a.2.2 0 0 1-.2.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCamera = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.76 5a.99.99 0 0 1 .71.29l1.83 1.83c.56.57 1.31.88 2.11.88H20c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h1.59a2.97 2.97 0 0 0 2.12-.88l1.83-1.83c.18-.19.44-.29.7-.29h3.52m0-2h-3.51c-.8 0-1.56.32-2.12.88L6.29 5.71a1.01 1.01 0 0 1-.7.29H4C2.34 6 1 7.34 1 9v10c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h-1.59c-.27 0-.52-.11-.71-.29l-1.83-1.83c-.55-.56-1.32-.88-2.11-.88h0zM12 10a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.78 10l-4.1-1.1-1.1-1.9c-.54-.93-1.53-1.5-2.6-1.5H5.84c-.8 0-1.56.32-2.12.88l-2.5 2.5c-.76.76-1.05 1.86-.78 2.9l.67 2.5c.26.98.99 1.74 1.92 2.06L3 16c0 1.66 1.34 3 3 3s3-1.34 3-3c0 .17-.02.34-.05.5h7.1c-.03-.16-.05-.33-.05-.5 0 1.66 1.34 3 3 3s3-1.34 3-3c0 .11-.02.22-.03.33 1.18-.4 2.03-1.51 2.03-2.83v-.6a3 3 0 0 0-2.22-2.9zM6 17c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm13 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm3-3.5c0 .37-.21.67-.5.85.01.01.01.03.02.04A2.97 2.97 0 0 0 19 13c-1.11 0-2.06.61-2.58 1.5H8.58C8.06 13.61 7.11 13 6 13a2.97 2.97 0 0 0-2.52 1.39c.01-.01.02-.03.02-.05a1.01 1.01 0 0 1-.45-.58l-.67-2.5a1.03 1.03 0 0 1 .26-.97l2.5-2.5c.18-.19.44-.29.7-.29h8.14c.36 0 .69.19.87.5l1.1 1.9c.27.46.7.79 1.21.93l4.1 1.1c.44.12.74.52.74.97v.6zm-8.8-5H8.4c-.55 0-1 .45-1 1s.45 1 1 1h4.8c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCardsOverlap = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.5 7c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1h-9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9m0-2h-9c-1.66 0-3 1.34-3 3v12c0 1.66 1.34 3 3 3h9c1.66 0 3-1.34 3-3V8c0-1.66-1.34-3-3-3h0zm-14 9.82V4c0-.55.45-1 1-1H13h1.5 2.82c-.42-1.16-1.52-2-2.82-2h-9c-1.66 0-3 1.34-3 3v12c0 1.3.84 2.4 2 2.82V16v-1.18z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCart = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.47 18h-7.93a3 3 0 0 1-2.9-2.22l-2.3-8.56C4.99 5.91 3.81 5 2.45 5H2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h.45c2.26 0 4.24 1.52 4.83 3.71L7.62 8h13.22c.63 0 1.2.29 1.59.78a2.01 2.01 0 0 1 .35 1.74l-1.41 5.26c-.36 1.31-1.55 2.22-2.9 2.22zM8.16 10l1.41 5.26c.12.44.51.74.96.74h7.93c.45 0 .85-.3.97-.74L20.84 10H8.16zM10 19a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3h0zm9 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 1 0 0-3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChart = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 3c.55 0 1 .45 1 1v16c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h16m0-2H4C2.34 1 1 2.34 1 4v16c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3h0zM7 18h0c-.55 0-1-.45-1-1V7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1zm5 0h0c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1zm5 0h0c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v7c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChat = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.5 12c1.29 0 2.52.55 3.37 1.52.87.98 1.25 2.25 1.09 3.56a4.56 4.56 0 0 1-3.68 3.85c-.26.05-.52.07-.78.07a4.53 4.53 0 0 1-2.24-.6l-.72-.41-.71.19.19-.71-.42-.73a4.47 4.47 0 0 1-.54-3.02c.32-1.9 1.94-3.45 3.85-3.68A3.99 3.99 0 0 1 7.5 12m0-2a7.86 7.86 0 0 0-.83.05c-2.79.35-5.11 2.56-5.58 5.33a6.5 6.5 0 0 0 .78 4.36l-.42 1.58c-.18.65.33 1.26.96 1.26.09 0 .17-.01.26-.04l1.58-.42A6.43 6.43 0 0 0 7.5 23a7.02 7.02 0 0 0 1.11-.09 6.52 6.52 0 0 0 5.33-5.58c.5-3.96-2.58-7.33-6.44-7.33h0zm14.39 3.37c.9-1.56 1.32-3.44 1-5.43-.57-3.63-3.61-6.51-7.26-6.89a8.65 8.65 0 0 0-.88-.05C10.6 1 7.18 4.07 6.6 8.06A6.69 6.69 0 0 1 7.5 8c.38 0 .75.03 1.11.08.21-1.11.71-2.15 1.49-3.01A6.26 6.26 0 0 1 14.75 3c.22 0 .45.01.68.04 2.75.29 5.06 2.48 5.49 5.21a6.22 6.22 0 0 1-.76 4.12l-.41.72.21.8.21.79-.79-.21-.8-.21-.72.41a6.51 6.51 0 0 1-1.96.72c.09.67.11 1.35.03 2.03 1.05-.15 2.04-.5 2.92-1.01l2.46.66c.09.02.18.04.26.04.63 0 1.14-.61.96-1.26l-.64-2.48z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10 18c-.26 0-.51-.1-.71-.29L4 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L10 15.59 19.59 6c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-10.29 10.3c-.2.19-.45.29-.71.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheckCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm-1 16c-.26 0-.51-.1-.71-.29L7 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L11 13.59 16.59 8c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCheckSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-6 15c-.26 0-.51-.1-.71-.29L7 12.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L11 13.59 16.59 8c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 16c-.26 0-.51-.1-.71-.29L7 11.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L12 13.59 15.59 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm.59 17L8.3 12.71c-.39-.39-.39-1.02 0-1.41L12.59 7c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41L10.41 12 14 15.59c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm-2 17h0c-.39-.39-.39-1.02 0-1.41L13.59 12 10 8.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l4.29 4.29c.39.39.39 1.02 0 1.41L11.41 17c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronCircleUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2h0a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm3.59 14L12 10.41 8.41 14c-.39.39-1.02.39-1.41 0h0c-.39-.39-.39-1.02 0-1.41l4.29-4.29c.39-.39 1.02-.39 1.41 0l4.3 4.29c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronDown = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 17c-.26 0-.51-.1-.71-.29L5 10.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L12 14.59 17.59 9c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-6.29 6.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronLeft = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.59 19L7.3 12.71c-.39-.39-.39-1.02 0-1.41L13.59 5c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41L9.41 12 15 17.59c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronRight = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 19h0c-.39-.39-.39-1.02 0-1.41L14.59 12 9 6.41C8.61 6.02 8.61 5.39 9 5h0c.39-.39 1.02-.39 1.41 0l6.29 6.29c.39.39.39 1.02 0 1.41L10.41 19c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgChevronUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.59 15L12 9.41 6.41 15c-.39.39-1.02.39-1.41 0h0c-.39-.39-.39-1.02 0-1.41l6.29-6.29c.39-.39 1.02-.39 1.41 0l6.3 6.29c.39.39.39 1.02 0 1.41h0c-.39.39-1.02.39-1.41 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgClock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 0a12 12 0 1 0 0 24 12 12 0 1 0 0-24zm0 22A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10zm1-10.41V5c0-.55-.45-1-1-1s-1 .45-1 1v7.41l4.29 4.29c.2.2.45.3.71.3s.51-.1.71-.29c.39-.39.39-1.02 0-1.41L13 11.59z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgClock24 = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.69 4.43c-.03-.09-.05-.26-.05-.42 0-1.06.78-2.01 2.27-2.01 1.44 0 2.26.9 2.26 1.98 0 .71-.34 1.3-1.1 1.72l-.99.56c-.15.08-.33.26-.36.38h2.45V8h-4.55v-.1c0-1.11.33-1.97 1.41-2.6l.93-.55c.43-.24.59-.44.59-.78 0-.31-.24-.58-.66-.58-.47 0-.71.34-.71.72a1.17 1.17 0 0 0 .04.32h-1.53zm5.17.85l2.4-3.16h1.89v3.34H24V6.8h-.85V8h-1.62V6.8h-2.66V5.28zm2.67-1.46L20.3 5.46h1.22V3.82zM14 18c-.26 0-.51-.1-.71-.29L10 14.41V8c0-.55.45-1 1-1s1 .45 1 1v5.59l2.71 2.71c.39.39.39 1.02 0 1.41-.2.19-.45.29-.71.29zm5.47-8c.33.94.53 1.95.53 3 0 4.96-4.04 9-9 9s-9-4.04-9-9 4.04-9 9-9c.21 0 .42.02.62.03v-2c-.2-.01-.41-.03-.62-.03C4.92 2 0 6.92 0 13s4.92 11 11 11 11-4.92 11-11a10.98 10.98 0 0 0-.42-3h-2.11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgComputer = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 2H4C2.34 2 1 3.34 1 5v11c0 1.66 1.34 3 3 3h6v1H8c-.55 0-1 .45-1 1s.45 1 1 1h8c.55 0 1-.45 1-1s-.45-1-1-1h-2v-1h6c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3zm1 14c0 .55-.45 1-1 1h-6-4-6c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCreditCard = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 2.5H3c-1.66 0-3 1.34-3 3v13c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3v-13c0-1.66-1.34-3-3-3zm-18 2h18c.55 0 1 .45 1 1v2H2v-2c0-.55.45-1 1-1zm18 15H3c-.55 0-1-.45-1-1v-7h20v7c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCross = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.0098 3.99001C19.6198 3.60001 18.9898 3.60001 18.5998 3.99001L11.9998 10.59L5.39977 3.99001C5.00977 3.60001 4.37977 3.60001 3.98977 3.99001C3.59977 4.38001 3.59977 5.01001 3.98977 5.40001L10.5898 12L3.98977 18.6C3.59977 18.99 3.59977 19.62 3.98977 20.01C4.37977 20.4 5.00977 20.4 5.39977 20.01L11.9998 13.41L18.5998 20.01C18.9898 20.4 19.6198 20.4 20.0098 20.01C20.3998 19.62 20.3998 18.99 20.0098 18.6L13.4098 12L20.0098 5.40001C20.3998 5.01001 20.3998 4.38001 20.0098 3.99001V3.99001Z\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgCrossCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm1.41 12l2.48-2.48c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0L12 10.59 9.52 8.11c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41L10.59 12l-2.48 2.48c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L12 13.41l2.48 2.48c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41L13.41 12z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgCrossSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1.11 7.11h0c-.39-.39-1.02-.39-1.41 0L12 10.59 9.52 8.11c-.39-.39-1.02-.39-1.41 0h0c-.39.39-.39 1.02 0 1.41L10.59 12l-2.48 2.48c-.39.39-.39 1.02 0 1.41h0c.39.39 1.02.39 1.41 0L12 13.41l2.48 2.48c.39.39 1.02.39 1.41 0h0c.39-.39.39-1.02 0-1.41L13.41 12l2.48-2.48c.39-.39.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDamageThirdParties = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.3 18H14c-1.1 0-2-.9-2-2v-1c0-.55-.45-1-1-1v-1.09c2.88-.5 5-3.15 5-6.16V2.41c0-.6-.49-1-1.01-1-.25 0-.5.09-.7.29l-.59.59a.98.98 0 0 1-.71.29c-.26 0-.51-.1-.71-.29L10.69.7a.98.98 0 0 0-.71-.29c-.26 0-.51.1-.71.29L7.71 2.29c-.2.2-.45.3-.71.3s-.51-.1-.71-.29l-.58-.59c-.2-.2-.45-.29-.7-.29-.51 0-1.01.4-1.01 1V7c0 2.97 2.16 5.43 5 5.91V14H4.93C3.61 14 2.65 15.25 3 16.52l1.41 5.26C4.76 23.09 5.94 24 7.3 24h5.4a3 3 0 0 0 2.9-2.22l.67-2.52A1 1 0 0 0 15.3 18zM6 7V4.42c.32.11.65.17 1 .17a2.97 2.97 0 0 0 2.12-.88l.88-.88.88.88c.57.56 1.32.88 2.12.88.35 0 .68-.06 1-.17v2.33c0 2.28-1.74 4.19-3.88 4.25H10a4 4 0 0 1-4-4zm7.66 14.26c-.11.44-.51.74-.96.74H7.3c-.45 0-.85-.3-.97-.74L4.93 16H10a4 4 0 0 0 4 4h0l-.34 1.26zM19 20.8a1.97 1.97 0 0 0-1.73 1l-.4.7c-.38.67.1 1.5.87 1.5h2.54c.77 0 1.25-.83.87-1.5l-.4-.7c-.4-.66-1.08-1-1.75-1h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDashboard = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 3v8H3V3h6m0-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0zm0 16v4H3v-4h6m0-2H3c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2h0zm12-2v8h-6v-8h6m0-2h-6c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2h0zm0-8v4h-6V3h6m0-2h-6c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDevices = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 8c.55 0 1 .45 1 1v10c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h3m0-2h-3c-1.66 0-3 1.34-3 3v10c0 1.66 1.34 3 3 3h3c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h0zm-8 11h-2-6c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h13 1.3H20h2.82C22.4 2.84 21.3 2 20 2H4C2.34 2 1 3.34 1 5v11c0 1.66 1.34 3 3 3h6v1H8c-.55 0-1 .45-1 1s.45 1 1 1h5.03c-.64-.84-1.03-1.87-1.03-3v-2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDiamond = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.95 7.84L19.58 2c-.36-.62-1.02-1-1.73-1H6.15c-.71 0-1.37.38-1.73 1L1.05 7.84a1.98 1.98 0 0 0 .15 2.22l9.22 11.9a1.98 1.98 0 0 0 3.16 0l9.22-11.9a2.02 2.02 0 0 0 .15-2.22zM14.81 10L12 17.98 9.2 10h5.61zM9.55 8L12 3.94 14.45 8h-4.9zm4.22-5h3.5l-1.29 3.66L13.77 3zM8.02 6.66L6.74 3h3.49L8.02 6.66zm1.87 11.35L3.69 10h3.39l2.81 8.01zM16.93 10h3.39l-6.2 8.01L16.93 10zm3.8-2h-3.1l1.17-3.34L20.73 8zM5.2 4.66L6.37 8h-3.1L5.2 4.66z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDirection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2.24c.15 0 .45.04.71.29l8.76 8.76c.39.39.39 1.02 0 1.41l-8.76 8.76c-.25.25-.55.29-.71.29s-.45-.04-.71-.29l-8.75-8.75c-.39-.39-.39-1.02 0-1.41l8.75-8.76c.25-.25.55-.29.71-.29m0-2.01c-.77 0-1.54.29-2.12.88L1.12 9.88a3 3 0 0 0 0 4.24l8.76 8.76c.59.59 1.35.88 2.12.88s1.54-.29 2.12-.88l8.76-8.76a3 3 0 0 0 0-4.24l-8.76-8.76C13.53.53 12.77.24 12 .24h0zm4.71 11.05l-3-3c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l1.29 1.29H8c-.55 0-1 .45-1 1s.45 1 1 1h5.59l-1.29 1.29c-.39.39-.39 1.02 0 1.41a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29l3-3c.39-.39.39-1.02 0-1.41z\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgDocument = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.12 7.12L13.88.88C13.32.32 12.55 0 11.76 0H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14 3.83L17.17 7H14V3.83zM19 21c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocumentAlert = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.12 7.12L14.88.88C14.32.32 13.55 0 12.76 0H7a3.01 3.01 0 0 0-3 3v6.52c.62-.41 1.29-.74 2-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21c0 .55-.45 1-1 1h-2.52c-.51.76-1.13 1.43-1.83 2H19a3.01 3.01 0 0 0 3-3V9.24c0-.79-.32-1.56-.88-2.12zM15 3.83L18.17 7H15V3.83zM9 12c2.76 0 5 2.24 5 5s-2.24 5-5 5-5-2.24-5-5 2.24-5 5-5m0-2c-3.87 0-7 3.13-7 7s3.13 7 7 7 7-3.13 7-7-3.13-7-7-7h0zm0 8c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1z\"/><circle cx=\"9\" cy=\"20\" r=\"1\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocumentPlus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19.12 7.12L12.88.88A2.97 2.97 0 0 0 10.76 0H5a3.01 3.01 0 0 0-3 3v18a3.01 3.01 0 0 0 3 3h7c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V13c0 .55.45 1 1 1s1-.45 1-1V9.24a2.97 2.97 0 0 0-.88-2.12zM13 3.83L16.17 7H13V3.83zM21 19h-2v-2c0-.55-.45-1-1-1s-1 .45-1 1v2h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDocumentSigned = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.12 7.12L13.88.88C13.32.32 12.55 0 11.76 0H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14 3.83L17.17 7H14V3.83zM19 21c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21zm-3-3h-6c-.55 0-1 .45-1 1s.45 1 1 1h6c.55 0 1-.45 1-1s-.45-1-1-1zm0-3h-2c-.55 0-1 .45-1 1s.45 1 1 1h2c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgDownload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 23H6a4.02 4.02 0 0 1-3.8-2.74c-.2-.62.29-1.26.95-1.26h0c.42 0 .82.24.95.63.27.8 1.02 1.37 1.9 1.37h12c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 18 23zm-2-12c-.39-.39-1.02-.39-1.41 0L13 12.59V2c0-.55-.45-1-1-1s-1 .45-1 1v10.59L9.41 11c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l3.29 3.29c.2.2.45.3.71.3s.51-.1.71-.29l3.29-3.3c.39-.39.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEuro = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 20c-3.35 0-6.22-2.07-7.41-5H13v-2H6.07c-.04-.33-.07-.66-.07-1s.03-.67.07-1H15V9H6.59C7.78 6.07 10.65 4 14 4a7.95 7.95 0 0 1 5.66 2.34l1.41-1.41A9.93 9.93 0 0 0 14 2C9.53 2 5.74 4.95 4.46 9H3v2h1.05c-.03.33-.05.66-.05 1s.02.67.05 1H3v2h1.46c1.28 4.05 5.07 7 9.54 7a9.93 9.93 0 0 0 7.07-2.93l-1.41-1.41A7.95 7.95 0 0 1 14 20z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExclamationCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 14.37h0c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v7c0 .56-.45 1-1 1z\"/><circle cx=\"12\" cy=\"17.38\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExclamationTriangle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2.77c.18 0 .62.05.88.52l8.85 16.23a.97.97 0 0 1-.02.99c-.09.15-.34.49-.86.49H3.15c-.51 0-.77-.34-.86-.49s-.26-.54-.02-.99l8.85-16.23c.26-.47.7-.52.88-.52m0-2c-1.03 0-2.07.52-2.63 1.56L.51 18.56C-.58 20.56.87 23 3.14 23h17.7c2.28 0 3.72-2.44 2.63-4.44L14.63 2.33C14.07 1.29 13.03.77 12 .77h0zM12 15h0c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v6c0 .55-.45 1-1 1z\"/><circle cx=\"12\" cy=\"18\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgExternalLink = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 22H7c-2.76 0-5-2.24-5-5V7c0-2.76 2.24-5 5-5h3c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1H7C5.34 4 4 5.34 4 7v10c0 1.66 1.34 3 3 3h10c1.66 0 3-1.34 3-3v-3c0-.55.45-1 1-1h0c.55 0 1 .45 1 1v3c0 2.76-2.24 5-5 5zm5-21h-7c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h4.59L12 10.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L21 4.41V9c0 .55.45 1 1 1h0c.55 0 1-.45 1-1V2c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEye = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 6c4.69 0 7.82 3.39 9.27 5.4.26.36.26.83 0 1.19C19.82 14.61 16.69 18 12 18s-7.82-3.39-9.27-5.4c-.26-.36-.26-.83 0-1.19C4.18 9.39 7.31 6 12 6m0-2C6.43 4 2.8 7.88 1.1 10.24c-.76 1.06-.76 2.47 0 3.52C2.8 16.12 6.43 20 12 20s9.2-3.88 10.9-6.24c.76-1.06.76-2.47 0-3.52C21.2 7.88 17.57 4 12 4h0zm0 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgEyeSlashed = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.9 10.24C21.2 7.88 17.57 4 12 4c-2.03 0-3.79.52-5.3 1.28l1.5 1.51C9.33 6.31 10.59 6 12 6c4.69 0 7.82 3.39 9.27 5.4.26.36.26.83 0 1.19-.76 1.06-1.98 2.49-3.65 3.61l1.44 1.44c1.73-1.24 3.01-2.74 3.83-3.89.76-1.05.76-2.45.01-3.51zM12 10c1.1 0 2 .9 2 2 0 .18-.03.35-.07.51l1.51 1.51A3.95 3.95 0 0 0 16 12a4 4 0 0 0-4-4c-.74 0-1.43.22-2.02.57l1.51 1.51A1.7 1.7 0 0 1 12 10zm7.71 10.29l-1.87-1.87-1.48-1.48-1.85-1.85-1.42-1.42-2.76-2.76-1.42-1.42-2.03-2.02-1.45-1.45-1.72-1.73c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41l1.53 1.53a18.18 18.18 0 0 0-2.72 3c-.76 1.06-.76 2.47 0 3.52C2.8 16.12 6.43 20 12 20a11.57 11.57 0 0 0 3.91-.68l2.39 2.39c.19.19.44.29.7.29s.51-.1.71-.29a1.01 1.01 0 0 0 0-1.42zM12 18c-4.69 0-7.82-3.39-9.27-5.4-.26-.36-.26-.83 0-1.19.57-.8 1.42-1.81 2.51-2.75l2.81 2.81c-.02.17-.05.35-.05.53a4 4 0 0 0 4 4c.18 0 .36-.03.53-.05l1.77 1.77A9.99 9.99 0 0 1 12 18z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFacebook = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M24 12.07C24 5.27 18.41-.22 11.6 0 5.56.2.47 5.11.03 11.18-.43 17.6 4.11 23.05 10.12 24v-8.44H7.08v-3.49h3.05V9.41c0-3.03 1.79-4.7 4.53-4.7 1.31 0 2.69.24 2.69.24v2.97h-1.51c-1.49 0-1.96.93-1.96 1.89v2.26h3.33l-.53 3.49h-2.8V24C19.61 23.09 24 18.1 24 12.07z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFamilyRc = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13zm-8.35 8.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11-2-.7-3.37-1.79-4.31-2.96.12-.43.49-.76.96-.76h8c.47 0 .84.33.95.76-.94 1.18-2.31 2.26-4.3 2.96zm5.45-4.84c-.54-.54-1.28-.88-2.1-.88H8c-.82 0-1.56.34-2.1.88-.9-1.95-.9-3.7-.9-3.83V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .14 0 1.88-.9 3.84zM12 6a4 4 0 1 0 0 8 4 4 0 1 0 0-8zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFax = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M5.62 10.2V15H3.64v-4.8H2.61V8.54h1.03v-.56c0-1.48.9-2.5 2.47-2.5.4 0 .75.05.94.14v1.61c-.1-.03-.27-.06-.59-.06-.35 0-.84.16-.84.87v.49h1.4v1.66h-1.4zm4.03.89l1.52-.23c.35-.05.47-.22.47-.44 0-.38-.33-.7-.95-.7-.69 0-1.07.47-1.1.95l-1.72-.35c.07-.94.93-2.16 2.83-2.16 2.09 0 2.86 1.17 2.86 2.5v3.16c0 .51.06.94.08 1h-1.78c-.01-.05-.06-.29-.06-.73-.35.55-.97.91-1.83.91-1.42 0-2.24-.94-2.24-1.96.01-1.15.85-1.8 1.92-1.95zm1.99 1.19V12l-1.22.2c-.42.06-.73.26-.73.71 0 .34.22.66.77.66.58 0 1.18-.29 1.18-1.29zm5.11-.5l-2.26-3.24h2.33l1.16 1.77 1.13-1.77h2.21l-2.24 3.16 2.31 3.3h-2.31l-1.2-1.82L16.7 15h-2.17l2.22-3.22z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFloppyDisk = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.12 7.12L18 3h0l-1.12-1.12c-.56-.56-1.33-.88-2.12-.88H6 4C2.34 1 1 2.34 1 4v16c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM14.76 3a.99.99 0 0 1 .71.29l.53.54V9c0 .55-.45 1-1 1H9c-.55 0-1-.45-1-1V3h6.76zM21 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h2v6c0 1.66 1.34 3 3 3h6c1.66 0 3-1.34 3-3V5.83l2.71 2.71c.19.18.29.44.29.7V20z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgFolder = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8.76 5a.99.99 0 0 1 .71.29l1.83 1.83c.56.57 1.31.88 2.11.88H20c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h4.76m0-2H4C2.34 3 1 4.34 1 6v12c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3h-6.59c-.27 0-.52-.11-.71-.29l-1.83-1.83C10.32 3.32 9.55 3 8.76 3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGear = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 3v.95a2 2 0 0 0 1.11 1.79c.3.15.55.29.76.43.33.22.72.34 1.11.34.34 0 .69-.09 1-.27l.81-.47 2 3.46-.82.47c-.66.38-1.04 1.1-1 1.86l.01.15.02.29-.01.29-.01.15c-.05.76.34 1.48 1 1.86l.82.47-2 3.46-.81-.47c-.31-.18-.66-.27-1-.27a1.96 1.96 0 0 0-1.11.34c-.21.14-.46.28-.76.43A1.99 1.99 0 0 0 14 20.05V21h-4v-.95a2 2 0 0 0-1.11-1.79c-.3-.15-.55-.29-.76-.43-.33-.22-.72-.34-1.11-.34-.34 0-.69.09-1 .27l-.81.47-2-3.46.82-.47c.66-.38 1.04-1.1 1-1.86l-.01-.15L5 12l.01-.29.01-.15c.05-.76-.34-1.48-1-1.86l-.81-.47 2-3.46.81.47c.31.18.66.27 1 .27a1.96 1.96 0 0 0 1.11-.34c.21-.14.46-.28.76-.43A2 2 0 0 0 10 3.95V3h4m0-2h-4c-1.1 0-2 .9-2 2v.95c-.34.16-.67.35-.98.56l-.81-.47c-.31-.18-.66-.27-1-.27-.69 0-1.36.36-1.73 1l-2 3.46c-.55.96-.22 2.18.73 2.73l.82.47L3 12c0 .19.02.37.03.56l-.82.47c-.96.55-1.28 1.78-.73 2.73l2 3.46c.37.64 1.04 1 1.73 1a2.03 2.03 0 0 0 1-.27l.81-.47a7.36 7.36 0 0 0 .98.57V21c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2v-.95c.34-.17.67-.35.98-.56l.81.47c.31.18.66.27 1 .27.69 0 1.36-.36 1.73-1l2-3.46c.55-.96.22-2.18-.73-2.73l-.82-.47L21 12c0-.19-.02-.37-.03-.56l.82-.47c.96-.55 1.28-1.78.73-2.73l-2-3.46c-.37-.64-1.04-1-1.73-1a2.03 2.03 0 0 0-1 .27l-.81.47a6.41 6.41 0 0 0-.98-.57V3c0-1.1-.9-2-2-2h0zm-2 8a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGift = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 7h-4c1.66 0 3-1.34 3-3h0c0-1.66-1.34-3-3-3s-3 1.34-3 3c0-1.66-1.34-3-3-3S6 2.34 6 4h0c0 1.66 1.34 3 3 3H5c-1.66 0-3 1.34-3 3v10c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3V10c0-1.66-1.34-3-3-3zm0 2c.55 0 1 .45 1 1v4h-7V9h6zm-5-5c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1h-1V4zM8 4c0-.55.45-1 1-1s1 .45 1 1v1H9c-.55 0-1-.45-1-1zM5 9h6v5H4v-4c0-.55.45-1 1-1zM4 20v-4h7v5H5c-.55 0-1-.45-1-1zm15 1h-6v-5h7v4c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgGpsDisabled = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 11h-1.06c-.46-4.17-3.77-7.48-7.94-7.94V2c0-.55-.45-1-1-1s-1 .45-1 1v1.06C6.83 3.52 3.52 6.83 3.06 11H2c-.55 0-1 .45-1 1s.45 1 1 1h1.06c.46 4.17 3.77 7.48 7.94 7.94V22c0 .55.45 1 1 1s1-.45 1-1v-1.06c4.17-.46 7.48-3.77 7.94-7.94H22c.55 0 1-.45 1-1s-.45-1-1-1zm-9 7.92V18c0-.55-.45-1-1-1s-1 .45-1 1v.92A7 7 0 0 1 5.08 13H6c.55 0 1-.45 1-1s-.45-1-1-1h-.92A7 7 0 0 1 11 5.08V6c0 .55.45 1 1 1s1-.45 1-1v-.92A7 7 0 0 1 18.92 11H18c-.55 0-1 .45-1 1s.45 1 1 1h.92A7 7 0 0 1 13 18.92z\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgGpsEnabled = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22,11h-1.06c-.46-4.17-3.77-7.48-7.94-7.94v-1.06c0-.55-.45-1-1-1s-1,.45-1,1v1.06c-4.17.46-7.48,3.77-7.94,7.94h-1.06c-.55,0-1,.45-1,1s.45,1,1,1h1.06c.46,4.17,3.77,7.48,7.94,7.94v1.06c0,.55.45,1,1,1s1-.45,1-1v-1.06c4.17-.46,7.48-3.77,7.94-7.94h1.06c.55,0,1-.45,1-1s-.45-1-1-1ZM12,19c-3.86,0-7-3.14-7-7s3.14-7,7-7,7,3.14,7,7-3.14,7-7,7Z\"/><circle cx=\"12\" cy=\"12\" r=\"3\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgGrid = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 3v6H3V3h6m0-2H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0zm12 2v6h-6V3h6m0-2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2h0zM9 15v6H3v-6h6m0-2H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h0zm12 2v6h-6v-6h6m0-2h-6c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1h-3v-5a3.01 3.01 0 0 0-3-3h-2a3.01 3.01 0 0 0-3 3v5H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h3c1.1 0 2-.9 2-2v-5c0-.55.45-1 1-1h2c.55 0 1 .45 1 1v5c0 1.1.9 2 2 2h3c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageEbike = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 13c-.1 0-.2.02-.3.03l-.82-3.07C17.41 8.22 15.83 7 14.02 7H13c-.55 0-1 .45-1 1s.45 1 1 1h1.02c.9 0 1.7.61 1.93 1.48l.01.03-4.46 2.57-1.16-1.16A.96.96 0 0 0 11 11c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1h1.59l2.13 2.13-.99.57C7.81 13.66 6.49 13 5 13c-2.76 0-5 2.24-5 5s2.24 5 5 5a5 5 0 0 0 4.9-4h4.2a5 5 0 0 0 4.9 4c2.76 0 5-2.24 5-5s-2.24-5-5-5zm-2.5-.48l.28 1.03c-1.35.68-2.36 1.93-2.67 3.45H9.9c-.04-.2-.11-.38-.18-.57l6.78-3.91zM5 21a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3c1.3 0 2.4.84 2.82 2H5c-.55 0-1 .45-1 1s.45 1 1 1h2.82C7.4 20.16 6.3 21 5 21zm14 0a3.01 3.01 0 0 1-3-3c0-1.02.52-1.93 1.3-2.47l.73 2.73c.12.45.53.74.97.74.09 0 .17-.01.26-.03.53-.14.85-.69.71-1.22l-.73-2.72c1.54.12 2.76 1.4 2.76 2.97a3.01 3.01 0 0 1-3 3zM8 2v3H3l7 3V5h5L8 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageFamily = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16.37 9.52a8.01 8.01 0 0 0 0 8.96A6.99 6.99 0 0 0 18 14a6.99 6.99 0 0 0-1.63-4.48zM7.12 8.17A6.96 6.96 0 0 1 11 7a6.96 6.96 0 0 1 3.88 1.17C13.7 9.81 13 11.82 13 14s.7 4.19 1.88 5.83A6.96 6.96 0 0 1 11 21a6.96 6.96 0 0 1-3.88-1.17C8.3 18.19 9 16.18 9 14s-.7-4.19-1.88-5.83zm10.59 11.82a8.71 8.71 0 0 1-1.5 1.34C14.74 22.38 12.95 23 11 23s-3.74-.62-5.21-1.67a8.71 8.71 0 0 1-1.5-1.34 8.97 8.97 0 0 1 0-11.98 8.71 8.71 0 0 1 1.5-1.34C7.26 5.62 9.05 5 11 5s3.74.62 5.21 1.67a8.71 8.71 0 0 1 1.5 1.34 8.97 8.97 0 0 1 0 11.98zM5.62 9.53C4.61 10.74 4 12.3 4 14s.61 3.26 1.62 4.47C6.49 17.2 7 15.65 7 14s-.51-3.2-1.38-4.47z\" fill-rule=\"evenodd\"/><path d=\"M19.07 5.93c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l1.41-1.41c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-1.41 1.41c-.19.19-.44.29-.7.29zM20.66 8c-.35 0-.68-.18-.87-.5-.28-.48-.11-1.09.37-1.37l1.73-1c.48-.28 1.09-.11 1.37.37s.11 1.09-.37 1.37l-1.73 1a1.01 1.01 0 0 1-.5.13zM17 4.34a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l1-1.73A1.01 1.01 0 0 1 18.5.74a1.01 1.01 0 0 1 .37 1.37l-1 1.73a1.02 1.02 0 0 1-.87.5z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamagePets = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M4 9c.15 0 .45.04.71.29.39.39.39 1.02 0 1.41-.26.26-.56.3-.71.3s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C5.54 7.29 4.77 7 4 7h0zm4.5-3c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C10.04 2.29 9.27 2 8.5 2h0zm7 2c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C17.04 2.29 16.27 2 15.5 2h0zM20 9c.15 0 .45.04.71.29.25.26.29.56.29.71s-.04.45-.29.71c-.26.25-.56.29-.71.29s-.45-.04-.71-.29c-.39-.39-.39-1.02 0-1.41.26-.26.56-.3.71-.3m0-2c-.77 0-1.54.29-2.12.88a3 3 0 0 0 0 4.24c.58.59 1.35.88 2.12.88s1.54-.29 2.12-.88a3 3 0 0 0 0-4.24C21.54 7.29 20.77 7 20 7h0zm-8 5.49c1.07 0 2.07.42 2.83 1.17l1.96 1.96c.97.97 1.11 2.49.31 3.47a2.46 2.46 0 0 1-1.93.91c-.35 0-.69-.07-1.01-.21-.69-.31-1.42-.46-2.16-.46s-1.47.16-2.16.46c-.32.14-.66.21-1.01.21-.74 0-1.45-.33-1.93-.91-.8-.97-.67-2.49.31-3.47l1.96-1.96A3.96 3.96 0 0 1 12 12.49m0-2c-1.54 0-3.07.59-4.24 1.76L5.8 14.21c-1.66 1.66-1.92 4.35-.43 6.15C6.26 21.45 7.55 22 8.83 22c.62 0 1.24-.13 1.82-.38a3.38 3.38 0 0 1 1.35-.29c.46 0 .92.1 1.35.29.58.26 1.2.38 1.82.38a4.49 4.49 0 0 0 3.47-1.64c1.49-1.81 1.22-4.5-.43-6.15l-1.96-1.96A5.97 5.97 0 0 0 12 10.49h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDamageThirdParties = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 18h-.23L16.08 4.22C15.73 2.91 14.53 2 13.18 2h-2.36a3 3 0 0 0-2.9 2.22L4.23 18H4c-1.1 0-2 .9-2 2v1c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-1c0-1.1-.9-2-2-2zM10.82 4h2.36c.45 0 .85.3.97.74L15.02 8H8.98l.87-3.26c.12-.44.52-.74.97-.74zm-2.37 6h7.11l.8 3H7.64l.81-3zm-1.34 5h9.79l.8 3H6.3l.81-3zM20 21H4v-1h16v1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeDomesticThefts = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.83 9.9L8.43 2.17a2.89 2.89 0 0 0-1.49-.41c-1.04 0-2.05.54-2.6 1.5l-2 3.47C1.51 8.16 2 10 3.43 10.83L8 13.46V15c0 1.1-.9 2-2 2h-.11c-.38-1.87-1.8-3.37-3.64-3.84a1.07 1.07 0 0 0-.25-.03c-.54 0-1 .44-1 1.01v7.74a1.01 1.01 0 0 0 1 1.01 1.07 1.07 0 0 0 .25-.03c1.84-.47 3.26-1.97 3.64-3.84H6a4 4 0 0 0 4-4v-.38l1.83 1.06a2.96 2.96 0 0 0 1.5.4c.36 0 .73-.07 1.08-.2.23.22.49.42.78.59.63.36 1.32.54 2 .54a4.02 4.02 0 0 0 3.47-2 4 4 0 0 0 .42-2.93l.75-.44c.66-.41.66-1.38 0-1.76zM3 20.24v-4.48a3.01 3.01 0 0 1 0 4.48zM18.92 14c-.35.61-1.02 1-1.73 1-.3 0-.59-.07-.86-.2l2.85-1.64a1.96 1.96 0 0 1-.26.84zm-.61-2.65l-3.11 1.8-1.37.79c-.16.09-.32.13-.49.13h-.01c-.18 0-.35-.04-.5-.13l-8.4-4.85c-.47-.27-.64-.88-.36-1.36l2-3.47a.99.99 0 0 1 .87-.5c.17 0 .34.05.49.14l8.99 5.18 2.91 1.68-1.02.59z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeEmergenciesRepairs = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 5h-3.18C19.4 3.84 18.3 3 17 3c0-1.1-.9-2-2-2H5C2.24 1 0 3.24 0 6a5 5 0 0 0 4 4.9V20c0 1.66 1.34 3 3 3h.7a3 3 0 0 0 2.9-2.22L13.22 11H15c1.1 0 2-.9 2-2 1.3 0 2.4-.84 2.82-2H23c.55 0 1-.45 1-1s-.45-1-1-1zM8.66 20.26c-.11.44-.51.74-.96.74H7c-.55 0-1-.45-1-1v-9h5.14l-2.48 9.26zM15 9h-1.25H5a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3h10v6zm2-2V5c.55 0 1 .45 1 1s-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeFireExplosion = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10.78 3.16c4.68.85 8.22 5 8.22 9.84v.69c0 3.85-2.87 7.06-6.53 7.29-.16.01-.31.02-.47.02a7.01 7.01 0 0 1-7-7c0-2.13.95-4.11 2.6-5.45 1.63-1.32 2.74-3.2 3.18-5.39m-.86-2.12c-.49 0-.92.36-.97.85-.2 2.04-1.11 3.9-2.61 5.1C4.3 8.64 3 11.17 3 14c0 4.97 4.03 9 9 9 .2 0 .4-.01.6-.02 4.78-.31 8.4-4.5 8.4-9.29V13c0-6.29-4.84-11.45-11-11.96h-.08 0zm3.91 11.09c.67 1.03.74 2.38.1 3.49l-.14.24c-.4.69-1.14 1.14-1.89 1.14a1.7 1.7 0 0 1-.74-.16c-.53-.24-.91-.67-1.09-1.22-.17-.54-.11-1.12.17-1.61a1.99 1.99 0 0 1 1.42-.98c.77-.11 1.51-.43 2.17-.9m.12-2.42c-.28 0-.56.13-.77.35-.5.53-1.13.9-1.83 1-1.15.18-2.22.86-2.84 1.95-1.17 2.03-.37 4.65 1.81 5.64.51.23 1.05.34 1.58.34 1.45 0 2.86-.81 3.62-2.14l.14-.24a5.34 5.34 0 0 0-1.14-6.71 1 1 0 0 0-.57-.19h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeFurnitureContent = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 10.18V7c0-.55-.45-1-1-1h-1a3.01 3.01 0 0 1-3-3V2c0-.55-.45-1-1-1H8a4 4 0 0 0-4 4v5.18C2.84 10.6 2 11.7 2 13v6c0 1.1.9 2 2 2 0 1.1.9 2 2 2s2-.9 2-2H6h12-2c0 1.1.9 2 2 2s2-.9 2-2c1.1 0 2-.9 2-2v-6c0-1.3-.84-2.4-2-2.82zM8 16h8v3H8v-3zm-4-3c0-.55.45-1 1-1s1 .45 1 1v6h0 0-2v-6zm12 0v1H8v-1c0-1.3-.84-2.4-2-2.82V5c0-1.1.9-2 2-2h5c0 2.76 2.24 5 5 5v2.18c-1.16.42-2 1.52-2 2.82zm4 6h-2v-6c0-.55.45-1 1-1s1 .45 1 1v6z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFamily = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15 3v4 2h2 4v6h-4-2v2 4H9v-4-2H7 3V9h4 2V7 3h6m0-2H9c-1.1 0-2 .9-2 2v4H3c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h4v4c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2v-4h4c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2h-4V3c0-1.1-.9-2-2-2h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFreetime = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.49 3.51h0a5.99 5.99 0 0 0-4.24-1.76c-1.53 0-3.08.59-4.25 1.76L3.51 12c-2.34 2.34-2.34 6.14 0 8.49a5.99 5.99 0 0 0 8.48 0l8.5-8.49c2.34-2.34 2.34-6.14 0-8.49zm-9.9 15.56a3.96 3.96 0 0 1-2.83 1.17c-1.07 0-2.07-.42-2.83-1.17a3.96 3.96 0 0 1-1.17-2.83c0-1.07.42-2.07 1.17-2.83l3.54-3.54 5.66 5.66-3.54 3.54zm8.48-8.48l-3.54 3.54-5.65-5.67 3.54-3.54a3.96 3.96 0 0 1 2.83-1.17c1.07 0 2.07.42 2.83 1.17a4.03 4.03 0 0 1-.01 5.67z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeInsuranceHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0zm3 10.83V10.5a3.01 3.01 0 0 0-3-3 3.01 3.01 0 0 0-3 3v1.57c-.86.22-1.5 1-1.5 1.93v3c0 1.1.9 2 2 2h5c1.1 0 2-.9 2-2v-3c0-.93-.64-1.71-1.5-1.93zM12 9.5c.55 0 1 .45 1 1V12h-2v-1.5c0-.55.45-1 1-1zm2.5 7.5h-5v-3h5v3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeLegalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.65 21.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v6.81l1.96 1.13c.05-.51.04-.83.04-.83V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.94 2.39C3.75 4.02 3 4.88 3 5.89V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22c2.68-.94 4.41-2.46 5.55-4.07l-1.75-1.01c-.94 1.27-2.34 2.45-4.46 3.19zm8.95-5.21l-5.63-3.25.5-.87a3 3 0 0 0-1.1-4.1l-.87-.5c-.47-.27-.99-.4-1.5-.4-1.04 0-2.05.54-2.6 1.5l-2 3.46a3 3 0 0 0 1.1 4.1l.87.5c.47.27.99.4 1.5.4 1.04 0 2.05-.54 2.6-1.5l.5-.87 5.63 3.25a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5.27-.46.1-1.07-.37-1.35zm-8.87-1.65c-.18.31-.51.5-.87.5-.18 0-.34-.05-.5-.13l-.87-.5c-.31-.18-.43-.46-.47-.61a1 1 0 0 1 .1-.76l2-3.46c.18-.31.51-.5.87-.5.18 0 .34.05.5.13l.87.5c.31.18.43.46.47.61a1 1 0 0 1-.1.76l-2 3.46z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeNaturalEvents = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.11 23a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l2.02-3.5H10c-.36 0-.69-.19-.87-.5s-.18-.69 0-1l2.89-5a1.01 1.01 0 0 1 1.37-.37 1.01 1.01 0 0 1 .37 1.37L11.73 16H15c.36 0 .69.19.87.5s.18.69 0 1l-2.89 5c-.19.32-.52.5-.87.5zm6.88-15.86c0-.05.01-.09.01-.14 0-3.31-2.69-6-6-6-2.62 0-4.85 1.69-5.66 4.03C7.22 5.03 7.12 5 7 5c-2.76 0-5 2.24-5 5s2.24 5 5 5h.69l1.15-2H7a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 2.93-3l.27.03 1.52.1.5-1.44C9.78 4.08 11.3 3 13 3c2.19 0 3.98 1.77 4 3.96 0 .04-.01.09-.01.13l-.04 1.59 1.54.4C19.38 9.3 20 10.1 20 11c0 1.1-.9 2-2 2h-2.29c-.06.17-.13.34-.22.5l-.87 1.5H18a4 4 0 0 0 4-4 3.99 3.99 0 0 0-3.01-3.86z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeRobberiesOutside = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19.3 10.31c-.54-2.33-2.16-4.25-4.31-5.18 0-.05.01-.09.01-.13 0-1.66-1.34-3-3-3S9 3.34 9 5c0 .04.01.08.01.12-2.15.94-3.77 2.86-4.31 5.19-1 .48-1.7 1.5-1.7 2.69 0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3 0-1.19-.7-2.21-1.7-2.69zm-8.19-5.75c.17-.33.5-.56.89-.56s.72.23.89.56c-.3-.04-.59-.06-.89-.06s-.59.02-.89.06zM18 14H6c-.55 0-1-.45-1-1a1 1 0 0 1 .57-.89l.86-.42.22-.93C7.23 8.25 9.43 6.5 12 6.5s4.77 1.75 5.35 4.26l.22.93.86.42c.28.13.57.44.57.89 0 .55-.45 1-1 1zm1.87 4h-7.74c-.05 0-.09.01-.13.02-.04-.01-.09-.02-.13-.02H4.13c-.65 0-1.14.62-.97 1.25A4.99 4.99 0 0 0 8 23c1.64 0 3.09-.8 4-2.02.91 1.22 2.36 2.02 4 2.02a4.99 4.99 0 0 0 4.84-3.75c.17-.63-.32-1.25-.97-1.25zM8 21a3.01 3.01 0 0 1-2.24-1h4.48A3.01 3.01 0 0 1 8 21zm8 0a3.01 3.01 0 0 1-2.24-1h4.48A3.01 3.01 0 0 1 16 21z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeTelephoneLegalAdvice = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 2.67c.71.46 1.7.91 3 1.15v2.92c0 .03-.03 3.14-3 4.17-2.86-.99-2.99-3.86-3-4.17V3.82c1.3-.23 2.29-.69 3-1.15m0-2.24c-.22 0-.44.07-.61.22-.61.5-1.7 1.14-3.48 1.31a1 1 0 0 0-.91.99v3.8s0 4.61 4.43 6.09a1.79 1.79 0 0 0 .57.09c.19 0 .38-.03.57-.09C22 11.36 22 6.75 22 6.75v-3.8a1 1 0 0 0-.9-.99c-1.78-.17-2.88-.8-3.48-1.31A.96.96 0 0 0 17 .43h0zm-.98 22.25a4 4 0 0 1-.84-.09C8.3 21.11 2.89 15.7 1.41 8.81A3.88 3.88 0 0 1 2.2 5.5C2.97 4.55 4.11 4 5.33 4h1.03A3 3 0 0 1 9.3 6.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.13 2.65 3.5 3.67l.08-.08c.71-.71 1.73-1.02 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.47.88zM5.33 6a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25 1.98 1.98 0 0 0 1.64-.39 2.01 2.01 0 0 0 .75-1.57v-1.03c0-.47-.34-.89-.8-.98l-2.34-.47c-.33-.06-.67.04-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.56-.79-.46-1.86.24-2.55.27-.27.37-.61.31-.94L7.34 6.8a1 1 0 0 0-.98-.8H5.33z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHomeTheftValuable = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M15.73 7.93l1.5-2.6a2 2 0 0 0-.32-2.41l-1.33-1.33c-.37-.38-.88-.59-1.41-.59H9.83c-.53 0-1.04.21-1.42.59L7.09 2.91a2 2 0 0 0-.32 2.42l1.5 2.6C5.73 9.27 4 11.93 4 15a8 8 0 1 0 16 0c0-3.07-1.73-5.73-4.27-7.07zM8.5 4.33L9.83 3h4.34 0l1.33 1.33-1.67 2.89C13.24 7.08 12.63 7 12 7s-1.24.08-1.83.22L8.5 4.33zM12 21c-3.31 0-6-2.69-6-6 0-2.32 1.33-4.34 3.27-5.34a5.98 5.98 0 0 1 5.46 0c1.94 1 3.27 3.01 3.27 5.34 0 3.31-2.69 6-6 6z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgHourglass = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.83 8.59C18.57 7.84 19 6.81 19 5.76V4a3.01 3.01 0 0 0-3-3H8a3.01 3.01 0 0 0-3 3v1.76c0 1.05.43 2.08 1.17 2.83l2.71 2.71a.99.99 0 0 1 .29.71.99.99 0 0 1-.29.71l-2.71 2.71C5.42 16.17 5 17.17 5 18.24V20a3.01 3.01 0 0 0 3 3h8a3.01 3.01 0 0 0 3-3v-1.76c0-1.07-.42-2.07-1.17-2.83l-2.71-2.71a.99.99 0 0 1-.29-.71.99.99 0 0 1 .29-.71l2.71-2.69zM17 20c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1v-1h10v1zm-3.29-5.88l2.71 2.71c.05.05.09.11.14.17H7.45l.14-.17 2.71-2.71a2.97 2.97 0 0 0 .88-2.12 2.97 2.97 0 0 0-.88-2.12L7.59 7.17C7.21 6.8 7 6.28 7 5.76V4c0-.55.45-1 1-1h8c.55 0 1 .45 1 1v1.76c0 .53-.21 1.04-.59 1.41L13.7 9.88a2.97 2.97 0 0 0-.88 2.12c.01.8.32 1.55.89 2.12z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgIdCard = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 2.5H3c-1.66 0-3 1.34-3 3v13c0 1.66 1.34 3 3 3h3 12 3c1.66 0 3-1.34 3-3v-13c0-1.66-1.34-3-3-3zm-13 17v-2c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v2H8zm14-1c0 .55-.45 1-1 1h-3v-2c0-1.66-1.34-3-3-3H9c-1.66 0-3 1.34-3 3v2H3c-.55 0-1-.45-1-1v-13c0-.55.45-1 1-1h18c.55 0 1 .45 1 1v13zm-10-12c-1.93 0-3.5 1.57-3.5 3.5s1.57 3.5 3.5 3.5 3.5-1.57 3.5-3.5-1.57-3.5-3.5-3.5zm0 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 1 1 0 3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInfoCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm0 18.62h0c-.55 0-1-.45-1-1v-6h0c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h1c.55 0 1 .45 1 1v7c0 .56-.45 1-1 1z\"/><circle cx=\"12\" cy=\"6.63\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInstagram = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2.98l4.44.06c1.07.05 1.65.23 2.04.38.51.2.88.44 1.26.82s.62.75.82 1.26c.15.39.33.97.38 2.04l.06 4.44-.06 4.44c-.05 1.07-.23 1.65-.38 2.04-.2.51-.44.88-.82 1.26s-.75.62-1.26.82c-.39.15-.97.33-2.04.38l-4.44.06c-2.93 0-3.29-.01-4.44-.06-1.07-.05-1.65-.23-2.04-.38-.51-.2-.88-.44-1.26-.82-.4-.35-.64-.72-.84-1.23-.15-.39-.33-.97-.38-2.04L2.98 12l.06-4.44c.06-1.08.23-1.66.38-2.05a3.34 3.34 0 0 1 .83-1.26 3.34 3.34 0 0 1 1.26-.83c.39-.15.97-.32 2.05-.37L12 2.98M12 1l-4.54.07c-1.17.05-1.97.24-2.67.51-.72.28-1.33.65-1.94 1.27-.62.61-.99 1.22-1.27 1.94-.27.7-.46 1.5-.51 2.67L1 12l.07 4.54c.05 1.17.24 1.97.51 2.67a5.42 5.42 0 0 0 1.27 1.95c.61.61 1.22.99 1.95 1.27.7.27 1.5.46 2.67.51L12 23l4.54-.07c1.17-.05 1.97-.24 2.67-.51a5.42 5.42 0 0 0 1.95-1.27c.61-.61.99-1.22 1.27-1.95.27-.7.46-1.5.51-2.67L23 12l-.07-4.54c-.05-1.17-.24-1.97-.51-2.67a5.42 5.42 0 0 0-1.27-1.95c-.61-.61-1.22-.99-1.95-1.27-.7-.27-1.5-.46-2.67-.51L12 1h0zm0 5.35a5.65 5.65 0 0 0 0 11.3 5.65 5.65 0 0 0 0-11.3zm0 9.32c-2.03 0-3.67-1.64-3.67-3.67S9.97 8.33 12 8.33s3.67 1.64 3.67 3.67-1.64 3.67-3.67 3.67z\"/><circle cx=\"17.87\" cy=\"6.13\" r=\"1.32\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgInsuranceHome = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.24c.15 0 .45.04.71.29l6.71 6.71c.38.38.59.88.59 1.41V20c0 .55-.45 1-1 1H5c-.55 0-1-.45-1-1v-8.34c0-.53.21-1.04.59-1.41l6.71-6.71c.25-.26.55-.3.7-.3m0-2c-.77 0-1.54.29-2.12.88L3.17 8.83C2.42 9.58 2 10.6 2 11.66V20c0 1.66 1.34 3 3 3h14c1.66 0 3-1.34 3-3v-8.34c0-1.06-.42-2.08-1.17-2.83l-6.71-6.71A3 3 0 0 0 12 1.24h0zm3 10.83V10.5a3.01 3.01 0 0 0-3-3 3.01 3.01 0 0 0-3 3v1.57c-.86.22-1.5 1-1.5 1.93v3c0 1.1.9 2 2 2h5c1.1 0 2-.9 2-2v-3c0-.93-.64-1.71-1.5-1.93zM12 9.5c.55 0 1 .45 1 1V12h-2v-1.5c0-.55.45-1 1-1zm2.5 7.5h-5v-3h5v3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgJobs = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 6h-3a4 4 0 0 0-4-4h-2a4 4 0 0 0-4 4H4C2.34 6 1 7.34 1 9v4 7c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.66-1.34-3-3-3zM9 6c0-1.1.9-2 2-2h2c1.1 0 2 .9 2 2H9zM4 8h16c.55 0 1 .45 1 1v3h-7c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1H3V9c0-.55.45-1 1-1zm16 13H4c-.55 0-1-.45-1-1v-6h7v1c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-1h7v6c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgKey = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M8 19a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3 3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 8a7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7c.76 0 1.5.12 2.22.36l8.07-8.07c.19-.18.44-.29.71-.29h3c.55 0 1 .45 1 1v3c0 .27-.11.52-.29.71l-1 1c-.19.18-.44.29-.71.29h-1v1c0 .27-.11.52-.29.71l-1 1c-.19.18-.44.29-.71.29h-1v1c0 .27-.11.52-.29.71l-2.07 2.07c.24.72.36 1.46.36 2.22a7.01 7.01 0 0 1-7 7zm0-12c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5c0-.71-.15-1.4-.45-2.05a1.01 1.01 0 0 1 .2-1.12L15 10.59V9c0-.55.45-1 1-1h1.59l.41-.41V6c0-.55.45-1 1-1h1.59l.41-.41V3h-1.59l-8.24 8.24a.99.99 0 0 1-1.12.2C9.4 11.15 8.71 11 8 11z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgKeyhole = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3c2.76 0 5 2.24 5 5a4.99 4.99 0 0 1-1.84 3.87l-1 .82.33 1.25 1.9 7.06H7.61l1.89-7.07.33-1.25-1-.82C7.71 10.95 7.04 9.58 7 8.13c-.03-1.31.47-2.58 1.42-3.57.94-.98 2.19-1.54 3.49-1.56H12m0-2h-.12C8.06 1.06 4.9 4.36 5 8.18c.05 2.11 1.04 3.99 2.57 5.24l-1.89 7.07C5.33 21.75 6.29 23 7.61 23h8.79a2 2 0 0 0 1.93-2.52l-1.89-7.07C18 12.13 19 10.18 19 8c0-3.87-3.13-7-7-7h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLink = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 13H7c-.55 0-1-.45-1-1s.45-1 1-1h10c.55 0 1 .45 1 1s-.45 1-1 1zm-8 6H7a7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7h2c.55 0 1 .45 1 1s-.45 1-1 1H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h2c.55 0 1 .45 1 1s-.45 1-1 1zm8 0h-2c-.55 0-1-.45-1-1s.45-1 1-1h2c2.76 0 5-2.24 5-5s-2.24-5-5-5h-2c-.55 0-1-.45-1-1s.45-1 1-1h2a7.01 7.01 0 0 1 7 7 7.01 7.01 0 0 1-7 7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLinkedin = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path fill-rule=\"evenodd\" d=\"M21.41 1H2.66C1.76 1 1 1.71 1 2.58v18.83c0 .88.76 1.59 1.66 1.59h18.75c.9 0 1.59-.71 1.59-1.58V2.58A1.58 1.58 0 0 0 21.41 1zM9.71 9.25h2.98v1.5c.43-.86 1.53-1.63 3.18-1.63 3.17 0 3.92 1.71 3.92 4.86v5.82h-3.21v-5.11c0-1.79-.43-2.8-1.52-2.8-1.51 0-2.14 1.09-2.14 2.8v5.11H9.71V9.25zm-5.5 10.54h3.21V9.25H4.21v10.54zM7.87 5.81c0 1.14-.92 2.06-2.06 2.06s-2.06-.92-2.06-2.06.92-2.06 2.06-2.06 2.06.92 2.06 2.06z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgList = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22 6H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h14c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-7 7H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h7c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm4 7H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h11c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/><circle cx=\"2.25\" cy=\"5\" r=\"1.25\"/><circle cx=\"2.25\" cy=\"12\" r=\"1.25\"/><circle cx=\"2.25\" cy=\"19\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgListCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm6 9h-7c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h7c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm-5 4h-2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h2c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1zm3 4h-5c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h5c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/><circle cx=\"7\" cy=\"12\" r=\"1\"/><circle cx=\"7\" cy=\"8\" r=\"1\"/><circle cx=\"7\" cy=\"16\" r=\"1\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLoader = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12,24C5.38,24,0,18.62,0,12S5.38,0,12,0c0.55,0,1,0.45,1,1s-0.45,1-1,1C6.49,2,2,6.49,2,12s4.49,10,10,10s10-4.49,10-10   c0-0.55,0.45-1,1-1s1,0.45,1,1C24,18.62,18.62,24,12,24z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLocalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .26.02 6.46-6.35 8.68-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM12 8c1.1 0 2 .9 2 2 0 .61-.28 1.05-.51 1.31a2.94 2.94 0 0 0-.62 2.74l.52 1.95H10.6l.52-1.93c.27-.99.03-2.03-.62-2.77-.26-.29-.66-.92-.44-1.82.17-.69.75-1.26 1.44-1.42A2.2 2.2 0 0 1 12 8m0-2c-.31 0-.62.03-.94.11A3.98 3.98 0 0 0 8.12 9c-.34 1.4.06 2.7.88 3.63.22.25.28.61.19.93l-.85 3.19c-.17.63.31 1.25.96 1.25h5.39a1 1 0 0 0 .97-1.26l-.86-3.21c-.08-.31-.03-.65.18-.89A3.93 3.93 0 0 0 16 10a4 4 0 0 0-4-4h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLock = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 10V7c0-3.31-2.69-6-6-6S6 3.69 6 7v3c-1.66 0-3 1.34-3 3v7c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7c0-1.66-1.34-3-3-3zM8 7a4 4 0 1 1 8 0v3H8V7zm11 13c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLockOpen = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 10h0V7.21c0-3.05-2.19-5.77-5.21-6.16C9.54.63 6.7 2.84 6.11 5.84c-.12.6.38 1.16 1 1.16.48 0 .88-.34.98-.81.4-1.94 2.22-3.37 4.32-3.17 2.08.2 3.6 2.07 3.6 4.16V10H6c-1.66 0-3 1.34-3 3v7c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7c0-1.66-1.34-3-3-3zm1 10c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1v-7c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgLogout = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.71 11.29L18.41 8c-.39-.39-1.02-.39-1.41 0s-.39 1.02 0 1.41L18.59 11H12c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h6.59L17 14.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0l3.29-3.29a1 1 0 0 0 .01-1.42zM15 23H7a4 4 0 0 1-4-4V5a4 4 0 0 1 4-4h8a4.02 4.02 0 0 1 3.8 2.74c.2.62-.29 1.26-.95 1.26h0c-.42 0-.82-.24-.95-.63C16.63 3.57 15.88 3 15 3H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 15 23z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMagicWand = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 6.59L17.41 5c-.78-.78-2.05-.78-2.83 0L2 17.59c-.78.78-.78 2.05 0 2.83L3.59 22c.39.39.9.58 1.41.58s1.02-.2 1.41-.58L19 9.41a1.99 1.99 0 0 0 0-2.82zm-3-.18L17.59 8 16.5 9.09 14.91 7.5 16 6.41zM5 20.59L3.41 19 13.5 8.91l1.59 1.59L5 20.59zM8 1l-.61 1.82a3 3 0 0 1-1.5 1.74L5 5l.89.44a3 3 0 0 1 1.5 1.74L8 9l.61-1.82a2.98 2.98 0 0 1 1.5-1.73L11 5l-.89-.44a3 3 0 0 1-1.5-1.74L8 1h0zm11 12l-.61 1.82a2.98 2.98 0 0 1-1.5 1.73L16 17l.89.44a3.01 3.01 0 0 1 1.5 1.73L19 21l.61-1.82a2.98 2.98 0 0 1 1.5-1.73L22 17l-.89-.44a3.01 3.01 0 0 1-1.5-1.73L19 13h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMagnifyingGlass = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 21.59l-6.83-6.83C17.31 13.31 18 11.49 18 9.5A8.51 8.51 0 0 0 9.5 1 8.51 8.51 0 0 0 1 9.5 8.51 8.51 0 0 0 9.5 18c1.99 0 3.81-.69 5.26-1.83L21.59 23c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41zM9.5 16C5.92 16 3 13.08 3 9.5S5.92 3 9.5 3 16 5.92 16 9.5 13.08 16 9.5 16z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMail = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 3H3C1.34 3 0 4.34 0 6v12c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3zM3 5h18c.55 0 1 .45 1 1v.07l-10 5.77L2 6.07V6c0-.55.45-1 1-1zm18 14H3c-.55 0-1-.45-1-1V8.38l9.5 5.48c.15.09.33.14.5.14s.35-.04.5-.13L22 8.38V18c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMapMarker = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3c3.58 0 6.5 2.92 6.5 6.5 0 .66-.1 1.32-.3 1.95-.1.31-.22.62-.39.95l-.45.78-4.09 7.08A1.46 1.46 0 0 1 12 21c-.52 0-1.01-.28-1.27-.73l-4.09-7.09-.45-.78a5.3 5.3 0 0 1-.39-.95c-.27-.86-.36-1.77-.26-2.68.35-3.15 3.11-5.68 6.29-5.77H12m0-2h-.23c-4.18.11-7.76 3.39-8.22 7.55-.13 1.23-.01 2.41.34 3.5.14.45.33.89.54 1.31l.48.83L9 21.27c.62 1.07 1.76 1.73 3 1.73h0c1.24 0 2.38-.66 3-1.73l4.09-7.08.48-.83c.21-.42.4-.85.54-1.31.25-.8.39-1.66.39-2.55A8.51 8.51 0 0 0 12 1h0zm0 6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMedal = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 11.13l2.28 1.37-.6-2.59 2.01-1.74-2.65-.22L12 5.5l-1.04 2.44-2.64.23 2.01 1.74-.6 2.59L12 11.13zM21 9c0-4.97-4.03-9-9-9S3 4.03 3 9a8.94 8.94 0 0 0 2 5.65v6.74c0 1.13.93 2 2 2a1.86 1.86 0 0 0 .52-.07l3.71-.99a2.93 2.93 0 0 1 .78-.1 2.98 2.98 0 0 1 .78.1l3.71.99a1.91 1.91 0 0 0 .52.07c1.07 0 2-.87 2-2v-6.74A9.04 9.04 0 0 0 21 9zm-9-7a7.01 7.01 0 0 1 7 7 7.01 7.01 0 0 1-7 7 7.01 7.01 0 0 1-7-7 7.01 7.01 0 0 1 7-7zM5.87 15.57c.05.04.1.09.15.13a.64.64 0 0 1-.15-.13zm7.42 4.83c-.42-.11-.86-.17-1.29-.17-.44 0-.87.06-1.29.17L7 21.39v-4.91A8.94 8.94 0 0 0 12 18a8.94 8.94 0 0 0 5-1.52v4.91l-3.71-.99zm4.7-4.69c.05-.04.1-.09.15-.13-.05.04-.1.08-.15.13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 13H5c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h14c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinusCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm4 13H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMinusSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1 12H8c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h8c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoped = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 15c.55 0 1 .45 1 1s-.45 1-1 1c-.56 0-1-.45-1-1s.44-1 1-1zm-15.6-.5h-.79l.62-1.24A4.98 4.98 0 0 1 7.7 10.5h2.91l-.69 2.05c-.21.65-.2 1.33.02 1.95H8.6 3.4zm3.46 2c-.17.3-.49.5-.86.5s-.7-.2-.86-.5h1.72zm9.17-.11C16.22 17.86 17.47 19 19 19a3.01 3.01 0 0 0 3-3c0-1.41-.97-2.58-2.27-2.9.18-.5.27-1.04.27-1.6v-.17a4.02 4.02 0 0 0-.57-2.06L17.76 6.5H18c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-2-1a.99.99 0 0 0-1 1c0 .55.44 1 1 1h.43l2.28 3.8a1.98 1.98 0 0 1 .29 1.03v.17a3.01 3.01 0 0 1-3 3h-2.23c-.33 0-.62-.16-.81-.42s-.24-.59-.14-.9l.69-2.05c.2-.61.1-1.28-.28-1.8-.37-.52-.98-.83-1.62-.83H7.7a6.97 6.97 0 0 0-6.26 3.87L.82 13.6a2.02 2.02 0 0 0 .09 1.95 2.01 2.01 0 0 0 1.7.95h.44A2.98 2.98 0 0 0 6 19a2.99 2.99 0 0 0 2.95-2.5H15a5 5 0 0 0 1.03-.11z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreHorizontal = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"5\" cy=\"12\" r=\"2\"/><circle cx=\"19\" cy=\"12\" r=\"2\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreHorizontalCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0z\"/><circle cx=\"12\" cy=\"12\" r=\"1.25\"/><circle cx=\"7.75\" cy=\"12\" r=\"1.25\"/><circle cx=\"16.25\" cy=\"12\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMoreVertical = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><circle cx=\"12\" cy=\"12\" r=\"2\"/><circle cx=\"12\" cy=\"5\" r=\"2\"/><circle cx=\"12\" cy=\"19\" r=\"2\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgMotorcycle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20 11c-.37 0-.72.07-1.06.16L16.25 6.5c-.54-.93-1.53-1.5-2.6-1.5H12c-.55 0-1 .45-1 1s.45 1 1 1h1.65c.36 0 .69.19.87.5l.28.5h-3.01a6.92 6.92 0 0 0-1.81.24L6.21 9.25c-.54-.75-1.29-1.3-2.19-1.54l-2.76-.74c-.53-.14-1.08.18-1.23.71-.14.53.17 1.08.71 1.22l2.76.74a1.96 1.96 0 0 1 1.21.93l.34.58C4.71 11.07 4.37 11 4 11a4 4 0 1 0 0 8c1.89 0 3.46-1.31 3.88-3.08.57.67 1.39 1.08 2.27 1.08h1.69c1.07 0 2.06-.58 2.6-1.5l2.35-4.06.41.71c-.74.72-1.2 1.73-1.2 2.85a4 4 0 1 0 8 0 4 4 0 0 0-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm8.71-2.5c-.18.31-.51.5-.87.5h-1.69c-.36 0-.69-.19-.87-.5l-2-3.47 3.21-.86c.42-.11.86-.17 1.29-.17h3.52l-2.59 4.5zM20 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgOdometer = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.42 17.91c1-1.75 1.58-3.76 1.58-5.91a12.03 12.03 0 0 0-1.59-5.96c-.01-.01-.01-.03-.02-.04-.01-.02-.03-.02-.04-.04-1.04-1.79-2.53-3.27-4.32-4.32-.01-.01-.01-.02-.03-.03-.01-.01-.03-.01-.04-.01C16.2.59 14.17 0 12 0h0 0l-.48.01C9.58.08 7.75.64 6.14 1.56c-.05.02-.1.02-.14.05-.06.03-.1.09-.16.13a12.3 12.3 0 0 0-4.1 4.11c-.05.05-.1.09-.13.15-.03.04-.03.08-.04.12C.66 7.71.11 9.52.02 11.43c-.01.16.01.31.01.47-.01.04-.03.07-.03.1 0 .04.02.07.02.11a11.92 11.92 0 0 0 1.56 5.81c.01.02.01.05.03.08.01.02.03.04.05.06a11.85 11.85 0 0 0 2.8 3.26c.53.44 1.21.68 1.9.68h11.28c.7 0 1.37-.24 1.91-.68 1.11-.9 2.04-2 2.77-3.23.02-.03.05-.06.07-.09s.02-.06.03-.09zm-4.13 1.86a1.01 1.01 0 0 1-.65.23H6.36a1.01 1.01 0 0 1-.65-.23c-.7-.57-1.31-1.23-1.82-1.93l.81-.47A1.01 1.01 0 0 0 5.07 16a1.01 1.01 0 0 0-1.37-.37l-.83.48c-.43-.97-.7-2.02-.81-3.11H3c.55 0 1-.45 1-1s-.45-1-1-1h-.94a9.94 9.94 0 0 1 .83-3.11l.82.47c.15.1.33.14.49.14.35 0 .68-.18.87-.5.28-.48.11-1.09-.37-1.37l-.8-.46c.63-.87 1.4-1.64 2.27-2.27l.46.8c.19.32.52.5.87.5a1.01 1.01 0 0 0 .5-.13 1.01 1.01 0 0 0 .37-1.37l-.47-.81c.96-.44 2.01-.72 3.1-.83V3c0 .55.45 1 1 1s1-.45 1-1v-.95a9.75 9.75 0 0 1 3.1.84l-.47.82A1 1 0 0 0 16 5.07a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5l.47-.81c.87.63 1.64 1.4 2.27 2.27l-.81.47c-.48.28-.65.89-.37 1.37.19.32.52.5.87.5a1.01 1.01 0 0 0 .5-.13l.81-.47a9.82 9.82 0 0 1 .84 3.1H21c-.55 0-1 .45-1 1s.45 1 1 1h.94a9.95 9.95 0 0 1-.83 3.1l-.82-.47c-.48-.28-1.09-.11-1.37.37s-.11 1.09.37 1.37l.81.47c-.51.7-1.11 1.36-1.81 1.93zM13 9.14V7c0-.55-.45-1-1-1s-1 .45-1 1v2.14c-1.72.45-3 2-3 3.86a4 4 0 1 0 8 0c0-1.86-1.28-3.41-3-3.86zM12 15c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPaperPlane = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.5 11.13L4.09 1.66c-.16-.09-.33-.14-.5-.14-.61 0-1.15.58-.97 1.26l2.4 8.96a.92.92 0 0 1 0 .52l-2.4 8.96c-.18.68.35 1.26.97 1.26.17 0 .34-.04.5-.14l16.41-9.47c.67-.39.67-1.35 0-1.74zM16.27 11H6.9L5.18 4.6 16.27 11zM6.9 13h9.37L5.18 19.4 6.9 13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPaperclip = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12,24c-3.86,0-7-3.14-7-7V5c0-2.76,2.24-5,5-5s5,2.24,5,5v12c0,1.65-1.35,3-3,3s-3-1.35-3-3V6c0-0.55,0.45-1,1-1   s1,0.45,1,1v11c0,0.55,0.45,1,1,1s1-0.45,1-1V5c0-1.65-1.35-3-3-3S7,3.35,7,5v12c0,2.76,2.24,5,5,5s5-2.24,5-5V6c0-0.55,0.45-1,1-1   s1,0.45,1,1v11C19,20.86,15.86,24,12,24z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPec = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M1.14 17.27V8.34h1.91V9c.27-.42.98-.82 1.9-.82 1.87 0 3 1.43 3 3.37 0 1.98-1.27 3.39-3.08 3.39-.83 0-1.46-.29-1.75-.65v2.98H1.14zm3.4-7.3c-.77 0-1.46.52-1.46 1.6 0 1.07.69 1.61 1.46 1.61.78 0 1.47-.53 1.47-1.61s-.69-1.6-1.47-1.6zm10.7 2.97C14.93 14.06 13.9 15 12.27 15c-1.78 0-3.37-1.27-3.37-3.45 0-2.08 1.55-3.41 3.22-3.41 2 0 3.24 1.24 3.24 3.32a4.59 4.59 0 0 1-.04.61h-4.5c.04.73.7 1.25 1.47 1.25.71 0 1.12-.34 1.3-.84l1.65.46zm-1.79-2.14c-.03-.49-.36-1.09-1.3-1.09-.83 0-1.25.61-1.29 1.09h2.59zm4.82.78c0 1.03.69 1.6 1.51 1.6.77 0 1.2-.48 1.34-.96l1.74.53c-.28 1.13-1.35 2.25-3.08 2.25-1.92 0-3.47-1.42-3.47-3.42 0-2.01 1.51-3.43 3.41-3.43 1.78 0 2.82 1.09 3.11 2.25l-1.77.53c-.14-.49-.53-.96-1.3-.96-.82 0-1.49.57-1.49 1.61z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPen = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.22 3.5L20.5 1.78a3 3 0 0 0-4.24 0L1.88 16.16c-.57.56-.88 1.32-.88 2.12V22c0 .55.45 1 1 1h3.72a2.97 2.97 0 0 0 2.12-.88L22.22 7.74a2.97 2.97 0 0 0 .88-2.12c0-.8-.32-1.55-.88-2.12zM6.43 20.71a1.05 1.05 0 0 1-.71.29H3v-2.72a1.05 1.05 0 0 1 .29-.71L14.74 6.13l3.13 3.13L6.43 20.71zM20.8 6.33l-1.52 1.52-3.13-3.13 1.52-1.52c.39-.39 1.02-.39 1.41 0l1.72 1.72a.99.99 0 0 1 .29.71c.01.26-.1.51-.29.7z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPenForm = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23 2.59L21.41 1c-.78-.78-2.05-.78-2.83 0l-9.29 9.29c-.18.19-.29.44-.29.71v3c0 .55.45 1 1 1h3c.27 0 .52-.11.71-.29L23 5.41a1.99 1.99 0 0 0 0-2.82zM12.59 13H11v-1.59l6-6L18.59 7l-6 6zM20 5.59L18.41 4 20 2.41 21.59 4 20 5.59zM17 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h5.76l2-2H4C2.34 5 1 6.34 1 8v12c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-7.76l-2 2V20zM6 19h8c.55 0 1-.45 1-1s-.45-1-1-1H6c-.55 0-1 .45-1 1s.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPhone = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17.02 21.68c-.28 0-.56-.03-.83-.09A17.98 17.98 0 0 1 2.41 7.81 3.88 3.88 0 0 1 3.2 4.5C3.97 3.55 5.11 3 6.33 3h1.03c1.42 0 2.66 1.01 2.94 2.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.14 2.65 3.52 3.68l-.01-.02.08-.08c.71-.71 1.72-1.01 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.48.89zM6.33 5a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25c.58.13 1.17-.02 1.64-.4a2.01 2.01 0 0 0 .75-1.57v-1.03a1 1 0 0 0-.8-.98l-2.34-.47a.99.99 0 0 0-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.57-.79-.47-1.87.22-2.56.27-.27.37-.61.31-.94L8.34 5.8a1 1 0 0 0-.98-.8H6.33z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPhone24 = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16.02 21.68c-.28 0-.56-.03-.83-.09A17.98 17.98 0 0 1 1.41 7.81 3.88 3.88 0 0 1 2.2 4.5C2.97 3.55 4.11 3 5.33 3h1.03A3 3 0 0 1 9.3 5.41l.47 2.34c.2.99-.11 2-.82 2.71.94 1.42 2.14 2.65 3.52 3.68l-.01-.02.08-.08c.71-.71 1.72-1.02 2.71-.82l2.34.47c1.4.28 2.41 1.52 2.41 2.94v1.03c0 1.22-.55 2.36-1.5 3.13a3.87 3.87 0 0 1-2.48.89zM5.33 5a1.98 1.98 0 0 0-1.57.75 1.95 1.95 0 0 0-.4 1.64 16.01 16.01 0 0 0 12.25 12.25c.58.13 1.17-.02 1.64-.4a2.01 2.01 0 0 0 .75-1.57v-1.03a1 1 0 0 0-.8-.98l-2.34-.47c-.33-.06-.67.04-.9.27l-.08.08c-.7.7-1.81.79-2.59.21-1.55-1.15-2.9-2.54-4.01-4.11-.57-.79-.47-1.87.22-2.56.27-.27.37-.61.31-.94L7.34 5.8a1 1 0 0 0-.98-.8H5.33zm6.36-.57c-.03-.09-.05-.26-.05-.42 0-1.06.78-2.01 2.27-2.01 1.44 0 2.26.9 2.26 1.98 0 .71-.34 1.3-1.1 1.72l-.99.56c-.15.08-.33.26-.36.38h2.45V8h-4.55v-.1c0-1.11.33-1.97 1.41-2.6l.93-.55c.43-.24.59-.44.59-.78 0-.31-.24-.58-.66-.58-.47 0-.71.34-.71.72a1.17 1.17 0 0 0 .04.32h-1.53zm5.17.85l2.4-3.16h1.89v3.34H22V6.8h-.85V8h-1.62V6.8h-2.66V5.28zm2.67-1.46L18.3 5.46h1.22V3.82z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlate = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 5c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h18m0-2H3C1.34 3 0 4.34 0 6v12c0 1.66 1.34 3 3 3h18c1.66 0 3-1.34 3-3V6c0-1.66-1.34-3-3-3h0zM3.98 9.95c.98-.02 1.49-.49 1.63-1.19h1.53v6.49H5.3v-4.04H3.98V9.95zm4.29 0c.98-.02 1.49-.49 1.63-1.19h1.53v6.49H9.59v-4.04H8.27V9.95zm4.33 3.18v-1.59h3.09v1.59H12.6zm4.27-3.18c.98-.02 1.49-.49 1.63-1.19h1.53v6.49h-1.84v-4.04h-1.32V9.95z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 11h-6V5c0-.55-.45-1-1-1s-1 .45-1 1v6H5c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1v-6h6c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlusCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm4 11h-3V8c0-.55-.45-1-1-1s-1 .45-1 1v3H8c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPlusSquare = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 3a4 4 0 0 1 4 4v10a4 4 0 0 1-4 4H7a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h10m0-2H7C3.69 1 1 3.69 1 7v10c0 3.31 2.69 6 6 6h10c3.31 0 6-2.69 6-6V7c0-3.31-2.69-6-6-6h0zm-1 10h-3V8c0-.55-.45-1-1-1h0c-.55 0-1 .45-1 1v3H8c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1h3v3c0 .55.45 1 1 1h0c.55 0 1-.45 1-1v-3h3c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPound = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.41 20l1.06-1.06A6.97 6.97 0 0 0 10.5 14H14v-2h-3.78c-.15-.52-.35-1.03-.63-1.51l-.95-1.65c-.56-.97-.56-2.16.01-3.03C9.4 4.68 10.65 4 12 4c1.59 0 3.03.94 3.67 2.4l1.83-.8C16.54 3.41 14.39 2 12 2 9.97 2 8.09 3.01 6.98 4.71 6 6.21 5.97 8.22 6.91 9.84l.95 1.65c.1.17.17.34.24.51H6v2h2.52c0 1.29-.49 2.56-1.45 3.52L6 18.59a2 2 0 0 0-.43 2.18A1.98 1.98 0 0 0 7.41 22H18v-2H7.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgPrima = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0zm5.08 8.76c-.21-1.44-1.37-2.53-2.81-2.66-.72-.13-1.45-.13-2.15.02-.37.04-.65.36-.65.74.02.41.37.75.78.76.06 0 .13 0 .19-.02.47-.11.94-.12 1.42-.05 1 .09 1.77.98 1.7 2 .06.84.04 1.69-.07 2.54a1.76 1.76 0 0 1-1.82 1.6c-.82.07-1.64-.07-2.4-.42-.16-.04-.27-.2-.25-.37v-1.53-.76-.62-1.24-1.1c-.02-.44-.39-.79-.84-.8-.13.01-.25.03-.37.08L7.05 8.31c-.3.17-.5.39-.56.63-.05.2-.01.41.11.61.26.42.72.5 1.25.21L9.35 9v9.24a.8.8 0 0 0 .79.76h.04a.81.81 0 0 0 .81-.77v-3.38l.64.12 1.44.24a3.49 3.49 0 0 0 2.62-.6 1.56 1.56 0 0 0 .2-.16c.15-.13.29-.27.42-.42s.24-.31.34-.48.19-.35.26-.53c.04-.09.07-.19.09-.28l.08-.33c.04-.24.06-.48.08-.72l.04-.73v-.74l-.04-.74-.08-.72z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgQuestionCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 2a10.02 10.02 0 0 1 10 10 10.02 10.02 0 0 1-10 10A10.02 10.02 0 0 1 2 12 10.02 10.02 0 0 1 12 2m0-2a12 12 0 1 0 0 24 12 12 0 1 0 0-24h0z\"/><circle cx=\"12\" cy=\"17.37\" r=\"1.25\"/><path d=\"M13 14.37h-2v-.1c0-1.3.73-2.47 1.95-3.14.77-.42 1.16-1.25 1.02-2.12a2.03 2.03 0 0 0-1.59-1.61c-.61-.11-1.2.04-1.66.43a1.93 1.93 0 0 0-.59.85c-.15.4-.48.69-.9.69h-.15a.93.93 0 0 1-.89-1.21c.22-.71.65-1.36 1.24-1.85 1.23-1.03 3-1.25 4.53-.45a3.64 3.64 0 0 1 1.5 1.46c1.16 2.09.37 4.53-1.55 5.57-.57.31-.91.83-.91 1.38v.1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgRefund = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><circle cx=\"17.5\" cy=\"12.5\" r=\"1.5\"/><path d=\"M21 4.18v-.86A2.99 2.99 0 0 0 19.83.94c-.75-.57-1.69-.76-2.6-.52L3.79 4.02C2.23 4.13 1 5.42 1 7v11a3.01 3.01 0 0 0 3 3c.55 0 1-.45 1-1s-.45-1-1-1-1-.45-1-1V7c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v6c0 .55.45 1 1 1s1-.45 1-1V7c0-1.3-.84-2.4-2-2.82zm-3.26-1.83c.3-.08.62-.02.87.17a1.02 1.02 0 0 1 .39.8V4h-7.4l6.14-1.65zM22 16c-.55 0-1 .45-1 1v1c0 .55-.45 1-1 1h-9.59l1.29-1.29c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0l-3 3c-.09.09-.17.2-.22.33a1 1 0 0 0 0 .76c.05.12.12.23.22.33l3 3A1.06 1.06 0 0 0 11 24c.26 0 .51-.1.71-.29.39-.39.39-1.02 0-1.41l-1.3-1.3H20a3.01 3.01 0 0 0 3-3v-1c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgReload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 0c-.55 0-1 .45-1 1v2.08c-4.71-4.23-11.97-4.09-16.49.44-4.68 4.68-4.68 12.29 0 16.97A11.96 11.96 0 0 0 12 24a11.96 11.96 0 0 0 8.49-3.51c.39-.39.39-1.02 0-1.41s-1.02-.39-1.41 0c-3.9 3.9-10.25 3.9-14.15 0s-3.9-10.25 0-14.15 10.25-3.9 14.15 0c.02.03.06.04.1.07H16c-.55 0-1 .45-1 1s.45 1 1 1h5c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgShieldCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85V13v.05c0 .06.09 6.42-6.35 8.67-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM11.5 16c-.26 0-.51-.1-.71-.29l-2.29-2.3c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l1.59 1.59L15.09 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSki = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M22.71 18.29c-.39-.39-1.02-.39-1.41 0l-.55.55c-1.26 1.26-3.11 1.75-4.83 1.29l-3.85-1.03 1.75-1.75a2.97 2.97 0 0 0 .78-2.9c-.28-1.05-1.07-1.84-2.12-2.12l-.86-.23c-.5-.13-.66-.54-.71-.71-.04-.17-.11-.6.26-.96l.32-.32 3.02 1.75A.87.87 0 0 0 15 12c.35 0 .68-.18.87-.5.28-.48.11-1.09-.37-1.37l-2.56-1.48 1.89-1.89c.36.16.76.24 1.17.24 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .51.14.98.36 1.4l-2.22 2.22-6.41-3.7c-.47-.28-1.08-.11-1.36.37-.27.48-.11 1.09.37 1.36l5.95 3.44c-.72.76-1 1.81-.73 2.83.28 1.05 1.07 1.84 2.12 2.12l.86.23c.5.13.66.54.71.71.04.17.11.6-.26.97l-2.56 2.56-7.58-2.03c-.53-.14-1.08.17-1.22.71-.14.53.17 1.08.71 1.22l13.65 3.66c.59.16 1.2.24 1.8.24 1.83 0 3.63-.72 4.96-2.05l.55-.55a1 1 0 0 0 .01-1.42zM16 3c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSmartphone = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M16 2c.55 0 1 .45 1 1v18c0 .55-.45 1-1 1H8c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h8m0-2H8C6.34 0 5 1.34 5 3v18c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3h0zm-4 18.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 1 0 0-2.5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgStar = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 4.93l1.67 4 .47 1.12 1.21.1 4.32.35-3.29 2.82-.92.79.28 1.19 1 4.22-3.7-2.25-1.04-.64-1.04.63-3.7 2.25 1-4.22.28-1.19-.92-.79-3.29-2.81 4.32-.35 1.21-.1.47-1.12 1.67-4m0-3.6a.98.98 0 0 0-.92.62L8.49 8.16l-6.71.54c-.89.07-1.24 1.18-.57 1.76l5.11 4.38-1.56 6.55c-.16.67.37 1.23.97 1.23.17 0 .35-.05.52-.15l5.75-3.5 5.75 3.5c.17.1.35.15.52.15.6 0 1.13-.56.97-1.23l-1.56-6.55 5.11-4.38c.67-.58.32-1.68-.57-1.76l-6.71-.54-2.59-6.21a.98.98 0 0 0-.92-.62h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgStarFilled = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.92 1.95l2.59 6.21 6.71.54c.89.07 1.24 1.18.57 1.76l-5.11 4.38 1.56 6.55c.21.86-.73 1.55-1.49 1.08L12 18.97l-5.75 3.5c-.76.46-1.7-.22-1.49-1.08l1.56-6.55-5.11-4.38c-.68-.58-.32-1.69.57-1.76l6.71-.54 2.59-6.21a1 1 0 0 1 1.84 0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSteeringWheel = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 0a12 12 0 1 0 0 24 12 12 0 1 0 0-24zm0 2c3.69 0 6.91 2.02 8.65 5H3.35C5.09 4.02 8.31 2 12 2zM2.2 14h2.52a3 3 0 0 1 2.94 2.41l1 5C5.4 20.25 2.91 17.45 2.2 14zm8.6 7.92l-1.18-5.9C9.16 13.69 7.1 12 4.72 12H2a9.91 9.91 0 0 1 .46-3h19.08a9.91 9.91 0 0 1 .46 3h-2.72c-2.38 0-4.44 1.69-4.9 4.02l-1.18 5.9c-.4.05-.79.08-1.2.08s-.8-.03-1.2-.08zm4.54-.5l1-5c.28-1.4 1.52-2.41 2.94-2.41h2.52c-.71 3.44-3.2 6.24-6.46 7.41z\"/><circle cx=\"12\" cy=\"12\" r=\"1.25\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgSwitch = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M9 22c-.39.39-1.02.39-1.41 0L1.3 15.71c-.29-.29-.37-.72-.22-1.09S1.6 14 2 14h20c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1H4.41L9 20.59c.39.39.39 1.02 0 1.41h0zm13-12H2c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h17.59L15 3.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l6.29 6.29c.29.29.37.72.22 1.09S22.4 10 22 10z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTablet = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 2c.55 0 1 .45 1 1v18c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1h12m0-2H6C4.34 0 3 1.34 3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3h0zm-6 18.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 1 0 0-2.5h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgThumbUp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.1 11.09l-2.96-.79c-.44-.12-.74-.51-.74-.97V4c0-1.66-1.34-3-3-3h-.03c-.71 0-1.37.38-1.73 1l-4.85 8.4c-.18.31-.51.5-.87.5H4c-1.66 0-3 1.34-3 3V20c0 1.66 1.34 3 3 3h2.5 11.21a3 3 0 0 0 2.9-2.22l1.61-6.02c.43-1.6-.52-3.24-2.12-3.67zM3 20v-6.1c0-.55.45-1 1-1h1.5V21H4c-.55 0-1-.45-1-1zm17.29-5.76l-1.61 6.02c-.12.44-.51.74-.97.74H7.5v-8.56c.41-.26.77-.6 1.02-1.04L13.37 3h.03c.55 0 1 .45 1 1v5.33a3 3 0 0 0 2.22 2.9l2.96.79c.54.14.85.69.71 1.22z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTrashBin = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14 1h-4c-1.1 0-2 .9-2 2h8c0-1.1-.9-2-2-2zm7 3H3c-.55 0-1 .45-1 1s.45 1 1 1h.54v12c0 2.76 2.24 5 5 5h7c2.76 0 5-2.24 5-5V6H21c.55 0 1-.45 1-1s-.45-1-1-1zm-2.46 14a3.01 3.01 0 0 1-3 3h-7a3.01 3.01 0 0 1-3-3V6h13v12zM9 19c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v9c0 .55.45 1 1 1zm6 0c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v9c0 .55.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTshirtSport = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M7.4 3.73l1.06 1.06c.94.94 2.2 1.46 3.54 1.46s2.59-.52 3.54-1.46l1.06-1.06 3.4.91V8.5h-1-2v2 10H7v-10-2H5 4V4.64l3.4-.91M16 1.5l-1.88 1.88a3 3 0 0 1-2.12.88c-.77 0-1.54-.29-2.12-.88L8 1.5 3.48 2.71C2.61 2.94 2 3.74 2 4.64V8.5c0 1.1.9 2 2 2h1v10c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-10h1c1.1 0 2-.9 2-2V4.64c0-.91-.61-1.7-1.48-1.93L16 1.5h0zm-5 16v-6H9v-1.29c1.33-.01 2.15-.76 2.33-1.71H13v9h-2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgTwitter = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.54 7.1l.01.64c0 6.51-4.95 14.01-14.01 14.01h0c-2.68 0-5.3-.76-7.55-2.21.39.05.78.07 1.17.07 2.22 0 4.37-.74 6.12-2.11-2.11-.04-3.95-1.41-4.6-3.42A5.04 5.04 0 0 0 4.9 14C2.6 13.54.95 11.52.95 9.17v-.06a4.92 4.92 0 0 0 2.25.61C1.03 8.28.36 5.4 1.67 3.15c2.5 3.08 6.19 4.95 10.15 5.14a4.93 4.93 0 0 1 1.42-4.7 4.94 4.94 0 0 1 6.97.21 9.86 9.86 0 0 0 3.13-1.2c-.37 1.14-1.14 2.11-2.16 2.72A9.61 9.61 0 0 0 24 4.55a9.78 9.78 0 0 1-2.46 2.55z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUmbrella = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 1h-.21C5.74 1.11 1 6.31 1 12.36v.02c0 .58.48 1 1 1 .15 0 .3-.03.45-.11l.76-.38a4.02 4.02 0 0 1 3.58 0l1.69.85c.14.07.29.11.45.11a.99.99 0 0 0 .55-.17l.3-.2a4.03 4.03 0 0 1 1.22-.54V20c0 .55-.45 1-1 1s-1-.45-1-1-.45-1-1-1-1 .45-1 1a3.01 3.01 0 0 0 3 3 3.01 3.01 0 0 0 3-3v-7.06a3.8 3.8 0 0 1 1.22.54l.3.2c.17.11.36.17.55.17.15 0 .31-.03.45-.11l1.69-.85a4.02 4.02 0 0 1 3.58 0l.76.38c.15.07.3.11.45.11.52 0 1-.42 1-1V12c0-6.08-4.92-11-11-11zm7 9.47c-.93 0-1.85.22-2.68.63l-1.18.59c-.95-.58-2.03-.89-3.14-.89s-2.19.31-3.14.89l-1.18-.59c-.83-.41-1.75-.63-2.68-.63a5.95 5.95 0 0 0-1.87.3c.73-4.33 4.35-7.69 8.7-7.77H12c4.55 0 8.32 3.4 8.92 7.79-.62-.21-1.27-.32-1.92-.32z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUpload = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18 23H6a4.02 4.02 0 0 1-3.8-2.74c-.2-.62.29-1.26.95-1.26h0c.42 0 .82.24.95.63.27.8 1.02 1.37 1.9 1.37h12c.88 0 1.63-.57 1.9-1.37.13-.39.54-.63.95-.63h0c.66 0 1.15.64.95 1.26A4.02 4.02 0 0 1 18 23zM16 5.59L12.71 2.3c-.2-.2-.45-.3-.71-.3s-.51.1-.71.29L8 5.59c-.39.39-.39 1.02 0 1.41s1.02.39 1.41 0L11 5.41V16c0 .55.45 1 1 1s1-.45 1-1V5.41L14.59 7c.39.39 1.02.39 1.41 0s.39-1.02 0-1.41z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUser = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C9.24 1 7 3.24 7 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm5 14c1.1 0 2 .9 2 2v4H5v-4c0-1.1.9-2 2-2h10m0-2H7a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4a4 4 0 0 0-4-4h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCheck = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm6 16c-.26 0-.51-.1-.71-.29L14 14.41c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0L17 14.59 20.59 11c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3zm2 3c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCircle = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2m0-2a4 4 0 1 0 0 8 4 4 0 1 0 0-8h0z\"/><path d=\"M17 20.65A9.9 9.9 0 0 1 12 22c-1.82 0-3.53-.49-5-1.34V18a.99.99 0 0 1 1-1h8c.55 0 1 .44 1 1v2.65zM5 19.14C3.15 17.32 2 14.79 2 12 2 6.48 6.48 2 12 2c5.51 0 10 4.48 10 10 0 2.79-1.15 5.32-3 7.14V18c0-1.66-1.35-3-3-3H8c-1.66 0-3 1.34-3 3v1.14zm0 2.6c.62.46 1.29.85 2 1.17 1.52.7 3.22 1.09 5 1.09s3.48-.39 5-1.09a11.13 11.13 0 0 0 2-1.17c3.03-2.18 5-5.73 5-9.74 0-6.63-5.38-12-12-12A12 12 0 0 0 0 12c0 4.01 1.97 7.56 5 9.74z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserCross = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm9.89 15.89h0c.39-.39.39-1.02 0-1.41L19.41 14l1.48-1.48c.39-.39.39-1.02 0-1.41h0c-.39-.39-1.02-.39-1.41 0L18 12.59l-1.48-1.48c-.39-.39-1.02-.39-1.41 0h0c-.39.39-.39 1.02 0 1.41L16.59 14l-1.48 1.48c-.39.39-.39 1.02 0 1.41h0c.39.39 1.02.39 1.41 0L18 15.41l1.48 1.48c.39.39 1.02.39 1.41 0zM19 20c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserMinus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm8 19c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1zm2-4h-6c-.55 0-1-.45-1-1h0c0-.55.45-1 1-1h6c.55 0 1 .45 1 1h0c0 .55-.45 1-1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUserPlus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M11 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3m0-2C8.24 1 6 3.24 6 6s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5h0zm7 17.09h0c.55 0 1-.45 1-1V15h2.09c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H19v-2.09c0-.55-.45-1-1-1h0c-.55 0-1 .45-1 1V13h-2.09c-.55 0-1 .45-1 1h0c0 .55.45 1 1 1H17v2.09c0 .55.45 1 1 1zM19 20c-.55 0-1 .45-1 1H4v-4c0-1.1.9-2 2-2h5c.55 0 1-.45 1-1h0c0-.55-.45-1-1-1H6a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2 0-.55-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgUsers = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M10 11c.98 0 1.88-.29 2.65-.77-.21.13-.42.25-.65.35a4.94 4.94 0 0 0 2 .42c2.76 0 5-2.24 5-5s-2.24-5-5-5a4.94 4.94 0 0 0-2 .42c.23.1.44.22.65.35C11.88 1.29 10.98 1 10 1 7.24 1 5 3.24 5 6s2.24 5 5 5zm2.99-1.01l.26-.21-.26.21zM14 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3c.61-.84 1-1.87 1-3s-.39-2.16-1.02-3zm-.76-.78l-.26-.21.26.21zM10 3a3.01 3.01 0 0 1 3 3 3.01 3.01 0 0 1-3 3 3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 3-3zm9 10h-4c.24 0 .48.03.71.07-.23-.04-.47-.07-.71-.07H5a4 4 0 0 0-4 4v4c0 1.1.9 2 2 2h14c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-4c0-.73-.21-1.41-.55-2H19c1.1 0 2 .9 2 2v4h-2c0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h4c1.1 0 2-.9 2-2v-4a4 4 0 0 0-4-4zm-2 8H3v-4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v4zm-1.09-7.89a3.74 3.74 0 0 1 .54.17c-.18-.07-.36-.12-.54-.17zm.79.28c.13.06.26.13.38.21l-.38-.21zm.69.42l.16.13-.16-.13z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVan = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.6 11.8L21.98 9c-.54-.93-1.53-1.5-2.6-1.5H18v-1c0-1.66-1.34-3-3-3H3c-1.66 0-3 1.34-3 3v9c0 1.1.9 2 2 2h.05a2.99 2.99 0 0 0 5.9 0h7.1a2.99 2.99 0 0 0 5.9 0H22c1.1 0 2-.9 2-2v-2.2c0-.52-.14-1.04-.4-1.5zM2 6.5c0-.55.45-1 1-1h12c.55 0 1 .45 1 1v1 7.28a2.87 2.87 0 0 0-.58.72H7.58C7.06 14.61 6.11 14 5 14s-2.06.61-2.58 1.5H2v-9zM5 18c-.37 0-.67-.21-.85-.5-.09-.15-.15-.31-.15-.5 0-.55.45-1 1-1s1 .45 1 1c0 .19-.06.35-.15.5-.18.29-.48.5-.85.5zm13 0c-.37 0-.67-.21-.85-.5-.09-.15-.15-.31-.15-.5 0-.55.45-1 1-1s1 .45 1 1c0 .19-.06.35-.15.5-.18.29-.48.5-.85.5zm4-2.5h-1.42c-.52-.89-1.47-1.5-2.58-1.5V9.5h1.38c.36 0 .69.19.87.5l1.62 2.8c.09.15.13.32.13.5v2.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleCollisionKasko = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M17 7c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1s1 .45 1 1v3c0 .55-.45 1-1 1zm4 0a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l1.5-2.6A1.01 1.01 0 0 1 23 2.53a1.01 1.01 0 0 1 .37 1.37l-1.5 2.6A1.02 1.02 0 0 1 21 7zm-8 0c-.35 0-.68-.18-.87-.5l-1.5-2.6c-.27-.48-.11-1.09.37-1.36.48-.28 1.09-.11 1.37.37l1.5 2.6c.28.48.11 1.09-.37 1.37-.16.08-.33.12-.5.12zm8.77 6l-2.4-.64 1.43-.83c.61-.35 1.3-.53 2-.53h.2c.55 0 1-.45 1-1 0-.56-.45-1-1-1h-.2c-1.05 0-2.09.27-3 .8l-2.5 1.45-.72-1.25c-.54-.93-1.53-1.5-2.6-1.5H5.84c-.8 0-1.56.31-2.12.88l-2.5 2.49c-.76.76-1.05 1.87-.78 2.9l.67 2.5c.27.98 1 1.75 1.92 2.07a2.99 2.99 0 0 0 5.92.16h7.1A2.98 2.98 0 0 0 19 22c1.54 0 2.81-1.17 2.98-2.67A3 3 0 0 0 24 16.5v-.61c0-1.35-.92-2.54-2.23-2.89zM6.86 19.5c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm13 0c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm2.14-3c0 .36-.2.68-.49.86C20.97 16.54 20.05 16 19 16c-1.11 0-2.08.6-2.6 1.5H8.6C8.08 16.6 7.11 16 6 16c-1.05 0-1.97.54-2.51 1.35-.21-.13-.38-.34-.44-.59l-.67-2.51c-.1-.34 0-.71.25-.96l2.5-2.5a.99.99 0 0 1 .71-.29h8.14c.35 0 .69.19.86.5l1.1 1.9a2.01 2.01 0 0 0 1.22.93l4.09 1.1c.44.12.75.51.75.96v.61zm-8.8-5H8.4a.99.99 0 0 0-1 1c0 .55.44 1 1 1h4.8c.55 0 1-.45 1-1 0-.56-.45-1-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleDriverInjuries = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19.07 12l1.41-1.41c1.56-1.56 1.56-4.09 0-5.66l-1.41-1.41a3.98 3.98 0 0 0-2.83-1.17 3.98 3.98 0 0 0-2.83 1.17L12 4.93l-1.41-1.41c-.79-.79-1.81-1.18-2.83-1.18a4 4 0 0 0-2.83 1.17L3.51 4.93c-1.56 1.56-1.56 4.09 0 5.66L4.93 12l-1.41 1.41c-1.56 1.56-1.56 4.09 0 5.66l1.41 1.41a3.98 3.98 0 0 0 2.83 1.17 3.98 3.98 0 0 0 2.83-1.17l7.07-7.07 1.41 1.41c.78.78.78 2.05 0 2.83l-1.41 1.41c-.38.38-.88.59-1.41.59s-1.04-.21-1.41-.59l-1.41-1.41L12 19.07l1.41 1.41a3.98 3.98 0 0 0 2.83 1.17 3.98 3.98 0 0 0 2.83-1.17l1.41-1.41c1.56-1.56 1.56-4.09 0-5.66L19.07 12zM4.93 9.17c-.38-.38-.59-.88-.59-1.41s.21-1.04.59-1.41l1.41-1.41c.38-.38.88-.59 1.41-.59s1.04.21 1.41.59l1.41 1.41-4.24 4.24-1.4-1.42zm4.24 9.9c-.38.38-.88.59-1.41.59s-1.04-.21-1.41-.59l-1.41-1.41c-.78-.78-.78-2.05 0-2.83l9.9-9.9c.38-.38.88-.59 1.41-.59s1.04.21 1.41.59l1.41 1.41c.38.38.59.88.59 1.41s-.21 1.04-.59 1.41l-9.9 9.91zm3.54-10.19c-.26 0-.51.1-.71.29L9.17 12c-.39.39-.39 1.02 0 1.41l1.41 1.41a.98.98 0 0 0 .71.29c.26 0 .51-.1.71-.29L14.83 12c.39-.39.39-1.02 0-1.41l-1.41-1.41a1.01 1.01 0 0 0-.71-.3h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleFullKasko = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85v7.15c0 .26.02 6.46-6.35 8.68-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zM11.5 16c-.26 0-.51-.1-.71-.29l-2.29-2.3c-.39-.39-.39-1.02 0-1.41h0c.39-.39 1.02-.39 1.41 0l1.59 1.59L15.09 10c.39-.39 1.02-.39 1.41 0h0c.39.39.39 1.02 0 1.41l-4.29 4.29c-.2.2-.45.3-.71.3z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleGlasses = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 4c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1h-9.65l1.25-4.68c.28-1.03.13-2.11-.4-3.04l-2.06-3.57L11.41 4H21m0-2H10.64c-.45 0-.85.3-.97.74L8.21 8.2c-.14.51-.07 1.06.2 1.52l2.06 3.57c.27.46.34 1.01.2 1.52l-1.59 5.94c-.17.63.31 1.25.97 1.25H21c1.66 0 3-1.34 3-3V5c0-1.66-1.34-3-3-3h0zM5.27 4l-.99 3.68c-.28 1.03-.13 2.11.4 3.04l2.06 3.57L5.21 20H3c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h2.27m1.3-2H3C1.34 2 0 3.34 0 5v14c0 1.66 1.34 3 3 3h2.98c.45 0 .85-.3.97-.74l1.73-6.46c.14-.51.07-1.06-.2-1.52L6.41 9.72c-.26-.46-.33-1.01-.2-1.52l1.32-4.94A.99.99 0 0 0 6.57 2h0zM15 12c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l4-4c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-4 4c-.19.19-.44.29-.7.29zm2 3c-.26 0-.51-.1-.71-.29-.39-.39-.39-1.02 0-1.41l2-2c.39-.39 1.02-.39 1.41 0s.39 1.02 0 1.41l-2 2c-.19.19-.44.29-.7.29z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleLegalProtection = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.65 21.72c-.21.07-.42.11-.65.11-.22 0-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04c1.26 1.1 3.5 2.48 7 2.85v6.81l1.96 1.13c.05-.51.04-.83.04-.83V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39-.37-.33-.83-.49-1.3-.49a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.94 2.39C3.75 4.02 3 4.88 3 5.89V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22.44 0 .89-.07 1.31-.22 2.68-.94 4.41-2.46 5.55-4.07l-1.75-1.01c-.94 1.27-2.34 2.45-4.46 3.19zm8.95-5.21l-5.63-3.25.5-.87a3 3 0 0 0-1.1-4.1l-.87-.5c-.47-.27-.99-.4-1.5-.4-1.04 0-2.05.54-2.6 1.5l-2 3.46a3 3 0 0 0 1.1 4.1l.87.5c.47.27.99.4 1.5.4 1.04 0 2.05-.54 2.6-1.5l.5-.87 5.63 3.25a1.01 1.01 0 0 0 .5.13c.35 0 .68-.18.87-.5.27-.46.1-1.07-.37-1.35zm-8.87-1.65c-.18.31-.51.5-.87.5-.18 0-.34-.05-.5-.13l-.87-.5c-.31-.18-.43-.46-.47-.61a1 1 0 0 1 .1-.76l2-3.46c.18-.31.51-.5.87-.5.18 0 .34.05.5.13l.87.5c.31.18.43.46.47.61a1 1 0 0 1-.1.76l-2 3.46z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleNaturalEvents = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12.11 23a1.01 1.01 0 0 1-.5-.13 1.01 1.01 0 0 1-.37-1.37l2.02-3.5H10c-.36 0-.69-.19-.87-.5s-.18-.69 0-1l2.89-5a1.01 1.01 0 0 1 1.37-.37 1.01 1.01 0 0 1 .37 1.37L11.73 16H15c.36 0 .69.19.87.5s.18.69 0 1l-2.89 5c-.19.32-.52.5-.87.5zm6.88-15.86c0-.05.01-.09.01-.14 0-3.31-2.69-6-6-6-2.62 0-4.85 1.69-5.66 4.03C7.22 5.03 7.12 5 7 5c-2.76 0-5 2.24-5 5s2.24 5 5 5h.69l1.15-2H7a3.01 3.01 0 0 1-3-3 3.01 3.01 0 0 1 2.93-3l.27.03 1.52.1.5-1.44C9.78 4.08 11.3 3 13 3c2.19 0 3.98 1.77 4 3.96 0 .04-.01.09-.01.13l-.04 1.59 1.54.4C19.38 9.3 20 10.1 20 11c0 1.1-.9 2-2 2h-2.29c-.06.17-.13.34-.22.5l-.87 1.5H18a4 4 0 0 0 4-4 3.99 3.99 0 0 0-3.01-3.86z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleRicourseWaiver = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21.12 7.12L14.88.88C14.32.32 13.55 0 12.76 0H7C5.34 0 4 1.34 4 3v2.18c.31-.11.65-.18 1-.18h1V3c0-.55.45-1 1-1h5.76c.08 0 .16.03.24.05V7c0 1.1.9 2 2 2h4.95c.02.08.05.16.05.24V21c0 .55-.45 1-1 1h-8.35c-.26.75-.67 1.42-1.19 2H19c1.66 0 3-1.34 3-3V9.24c0-.79-.32-1.56-.88-2.12zM15 7V3.83L18.17 7H15zm-9 9.14V13h1c.55 0 1-.45 1-1s-.45-1-1-1H6V9h2c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1v8.14c-1.72.45-3 2-3 3.86a4 4 0 1 0 8 0c0-1.86-1.28-3.41-3-3.86zM5 22c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleRoadsideAssistance = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.59 11.8l-2.48-4.3c-.54-.93-1.53-1.5-2.6-1.5H15c-1.11 0-2 .89-2 2v2.59l-7.29-7.3c-.29-.28-.72-.37-1.09-.21C4.24 3.23 4 3.6 4 4v4.28c-.6.34-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72V6.41l6.09 6.09H2c-1.11 0-2 .89-2 2v2a2 2 0 0 0 2 2h.05A2.98 2.98 0 0 0 5 21a2.99 2.99 0 0 0 2.95-2.5H13h2 .05A2.98 2.98 0 0 0 18 21a2.99 2.99 0 0 0 2.95-2.5H22c1.1 0 2-.9 2-2v-3.2a2.94 2.94 0 0 0-.41-1.5zM5.86 18.5c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm7.14-2H7.6C7.08 15.6 6.11 15 5 15s-2.08.6-2.6 1.5H2v-2h11v2zm5.86 2c-.17.3-.49.5-.86.5s-.7-.2-.86-.5c-.09-.15-.14-.32-.14-.5 0-.55.44-1 1-1 .55 0 1 .45 1 1 0 .18-.05.35-.14.5zm3.14-2h-1.4c-.52-.9-1.49-1.5-2.6-1.5s-2.08.6-2.6 1.5H15v-4V8h3.51c.36 0 .69.19.87.5l2.48 4.3c.09.15.14.33.14.5v3.2z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleSecuredBonus = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M12 3.04c1.26 1.1 3.5 2.48 7 2.85V13v.05c0 .06.09 6.42-6.35 8.67-.21.07-.42.11-.65.11s-.44-.04-.65-.11C4.98 19.5 5 13.3 5 13.05V13l-.03-7.1C8.5 5.52 10.74 4.14 12 3.04m0-2.01a1.97 1.97 0 0 0-1.3.49c-1.09.95-2.96 2.07-5.95 2.39-1 .11-1.75.97-1.75 1.98V13s-.2 7.85 7.69 10.61c.42.15.86.22 1.31.22s.89-.07 1.31-.22C21.2 20.85 21 13 21 13V5.89c0-1.01-.75-1.87-1.75-1.98-2.98-.32-4.86-1.44-5.95-2.39a1.97 1.97 0 0 0-1.3-.49h0zm0 13.4L14.6 16l-.69-2.96 2.3-1.99-3.03-.26L12 8l-1.18 2.79-3.03.26 2.3 1.99L9.4 16l2.6-1.57z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleTheftFire = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M19 4h-3.09l2.88-1.66a1.01 1.01 0 0 0 .37-1.37A1.01 1.01 0 0 0 17.79.6L14 2.78C13.48 2.3 12.78 2 12 2c-1.33 0-2.44.87-2.84 2.07C6.25 4.47 4 6.98 4 10v8c0 .55.45 1 1 1s1-.45 1-1v-8c0-1.94 1.38-3.55 3.21-3.92.08.21.18.42.31.6C8.02 7.55 7 9.15 7 11v11c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V11c0-1.85-1.02-3.45-2.52-4.32a2.85 2.85 0 0 0 .33-.68H19c.55 0 1-.45 1-1s-.45-1-1-1zm-4 18H9V11a3.01 3.01 0 0 1 3-3 3.01 3.01 0 0 1 3 3v11zM11 5c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgVehicleVandalism = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M18.06 1.22a4.61 4.61 0 0 0-3.63 1.79l-9.97 13c-.27.35-.65.6-1.07.71l-.69.19c-.7.19-1.23.72-1.41 1.41a1.97 1.97 0 0 0 .52 1.93l1.79 1.79c.38.38.88.59 1.41.59a1.98 1.98 0 0 0 .53-.07c.7-.19 1.23-.72 1.41-1.41l.18-.69c.11-.42.37-.8.71-1.07l13-9.97c1.12-.86 1.79-2.21 1.79-3.63 0-2.52-2.05-4.57-4.57-4.57zM5.2 19.94l-.18.69-1.79-1.79.69-.18c.33-.09.64-.23.93-.4l.75.75c-.17.3-.31.6-.4.93zM19.63 7.83L6.96 17.55l-.66-.66 9.72-12.67c.48-.63 1.25-1 2.04-1a2.57 2.57 0 0 1 2.57 2.57c0 .79-.37 1.56-1 2.04zM5 4h6c.55 0 1-.45 1-1s-.45-1-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1zM3 7h6c.55 0 1-.45 1-1s-.45-1-1-1H3c-.55 0-1 .45-1 1s.45 1 1 1z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWallet = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M21 6.18v-.86a2.99 2.99 0 0 0-1.17-2.38 3 3 0 0 0-2.6-.52L3.79 6.02C2.23 6.13 1 7.42 1 9v11c0 1.66 1.34 3 3 3h16c1.66 0 3-1.34 3-3V9c0-1.3-.84-2.4-2-2.82zm-3.26-1.83c.3-.08.62-.02.87.17a1.02 1.02 0 0 1 .39.8V6h-7.41l6.15-1.65zM21 20c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1V9c0-.55.45-1 1-1h16c.55 0 1 .45 1 1v11z\"/><circle cx=\"17.5\" cy=\"14.5\" r=\"1.5\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWhatsapp = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M20.5 3.49C18.25 1.24 15.25 0 12.05 0 5.46 0 .1 5.33.1 11.89a11.82 11.82 0 0 0 1.6 5.95L0 24l6.33-1.65a11.96 11.96 0 0 0 5.71 1.45h0C18.64 23.79 24 18.46 24 11.9c0-3.18-1.24-6.16-3.5-8.41zm-8.45 18.3h0c-1.79 0-3.53-.48-5.06-1.38l-.36-.21-3.76.98 1-3.65-.24-.37c-.99-1.57-1.52-3.39-1.52-5.26 0-5.45 4.46-9.88 9.94-9.88a9.87 9.87 0 0 1 7.02 2.9c1.88 1.87 2.91 4.35 2.91 6.99 0 5.44-4.46 9.88-9.93 9.88zm5.45-7.41c-.3-.15-1.77-.87-2.04-.97s-.47-.15-.67.15-.77.97-.95 1.17c-.17.2-.35.22-.65.07s-1.26-.46-2.4-1.47c-.89-.79-1.49-1.76-1.66-2.06s-.02-.46.13-.61c.13-.13.3-.35.45-.52s.2-.3.3-.5.05-.37-.02-.52-.68-1.6-.93-2.2c-.24-.58-.49-.5-.67-.51-.17-.01-.37-.01-.57-.01a1.1 1.1 0 0 0-.8.37c-.28.3-1.04 1.02-1.04 2.48s1.07 2.88 1.22 3.07c.15.2 2.11 3.2 5.1 4.49.71.31 1.27.49 1.7.63.72.23 1.37.19 1.88.12.57-.09 1.77-.72 2.02-1.41s.25-1.29.17-1.41c-.07-.13-.27-.21-.57-.36z\" fill-rule=\"evenodd\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgWrench = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M14.35 3.99l.33.01-2.44 2.44a2.97 2.97 0 0 0-.88 2.12 2.97 2.97 0 0 0 .88 2.12l1.41 1.41a2.97 2.97 0 0 0 2.12.88 2.97 2.97 0 0 0 2.12-.88l2.45-2.45c.09 1.68-.52 3.35-1.74 4.57a5.96 5.96 0 0 1-4.24 1.75c-.22 0-.44-.01-.66-.04l-.95-.1-.68.68-3.37 3.37a.96.96 0 0 1-.7.3c-.15 0-.45-.04-.71-.29l-2.83-2.83c-.39-.39-.39-1.02 0-1.41l3.37-3.37.68-.68-.1-.95c-.23-2.09.62-4.11 2.29-5.41 1.02-.8 2.32-1.24 3.65-1.24m7.3 4.36h0 0m-7.3-6.36c-1.74 0-3.47.55-4.89 1.66-2.33 1.82-3.34 4.57-3.05 7.2l-3.37 3.37a3 3 0 0 0 0 4.24l2.83 2.83c.59.59 1.35.88 2.12.88s1.54-.29 2.12-.88l3.37-3.37.88.05c2.05 0 4.09-.78 5.65-2.34 2.27-2.27 2.88-5.55 1.86-8.38-.15-.41-.53-.62-.92-.62-.26 0-.52.1-.72.3l-3.75 3.75a.98.98 0 0 1-.71.29c-.26 0-.51-.1-.71-.29l-1.41-1.41c-.39-.39-.39-1.02 0-1.41l3.75-3.75c.53-.53.31-1.42-.4-1.66-.84-.31-1.74-.46-2.65-.46h0z\"/></svg>';
var $author$project$Pyxis$Components$IconSet$svgX = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M13.86 10.47L21.15 2h-1.73l-6.33 7.35L8.04 2H2.22l7.64 11.12L2.22 22h1.73l6.68-7.77L15.97 22h5.83l-7.92-11.53h0zm-2.36 2.75l-.77-1.11L4.56 3.3h2.65l4.97 7.11.77 1.11 6.46 9.24h-2.65l-5.27-7.54h0z\"/></svg>\n';
var $author$project$Pyxis$Components$IconSet$svgYoutube = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\" width=\"24\" height=\"24\"><path d=\"M23.5 6.22c-.28-1.03-1.09-1.85-2.12-2.12-1.87-.5-9.38-.5-9.38-.5s-7.51 0-9.38.5C1.59 4.38.78 5.19.5 6.22 0 8.09 0 12 0 12s0 3.91.5 5.78c.28 1.03 1.09 1.85 2.12 2.12 1.87.5 9.38.5 9.38.5s7.51 0 9.38-.5c1.03-.28 1.85-1.09 2.12-2.12.5-1.87.5-5.78.5-5.78s0-3.91-.5-5.78z\"/><path d=\"M9.6 15.6l6.24-3.6L9.6 8.4z\" fill=\"#fff\"/></svg>';
var $author$project$Pyxis$Components$IconSet$toString = function (icon) {
	switch (icon) {
		case 0:
			return $author$project$Pyxis$Components$IconSet$svgActivity;
		case 1:
			return $author$project$Pyxis$Components$IconSet$svgAlarm;
		case 2:
			return $author$project$Pyxis$Components$IconSet$svgAndroid;
		case 3:
			return $author$project$Pyxis$Components$IconSet$svgApple;
		case 4:
			return $author$project$Pyxis$Components$IconSet$svgArrowDown;
		case 5:
			return $author$project$Pyxis$Components$IconSet$svgArrowLeft;
		case 6:
			return $author$project$Pyxis$Components$IconSet$svgArrowRight;
		case 7:
			return $author$project$Pyxis$Components$IconSet$svgArrowUp;
		case 8:
			return $author$project$Pyxis$Components$IconSet$svgBaloon;
		case 9:
			return $author$project$Pyxis$Components$IconSet$svgBank;
		case 10:
			return $author$project$Pyxis$Components$IconSet$svgBell;
		case 11:
			return $author$project$Pyxis$Components$IconSet$svgBook;
		case 12:
			return $author$project$Pyxis$Components$IconSet$svgBuildings;
		case 13:
			return $author$project$Pyxis$Components$IconSet$svgBurger;
		case 14:
			return $author$project$Pyxis$Components$IconSet$svgCalendarClock;
		case 15:
			return $author$project$Pyxis$Components$IconSet$svgCalendarMail;
		case 16:
			return $author$project$Pyxis$Components$IconSet$svgCalendar;
		case 17:
			return $author$project$Pyxis$Components$IconSet$svgCamera;
		case 18:
			return $author$project$Pyxis$Components$IconSet$svgCar;
		case 19:
			return $author$project$Pyxis$Components$IconSet$svgCardsOverlap;
		case 20:
			return $author$project$Pyxis$Components$IconSet$svgCart;
		case 21:
			return $author$project$Pyxis$Components$IconSet$svgChart;
		case 22:
			return $author$project$Pyxis$Components$IconSet$svgChat;
		case 23:
			return $author$project$Pyxis$Components$IconSet$svgCheckCircle;
		case 24:
			return $author$project$Pyxis$Components$IconSet$svgCheckSquare;
		case 25:
			return $author$project$Pyxis$Components$IconSet$svgCheck;
		case 26:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleDown;
		case 27:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleLeft;
		case 28:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleRight;
		case 29:
			return $author$project$Pyxis$Components$IconSet$svgChevronCircleUp;
		case 30:
			return $author$project$Pyxis$Components$IconSet$svgChevronDown;
		case 31:
			return $author$project$Pyxis$Components$IconSet$svgChevronLeft;
		case 32:
			return $author$project$Pyxis$Components$IconSet$svgChevronRight;
		case 33:
			return $author$project$Pyxis$Components$IconSet$svgChevronUp;
		case 34:
			return $author$project$Pyxis$Components$IconSet$svgClock24;
		case 35:
			return $author$project$Pyxis$Components$IconSet$svgClock;
		case 36:
			return $author$project$Pyxis$Components$IconSet$svgComputer;
		case 37:
			return $author$project$Pyxis$Components$IconSet$svgCreditCard;
		case 38:
			return $author$project$Pyxis$Components$IconSet$svgCrossCircle;
		case 39:
			return $author$project$Pyxis$Components$IconSet$svgCrossSquare;
		case 40:
			return $author$project$Pyxis$Components$IconSet$svgCross;
		case 41:
			return $author$project$Pyxis$Components$IconSet$svgDamageThirdParties;
		case 42:
			return $author$project$Pyxis$Components$IconSet$svgDashboard;
		case 43:
			return $author$project$Pyxis$Components$IconSet$svgDevices;
		case 44:
			return $author$project$Pyxis$Components$IconSet$svgDiamond;
		case 45:
			return $author$project$Pyxis$Components$IconSet$svgDirection;
		case 46:
			return $author$project$Pyxis$Components$IconSet$svgDocumentAlert;
		case 47:
			return $author$project$Pyxis$Components$IconSet$svgDocumentPlus;
		case 48:
			return $author$project$Pyxis$Components$IconSet$svgDocumentSigned;
		case 49:
			return $author$project$Pyxis$Components$IconSet$svgDocument;
		case 50:
			return $author$project$Pyxis$Components$IconSet$svgDownload;
		case 51:
			return $author$project$Pyxis$Components$IconSet$svgEuro;
		case 52:
			return $author$project$Pyxis$Components$IconSet$svgExclamationCircle;
		case 53:
			return $author$project$Pyxis$Components$IconSet$svgExclamationTriangle;
		case 54:
			return $author$project$Pyxis$Components$IconSet$svgExternalLink;
		case 55:
			return $author$project$Pyxis$Components$IconSet$svgEyeSlashed;
		case 56:
			return $author$project$Pyxis$Components$IconSet$svgEye;
		case 57:
			return $author$project$Pyxis$Components$IconSet$svgFacebook;
		case 58:
			return $author$project$Pyxis$Components$IconSet$svgFamilyRc;
		case 59:
			return $author$project$Pyxis$Components$IconSet$svgFax;
		case 60:
			return $author$project$Pyxis$Components$IconSet$svgFloppyDisk;
		case 61:
			return $author$project$Pyxis$Components$IconSet$svgFolder;
		case 62:
			return $author$project$Pyxis$Components$IconSet$svgGear;
		case 63:
			return $author$project$Pyxis$Components$IconSet$svgGift;
		case 64:
			return $author$project$Pyxis$Components$IconSet$svgGpsDisabled;
		case 65:
			return $author$project$Pyxis$Components$IconSet$svgGpsEnabled;
		case 66:
			return $author$project$Pyxis$Components$IconSet$svgGrid;
		case 67:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageEbike;
		case 68:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageFamily;
		case 69:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamagePets;
		case 70:
			return $author$project$Pyxis$Components$IconSet$svgHomeDamageThirdParties;
		case 71:
			return $author$project$Pyxis$Components$IconSet$svgHomeDomesticThefts;
		case 72:
			return $author$project$Pyxis$Components$IconSet$svgHomeEmergenciesRepairs;
		case 73:
			return $author$project$Pyxis$Components$IconSet$svgHomeFireExplosion;
		case 74:
			return $author$project$Pyxis$Components$IconSet$svgHomeFurnitureContent;
		case 75:
			return $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFamily;
		case 76:
			return $author$project$Pyxis$Components$IconSet$svgHomeInjuriesFreetime;
		case 77:
			return $author$project$Pyxis$Components$IconSet$svgHomeInsuranceHome;
		case 78:
			return $author$project$Pyxis$Components$IconSet$svgHomeLegalProtection;
		case 79:
			return $author$project$Pyxis$Components$IconSet$svgHomeNaturalEvents;
		case 80:
			return $author$project$Pyxis$Components$IconSet$svgHomeRobberiesOutside;
		case 81:
			return $author$project$Pyxis$Components$IconSet$svgHomeTelephoneLegalAdvice;
		case 82:
			return $author$project$Pyxis$Components$IconSet$svgHomeTheftValuable;
		case 83:
			return $author$project$Pyxis$Components$IconSet$svgHome;
		case 84:
			return $author$project$Pyxis$Components$IconSet$svgHourglass;
		case 85:
			return $author$project$Pyxis$Components$IconSet$svgIdCard;
		case 86:
			return $author$project$Pyxis$Components$IconSet$svgInfoCircle;
		case 87:
			return $author$project$Pyxis$Components$IconSet$svgInstagram;
		case 88:
			return $author$project$Pyxis$Components$IconSet$svgInsuranceHome;
		case 89:
			return $author$project$Pyxis$Components$IconSet$svgJobs;
		case 90:
			return $author$project$Pyxis$Components$IconSet$svgKey;
		case 91:
			return $author$project$Pyxis$Components$IconSet$svgKeyhole;
		case 92:
			return $author$project$Pyxis$Components$IconSet$svgLink;
		case 93:
			return $author$project$Pyxis$Components$IconSet$svgLinkedin;
		case 94:
			return $author$project$Pyxis$Components$IconSet$svgListCircle;
		case 95:
			return $author$project$Pyxis$Components$IconSet$svgList;
		case 96:
			return $author$project$Pyxis$Components$IconSet$svgLoader;
		case 97:
			return $author$project$Pyxis$Components$IconSet$svgLocalProtection;
		case 98:
			return $author$project$Pyxis$Components$IconSet$svgLockOpen;
		case 99:
			return $author$project$Pyxis$Components$IconSet$svgLock;
		case 100:
			return $author$project$Pyxis$Components$IconSet$svgLogout;
		case 101:
			return $author$project$Pyxis$Components$IconSet$svgMagicWand;
		case 102:
			return $author$project$Pyxis$Components$IconSet$svgMagnifyingGlass;
		case 103:
			return $author$project$Pyxis$Components$IconSet$svgMail;
		case 104:
			return $author$project$Pyxis$Components$IconSet$svgMapMarker;
		case 105:
			return $author$project$Pyxis$Components$IconSet$svgMedal;
		case 106:
			return $author$project$Pyxis$Components$IconSet$svgMinusCircle;
		case 107:
			return $author$project$Pyxis$Components$IconSet$svgMinusSquare;
		case 108:
			return $author$project$Pyxis$Components$IconSet$svgMinus;
		case 109:
			return $author$project$Pyxis$Components$IconSet$svgMoped;
		case 110:
			return $author$project$Pyxis$Components$IconSet$svgMoreHorizontalCircle;
		case 111:
			return $author$project$Pyxis$Components$IconSet$svgMoreHorizontal;
		case 112:
			return $author$project$Pyxis$Components$IconSet$svgMoreVertical;
		case 113:
			return $author$project$Pyxis$Components$IconSet$svgMotorcycle;
		case 114:
			return $author$project$Pyxis$Components$IconSet$svgOdometer;
		case 115:
			return $author$project$Pyxis$Components$IconSet$svgPaperPlane;
		case 116:
			return $author$project$Pyxis$Components$IconSet$svgPaperclip;
		case 117:
			return $author$project$Pyxis$Components$IconSet$svgPec;
		case 118:
			return $author$project$Pyxis$Components$IconSet$svgPenForm;
		case 119:
			return $author$project$Pyxis$Components$IconSet$svgPen;
		case 120:
			return $author$project$Pyxis$Components$IconSet$svgPhone24;
		case 121:
			return $author$project$Pyxis$Components$IconSet$svgPhone;
		case 122:
			return $author$project$Pyxis$Components$IconSet$svgPlate;
		case 123:
			return $author$project$Pyxis$Components$IconSet$svgPlusCircle;
		case 124:
			return $author$project$Pyxis$Components$IconSet$svgPlusSquare;
		case 125:
			return $author$project$Pyxis$Components$IconSet$svgPlus;
		case 126:
			return $author$project$Pyxis$Components$IconSet$svgPound;
		case 127:
			return $author$project$Pyxis$Components$IconSet$svgPrima;
		case 128:
			return $author$project$Pyxis$Components$IconSet$svgQuestionCircle;
		case 129:
			return $author$project$Pyxis$Components$IconSet$svgRefund;
		case 130:
			return $author$project$Pyxis$Components$IconSet$svgReload;
		case 131:
			return $author$project$Pyxis$Components$IconSet$svgShieldCheck;
		case 132:
			return $author$project$Pyxis$Components$IconSet$svgSki;
		case 133:
			return $author$project$Pyxis$Components$IconSet$svgSmartphone;
		case 134:
			return $author$project$Pyxis$Components$IconSet$svgStarFilled;
		case 135:
			return $author$project$Pyxis$Components$IconSet$svgStar;
		case 136:
			return $author$project$Pyxis$Components$IconSet$svgSteeringWheel;
		case 137:
			return $author$project$Pyxis$Components$IconSet$svgSwitch;
		case 138:
			return $author$project$Pyxis$Components$IconSet$svgTablet;
		case 139:
			return $author$project$Pyxis$Components$IconSet$svgThumbUp;
		case 140:
			return $author$project$Pyxis$Components$IconSet$svgTrashBin;
		case 141:
			return $author$project$Pyxis$Components$IconSet$svgTshirtSport;
		case 142:
			return $author$project$Pyxis$Components$IconSet$svgTwitter;
		case 143:
			return $author$project$Pyxis$Components$IconSet$svgUmbrella;
		case 144:
			return $author$project$Pyxis$Components$IconSet$svgUpload;
		case 145:
			return $author$project$Pyxis$Components$IconSet$svgUserCheck;
		case 146:
			return $author$project$Pyxis$Components$IconSet$svgUserCircle;
		case 147:
			return $author$project$Pyxis$Components$IconSet$svgUserCross;
		case 148:
			return $author$project$Pyxis$Components$IconSet$svgUserMinus;
		case 149:
			return $author$project$Pyxis$Components$IconSet$svgUserPlus;
		case 150:
			return $author$project$Pyxis$Components$IconSet$svgUser;
		case 151:
			return $author$project$Pyxis$Components$IconSet$svgUsers;
		case 152:
			return $author$project$Pyxis$Components$IconSet$svgVan;
		case 153:
			return $author$project$Pyxis$Components$IconSet$svgVehicleCollisionKasko;
		case 154:
			return $author$project$Pyxis$Components$IconSet$svgVehicleDriverInjuries;
		case 155:
			return $author$project$Pyxis$Components$IconSet$svgVehicleFullKasko;
		case 156:
			return $author$project$Pyxis$Components$IconSet$svgVehicleGlasses;
		case 157:
			return $author$project$Pyxis$Components$IconSet$svgVehicleLegalProtection;
		case 158:
			return $author$project$Pyxis$Components$IconSet$svgVehicleNaturalEvents;
		case 159:
			return $author$project$Pyxis$Components$IconSet$svgVehicleRicourseWaiver;
		case 160:
			return $author$project$Pyxis$Components$IconSet$svgVehicleRoadsideAssistance;
		case 161:
			return $author$project$Pyxis$Components$IconSet$svgVehicleSecuredBonus;
		case 162:
			return $author$project$Pyxis$Components$IconSet$svgVehicleTheftFire;
		case 163:
			return $author$project$Pyxis$Components$IconSet$svgVehicleVandalism;
		case 164:
			return $author$project$Pyxis$Components$IconSet$svgWallet;
		case 165:
			return $author$project$Pyxis$Components$IconSet$svgWhatsapp;
		case 166:
			return $author$project$Pyxis$Components$IconSet$svgWrench;
		case 167:
			return $author$project$Pyxis$Components$IconSet$svgX;
		default:
			return $author$project$Pyxis$Components$IconSet$svgYoutube;
	}
};
var $author$project$Pyxis$Components$Icon$render = function (configuration) {
	var ariaLabel = configuration.e7;
	var ariaLabelledby = configuration.e9;
	var icon = configuration.iN;
	var color = configuration.fn;
	var theme = configuration.eP;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				$author$project$Pyxis$Components$Icon$getClassList(configuration)),
				$author$project$Pyxis$Commons$Attributes$ariaHidden(
				$elm_community$maybe_extra$Maybe$Extra$isNothing(ariaLabel) && $elm_community$maybe_extra$Maybe$Extra$isNothing(ariaLabelledby)),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabel, ariaLabel),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabelledBy, ariaLabelledby),
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$elm_community$maybe_extra$Maybe$Extra$isJust(ariaLabel) || $elm_community$maybe_extra$Maybe$Extra$isJust(ariaLabelledby),
				$author$project$Pyxis$Commons$Attributes$role('img')),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Icon$inlineColorVariable, color),
				A2(
				$elm$html$Html$Attributes$attribute,
				'data-name',
				$author$project$Pyxis$Components$IconSet$toLabel(icon)),
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
				$author$project$Pyxis$Commons$Attributes$theme(theme))
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				$elm$core$Result$toMaybe(
					$Garados007$elm_svg_parser$SvgParser$parse(
						$author$project$Pyxis$Components$IconSet$toString(icon))))
			]));
};
var $elm$html$Html$a = _VirtualDom_node('a');
var $author$project$Pyxis$Components$IconSet$ExternalLink = 54;
var $author$project$Pyxis$Components$IconSet$externalLink = 54;
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Pyxis$Commons$Attributes$LinkTarget$toString = function (linkTarget) {
	switch (linkTarget) {
		case 0:
			return '_blank';
		case 1:
			return '_self';
		case 2:
			return '_parent';
		case 3:
			return '_top';
		default:
			return 'framename';
	}
};
var $author$project$Pyxis$Commons$Attributes$target = A2($elm$core$Basics$composeR, $author$project$Pyxis$Commons$Attributes$LinkTarget$toString, $elm$html$Html$Attributes$target);
var $author$project$Pyxis$Commons$Attributes$testId = $elm$html$Html$Attributes$attribute('data-test-id');
var $author$project$Pyxis$Components$Icon$withClassList = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: a});
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupExternalLinkItem = F3(
	function (tabIndexValue, _v0, target) {
		var href = _v0.V;
		var label = _v0.d3;
		var danger = _v0.aG;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-nav-item side-nav-item--with-icon'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-nav-item--danger', danger)
						])),
					$elm$html$Html$Attributes$href(href),
					$elm$html$Html$Attributes$tabindex(tabIndexValue),
					$author$project$Pyxis$Commons$Attributes$target(target),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label),
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('side-nav-item__external-link-icon', true)
							]),
						$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$externalLink)))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$isActiveRoute = F2(
	function (href, activeRoute) {
		return _Utils_eq(href, activeRoute);
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupInternalLinkItem = F3(
	function (activeRoute, tabIndexValue, _v0) {
		var href = _v0.V;
		var label = _v0.d3;
		var danger = _v0.aG;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-nav-item'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-nav-item--danger', danger),
							_Utils_Tuple2(
							'side-nav-item--selected',
							A2($author$project$Pyxis$Components$NavigationSidebar$isActiveRoute, href, activeRoute))
						])),
					$elm$html$Html$Attributes$href(href),
					$elm$html$Html$Attributes$tabindex(tabIndexValue),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(label)
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupItemLink = F3(
	function (activeRoute, tabIndexValue, groupLink) {
		if (!groupLink.$) {
			var configuration = groupLink.a;
			return A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupInternalLinkItem, activeRoute, tabIndexValue, configuration);
		} else {
			var configuration = groupLink.a;
			var target = groupLink.b;
			return A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupExternalLinkItem, tabIndexValue, configuration, target);
		}
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupItemAsDropdown = F2(
	function (activeRoute, groupLink) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__dropdown__list__item')
				]),
			_List_fromArray(
				[
					A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupItemLink, activeRoute, 0, groupLink)
				]));
	});
var $elm$html$Html$ul = _VirtualDom_node('ul');
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupAsDropdown = F3(
	function (activeRoute, label, groupItemList) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__dropdown-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__dropdown__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__dropdown__list')
						]),
					A2(
						$elm$core$List$map,
						$author$project$Pyxis$Components$NavigationSidebar$renderGroupItemAsDropdown(activeRoute),
						$author$project$Pyxis$Commons$NonEmptyList$toList(groupItemList)))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupItemAsList = F3(
	function (activeRoute, groupExpanded, groupLink) {
		var tabIndexValue = groupExpanded ? 0 : (-1);
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__group__list__item')
				]),
			_List_fromArray(
				[
					A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupItemLink, activeRoute, tabIndexValue, groupLink)
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroupAsList = F3(
	function (activeRoute, groupItemList, groupExpanded) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__group-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__group__list')
						]),
					A2(
						$elm$core$List$map,
						A2($author$project$Pyxis$Components$NavigationSidebar$renderGroupItemAsList, activeRoute, groupExpanded),
						$author$project$Pyxis$Commons$NonEmptyList$toList(groupItemList)))
				]));
	});
var $author$project$Pyxis$Commons$Render$renderIfElse = F3(
	function (condition, falseHtml, trueHtml) {
		return condition ? trueHtml : falseHtml;
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderGroup = F5(
	function (key, _v0, groupItemList, tagger, model) {
		var label = _v0.d3;
		var icon = _v0.iN;
		var activeRoute = model.b6;
		var groupIsSelected = A2(
			$elm$core$List$member,
			activeRoute,
			$author$project$Pyxis$Components$NavigationSidebar$pickNavItemGroupLinkHref(groupItemList));
		var groupExpanded = A2($author$project$Pyxis$Components$NavigationSidebar$isGroupExpanded, key, model);
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-nav__list__item side-nav__list__item--with-sub-item'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-nav__list__item--expanded', groupExpanded)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-nav-item side-nav-item--button side-nav-item--with-icon'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('side-nav-item--selected', groupIsSelected && (!groupExpanded))
								])),
							A2($elm$html$Html$Attributes$attribute, 'aria-controls', 'group-item'),
							A2($elm$html$Html$Attributes$attribute, 'data-group-key', key),
							$elm$html$Html$Events$onClick(
							tagger(
								$author$project$Pyxis$Components$NavigationSidebar$ToggleGroupExpand(key)))
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Icon$render(
							$author$project$Pyxis$Components$Icon$config(icon)),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-nav-item__text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(label)
								])),
							$author$project$Pyxis$Components$Icon$render(
							A2(
								$author$project$Pyxis$Components$Icon$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('side-nav-item__toggle-icon', true)
									]),
								$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$chevronDown)))
						])),
					A3(
					$author$project$Pyxis$Commons$Render$renderIfElse,
					$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model),
					A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupAsDropdown, activeRoute, label, groupItemList),
					A3($author$project$Pyxis$Components$NavigationSidebar$renderGroupAsList, activeRoute, groupItemList, groupExpanded))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderKeyedGroup = F5(
	function (key, groupConfig, groupItemList, tagger, model) {
		return _Utils_Tuple2(
			key,
			A5($author$project$Pyxis$Components$NavigationSidebar$renderGroup, key, groupConfig, groupItemList, tagger, model));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemText = function (label) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-nav-item__text')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemToolTip = function (label) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-nav-item__tooltip')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderExternalLink = F3(
	function (model, _v0, target) {
		var href = _v0.V;
		var divider = _v0.bh;
		var label = _v0.d3;
		var icon = _v0.iN;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-nav__list__item', true),
							_Utils_Tuple2('side-nav__list__item--with-divider', divider)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-nav-item side-nav-item--with-icon'),
							$elm$html$Html$Attributes$href(href),
							$author$project$Pyxis$Commons$Attributes$target(target),
							A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Icon$render(
							$author$project$Pyxis$Components$Icon$config(icon)),
							A3(
							$author$project$Pyxis$Commons$Render$renderIfElse,
							$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model),
							$author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemToolTip(label),
							$author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemText(label)),
							$author$project$Pyxis$Components$Icon$render(
							A2(
								$author$project$Pyxis$Components$Icon$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('side-nav-item__external-link-icon', true)
									]),
								$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$externalLink)))
						]))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderInternalLink = F2(
	function (model, _v0) {
		var activeRoute = model.b6;
		var href = _v0.V;
		var divider = _v0.bh;
		var label = _v0.d3;
		var icon = _v0.iN;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-nav__list__item', true),
							_Utils_Tuple2('side-nav__list__item--with-divider', divider)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-nav-item'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('side-nav-item', true),
									_Utils_Tuple2(
									'side-nav-item--selected',
									A2($author$project$Pyxis$Components$NavigationSidebar$isActiveRoute, href, activeRoute))
								])),
							$elm$html$Html$Attributes$href(href),
							A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Icon$render(
							$author$project$Pyxis$Components$Icon$config(icon)),
							A3(
							$author$project$Pyxis$Commons$Render$renderIfElse,
							$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model),
							$author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemToolTip(label),
							$author$project$Pyxis$Components$NavigationSidebar$renderNavigationItemText(label))
						]))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderLink = F2(
	function (model, navItemLink) {
		if (!navItemLink.$) {
			var navigationItemLinkConfig = navItemLink.a;
			return A2($author$project$Pyxis$Components$NavigationSidebar$renderInternalLink, model, navigationItemLinkConfig);
		} else {
			var navigationItemLinkConfig = navItemLink.a;
			var target = navItemLink.b;
			return A3($author$project$Pyxis$Components$NavigationSidebar$renderExternalLink, model, navigationItemLinkConfig, target);
		}
	});
var $elm$html$Html$h2 = _VirtualDom_node('h2');
var $author$project$Pyxis$Components$NavigationSidebar$renderSectionTitle = function (title) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-nav__list__item')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$h2,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-nav__list__item__title')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(title)
					]))
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderNavItem = F4(
	function (tagger, model, elementIndex, navItem) {
		switch (navItem.$) {
			case 0:
				var navItemLink = navItem.a;
				return _Utils_Tuple2(
					$author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey(elementIndex),
					A2($author$project$Pyxis$Components$NavigationSidebar$renderLink, model, navItemLink));
			case 1:
				var title = navItem.a;
				return _Utils_Tuple2(
					$author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey(elementIndex),
					$author$project$Pyxis$Components$NavigationSidebar$renderSectionTitle(title));
			default:
				if (navItem.a.b.$ === 1) {
					var _v1 = navItem.a;
					var _v2 = _v1.b;
					return _Utils_Tuple2(
						$author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey(elementIndex),
						$author$project$Pyxis$Commons$Render$empty);
				} else {
					var _v3 = navItem.a;
					var groupConfiguration = _v3.a;
					var items = _v3.b.a;
					return $author$project$Pyxis$Components$NavigationSidebar$isExpanded(model) ? A5(
						$author$project$Pyxis$Components$NavigationSidebar$renderKeyedGroup,
						'with-list-' + $author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey(elementIndex),
						groupConfiguration,
						items,
						tagger,
						model) : A5(
						$author$project$Pyxis$Components$NavigationSidebar$renderKeyedGroup,
						'with-dropdown-' + $author$project$Pyxis$Components$NavigationSidebar$elementIndexToKey(elementIndex),
						groupConfiguration,
						items,
						tagger,
						model);
				}
		}
	});
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Keyed$ul = $elm$html$Html$Keyed$node('ul');
var $author$project$Pyxis$Components$NavigationSidebar$renderNavigationList = F4(
	function (tagger, model, ariaLabel, navigation) {
		return A2(
			$elm$html$Html$nav,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-nav'),
					A2($elm$html$Html$Attributes$attribute, 'aria-label', ariaLabel)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$Keyed$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-nav__list')
						]),
					A2(
						$elm$core$List$indexedMap,
						A2($author$project$Pyxis$Components$NavigationSidebar$renderNavItem, tagger, model),
						$author$project$Pyxis$Commons$NonEmptyList$toList(navigation)))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderSlotBottom = F2(
	function (model, customContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-panel__slot-bottom', true),
							_Utils_Tuple2(
							'side-panel__slot-bottom--opened',
							$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model)),
							_Utils_Tuple2(
							'side-panel__slot-bottom--collapsed',
							!$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model))
						]))
				]),
			_List_fromArray(
				[customContent]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderSlotTop = F2(
	function (model, customContent) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-panel__slot-top', true),
							_Utils_Tuple2(
							'side-panel__slot-top--opened',
							$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model)),
							_Utils_Tuple2(
							'side-panel__slot-top--collapsed',
							!$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model))
						]))
				]),
			_List_fromArray(
				[customContent]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$renderContent = F3(
	function (tagger, model, _v0) {
		var slotBottom = _v0.hk;
		var slotTop = _v0.hl;
		var navigation = _v0.gr;
		var id = _v0.iO;
		var classList = _v0.fj;
		var testId = _v0.eO;
		var navigationAriaLabel = _v0.gs;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__content-wrapper'),
					$elm$html$Html$Attributes$classList(classList),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						$author$project$Pyxis$Components$NavigationSidebar$renderSlotTop(model),
						slotTop)),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						A3($author$project$Pyxis$Components$NavigationSidebar$renderNavigationList, tagger, model, navigationAriaLabel),
						navigation)),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						$author$project$Pyxis$Components$NavigationSidebar$renderSlotBottom(model),
						slotBottom))
				]));
	});
var $author$project$Pyxis$Components$IconSet$Logout = 100;
var $author$project$Pyxis$Components$IconSet$logout = 100;
var $author$project$Pyxis$Components$NavigationSidebar$renderLogoutIcon = $author$project$Pyxis$Components$Icon$render(
	$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$logout));
var $author$project$Pyxis$Components$IconSet$MoreVertical = 112;
var $author$project$Pyxis$Components$IconSet$moreVertical = 112;
var $author$project$Pyxis$Components$NavigationSidebar$renderShowMoreIcon = $author$project$Pyxis$Components$Icon$render(
	$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$moreVertical));
var $author$project$Pyxis$Components$NavigationSidebar$renderActionIcon = function (actions) {
	return A2(
		$elm$core$Maybe$withDefault,
		$author$project$Pyxis$Components$NavigationSidebar$renderLogoutIcon,
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$always($author$project$Pyxis$Components$NavigationSidebar$renderShowMoreIcon),
			actions));
};
var $author$project$Pyxis$Commons$NonEmptyList$map = function (mapper) {
	return A2(
		$elm$core$Tuple$mapBoth,
		mapper,
		$elm$core$List$map(mapper));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderFooterAction = function (_v0) {
	var label = _v0.a;
	var theMsg = _v0.b;
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-panel__footer-dropdown__list__item'),
				$elm$html$Html$Events$onClick(theMsg)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-nav-item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(label)
					]))
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderLogoutAction = function (onLogoutAction) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-panel__footer-dropdown__list__item side-panel__footer-dropdown__list__item--logout'),
				$elm$html$Html$Events$onClick(onLogoutAction)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-nav-item')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text('Logout'),
						$author$project$Pyxis$Components$NavigationSidebar$renderLogoutIcon
					]))
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderFooterDropdown = F3(
	function (userInfo, onLogoutAction, actions) {
		var actionList = A2(
			$elm$core$List$append,
			$author$project$Pyxis$Commons$NonEmptyList$toList(
				A2($author$project$Pyxis$Commons$NonEmptyList$map, $author$project$Pyxis$Components$NavigationSidebar$renderFooterAction, actions)),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$NavigationSidebar$renderLogoutAction(onLogoutAction)
				]));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__footer-dropdown')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__footer-dropdown__title')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-panel__footer__user__name text-m-bold c-neutral-base')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(userInfo.i6)
								])),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-panel__footer__user__email text-s-book c-neutral-25')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(userInfo.cn)
								]))
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__footer-dropdown__list')
						]),
					actionList)
				]));
	});
var $elm$html$Html$img = _VirtualDom_node('img');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$Pyxis$Components$IconSet$User = 150;
var $author$project$Pyxis$Components$IconSet$user = 150;
var $author$project$Pyxis$Components$NavigationSidebar$renderUserAvatar = function (userAvatar) {
	if (!userAvatar.$) {
		return $author$project$Pyxis$Components$Icon$render(
			$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$user));
	} else {
		var imageReference = userAvatar.a;
		return A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(imageReference)
				]),
			_List_Nil);
	}
};
var $author$project$Pyxis$Components$NavigationSidebar$renderFooter = function (_v0) {
	var actions = _v0.a0;
	var userAvatar = _v0.hH;
	var userInfo = _v0.j4;
	var onLogout = _v0.jh;
	var id = _v0.iO;
	var classList = _v0.fj;
	var testId = _v0.eO;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-panel__footer-wrapper'),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-panel__footer'),
						$elm$html$Html$Events$onClick(onLogout)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('side-panel__footer__avatar')
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Components$NavigationSidebar$renderUserAvatar(userAvatar)
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('side-panel__footer__user')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('side-panel__footer__user__name text-m-bold')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(userInfo.i6)
									])),
								A2(
								$elm$html$Html$span,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('side-panel__footer__user__email text-s-book')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(userInfo.cn)
									]))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('side-panel__footer__icon', true),
										_Utils_Tuple2(
										'side-panel__footer__icon--logout',
										$elm_community$maybe_extra$Maybe$Extra$isNothing(actions))
									]))
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Components$NavigationSidebar$renderActionIcon(actions)
							]))
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2($author$project$Pyxis$Components$NavigationSidebar$renderFooterDropdown, userInfo, onLogout),
					actions))
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$ToggleExpand = {$: 0};
var $elm$svg$Svg$clipPath = $elm$svg$Svg$trustedNode('clipPath');
var $elm$svg$Svg$Attributes$clipPath = _VirtualDom_attribute('clip-path');
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$defs = $elm$svg$Svg$trustedNode('defs');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$Attributes$id = _VirtualDom_attribute('id');
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$rect = $elm$svg$Svg$trustedNode('rect');
var $elm$svg$Svg$Attributes$transform = _VirtualDom_attribute('transform');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $elm$svg$Svg$Attributes$xmlSpace = A2(_VirtualDom_attributeNS, 'http://www.w3.org/XML/1998/namespace', 'xml:space');
var $author$project$Pyxis$Components$NavigationSidebar$defaultCollapsedLogo = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('40'),
			$elm$svg$Svg$Attributes$height('40'),
			$elm$svg$Svg$Attributes$viewBox('0 0 40 40'),
			$elm$svg$Svg$Attributes$fill('none'),
			$elm$svg$Svg$Attributes$xmlSpace('http://www.w3.org/2000/svg')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$clipPath('url(#clip0_689_42689)')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M20.0005 0C8.95399 0 0.00050354 8.95349 0.00050354 20C0.00050354 31.0465 8.95399 40 20.0005 40C31.047 40 40.0005 31.0465 40.0005 20C40.0005 8.95349 31.047 0 20.0005 0ZM30.6594 19.7674C30.0393 24.0698 26.3571 26.5504 21.7834 26.1628C20.1168 26.0078 18.4889 25.5814 16.7059 25.2713C16.7059 28.2946 16.7059 31.4729 16.7059 33.3333C16.6672 34.1473 15.9695 34.8062 15.1168 34.8062C14.2641 34.8062 13.5276 34.1473 13.5276 33.3333V33.2946C13.5276 26.8992 13.5276 18.4496 13.5276 11.7829C12.2098 12.4031 10.9307 13.062 9.69043 13.7209C8.60515 14.3023 7.71368 14.1473 7.20981 13.3333C6.66717 12.4419 6.97725 11.5504 8.14004 10.8915C11.2021 9.26357 12.5586 8.60465 14.4191 7.75194C14.6517 7.67442 14.8842 7.5969 15.1555 7.5969C16.047 7.63566 16.7834 8.33333 16.7834 9.14729V11.6279C16.7447 13.062 16.7834 14.4574 16.7834 15.8915C16.7834 17.6357 16.8222 19.3798 16.7834 21.124C16.7834 21.7054 16.9772 22.0155 17.5586 22.2481C19.3416 23.0233 21.2021 23.4109 23.14 23.2171C25.6594 23.0233 27.2873 21.7054 27.5586 19.3023C27.7912 17.3643 27.8687 15.3876 27.7137 13.4496C27.4424 10.4264 26.1633 9.03101 23.5664 8.60465C22.4036 8.41085 21.3183 8.48837 20.2331 8.72093C20.1168 8.75969 20.0005 8.75969 19.8842 8.75969C19.109 8.75969 18.3726 8.06202 18.3726 7.28682C18.3726 6.55039 18.8765 6.00775 19.6129 5.89147C21.1633 5.5814 22.7912 5.54264 24.4579 5.85271C27.9075 6.43411 30.1555 8.48837 30.6594 11.7054C30.9695 14.3411 31.047 17.093 30.6594 19.7674Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil)
				])),
			A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id('clip0_689_42689')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$rect,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$width('40'),
									$elm$svg$Svg$Attributes$height('40'),
									$elm$svg$Svg$Attributes$fill('white'),
									$elm$svg$Svg$Attributes$transform('translate(0.00050354)')
								]),
							_List_Nil)
						]))
				]))
		]));
var $author$project$Pyxis$Components$NavigationSidebar$defaultExpandedLogo = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('122'),
			$elm$svg$Svg$Attributes$height('41'),
			$elm$svg$Svg$Attributes$viewBox('0 0 122 41'),
			$elm$svg$Svg$Attributes$fill('none'),
			$elm$svg$Svg$Attributes$xmlSpace('http://www.w3.org/2000/svg')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M66.6693 8.49788C65.7425 8.82139 65.3629 9.35685 65.3741 10.372C65.4411 15.5482 65.4076 20.7243 65.4076 25.8893C65.4076 26.2909 65.4187 26.6814 65.3964 27.083C65.3294 28.4328 64.5925 29.2583 63.4759 29.2694C62.2812 29.2806 61.4103 28.4105 61.3991 27.0495C61.3768 20.8136 61.3656 14.5776 61.4326 8.3417C61.4438 7.72815 61.8681 6.89148 62.3817 6.56797C66.6804 3.82372 71.3141 3.22132 76.1712 5.03967C77.0867 5.38549 77.846 6.12175 78.7951 6.75762C81.0282 4.95042 83.5963 4.02452 86.5775 4.03567C92.4617 4.05798 95.6886 6.72415 95.9454 12.3242C96.1799 17.2661 96.0347 22.2303 96.0124 27.1834C96.0012 28.4886 95.1303 29.2917 93.9802 29.2694C92.9307 29.2471 92.0598 28.4328 92.0374 27.228C91.9816 23.6024 92.0039 19.9769 91.9928 16.3514C91.9928 15.2916 92.0263 14.2318 91.9593 13.172C91.6801 8.75445 88.7771 6.75762 84.2662 7.91779C83.4288 8.12974 82.6472 8.57597 81.8991 9.01103C81.0282 9.52418 80.6486 10.2604 80.6597 11.3314C80.7267 16.5968 80.7044 21.851 80.6821 27.1164C80.6821 28.4328 79.867 29.2694 78.7504 29.2694C77.5892 29.2694 76.7964 28.4439 76.7294 27.1722C76.7071 26.8152 76.7183 26.4694 76.7183 26.1124C76.7183 22.0853 76.7183 18.0693 76.7183 14.0422C76.7183 13.5513 76.7294 13.0716 76.6848 12.5808C76.3498 9.28992 74.3512 7.54966 70.8564 7.67237C70.8675 7.67237 69.0029 7.68352 66.6693 8.49788Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M34.6128 9.70217C34.9477 6.44476 37.2702 4.42561 40.6868 4.12441C42.5627 3.95708 44.4943 4.16904 46.3701 4.4033C47.7993 4.58179 48.4916 5.53001 48.2794 6.55632C48.0673 7.58262 47.0624 8.11809 45.6667 7.98422C44.4161 7.87267 43.1656 7.67187 41.9262 7.72764C39.7266 7.82804 38.5877 8.89897 38.5096 10.9851C38.4091 14.0305 38.4426 17.076 38.4314 20.1326C38.4202 22.2967 38.4426 24.539 38.4202 26.7032C38.4091 28.343 37.7056 29.2466 36.4663 29.2801C35.1376 29.3247 34.3448 28.3877 34.3448 26.7255C34.3336 24.1597 34.3448 21.527 34.3448 18.9612C34.423 18.9612 34.3001 12.7588 34.6128 9.70217Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M55.9726 16.7868C55.9726 20.1446 55.9837 23.5024 55.9726 26.8602C55.9614 28.3997 55.2468 29.2698 54.0409 29.2811C52.7569 29.2921 51.9976 28.3774 51.9976 26.8044C51.9865 20.1781 51.9865 19.5757 51.9976 12.9605C51.9976 11.2983 52.6676 10.4393 53.9516 10.4282C55.3026 10.4058 55.9726 11.2648 55.9837 13.0162C55.9726 16.2736 55.9726 13.5182 55.9726 16.7868Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M53.9074 8.34175C52.7797 8.33059 51.7525 7.31544 51.7413 6.21104C51.7301 4.98394 52.6904 4.04688 53.9521 4.04688C55.2138 4.04688 56.2299 5.02856 56.1852 6.18873C56.1629 7.37122 55.1133 8.3529 53.9074 8.34175Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M29.3094 11.5209C28.6729 7.5384 25.8815 5.03957 21.6275 4.29215C19.5507 3.93517 17.552 3.99095 15.6204 4.35908C14.7495 4.5041 14.1242 5.19574 14.1019 6.08818C14.0795 7.05871 14.984 7.95115 15.9665 7.92884C16.1117 7.92884 16.2457 7.90653 16.3797 7.87307C17.7084 7.57187 19.0706 7.48262 20.4997 7.7392C23.6931 8.28582 25.3009 9.99261 25.6359 13.7409C25.848 16.1393 25.7476 18.6047 25.4573 21.0031C25.1111 23.9705 23.1125 25.5992 19.975 25.8669C17.5632 26.0677 15.2631 25.5992 13.0635 24.651C12.3824 24.3609 12.1032 23.9928 12.1256 23.2565C12.1814 21.0923 12.1479 18.9282 12.1479 16.764C12.1479 15.0126 12.1256 13.25 12.1591 11.4986V8.442C12.1591 7.40453 11.2547 6.5344 10.1493 6.51209C9.8143 6.50094 9.50167 6.56787 9.22253 6.67943C6.92242 7.68342 5.26992 8.5424 1.47363 10.5392C0.0444372 11.3201 -0.346357 12.458 0.312411 13.5512C0.926517 14.5887 2.04307 14.7672 3.39411 14.0421C4.94612 13.2054 6.53163 12.4022 8.13947 11.6325C8.17297 19.8429 8.15063 30.3068 8.1618 38.2384V38.2495C8.1618 38.2607 8.1618 38.2607 8.1618 38.2607C8.1618 38.283 8.1618 38.2941 8.1618 38.3165H8.17297C8.21763 39.3316 9.08854 40.146 10.1604 40.146C11.2323 40.146 12.1144 39.3316 12.1479 38.3165H12.1591C12.1702 36.0296 12.1591 32.0582 12.1591 28.3323C14.3922 28.745 16.4132 29.2805 18.4676 29.459C24.1621 29.9498 28.6953 26.8709 29.4769 21.5274C29.9458 18.2477 29.8342 14.8006 29.3094 11.5209Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M121.056 11.543C120.945 7.9621 118.801 5.44095 115.306 4.54851C111.342 3.53336 107.457 4.0242 103.694 5.48557C102.454 5.96526 101.997 6.96926 102.376 7.92863C102.734 8.83223 103.794 9.16689 105.123 8.77645C105.123 8.77645 108.618 7.62743 112.068 7.64974C115.005 7.67205 116.668 9.0888 117.07 11.6769C117.137 12.1343 117.148 12.6028 117.137 13.0936H117.148L117.182 18.1471V18.2029C117.182 18.2252 117.182 18.2586 117.182 18.2809V18.4037C117.182 20.222 117.238 21.8842 117.137 23.5352C117.115 23.9926 116.724 24.6507 116.311 24.8515C113.408 26.2683 110.337 26.4914 107.244 25.5432C105.268 24.9296 104.308 23.4571 104.308 21.4826C104.308 19.6865 105.38 18.3033 107.356 17.6451C109.288 17.0092 111.175 17.0427 113.062 17.3327C113.151 17.3439 113.252 17.355 113.352 17.355C114.245 17.355 114.96 16.6299 114.96 15.7486C114.96 14.9678 114.402 14.3207 113.653 14.1757C111.164 13.8076 108.674 13.7407 106.161 14.4434C102.454 15.4809 100.322 18.0355 100.311 21.4603C100.299 25.1974 102.354 28.0197 106.128 28.9456C110.985 30.1504 115.652 29.3695 119.973 26.9934C120.509 26.7034 121.045 25.9002 121.056 25.3312C121.146 20.7463 121.213 16.1391 121.056 11.543Z'),
					$elm$svg$Svg$Attributes$fill('#8334C2')
				]),
			_List_Nil)
		]));
var $author$project$Pyxis$Components$NavigationSidebar$pickLogosFromConfig = function (logoConfig) {
	if (!logoConfig.$) {
		return _Utils_Tuple2($author$project$Pyxis$Components$NavigationSidebar$defaultCollapsedLogo, $author$project$Pyxis$Components$NavigationSidebar$defaultExpandedLogo);
	} else {
		var collapsedLogo = logoConfig.a;
		var expandedLogo = logoConfig.b;
		return _Utils_Tuple2(collapsedLogo, expandedLogo);
	}
};
var $author$project$Pyxis$Components$NavigationSidebar$renderLogos = function (logoConfig) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('side-panel__logo-wrapper')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-panel__logo side-panel__logo--collapsed')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$NavigationSidebar$pickLogosFromConfig(logoConfig).a
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('side-panel__logo side-panel__logo--expanded')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$NavigationSidebar$pickLogosFromConfig(logoConfig).b
					]))
			]));
};
var $author$project$Pyxis$Components$NavigationSidebar$renderHeader = F2(
	function (tagger, _v0) {
		var logo = _v0.gg;
		var classList = _v0.fj;
		var id = _v0.iO;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('side-panel__header-wrapper'),
					$elm$html$Html$Attributes$classList(classList),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$button,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('side-panel__toggle'),
							$elm$html$Html$Events$onClick(
							tagger($author$project$Pyxis$Components$NavigationSidebar$ToggleExpand))
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-panel__toggle__line')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-panel__toggle__line')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('side-panel__toggle__line')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('sr-only')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text('Close Sidebar')
								]))
						])),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$NavigationSidebar$renderLogos, logo))
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$render = F3(
	function (tagger, model, _v0) {
		var headerConfig = _v0.f$;
		var contentConfig = _v0.fq;
		var footerConfig = _v0.fQ;
		var theme = _v0.eP;
		var id = _v0.iO;
		var classList = _v0.fj;
		var testId = _v0.eO;
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('side-panel', true),
							_Utils_Tuple2(
							'side-panel--opened',
							$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model)),
							_Utils_Tuple2(
							'side-panel--collapsed',
							!$author$project$Pyxis$Components$NavigationSidebar$isExpanded(model))
						])),
					$elm$html$Html$Attributes$classList(classList),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
					$author$project$Pyxis$Commons$Attributes$theme(theme))
				]),
			_List_fromArray(
				[
					A2($author$project$Pyxis$Components$NavigationSidebar$renderHeader, tagger, headerConfig),
					A3($author$project$Pyxis$Components$NavigationSidebar$renderContent, tagger, model, contentConfig),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$NavigationSidebar$renderFooter, footerConfig)),
					$author$project$Pyxis$Components$NavigationSidebar$overlay(tagger)
				]));
	});
var $author$project$Pyxis$Components$NavigationSidebar$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$NavigationSidebar$withFooter = F2(
	function (footerConfig, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fQ: $elm$core$Maybe$Just(footerConfig)
			});
	});
var $author$project$Pyxis$Components$NavigationSidebar$withHeader = F2(
	function (headerConfig, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{f$: headerConfig});
	});
var $author$project$Pyxis$Components$NavigationSidebar$withHeaderLogo = F2(
	function (logo, _v0) {
		var headerConfig = _v0;
		return _Utils_update(
			headerConfig,
			{
				gg: $elm$core$Maybe$Just(logo)
			});
	});
var $author$project$Main$navigationSidebar = function (model) {
	var headerConfiguration = A2($author$project$Pyxis$Components$NavigationSidebar$withHeaderLogo, $author$project$Pyxis$Components$NavigationSidebar$primaLogo, $author$project$Pyxis$Components$NavigationSidebar$header);
	var footerConfiguration = A3($author$project$Pyxis$Components$NavigationSidebar$footer, '', '', $author$project$Msg$Logout);
	return A3(
		$author$project$Pyxis$Components$NavigationSidebar$render,
		$author$project$Msg$NavigationSidebarMsg,
		model.lZ,
		A2(
			$author$project$Pyxis$Components$NavigationSidebar$withFooter,
			footerConfiguration,
			A2(
				$author$project$Pyxis$Components$NavigationSidebar$withHeader,
				headerConfiguration,
				A2(
					$author$project$Pyxis$Components$NavigationSidebar$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('main-header-navigation', true)
						]),
					$author$project$Pyxis$Components$NavigationSidebar$config))));
};
var $author$project$Main$addHeaderToBody = F2(
	function (model, _v0) {
		var title = _v0.hE;
		var body = _v0.fd;
		var container = _List_fromArray(
			[
				A2(
				$elm$html$Html$main_,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container--with-sidebar')
					]),
				body)
			]);
		return {
			fd: A2(
				$elm$core$List$append,
				_List_fromArray(
					[
						$author$project$Main$navigationSidebar(model)
					]),
				container),
			hE: title
		};
	});
var $elm$browser$Browser$Document = F2(
	function (title, body) {
		return {fd: body, hE: title};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Main$mapDocument = F2(
	function (msgMapper, _v0) {
		var title = _v0.hE;
		var body = _v0.fd;
		return A2(
			$elm$browser$Browser$Document,
			title,
			A2(
				$elm$core$List$map,
				$elm$html$Html$map(msgMapper),
				body));
	});
var $author$project$Translations$routeLogin = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Login';
	} else {
		return 'Ingresar';
	}
};
var $author$project$Pyxis$Components$Button$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Button$Full = 1;
var $author$project$Pyxis$Components$Button$full = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{di: 1});
};
var $author$project$Pyxis$Components$Button$Link = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$Pyxis$Components$Button$link = function (href) {
	return A2($author$project$Pyxis$Components$Button$Link, href, $elm$core$Maybe$Nothing);
};
var $author$project$Translations$loginButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Login';
	} else {
		return 'Iniciar sesión';
	}
};
var $author$project$Translations$loginErrorText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Something went wrong.';
	} else {
		return 'Algo salió mal';
	}
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$Page$Login$View$encodeReturnUrl = F2(
	function (env, returnRoute) {
		var _v0 = $author$project$Env$languageOverride(env);
		if (!_v0.$) {
			var languageOverride = _v0.a;
			return A2(
				$elm$url$Url$Builder$absolute,
				A2(
					$elm$core$List$cons,
					$author$project$Translations$languageToString(languageOverride),
					$author$project$Route$routeToPieces(returnRoute)),
				_List_Nil);
		} else {
			return A2(
				$elm$url$Url$Builder$absolute,
				$author$project$Route$routeToPieces(returnRoute),
				_List_Nil);
		}
	});
var $author$project$Env$loginPath = function (_v0) {
	var environment = _v0;
	return environment.cG;
};
var $author$project$Page$Login$View$loginLink = F2(
	function (env, returnRoute) {
		return _Utils_ap(
			$author$project$Env$externalApiUrl(env),
			_Utils_ap(
				$author$project$Env$loginPath(env),
				A2($author$project$Page$Login$View$encodeReturnUrl, env, returnRoute)));
	});
var $author$project$Common$ComponentUI$Illustrations$primaLogo = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$width('194'),
			$elm$svg$Svg$Attributes$height('80'),
			$elm$svg$Svg$Attributes$viewBox('0 0 194 80'),
			$elm$svg$Svg$Attributes$fill('none')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$clipPath('url(#clip0_38_4221)')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M105.516 7.15611C104.159 7.46915 103.26 8.7576 103.432 10.1396C103.54 18.3799 103.485 26.6126 103.485 34.8453C103.485 35.4847 103.504 36.1127 103.466 36.7464C103.357 38.8938 102.172 40.209 100.372 40.2262C99.4404 40.2434 98.5451 39.8692 97.9057 39.1935C97.2701 38.5235 96.9494 37.613 97.0276 36.693C96.9914 26.7653 96.9723 16.8357 97.083 6.90796C97.1593 5.7894 97.7167 4.75865 98.6119 4.08484C105.171 -0.316872 113.466 -1.22546 120.821 1.65302C122.323 2.41081 123.739 3.32704 125.049 4.38834C128.582 1.50604 133.02 -0.0286422 137.58 0.0515276C147.057 0.087795 152.255 4.33107 152.669 13.249C153.047 21.1171 152.813 29.0196 152.778 36.9068C152.843 37.7925 152.522 38.6629 151.898 39.2928C151.268 39.9284 150.4 40.2682 149.506 40.2262C148.655 40.2071 147.846 39.854 147.256 39.2412C146.67 38.6323 146.353 37.8134 146.378 36.9698C146.288 31.1956 146.323 25.4272 146.307 19.6531C146.307 17.9657 146.361 16.2802 146.252 14.5833C145.803 7.54932 141.119 4.37116 133.86 6.21888C132.516 6.62164 131.233 7.20764 130.048 7.95781C128.706 8.68124 127.922 10.1319 128.053 11.6513C128.162 20.0348 128.124 28.3992 128.088 36.7827C128.181 37.6569 127.895 38.5312 127.301 39.1802C126.704 39.8349 125.858 40.209 124.971 40.209C124.085 40.2185 123.236 39.8654 122.615 39.2317C122.001 38.6037 121.674 37.7486 121.716 36.8705C121.68 36.3017 121.697 35.7519 121.697 35.1831V15.9824C121.697 15.1998 121.716 14.4363 121.644 13.6575C121.105 8.41783 117.885 5.64624 112.258 5.84285C109.962 5.95738 107.692 6.39831 105.52 7.15611H105.516Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M57.2042 8.97137C57.7387 3.76987 61.4532 0.545899 66.9143 0.0648796C69.9493 -0.106913 72.9977 0.041974 76.0021 0.509632C78.287 0.794044 79.3921 2.30773 79.0543 3.94739C78.7164 5.58706 77.1073 6.4403 74.8683 6.22651C72.8869 5.95737 70.8865 5.81993 68.8861 5.81612C65.3701 5.97646 63.5472 7.68484 63.4269 11.0176C63.2666 15.8793 63.32 20.743 63.3009 25.6219C63.2818 29.0711 63.32 32.6559 63.2818 36.1127C63.2627 38.7297 62.1385 40.1746 60.159 40.2281C58.0345 40.2987 56.769 38.8041 56.769 36.1471C56.7499 32.0489 56.769 27.8476 56.769 23.7513C56.874 23.7513 56.6774 13.8484 57.2023 8.96947L57.2042 8.97137Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M90.3506 20.974C90.3506 26.1507 90.3697 31.3235 90.3506 36.5002C90.3315 38.8729 89.1232 40.2128 87.0732 40.23C84.899 40.2472 83.6029 38.8366 83.6029 36.4143C83.5839 26.2022 83.5839 25.2726 83.6029 15.0758C83.6029 12.5141 84.7387 11.1913 86.9128 11.1723C89.2015 11.1379 90.3372 12.4626 90.3563 15.1617C90.3506 20.1837 90.3506 15.9347 90.3506 20.974Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M86.8518 6.70181C85.0976 6.62355 83.6851 5.16713 83.5858 3.33277C83.6755 1.46977 85.1396 0.00571518 86.9224 -0.00382885C88.7052 -0.0133729 90.1827 1.43541 90.2915 3.29459C90.1941 5.20531 88.6823 6.70181 86.8518 6.6999V6.70181Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M47.0895 12.0286C46.0873 5.62077 41.6856 1.59319 34.98 0.388736C31.8457 -0.168635 28.6332 -0.132368 25.5123 0.497538C24.1399 0.715142 23.1282 1.89288 23.1206 3.28058C23.1416 4.89925 24.4396 6.20869 26.0582 6.24305C26.2777 6.23923 26.4972 6.20869 26.7091 6.15333C28.8355 5.63223 31.0478 5.55969 33.2029 5.93764C38.2364 6.8176 40.7713 9.56628 41.2981 15.6C41.5997 19.4978 41.5062 23.4147 41.0156 27.2934C40.4773 32.073 37.3221 34.6938 32.3745 35.1252C28.637 35.4077 24.8862 34.7358 21.4809 33.1687C20.5036 32.8862 19.8775 31.9337 20.0035 30.9239C20.0913 27.4461 20.0397 23.953 20.0397 20.4713C20.0397 17.652 20.0035 14.8136 20.0569 11.9924V7.05047C20.0111 5.32873 18.612 3.95439 16.8902 3.93912C16.3901 3.92003 15.8919 4.01165 15.43 4.20826C11.788 5.8422 9.18246 7.22417 3.19835 10.4329C0.944044 11.6889 0.327499 13.5194 1.3678 15.2793C2.33556 16.9495 4.09548 17.2359 6.22571 16.0696C8.6709 14.7277 11.1657 13.4335 13.7063 12.1909C13.7579 25.4113 13.7235 42.2603 13.7426 55.0321V55.1581H13.7598C13.8609 56.8875 15.346 58.2084 17.0773 58.1053C18.6654 58.0118 19.9309 56.7462 20.0245 55.1581H20.0417C20.0588 51.4779 20.0417 45.0892 20.0417 39.0821C23.5596 39.7464 26.7473 40.6092 29.9846 40.8955C38.9522 41.6858 46.1045 36.7286 47.3376 28.1237C48.0534 22.7771 47.9694 17.3523 47.0875 12.0286H47.0895Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M192.958 11.8689C192.957 6.48992 189.084 1.86488 183.71 0.818854C177.486 -0.654746 170.954 -0.137459 165.04 2.29818C164.214 2.48715 163.506 3.0178 163.095 3.76033C162.691 4.49522 162.626 5.37136 162.92 6.15779C163.494 7.58558 165.201 8.11432 167.337 7.49777C170.958 6.39257 174.716 5.79321 178.501 5.71685C183.224 5.75312 185.9 7.99024 186.554 12.0808C186.661 12.8214 186.698 13.5716 186.663 14.3179H186.682L186.736 22.3025V22.7091C186.736 25.5837 186.825 28.2083 186.665 30.8177C186.56 31.6766 186.072 32.4421 185.337 32.8983C180.796 35.1182 175.575 35.5095 170.753 33.9901C167.321 33.0376 165.309 29.4815 166.262 26.0495C166.886 23.8047 168.671 22.0677 170.937 21.5103C173.912 20.6208 177.058 20.4509 180.112 21.0159C180.267 21.0369 180.423 21.0484 180.578 21.0522C181.973 21.1095 183.153 20.0253 183.21 18.6299C183.264 17.3396 182.338 16.2172 181.063 16.0244C177.06 15.3201 172.956 15.4632 169.013 16.4463C163.051 18.084 159.621 22.1231 159.602 27.5326C159.293 33.2094 163.277 38.2468 168.957 39.3615C176.503 41.2054 184.466 40.1021 191.225 36.2769C192.17 35.708 192.812 34.7441 192.968 33.6503C193.119 26.4102 193.209 19.13 192.958 11.8689Z'),
							$elm$svg$Svg$Attributes$fill('#8334C2')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M54.3849 77.8373V61.9694C54.3849 61.3891 54.5624 60.9253 54.9194 60.5798C55.2744 60.2343 55.7516 60.0606 56.351 60.0606H62.5851C64.3641 60.0606 65.7404 60.5474 66.7139 61.5208C67.7064 62.5134 68.2027 63.7102 68.2027 65.1151C68.2027 67.4172 67.3228 68.8774 65.5629 69.4958V69.5531C66.5554 69.7974 67.3361 70.3204 67.9088 71.126C68.4795 71.9315 68.7658 72.9336 68.7658 74.1304C68.7658 75.9839 68.2409 77.383 67.193 78.3298C66.1431 79.2747 64.7115 79.748 62.8963 79.748H56.3529C55.7535 79.748 55.2763 79.5705 54.9213 79.2136C54.5643 78.8585 54.3868 78.3985 54.3868 77.8373H54.3849ZM56.6869 62.2786V68.4861H62.4439C64.7459 68.4861 65.8988 67.4572 65.8988 65.3976C65.8988 63.338 64.7478 62.2806 62.4439 62.2806H56.6869V62.2786ZM56.6869 70.6755V77.5281H62.5851C65.1315 77.5281 66.4046 76.3771 66.4046 74.0732C66.4046 73.0252 66.0859 72.1968 65.4502 71.5879C64.8127 70.979 63.9518 70.6755 62.8657 70.6755H56.6869Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M72.1902 75.2261C72.1902 73.8784 72.6904 72.7828 73.6925 71.941C74.6946 71.0992 76.0747 70.6774 77.8346 70.6774C78.9951 70.6774 80.3065 70.874 81.7667 71.2672V70.1162C81.7667 68.9919 81.4804 68.1749 80.9097 67.6596C80.3389 67.1442 79.4533 66.8865 78.2564 66.8865C77.3383 66.8865 76.2255 67.1308 74.9141 67.6176C74.7461 67.692 74.5495 67.7302 74.3243 67.7302C74.1372 67.7302 73.9502 67.6844 73.7631 67.5908C73.4443 67.3847 73.2859 67.1041 73.2859 66.7491C73.2859 66.2623 73.5283 65.9073 74.017 65.682C75.2711 65.0827 76.7599 64.783 78.4817 64.783C80.2034 64.783 81.6274 65.2182 82.5818 66.0886C83.5362 66.959 84.0134 68.2742 84.0134 70.0341V77.5052C84.0134 77.9175 83.855 78.2267 83.5362 78.4329C83.1048 78.7516 82.3279 79.1067 81.2055 79.4999C80.1767 79.8377 79.0429 80.0057 77.8079 80.0057C76.0289 80.0057 74.6488 79.5743 73.6658 78.7135C72.6827 77.8526 72.1922 76.692 72.1922 75.2318L72.1902 75.2261ZM74.4655 75.1688C74.4655 77.0222 75.6643 77.9499 78.0598 77.9499C79.8388 77.9499 81.0738 77.6044 81.7667 76.9115V73.2333C80.1939 72.821 78.8921 72.6148 77.8632 72.6148C76.8344 72.6148 76.0327 72.8439 75.4066 73.302C74.7786 73.7601 74.4655 74.3843 74.4655 75.1688Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M88.3387 74.327C88.2643 72.9794 88.2643 71.649 88.3387 70.3395C88.4132 68.7113 89.0412 67.3713 90.2208 66.3234C91.3814 65.2946 92.8512 64.7792 94.6302 64.7792C96.4092 64.7792 97.7568 65.2659 98.7875 66.2394C99.8355 67.1938 100.36 68.0833 100.36 68.9079C100.36 69.2458 100.257 69.5206 100.051 69.7363C99.845 69.952 99.5931 70.0589 99.2934 70.0589C98.7875 70.0589 98.423 69.8051 98.1977 69.3011C97.8789 68.5338 97.4075 67.944 96.7795 67.5317C96.1515 67.1194 95.4452 66.9132 94.6588 66.9132C93.4982 66.9132 92.5572 67.2358 91.8357 67.8829C91.1141 68.53 90.7076 69.3947 90.614 70.4808C90.5396 71.5287 90.5396 72.7656 90.614 74.1877C90.7076 75.2929 91.1141 76.1824 91.8357 76.8562C92.5572 77.53 93.4963 77.8679 94.6588 77.8679C95.4452 77.8679 96.161 77.6617 96.8081 77.2494C97.4533 76.8371 97.9362 76.2473 98.255 75.4799C98.4802 74.9741 98.8448 74.7221 99.3506 74.7221C99.6503 74.7221 99.9023 74.829 100.108 75.0447C100.315 75.2604 100.418 75.5353 100.418 75.8732C100.418 76.7149 99.8832 77.614 98.8162 78.5684C97.7663 79.5228 96.3729 80.0019 94.6321 80.0019C92.8912 80.0019 91.3642 79.4674 90.2227 78.4004C89.0431 77.2761 88.4151 75.919 88.3406 74.327H88.3387Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M104.516 78.6791V60.9577C104.516 60.639 104.623 60.3679 104.838 60.1427C105.054 59.9174 105.319 59.8048 105.638 59.8048C105.957 59.8048 106.228 59.9174 106.453 60.1427C106.679 60.3679 106.791 60.639 106.791 60.9577V71.0973H107.268C107.887 71.0973 108.41 70.8816 108.841 70.4521L114.092 65.285C114.318 65.0426 114.592 64.9204 114.921 64.9204C115.249 64.9204 115.514 65.033 115.721 65.2564C115.946 65.5179 116.058 65.7813 116.058 66.0428C116.058 66.3425 115.936 66.6135 115.694 66.8579L110.16 72.1662L115.778 77.8679C116.039 78.1675 116.171 78.4481 116.171 78.7096C116.171 78.9711 116.058 79.2441 115.833 79.5247C115.627 79.7499 115.36 79.8626 115.033 79.8626C114.707 79.8626 114.43 79.7404 114.205 79.498L108.841 73.993C108.393 73.5444 107.877 73.3192 107.297 73.3192H106.791V78.6829C106.791 79.0208 106.684 79.3014 106.469 79.5247C106.253 79.7499 105.978 79.8626 105.64 79.8626C105.34 79.8626 105.079 79.7461 104.854 79.5113C104.628 79.2766 104.516 79.0017 104.516 78.6829V78.6791Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M118.866 74.327C118.792 73.0176 118.792 71.6871 118.866 70.3395C118.941 68.7285 119.569 67.4 120.748 66.352C121.947 65.3041 123.407 64.7792 125.129 64.7792C126.851 64.7792 128.34 65.2946 129.483 66.3234C130.682 67.3713 131.317 68.7113 131.394 70.3395C131.506 71.6871 131.506 73.0176 131.394 74.327C131.319 75.9743 130.682 77.3315 129.483 78.4004C128.342 79.4674 126.889 80.0019 125.129 80.0019C123.369 80.0019 121.955 79.4674 120.777 78.4004C119.578 77.3334 118.943 75.9762 118.866 74.327ZM121.142 74.1877C121.235 75.2929 121.642 76.1824 122.363 76.8562C123.085 77.53 124.007 77.8679 125.129 77.8679C126.251 77.8679 127.175 77.53 127.895 76.8562C128.615 76.1824 129.023 75.2929 129.117 74.1877C129.191 72.9527 129.191 71.7253 129.117 70.5075C129.042 69.4214 128.649 68.551 127.937 67.8963C127.225 67.2415 126.29 66.9132 125.129 66.9132C123.969 66.9132 123.031 67.2415 122.321 67.8963C121.609 68.551 121.216 69.4214 121.142 70.5075C121.067 71.7253 121.067 72.9508 121.142 74.1877Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M135.578 65.4511H136.561V64.1588C136.561 62.7367 136.945 61.6182 137.712 60.8031C138.479 59.9881 139.565 59.5815 140.97 59.5815C141.944 59.5815 142.768 59.6846 143.442 59.8907C144.041 60.0587 144.341 60.4156 144.341 60.9577C144.341 61.2383 144.244 61.4769 144.047 61.6735C143.851 61.8701 143.583 61.9675 143.247 61.9675C143.154 61.9675 142.822 61.9255 142.251 61.8415C141.68 61.7575 141.272 61.7155 141.029 61.7155C139.569 61.7155 138.838 62.5859 138.838 64.3268V65.4511H142.77C143.219 65.4148 143.534 65.577 143.711 65.9435C143.889 66.3081 143.889 66.6746 143.711 67.0392C143.534 67.4038 143.219 67.5679 142.77 67.5297H138.838V78.6791C138.838 79.0169 138.729 79.2975 138.515 79.5209C138.3 79.7461 138.033 79.8587 137.716 79.8587C137.399 79.8587 137.126 79.7461 136.901 79.5209C136.675 79.2956 136.563 79.015 136.563 78.6791V67.5297H135.58C135.131 67.5679 134.816 67.4038 134.639 67.0392C134.461 66.6746 134.461 66.3081 134.639 65.9435C134.816 65.5789 135.131 65.4148 135.58 65.4511H135.578Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M146.586 65.4511H147.569V64.1588C147.569 62.7367 147.953 61.6182 148.72 60.8031C149.487 59.9881 150.573 59.5815 151.978 59.5815C152.952 59.5815 153.776 59.6846 154.45 59.8907C155.05 60.0587 155.349 60.4156 155.349 60.9577C155.349 61.2383 155.252 61.4769 155.055 61.6735C154.859 61.8701 154.592 61.9675 154.256 61.9675C154.162 61.9675 153.83 61.9255 153.259 61.8415C152.688 61.7575 152.28 61.7155 152.038 61.7155C150.577 61.7155 149.846 62.5859 149.846 64.3268V65.4511H153.778C154.227 65.4148 154.542 65.577 154.719 65.9435C154.897 66.3081 154.897 66.6746 154.719 67.0392C154.542 67.4038 154.227 67.5679 153.778 67.5297H149.846V78.6791C149.846 79.0169 149.737 79.2975 149.524 79.5209C149.308 79.7461 149.041 79.8587 148.724 79.8587C148.407 79.8587 148.134 79.7461 147.909 79.5209C147.684 79.2956 147.571 79.015 147.571 78.6791V67.5297H146.588C146.139 67.5679 145.824 67.4038 145.647 67.0392C145.469 66.6746 145.469 66.3081 145.647 65.9435C145.824 65.5789 146.139 65.4148 146.588 65.4511H146.586ZM159.28 62.7272C158.886 62.7272 158.558 62.5859 158.297 62.3053C158.035 62.0248 157.903 61.6869 157.903 61.2937C157.903 60.9005 158.033 60.5721 158.297 60.3106C158.558 60.03 158.886 59.8888 159.28 59.8888C159.673 59.8888 160.001 60.03 160.263 60.3106C160.543 60.5721 160.684 60.9005 160.684 61.2937C160.684 61.6869 160.543 62.0248 160.263 62.3053C160.001 62.5859 159.673 62.7272 159.28 62.7272ZM158.155 78.6791V66.1535C158.155 65.8347 158.262 65.5599 158.478 65.3251C158.694 65.0903 158.959 64.9739 159.278 64.9739C159.596 64.9739 159.867 65.0903 160.093 65.3251C160.318 65.5599 160.431 65.8347 160.431 66.1535V78.6791C160.431 79.0169 160.324 79.2975 160.108 79.5209C159.892 79.7442 159.617 79.8587 159.28 79.8587C158.98 79.8587 158.718 79.7461 158.493 79.5209C158.268 79.2956 158.155 79.015 158.155 78.6791Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M165.178 74.327C165.103 72.9794 165.103 71.649 165.178 70.3395C165.252 68.7113 165.88 67.3713 167.06 66.3234C168.22 65.2946 169.69 64.7792 171.469 64.7792C173.248 64.7792 174.596 65.2659 175.627 66.2394C176.674 67.1938 177.199 68.0833 177.199 68.9079C177.199 69.2458 177.096 69.5206 176.89 69.7363C176.684 69.952 176.432 70.0589 176.132 70.0589C175.627 70.0589 175.262 69.8051 175.037 69.3011C174.718 68.5338 174.246 67.944 173.618 67.5317C172.99 67.1194 172.284 66.9132 171.498 66.9132C170.337 66.9132 169.396 67.2358 168.675 67.8829C167.953 68.53 167.547 69.3947 167.453 70.4808C167.379 71.5287 167.379 72.7656 167.453 74.1877C167.547 75.2929 167.953 76.1824 168.675 76.8562C169.396 77.53 170.335 77.8679 171.498 77.8679C172.284 77.8679 173 77.6617 173.647 77.2494C174.292 76.8371 174.775 76.2473 175.094 75.4799C175.319 74.9741 175.684 74.7221 176.19 74.7221C176.489 74.7221 176.741 74.829 176.947 75.0447C177.154 75.2604 177.257 75.5353 177.257 75.8732C177.257 76.7149 176.722 77.614 175.655 78.5684C174.605 79.5228 173.212 80.0019 171.471 80.0019C169.73 80.0019 168.203 79.4674 167.062 78.4004C165.882 77.2761 165.254 75.919 165.18 74.327H165.178Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$d('M180.822 73.9605C180.784 73.1741 180.767 72.6224 180.767 72.3037C180.767 71.668 180.796 71.0954 180.851 70.5915C181 68.7934 181.637 67.3751 182.76 66.3367C183.882 65.2984 185.316 64.7773 187.056 64.7773C188.891 64.7773 190.351 65.348 191.437 66.4895C192.542 67.6309 193.094 69.0931 193.094 70.8702V71.7119C193.094 72.7981 192.542 73.3402 191.437 73.3402H183.04V73.7887C183.04 75.0619 183.42 76.0678 184.178 76.8085C184.936 77.5491 185.951 77.9175 187.224 77.9175C188.104 77.9175 189.246 77.6731 190.651 77.1864C190.893 77.0929 191.09 77.0452 191.241 77.0452C191.408 77.0452 191.596 77.1024 191.802 77.2131C192.082 77.4193 192.241 77.7094 192.279 78.0835C192.279 78.5149 192.073 78.8509 191.66 79.0952C190.649 79.6946 189.16 79.9943 187.196 79.9943C185.232 79.9943 183.792 79.4522 182.66 78.3641C181.527 77.278 180.914 75.8082 180.82 73.9548L180.822 73.9605ZM183.04 71.208V71.4333H190.511C190.737 71.4333 190.857 71.3206 190.876 71.0954V70.7308C190.876 69.6256 190.567 68.7189 189.948 68.007C189.236 67.2205 188.282 66.8273 187.083 66.8273C185.755 66.8273 184.733 67.2396 184.021 68.0623C183.367 68.8678 183.038 69.9158 183.038 71.208H183.04Z'),
							$elm$svg$Svg$Attributes$fill('#90939D')
						]),
					_List_Nil)
				])),
			A2(
			$elm$svg$Svg$defs,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$clipPath,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$id('clip0_38_4221')
						]),
					_List_fromArray(
						[
							A2(
							$elm$svg$Svg$rect,
							_List_fromArray(
								[
									$elm$svg$Svg$Attributes$width('192.188'),
									$elm$svg$Svg$Attributes$height('80'),
									$elm$svg$Svg$Attributes$fill('white'),
									$elm$svg$Svg$Attributes$transform('translate(0.905884)')
								]),
							_List_Nil)
						]))
				]))
		]));
var $author$project$Pyxis$Components$Button$Primary = 0;
var $author$project$Pyxis$Components$Button$Button = {$: 0};
var $author$project$Pyxis$Components$Button$Inherit = 2;
var $author$project$Pyxis$Components$Button$L = 1;
var $author$project$Pyxis$Components$Button$None = {$: 3};
var $author$project$Pyxis$Components$Button$config = function (variant) {
	return {e2: $elm$core$Maybe$Nothing, e3: $elm$core$Maybe$Nothing, e4: $elm$core$Maybe$Nothing, e5: $elm$core$Maybe$Nothing, e6: $elm$core$Maybe$Nothing, e7: $elm$core$Maybe$Nothing, e8: $elm$core$Maybe$Nothing, fj: _List_Nil, fC: false, fR: $elm$core$Maybe$Nothing, iN: $author$project$Pyxis$Components$Button$None, iO: $elm$core$Maybe$Nothing, bz: false, gf: 'Loading...', gG: $elm$core$Maybe$Nothing, gR: $elm$core$Maybe$Nothing, hj: false, ax: 1, hs: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, nB: '', eP: $author$project$Pyxis$Commons$Properties$Theme$default, eT: $author$project$Pyxis$Components$Button$Button, hK: variant, di: 2};
};
var $author$project$Pyxis$Components$Button$primary = $author$project$Pyxis$Components$Button$config(0);
var $author$project$Pyxis$Commons$Attributes$ariaControls = $elm$html$Html$Attributes$attribute('aria-controls');
var $author$project$Pyxis$Commons$Attributes$ariaDescribedBy = $elm$html$Html$Attributes$attribute('aria-describedby');
var $author$project$Pyxis$Commons$Attributes$ariaDisabled = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-disabled'));
var $primait$prima_elm_extra$PrimaFunction$ifThenElse = F3(
	function (condition, a, b) {
		return condition ? a : b;
	});
var $author$project$Pyxis$Commons$Attributes$ariaExpanded = function (isExpanded) {
	return A2(
		$elm$html$Html$Attributes$attribute,
		'aria-expanded',
		A3($primait$prima_elm_extra$PrimaFunction$ifThenElse, isExpanded, 'true', 'false'));
};
var $author$project$Pyxis$Commons$Attributes$ariaHasPopup = $elm$html$Html$Attributes$attribute('aria-haspopup');
var $author$project$Pyxis$Components$AriaLive$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$AriaLive$config = function (text) {
	return text;
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$disabled = $elm$html$Html$Attributes$boolProperty('disabled');
var $elm$html$Html$Attributes$form = _VirtualDom_attribute('form');
var $author$project$Pyxis$Commons$Attributes$getAriaDisabledState = F2(
	function (maybeDisabled, loading) {
		var _v0 = _Utils_Tuple2(maybeDisabled, loading);
		if (!_v0.b) {
			if (!_v0.a.$) {
				var disabled = _v0.a.a;
				return $elm$core$Maybe$Just(disabled);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Just(true);
		}
	});
var $author$project$Pyxis$Components$Button$getButtonIconClass = function (icon) {
	switch (icon.$) {
		case 0:
			return 'button--append-icon';
		case 1:
			return 'button--prepend-icon';
		case 2:
			return 'button--icon-only';
		default:
			return '';
	}
};
var $author$project$Pyxis$Components$Button$getButtonSizeClass = function (size) {
	switch (size) {
		case 0:
			return 'button--xl';
		case 1:
			return 'button--l';
		case 2:
			return 'button--m';
		default:
			return 'button--s';
	}
};
var $author$project$Pyxis$Components$Button$getButtonVariantClass = function (variant) {
	switch (variant) {
		case 0:
			return 'button--primary';
		case 1:
			return 'button--secondary';
		case 2:
			return 'button--tertiary';
		case 3:
			return 'button--brand';
		case 5:
			return 'button--ghost';
		default:
			return 'button--danger';
	}
};
var $author$project$Pyxis$Components$Button$getButtonWidthClass = function (width) {
	switch (width) {
		case 2:
			return '';
		case 0:
			return 'button--min-width';
		default:
			return 'button--full-width';
	}
};
var $author$project$Pyxis$Components$Button$isAppend = function (buttonIcon) {
	if (!buttonIcon.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Button$isGhost = function (buttonVariant) {
	if (buttonVariant === 5) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Button$isPrepend = function (buttonIcon) {
	if (buttonIcon.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Button$isStandalone = function (buttonIcon) {
	if (buttonIcon.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$Negative = 0;
var $author$project$Pyxis$Commons$Attributes$TabIndex$negative = 0;
var $author$project$Pyxis$Commons$Attributes$ariaLivePolite = A2($elm$html$Html$Attributes$attribute, 'aria-live', 'polite');
var $author$project$Pyxis$Components$AriaLive$render = function (_v0) {
	var text = _v0;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('sr-only'),
				$author$project$Pyxis$Commons$Attributes$role('status'),
				$author$project$Pyxis$Commons$Attributes$ariaLivePolite
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(text)
			]));
};
var $author$project$Pyxis$Components$Icon$L = 2;
var $author$project$Pyxis$Components$Icon$sizeL = 2;
var $author$project$Pyxis$Components$Icon$sizeM = 1;
var $author$project$Pyxis$Components$Icon$S = 0;
var $author$project$Pyxis$Components$Icon$sizeS = 0;
var $author$project$Pyxis$Components$Icon$withSize = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: a});
	});
var $author$project$Pyxis$Components$Button$applyIconSize = F3(
	function (size, ghostButton, iconOnlyButton) {
		var _v0 = _Utils_Tuple3(size, ghostButton, iconOnlyButton);
		_v0$8:
		while (true) {
			_v0$9:
			while (true) {
				_v0$10:
				while (true) {
					_v0$11:
					while (true) {
						switch (_v0.a) {
							case 0:
								if (!_v0.b) {
									if (_v0.c) {
										var _v1 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeL);
									} else {
										break _v0$8;
									}
								} else {
									if (_v0.c) {
										var _v5 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeL);
									} else {
										break _v0$8;
									}
								}
							case 1:
								if (!_v0.b) {
									if (_v0.c) {
										var _v2 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeL);
									} else {
										break _v0$9;
									}
								} else {
									if (_v0.c) {
										var _v6 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeL);
									} else {
										break _v0$9;
									}
								}
							case 2:
								if (!_v0.b) {
									if (_v0.c) {
										var _v3 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
									} else {
										break _v0$10;
									}
								} else {
									if (_v0.c) {
										var _v7 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeL);
									} else {
										break _v0$10;
									}
								}
							default:
								if (!_v0.b) {
									if (_v0.c) {
										var _v4 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
									} else {
										break _v0$11;
									}
								} else {
									if (_v0.c) {
										var _v8 = _v0.a;
										return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM);
									} else {
										break _v0$11;
									}
								}
						}
					}
					var _v12 = _v0.a;
					return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
				}
				var _v11 = _v0.a;
				return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS);
			}
			var _v10 = _v0.a;
			return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM);
		}
		var _v9 = _v0.a;
		return $author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM);
	});
var $author$project$Pyxis$Components$Button$pickIcon = function (buttonIcon) {
	switch (buttonIcon.$) {
		case 0:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		case 1:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		case 2:
			var icon = buttonIcon.a;
			return $elm$core$Maybe$Just(icon);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Pyxis$Components$Button$renderIcon = F4(
	function (size, ghostButton, iconOnlyButton, icon) {
		return $author$project$Pyxis$Commons$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Icon$config,
					A2(
						$elm$core$Basics$composeR,
						A3($author$project$Pyxis$Components$Button$applyIconSize, size, ghostButton, iconOnlyButton),
						$author$project$Pyxis$Components$Icon$render)),
				$author$project$Pyxis$Components$Button$pickIcon(icon)));
	});
var $author$project$Pyxis$Commons$Render$renderIf = function (condition) {
	return A2($author$project$Pyxis$Commons$Render$renderIfElse, condition, $author$project$Pyxis$Commons$Render$empty);
};
var $author$project$Pyxis$Components$Button$renderTag = function (_v0) {
	var configData = _v0;
	var _v1 = configData.eT;
	if (_v1.$ === 3) {
		return $elm$html$Html$a;
	} else {
		return $elm$html$Html$button;
	}
};
var $author$project$Pyxis$Commons$Render$renderUnless = function (condition) {
	return $author$project$Pyxis$Commons$Render$renderIf(!condition);
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$toInt = function (index) {
	if (!index) {
		return -1;
	} else {
		return 0;
	}
};
var $author$project$Pyxis$Commons$Attributes$TabIndex$tabIndex = A2($elm$core$Basics$composeR, $author$project$Pyxis$Commons$Attributes$TabIndex$toInt, $elm$html$Html$Attributes$tabindex);
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Pyxis$Components$Button$typeToAttribute = function (type_) {
	switch (type_.$) {
		case 1:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('submit')
				]);
		case 2:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('reset')
				]);
		case 0:
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$type_('button')
				]);
		default:
			var href = type_.a;
			var target = type_.b;
			return _List_fromArray(
				[
					$elm$html$Html$Attributes$href(href),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$target, target)
				]);
	}
};
var $author$project$Pyxis$Components$Button$render = function (configuration) {
	var configData = configuration;
	return A3(
		$author$project$Pyxis$Components$Button$renderTag,
		configuration,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('button', true),
								_Utils_Tuple2('button--loading', configData.bz),
								_Utils_Tuple2('button--shadow', configData.hj),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonIconClass(configData.iN),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonVariantClass(configData.hK),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonWidthClass(configData.di),
								true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Button$getButtonSizeClass(configData.ax),
								true)
							]),
						configData.fj)),
					$elm$html$Html$Attributes$disabled(configData.fC),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Events$onClick, configData.gG),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					A2(
						$elm$core$Basics$composeR,
						$elm$json$Json$Decode$succeed,
						$elm$html$Html$Events$on('mousedown')),
					configData.gR),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configData.iO),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabelledBy, configData.e8),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaDescribedBy, configData.e3),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaLabel, configData.e7),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					$author$project$Pyxis$Commons$Attributes$ariaDisabled,
					A2($author$project$Pyxis$Commons$Attributes$getAriaDisabledState, configData.e4, configData.bz)),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaControls, configData.e2),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaExpanded, configData.e5),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$ariaHasPopup, configData.e6),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					$author$project$Pyxis$Commons$Attributes$TabIndex$tabIndex,
					A3(
						$primait$prima_elm_extra$PrimaFunction$ifThenElse,
						configData.bz,
						$elm$core$Maybe$Just($author$project$Pyxis$Commons$Attributes$TabIndex$negative),
						configData.hs)),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$author$project$Pyxis$Commons$Properties$Theme$isAlternative(configData.eP),
					$author$project$Pyxis$Commons$Attributes$theme(configData.eP)),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$form, configData.fR)
				]),
			$author$project$Pyxis$Components$Button$typeToAttribute(configData.eT)),
		_List_fromArray(
			[
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				configData.bz,
				$author$project$Pyxis$Components$AriaLive$render(
					$author$project$Pyxis$Components$AriaLive$config(configData.gf))),
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				$author$project$Pyxis$Components$Button$isPrepend(configData.iN) || $author$project$Pyxis$Components$Button$isStandalone(configData.iN),
				A4(
					$author$project$Pyxis$Components$Button$renderIcon,
					configData.ax,
					$author$project$Pyxis$Components$Button$isGhost(configData.hK),
					$author$project$Pyxis$Components$Button$isStandalone(configData.iN),
					configData.iN)),
				A2(
				$author$project$Pyxis$Commons$Render$renderUnless,
				$author$project$Pyxis$Components$Button$isStandalone(configData.iN),
				$elm$html$Html$text(configData.nB)),
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				$author$project$Pyxis$Components$Button$isAppend(configData.iN),
				A4(
					$author$project$Pyxis$Components$Button$renderIcon,
					configData.ax,
					$author$project$Pyxis$Components$Button$isGhost(configData.hK),
					$author$project$Pyxis$Components$Button$isStandalone(configData.iN),
					configData.iN))
			]));
};
var $elm$html$Html$section = _VirtualDom_node('section');
var $author$project$Pyxis$Components$Button$XL = 0;
var $author$project$Pyxis$Components$Button$sizeXL = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{ax: 0});
};
var $author$project$Pyxis$Components$Button$withSize = function (setSize) {
	return setSize;
};
var $author$project$Pyxis$Components$Button$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{nB: text});
	});
var $author$project$Pyxis$Components$Button$withType = F2(
	function (type_, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{eT: type_});
	});
var $author$project$Pyxis$Components$Button$withWidth = function (setWidth) {
	return setWidth;
};
var $author$project$Page$Login$View$view = F3(
	function (i18n, env, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$main_,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-responsive display-flex justify-content-center align-items-center login-container')
					]),
				function () {
					var _v0 = model.ku;
					switch (_v0.$) {
						case 0:
							return _List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$loginErrorText(i18n))
								]);
						case 1:
							return _List_fromArray(
								[
									$elm$html$Html$text('')
								]);
						case 3:
							return _List_fromArray(
								[
									$elm$html$Html$text('')
								]);
						default:
							return _List_fromArray(
								[
									A2(
									$elm$html$Html$section,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-container__logo')
										]),
									_List_fromArray(
										[$author$project$Common$ComponentUI$Illustrations$primaLogo])),
									A2(
									$elm$html$Html$section,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('login-container__actions')
										]),
									_List_fromArray(
										[
											$author$project$Pyxis$Components$Button$render(
											A2(
												$author$project$Pyxis$Components$Button$withType,
												$author$project$Pyxis$Components$Button$link(
													A2($author$project$Page$Login$View$loginLink, env, model.ny)),
												A2(
													$author$project$Pyxis$Components$Button$withWidth,
													$author$project$Pyxis$Components$Button$full,
													A2(
														$author$project$Pyxis$Components$Button$withSize,
														$author$project$Pyxis$Components$Button$sizeXL,
														A2(
															$author$project$Pyxis$Components$Button$withText,
															$author$project$Translations$loginButtonText(i18n),
															$author$project$Pyxis$Components$Button$primary)))))
										]))
								]);
					}
				}())
			]);
	});
var $author$project$Page$Login$view = F3(
	function (i18n, env, model) {
		return {
			fd: A3($author$project$Page$Login$View$view, i18n, env, model),
			hE: $author$project$Translations$routeLogin(i18n)
		};
	});
var $author$project$Translations$routeNotAuthorized = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Not authorized';
	} else {
		return 'No Autorizado';
	}
};
var $author$project$Page$NotAuthorized$View$view = function (_v0) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('container-responsive'),
					$elm$html$Html$Attributes$class('justify-content-center')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('You do not have the required permissions to perform this action. Please contact your supervisor if you need to acquire permissions.')
				]))
		]);
};
var $author$project$Page$NotAuthorized$view = F2(
	function (i18n, model) {
		return {
			fd: $author$project$Page$NotAuthorized$View$view(model),
			hE: $author$project$Translations$routeNotAuthorized(i18n)
		};
	});
var $author$project$Translations$routeNotFound = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Not found';
	} else {
		return 'Sin Resultados';
	}
};
var $elm$html$Html$h1 = _VirtualDom_node('h1');
var $author$project$Pyxis$Components$IconSet$Home = 83;
var $author$project$Pyxis$Components$IconSet$home = 83;
var $author$project$Pyxis$Components$Button$Prepend = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Button$withIconPrepend = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iN: $author$project$Pyxis$Components$Button$Prepend(icon)
			});
	});
var $author$project$Page$NotFound$View$view = function (_v0) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$h1,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text('Not found')
				])),
			$author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withType,
				$author$project$Pyxis$Components$Button$link(
					$author$project$Route$toString(
						$author$project$Route$Search(
							$elm$core$Result$Ok($author$project$Page$Search$SearchQueryParam$Empty)))),
				A2(
					$author$project$Pyxis$Components$Button$withText,
					'Go Home',
					A2($author$project$Pyxis$Components$Button$withIconPrepend, $author$project$Pyxis$Components$IconSet$home, $author$project$Pyxis$Components$Button$primary))))
		]);
};
var $author$project$Page$NotFound$view = F2(
	function (i18n, model) {
		return {
			fd: $author$project$Page$NotFound$View$view(model),
			hE: $author$project$Translations$routeNotFound(i18n)
		};
	});
var $author$project$Translations$routeOfferDetails = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Offer details';
	} else {
		return 'Detalles de Oferta';
	}
};
var $author$project$Page$OfferDetails$Msg$RedirectToPayment = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$OfferDetails$Msg$ShowCompleteOfferLegalInformationModal = function (a) {
	return {$: 9, a: a};
};
var $author$project$Page$OfferDetails$Msg$ShowRecordingDisclaimerModal = function (a) {
	return {$: 10, a: a};
};
var $author$project$Common$ErrorContainer$CarCrashing = 0;
var $author$project$Pyxis$Components$Form$Legend$Center = 0;
var $author$project$Pyxis$Components$Form$Legend$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$Legend$config = {ko: $elm$core$Maybe$Nothing, dn: 0, fj: _List_Nil, kR: $elm$core$Maybe$Nothing, dP: $elm$core$Maybe$Nothing, iO: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, hE: $elm$core$Maybe$Nothing};
var $author$project$Common$ErrorContainer$iconToImagePath = function (errorIcon) {
	return '/assets/' + function () {
		if (!errorIcon) {
			return 'car-crashing.svg';
		} else {
			return 'camera.svg';
		}
	}();
};
var $author$project$Pyxis$Components$Form$Legend$Left = 1;
var $author$project$Pyxis$Components$Form$Legend$isAlignedLeft = $elm$core$Basics$eq(1);
var $elm$html$Html$legend = _VirtualDom_node('legend');
var $author$project$Pyxis$Components$Icon$Boxed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Icon$Brand = 1;
var $author$project$Pyxis$Components$Icon$brand = $author$project$Pyxis$Components$Icon$Boxed(1);
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $author$project$Pyxis$Components$Icon$withStyle = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{hr: a});
	});
var $author$project$Pyxis$Components$Form$Legend$renderAddonByType = function (type_) {
	if (!type_.$) {
		var icon = type_.a;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-legend__addon')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withStyle,
						$author$project$Pyxis$Components$Icon$brand,
						$author$project$Pyxis$Components$Icon$config(icon)))
				]));
	} else {
		var url = type_.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-legend__addon')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src(url),
							$elm$html$Html$Attributes$height(80)
						]),
					_List_Nil)
				]));
	}
};
var $author$project$Pyxis$Components$Form$Legend$renderDescription = function (str) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-legend__text')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(str)
			]));
};
var $elm$html$Html$h3 = _VirtualDom_node('h3');
var $elm$html$Html$h4 = _VirtualDom_node('h4');
var $elm$html$Html$h5 = _VirtualDom_node('h5');
var $elm$html$Html$h6 = _VirtualDom_node('h6');
var $author$project$Pyxis$Components$Form$Legend$renderTag = function (maybeHeadingLevel) {
	if (!maybeHeadingLevel.$) {
		switch (maybeHeadingLevel.a) {
			case 0:
				var _v1 = maybeHeadingLevel.a;
				return $elm$html$Html$h1;
			case 1:
				var _v2 = maybeHeadingLevel.a;
				return $elm$html$Html$h2;
			case 2:
				var _v3 = maybeHeadingLevel.a;
				return $elm$html$Html$h3;
			case 3:
				var _v4 = maybeHeadingLevel.a;
				return $elm$html$Html$h4;
			case 4:
				var _v5 = maybeHeadingLevel.a;
				return $elm$html$Html$h5;
			default:
				var _v6 = maybeHeadingLevel.a;
				return $elm$html$Html$h6;
		}
	} else {
		return $elm$html$Html$span;
	}
};
var $author$project$Pyxis$Components$Form$Legend$renderTitle = F2(
	function (maybeHeadingLevel, str) {
		return A3(
			$author$project$Pyxis$Components$Form$Legend$renderTag,
			maybeHeadingLevel,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-legend__title')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	});
var $author$project$Pyxis$Components$Form$Legend$render = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$legend,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('form-legend', true),
							_Utils_Tuple2(
							'form-legend--align-left',
							$author$project$Pyxis$Components$Form$Legend$isAlignedLeft(configuration.dn))
						]),
					configuration.fj)),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configuration.iO),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configuration.eO)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Legend$renderAddonByType, configuration.ko)),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					$author$project$Pyxis$Components$Form$Legend$renderTitle(configuration.dP),
					configuration.hE)),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Legend$renderDescription, configuration.kR))
			]));
};
var $author$project$Pyxis$Components$Form$Legend$withTitle = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				hE: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Common$ErrorContainer$custom = F2(
	function (errorIcon, title) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('error-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error-container__icon')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('space-v-xs'),
									$elm$html$Html$Attributes$src(
									$author$project$Common$ErrorContainer$iconToImagePath(errorIcon))
								]),
							_List_Nil)
						])),
					$author$project$Pyxis$Components$Form$Legend$render(
					A2($author$project$Pyxis$Components$Form$Legend$withTitle, title, $author$project$Pyxis$Components$Form$Legend$config))
				]));
	});
var $author$project$Common$ErrorContainer$generic = A2($author$project$Common$ErrorContainer$custom, 0, 'Oops! This is embarrassing... something has gone wrong.');
var $author$project$Common$ErrorContainer$Camera = 1;
var $author$project$Common$ErrorContainer$notFound = A2($author$project$Common$ErrorContainer$custom, 1, '404 - we can\'t find this page');
var $author$project$Translations$completeOfferLegalInformationContent = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The customer has been previously informed about the Privacy Policy. If you have voluntarily mentioned that you do not agree to receive commercial emails, please make sure you tick the relevant boxes.';
	} else {
		return 'El cliente ha sido informado previamente sobre la Política de privacidad. Si ha mencionado voluntariamente no estar de acuerdo con recibir emails comerciales, asegúrate de seleccionar las casillas correspondientes.';
	}
};
var $author$project$Pyxis$Components$Modal$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$M = 1;
var $author$project$Pyxis$Components$Modal$config = {dl: $elm$core$Maybe$Nothing, fj: _List_Nil, bf: _List_Nil, fO: $elm$core$Maybe$Nothing, f_: $elm$core$Maybe$Nothing, dU: false, ee: $elm$core$Maybe$Nothing, ax: 1, eO: $elm$core$Maybe$Nothing};
var $author$project$Translations$completeOfferLegalInformationButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Continue';
	} else {
		return 'Continuar';
	}
};
var $author$project$Pyxis$Components$Modal$Footer$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$Footer$config = {dr: _List_Nil, fj: _List_Nil, dC: $elm$core$Maybe$Nothing, dM: false, d2: false, nB: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default};
var $author$project$Pyxis$Components$Modal$Footer$withButtons = F2(
	function (buttons, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{dr: buttons});
	});
var $author$project$Pyxis$Components$Button$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Button$withOnClick = F2(
	function (onClick, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gG: $elm$core$Maybe$Just(onClick)
			});
	});
var $author$project$Pyxis$Components$Button$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$footer = F3(
	function (i18n, externalUrl, ctaMsg) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$a,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$target('_blank'),
							$elm$html$Html$Attributes$href(
							$author$project$ExternalUrl$toString(externalUrl))
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Button$render(
							A2(
								$author$project$Pyxis$Components$Button$withTestId,
								'legal-info-btn',
								A2(
									$author$project$Pyxis$Components$Button$withId,
									'legal-info-btn',
									A2(
										$author$project$Pyxis$Components$Button$withText,
										$author$project$Translations$completeOfferLegalInformationButtonText(i18n),
										A2($author$project$Pyxis$Components$Button$withOnClick, ctaMsg, $author$project$Pyxis$Components$Button$primary)))))
						]))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Translations$completeOfferLegalInformationTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Preference over commercial emails';
	} else {
		return 'Preferencia sobre emails comerciales';
	}
};
var $author$project$Pyxis$Components$Modal$Header$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Modal$Header$config = {dp: $elm$core$Maybe$Nothing, fj: _List_Nil, dy: $elm$core$Maybe$Nothing, dC: $elm$core$Maybe$Nothing, iN: $elm$core$Maybe$Nothing, d2: false, hE: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Modal$Header$withTitle = F2(
	function (title, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				hE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$header = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withTitle,
		$author$project$Translations$completeOfferLegalInformationTitle(i18n),
		$author$project$Pyxis$Components$Modal$Header$config);
};
var $author$project$Pyxis$Components$Modal$contentId = function (id) {
	return id + '-content';
};
var $author$project$Pyxis$Components$Modal$getModalSizeClass = function (size) {
	switch (size) {
		case 0:
			return 'modal--s';
		case 1:
			return 'modal--m';
		default:
			return 'modal--l';
	}
};
var $elm$html$Html$footer = _VirtualDom_node('footer');
var $author$project$Pyxis$Components$Modal$Footer$renderButtons = function (fullWidthButton) {
	return $elm$html$Html$div(
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal__footer__buttons', true),
						_Utils_Tuple2('modal__footer__buttons--full-width', fullWidthButton)
					]))
			]));
};
var $author$project$Pyxis$Components$Modal$Footer$renderText = function (text) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal__footer__text')
			]),
		_List_fromArray(
			[text]));
};
var $author$project$Pyxis$Components$Modal$Footer$render = function (_v0) {
	var classList = _v0.fj;
	var customContent = _v0.dC;
	var isSticky = _v0.d2;
	var fullWidthButton = _v0.dM;
	var text = _v0.nB;
	var buttons = _v0.dr;
	var theme = _v0.eP;
	return A2(
		$elm$html$Html$footer,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('modal__footer', true),
						_Utils_Tuple2('modal__footer--sticky', isSticky),
						_Utils_Tuple2(
						'modal__footer--alt',
						$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme)),
						_Utils_Tuple2(
						'modal__footer--custom',
						$elm_community$maybe_extra$Maybe$Extra$isJust(customContent))
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
				$author$project$Pyxis$Commons$Attributes$theme(theme))
			]),
		A2(
			$elm$core$Maybe$withDefault,
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Footer$renderText, text)),
					A2($author$project$Pyxis$Components$Modal$Footer$renderButtons, fullWidthButton, buttons)
				]),
			customContent));
};
var $author$project$Pyxis$Components$Badge$getBadgeVariantClass = function (variant) {
	switch (variant) {
		case 2:
			return 'badge--action';
		case 5:
			return 'badge--alert';
		case 1:
			return 'badge--brand';
		case 7:
			return 'badge--brand-gradient';
		case 3:
			return 'badge--error';
		case 8:
			return 'badge--ghost';
		case 0:
			return '';
		case 6:
			return 'badge--neutral-gradient';
		default:
			return 'badge--success';
	}
};
var $author$project$Pyxis$Components$Badge$render = function (_v0) {
	var classList = _v0.fj;
	var id = _v0.iO;
	var ribbon = _v0.ew;
	var testId = _v0.eO;
	var text = _v0.nB;
	var theme = _v0.eP;
	var variant = _v0.hK;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('badge', true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Badge$getBadgeVariantClass(variant),
						true),
						_Utils_Tuple2('badge--ribbon', ribbon)
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
				$author$project$Pyxis$Commons$Attributes$theme(theme))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(text)
			]));
};
var $author$project$Pyxis$Components$Modal$Header$renderBadge = function (badge) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('modal__header__badge')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Badge$render(badge)
			]));
};
var $author$project$Pyxis$Components$Button$button = $author$project$Pyxis$Components$Button$Button;
var $author$project$Pyxis$Components$IconSet$Cross = 40;
var $author$project$Pyxis$Components$IconSet$cross = 40;
var $author$project$Pyxis$Components$Button$S = 3;
var $author$project$Pyxis$Components$Button$sizeS = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{ax: 3});
};
var $author$project$Pyxis$Components$Button$Tertiary = 2;
var $author$project$Pyxis$Components$Button$tertiary = $author$project$Pyxis$Components$Button$config(2);
var $author$project$Pyxis$Components$Button$withAriaLabel = F2(
	function (ariaLabel, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				e7: $elm$core$Maybe$Just(ariaLabel)
			});
	});
var $author$project$Pyxis$Components$Button$Standalone = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Button$withIconOnly = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iN: $author$project$Pyxis$Components$Button$Standalone(icon)
			});
	});
var $author$project$Pyxis$Components$Modal$Header$renderCloseButton = function (_v0) {
	var msg = _v0.i4;
	var ariaLabel = _v0.e7;
	return $author$project$Pyxis$Components$Button$render(
		A2(
			$author$project$Pyxis$Components$Button$withIconOnly,
			$author$project$Pyxis$Components$IconSet$cross,
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				msg,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withAriaLabel,
						ariaLabel,
						A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeS, $author$project$Pyxis$Components$Button$tertiary))))));
};
var $author$project$Pyxis$Components$Modal$Header$renderIcon = $author$project$Pyxis$Components$Icon$render;
var $author$project$Pyxis$Components$Modal$Header$renderTitle = F2(
	function (id, title) {
		return A2(
			$elm$html$Html$h2,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('modal__header__title'),
					$elm$html$Html$Attributes$id(id)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title)
				]));
	});
var $author$project$Pyxis$Components$Modal$Header$render = F2(
	function (titleId, _v0) {
		var classList = _v0.fj;
		var closeButton = _v0.dy;
		var customContent = _v0.dC;
		var isSticky = _v0.d2;
		var title = _v0.hE;
		var badge = _v0.dp;
		var icon = _v0.iN;
		return A2(
			$elm$html$Html$header,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('modal__header', true),
							_Utils_Tuple2('modal__header--sticky', isSticky)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('modal__header__wrapper', true),
									_Utils_Tuple2(
									'modal__header__wrapper--custom',
									$elm_community$maybe_extra$Maybe$Extra$isJust(customContent))
								])),
							$elm$html$Html$Attributes$classList(classList)
						]),
					A2(
						$elm$core$Maybe$withDefault,
						_List_fromArray(
							[
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderBadge, badge)),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderIcon, icon)),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2(
									$elm$core$Maybe$map,
									$author$project$Pyxis$Components$Modal$Header$renderTitle(titleId),
									title))
							]),
						customContent)),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Header$renderCloseButton, closeButton))
				]));
	});
var $author$project$Pyxis$Components$Modal$screenReaderDescriptionId = function (id) {
	return id + '-sr-description';
};
var $author$project$Pyxis$Components$Modal$renderDescribedBy = F2(
	function (id, description) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('sr-only'),
					$elm$html$Html$Attributes$id(
					$author$project$Pyxis$Components$Modal$screenReaderDescriptionId(id))
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(description)
				]));
	});
var $author$project$Pyxis$Components$Modal$titleId = function (id) {
	return id + '-header-title';
};
var $author$project$Pyxis$Components$Modal$Header$withCloseButton = F2(
	function (onCloseData, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{dy: onCloseData});
	});
var $author$project$Pyxis$Components$Modal$render = F2(
	function (_v0, _v1) {
		var id = _v0.iO;
		var isOpen = _v0.f9;
		var classList = _v1.fj;
		var header = _v1.f_;
		var content = _v1.bf;
		var footer = _v1.fO;
		var size = _v1.ax;
		var onCloseMsg = _v1.ee;
		var accessibleDescription = _v1.dl;
		var testId = _v1.eO;
		var isCentered = _v1.dU;
		return A2(
			$author$project$Pyxis$Commons$Render$renderIf,
			isOpen,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('modal-backdrop', true),
								_Utils_Tuple2('modal-backdrop--show', isOpen)
							])),
						$elm$html$Html$Attributes$classList(classList)
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2(
							$elm$core$Maybe$map,
							$author$project$Pyxis$Components$Modal$renderDescribedBy(id),
							accessibleDescription)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('modal-close'),
								A2(
								$author$project$Pyxis$Commons$Attributes$maybe,
								A2(
									$elm$core$Basics$composeR,
									function ($) {
										return $.i4;
									},
									$elm$html$Html$Events$onClick),
								onCloseMsg)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$section,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('modal', true),
										_Utils_Tuple2(
										$author$project$Pyxis$Components$Modal$getModalSizeClass(size),
										true),
										_Utils_Tuple2('modal--center', isCentered)
									])),
								$elm$html$Html$Attributes$id(id),
								$author$project$Pyxis$Commons$Attributes$role('dialog'),
								A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
								$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
								$author$project$Pyxis$Components$Modal$titleId(id)),
								$author$project$Pyxis$Commons$Attributes$ariaDescribedBy(
								A2(
									$elm$core$Maybe$withDefault,
									$author$project$Pyxis$Components$Modal$contentId(id),
									A2(
										$elm$core$Maybe$map,
										$elm$core$Basics$always(
											$author$project$Pyxis$Components$Modal$screenReaderDescriptionId(id)),
										accessibleDescription))),
								A2($elm$html$Html$Attributes$attribute, 'aria-modal', 'true')
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2(
									$elm$core$Maybe$map,
									$author$project$Pyxis$Components$Modal$Header$render(
										$author$project$Pyxis$Components$Modal$titleId(id)),
									A2(
										$elm$core$Maybe$map,
										$author$project$Pyxis$Components$Modal$Header$withCloseButton(onCloseMsg),
										header))),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('modal__content'),
										$elm$html$Html$Attributes$id(
										$author$project$Pyxis$Components$Modal$contentId(id))
									]),
								content),
								$author$project$Pyxis$Commons$Render$renderMaybe(
								A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Modal$Footer$render, footer))
							]))
					])));
	});
var $author$project$Pyxis$Components$Modal$S = 0;
var $author$project$Pyxis$Components$Modal$sizeS = 0;
var $author$project$Pyxis$Components$Modal$withCloseMsg = F3(
	function (msg, ariaLabel, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				ee: $elm$core$Maybe$Just(
					{e7: ariaLabel, i4: msg})
			});
	});
var $author$project$Pyxis$Components$Modal$withContent = F2(
	function (content, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{bf: content});
	});
var $author$project$Pyxis$Components$Modal$withFooter = F2(
	function (footer, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				fO: $elm$core$Maybe$Just(footer)
			});
	});
var $author$project$Pyxis$Components$Modal$withHeader = F2(
	function (header, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				f_: $elm$core$Maybe$Just(header)
			});
	});
var $author$project$Pyxis$Components$Modal$withSize = F2(
	function (size, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{ax: size});
	});
var $author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$render = F5(
	function (offer, i18n, env, closeMsg, modelMessage) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			modelMessage,
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A3(
					$author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$footer,
					i18n,
					env(
						$author$project$Common$Offer$pickId(offer)),
					closeMsg),
				A3(
					$author$project$Pyxis$Components$Modal$withCloseMsg,
					closeMsg,
					'',
					A2(
						$author$project$Pyxis$Components$Modal$withContent,
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$completeOfferLegalInformationContent(i18n))
							]),
						A2(
							$author$project$Pyxis$Components$Modal$withHeader,
							$author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$header(i18n),
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Translations$bundleSlugComprehensiveCover = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Comprehensive cover';
	} else {
		return 'Todo riesgo con franquicia';
	}
};
var $author$project$Translations$bundleSlugThirdPartyExtended = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Third party extended';
	} else {
		return 'Terceros ampliado';
	}
};
var $author$project$Translations$bundleSlugThirdPartyOnly = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Third party only';
	} else {
		return 'Terceros básico';
	}
};
var $author$project$Common$Product$bundleSlugToString = F2(
	function (i18n, bundleSlug) {
		switch (bundleSlug) {
			case 0:
				return $author$project$Translations$bundleSlugThirdPartyOnly(i18n);
			case 1:
				return $author$project$Translations$bundleSlugThirdPartyExtended(i18n);
			default:
				return $author$project$Translations$bundleSlugComprehensiveCover(i18n);
		}
	});
var $author$project$Common$Locale$formatDate = function (date) {
	return _Utils_eq(date, $author$project$Common$Locale$defaultDate) ? '' : A2($justinmimbs$date$Date$format, 'dd/MM/y', date);
};
var $author$project$Common$Offer$pickSelectedBundle = function (_v0) {
	var offer = _v0;
	return offer.hg;
};
var $author$project$Common$Offer$pickStartDate = function (_v0) {
	var offer = _v0;
	return $author$project$Common$Locale$posixToCustomerDate(offer.np);
};
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$node = $elm$virtual_dom$VirtualDom$node;
var $author$project$Translations$recordingDisclaimerModalContent = F3(
	function (data, extraAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('\"I confirm that you will take out an insurance policy ('),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.kz)
								])),
							$elm$html$Html$text('), which covers: '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text('compulsory and voluntary civil liability, 24-hour travel assistance with no km limit, free choice of garage, driver protection, legal defence and damage claims / windows, theft, fire / own damage with an excess of €200.')
								]))
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Drivers under 26 years of age and with less than 2 years of driving licence are not covered, unless explicitly declared.')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Payment will be made today but the insurance will take effect on '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.np)
								])),
							$elm$html$Html$text('. The total price for your insurance is '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.nF)
								])),
							$elm$html$Html$text(', including insurance premium tax and statutory surcharges.')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('You have 14 days to exercise your right of withdrawal, in which case the unearned premium will be refunded. You can find information on how to proceed on our website or contact our customer service.\"')
						])),
					A3(
					$elm$html$Html$node,
					'h2',
					_Utils_ap(_List_Nil, extraAttrs.lc),
					_List_fromArray(
						[
							$elm$html$Html$text('Call recording protocol')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Pause the recording in Aircall before the customer\'s bank details and remember to resume it afterwards.')
						]))
				]);
		} else {
			return _List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('\"Te confirmo que contratarás un seguro a ('),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.kz)
								])),
							$elm$html$Html$text('), que cubre: '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text('responsabilidad civil obligatoria y voluntaria, asistencia en viaje 24 h sin límite de km, libre elección de taller, protección al conductor, defensa jurídica y reclamación de daños / lunas, robo, incendio / daños propios con franquicia 200€.')
								]))
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Los conductores menores de 26 años y con menos de 2 años de antigüedad de carnet no están cubiertos, salvo que estén declarados de manera explícita.')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('El pago se realizará hoy pero el seguro entrará en vigor el '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.np)
								])),
							$elm$html$Html$text('. El precio de su seguro es de '),
							A3(
							$elm$html$Html$node,
							'strong',
							_Utils_ap(_List_Nil, extraAttrs.nv),
							_List_fromArray(
								[
									$elm$html$Html$text(data.nF)
								])),
							$elm$html$Html$text(', e incluye el Impuesto sobre las Primas de Seguros y los recargos legales.')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Tienes 14 días para ejercer el derecho de desistimiento, y en ese caso se te devolverá la prima no consumida. Puedes encontrar la información sobre cómo proceder en nuestra página web o contactar con nuestro servicio de atención al cliente\"')
						])),
					A3(
					$elm$html$Html$node,
					'h2',
					_Utils_ap(_List_Nil, extraAttrs.lc),
					_List_fromArray(
						[
							$elm$html$Html$text('Protocolo de grabación de llamadas')
						])),
					A3(
					$elm$html$Html$node,
					'p',
					_Utils_ap(_List_Nil, extraAttrs.mW),
					_List_fromArray(
						[
							$elm$html$Html$text('Pausa la grabación en Aircall antes de los datos bancarios del cliente y recuerda reanudarla luego.')
						]))
				]);
		}
	});
var $author$project$Common$Price$pickSign = function (_v0) {
	return '€';
};
var $cuducos$elm_format_number$FormatNumber$Locales$Exact = function (a) {
	return {$: 2, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Min = function (a) {
	return {$: 0, a: a};
};
var $cuducos$elm_format_number$FormatNumber$Locales$Western = 0;
var $cuducos$elm_format_number$FormatNumber$Locales$base = {
	kP: '.',
	kQ: $cuducos$elm_format_number$FormatNumber$Locales$Min(0),
	gt: '−',
	gu: '',
	jq: '',
	jr: '',
	eK: 0,
	az: '',
	kf: '',
	kg: ''
};
var $author$project$Translations$currentLanguage = function (_v0) {
	var lang = _v0;
	return lang;
};
var $author$project$Common$Price$decimalSeparator = function (i18n) {
	var _v0 = $author$project$Translations$currentLanguage(i18n);
	if (!_v0) {
		return '.';
	} else {
		return ',';
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {kQ: decimals, iU: integers, jj: original, eo: prefix, eI: suffix};
	});
var $cuducos$elm_format_number$FormatNumber$Parser$Negative = 2;
var $cuducos$elm_format_number$FormatNumber$Parser$Positive = 0;
var $cuducos$elm_format_number$FormatNumber$Parser$Zero = 1;
var $cuducos$elm_format_number$FormatNumber$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.iU,
				$elm$core$List$singleton(formatted.kQ))));
	return onlyZeros ? 1 : ((formatted.jj < 0) ? 2 : 0);
};
var $elm$core$String$filter = _String_filter;
var $cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit = F2(
	function (desiredLength, value) {
		var length = $elm$core$String$length(value);
		var missing = (_Utils_cmp(length, desiredLength) < 0) ? $elm$core$Basics$abs(desiredLength - length) : 0;
		return _Utils_ap(
			value,
			A2($elm$core$String$repeat, missing, '0'));
	});
var $cuducos$elm_format_number$FormatNumber$Parser$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $cuducos$elm_format_number$FormatNumber$Parser$getDecimals = F2(
	function (locale, digits) {
		var _v0 = locale.kQ;
		switch (_v0.$) {
			case 1:
				return $cuducos$elm_format_number$FormatNumber$Parser$removeZeros(digits);
			case 2:
				return digits;
			default:
				var min = _v0.a;
				return A2($cuducos$elm_format_number$FormatNumber$Parser$addZerosToFit, min, digits);
		}
	});
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitInParts = F2(
	function (locale, value) {
		var toString = function () {
			var _v1 = locale.kQ;
			switch (_v1.$) {
				case 1:
					var max = _v1.a;
					return $myrho$elm_round$Round$round(max);
				case 0:
					return $elm$core$String$fromFloat;
				default:
					var exact = _v1.a;
					return $myrho$elm_round$Round$round(exact);
			}
		}();
		var asList = A2(
			$elm$core$String$split,
			'.',
			toString(value));
		var decimals = function () {
			var _v0 = $elm$core$List$tail(asList);
			if (!_v0.$) {
				var values = _v0.a;
				return A2(
					$elm$core$Maybe$withDefault,
					'',
					$elm$core$List$head(values));
			} else {
				return '';
			}
		}();
		var integers = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(asList));
		return _Utils_Tuple2(integers, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian = function (integers) {
	var thousand = ($elm$core$String$length(integers) > 3) ? A2($elm$core$String$right, 3, integers) : integers;
	var reversedSplitHundreds = function (value) {
		return ($elm$core$String$length(value) > 2) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 2, value),
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 2, value))) : ((!$elm$core$String$length(value)) ? _List_Nil : _List_fromArray(
			[value]));
	};
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$cons,
			thousand,
			reversedSplitHundreds(
				A2($elm$core$String$dropRight, 3, integers))));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $cuducos$elm_format_number$FormatNumber$Parser$splitIntegers = F2(
	function (system, integers) {
		if (!system) {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByWestern(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		} else {
			return $cuducos$elm_format_number$FormatNumber$Parser$splitByIndian(
				A2($elm$core$String$filter, $elm$core$Char$isDigit, integers));
		}
	});
var $cuducos$elm_format_number$FormatNumber$Parser$parse = F2(
	function (locale, original) {
		var parts = A2($cuducos$elm_format_number$FormatNumber$Parser$splitInParts, locale, original);
		var integers = A2(
			$cuducos$elm_format_number$FormatNumber$Parser$splitIntegers,
			locale.eK,
			A2($elm$core$String$filter, $elm$core$Char$isDigit, parts.a));
		var decimals = A2($cuducos$elm_format_number$FormatNumber$Parser$getDecimals, locale, parts.b);
		var partial = A5($cuducos$elm_format_number$FormatNumber$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $cuducos$elm_format_number$FormatNumber$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{eo: locale.gt, eI: locale.gu});
			case 0:
				return _Utils_update(
					partial,
					{eo: locale.jq, eI: locale.jr});
			default:
				return _Utils_update(
					partial,
					{eo: locale.kf, eI: locale.kg});
		}
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.kP, decimals);
	});
var $cuducos$elm_format_number$FormatNumber$Stringfy$stringfy = F2(
	function (locale, formatted) {
		var stringfyDecimals = $cuducos$elm_format_number$FormatNumber$Stringfy$formatDecimals(locale);
		var integers = A2($elm$core$String$join, locale.az, formatted.iU);
		var decimals = stringfyDecimals(formatted.kQ);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.eo, integers, decimals, formatted.eI]));
	});
var $cuducos$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A2(
			$cuducos$elm_format_number$FormatNumber$Stringfy$stringfy,
			locale,
			A2($cuducos$elm_format_number$FormatNumber$Parser$parse, locale, number_));
	});
var $prikhi$decimal$Decimal$toFloat = function (d) {
	var _v0 = $elm$core$String$toFloat(
		$prikhi$decimal$Decimal$toString(d));
	if (!_v0.$) {
		var a = _v0.a;
		return a;
	} else {
		return 42.0;
	}
};
var $author$project$Common$Price$toAmountString = F2(
	function (i18n, amount) {
		var base = $cuducos$elm_format_number$FormatNumber$Locales$base;
		return A2(
			$cuducos$elm_format_number$FormatNumber$format,
			_Utils_update(
				base,
				{
					kP: $author$project$Common$Price$decimalSeparator(i18n),
					kQ: $cuducos$elm_format_number$FormatNumber$Locales$Exact(2)
				}),
			$prikhi$decimal$Decimal$toFloat(amount));
	});
var $author$project$Common$Price$toStringNoSpaceSignLast = F2(
	function (i18n, _v0) {
		var amount = _v0.a;
		var currency = _v0.b;
		return _Utils_ap(
			A2($author$project$Common$Price$toAmountString, i18n, amount),
			$author$project$Common$Price$pickSign(currency));
	});
var $cmditch$elm_bigint$BigInt$MagnitudePair = $elm$core$Basics$identity;
var $cmditch$elm_bigint$BigInt$sameSizeRaw = F2(
	function (xs, ys) {
		var _v0 = _Utils_Tuple2(xs, ys);
		if (!_v0.a.b) {
			if (!_v0.b.b) {
				return _List_Nil;
			} else {
				var _v2 = _v0.b;
				var y = _v2.a;
				var ys_ = _v2.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(0, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, _List_Nil, ys_));
			}
		} else {
			if (!_v0.b.b) {
				var _v1 = _v0.a;
				var x = _v1.a;
				var xs_ = _v1.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, 0),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, _List_Nil));
			} else {
				var _v3 = _v0.a;
				var x = _v3.a;
				var xs_ = _v3.b;
				var _v4 = _v0.b;
				var y = _v4.a;
				var ys_ = _v4.b;
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(x, y),
					A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs_, ys_));
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sameSizeNotNormalized = F2(
	function (_v0, _v1) {
		var xs = _v0;
		var ys = _v1;
		return A2($cmditch$elm_bigint$BigInt$sameSizeRaw, xs, ys);
	});
var $cmditch$elm_bigint$BigInt$toPositiveSign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 2, _List_Nil);
		case 1:
			var digits = bigInt.a;
			return A2(
				$cmditch$elm_bigint$BigInt$mkBigIntNotNormalised,
				0,
				$cmditch$elm_bigint$BigInt$reverseMagnitude(digits));
		default:
			var digits = bigInt.a;
			return A2($cmditch$elm_bigint$BigInt$mkBigIntNotNormalised, 0, digits);
	}
};
var $cmditch$elm_bigint$BigInt$add = F2(
	function (a, b) {
		var _v0 = $cmditch$elm_bigint$BigInt$toPositiveSign(b);
		var mb = _v0.b;
		var _v1 = $cmditch$elm_bigint$BigInt$toPositiveSign(a);
		var ma = _v1.b;
		var _v2 = A2($cmditch$elm_bigint$BigInt$sameSizeNotNormalized, ma, mb);
		var pairs = _v2;
		var added = A2(
			$elm$core$List$map,
			function (_v3) {
				var a_ = _v3.a;
				var b_ = _v3.b;
				return a_ + b_;
			},
			pairs);
		return $cmditch$elm_bigint$BigInt$normalise(
			A2($cmditch$elm_bigint$BigInt$BigIntNotNormalised, 0, added));
	});
var $cmditch$elm_bigint$BigInt$magnitude = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return _List_Nil;
		case 0:
			var mag = bigInt.a;
			return mag;
		default:
			var mag = bigInt.a;
			return mag;
	}
};
var $cmditch$elm_bigint$BigInt$mulSingleDigit = F2(
	function (_v0, d) {
		var xs = _v0;
		return $cmditch$elm_bigint$BigInt$normaliseMagnitude(
			A2(
				$elm$core$List$map,
				$elm$core$Basics$mul(d),
				xs));
	});
var $cmditch$elm_bigint$BigInt$mulMagnitudes = F2(
	function (_v0, _v1) {
		var mag1 = _v0;
		var mag2 = _v1;
		if (!mag1.b) {
			return _List_Nil;
		} else {
			if (!mag1.b.b) {
				var m = mag1.a;
				return A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
			} else {
				var m = mag1.a;
				var mx = mag1.b;
				var accum = A2($cmditch$elm_bigint$BigInt$mulSingleDigit, mag2, m);
				var _v3 = A2($cmditch$elm_bigint$BigInt$mulMagnitudes, mx, mag2);
				var rest = _v3;
				var bigInt = A2(
					$cmditch$elm_bigint$BigInt$add,
					A2($cmditch$elm_bigint$BigInt$mkBigInt, 0, accum),
					A2(
						$cmditch$elm_bigint$BigInt$mkBigInt,
						0,
						A2($elm$core$List$cons, 0, rest)));
				return $cmditch$elm_bigint$BigInt$magnitude(bigInt);
			}
		}
	});
var $cmditch$elm_bigint$BigInt$sign = function (bigInt) {
	switch (bigInt.$) {
		case 2:
			return 2;
		case 0:
			return 0;
		default:
			return 1;
	}
};
var $cmditch$elm_bigint$BigInt$signProduct = F2(
	function (x, y) {
		return ((x === 2) || (y === 2)) ? 2 : (_Utils_eq(x, y) ? 0 : 1);
	});
var $cmditch$elm_bigint$BigInt$mul = F2(
	function (int1, int2) {
		return A2(
			$cmditch$elm_bigint$BigInt$mkBigInt,
			A2(
				$cmditch$elm_bigint$BigInt$signProduct,
				$cmditch$elm_bigint$BigInt$sign(int1),
				$cmditch$elm_bigint$BigInt$sign(int2)),
			A2(
				$cmditch$elm_bigint$BigInt$mulMagnitudes,
				$cmditch$elm_bigint$BigInt$magnitude(int1),
				$cmditch$elm_bigint$BigInt$magnitude(int2)));
	});
var $prikhi$decimal$Decimal$addDecimals = F2(
	function (i, _v0) {
		addDecimals:
		while (true) {
			var m = _v0.a;
			var e = _v0.b;
			var mul10 = function (x) {
				return A2(
					$cmditch$elm_bigint$BigInt$mul,
					x,
					$cmditch$elm_bigint$BigInt$fromInt(10));
			};
			if (!i) {
				return A2($prikhi$decimal$Decimal$Decimal, m, e);
			} else {
				if (i > 0) {
					var $temp$i = i - 1,
						$temp$_v0 = A2(
						$prikhi$decimal$Decimal$Decimal,
						mul10(m),
						e - 1);
					i = $temp$i;
					_v0 = $temp$_v0;
					continue addDecimals;
				} else {
					return A2($prikhi$decimal$Decimal$Decimal, m, e);
				}
			}
		}
	});
var $prikhi$decimal$Decimal$toExponent = F2(
	function (e, _v0) {
		var md = _v0.a;
		var ed = _v0.b;
		return A2(
			$prikhi$decimal$Decimal$addDecimals,
			ed - e,
			A2($prikhi$decimal$Decimal$Decimal, md, ed));
	});
var $prikhi$decimal$Decimal$toCommonExponent = function (_v0) {
	var a = _v0.a;
	var b = _v0.b;
	var _v1 = b;
	var mb = _v1.a;
	var eb = _v1.b;
	var _v2 = a;
	var ma = _v2.a;
	var ea = _v2.b;
	var exponent = A2($elm$core$Basics$min, ea, eb);
	return _Utils_Tuple2(
		A2($prikhi$decimal$Decimal$toExponent, exponent, a),
		A2($prikhi$decimal$Decimal$toExponent, exponent, b));
};
var $prikhi$decimal$Decimal$add = F2(
	function (a, b) {
		var _v0 = $prikhi$decimal$Decimal$toCommonExponent(
			_Utils_Tuple2(a, b));
		var ra = _v0.a;
		var rb = _v0.b;
		var _v1 = rb;
		var mb = _v1.a;
		var eb = _v1.b;
		var _v2 = ra;
		var ma = _v2.a;
		var ea = _v2.b;
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			A2($cmditch$elm_bigint$BigInt$add, ma, mb),
			ea);
	});
var $author$project$Common$Price$pickAmount = function (_v0) {
	var amount = _v0.a;
	return amount;
};
var $author$project$Common$Price$sum = F2(
	function (firstAddend, secondAddend) {
		return $author$project$Common$Price$euro(
			A2(
				$prikhi$decimal$Decimal$add,
				$author$project$Common$Price$pickAmount(firstAddend),
				$author$project$Common$Price$pickAmount(secondAddend)));
	});
var $prikhi$decimal$Decimal$fromIntWithExponent = F2(
	function (n, e) {
		return A2(
			$prikhi$decimal$Decimal$Decimal,
			$cmditch$elm_bigint$BigInt$fromInt(n),
			e);
	});
var $prikhi$decimal$Decimal$fromInt = function (n) {
	return A2($prikhi$decimal$Decimal$fromIntWithExponent, n, 0);
};
var $prikhi$decimal$Decimal$zero = $prikhi$decimal$Decimal$fromInt(0);
var $author$project$Common$Price$zero = function (currency) {
	return A2($author$project$Common$Price$new, $prikhi$decimal$Decimal$zero, currency);
};
var $author$project$Common$Price$sumAll = function (prices) {
	return A3(
		$elm$core$List$foldl,
		$author$project$Common$Price$sum,
		$author$project$Common$Price$zero(0),
		prices);
};
var $author$project$Common$Offer$totalPrice = function (_v0) {
	var offer = _v0;
	return $author$project$Common$Price$sumAll(
		A2(
			$elm$core$List$map,
			function (product) {
				return product.kr.la;
			},
			offer.ep));
};
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$defaultContent = F2(
	function (i18n, offer) {
		return A3(
			$author$project$Translations$recordingDisclaimerModalContent,
			{
				kz: A2(
					$author$project$Common$Product$bundleSlugToString,
					i18n,
					$author$project$Common$Offer$pickSelectedBundle(offer)),
				np: $author$project$Common$Locale$formatDate(
					$author$project$Common$Offer$pickStartDate(offer)),
				nF: A2(
					$author$project$Common$Price$toStringNoSpaceSignLast,
					i18n,
					$author$project$Common$Offer$totalPrice(offer))
			},
			{
				lc: _List_fromArray(
					[
						$elm$html$Html$Attributes$class('recording-disclaimer-modal__subtitle c-neutral-25')
					]),
				mW: _List_fromArray(
					[
						$elm$html$Html$Attributes$class('recording-disclaimer-modal__paragraph c-neutral-25')
					]),
				nv: _List_fromArray(
					[
						$elm$html$Html$Attributes$class('recording-disclaimer-modal__strong c-neutral-25')
					])
			},
			i18n);
	});
var $author$project$Translations$recordingDisclaimerModalRenewalContent = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Pause the recording in Aircall before the customer\'s bank details and remember to resume it afterwards.';
	} else {
		return 'Pausa la grabación en Aircall antes de los datos bancarios del cliente y recuerda reanudarla luego.';
	}
};
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$renewalContent = A2(
	$elm$core$Basics$composeR,
	$author$project$Translations$recordingDisclaimerModalRenewalContent,
	A2($elm$core$Basics$composeR, $elm$html$Html$text, $elm$core$List$singleton));
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$content = F2(
	function (i18n, offer) {
		return $author$project$Common$Offer$pickIsRenewal(offer) ? $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$renewalContent(i18n) : A2($author$project$Common$ComponentUI$Modal$RecordingDisclaimer$defaultContent, i18n, offer);
	});
var $author$project$Translations$recordingDisclaimerModalButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchase Offer';
	} else {
		return 'Comprar Oferta';
	}
};
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$footer = F2(
	function (i18n, msg) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withTestId,
						'purchase-button',
						A2(
							$author$project$Pyxis$Components$Button$withId,
							'purchase-button',
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$recordingDisclaimerModalButtonText(i18n),
								A2($author$project$Pyxis$Components$Button$withOnClick, msg, $author$project$Pyxis$Components$Button$primary)))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Pyxis$Components$IconSet$HomeLegalProtection = 78;
var $author$project$Pyxis$Components$IconSet$homeLegalProtection = 78;
var $author$project$Translations$recordingDisclaimerModalTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Legal Text';
	} else {
		return 'Texto Legal';
	}
};
var $author$project$Pyxis$Tokens$Color$BrandBase = 7;
var $author$project$Pyxis$Tokens$Color$tokenBrandBase = 7;
var $author$project$Pyxis$Components$Icon$withColor = F2(
	function (color, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fn: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Pyxis$Components$Modal$Header$withIcon = F2(
	function (icon, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				iN: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$defaultHeader = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withIcon,
		A2(
			$author$project$Pyxis$Components$Icon$withColor,
			$author$project$Pyxis$Tokens$Color$tokenBrandBase,
			$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$homeLegalProtection)),
		A2(
			$author$project$Pyxis$Components$Modal$Header$withTitle,
			$author$project$Translations$recordingDisclaimerModalTitle(i18n),
			$author$project$Pyxis$Components$Modal$Header$config));
};
var $author$project$Pyxis$Components$IconSet$Phone = 121;
var $author$project$Pyxis$Components$IconSet$phone = 121;
var $author$project$Translations$recordingDisclaimerModalRenewalTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Call recording protocol';
	} else {
		return 'Protocolo de grabación de llamadas';
	}
};
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$renewalHeader = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withIcon,
		A2(
			$author$project$Pyxis$Components$Icon$withColor,
			$author$project$Pyxis$Tokens$Color$tokenBrandBase,
			$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$phone)),
		A2(
			$author$project$Pyxis$Components$Modal$Header$withTitle,
			$author$project$Translations$recordingDisclaimerModalRenewalTitle(i18n),
			$author$project$Pyxis$Components$Modal$Header$config));
};
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$header = F2(
	function (i18n, offer) {
		return $author$project$Common$Offer$pickIsRenewal(offer) ? $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$renewalHeader(i18n) : $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$defaultHeader(i18n);
	});
var $author$project$Pyxis$Components$Modal$L = 2;
var $author$project$Pyxis$Components$Modal$sizeL = 2;
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$size = function (offer) {
	return $author$project$Common$Offer$pickIsRenewal(offer) ? $author$project$Pyxis$Components$Modal$sizeS : $author$project$Pyxis$Components$Modal$sizeL;
};
var $author$project$Pyxis$Components$Modal$withTestId = F2(
	function (id, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				eO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Common$ComponentUI$Modal$RecordingDisclaimer$render = F5(
	function (offer, i18n, closeMsg, ctaMsg, modalMessage) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			modalMessage,
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A2(
					$author$project$Common$ComponentUI$Modal$RecordingDisclaimer$footer,
					i18n,
					ctaMsg(offer)),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A2($author$project$Common$ComponentUI$Modal$RecordingDisclaimer$content, i18n, offer),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						A2($author$project$Common$ComponentUI$Modal$RecordingDisclaimer$header, i18n, offer),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							closeMsg,
							'',
							A2(
								$author$project$Pyxis$Components$Modal$withSize,
								$author$project$Common$ComponentUI$Modal$RecordingDisclaimer$size(offer),
								A2($author$project$Pyxis$Components$Modal$withTestId, 'stop-recording-disclaimer-modal', $author$project$Pyxis$Components$Modal$config)))))));
	});
var $author$project$Pyxis$Components$Loaders$Loader$getModifierClassForVariant = function (variant) {
	if (variant.$ === 1) {
		return 'loader--with-car';
	} else {
		if (variant.a === 1) {
			var _v1 = variant.a;
			return 'loader--s';
		} else {
			var _v2 = variant.a;
			return '';
		}
	}
};
var $author$project$Pyxis$Components$Loaders$Loader$getVariantClass = function (variant) {
	if (variant.$ === 1) {
		return 'loader__car';
	} else {
		return 'loader__spinner';
	}
};
var $author$project$Pyxis$Components$Loaders$Loader$isCar = function (variant) {
	if (variant.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $elm$svg$Svg$Attributes$class = _VirtualDom_attribute('class');
var $elm$svg$Svg$Attributes$x1 = _VirtualDom_attribute('x1');
var $elm$svg$Svg$Attributes$x2 = _VirtualDom_attribute('x2');
var $elm$svg$Svg$Attributes$y1 = _VirtualDom_attribute('y1');
var $elm$svg$Svg$Attributes$y2 = _VirtualDom_attribute('y2');
var $author$project$Pyxis$Components$Loaders$LoaderCar$renderSvg = A2(
	$elm$svg$Svg$svg,
	_List_fromArray(
		[
			A2($elm$html$Html$Attributes$attribute, 'aria-hidden', 'true'),
			$elm$svg$Svg$Attributes$height('50'),
			$elm$svg$Svg$Attributes$viewBox('0 0 375 125')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$g,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$class('loader__car__items-wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__wheel'),
							$elm$svg$Svg$Attributes$d('M184.9,106.3c-12.2,0-21.8-9.6-21.8-21.8s9.6-21.8,21.8-21.8c12.2,0,21.8,9.6,21.8,21.8 S197.1,106.3,184.9,106.3z M184.9,71.2c-7.4,0-12.8,5.9-12.8,12.8c0,7.4,5.9,12.8,12.8,12.8c7.4,0,12.8-5.9,12.8-12.8 C198.2,77.1,192.4,71.2,184.9,71.2z M281.2,106.3c-12.2,0-21.8-9.6-21.8-21.8s9.6-21.8,21.8-21.8c12.2,0,21.8,9.6,21.8,21.8 S292.9,106.3,281.2,106.3z M281.2,71.2c-7.4,0-12.8,5.9-12.8,12.8c0,7.4,5.9,12.8,12.8,12.8c7.4,0,12.8-5.9,12.8-12.8 S288.1,71.2,281.2,71.2z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__body'),
							$elm$svg$Svg$Attributes$d('M308.8,47.8l-43.6-9c-1.1-0.5-2.7-1.1-3.2-2.1l-17.6-22.9c-2.7-3.7-6.9-5.9-11.2-5.9h-60.6 c-4.3,0-8.5,2.1-11.2,5.3l-28.2,33.5c-3.2,3.7-4.3,9-3.2,13.8l4.8,18.1c1.6,6.4,7.4,10.6,13.8,10.6h17c2.1,0,4.3-2.1,4.3-4.3 c0-2.1-1.6-4.3-4.3-4.3h-17c-2.7,0-4.8-1.6-5.3-4.3l-4.8-18.1c-0.5-2.1,0-4.3,1.1-5.9l28.2-33.5c1.1-1.6,2.7-2.1,4.3-2.1h60.6 c1.6,0,3.2,0.5,4.3,2.1l17.6,22.9c2.1,2.7,5.3,4.8,8.5,5.3l43.6,9c2.7,0.5,4.8,3.2,4.8,5.9v12.2c0,3.2-2.7,5.9-5.9,5.9h-6.4 c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h6.4c8,0,14.4-6.4,14.9-14.4V62.2C320.6,55.2,315.8,49.4,308.8,47.8z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__body'),
							$elm$svg$Svg$Attributes$d('M241.8,40.9c0-2.1-1.6-4.3-4.3-4.3h-69.7c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h69.7 C239.7,45.1,241.8,43,241.8,40.9z')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$path,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__body'),
							$elm$svg$Svg$Attributes$d('M263.6,80.2h-61.2c-2.1,0-4.3,2.1-4.3,4.3c0,2.1,1.6,4.3,4.3,4.3h61.2c2.1,0,4.3-2.1,4.3-4.3 C267.9,82.4,266.3,80.2,263.6,80.2z')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'line',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__line loader__car__line--top'),
							$elm$svg$Svg$Attributes$x1('68'),
							$elm$svg$Svg$Attributes$x2('114.2'),
							$elm$svg$Svg$Attributes$y1('46.7'),
							$elm$svg$Svg$Attributes$y2('46.7')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'line',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__line loader__car__line--bottom'),
							$elm$svg$Svg$Attributes$x1('33.2'),
							$elm$svg$Svg$Attributes$x2('99.4'),
							$elm$svg$Svg$Attributes$y1('65.9'),
							$elm$svg$Svg$Attributes$y2('65.9')
						]),
					_List_Nil),
					A3(
					$elm$svg$Svg$node,
					'line',
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$class('loader__car__line loader__car__line--middle'),
							$elm$svg$Svg$Attributes$x1('68'),
							$elm$svg$Svg$Attributes$x2('114.2'),
							$elm$svg$Svg$Attributes$y1('84.5'),
							$elm$svg$Svg$Attributes$y2('84.5')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Pyxis$Commons$Render$maybeMapRender = function (renderFn) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(renderFn),
		$author$project$Pyxis$Commons$Render$renderMaybe);
};
var $author$project$Pyxis$Components$Loaders$Loader$renderTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('loader__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$Loaders$Loader$renderTextBlock = F3(
	function (isHiddenText, title, label) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('loader__text-wrapper', true),
							_Utils_Tuple2('sr-only', isHiddenText)
						]))
				]),
			_List_fromArray(
				[
					A2($author$project$Pyxis$Commons$Render$maybeMapRender, $author$project$Pyxis$Components$Loaders$Loader$renderTitle, title),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('loader__text')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						]))
				]));
	});
var $author$project$Pyxis$Components$Loaders$Loader$render = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('loader', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Loaders$Loader$getModifierClassForVariant(configuration.hK),
							true)
						]),
					configuration.fj)),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configuration.iO),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configuration.eO),
				$author$project$Pyxis$Commons$Attributes$ariaLivePolite,
				A2(
				$author$project$Pyxis$Commons$Attributes$renderIf,
				$author$project$Pyxis$Commons$Properties$Theme$isAlternative(configuration.eP),
				$author$project$Pyxis$Commons$Attributes$theme(configuration.eP))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class(
						$author$project$Pyxis$Components$Loaders$Loader$getVariantClass(configuration.hK))
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Commons$Render$renderIf,
						$author$project$Pyxis$Components$Loaders$Loader$isCar(configuration.hK),
						$author$project$Pyxis$Components$Loaders$LoaderCar$renderSvg)
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2($author$project$Pyxis$Components$Loaders$Loader$renderTextBlock, configuration.dY, configuration.hE),
					configuration.nB))
			]));
};
var $author$project$Pyxis$Components$Loaders$Loader$M = 0;
var $author$project$Pyxis$Components$Loaders$Loader$Spinner = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Loaders$Loader$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Loaders$Loader$config = function (variant) {
	return {fj: _List_Nil, iO: $elm$core$Maybe$Nothing, dY: false, eO: $elm$core$Maybe$Nothing, nB: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default, hE: $elm$core$Maybe$Nothing, hK: variant};
};
var $author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM = $author$project$Pyxis$Components$Loaders$Loader$config(
	$author$project$Pyxis$Components$Loaders$Loader$Spinner(0));
var $author$project$Page$OfferDetails$Msg$AmendOfferCoverageEffectiveDate = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Common$CoverContinuity$CurrentInsuranceInfo = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$OfferDetails$Msg$ShowTooltip = function (a) {
	return {$: 12, a: a};
};
var $author$project$Common$Portfolio$Config = $elm$core$Basics$identity;
var $author$project$Common$Portfolio$config = function (vehicleOwner) {
	return function (mainDriver) {
		return function (additionalDriver) {
			return function (policyHolder) {
				return function (vehicle) {
					return function (claimsHistory) {
						return function (coversData) {
							return function (selectedBoundleSlug) {
								return function (onHoverTooltip) {
									return function (customerId) {
										return {hS: additionalDriver, h3: claimsHistory, ij: coversData, fw: customerId, d5: mainDriver, gF: $elm$core$Maybe$Nothing, jf: onHoverTooltip, g2: policyHolder, jO: selectedBoundleSlug, hL: vehicle, j7: vehicleOwner};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Page$OfferDetails$Model$pickCustomerId = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.fw;
	},
	A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$toMaybe, $elm_community$maybe_extra$Maybe$Extra$join));
var $author$project$Common$Offer$pickData = function (_v0) {
	var offer = _v0;
	return offer;
};
var $author$project$Page$OfferDetails$View$portfolioConfig = F2(
	function (model, offer) {
		var _v0 = $author$project$Common$Offer$pickData(offer);
		var vehicleOwner = _v0.j7;
		var mainDriver = _v0.d5;
		var additionalDriver = _v0.hS;
		var policyHolder = _v0.g2;
		var vehicle = _v0.hL;
		var coverContinuity = _v0.$8;
		var selectedBundle = _v0.hg;
		return $author$project$Common$Portfolio$config(vehicleOwner)(mainDriver)(additionalDriver)(policyHolder)(vehicle)(model.cf)(
			$author$project$Common$CoverContinuity$CurrentInsuranceInfo(coverContinuity))(selectedBundle)($author$project$Page$OfferDetails$Msg$ShowTooltip)(
			$author$project$Page$OfferDetails$Model$pickCustomerId(model));
	});
var $author$project$Common$Portfolio$OuterTabsChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Tabs$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$Left = 0;
var $author$project$Pyxis$Components$Tabs$Underline = 0;
var $author$project$Pyxis$Components$Tabs$underline = 0;
var $author$project$Pyxis$Components$Tabs$config = function (id) {
	return {dl: $elm$core$Maybe$Nothing, fj: _List_Nil, fv: $elm$core$Maybe$Nothing, fZ: false, iO: id, S: 0, db: _List_Nil, eO: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default, hK: $author$project$Pyxis$Components$Tabs$underline};
};
var $author$project$Pyxis$Components$Tabs$getActiveTabIndex = function (_v0) {
	var activeIndex = _v0.P;
	return activeIndex;
};
var $elm_community$html_extra$Html$Extra$nothing = $elm$html$Html$text('');
var $author$project$Common$Render$maybe = F2(
	function (content, maybeValue) {
		if (maybeValue.$ === 1) {
			return $elm_community$html_extra$Html$Extra$nothing;
		} else {
			var value = maybeValue.a;
			return content(value);
		}
	});
var $author$project$Pyxis$Components$IconSet$Chart = 21;
var $author$project$Pyxis$Components$IconSet$chart = 21;
var $author$project$Translations$detailsPortfolioCoverLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Cover';
	} else {
		return 'Coberturas';
	}
};
var $author$project$Translations$detailsPortfolioDataLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Data';
	} else {
		return 'Datos';
	}
};
var $author$project$Translations$detailsCurrentInsurerLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Current insurer';
	} else {
		return 'Aseguradora Actual';
	}
};
var $author$project$Translations$detailsPreviousInsurer = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Previous insurer';
	} else {
		return 'Aseguradora anterior';
	}
};
var $author$project$Common$Render$empty = $elm_community$html_extra$Html$Extra$nothing;
var $author$project$Pyxis$Components$Badge$Neutral = 0;
var $author$project$Pyxis$Components$Badge$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Badge$config = function (variant) {
	return {fj: _List_Nil, iO: $elm$core$Maybe$Nothing, ew: false, eO: $elm$core$Maybe$Nothing, nB: '', eP: $author$project$Pyxis$Commons$Properties$Theme$default, hK: variant};
};
var $author$project$Pyxis$Components$Badge$neutral = $author$project$Pyxis$Components$Badge$config(0);
var $author$project$Pyxis$Components$Badge$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{nB: text});
	});
var $author$project$Common$CoverContinuity$renderBadge = F2(
	function (coverContinuityInfo, badgeText) {
		if (!coverContinuityInfo.$) {
			return $author$project$Common$Render$empty;
		} else {
			return $author$project$Pyxis$Components$Badge$render(
				A2($author$project$Pyxis$Components$Badge$withText, badgeText, $author$project$Pyxis$Components$Badge$neutral));
		}
	});
var $author$project$Translations$coversNoPreviousCoverageDescription = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Seize the opportunity! This customer is insurance-free, don\'t let it slip away.';
	} else {
		return '¡Aprovecha la oportunidad! Este cliente no tiene seguro, no lo dejes escapar.';
	}
};
var $author$project$Translations$coversNoPreviousCoverageTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Starting fresh, no prior coverage.';
	} else {
		return 'Empezando de cero, sin seguro previo.';
	}
};
var $author$project$Translations$coversSummaryPendingCoversInfo = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Previous policy information is not available until it’s entered by the customer or agent.';
	} else {
		return 'La información sobre pólizas anteriores no está disponible hasta que el cliente o el agente la introduzca.';
	}
};
var $author$project$Translations$coversSummaryWithNoCoversInfo = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The information about previous policies is not available for this client, as we didn’t request this data at the time of their registration.';
	} else {
		return 'La información sobre pólizas anteriores no está disponible para este cliente, ya que no hemos solicitado estos datos al momento de su registro.';
	}
};
var $author$project$Translations$coversTercerosBasicoSummaryInfo = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'This package does not provide other covers.';
	} else {
		return 'Este paquete no proporciona otras coberturas.';
	}
};
var $author$project$Common$CoverContinuity$renderCompanyContent = function (contentList) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('covers__title')
			]),
		contentList);
};
var $author$project$Pyxis$Components$Tooltip$Model = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tooltip$init = A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Modal$init, $elm$core$Basics$identity);
var $author$project$Pyxis$Components$Tooltip$Neutral = 0;
var $author$project$Pyxis$Components$Tooltip$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tooltip$Right = 4;
var $author$project$Pyxis$Components$IconSet$QuestionCircle = 128;
var $author$project$Pyxis$Components$IconSet$questionCircle = 128;
var $author$project$Pyxis$Components$Tooltip$config = F3(
	function (variant, id, isMobile) {
		return {fj: _List_Nil, fk: 'Close', bf: $author$project$Pyxis$Commons$Render$empty, dD: $elm$core$Maybe$Nothing, iN: $elm$core$Maybe$Nothing, iO: id, f8: isMobile, en: 4, eO: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default, hE: $elm$core$Maybe$Nothing, eS: $author$project$Pyxis$Components$IconSet$questionCircle, hK: variant};
	});
var $author$project$Pyxis$Components$Tooltip$neutral = $author$project$Pyxis$Components$Tooltip$config(0);
var $author$project$Pyxis$Components$Tooltip$getTooltipPositionClass = function (position) {
	switch (position) {
		case 0:
			return 'tooltip--bottom';
		case 1:
			return 'tooltip--bottom-left';
		case 2:
			return 'tooltip--bottom-right';
		case 3:
			return 'tooltip--left';
		case 4:
			return 'tooltip--right';
		case 5:
			return 'tooltip--top';
		case 6:
			return 'tooltip--top-left';
		default:
			return 'tooltip--top-right';
	}
};
var $author$project$Pyxis$Components$Tooltip$getTooltipVariantClass = function (variant) {
	if (!variant) {
		return '';
	} else {
		return 'tooltip--brand';
	}
};
var $author$project$Pyxis$Components$Tooltip$getClassList = function (_v0) {
	var variant = _v0.hK;
	var theme = _v0.eP;
	var position = _v0.en;
	var classList = _v0.fj;
	return _Utils_ap(
		_List_fromArray(
			[
				_Utils_Tuple2('tooltip', true),
				_Utils_Tuple2(
				$author$project$Pyxis$Components$Tooltip$getTooltipVariantClass(variant),
				true),
				_Utils_Tuple2(
				$author$project$Pyxis$Components$Tooltip$getTooltipPositionClass(position),
				true)
			]),
		classList);
};
var $author$project$Pyxis$Components$Tooltip$renderIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Icon$config,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeS),
		$author$project$Pyxis$Components$Icon$render));
var $author$project$Pyxis$Components$Tooltip$ShowModal = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$Basics$applyIfJust = F2(
	function (f, a) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm$core$Basics$identity,
			A2($elm$core$Maybe$map, f, a));
	});
var $author$project$Pyxis$Components$Tooltip$renderModal = F3(
	function (tagger, modalModel, _v0) {
		var icon = _v0.iN;
		var title = _v0.hE;
		var content = _v0.bf;
		var closeButtonLabel = _v0.fk;
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			modalModel,
			A2(
				$author$project$Pyxis$Components$Modal$withContent,
				_List_fromArray(
					[content]),
				A2(
					$author$project$Pyxis$Components$Modal$withHeader,
					A3(
						$author$project$Pyxis$Commons$Basics$applyIfJust,
						$author$project$Pyxis$Components$Modal$Header$withIcon,
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Icon$config, icon),
						A3($author$project$Pyxis$Commons$Basics$applyIfJust, $author$project$Pyxis$Components$Modal$Header$withTitle, title, $author$project$Pyxis$Components$Modal$Header$config)),
					A3(
						$author$project$Pyxis$Components$Modal$withCloseMsg,
						tagger(false),
						closeButtonLabel,
						$author$project$Pyxis$Components$Modal$config))));
	});
var $author$project$Pyxis$Components$Button$Ghost = 5;
var $author$project$Pyxis$Components$Button$ghost = $author$project$Pyxis$Components$Button$config(5);
var $author$project$Pyxis$Components$Button$withAriaLabelledBy = F2(
	function (ariaLabelledBy, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				e8: $elm$core$Maybe$Just(ariaLabelledBy)
			});
	});
var $author$project$Pyxis$Components$Button$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Tooltip$renderTrigger = F2(
	function (tagger, _v0) {
		var customTrigger = _v0.dD;
		var triggerIcon = _v0.eS;
		var theme = _v0.eP;
		var id = _v0.iO;
		return $elm_community$maybe_extra$Maybe$Extra$isJust(customTrigger) ? A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Events$onClick(
					tagger(true))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(customTrigger)
				])) : $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2('tooltip-trigger', true)
					]),
				A2(
					$author$project$Pyxis$Components$Button$withIconOnly,
					triggerIcon,
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						tagger(true),
						A2(
							$author$project$Pyxis$Components$Button$withSize,
							$author$project$Pyxis$Components$Button$sizeS,
							A2($author$project$Pyxis$Components$Button$withAriaLabelledBy, id, $author$project$Pyxis$Components$Button$ghost))))));
	});
var $author$project$Pyxis$Components$Tooltip$wrapperId = function (id) {
	return id + '-wrapper';
};
var $author$project$Pyxis$Components$Tooltip$render = F3(
	function (tagger, _v0, tooltipConfig) {
		var showModal = _v0;
		var content = tooltipConfig.bf;
		var icon = tooltipConfig.iN;
		var id = tooltipConfig.iO;
		var testId = tooltipConfig.eO;
		var isMobile = tooltipConfig.f8;
		var theme = tooltipConfig.eP;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('tooltip-wrapper'),
					$elm$html$Html$Attributes$id(
					$author$project$Pyxis$Components$Tooltip$wrapperId(id)),
					$author$project$Pyxis$Commons$Attributes$testId(
					$author$project$Pyxis$Components$Tooltip$wrapperId(id)),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
					$author$project$Pyxis$Commons$Attributes$theme(theme))
				]),
			_List_fromArray(
				[
					A2($author$project$Pyxis$Components$Tooltip$renderTrigger, tagger, tooltipConfig),
					isMobile ? A3($author$project$Pyxis$Components$Tooltip$renderModal, tagger, showModal, tooltipConfig) : A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$classList(
							$author$project$Pyxis$Components$Tooltip$getClassList(tooltipConfig)),
							$author$project$Pyxis$Commons$Attributes$role('tooltip'),
							A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
							$elm$html$Html$Attributes$id(id)
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$span,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('tooltip__content')
								]),
							_List_fromArray(
								[
									$author$project$Pyxis$Commons$Render$renderMaybe(
									A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tooltip$renderIcon, icon)),
									content
								]))
						]))
				]));
	});
var $author$project$Pyxis$Components$Tooltip$withContent = F2(
	function (content, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{bf: content});
	});
var $author$project$Common$CoverContinuity$renderTooltip = F2(
	function (tooltipText, onHover) {
		return A3(
			$author$project$Pyxis$Components$Tooltip$render,
			onHover,
			$author$project$Pyxis$Components$Tooltip$init('prima-package-tooltip'),
			A2(
				$author$project$Pyxis$Components$Tooltip$withContent,
				$elm$html$Html$text(tooltipText),
				A2($author$project$Pyxis$Components$Tooltip$neutral, 'default', false)));
	});
var $author$project$Common$CoverContinuity$renderCompany = F3(
	function (tooltipText, onTooltipHover, company) {
		if (!company.$) {
			return $author$project$Common$CoverContinuity$renderCompanyContent(
				_List_fromArray(
					[
						$elm$html$Html$text('Prima'),
						A2($author$project$Common$CoverContinuity$renderTooltip, tooltipText, onTooltipHover)
					]));
		} else {
			var companyName = company.a;
			return $author$project$Common$CoverContinuity$renderCompanyContent(
				_List_fromArray(
					[
						$elm$html$Html$text(companyName)
					]));
		}
	});
var $author$project$Common$CoverContinuity$renderCompanyName = function (companyName) {
	return $author$project$Common$CoverContinuity$renderCompanyContent(
		_List_fromArray(
			[
				$elm$html$Html$text(companyName)
			]));
};
var $author$project$Translations$coversDanosProprios = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Daños propios';
	} else {
		return 'Daños propios';
	}
};
var $author$project$Pyxis$Components$IconSet$CrossCircle = 38;
var $author$project$Pyxis$Components$IconSet$crossCircle = 38;
var $author$project$Pyxis$Tokens$Color$ErrorBase = 11;
var $author$project$Pyxis$Tokens$Color$tokenErrorBase = 11;
var $author$project$Pyxis$Tokens$Color$SuccessBase = 34;
var $author$project$Pyxis$Tokens$Color$tokenSuccessBase = 34;
var $author$project$Common$CoverContinuity$coverContinuityIcon = function (thereIsCoverContinuity) {
	return thereIsCoverContinuity ? A2(
		$author$project$Pyxis$Components$Icon$withColor,
		$author$project$Pyxis$Tokens$Color$tokenSuccessBase,
		$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$checkCircle)) : A2(
		$author$project$Pyxis$Components$Icon$withColor,
		$author$project$Pyxis$Tokens$Color$tokenErrorBase,
		$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$crossCircle));
};
var $author$project$Common$CoverContinuity$renderDeclaredCover = F2(
	function (text, hasCover) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('covers__declared-cover')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeM,
						$author$project$Common$CoverContinuity$coverContinuityIcon(hasCover))),
					$elm$html$Html$text(text)
				]));
	});
var $author$project$Common$CoverContinuity$renderDanosProprios = F2(
	function (i18n, danosProprios) {
		return A2(
			$author$project$Common$CoverContinuity$renderDeclaredCover,
			$author$project$Translations$coversDanosProprios(i18n),
			danosProprios);
	});
var $author$project$Translations$coversDeclaredCoverTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Declared covers:';
	} else {
		return 'Coberturas declaradas:';
	}
};
var $author$project$Common$CoverContinuity$renderDeclaredCoverTitle = function (i18n) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('covers__subtitle')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Translations$coversDeclaredCoverTitle(i18n))
			]));
};
var $author$project$Translations$coversLunas = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Lunas';
	} else {
		return 'Lunas';
	}
};
var $author$project$Common$CoverContinuity$renderLunas = F2(
	function (i18n, lunas) {
		return A2(
			$author$project$Common$CoverContinuity$renderDeclaredCover,
			$author$project$Translations$coversLunas(i18n),
			lunas);
	});
var $author$project$Pyxis$Components$Message$Default = 0;
var $author$project$Pyxis$Components$Message$Neutral = 0;
var $author$project$Pyxis$Components$Message$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Message$defaultIcon = function (variant) {
	switch (variant) {
		case 1:
			return $author$project$Pyxis$Components$IconSet$thumbUp;
		case 3:
			return $author$project$Pyxis$Components$IconSet$checkCircle;
		case 2:
			return $author$project$Pyxis$Components$IconSet$exclamationTriangle;
		case 4:
			return $author$project$Pyxis$Components$IconSet$exclamationCircle;
		case 5:
			return $author$project$Pyxis$Components$IconSet$infoCircle;
		default:
			return $author$project$Pyxis$Components$IconSet$infoCircle;
	}
};
var $author$project$Pyxis$Components$Message$config = F2(
	function (variant, style) {
		return {
			fj: _List_Nil,
			bf: _List_Nil,
			iN: $author$project$Pyxis$Components$Message$defaultIcon(variant),
			iO: $elm$core$Maybe$Nothing,
			aO: $elm$core$Maybe$Nothing,
			hr: style,
			eO: $elm$core$Maybe$Nothing,
			hE: $elm$core$Maybe$Nothing,
			hK: variant
		};
	});
var $author$project$Pyxis$Components$Message$neutral = A2($author$project$Pyxis$Components$Message$config, 0, 0);
var $author$project$Pyxis$Components$Message$getMessageStyleClass = function (style) {
	if (style === 1) {
		return 'message--with-background-color';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Message$getMessageVariantClass = function (variant) {
	switch (variant) {
		case 1:
			return 'message--brand';
		case 3:
			return 'message--success';
		case 2:
			return 'message--alert';
		case 4:
			return 'message--error';
		case 5:
			return 'message--ghost';
		default:
			return '';
	}
};
var $author$project$Pyxis$Components$Message$getRole = function (variant) {
	if (variant === 4) {
		return 'alert';
	} else {
		return 'status';
	}
};
var $author$project$Pyxis$Components$Message$renderClose = function (_v0) {
	var onDismiss = _v0.aO;
	var ariaLabel = _v0.e7;
	return A2(
		$elm$html$Html$button,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('message__close'),
				$elm$html$Html$Attributes$type_('button'),
				$elm$html$Html$Events$onClick(onDismiss),
				$author$project$Pyxis$Commons$Attributes$ariaLabel(ariaLabel)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$cross))
			]));
};
var $author$project$Pyxis$Components$Message$renderTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('message__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$Message$render = function (_v0) {
	var classList = _v0.fj;
	var icon = _v0.iN;
	var id = _v0.iO;
	var testId = _v0.eO;
	var content = _v0.bf;
	var title = _v0.hE;
	var onDismiss = _v0.aO;
	var style = _v0.hr;
	var variant = _v0.hK;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('message', true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Message$getMessageStyleClass(style),
						true),
						_Utils_Tuple2(
						$author$project$Pyxis$Components$Message$getMessageVariantClass(variant),
						true),
						_Utils_Tuple2(
						'message--dismissable',
						$elm_community$maybe_extra$Maybe$Extra$isJust(onDismiss))
					])),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				$author$project$Pyxis$Commons$Attributes$role(
				$author$project$Pyxis$Components$Message$getRole(variant))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__icon')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Icon$render(
						$author$project$Pyxis$Components$Icon$config(icon))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('message__content-wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Message$renderTitle, title)),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('message__text')
							]),
						content)
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Message$renderClose, onDismiss))
			]));
};
var $author$project$Pyxis$Components$Message$withContent = F2(
	function (htmlText, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{bf: htmlText});
	});
var $author$project$Common$CoverContinuity$renderMessage = function (text) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('covers__declared-cover')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Message$render(
				A2(
					$author$project$Pyxis$Components$Message$withContent,
					_List_fromArray(
						[
							$elm$html$Html$text(text)
						]),
					$author$project$Pyxis$Components$Message$neutral))
			]));
};
var $author$project$Common$CoverContinuity$renderCoverContinuityInfo = F3(
	function (i18n, onTooltipHover, coverContinuityInfo) {
		switch (coverContinuityInfo.$) {
			case 2:
				var companyName = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Common$CoverContinuity$renderCompanyName(companyName),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							$author$project$Common$CoverContinuity$renderMessage(
							$author$project$Translations$coversSummaryWithNoCoversInfo(i18n))
						]));
			case 3:
				var companyName = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Common$CoverContinuity$renderCompanyName(companyName),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							$author$project$Common$CoverContinuity$renderMessage(
							$author$project$Translations$coversSummaryPendingCoversInfo(i18n))
						]));
			case 0:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('cover__no-previous-coverage-container')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src('/assets/no-coverage.svg')
								]),
							_List_Nil),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('covers__title')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Translations$coversNoPreviousCoverageTitle(i18n))
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('cover__no-previous-coverage-description')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text(
											$author$project$Translations$coversNoPreviousCoverageDescription(i18n))
										]))
								]))
						]));
			case 1:
				var info = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Common$CoverContinuity$renderCompany,
							$author$project$Translations$bundleSlugThirdPartyOnly(i18n),
							onTooltipHover,
							info),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							$author$project$Common$CoverContinuity$renderMessage(
							$author$project$Translations$coversTercerosBasicoSummaryInfo(i18n))
						]));
			case 4:
				var info = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Common$CoverContinuity$renderCompany,
							$author$project$Translations$bundleSlugThirdPartyExtended(i18n),
							onTooltipHover,
							info.T),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							A2($author$project$Common$CoverContinuity$renderLunas, i18n, info.as)
						]));
			case 5:
				var info = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Common$CoverContinuity$renderCompany,
							$author$project$Translations$bundleSlugComprehensiveCover(i18n),
							onTooltipHover,
							info.T),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							A2($author$project$Common$CoverContinuity$renderLunas, i18n, info.as),
							A2($author$project$Common$CoverContinuity$renderDanosProprios, i18n, info.dN)
						]));
			default:
				var info = coverContinuityInfo.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A3(
							$author$project$Common$CoverContinuity$renderCompany,
							$author$project$Translations$bundleSlugComprehensiveCover(i18n),
							onTooltipHover,
							info.T),
							$author$project$Common$CoverContinuity$renderDeclaredCoverTitle(i18n),
							$elm$html$Html$text(info.fe)
						]));
		}
	});
var $author$project$Common$CoverContinuity$render = F3(
	function (i18n, onTooltipHover, coverContinuity) {
		var _v0 = function () {
			if (!coverContinuity.$) {
				var info = coverContinuity.a;
				return _Utils_Tuple2(
					$author$project$Translations$detailsCurrentInsurerLabel(i18n),
					info);
			} else {
				var info = coverContinuity.a;
				return _Utils_Tuple2(
					$author$project$Translations$detailsPreviousInsurer(i18n),
					info);
			}
		}();
		var badgeText = _v0.a;
		var continuityInfo = _v0.b;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Common$CoverContinuity$renderBadge, continuityInfo, badgeText),
					A3($author$project$Common$CoverContinuity$renderCoverContinuityInfo, i18n, onTooltipHover, continuityInfo)
				]));
	});
var $author$project$Pyxis$Components$Badge$BrandGradient = 7;
var $author$project$Pyxis$Components$Badge$brandGradient = $author$project$Pyxis$Components$Badge$config(7);
var $author$project$Translations$coversPolicyIncludes = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy includes:';
	} else {
		return 'Coberturas incluidas:';
	}
};
var $author$project$Translations$coversSelectedCoverage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Cover selected';
	} else {
		return 'Cobertura seleccionada';
	}
};
var $author$project$Pyxis$Components$Icon$default = $author$project$Pyxis$Components$Icon$Default;
var $author$project$Common$CoverPackages$renderCover = function (cover) {
	return A2(
		$elm$html$Html$li,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('covers__cover')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withColor,
					$author$project$Pyxis$Tokens$Color$tokenBrandBase,
					A2(
						$author$project$Pyxis$Components$Icon$withStyle,
						$author$project$Pyxis$Components$Icon$default,
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Icon$sizeM,
							$author$project$Pyxis$Components$Icon$config(cover.iN))))),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(cover.ig)
					]))
			]));
};
var $author$project$Common$CoverPackages$renderTooltip = F2(
	function (tooltipText, onHover) {
		return A3(
			$author$project$Pyxis$Components$Tooltip$render,
			onHover,
			$author$project$Pyxis$Components$Tooltip$init('package-cover-tooltip'),
			A2(
				$author$project$Pyxis$Components$Tooltip$withContent,
				$elm$html$Html$text(tooltipText),
				A2($author$project$Pyxis$Components$Tooltip$neutral, 'default', false)));
	});
var $author$project$Common$CoverPackages$SelectedPackage = F3(
	function (packageName, covers, tooltipText) {
		return {ih: covers, jk: packageName, j1: tooltipText};
	});
var $author$project$Translations$coversThirdPartyExtendedSubtitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Protect yourself against unforeseen events by adding glass, theft and fire coverage.';
	} else {
		return 'Protégete ante imprevistos añadiendo la cobertura de lunas, robo e incendio.';
	}
};
var $author$project$Common$CoverPackages$Cover = F2(
	function (coverName, icon) {
		return {ig: coverName, iN: icon};
	});
var $author$project$Pyxis$Components$IconSet$Car = 18;
var $author$project$Pyxis$Components$IconSet$car = 18;
var $author$project$Translations$coversCoverageCivilLiability = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Mandatory and voluntary civil liability';
	} else {
		return 'Responsabilidad civil obligatoria y voluntaria';
	}
};
var $author$project$Translations$coversCoverageDriverProtection = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Third party damage';
	} else {
		return 'Protección al conductor';
	}
};
var $author$project$Translations$coversCoverageFreeWorkshopChoice = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Free choice of workshop';
	} else {
		return 'Libre elección de taller';
	}
};
var $author$project$Translations$coversCoverageLegalDefense = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Legal defense and damage claims';
	} else {
		return 'Defensa jurídica y reclamación de daños';
	}
};
var $author$project$Translations$coversCoverageRoadsideAssistance = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return '24h. roadside assistance with no km limit';
	} else {
		return 'Asistencia en viaje 24 h sin límite de km';
	}
};
var $author$project$Pyxis$Components$IconSet$VehicleLegalProtection = 157;
var $author$project$Pyxis$Components$IconSet$vehicleLegalProtection = 157;
var $author$project$Pyxis$Components$IconSet$VehicleRoadsideAssistance = 160;
var $author$project$Pyxis$Components$IconSet$vehicleRoadsideAssistance = 160;
var $author$project$Pyxis$Components$IconSet$Wrench = 166;
var $author$project$Pyxis$Components$IconSet$wrench = 166;
var $author$project$Common$CoverPackages$baseCoverPackage = function (i18n) {
	return _List_fromArray(
		[
			A2(
			$author$project$Common$CoverPackages$Cover,
			$author$project$Translations$coversCoverageCivilLiability(i18n),
			$author$project$Pyxis$Components$IconSet$vehicleLegalProtection),
			A2(
			$author$project$Common$CoverPackages$Cover,
			$author$project$Translations$coversCoverageRoadsideAssistance(i18n),
			$author$project$Pyxis$Components$IconSet$vehicleRoadsideAssistance),
			A2(
			$author$project$Common$CoverPackages$Cover,
			$author$project$Translations$coversCoverageFreeWorkshopChoice(i18n),
			$author$project$Pyxis$Components$IconSet$wrench),
			A2(
			$author$project$Common$CoverPackages$Cover,
			$author$project$Translations$coversCoverageDriverProtection(i18n),
			$author$project$Pyxis$Components$IconSet$car),
			A2(
			$author$project$Common$CoverPackages$Cover,
			$author$project$Translations$coversCoverageLegalDefense(i18n),
			$author$project$Pyxis$Components$IconSet$vehicleLegalProtection)
		]);
};
var $author$project$Translations$coversCoverageMisfortunes = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Glass, theft, fire';
	} else {
		return 'Lunas, robo, incendio';
	}
};
var $author$project$Pyxis$Components$IconSet$VehicleDriverInjuries = 154;
var $author$project$Pyxis$Components$IconSet$vehicleDriverInjuries = 154;
var $author$project$Common$CoverPackages$tercerosAmpliadoCoverPackage = function (i18n) {
	return A2(
		$elm$core$List$append,
		$author$project$Common$CoverPackages$baseCoverPackage(i18n),
		_List_fromArray(
			[
				A2(
				$author$project$Common$CoverPackages$Cover,
				$author$project$Translations$coversCoverageMisfortunes(i18n),
				$author$project$Pyxis$Components$IconSet$vehicleDriverInjuries)
			]));
};
var $author$project$Common$CoverPackages$tercerosAmpliadoPackage = function (i18n) {
	return A3(
		$author$project$Common$CoverPackages$SelectedPackage,
		$author$project$Translations$bundleSlugThirdPartyExtended(i18n),
		$author$project$Common$CoverPackages$tercerosAmpliadoCoverPackage(i18n),
		$author$project$Translations$coversThirdPartyExtendedSubtitle(i18n));
};
var $author$project$Translations$coversThirdPartyBasicSubtitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Get a powerful third party insurance with top quality travel assistance.';
	} else {
		return 'Obtén un seguro a terceros potente, con asistencia en viaje de primera calidad.';
	}
};
var $author$project$Common$CoverPackages$tercerosBasicoPackage = function (i18n) {
	return A3(
		$author$project$Common$CoverPackages$SelectedPackage,
		$author$project$Translations$bundleSlugThirdPartyOnly(i18n),
		$author$project$Common$CoverPackages$baseCoverPackage(i18n),
		$author$project$Translations$coversThirdPartyBasicSubtitle(i18n));
};
var $author$project$Translations$coversComprehensiveSubtitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Drive worry-free with our most complete insurance.';
	} else {
		return 'Conduce libre de preocupaciones con nuestro seguro más completo.';
	}
};
var $author$project$Translations$coversCoveragePersonalDamage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Personal damage up to €200,00';
	} else {
		return 'Daños propios con franquicia 200€';
	}
};
var $author$project$Pyxis$Components$IconSet$HomeTheftValuable = 82;
var $author$project$Pyxis$Components$IconSet$homeTheftValuable = 82;
var $author$project$Common$CoverPackages$tercerosTodoRiesgoCoverPackage = function (i18n) {
	return A2(
		$elm$core$List$append,
		$author$project$Common$CoverPackages$tercerosAmpliadoCoverPackage(i18n),
		_List_fromArray(
			[
				A2(
				$author$project$Common$CoverPackages$Cover,
				$author$project$Translations$coversCoveragePersonalDamage(i18n),
				$author$project$Pyxis$Components$IconSet$homeTheftValuable)
			]));
};
var $author$project$Common$CoverPackages$tercerosTodoRiesgoConFranquicia = function (i18n) {
	return A3(
		$author$project$Common$CoverPackages$SelectedPackage,
		$author$project$Translations$bundleSlugComprehensiveCover(i18n),
		$author$project$Common$CoverPackages$tercerosTodoRiesgoCoverPackage(i18n),
		$author$project$Translations$coversComprehensiveSubtitle(i18n));
};
var $author$project$Common$CoverPackages$renderCurrentPackage = F3(
	function (i18n, onTooltipHover, productSlug) {
		var _v0 = function () {
			switch (productSlug) {
				case 0:
					return $author$project$Common$CoverPackages$tercerosBasicoPackage(i18n);
				case 1:
					return $author$project$Common$CoverPackages$tercerosAmpliadoPackage(i18n);
				default:
					return $author$project$Common$CoverPackages$tercerosTodoRiesgoConFranquicia(i18n);
			}
		}();
		var packageName = _v0.jk;
		var covers = _v0.ih;
		var tooltipText = _v0.j1;
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Badge$render(
					A2(
						$author$project$Pyxis$Components$Badge$withText,
						$author$project$Translations$coversSelectedCoverage(i18n),
						$author$project$Pyxis$Components$Badge$brandGradient)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('covers__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(packageName),
							A2($author$project$Common$CoverPackages$renderTooltip, tooltipText, onTooltipHover)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('covers__subtitle')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$coversPolicyIncludes(i18n))
						])),
					A2(
					$elm$html$Html$ul,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('covers__list')
						]),
					A2($elm$core$List$map, $author$project$Common$CoverPackages$renderCover, covers))
				]));
	});
var $author$project$Common$Portfolio$renderCoversTab = F2(
	function (i18n, _v0) {
		var coversData = _v0.ij;
		var selectedBoundleSlug = _v0.jO;
		var onHoverTooltip = _v0.jf;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('portfolio-cover-tab-container')
				]),
			_List_fromArray(
				[
					A3($author$project$Common$CoverPackages$renderCurrentPackage, i18n, onHoverTooltip, selectedBoundleSlug),
					A3($author$project$Common$CoverContinuity$render, i18n, onHoverTooltip, coversData)
				]));
	});
var $author$project$Common$Portfolio$InnerTabsChanged = function (a) {
	return {$: 1, a: a};
};
var $author$project$Common$ErrorContainer$resourceNotFound = A2($author$project$Common$ErrorContainer$custom, 1, 'Resource not found');
var $author$project$Common$ComponentUI$LabelInfo$Config = $elm$core$Basics$identity;
var $author$project$Common$ComponentUI$LabelInfo$config = function (label) {
	return {a0: _List_Nil, d3: label, eW: $elm$core$Maybe$Nothing};
};
var $author$project$Translations$detailsClaimsActionNeededLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Princing action needed';
	} else {
		return 'Acción Pricing';
	}
};
var $author$project$Translations$detailsDocumentIdLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'DNI/NIE';
	} else {
		return 'DNI/NIE';
	}
};
var $author$project$Translations$detailsSincoInfoLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'SINCO info';
	} else {
		return 'Información de SINCO';
	}
};
var $author$project$Pyxis$Components$Text$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$TagBuilder$Tag = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$TagBuilder$p = 'p';
var $author$project$Pyxis$Tokens$FontWeight$FontWeightBook = 1;
var $author$project$Pyxis$Tokens$FontWeight$tokenBook = 1;
var $author$project$Pyxis$Tokens$TextSize$TextSizeM = 1;
var $author$project$Pyxis$Tokens$TextSize$tokenM = 1;
var $author$project$Pyxis$Components$Text$config = function (content) {
	return {fj: _List_Nil, fn: $elm$core$Maybe$Nothing, bf: content, fN: $author$project$Pyxis$Tokens$FontWeight$tokenBook, iO: $elm$core$Maybe$Nothing, gh: _List_Nil, hf: _List_Nil, ax: $author$project$Pyxis$Tokens$TextSize$tokenM, hy: $author$project$Pyxis$Commons$TagBuilder$p, eO: $elm$core$Maybe$Nothing};
};
var $author$project$Common$ComponentUI$LabelInfo$defaultValue = '-';
var $elm_community$maybe_extra$Maybe$Extra$filter = F2(
	function (f, m) {
		if (!m.$) {
			var a = m.a;
			return f(a) ? m : $elm$core$Maybe$Nothing;
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Common$DefaultValues$isDefaultString = function (value) {
	return _Utils_eq(value, $author$project$Common$DefaultValues$string);
};
var $author$project$Pyxis$Tokens$FontWeight$toString = function (fontWeight) {
	if (!fontWeight) {
		return 'bold';
	} else {
		return 'book';
	}
};
var $author$project$Pyxis$Tokens$TextSize$toString = function (textSize) {
	switch (textSize) {
		case 0:
			return 'l';
		case 1:
			return 'm';
		default:
			return 's';
	}
};
var $author$project$Pyxis$Components$Text$baseClass = F2(
	function (size, fontWeight) {
		return $elm$html$Html$Attributes$class(
			A2(
				$elm$core$String$join,
				'-',
				_List_fromArray(
					[
						'text',
						$author$project$Pyxis$Tokens$TextSize$toString(size),
						$author$project$Pyxis$Tokens$FontWeight$toString(fontWeight)
					])));
	});
var $author$project$Pyxis$Commons$Attributes$colorClass = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Tokens$Color$toString,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$Basics$append('c-'),
		$elm$html$Html$Attributes$class));
var $author$project$Pyxis$Tokens$Spacing$toString = function (spacing) {
	switch (spacing) {
		case 0:
			return '0';
		case 1:
			return '5xl';
		case 2:
			return '4xl';
		case 3:
			return '3xl';
		case 4:
			return '2xl';
		case 5:
			return 'xl';
		case 6:
			return 'l';
		case 7:
			return 'm';
		case 8:
			return 's';
		case 9:
			return 'xs';
		case 10:
			return '2xs';
		case 11:
			return '3xs';
		default:
			return '4xs';
	}
};
var $author$project$Pyxis$Commons$Attributes$Margin$toClass = function (margin) {
	switch (margin.$) {
		case 0:
			var s = margin.a;
			return 'margin-t-' + $author$project$Pyxis$Tokens$Spacing$toString(s);
		case 1:
			var s = margin.a;
			return 'margin-b-' + $author$project$Pyxis$Tokens$Spacing$toString(s);
		case 2:
			var s = margin.a;
			return 'margin-l-' + $author$project$Pyxis$Tokens$Spacing$toString(s);
		default:
			var s = margin.a;
			return 'margin-r-' + $author$project$Pyxis$Tokens$Spacing$toString(s);
	}
};
var $author$project$Pyxis$Components$Text$marginClassList = function (margins) {
	return $elm$html$Html$Attributes$classList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Commons$Attributes$Margin$toClass,
				A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Tuple$pair, true)),
			margins));
};
var $author$project$Pyxis$Commons$Responsive$pickBreakpoint = function (_v0) {
	var breakpoint = _v0.aD;
	return breakpoint;
};
var $author$project$Pyxis$Commons$Responsive$pickPropertyValue = function (_v0) {
	var propertyValue = _v0.aS;
	return propertyValue;
};
var $author$project$Pyxis$Tokens$Breakpoint$toString = function (breakpoint) {
	switch (breakpoint) {
		case 0:
			return 'base';
		case 1:
			return '2xs';
		case 2:
			return 'xs';
		case 3:
			return 's';
		case 4:
			return 'm';
		case 5:
			return 'l';
		default:
			return 'xl';
	}
};
var $author$project$Pyxis$Components$Text$responsiveSizeClass = function (responsiveSize) {
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'text',
				$author$project$Pyxis$Tokens$TextSize$toString(
				$author$project$Pyxis$Commons$Responsive$pickPropertyValue(responsiveSize)),
				'from',
				$author$project$Pyxis$Tokens$Breakpoint$toString(
				$author$project$Pyxis$Commons$Responsive$pickBreakpoint(responsiveSize))
			]));
};
var $author$project$Pyxis$Components$Text$responsiveSizeClassList = function (responsiveSizes) {
	return $elm$html$Html$Attributes$classList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Text$responsiveSizeClass,
				A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Tuple$pair, true)),
			responsiveSizes));
};
var $author$project$Pyxis$Commons$TagBuilder$unwrap = function (_v0) {
	var nodeName = _v0;
	return nodeName;
};
var $author$project$Pyxis$Components$Text$render = function (_v0) {
	var content = _v0.bf;
	var margins = _v0.gh;
	var id = _v0.iO;
	var classList = _v0.fj;
	var testId = _v0.eO;
	var color = _v0.fn;
	var tag = _v0.hy;
	var fontWeight = _v0.fN;
	var size = _v0.ax;
	var responsiveSize = _v0.hf;
	return A3(
		$elm$html$Html$node,
		$author$project$Pyxis$Commons$TagBuilder$unwrap(tag),
		_List_fromArray(
			[
				A2($author$project$Pyxis$Components$Text$baseClass, size, fontWeight),
				$author$project$Pyxis$Components$Text$responsiveSizeClassList(responsiveSize),
				$author$project$Pyxis$Components$Text$marginClassList(margins),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$colorClass, color)
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Common$ComponentUI$LabelInfo$renderAction = F2(
	function (value, userAction) {
		if (userAction.$ === 1) {
			return $author$project$Pyxis$Commons$Render$empty;
		} else {
			var icon = userAction.a;
			var act = userAction.b;
			return $author$project$Pyxis$Components$Button$render(
				A2(
					$author$project$Pyxis$Components$Button$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('label-info__action', true),
							_Utils_Tuple2('feature-flag--changeEmail', true)
						]),
					A2(
						$author$project$Pyxis$Components$Button$withSize,
						$author$project$Pyxis$Components$Button$sizeS,
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							act(value),
							A2($author$project$Pyxis$Components$Button$withIconOnly, icon, $author$project$Pyxis$Components$Button$ghost)))));
		}
	});
var $author$project$Common$ComponentUI$LabelInfo$renderActions = F2(
	function (actions, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('label-info__actions')
				]),
			A2(
				$elm$core$List$map,
				$author$project$Common$ComponentUI$LabelInfo$renderAction(value),
				actions));
	});
var $author$project$Pyxis$Tokens$FontWeight$FontWeightBold = 0;
var $author$project$Pyxis$Tokens$FontWeight$tokenBold = 0;
var $author$project$Pyxis$Tokens$Color$Neutral25 = 16;
var $author$project$Pyxis$Tokens$Color$tokenNeutral25 = 16;
var $author$project$Pyxis$Tokens$Color$NeutralBase = 22;
var $author$project$Pyxis$Tokens$Color$tokenNeutralBase = 22;
var $author$project$Pyxis$Tokens$TextSize$TextSizeS = 2;
var $author$project$Pyxis$Tokens$TextSize$tokenS = 2;
var $author$project$Pyxis$Components$Text$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Text$withColor = F2(
	function (color, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fn: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Pyxis$Components$Text$withFontWeight = F2(
	function (fontWeight, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fN: fontWeight});
	});
var $author$project$Pyxis$Components$Text$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Common$ComponentUI$LabelInfo$render = function (_v0) {
	var label = _v0.d3;
	var value = _v0.eW;
	var actions = _v0.a0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('label-info', true),
						_Utils_Tuple2(
						'label-info--with-action',
						$elm_community$maybe_extra$Maybe$Extra$isJust(value) && (!$elm$core$List$isEmpty(actions)))
					]))
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Text$render(
				A2(
					$author$project$Pyxis$Components$Text$withFontWeight,
					$author$project$Pyxis$Tokens$FontWeight$tokenBook,
					A2(
						$author$project$Pyxis$Components$Text$withColor,
						$author$project$Pyxis$Tokens$Color$tokenNeutral25,
						A2(
							$author$project$Pyxis$Components$Text$withSize,
							$author$project$Pyxis$Tokens$TextSize$tokenS,
							A2(
								$author$project$Pyxis$Components$Text$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('label-info__title', true)
									]),
								$author$project$Pyxis$Components$Text$config(
									$elm$html$Html$text(label))))))),
				$author$project$Pyxis$Components$Text$render(
				A2(
					$author$project$Pyxis$Components$Text$withFontWeight,
					$author$project$Pyxis$Tokens$FontWeight$tokenBold,
					A2(
						$author$project$Pyxis$Components$Text$withColor,
						$author$project$Pyxis$Tokens$Color$tokenNeutralBase,
						A2(
							$author$project$Pyxis$Components$Text$withSize,
							$author$project$Pyxis$Tokens$TextSize$tokenM,
							A2(
								$author$project$Pyxis$Components$Text$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('label-info__value', true)
									]),
								$author$project$Pyxis$Components$Text$config(
									$elm$html$Html$text(
										A2(
											$elm$core$Maybe$withDefault,
											$author$project$Common$ComponentUI$LabelInfo$defaultValue,
											A2(
												$elm_community$maybe_extra$Maybe$Extra$filter,
												A2($elm$core$Basics$composeR, $author$project$Common$DefaultValues$isDefaultString, $elm$core$Basics$not),
												value))))))))),
				A2(
				$elm$core$Maybe$withDefault,
				$author$project$Pyxis$Commons$Render$empty,
				A2(
					$elm$core$Maybe$map,
					$author$project$Common$ComponentUI$LabelInfo$renderActions(actions),
					value))
			]));
};
var $author$project$Translations$detailsPricingActionUnknownMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The information could not be retrieved';
	} else {
		return 'No se pudo recuperar la información';
	}
};
var $author$project$Common$PricingAction$toString = F2(
	function (i18n, pricingAction) {
		if (!pricingAction.$) {
			var value = pricingAction.a;
			return value;
		} else {
			return $author$project$Translations$detailsPricingActionUnknownMessage(i18n);
		}
	});
var $author$project$Common$ComponentUI$LabelInfo$withValue = F2(
	function (value, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eW: $elm$core$Maybe$Just(value)
			});
	});
var $author$project$Common$ClaimHistory$firstColumnElements = F2(
	function (i18n, claimHistory) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					claimHistory.iO,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsDocumentIdLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					claimHistory.jR,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsSincoInfoLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					A2($author$project$Common$PricingAction$toString, i18n, claimHistory.eZ),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsClaimsActionNeededLabel(i18n))))
			]);
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$ColumnedDetailsBox = $elm$core$Basics$identity;
var $author$project$Common$ComponentUI$ColumnedDetailsBox$ColumnedDetailsBoxData = F4(
	function (firstColumnElements, secondColumnElements, i18n, title) {
		return {iw: firstColumnElements, ln: i18n, jJ: secondColumnElements, hE: title};
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$new = F3(
	function (firstColumnElements, secondColumnElements, i18n) {
		return A4($author$project$Common$ComponentUI$ColumnedDetailsBox$ColumnedDetailsBoxData, firstColumnElements, secondColumnElements, i18n, $elm$core$Maybe$Nothing);
	});
var $author$project$Translations$detailsClaimsDeclaredBySincoLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Claims declared by SINCO';
	} else {
		return 'Siniestros declarados por SINCO';
	}
};
var $author$project$Translations$detailsClaimsDeclaredWithFaultByCustomerLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'At-fault claims declared by the customer';
	} else {
		return 'Siniestros con culpa declarados por el cliente';
	}
};
var $author$project$Translations$detailsSincoNumberLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'SINCO number';
	} else {
		return 'Últimos cinco dígitos de la póliza actual';
	}
};
var $author$project$Translations$detailsClaimsCountApproximationSuffix = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'or more';
	} else {
		return 'o más';
	}
};
var $author$project$Common$Count$toString = F2(
	function (i18n, count) {
		switch (count.$) {
			case 0:
				var n = count.a;
				return $elm$core$String$fromInt(n);
			case 1:
				var n = count.a;
				return A2(
					$elm$core$String$join,
					' ',
					_List_fromArray(
						[
							$elm$core$String$fromInt(n),
							$author$project$Translations$detailsClaimsCountApproximationSuffix(i18n)
						]));
			default:
				return '';
		}
	});
var $author$project$Common$ComponentUI$LabelInfo$withMaybeValue = F2(
	function (value, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{eW: value});
	});
var $author$project$Common$ClaimHistory$secondColumnElements = F2(
	function (i18n, claimHistory) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					claimHistory.jS,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsSincoNumberLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					A2($author$project$Common$Count$toString, i18n, claimHistory.fh),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsClaimsDeclaredWithFaultByCustomerLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withMaybeValue,
					A2($elm$core$Maybe$map, $elm$core$String$fromInt, claimHistory.h2),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsClaimsDeclaredBySincoLabel(i18n))))
			]);
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$renderDataDetails = function (columnedDetailsBoxData) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('column-info__details')
				]),
			columnedDetailsBoxData.iw),
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('column-info__details')
				]),
			columnedDetailsBoxData.jJ)
		]);
};
var $elm_community$html_extra$Html$Extra$viewMaybe = F2(
	function (fn, maybeThing) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm_community$html_extra$Html$Extra$nothing,
			A2($elm$core$Maybe$map, fn, maybeThing));
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$renderTitle = function (label) {
	return A2(
		$elm_community$html_extra$Html$Extra$viewMaybe,
		A2(
			$elm$core$Basics$composeR,
			$elm$html$Html$text,
			A2(
				$elm$core$Basics$composeR,
				$elm$core$List$singleton,
				$elm$html$Html$div(
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('column-info__label')
						])))),
		label);
};
var $author$project$Common$ComponentUI$ColumnedDetailsBox$view = function (_v0) {
	var columnedDetailsBoxData = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('column-info')
			]),
		A2(
			$elm$core$List$cons,
			$author$project$Common$ComponentUI$ColumnedDetailsBox$renderTitle(columnedDetailsBoxData.hE),
			$author$project$Common$ComponentUI$ColumnedDetailsBox$renderDataDetails(columnedDetailsBoxData)));
};
var $author$project$Common$ClaimHistory$view = F2(
	function (i18n, claimHistory) {
		return $author$project$Common$ComponentUI$ColumnedDetailsBox$view(
			A3(
				$author$project$Common$ComponentUI$ColumnedDetailsBox$new,
				A2($author$project$Common$ClaimHistory$firstColumnElements, i18n, claimHistory),
				A2($author$project$Common$ClaimHistory$secondColumnElements, i18n, claimHistory),
				i18n));
	});
var $author$project$Common$Portfolio$renderClaimHistoryTab = F2(
	function (i18n, remoteData) {
		switch (remoteData.$) {
			case 3:
				if (!remoteData.a.$) {
					var claimHistory = remoteData.a.a;
					return A2($author$project$Common$ClaimHistory$view, i18n, claimHistory);
				} else {
					var _v1 = remoteData.a;
					return $author$project$Common$ErrorContainer$resourceNotFound;
				}
			case 1:
				return $author$project$Pyxis$Components$Loaders$Loader$render($author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM);
			case 2:
				return $author$project$Common$ErrorContainer$generic;
			default:
				return $author$project$Common$ErrorContainer$resourceNotFound;
		}
	});
var $author$project$Pyxis$Components$Tabs$Tab = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Tabs$tab = {dp: $elm$core$Maybe$Nothing, bf: _List_Nil, iN: $elm$core$Maybe$Nothing, d3: $elm$core$Maybe$Nothing, nB: ''};
var $author$project$Common$Portfolio$tabContent = $elm$html$Html$div(
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('multi-info-container__tab-content')
		]));
var $author$project$Pyxis$Components$Tabs$withContent = F2(
	function (content, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{bf: content});
	});
var $author$project$Pyxis$Components$Tabs$withText = F2(
	function (text, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{nB: text});
	});
var $author$project$Common$Portfolio$tabContainer = F2(
	function (label, content) {
		return A2(
			$author$project$Pyxis$Components$Tabs$withContent,
			_List_fromArray(
				[
					$author$project$Common$Portfolio$tabContent(content)
				]),
			A2($author$project$Pyxis$Components$Tabs$withText, label, $author$project$Pyxis$Components$Tabs$tab));
	});
var $author$project$Common$Portfolio$claimHistoryTab = F3(
	function (i18n, label, claimsHistoryData) {
		return A2(
			$author$project$Common$Portfolio$tabContainer,
			label,
			$elm$core$List$singleton(
				A2($author$project$Common$Portfolio$renderClaimHistoryTab, i18n, claimsHistoryData)));
	});
var $author$project$Translations$detailsAdditionalDriverLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Additional driver';
	} else {
		return 'Conductor adicional';
	}
};
var $author$project$Translations$detailsClaimHistoryLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Claims history';
	} else {
		return 'Historial de siniestros';
	}
};
var $author$project$Translations$detailsDriversLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Drivers';
	} else {
		return 'Conductores';
	}
};
var $author$project$Translations$detailsMainDriverLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Main driver';
	} else {
		return 'Conductor principal';
	}
};
var $author$project$Translations$detailsPolicyholderLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policyholder';
	} else {
		return 'Tomador del seguro';
	}
};
var $author$project$Translations$detailsVehicleLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle';
	} else {
		return 'Coche';
	}
};
var $author$project$Translations$detailsVehicleOwnerLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle Owner';
	} else {
		return 'Propietario del vehículo';
	}
};
var $author$project$Common$DefaultValues$isDefaultDate = function (value) {
	return _Utils_eq(value, $author$project$Common$DefaultValues$date);
};
var $author$project$Common$Person$isEmptyAdditionalDriver = function (person) {
	if (person.$ === 1) {
		var firstName = person.a.aq;
		var lastName = person.a.at;
		var documentId = person.a.ai;
		var dateOfBirth = person.a.ag;
		return $author$project$Common$DefaultValues$isDefaultString(firstName) && ($author$project$Common$DefaultValues$isDefaultString(lastName) && ($author$project$Common$DefaultValues$isDefaultString(documentId) && $author$project$Common$DefaultValues$isDefaultDate(dateOfBirth)));
	} else {
		return false;
	}
};
var $author$project$Common$Person$appendToColumn = function (column) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			function (_v0) {
				var key = _v0.a;
				var value = _v0.b;
				return $author$project$Common$ComponentUI$LabelInfo$render(
					A2(
						$author$project$Common$ComponentUI$LabelInfo$withValue,
						value,
						$author$project$Common$ComponentUI$LabelInfo$config(key)));
			}),
		$elm$core$List$append(column));
};
var $author$project$Translations$detailsDateOfBirthLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Date of birth';
	} else {
		return 'Fecha de nacimiento';
	}
};
var $author$project$Translations$detailsFirstNameLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'First name';
	} else {
		return 'Nombre';
	}
};
var $author$project$Translations$detailsLastNameLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Last name';
	} else {
		return 'Apellido';
	}
};
var $author$project$Translations$drivingLicenseDateLessThanOneYearText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Less than 1 year old';
	} else {
		return 'Menos de 1 año';
	}
};
var $author$project$Translations$drivingLicenseDateLessThanTwoYearsText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Less than 2 years old';
	} else {
		return 'Menos de 2 años';
	}
};
var $author$project$Translations$drivingLicenseDateMoreThanTwoYearsOld = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'More than 2 years old';
	} else {
		return 'Más de 2 años';
	}
};
var $author$project$Translations$drivingLicenseDateOneToThreeYearsOld = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return '1 to 3 years old';
	} else {
		return '1 a 3 años';
	}
};
var $author$project$Common$Person$additionalDriverDrivingLicenseText = F2(
	function (i18n, drivingLicenseYears) {
		switch (drivingLicenseYears) {
			case 0:
				return $author$project$Translations$drivingLicenseDateLessThanOneYearText(i18n);
			case 1:
				return $author$project$Translations$drivingLicenseDateLessThanTwoYearsText(i18n);
			case 2:
				return $author$project$Translations$drivingLicenseDateOneToThreeYearsOld(i18n);
			default:
				return $author$project$Translations$drivingLicenseDateMoreThanTwoYearsOld(i18n);
		}
	});
var $author$project$Common$Person$mainDriverDrivingLicenseDateText = F2(
	function (i18n, data) {
		var bracketedText = data.dZ ? $author$project$Translations$drivingLicenseDateMoreThanTwoYearsOld(i18n) : $author$project$Translations$drivingLicenseDateLessThanTwoYearsText(i18n);
		return data.dG + (' (' + (bracketedText + ')'));
	});
var $author$project$Common$Person$drivingLicenseDataText = F2(
	function (i18n, person) {
		switch (person.$) {
			case 1:
				var drivingLicenseYears = person.b;
				return A2(
					$elm$core$Maybe$map,
					$author$project$Common$Person$additionalDriverDrivingLicenseText(i18n),
					drivingLicenseYears);
			case 0:
				var drivingLicenseData = person.d;
				return A2(
					$elm$core$Maybe$map,
					$author$project$Common$Person$mainDriverDrivingLicenseDateText(i18n),
					drivingLicenseData);
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Common$Person$pickPersonData = function (person) {
	switch (person.$) {
		case 1:
			var personData = person.a;
			return personData;
		case 0:
			var personData = person.a;
			return personData;
		default:
			var personData = person.a;
			return personData;
	}
};
var $author$project$Translations$drivingLicenseDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Date of driving license';
	} else {
		return 'Fecha del carné de conducir';
	}
};
var $author$project$Common$Person$renderDrivingLicenseLabel = F2(
	function (i18n, text) {
		return $author$project$Common$ComponentUI$LabelInfo$render(
			A2(
				$author$project$Common$ComponentUI$LabelInfo$withValue,
				text,
				$author$project$Common$ComponentUI$LabelInfo$config(
					$author$project$Translations$drivingLicenseDateLabel(i18n))));
	});
var $author$project$Common$Person$firstColumnElements = F2(
	function (i18n, person) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Person$pickPersonData(person).aq,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsFirstNameLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Person$pickPersonData(person).at,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsLastNameLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Person$pickPersonData(person).ai,
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsDocumentIdLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Locale$formatDate(
						$author$project$Common$Person$pickPersonData(person).ag),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsDateOfBirthLabel(i18n)))),
				A2(
				$author$project$Common$Render$maybe,
				$author$project$Common$Person$renderDrivingLicenseLabel(i18n),
				A2($author$project$Common$Person$drivingLicenseDataText, i18n, person))
			]);
	});
var $author$project$Translations$detailsAddressLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Address';
	} else {
		return 'Dirección';
	}
};
var $author$project$Translations$detailsEmailAddressLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Email address';
	} else {
		return 'Correo electrónico';
	}
};
var $author$project$Translations$detailsPhoneNumberLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Phone number';
	} else {
		return 'Número de teléfono';
	}
};
var $author$project$Common$Address$isDefaultAddress = $elm$core$Basics$eq($author$project$Common$Address$defaultAddress);
var $author$project$Common$Person$formatAddress = function (address) {
	return $author$project$Common$Address$isDefaultAddress(address) ? '' : A2(
		$elm$core$String$join,
		', ',
		_List_fromArray(
			[address.nt, address.nu, address.m$, address.m4]));
};
var $author$project$Common$ComponentUI$LabelInfo$Action = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Common$ComponentUI$LabelInfo$Noop = {$: 1};
var $author$project$Common$ComponentUI$LabelInfo$maybeAction = function (icon) {
	return A2(
		$elm_community$maybe_extra$Maybe$Extra$unwrap,
		$author$project$Common$ComponentUI$LabelInfo$Noop,
		$author$project$Common$ComponentUI$LabelInfo$Action(icon));
};
var $author$project$Pyxis$Components$IconSet$Pen = 119;
var $author$project$Pyxis$Components$IconSet$pen = 119;
var $author$project$Common$Person$pickAddress = function (person) {
	switch (person.$) {
		case 1:
			return $author$project$Common$Address$defaultAddress;
		case 0:
			var address = person.b;
			return address;
		default:
			var address = person.b;
			return address;
	}
};
var $author$project$Common$ComponentUI$LabelInfo$withActions = F2(
	function (actions, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{a0: actions});
	});
var $author$project$Common$Person$secondColumnElements = F3(
	function (i18n, changeEmail, person) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Person$formatAddress(
						$author$project$Common$Person$pickAddress(person)),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsAddressLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withActions,
					_List_fromArray(
						[
							A2($author$project$Common$ComponentUI$LabelInfo$maybeAction, $author$project$Pyxis$Components$IconSet$pen, changeEmail)
						]),
					A2(
						$author$project$Common$ComponentUI$LabelInfo$withMaybeValue,
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.cn;
							},
							$author$project$Common$Person$pickContacts(person)),
						$author$project$Common$ComponentUI$LabelInfo$config(
							$author$project$Translations$detailsEmailAddressLabel(i18n))))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withMaybeValue,
					A2(
						$elm$core$Maybe$map,
						function ($) {
							return $.g$;
						},
						$author$project$Common$Person$pickContacts(person)),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsPhoneNumberLabel(i18n))))
			]);
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$withTitle = F2(
	function (title, _v0) {
		var columnedDetailsBoxData = _v0;
		return _Utils_update(
			columnedDetailsBoxData,
			{
				hE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Common$ComponentUI$ColumnedDetailsBox$withMaybeTitle = F2(
	function (maybeTitle, columnedDetailsBox) {
		if (!maybeTitle.$) {
			var title = maybeTitle.a;
			return A2($author$project$Common$ComponentUI$ColumnedDetailsBox$withTitle, title, columnedDetailsBox);
		} else {
			return columnedDetailsBox;
		}
	});
var $author$project$Common$Person$render = F3(
	function (i18n, person, _v0) {
		var label = _v0.d3;
		var changeEmail = _v0.fg;
		var additionalFields = _v0.e$;
		var secondColumn = A2(
			$author$project$Common$Person$appendToColumn,
			A3($author$project$Common$Person$secondColumnElements, i18n, changeEmail, person),
			additionalFields);
		return $author$project$Common$ComponentUI$ColumnedDetailsBox$view(
			A2(
				$author$project$Common$ComponentUI$ColumnedDetailsBox$withMaybeTitle,
				label,
				A3(
					$author$project$Common$ComponentUI$ColumnedDetailsBox$new,
					A2($author$project$Common$Person$firstColumnElements, i18n, person),
					secondColumn,
					i18n)));
	});
var $author$project$Common$Person$ViewConfig = $elm$core$Basics$identity;
var $author$project$Common$Person$viewConfig = {e$: _List_Nil, fg: $elm$core$Maybe$Nothing, d3: $elm$core$Maybe$Nothing};
var $author$project$Common$Person$withLabel = F2(
	function (label, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d3: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Common$Portfolio$renderPersonFromTuple = F2(
	function (i18n, _v0) {
		var label = _v0.a;
		var person = _v0.b;
		return A3(
			$author$project$Common$Person$render,
			i18n,
			person,
			A2($author$project$Common$Person$withLabel, label, $author$project$Common$Person$viewConfig));
	});
var $author$project$Common$Portfolio$multiPersonTab = F2(
	function (i18n, label) {
		return A2(
			$elm$core$Basics$composeR,
			$elm$core$List$map(
				$author$project$Common$Portfolio$renderPersonFromTuple(i18n)),
			$author$project$Common$Portfolio$tabContainer(label));
	});
var $author$project$Common$Person$withChangeEmail = F2(
	function (changeEmail, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fg: $elm$core$Maybe$Just(changeEmail)
			});
	});
var $author$project$Common$Portfolio$personTab = F4(
	function (i18n, changeEmail, label, person) {
		var maybeAddChangeEmail = A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Common$Person$withChangeEmail),
			$elm$core$Maybe$withDefault($elm$core$Basics$identity));
		return A2(
			$author$project$Common$Portfolio$tabContainer,
			label,
			$elm$core$List$singleton(
				A3(
					$author$project$Common$Person$render,
					i18n,
					person,
					A2(
						maybeAddChangeEmail,
						changeEmail,
						A2($author$project$Common$Person$withLabel, label, $author$project$Common$Person$viewConfig)))));
	});
var $author$project$Translations$detailsCustomerIdLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Customer Id';
	} else {
		return 'ID cliente';
	}
};
var $author$project$Common$Person$withAdditionalFields = F2(
	function (additionalFields, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{e$: additionalFields});
	});
var $author$project$Common$Portfolio$policyHolderTab = F5(
	function (i18n, changeEmail, label, customerId, person) {
		var maybeAddChangeEmail = A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map($author$project$Common$Person$withChangeEmail),
			$elm$core$Maybe$withDefault($elm$core$Basics$identity));
		var additionalFields = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$pair(
						$author$project$Translations$detailsCustomerIdLabel(i18n)),
					$elm$core$List$singleton),
				customerId));
		return A2(
			$author$project$Common$Portfolio$tabContainer,
			label,
			$elm$core$List$singleton(
				A3(
					$author$project$Common$Person$render,
					i18n,
					person,
					A2(
						$author$project$Common$Person$withAdditionalFields,
						additionalFields,
						A2(
							maybeAddChangeEmail,
							changeEmail,
							A2($author$project$Common$Person$withLabel, label, $author$project$Common$Person$viewConfig))))));
	});
var $author$project$Translations$detailsPlateNumberLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Plate';
	} else {
		return 'Matrícula';
	}
};
var $author$project$Translations$detailsVehicleBrandLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Brand';
	} else {
		return 'Marca';
	}
};
var $author$project$Translations$detailsVehicleMileageLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Annual KM';
	} else {
		return 'Kilometraje anual';
	}
};
var $author$project$Translations$detailsVehicleRegistrationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Registration';
	} else {
		return 'Fecha 1° matriculación';
	}
};
var $author$project$Common$Vehicle$pickBrand = function (_v0) {
	var vehicle = _v0;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[vehicle.d6, vehicle.d9, vehicle.hM]));
};
var $author$project$Common$Vehicle$pickMileage = function (_v0) {
	var vehicle = _v0;
	return vehicle.gm;
};
var $author$project$Common$Vehicle$pickPlateNumber = function (_v0) {
	var vehicle = _v0;
	return vehicle.g1;
};
var $author$project$Common$Vehicle$pickRegistrationDate = function (_v0) {
	var vehicle = _v0;
	return vehicle.hb;
};
var $author$project$Common$Vehicle$firstColumnElements = F2(
	function (i18n, vehicle) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickPlateNumber(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsPlateNumberLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickBrand(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleBrandLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickMileage(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleMileageLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickRegistrationDate(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleRegistrationDateLabel(i18n))))
			]);
	});
var $author$project$Translations$detailsVehicleAccessoriesLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Accessories';
	} else {
		return 'Accessories';
	}
};
var $author$project$Translations$detailsVehicleEnginePowerLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Power';
	} else {
		return 'Potencia';
	}
};
var $author$project$Translations$detailsVehicleFuelTypeLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Fuel type';
	} else {
		return 'Combustible';
	}
};
var $author$project$Translations$detailsVehicleUsageLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Usage';
	} else {
		return 'Uso';
	}
};
var $author$project$Common$Vehicle$enginePowerMeasureUnit = 'CV';
var $author$project$Common$Vehicle$formatEnginePower = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$String$append, ' ' + $author$project$Common$Vehicle$enginePowerMeasureUnit));
var $author$project$Common$Vehicle$pickAccessories = function (_v0) {
	var vehicle = _v0;
	return vehicle.eY;
};
var $author$project$Common$Vehicle$pickEnginePower = function (_v0) {
	var vehicle = _v0;
	return vehicle.fJ;
};
var $author$project$Common$Vehicle$pickFuelType = function (_v0) {
	var vehicle = _v0;
	return vehicle.fT;
};
var $author$project$Common$Vehicle$pickUsage = function (_v0) {
	var vehicle = _v0;
	return vehicle.hG;
};
var $author$project$Common$Vehicle$secondColumnElements = F2(
	function (i18n, vehicle) {
		return _List_fromArray(
			[
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickFuelType(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleFuelTypeLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withMaybeValue,
					A2(
						$elm$core$Maybe$map,
						$author$project$Common$Vehicle$formatEnginePower,
						$author$project$Common$Vehicle$pickEnginePower(vehicle)),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleEnginePowerLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					$author$project$Common$Vehicle$pickUsage(vehicle),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleUsageLabel(i18n)))),
				$author$project$Common$ComponentUI$LabelInfo$render(
				A2(
					$author$project$Common$ComponentUI$LabelInfo$withValue,
					A2(
						$elm$core$String$join,
						', ',
						$author$project$Common$Vehicle$pickAccessories(vehicle)),
					$author$project$Common$ComponentUI$LabelInfo$config(
						$author$project$Translations$detailsVehicleAccessoriesLabel(i18n))))
			]);
	});
var $author$project$Common$Vehicle$view = F2(
	function (i18n, vehicle) {
		return $author$project$Common$ComponentUI$ColumnedDetailsBox$view(
			A3(
				$author$project$Common$ComponentUI$ColumnedDetailsBox$new,
				A2($author$project$Common$Vehicle$firstColumnElements, i18n, vehicle),
				A2($author$project$Common$Vehicle$secondColumnElements, i18n, vehicle),
				i18n));
	});
var $author$project$Common$Portfolio$vehicleTab = F3(
	function (i18n, label, vehicle) {
		return A2(
			$author$project$Common$Portfolio$tabContainer,
			label,
			$elm$core$List$singleton(
				A2($author$project$Common$Vehicle$view, i18n, vehicle)));
	});
var $author$project$Common$Portfolio$multiInfoTabsConfig = F2(
	function (i18n, _v0) {
		var policyHolder = _v0.g2;
		var customerId = _v0.fw;
		var vehicle = _v0.hL;
		var vehicleOwner = _v0.j7;
		var mainDriver = _v0.d5;
		var additionalDriver = _v0.hS;
		var claimsHistory = _v0.h3;
		var onChangingEmail = _v0.gF;
		var additionalDriverTab = $author$project$Common$Person$isEmptyAdditionalDriver(additionalDriver) ? A4(
			$author$project$Common$Portfolio$personTab,
			i18n,
			$elm$core$Maybe$Nothing,
			$author$project$Translations$detailsMainDriverLabel(i18n),
			mainDriver) : A3(
			$author$project$Common$Portfolio$multiPersonTab,
			i18n,
			$author$project$Translations$detailsDriversLabel(i18n),
			_List_fromArray(
				[
					_Utils_Tuple2(
					$author$project$Translations$detailsMainDriverLabel(i18n),
					mainDriver),
					_Utils_Tuple2(
					$author$project$Translations$detailsAdditionalDriverLabel(i18n),
					additionalDriver)
				]));
		return _List_fromArray(
			[
				A5(
				$author$project$Common$Portfolio$policyHolderTab,
				i18n,
				onChangingEmail,
				$author$project$Translations$detailsPolicyholderLabel(i18n),
				customerId,
				policyHolder),
				A3(
				$author$project$Common$Portfolio$vehicleTab,
				i18n,
				$author$project$Translations$detailsVehicleLabel(i18n),
				vehicle),
				A4(
				$author$project$Common$Portfolio$personTab,
				i18n,
				$elm$core$Maybe$Nothing,
				$author$project$Translations$detailsVehicleOwnerLabel(i18n),
				vehicleOwner),
				additionalDriverTab,
				A3(
				$author$project$Common$Portfolio$claimHistoryTab,
				i18n,
				$author$project$Translations$detailsClaimHistoryLabel(i18n),
				claimsHistory)
			]);
	});
var $author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId = function (id) {
	return id + '-accessible-description';
};
var $author$project$Pyxis$Components$Tabs$renderAccessibleDescription = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$html$Html$text,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$List$singleton,
			$elm$html$Html$span(
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('sr-only'),
						$elm$html$Html$Attributes$id(id)
					]))));
};
var $author$project$Pyxis$Components$Tabs$Center = 1;
var $author$project$Pyxis$Components$Tabs$Pill = 1;
var $author$project$Pyxis$Components$Tabs$Right = 2;
var $author$project$Pyxis$Components$Tabs$getTablistClasses = function (_v0) {
	var variant = _v0.hK;
	var hasBorder = _v0.fZ;
	var placement = _v0.S;
	return _List_fromArray(
		[
			_Utils_Tuple2('tabs__tablist', true),
			_Utils_Tuple2('tabs__tablist--with-pills', 1 === variant),
			_Utils_Tuple2('tabs__tablist--with-border', hasBorder),
			_Utils_Tuple2('justify-content-center', placement === 1),
			_Utils_Tuple2('justify-content-flex-end', placement === 2)
		]);
};
var $author$project$Pyxis$Components$Tabs$offsetAttribute = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2(
		$elm$core$Basics$composeR,
		A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Basics$append, 'px'),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$append('--offset: '),
			$elm$html$Html$Attributes$attribute('style'))));
var $author$project$Pyxis$Components$Tabs$OnClick = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Tabs$OnKeyDown = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Pyxis$Commons$Attributes$ariaSelected = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-selected'));
var $author$project$Pyxis$Components$Tabs$getTabPanelId = function (id) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$String$fromInt,
		$elm$core$Basics$append(id + '-tabpanel-'));
};
var $author$project$Pyxis$Components$Tabs$handleKeyDown = F2(
	function (msg, key) {
		return _Utils_Tuple2(
			msg(key),
			A2(
				$elm$core$List$any,
				function (check) {
					return check(key);
				},
				_List_fromArray(
					[$author$project$Pyxis$Commons$Events$Keyboard$isArrowLeft, $author$project$Pyxis$Commons$Events$Keyboard$isArrowRight, $author$project$Pyxis$Commons$Events$Keyboard$isHome, $author$project$Pyxis$Commons$Events$Keyboard$isEnd])));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$Event = $elm$core$Basics$identity;
var $elm$json$Json$Decode$map5 = _Json_map5;
var $author$project$Pyxis$Commons$Events$Keyboard$decoder = function (tagger) {
	return A6(
		$elm$json$Json$Decode$map5,
		F5(
			function (keyCode, key, ctrlKey, shiftKey, innerHtml) {
				return tagger(
					{kL: ctrlKey, iR: innerHtml, iZ: key, i_: keyCode, no: shiftKey});
			}),
		A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int),
		A2($elm$json$Json$Decode$field, 'key', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'ctrlKey', $elm$json$Json$Decode$bool),
		A2($elm$json$Json$Decode$field, 'shiftKey', $elm$json$Json$Decode$bool),
		A2(
			$elm$json$Json$Decode$field,
			'target',
			A2($elm$json$Json$Decode$field, 'innerHTML', $elm$json$Json$Decode$string)));
};
var $elm$virtual_dom$VirtualDom$MayPreventDefault = function (a) {
	return {$: 2, a: a};
};
var $elm$html$Html$Events$preventDefaultOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayPreventDefault(decoder));
	});
var $author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn = F2(
	function (event, tagger) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			$author$project$Pyxis$Commons$Events$Keyboard$decoder(tagger));
	});
var $author$project$Pyxis$Components$Tabs$renderTabBadge = function (badge) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab__addon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Badge$render(badge)
			]));
};
var $author$project$Pyxis$Components$Tabs$renderTabIcon = function (icon) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab__icon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				$author$project$Pyxis$Components$Icon$config(icon))
			]));
};
var $author$project$Pyxis$Components$Tabs$renderTabLabel = function (label) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('tab__addon')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label)
			]));
};
var $author$project$Pyxis$Components$Tabs$renderTabLine = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('tab__line')
		]),
	_List_Nil);
var $author$project$Pyxis$Components$Tabs$renderTab = F5(
	function (tagger, _v0, _v1, index, _v2) {
		var activeIndex = _v0.P;
		var id = _v1.iO;
		var tabs = _v1.db;
		var variant = _v1.hK;
		var tabConfig = _v2;
		return A2(
			$elm$html$Html$map,
			tagger,
			A2(
				$elm$html$Html$button,
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Attributes$ariaSelected(
						_Utils_eq(activeIndex, index)),
						$author$project$Pyxis$Commons$Attributes$ariaControls(
						A2($author$project$Pyxis$Components$Tabs$getTabPanelId, id, index)),
						$author$project$Pyxis$Commons$Attributes$role('tab'),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('tab', true),
								_Utils_Tuple2('tab--pills', variant === 1)
							])),
						$elm$html$Html$Attributes$id(
						A2($author$project$Pyxis$Components$Tabs$getTabId, id, index)),
						$elm$html$Html$Attributes$type_('button'),
						$elm$html$Html$Attributes$tabindex(
						A3(
							$primait$prima_elm_extra$PrimaFunction$ifThenElse,
							_Utils_eq(activeIndex, index),
							0,
							-1)),
						$elm$html$Html$Events$onClick(
						$author$project$Pyxis$Components$Tabs$OnClick(index)),
						A2(
						$author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn,
						'keydown',
						$author$project$Pyxis$Components$Tabs$handleKeyDown(
							A2(
								$author$project$Pyxis$Components$Tabs$OnKeyDown,
								$elm$core$List$length(tabs),
								id)))
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tabs$renderTabIcon, tabConfig.iN)),
						$elm$html$Html$text(tabConfig.nB),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tabs$renderTabBadge, tabConfig.dp)),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Tabs$renderTabLabel, tabConfig.d3)),
						A2($author$project$Pyxis$Commons$Render$renderIf, !variant, $author$project$Pyxis$Components$Tabs$renderTabLine)
					])));
	});
var $author$project$Pyxis$Components$Tabs$renderTabList = F3(
	function (tagger, model, config_) {
		var accessibleDescription = config_.dl;
		var customMobileOffset = config_.fv;
		var id = config_.iO;
		var tabs = config_.db;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('tabs__tablist-wrapper'),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Tabs$offsetAttribute, customMobileOffset)
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								A2(
								$author$project$Pyxis$Commons$Attributes$renderIf,
								$elm_community$maybe_extra$Maybe$Extra$isJust(accessibleDescription),
								$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
									$author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId(id))),
								$author$project$Pyxis$Commons$Attributes$role('tablist'),
								$elm$html$Html$Attributes$classList(
								$author$project$Pyxis$Components$Tabs$getTablistClasses(config_))
							]),
						A2(
							$elm$core$List$indexedMap,
							A3($author$project$Pyxis$Components$Tabs$renderTab, tagger, model, config_),
							tabs))
					]))
			]);
	});
var $elm$html$Html$Attributes$hidden = $elm$html$Html$Attributes$boolProperty('hidden');
var $author$project$Pyxis$Components$Tabs$renderTabPanel = F4(
	function (_v0, _v1, index, _v2) {
		var activeIndex = _v0.P;
		var id = _v1.iO;
		var tabConfig = _v2;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					A2($author$project$Pyxis$Components$Tabs$getTabId, id, index)),
					$author$project$Pyxis$Commons$Attributes$role('tabpanel'),
					$elm$html$Html$Attributes$hidden(
					!_Utils_eq(activeIndex, index)),
					$elm$html$Html$Attributes$class('tabs__tab-panel'),
					$elm$html$Html$Attributes$id(
					A2($author$project$Pyxis$Components$Tabs$getTabPanelId, id, index))
				]),
			tabConfig.bf);
	});
var $author$project$Pyxis$Components$Tabs$renderTabPanelList = F2(
	function (model, config_) {
		var tabs = config_.db;
		return A2(
			$elm$core$List$indexedMap,
			A2($author$project$Pyxis$Components$Tabs$renderTabPanel, model, config_),
			tabs);
	});
var $author$project$Pyxis$Components$Tabs$render = F3(
	function (tagger, model, config_) {
		var conf = config_;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2('tabs', true),
						conf.fj)),
					$elm$html$Html$Attributes$id(conf.iO),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, conf.eO),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$author$project$Pyxis$Commons$Properties$Theme$isAlternative(conf.eP),
					$author$project$Pyxis$Commons$Attributes$theme(conf.eP))
				]),
			$elm$core$List$concat(
				_List_fromArray(
					[
						$elm$core$List$singleton(
						$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Tabs$renderAccessibleDescription(
									$author$project$Pyxis$Components$Tabs$getAccessibleDescriptionId(conf.iO)),
								conf.dl))),
						A3($author$project$Pyxis$Components$Tabs$renderTabList, tagger, model, config_),
						A2($author$project$Pyxis$Components$Tabs$renderTabPanelList, model, config_)
					])));
	});
var $author$project$Pyxis$Components$Tabs$withClassList = F2(
	function (classList, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Tabs$withTabs = F2(
	function (tabs, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{db: tabs});
	});
var $author$project$Pyxis$Components$Tabs$withTestId = F2(
	function (id, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{
				eO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Common$Portfolio$renderMultiInfo = F3(
	function (i18n, _v0, configuration) {
		var innerTabs = _v0.dQ;
		var tagger = _v0.hz;
		return A3(
			$author$project$Pyxis$Components$Tabs$render,
			A2($elm$core$Basics$composeR, $author$project$Common$Portfolio$InnerTabsChanged, tagger),
			innerTabs,
			A2(
				$author$project$Pyxis$Components$Tabs$withTabs,
				A2($author$project$Common$Portfolio$multiInfoTabsConfig, i18n, configuration),
				A2(
					$author$project$Pyxis$Components$Tabs$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('multi-info-container', true)
						]),
					A2(
						$author$project$Pyxis$Components$Tabs$withTestId,
						'multi-info',
						$author$project$Pyxis$Components$Tabs$config('')))));
	});
var $author$project$Pyxis$Components$IconSet$ShieldCheck = 131;
var $author$project$Pyxis$Components$IconSet$shieldCheck = 131;
var $author$project$Pyxis$Components$Tabs$withIcon = F2(
	function (icon, _v0) {
		var tab_ = _v0;
		return _Utils_update(
			tab_,
			{
				iN: $elm$core$Maybe$Just(icon)
			});
	});
var $author$project$Common$Portfolio$outerTabsConfig = F3(
	function (i18n, portfolio, configuration) {
		return _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Tabs$withContent,
				_List_fromArray(
					[
						A3($author$project$Common$Portfolio$renderMultiInfo, i18n, portfolio, configuration)
					]),
				A2(
					$author$project$Pyxis$Components$Tabs$withIcon,
					$author$project$Pyxis$Components$IconSet$chart,
					A2(
						$author$project$Pyxis$Components$Tabs$withText,
						$author$project$Translations$detailsPortfolioDataLabel(i18n),
						$author$project$Pyxis$Components$Tabs$tab))),
				A2(
				$author$project$Pyxis$Components$Tabs$withContent,
				_List_fromArray(
					[
						A2($author$project$Common$Portfolio$renderCoversTab, i18n, configuration)
					]),
				A2(
					$author$project$Pyxis$Components$Tabs$withIcon,
					$author$project$Pyxis$Components$IconSet$shieldCheck,
					A2(
						$author$project$Pyxis$Components$Tabs$withText,
						$author$project$Translations$detailsPortfolioCoverLabel(i18n),
						$author$project$Pyxis$Components$Tabs$tab)))
			]);
	});
var $author$project$Pyxis$Components$Tabs$pill = 1;
var $author$project$Translations$detailsPortfolioEditInformationLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Edit information';
	} else {
		return 'Edita la información';
	}
};
var $author$project$Common$Render$when = F2(
	function (condition, content) {
		return condition ? content : $author$project$Common$Render$empty;
	});
var $author$project$Pyxis$Components$Button$Append = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Button$withIconAppend = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iN: $author$project$Pyxis$Components$Button$Append(icon)
			});
	});
var $author$project$Common$Portfolio$renderEditButton = F3(
	function (i18n, isDataTabSelected, redirectUrl) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(redirectUrl),
					$elm$html$Html$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Common$Render$when,
					isDataTabSelected,
					$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withIconAppend,
							$author$project$Pyxis$Components$IconSet$pen,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeS,
								A2(
									$author$project$Pyxis$Components$Button$withClassList,
									_List_fromArray(
										[
											_Utils_Tuple2('portfolio-container__edit-button', true)
										]),
									A2(
										$author$project$Pyxis$Components$Button$withText,
										$author$project$Translations$detailsPortfolioEditInformationLabel(i18n),
										$author$project$Pyxis$Components$Button$ghost))))))
				]));
	});
var $author$project$Translations$detailsPortfolioEditCoverageLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Edit coverage';
	} else {
		return 'Edita la cobertura';
	}
};
var $author$project$Common$Portfolio$renderEditCoverageButton = F3(
	function (i18n, isCoverageTabSelected, redirectUrl) {
		return A2(
			$elm$html$Html$a,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$href(redirectUrl),
					$elm$html$Html$Attributes$target('_blank')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Common$Render$when,
					isCoverageTabSelected,
					$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withIconAppend,
							$author$project$Pyxis$Components$IconSet$pen,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeS,
								A2(
									$author$project$Pyxis$Components$Button$withClassList,
									_List_fromArray(
										[
											_Utils_Tuple2('portfolio-container__edit-button', true)
										]),
									A2(
										$author$project$Pyxis$Components$Button$withText,
										$author$project$Translations$detailsPortfolioEditCoverageLabel(i18n),
										$author$project$Pyxis$Components$Button$ghost))))))
				]));
	});
var $author$project$Pyxis$Components$Tabs$withVariant = F2(
	function (variant, _v0) {
		var conf = _v0;
		return _Utils_update(
			conf,
			{hK: variant});
	});
var $author$project$Common$Portfolio$render = F3(
	function (i18n, model, configuration) {
		var tagger = model.hz;
		var outerTabs = model.bJ;
		var editRedirectUrl = model.fF;
		var editCoverageUrl = model.fE;
		var isDataTabSelected = !$author$project$Pyxis$Components$Tabs$getActiveTabIndex(outerTabs);
		var isCoverageTabSelected = $author$project$Pyxis$Components$Tabs$getActiveTabIndex(outerTabs) === 1;
		return A2(
			$elm$html$Html$section,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('portfolio-container padding-xl radius-xl bg-white'),
					$elm$html$Html$Attributes$id('portfolio-container')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Common$Render$maybe,
					A2($author$project$Common$Portfolio$renderEditButton, i18n, isDataTabSelected),
					editRedirectUrl),
					A2(
					$author$project$Common$Render$maybe,
					A2($author$project$Common$Portfolio$renderEditCoverageButton, i18n, isCoverageTabSelected),
					editCoverageUrl),
					A3(
					$author$project$Pyxis$Components$Tabs$render,
					A2($elm$core$Basics$composeR, $author$project$Common$Portfolio$OuterTabsChanged, tagger),
					outerTabs,
					A2(
						$author$project$Pyxis$Components$Tabs$withTabs,
						A3($author$project$Common$Portfolio$outerTabsConfig, i18n, model, configuration),
						A2(
							$author$project$Pyxis$Components$Tabs$withVariant,
							$author$project$Pyxis$Components$Tabs$pill,
							A2(
								$author$project$Pyxis$Components$Tabs$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('portfolio-tabs-group', true)
									]),
								A2(
									$author$project$Pyxis$Components$Tabs$withTestId,
									'portfolio-tabs-group',
									$author$project$Pyxis$Components$Tabs$config('porfolio-tabs-group'))))))
				]));
	});
var $justinmimbs$date$Date$fromOrdinalDate = F2(
	function (y, od) {
		var daysInYear = $justinmimbs$date$Date$isLeapYear(y) ? 366 : 365;
		return $justinmimbs$date$Date$daysBeforeYear(y) + A3($elm$core$Basics$clamp, 1, daysInYear, od);
	});
var $elm_community$maybe_extra$Maybe$Extra$unpack = F3(
	function (_default, f, m) {
		if (m.$ === 1) {
			return _default(0);
		} else {
			var a = m.a;
			return f(a);
		}
	});
var $author$project$Common$TimeHelper$tomorrow = function (currentDate) {
	return A3(
		$elm_community$maybe_extra$Maybe$Extra$unpack,
		function (_v0) {
			return A2($justinmimbs$date$Date$fromOrdinalDate, 1970, 1);
		},
		function (today) {
			return A3($justinmimbs$date$Date$add, 3, 1, today);
		},
		currentDate);
};
var $author$project$Common$ComponentUI$DetailsResultBox$Offer = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$Common$ComponentUI$DetailsResultBox$newOfferViewConfiguration = F4(
	function (i18n, currentDate, actions, offer) {
		return A2(
			$author$project$Common$ComponentUI$DetailsResultBox$Offer,
			{
				ln: i18n,
				aR: $author$project$Common$Offer$totalPrice(offer)
			},
			{fu: currentDate, C: offer, gC: actions.gB, gI: actions.gH, gT: actions.gS});
	});
var $author$project$Pyxis$Components$Message$Alert = 2;
var $author$project$Pyxis$Components$Message$ColoredBackground = 1;
var $author$project$Pyxis$Components$Message$alert = A2($author$project$Pyxis$Components$Message$config, 2, 1);
var $author$project$Translations$detailsAmendBoxLink = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Click here';
	} else {
		return 'Clica aquí';
	}
};
var $author$project$Translations$detailsAmendBoxMessageDateInfo = F2(
	function (tomorrow, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'change the starting date to: ' + (tomorrow + ' 00:00 CET');
		} else {
			return 'para cambiar la fecha de entrada en vigor a: ' + (tomorrow + ' 00:00 CET');
		}
	});
var $author$project$Translations$detailsAmendBoxSubMessage = F2(
	function (strongAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('If the customer prefers a start date other than tomorrow, you must go to the '),
					A3(
					$elm$html$Html$node,
					'strong',
					_Utils_ap(_List_Nil, strongAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text('Edit information')
						])),
					$elm$html$Html$text(' section to change it.')
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('Si el cliente prefiere una fecha de inicio distinta a la de mañana, debes dirigirte a la sección '),
					A3(
					$elm$html$Html$node,
					'strong',
					_Utils_ap(_List_Nil, strongAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(' Editar información')
						])),
					$elm$html$Html$text('para cambiarla.')
				]);
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renderAmendSubMessage = function (message) {
	return $author$project$Pyxis$Components$Text$render(
		A2(
			$author$project$Pyxis$Components$Text$withSize,
			$author$project$Pyxis$Tokens$TextSize$tokenM,
			$author$project$Pyxis$Components$Text$config(
				A2($elm$html$Html$div, _List_Nil, message))));
};
var $author$project$Common$ComponentUI$DetailsResultBox$tomorrowString = function (today) {
	return $author$project$Common$Locale$formatDate(
		$author$project$Common$TimeHelper$tomorrow(today));
};
var $author$project$Common$ComponentUI$DetailsResultBox$amendMessageWithLink = F3(
	function (i18n, currentDate, onClickEvent) {
		return $author$project$Pyxis$Components$Message$render(
			A2(
				$author$project$Pyxis$Components$Message$withContent,
				_List_fromArray(
					[
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('message__link'),
								$elm$html$Html$Events$onClick(onClickEvent)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$detailsAmendBoxLink(i18n))
							])),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__amend-date-message')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								' ' + A2(
									$author$project$Translations$detailsAmendBoxMessageDateInfo,
									$author$project$Common$ComponentUI$DetailsResultBox$tomorrowString(currentDate),
									i18n))
							])),
						$author$project$Common$ComponentUI$DetailsResultBox$renderAmendSubMessage(
						A2($author$project$Translations$detailsAmendBoxSubMessage, _List_Nil, i18n))
					]),
				$author$project$Pyxis$Components$Message$alert));
	});
var $author$project$Common$Render$ifCondition = F2(
	function (content, condition) {
		return condition ? content : $author$project$Common$Render$empty;
	});
var $author$project$Common$Offer$isAmendStartDateAction = function (action) {
	if (action.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Common$Offer$canBeAmended = function (_v0) {
	var offer = _v0;
	return A2($elm$core$List$any, $author$project$Common$Offer$isAmendStartDateAction, offer.aB);
};
var $author$project$Common$ComponentUI$DetailsResultBox$isOfferAmendable = function (offer) {
	return $author$project$Common$Offer$canBeAmended(offer);
};
var $author$project$Common$ComponentUI$DetailsResultBox$viewAmendOfferIfAmendable = function (config) {
	if (config.$ === 2) {
		var i18n = config.a.ln;
		var offer = config.b.C;
		var currentDate = config.b.fu;
		var onAmendStartDateAction = config.b.gC;
		return A2(
			$author$project$Common$Render$ifCondition,
			A3($author$project$Common$ComponentUI$DetailsResultBox$amendMessageWithLink, i18n, currentDate, onAmendStartDateAction),
			$author$project$Common$ComponentUI$DetailsResultBox$isOfferAmendable(offer));
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Translations$detailsInterruptionDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Interruption date';
	} else {
		return 'Fecha de cancelación';
	}
};
var $author$project$Common$Locale$formatPosixDate = F2(
	function (tz, time) {
		var date = A2($justinmimbs$date$Date$fromPosix, tz, time);
		return $author$project$Common$Locale$formatDate(date);
	});
var $author$project$Common$ComponentUI$DetailsResultBox$createPolicyInterruptionDateInfo = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.kt;
		},
		A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Locale$formatPosixDate($author$project$Common$Locale$customerTimeZone),
			$elm$core$Tuple$pair(
				$author$project$Translations$detailsInterruptionDateLabel(i18n))));
};
var $author$project$Translations$detailsBundleLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Cover selected';
	} else {
		return 'Cobertura seleccionada';
	}
};
var $author$project$Translations$detailsEndDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'End date';
	} else {
		return 'Fecha de finalización';
	}
};
var $author$project$Translations$detailsPurchaseDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchase date';
	} else {
		return 'Fecha de contratación';
	}
};
var $author$project$Translations$detailsStartDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Start date';
	} else {
		return 'Fecha de inicio';
	}
};
var $author$project$Common$Policy$pickEndDate = function (_v0) {
	var policy = _v0;
	return policy.dI;
};
var $author$project$Common$Policy$pickProducts = function (_v0) {
	var policy = _v0;
	return policy.ep;
};
var $author$project$Common$Policy$pickFirstProduct = A2($elm$core$Basics$composeL, $elm$core$List$head, $author$project$Common$Policy$pickProducts);
var $author$project$Common$Policy$pickPurchaseDate = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Locale$posixToCustomerDate(policy.g6);
};
var $author$project$Common$Policy$pickStartDate = function (_v0) {
	var policy = _v0;
	return policy.np;
};
var $author$project$Common$ComponentUI$DetailsResultBox$productName = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.kA;
		},
		$author$project$Common$Product$bundleSlugToString(i18n));
};
var $author$project$Common$ComponentUI$DetailsResultBox$generalInfoForInterruptedPolicy = F3(
	function (i18n, interruption, policy) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				$author$project$Translations$detailsBundleLabel(i18n),
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					$author$project$Common$ComponentUI$DetailsResultBox$productName(i18n),
					$author$project$Common$Policy$pickFirstProduct(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsPurchaseDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickPurchaseDate(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsStartDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickStartDate(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsEndDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickEndDate(policy))),
				A2($author$project$Common$ComponentUI$DetailsResultBox$createPolicyInterruptionDateInfo, i18n, interruption)
			]);
	});
var $author$project$Translations$detailsCreationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Creation date';
	} else {
		return 'Fecha de la oferta';
	}
};
var $author$project$Common$Offer$pickCreationDate = function (_v0) {
	var offer = _v0;
	return $author$project$Common$Locale$posixToCustomerDate(offer.fs);
};
var $author$project$Common$Offer$pickProducts = function (_v0) {
	var offer = _v0;
	return offer.ep;
};
var $author$project$Common$Offer$pickFirstProduct = A2($elm$core$Basics$composeL, $elm$core$List$head, $author$project$Common$Offer$pickProducts);
var $author$project$Common$ComponentUI$DetailsResultBox$generalInfoForOffer = F2(
	function (i18n, offer) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				$author$project$Translations$detailsBundleLabel(i18n),
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					$author$project$Common$ComponentUI$DetailsResultBox$productName(i18n),
					$author$project$Common$Offer$pickFirstProduct(offer))),
				_Utils_Tuple2(
				$author$project$Translations$detailsStartDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Offer$pickStartDate(offer))),
				_Utils_Tuple2(
				$author$project$Translations$detailsCreationDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Offer$pickCreationDate(offer)))
			]);
	});
var $author$project$Common$ComponentUI$DetailsResultBox$generalInfoForPolicy = F2(
	function (i18n, policy) {
		return _List_fromArray(
			[
				_Utils_Tuple2(
				$author$project$Translations$detailsBundleLabel(i18n),
				A3(
					$elm_community$maybe_extra$Maybe$Extra$unwrap,
					'',
					$author$project$Common$ComponentUI$DetailsResultBox$productName(i18n),
					$author$project$Common$Policy$pickFirstProduct(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsPurchaseDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickPurchaseDate(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsStartDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickStartDate(policy))),
				_Utils_Tuple2(
				$author$project$Translations$detailsEndDateLabel(i18n),
				$author$project$Common$Locale$formatDate(
					$author$project$Common$Policy$pickEndDate(policy)))
			]);
	});
var $author$project$Common$ComponentUI$DetailsResultBox$generalInfo = function (config) {
	switch (config.$) {
		case 0:
			var i18n = config.a.ln;
			var policy = config.b.j;
			return A2($author$project$Common$ComponentUI$DetailsResultBox$generalInfoForPolicy, i18n, policy);
		case 1:
			var i18n = config.a.ln;
			var policy = config.b.j;
			var interruption = config.c.cw;
			return A3($author$project$Common$ComponentUI$DetailsResultBox$generalInfoForInterruptedPolicy, i18n, interruption, policy);
		default:
			var i18n = config.a.ln;
			var offer = config.b.C;
			return A2($author$project$Common$ComponentUI$DetailsResultBox$generalInfoForOffer, i18n, offer);
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$renderLabelInfo = F2(
	function (label, value) {
		return $author$project$Common$ComponentUI$LabelInfo$render(
			A2(
				$author$project$Common$ComponentUI$LabelInfo$withValue,
				value,
				$author$project$Common$ComponentUI$LabelInfo$config(label)));
	});
var $primait$prima_elm_extra$PrimaFunction$uncurry = F2(
	function (f, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return A2(f, x, y);
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewGeneralInfoRow = A2(
	$elm$core$Basics$composeR,
	$author$project$Common$ComponentUI$DetailsResultBox$generalInfo,
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$map(
			$primait$prima_elm_extra$PrimaFunction$uncurry($author$project$Common$ComponentUI$DetailsResultBox$renderLabelInfo)),
		$elm$html$Html$div(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__general-info-row')
				]))));
var $author$project$Common$Offer$pickSource = function (_v0) {
	var offer = _v0;
	return offer.hm;
};
var $author$project$Common$Policy$pickSource = function (_v0) {
	var policy = _v0;
	return policy.hm;
};
var $author$project$Common$ComponentUI$DetailsResultBox$fetchSource = function (config) {
	switch (config.$) {
		case 0:
			var policy = config.b.j;
			return $author$project$Common$Policy$pickSource(policy);
		case 1:
			var policy = config.b.j;
			return $author$project$Common$Policy$pickSource(policy);
		default:
			var offer = config.b.C;
			return $author$project$Common$Offer$pickSource(offer);
	}
};
var $author$project$Common$Offer$isCompleteAction = function (action) {
	if (action.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Common$Offer$mayBeCompleted = function (_v0) {
	var offer = _v0;
	return A2($elm$core$List$any, $author$project$Common$Offer$isCompleteAction, offer.aB);
};
var $author$project$Translations$detailsCompleteOfferLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Complete offer';
	} else {
		return 'Completar oferta';
	}
};
var $author$project$Pyxis$Components$Button$Secondary = 1;
var $author$project$Pyxis$Components$Button$secondary = $author$project$Pyxis$Components$Button$config(1);
var $author$project$Common$ComponentUI$DetailsResultBox$viewCompleteOfferButton = F2(
	function (i18n, onCompleteAction) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				onCompleteAction,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withText,
						$author$project$Translations$detailsCompleteOfferLabel(i18n),
						$author$project$Pyxis$Components$Button$secondary))));
	});
var $author$project$Common$Offer$isPurchaseAction = function (action) {
	if (!action.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Common$Offer$canBePurchased = function (_v0) {
	var offer = _v0;
	return A2($elm$core$List$any, $author$project$Common$Offer$isPurchaseAction, offer.aB);
};
var $author$project$Translations$detailsPurchaseButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchase offer';
	} else {
		return 'Comprar Oferta';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$viewPurchaseButton = F3(
	function (i18n, onPurchasedButtonClicked, offer) {
		return A2(
			$author$project$Common$Render$ifCondition,
			$author$project$Pyxis$Components$Button$render(
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					onPurchasedButtonClicked,
					A2(
						$author$project$Pyxis$Components$Button$withType,
						$author$project$Pyxis$Components$Button$button,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$detailsPurchaseButton(i18n),
							$author$project$Pyxis$Components$Button$primary)))),
			$author$project$Common$Offer$canBePurchased(offer));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$highlightRowCTA = function (config) {
	switch (config.$) {
		case 0:
			return $author$project$Common$Render$empty;
		case 1:
			return $author$project$Common$Render$empty;
		default:
			var i18n = config.a.ln;
			var offer = config.b.C;
			var onPurchaseAction = config.b.gT;
			var onCompleteAction = config.b.gI;
			return $author$project$Common$Offer$mayBeCompleted(offer) ? A2($author$project$Common$ComponentUI$DetailsResultBox$viewCompleteOfferButton, i18n, onCompleteAction) : ($author$project$Common$Offer$canBeAmended(offer) ? $author$project$Common$Render$empty : A3($author$project$Common$ComponentUI$DetailsResultBox$viewPurchaseButton, i18n, onPurchaseAction, offer));
	}
};
var $author$project$Translations$detailsCompleteOfferNotesLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'By completing the offer you will create a new quotation';
	} else {
		return 'Al completar la oferta crearás una nueva cotización';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$highlightRowNotes = function (config) {
	switch (config.$) {
		case 0:
			return $author$project$Common$Render$empty;
		case 1:
			return $author$project$Common$Render$empty;
		default:
			var i18n = config.a.ln;
			var offer = config.b.C;
			return A2(
				$author$project$Common$Render$when,
				$author$project$Common$Offer$mayBeCompleted(offer),
				A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details__highlight-row__right-side__notes')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$detailsCompleteOfferNotesLabel(i18n))
						])));
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$highlightRowValue = function (config) {
	switch (config.$) {
		case 0:
			var policy = config.b.j;
			return $author$project$Common$Policy$pickCustomerFacingId(policy);
		case 1:
			var policy = config.b.j;
			return $author$project$Common$Policy$pickCustomerFacingId(policy);
		default:
			var offer = config.b.C;
			return $author$project$Common$Offer$pickCustomerFacingId(offer);
	}
};
var $author$project$Translations$detailsOfferLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Offer by';
	} else {
		return 'Oferta de';
	}
};
var $author$project$Translations$detailsPolicyLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy by';
	} else {
		return 'Póliza de';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$highlightRowLabel = function (config) {
	switch (config.$) {
		case 0:
			var i18n = config.a.ln;
			return $author$project$Translations$detailsPolicyLabel(i18n);
		case 1:
			var i18n = config.a.ln;
			return $author$project$Translations$detailsPolicyLabel(i18n);
		default:
			var i18n = config.a.ln;
			return $author$project$Translations$detailsOfferLabel(i18n);
	}
};
var $author$project$Common$Source$isPrimaSource = function (source) {
	return source === 2;
};
var $author$project$Common$Render$emptySpace = $elm$core$String$fromChar('\u00A0');
var $author$project$Common$Render$nonBreakingSpace = $elm$html$Html$text($author$project$Common$Render$emptySpace);
var $author$project$Common$Source$toString = function (source) {
	switch (source) {
		case 0:
			return 'Acierto';
		case 1:
			return 'Check24';
		case 2:
			return 'Prima';
		default:
			return 'Rastreator';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$renderSourceInfo = F2(
	function (config, source) {
		return _List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Common$ComponentUI$DetailsResultBox$highlightRowLabel(config)),
				$author$project$Common$Render$nonBreakingSpace,
				A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'c-brand-base',
								$author$project$Common$Source$isPrimaSource(source)),
								_Utils_Tuple2('text-s-bold', true)
							]))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Common$Source$toString(source))
					]))
			]);
	});
var $author$project$Common$Price$pickCurrencySign = function (_v0) {
	var currency = _v0.b;
	return $author$project$Common$Price$pickSign(currency);
};
var $author$project$Common$ComponentUI$DetailsResultBox$pickI18N = function (config) {
	switch (config.$) {
		case 0:
			var i18n = config.a.ln;
			return i18n;
		case 1:
			var i18n = config.a.ln;
			return i18n;
		default:
			var i18n = config.a.ln;
			return i18n;
	}
};
var $author$project$Common$Price$amountString = F2(
	function (i18n, price) {
		return A2(
			$author$project$Common$Price$toAmountString,
			i18n,
			$author$project$Common$Price$pickAmount(price));
	});
var $author$project$Common$Price$toStringDecimals = F2(
	function (i18n, price) {
		return A2(
			$elm$core$Maybe$withDefault,
			'00',
			$elm$core$List$head(
				A2(
					$elm$core$List$drop,
					1,
					A2(
						$elm$core$String$split,
						$author$project$Common$Price$decimalSeparator(i18n),
						A2($author$project$Common$Price$amountString, i18n, price)))));
	});
var $prikhi$decimal$Decimal$truncate = F2(
	function (n, d) {
		var toDecimal = function (s) {
			var _v7 = $prikhi$decimal$Decimal$fromString(s);
			if (!_v7.$) {
				var a = _v7.a;
				return a;
			} else {
				return $prikhi$decimal$Decimal$zero;
			}
		};
		var str = $prikhi$decimal$Decimal$toString(d);
		var _v0 = _Utils_Tuple2(
			A2($elm$core$String$split, '.', str),
			n >= 0);
		_v0$4:
		while (true) {
			if (_v0.a.b) {
				if (!_v0.a.b.b) {
					if (_v0.b) {
						var _v1 = _v0.a;
						var a = _v1.a;
						return toDecimal(
							_Utils_ap(
								A2($elm$core$String$dropRight, n, a),
								A2($elm$core$String$repeat, n, '0')));
					} else {
						var _v2 = _v0.a;
						var a = _v2.a;
						return toDecimal(a);
					}
				} else {
					if (!_v0.a.b.b.b) {
						if (_v0.b) {
							var _v3 = _v0.a;
							var a = _v3.a;
							var _v4 = _v3.b;
							var b = _v4.a;
							return toDecimal(
								_Utils_ap(
									A2($elm$core$String$dropRight, n, a),
									A2($elm$core$String$repeat, n, '0')));
						} else {
							var _v5 = _v0.a;
							var a = _v5.a;
							var _v6 = _v5.b;
							var b = _v6.a;
							return toDecimal(
								a + ('.' + A2($elm$core$String$left, -n, b)));
						}
					} else {
						break _v0$4;
					}
				}
			} else {
				break _v0$4;
			}
		}
		return $prikhi$decimal$Decimal$zero;
	});
var $author$project$Common$Price$toStringIntegers = function (price) {
	return $prikhi$decimal$Decimal$toString(
		A2(
			$prikhi$decimal$Decimal$truncate,
			0,
			$author$project$Common$Price$pickAmount(price)));
};
var $author$project$Translations$detailsNetLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Net';
	} else {
		return 'Prima neta';
	}
};
var $author$project$Translations$detailsTaxIpsLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'IPS';
	} else {
		return 'IPS';
	}
};
var $author$project$Translations$detailsTaxSurchargesLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Surcharges';
	} else {
		return 'Recargos';
	}
};
var $author$project$Translations$detailsTotalAnnualPaymentLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Total annual payment';
	} else {
		return 'Pago total anual';
	}
};
var $author$project$Common$Policy$gross = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Price$sumAll(
		A2(
			$elm$core$List$map,
			function (product) {
				return product.kr.la;
			},
			policy.ep));
};
var $author$project$Common$Policy$net = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Price$sumAll(
		A2(
			$elm$core$List$map,
			function (product) {
				return product.kr.l_;
			},
			policy.ep));
};
var $author$project$Common$Policy$taxIps = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Price$sumAll(
		A2(
			$elm$core$List$map,
			function (product) {
				return product.kr.nz;
			},
			policy.ep));
};
var $author$project$Common$Policy$taxSurcharges = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Price$sumAll(
		A2(
			$elm$core$List$map,
			function (product) {
				return product.kr.nA;
			},
			policy.ep));
};
var $author$project$Common$Price$toStringSignFirst = F2(
	function (i18n, _v0) {
		var amount = _v0.a;
		var currency = _v0.b;
		return $elm$core$String$concat(
			_List_fromArray(
				[
					$author$project$Common$Price$pickSign(currency),
					' ',
					A2($author$project$Common$Price$toAmountString, i18n, amount)
				]));
	});
var $author$project$Pyxis$Components$Tooltip$BottomLeft = 1;
var $author$project$Pyxis$Components$Tooltip$positionBottomLeft = 1;
var $author$project$Pyxis$Components$Tooltip$withPosition = F2(
	function (position, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{en: position});
	});
var $author$project$Pyxis$Components$Tooltip$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Tooltip$withTriggerIcon = F2(
	function (triggerIcon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{eS: triggerIcon});
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewTooltip = F3(
	function (id, onClickTooltip, content) {
		return A3(
			$author$project$Pyxis$Components$Tooltip$render,
			onClickTooltip,
			$author$project$Pyxis$Components$Tooltip$init(id),
			A2(
				$author$project$Pyxis$Components$Tooltip$withContent,
				content,
				A2(
					$author$project$Pyxis$Components$Tooltip$withTestId,
					id,
					A2(
						$author$project$Pyxis$Components$Tooltip$withTriggerIcon,
						$author$project$Pyxis$Components$IconSet$infoCircle,
						A2(
							$author$project$Pyxis$Components$Tooltip$withPosition,
							$author$project$Pyxis$Components$Tooltip$positionBottomLeft,
							A2($author$project$Pyxis$Components$Tooltip$neutral, id, false))))));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewPolicyPriceTooltip = F3(
	function (i18n, onClickTooltip, policy) {
		return A3(
			$author$project$Common$ComponentUI$DetailsResultBox$viewTooltip,
			'payment-price-tooltip',
			onClickTooltip,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__tooltip__frame')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__tooltip__left-side')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Translations$detailsNetLabel(i18n))
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Translations$detailsTaxIpsLabel(i18n))
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Translations$detailsTaxSurchargesLabel(i18n))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('tooltip__total-payment')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										$author$project$Translations$detailsTotalAnnualPaymentLabel(i18n))
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__tooltip__right-side')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$author$project$Common$Price$toStringSignFirst,
											i18n,
											$author$project$Common$Policy$net(policy)))
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$author$project$Common$Price$toStringSignFirst,
											i18n,
											$author$project$Common$Policy$taxIps(policy)))
									])),
								A2(
								$elm$html$Html$div,
								_List_Nil,
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$author$project$Common$Price$toStringSignFirst,
											i18n,
											$author$project$Common$Policy$taxSurcharges(policy)))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('tooltip__total-payment')
									]),
								_List_fromArray(
									[
										$elm$html$Html$text(
										A2(
											$author$project$Common$Price$toStringSignFirst,
											i18n,
											$author$project$Common$Policy$gross(policy)))
									]))
							]))
					])));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewPolicyPrice = function (config) {
	var priceToShow = function (c) {
		switch (c.$) {
			case 2:
				var price = c.a.aR;
				return price;
			case 0:
				var price = c.a.aR;
				return price;
			default:
				var price = c.a.aR;
				return price;
		}
	};
	var i18n = $author$project$Common$ComponentUI$DetailsResultBox$pickI18N(config);
	var tooltip = function (c) {
		switch (c.$) {
			case 2:
				return $author$project$Common$Render$empty;
			case 0:
				var policy = c.b.j;
				var onTooltipClicked = c.b.bH;
				return A3($author$project$Common$ComponentUI$DetailsResultBox$viewPolicyPriceTooltip, i18n, onTooltipClicked, policy);
			default:
				var policy = c.b.j;
				var onTooltipClicked = c.b.bH;
				return A3($author$project$Common$ComponentUI$DetailsResultBox$viewPolicyPriceTooltip, i18n, onTooltipClicked, policy);
		}
	};
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__policy-price')
			]),
		_List_fromArray(
			[
				tooltip(config),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__policy-price__amount')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__policy-price__amount__integers-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Common$Price$pickCurrencySign(
									priceToShow(config)) + (' ' + $author$project$Common$Price$toStringIntegers(
									priceToShow(config))))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__policy-price__amount__decimals-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								_Utils_ap(
									$author$project$Common$Price$decimalSeparator(i18n),
									A2(
										$author$project$Common$Price$toStringDecimals,
										i18n,
										priceToShow(config))))
							]))
					]))
			]));
};
var $author$project$Translations$detailsRefundPriceAmountLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Refund';
	} else {
		return 'Reembolso';
	}
};
var $author$project$Common$ExpectedRefund$pickPrice = function (expectedRefund) {
	if (!expectedRefund.$) {
		var price = expectedRefund.a;
		return price;
	} else {
		var price = expectedRefund.a;
		return price;
	}
};
var $author$project$Common$Refund$pickPrice = function (_v0) {
	var refundData = _v0.a;
	return $author$project$Common$ExpectedRefund$pickPrice(refundData.k0);
};
var $author$project$Translations$detailsInterruptionReasonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Reason';
	} else {
		return 'Motivo';
	}
};
var $author$project$Translations$interruptionDissatisfactionDocsRequest = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Dissatisfaction docs request';
	} else {
		return 'Insatisfacción - pedido doc.';
	}
};
var $author$project$Translations$interruptionIncorrectVehicleInfo = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Incorrect vehicle info';
	} else {
		return 'Datos vehículo incorrectos';
	}
};
var $author$project$Translations$interruptionInexactDeclaration = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Inexact declaration';
	} else {
		return 'Agravación de riesgo - SINCO';
	}
};
var $author$project$Translations$interruptionNoReason = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'No reason';
	} else {
		return 'Sin razón';
	}
};
var $author$project$Translations$interruptionRenewalNotProvided = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Renewal not provided';
	} else {
		return 'Renovación no disponible a tiempo';
	}
};
var $author$project$Translations$interruptionRenewalRejected = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Renewal rejected';
	} else {
		return 'Renovación rechazada';
	}
};
var $author$project$Translations$interruptionVehicleScrapped = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Vehicle scrapped';
	} else {
		return 'Vehículo - pérdida total';
	}
};
var $author$project$Common$InterruptionReason$reasonToString = F2(
	function (i18n, reason) {
		switch (reason) {
			case 0:
				return $author$project$Translations$interruptionChargeback(i18n);
			case 1:
				return $author$project$Translations$interruptionCompetitorOffer(i18n);
			case 2:
				return $author$project$Translations$interruptionCoverageChange(i18n);
			case 3:
				return $author$project$Translations$interruptionDeceasedPolicyHolder(i18n);
			case 4:
				return $author$project$Translations$interruptionDissatisfactionDocsRequest(i18n);
			case 5:
				return $author$project$Translations$interruptionDissatisfactionService(i18n);
			case 6:
				return $author$project$Translations$interruptionDoesNotKnow(i18n);
			case 7:
				return $author$project$Translations$interruptionFraud(i18n);
			case 8:
				return $author$project$Translations$interruptionIncorrectDataOther(i18n);
			case 9:
				return $author$project$Translations$interruptionIncorrectEffectiveDate(i18n);
			case 10:
				return $author$project$Translations$interruptionIncorrectPersonalData(i18n);
			case 11:
				return $author$project$Translations$interruptionIncorrectVehicleInfo(i18n);
			case 12:
				return $author$project$Translations$interruptionInexactDeclaration(i18n);
			case 13:
				return $author$project$Translations$interruptionInexactDeclarationDni(i18n);
			case 14:
				return $author$project$Translations$interruptionInexactDeclarationSinco(i18n);
			case 15:
				return $author$project$Translations$interruptionMovingToAnotherCountry(i18n);
			case 16:
				return $author$project$Translations$interruptionNoInsuranceWanted(i18n);
			case 17:
				return $author$project$Translations$interruptionNoReason(i18n);
			case 18:
				return $author$project$Translations$interruptionOther(i18n);
			case 19:
				return $author$project$Translations$interruptionPaymentMethodOrDate(i18n);
			case 20:
				return $author$project$Translations$interruptionPolicyHolderChange(i18n);
			case 21:
				return $author$project$Translations$interruptionProductService(i18n);
			case 22:
				return $author$project$Translations$interruptionRenewalNotProvided(i18n);
			case 23:
				return $author$project$Translations$interruptionRenewalRejected(i18n);
			case 24:
				return $author$project$Translations$interruptionRiskAggravation(i18n);
			case 25:
				return $author$project$Translations$interruptionUnderwritingRules(i18n);
			case 26:
				return $author$project$Translations$interruptionVehicleDeregistration(i18n);
			case 27:
				return $author$project$Translations$interruptionVehicleNotPurchased(i18n);
			case 28:
				return $author$project$Translations$interruptionVehicleScrapped(i18n);
			case 29:
				return $author$project$Translations$interruptionVehicleSold(i18n);
			case 30:
				return $author$project$Translations$interruptionWantsGreenCard(i18n);
			case 31:
				return $author$project$Translations$interruptionPriceIncrease(i18n);
			default:
				return $author$project$Translations$interruptionPhotoVericiationNotProvided(i18n);
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$formatInterruptionReason = F2(
	function (i18n, interruption) {
		return _Utils_ap(
			A2($author$project$Common$InterruptionReason$reasonToString, i18n, interruption.g8),
			A3(
				$elm_community$maybe_extra$Maybe$Extra$unwrap,
				'',
				$elm$core$Basics$append(' - '),
				interruption.kR));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewRefundPriceTooltipBlock = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__refund-tooltip__frame__block')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details__refund-tooltip__frame__block__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details__refund-tooltip__frame__block__value')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewRefundPriceTooltip = F3(
	function (i18n, onClickTooltip, interruption) {
		return A3(
			$author$project$Common$ComponentUI$DetailsResultBox$viewTooltip,
			'refund-price',
			onClickTooltip,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__refund-tooltip__frame')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Common$ComponentUI$DetailsResultBox$viewRefundPriceTooltipBlock,
						$author$project$Translations$detailsInterruptionReasonLabel(i18n),
						A2($author$project$Common$ComponentUI$DetailsResultBox$formatInterruptionReason, i18n, interruption))
					])));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewPriceForCompletedRefund = F4(
	function (i18n, onClickTooltip, interruption, refund) {
		var status = refund.b;
		if (status === 2) {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__block')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__refund-price')
							]),
						_List_fromArray(
							[
								A3($author$project$Common$ComponentUI$DetailsResultBox$viewRefundPriceTooltip, i18n, onClickTooltip, interruption),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__refund-price__amount')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__refund-price__amount__label')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												$author$project$Translations$detailsRefundPriceAmountLabel(i18n))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__refund-price__amount__value')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text(
												A2(
													$author$project$Common$Price$toStringSignFirst,
													i18n,
													$author$project$Common$Refund$pickPrice(refund)))
											]))
									]))
							]))
					]));
		} else {
			return $author$project$Common$Render$empty;
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewRefundPrice = function (config) {
	if (config.$ === 1) {
		var i18n = config.a.ln;
		var onTooltipClicked = config.b.bH;
		var interruption = config.c.cw;
		var refundResult = config.c.cZ;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Render$empty,
			A2(
				$elm$core$Maybe$map,
				A3($author$project$Common$ComponentUI$DetailsResultBox$viewPriceForCompletedRefund, i18n, onTooltipClicked, interruption),
				$krisajenkins$remotedata$RemoteData$toMaybe(refundResult)));
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$viewHighlightRow = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details__highlight-row')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('align-items-flex-start display-flex flex-direction-column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__left-side__offer-text')
							]),
						A2(
							$author$project$Common$ComponentUI$DetailsResultBox$renderSourceInfo,
							config,
							$author$project$Common$ComponentUI$DetailsResultBox$fetchSource(config))),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__left-side__cfid-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Common$ComponentUI$DetailsResultBox$highlightRowValue(config))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__highlight-row__right-side')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__highlight-row__right-side__main')
							]),
						_List_fromArray(
							[
								$author$project$Common$ComponentUI$DetailsResultBox$viewRefundPrice(config),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('details__highlight-row__right-side__main__block')
									]),
								_List_fromArray(
									[
										$author$project$Common$ComponentUI$DetailsResultBox$viewPolicyPrice(config)
									])),
								$author$project$Common$ComponentUI$DetailsResultBox$highlightRowCTA(config)
							])),
						$author$project$Common$ComponentUI$DetailsResultBox$highlightRowNotes(config)
					]))
			]));
};
var $author$project$Translations$detailsRefundBoxRefundRetrievingErrorFirstLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Sorry, we are unable to access your refund details at this time due to a technical error.';
	} else {
		return 'Lo sentimos, no podemos acceder a los datos del reembolso en este momento debido a un error técnico.';
	}
};
var $author$project$Translations$detailsRefundBoxRefundRetrievingErrorSecondLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please wait a few minutes and refresh the page to try again.';
	} else {
		return 'Espera unos minutos y actualiza la página para intentarlo nuevamente.';
	}
};
var $author$project$Translations$detailsRefundBoxLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Refund management';
	} else {
		return 'Gestión de reembolsos';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$refundBox = F2(
	function (i18n, content) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__refund-box details__box')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$h4,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details__refund-box__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$detailsRefundBoxLabel(i18n))
						])),
					content
				]));
	});
var $author$project$Pyxis$Components$Icon$Alert = 3;
var $author$project$Pyxis$Components$Icon$alert = $author$project$Pyxis$Components$Icon$Boxed(3);
var $author$project$Translations$detailsReloadButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Reload';
	} else {
		return 'Actualizar';
	}
};
var $author$project$Pyxis$Components$Button$M = 2;
var $author$project$Pyxis$Components$Button$sizeM = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{ax: 2});
};
var $author$project$Common$ComponentUI$ErrorBox$render = F3(
	function (i18n, errorMessageList, onRetryButtonClick) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('error-box')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withStyle,
						$author$project$Pyxis$Components$Icon$alert,
						$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$exclamationTriangle))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error-box__alert__message')
						]),
					A2(
						$elm$core$List$map,
						A2(
							$elm$core$Basics$composeR,
							$elm$html$Html$text,
							A2(
								$elm$core$Basics$composeR,
								$elm$core$List$singleton,
								$elm$html$Html$div(_List_Nil))),
						errorMessageList)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('error-box__retry-button')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Button$render(
							A2(
								$author$project$Pyxis$Components$Button$withOnClick,
								onRetryButtonClick,
								A2(
									$author$project$Pyxis$Components$Button$withText,
									$author$project$Translations$detailsReloadButton(i18n),
									A2(
										$author$project$Pyxis$Components$Button$withType,
										$author$project$Pyxis$Components$Button$button,
										A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeM, $author$project$Pyxis$Components$Button$primary)))))
						]))
				]));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$refundBoxFailure = F3(
	function (i18n, policyData, interruptedData) {
		return A2(
			$author$project$Common$ComponentUI$DetailsResultBox$refundBox,
			i18n,
			A3(
				$author$project$Common$ComponentUI$ErrorBox$render,
				i18n,
				_List_fromArray(
					[
						$author$project$Translations$detailsRefundBoxRefundRetrievingErrorFirstLabel(i18n),
						$author$project$Translations$detailsRefundBoxRefundRetrievingErrorSecondLabel(i18n)
					]),
				interruptedData.gK(
					$author$project$Common$Policy$pickId(policyData.j))));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$refundBoxLoading = function (i18n) {
	return A2(
		$author$project$Common$ComponentUI$DetailsResultBox$refundBox,
		i18n,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__refund-box__loading')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Loaders$Loader$render($author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM)
				])));
};
var $author$project$Translations$detailsRefundBoxNotAskedLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please manage the refund manually.';
	} else {
		return 'Gestione el reembolso manualmente.';
	}
};
var $author$project$Pyxis$Components$Icon$Neutral = 0;
var $author$project$Pyxis$Components$Icon$neutral = $author$project$Pyxis$Components$Icon$Boxed(0);
var $author$project$Common$ComponentUI$DetailsResultBox$refundBoxNotAsked = function (i18n) {
	return A2(
		$author$project$Common$ComponentUI$DetailsResultBox$refundBox,
		i18n,
		A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__refund-box__not-asked')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withStyle,
						$author$project$Pyxis$Components$Icon$neutral,
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Icon$sizeL,
							$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$infoCircle)))),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('details__refund-box__not-asked__message')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$detailsRefundBoxNotAskedLabel(i18n))
						]))
				])));
};
var $author$project$Translations$detailsRefundBoxRefundEstimatedAmountLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Estimated amount';
	} else {
		return 'Reembolso estimado';
	}
};
var $author$project$Translations$detailsRefundButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Refund';
	} else {
		return 'Reembolsar';
	}
};
var $author$project$Common$Refund$isNotRequired = function (_v0) {
	var status = _v0.b;
	return status === 4;
};
var $author$project$Pyxis$Components$Button$withDisabled = F2(
	function (isDisabled, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fC: isDisabled});
	});
var $author$project$Common$ComponentUI$DetailsResultBox$refundBoxSuccess = F3(
	function (i18n, refund, _v0) {
		var interruption = _v0.cw;
		var onIssueRefundButtonClicked = _v0.gQ;
		return A2(
			$author$project$Common$ComponentUI$DetailsResultBox$refundBox,
			i18n,
			A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__refund-box__info')
					]),
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$primait$prima_elm_extra$PrimaFunction$uncurry($author$project$Common$ComponentUI$DetailsResultBox$renderLabelInfo),
						_List_fromArray(
							[
								_Utils_Tuple2(
								$author$project$Translations$detailsRefundBoxRefundEstimatedAmountLabel(i18n),
								A2(
									$author$project$Common$Price$toStringSignFirst,
									i18n,
									$author$project$Common$Refund$pickPrice(refund))),
								_Utils_Tuple2(
								$author$project$Translations$detailsInterruptionReasonLabel(i18n),
								A2($author$project$Common$ComponentUI$DetailsResultBox$formatInterruptionReason, i18n, interruption))
							])),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('details__refund-box__info__refund_button')
								]),
							_List_fromArray(
								[
									$author$project$Pyxis$Components$Button$render(
									A2(
										$author$project$Pyxis$Components$Button$withDisabled,
										$author$project$Common$Refund$isNotRequired(refund),
										A2(
											$author$project$Pyxis$Components$Button$withOnClick,
											onIssueRefundButtonClicked(refund),
											A2(
												$author$project$Pyxis$Components$Button$withText,
												$author$project$Translations$detailsRefundButton(i18n),
												A2(
													$author$project$Pyxis$Components$Button$withType,
													$author$project$Pyxis$Components$Button$button,
													A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeM, $author$project$Pyxis$Components$Button$primary))))))
								]))
						]))));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$interruptionInfoByRemoteData = F3(
	function (i18n, policyData, interruptionData) {
		var _v0 = interruptionData.cZ;
		switch (_v0.$) {
			case 0:
				return $author$project$Common$ComponentUI$DetailsResultBox$refundBoxNotAsked(i18n);
			case 1:
				return $author$project$Common$ComponentUI$DetailsResultBox$refundBoxLoading(i18n);
			case 2:
				return A3($author$project$Common$ComponentUI$DetailsResultBox$refundBoxFailure, i18n, policyData, interruptionData);
			default:
				switch (_v0.a.b) {
					case 0:
						var refund = _v0.a;
						var _v1 = refund.b;
						return A3($author$project$Common$ComponentUI$DetailsResultBox$refundBoxSuccess, i18n, refund, interruptionData);
					case 4:
						var refund = _v0.a;
						var _v2 = refund.b;
						return A3($author$project$Common$ComponentUI$DetailsResultBox$refundBoxSuccess, i18n, refund, interruptionData);
					default:
						return $author$project$Common$Render$empty;
				}
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionInfo = function (config) {
	if (config.$ === 1) {
		var i18n = config.a.ln;
		var policyData = config.b;
		var interruptionData = config.c;
		return A3($author$project$Common$ComponentUI$DetailsResultBox$interruptionInfoByRemoteData, i18n, policyData, interruptionData);
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Common$User$hasPermission = F2(
	function (user, permission) {
		if (!user.$) {
			return false;
		} else {
			var permissions = user.a.cN;
			return A2($elm$core$List$member, permission, permissions);
		}
	});
var $author$project$Common$User$hasInterruptPolicyPermission = function (user) {
	return A2($author$project$Common$User$hasPermission, user, 'policy:cancel');
};
var $author$project$Common$Policy$pickCoolingOffPeriodEndAtAsTimePosix = function (_v0) {
	var policy = _v0;
	return policy.ci;
};
var $author$project$Common$ComponentUI$DetailsResultBox$TerminateByCustomer = 1;
var $author$project$Common$ComponentUI$DetailsResultBox$TerminateByPrima = 2;
var $author$project$Common$ComponentUI$DetailsResultBox$Withdraw = 0;
var $author$project$Common$ComponentUI$DetailsResultBox$interruptableActions = function (_v0) {
	var canBeWithdrawn = _v0.ff;
	var canBeTerminatedByCustomer = _v0.hY;
	var canBeTerminatedByPrima = _v0.hZ;
	var _v1 = _Utils_Tuple3(canBeWithdrawn, canBeTerminatedByCustomer, canBeTerminatedByPrima);
	if (_v1.a) {
		if (!_v1.c) {
			return _List_fromArray(
				[0]);
		} else {
			return _List_fromArray(
				[0, 2]);
		}
	} else {
		if (_v1.b) {
			if (!_v1.c) {
				return _List_fromArray(
					[1]);
			} else {
				return _List_fromArray(
					[1, 2]);
			}
		} else {
			if (_v1.c) {
				return _List_fromArray(
					[2]);
			} else {
				return _List_Nil;
			}
		}
	}
};
var $author$project$Translations$policyCustomerTerminationButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Terminate';
	} else {
		return 'Cliente cancela';
	}
};
var $author$project$Translations$policyPrimaTerminationButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Prima Terminate';
	} else {
		return 'Prima cancela';
	}
};
var $author$project$Translations$policyWithdrawButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Withdraw';
	} else {
		return 'Desistimiento';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$renderButton = F3(
	function (label, id, action) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withTestId,
				id,
				A2(
					$author$project$Pyxis$Components$Button$withId,
					id,
					A2(
						$author$project$Pyxis$Components$Button$withText,
						label,
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							action,
							$author$project$Pyxis$Components$Button$sizeM(
								A2($author$project$Pyxis$Components$Button$withType, $author$project$Pyxis$Components$Button$button, $author$project$Pyxis$Components$Button$tertiary)))))));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renderInterruptionButton = F3(
	function (i18n, onInterruptionButtonPressed, action) {
		var _v0 = function () {
			switch (action) {
				case 0:
					return {
						iO: 'withdraw',
						dS: 0,
						d3: $author$project$Translations$policyWithdrawButton(i18n)
					};
				case 1:
					return {
						iO: 'interruption',
						dS: 1,
						d3: $author$project$Translations$policyCustomerTerminationButton(i18n)
					};
				default:
					return {
						iO: 'prima-interruption',
						dS: 2,
						d3: $author$project$Translations$policyPrimaTerminationButton(i18n)
					};
			}
		}();
		var interruptionKind = _v0.dS;
		var label = _v0.d3;
		var id = _v0.iO;
		return A3(
			$author$project$Common$ComponentUI$DetailsResultBox$renderButton,
			label,
			id,
			onInterruptionButtonPressed(interruptionKind));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renderInterruptionButtons = F2(
	function (i18n, _v0) {
		var interruptionStatus = _v0.f6;
		var onInterruptionButtonClicked = _v0.jg;
		return A2(
			$elm$core$List$map,
			A2($author$project$Common$ComponentUI$DetailsResultBox$renderInterruptionButton, i18n, onInterruptionButtonClicked),
			$author$project$Common$ComponentUI$DetailsResultBox$interruptableActions(interruptionStatus));
	});
var $author$project$Translations$policyDoesNotRenewButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Doesn\'t renew';
	} else {
		return 'Cliente no renueva';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$renderRenewalCancellationButton = F3(
	function (i18n, policy, _v0) {
		var onRenewalCancellationButtonClicked = _v0.ji;
		var canRenewalBeCancelled = _v0.h_;
		return $elm$core$List$singleton(
			A2(
				$author$project$Common$Render$when,
				canRenewalBeCancelled,
				A3(
					$author$project$Common$ComponentUI$DetailsResultBox$renderButton,
					$author$project$Translations$policyDoesNotRenewButton(i18n),
					'does-not-renew',
					onRenewalCancellationButtonClicked(policy))));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionButtons = F3(
	function (i18n, policy, detailsResultInterruptableData) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex align-self-flex-end details__interruptions-row__ctas-group')
				]),
			A2(
				$elm$core$List$append,
				A3($author$project$Common$ComponentUI$DetailsResultBox$renderRenewalCancellationButton, i18n, policy, detailsResultInterruptableData),
				A2($author$project$Common$ComponentUI$DetailsResultBox$renderInterruptionButtons, i18n, detailsResultInterruptableData)));
	});
var $author$project$Common$Locale$formatPosixTime = F2(
	function (tz, time) {
		var minute = $elm$core$String$fromInt(
			A2($elm$time$Time$toMinute, tz, time));
		var hour = $elm$core$String$fromInt(
			A2($elm$time$Time$toHour, tz, time));
		return A3($elm$core$String$padLeft, 2, '0', hour) + (':' + A3($elm$core$String$padLeft, 2, '0', minute));
	});
var $author$project$Translations$policyCoolingOffPeriodEndDateMessage = F3(
	function (data, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Customer can withdraw from the contract until '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.kJ + (' of ' + data.kI))
						]))
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('El cliente puede desistir del contrato hasta las ' + (data.kJ + (' del día ' + data.kI)))
				]);
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$withdrawMessage = F2(
	function (i18n, coolingOffEndAt) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__interruptions-row__cooling-off-period-message')
				]),
			A3(
				$author$project$Translations$policyCoolingOffPeriodEndDateMessage,
				{
					kI: A2($author$project$Common$Locale$formatPosixDate, $author$project$Common$Locale$customerTimeZone, coolingOffEndAt),
					kJ: A2($author$project$Common$Locale$formatPosixTime, $author$project$Common$Locale$customerTimeZone, coolingOffEndAt)
				},
				_List_Nil,
				i18n));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionsRow = function (config) {
	switch (config.$) {
		case 2:
			return $author$project$Common$Render$empty;
		case 1:
			return $author$project$Common$Render$empty;
		default:
			var i18n = config.a.ln;
			var policy = config.b.j;
			var detailsResultInterruptableData = config.c;
			var user = config.d;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__interruptions-row')
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Common$Render$when,
						detailsResultInterruptableData.f6.ff,
						A2(
							$author$project$Common$Render$maybe,
							$author$project$Common$ComponentUI$DetailsResultBox$withdrawMessage(i18n),
							$author$project$Common$Policy$pickCoolingOffPeriodEndAtAsTimePosix(policy))),
						A2(
						$author$project$Common$Render$when,
						$author$project$Common$User$hasInterruptPolicyPermission(user),
						A3($author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionButtons, i18n, policy, detailsResultInterruptableData))
					]));
	}
};
var $author$project$Common$Policy$pickRenewalCancellation = function (_v0) {
	var policy = _v0;
	return policy.hc;
};
var $author$project$Common$Policy$pickStatus = function (_v0) {
	var policy = _v0;
	return policy.c1;
};
var $author$project$Translations$detailsRenewalCancellationForActivePolicyMessage = F3(
	function (renewalCancellationReason, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('This policy will not be renewed due to a customer request. The reason for the renewal’s cancellation is: '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(renewalCancellationReason)
						])),
					$elm$html$Html$text('.')
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('Esta póliza no se renovará porque el cliente así lo ha solicitado. El motivo por el cual se cancela la renovación es: '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(renewalCancellationReason)
						])),
					$elm$html$Html$text('.')
				]);
		}
	});
var $author$project$Translations$detailsRenewalCancellationForNotRenewedPolicyMessage = F3(
	function (renewalCancellationReason, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('The reason for the renewal’s cancellation is: '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(renewalCancellationReason)
						])),
					$elm$html$Html$text('.')
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('El motivo por el cual se cancela la renovación es: '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(renewalCancellationReason)
						])),
					$elm$html$Html$text('.')
				]);
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renewalCancellationMessageContent = F3(
	function (i18n, policyStatus, renewalCancellation) {
		if (policyStatus === 1) {
			return A3(
				$author$project$Translations$detailsRenewalCancellationForActivePolicyMessage,
				A2($author$project$Common$InterruptionReason$reasonToString, i18n, renewalCancellation.g8),
				_List_Nil,
				i18n);
		} else {
			return A3(
				$author$project$Translations$detailsRenewalCancellationForNotRenewedPolicyMessage,
				A2($author$project$Common$InterruptionReason$reasonToString, i18n, renewalCancellation.g8),
				_List_Nil,
				i18n);
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renewalsCancellationMessage = F3(
	function (i18n, policyStatus, renewalCancellation) {
		return $author$project$Pyxis$Components$Message$render(
			A2(
				$author$project$Pyxis$Components$Message$withContent,
				A3($author$project$Common$ComponentUI$DetailsResultBox$renewalCancellationMessageContent, i18n, policyStatus, renewalCancellation),
				$author$project$Pyxis$Components$Message$neutral));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewRenewalsCancellationInfoBox = function (config) {
	if (!config.$) {
		var i18n = config.a.ln;
		var policy = config.b.j;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Render$empty,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Common$ComponentUI$DetailsResultBox$renewalsCancellationMessage,
					i18n,
					$author$project$Common$Policy$pickStatus(policy)),
				$author$project$Common$Policy$pickRenewalCancellation(policy)));
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Common$Policy$pickRenewalPolicyId = function (_v0) {
	var policy = _v0;
	return policy.hd;
};
var $author$project$Translations$detailsRenewalsReminderLink = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'here';
	} else {
		return 'aquí';
	}
};
var $author$project$Translations$detailsRenewalsReminderMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Check if the client has a renewed policy and if he wants to cancel it as well. You can find the details of the other policy';
	} else {
		return 'Verifica si el cliente tiene una póliza renovada y si desea cancelarla también. Puedes consultar los datos de la otra póliza';
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$reminderMessage = F3(
	function (i18n, onRenewalsLinkClicked, renewlPolicyId) {
		return $author$project$Pyxis$Components$Message$render(
			A2(
				$author$project$Pyxis$Components$Message$withContent,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Translations$detailsRenewalsReminderMessage(i18n)),
						$elm$html$Html$text(' '),
						A2(
						$elm$html$Html$span,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('message__link'),
								$elm$html$Html$Events$onClick(
								onRenewalsLinkClicked(renewlPolicyId))
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$detailsRenewalsReminderLink(i18n))
							])),
						$elm$html$Html$text('.')
					]),
				$author$project$Pyxis$Components$Message$alert));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewRenewalsReminderInfoBox = function (config) {
	if (config.$ === 1) {
		var i18n = config.a.ln;
		var policy = config.b.j;
		var onRenewalsLinkClicked = config.c.bG;
		return A2(
			$author$project$Common$Render$maybe,
			A2($author$project$Common$ComponentUI$DetailsResultBox$reminderMessage, i18n, onRenewalsLinkClicked),
			$author$project$Common$Policy$pickRenewalPolicyId(policy));
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Common$Offer$pickVehicle = function (_v0) {
	var offer = _v0;
	return offer.hL;
};
var $author$project$Common$Policy$pickVehicle = function (_v0) {
	var policy = _v0;
	return policy.hL;
};
var $author$project$Common$ComponentUI$DetailsResultBox$extractVehicleInfo = F2(
	function (pickerFunction, config) {
		switch (config.$) {
			case 0:
				var policy = config.b.j;
				return pickerFunction(
					$author$project$Common$Policy$pickVehicle(policy));
			case 1:
				var policy = config.b.j;
				return pickerFunction(
					$author$project$Common$Policy$pickVehicle(policy));
			default:
				var offer = config.b.C;
				return pickerFunction(
					$author$project$Common$Offer$pickVehicle(offer));
		}
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renderVehicleInfo = function (config) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Common$ComponentUI$DetailsResultBox$extractVehicleInfo, $author$project$Common$Vehicle$pickPlateNumber, config))
				])),
			A2(
			$elm$html$Html$span,
			_List_Nil,
			_List_fromArray(
				[
					$elm$html$Html$text(
					A2($author$project$Common$ComponentUI$DetailsResultBox$extractVehicleInfo, $author$project$Common$Vehicle$pickBrand, config))
				]))
		]);
};
var $author$project$Common$ComponentUI$DetailsResultBox$infoBlock = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details__status-row__info-block')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Icon$sizeL,
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$car))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('pointed-text')
					]),
				$author$project$Common$ComponentUI$DetailsResultBox$renderVehicleInfo(config))
			]));
};
var $author$project$Pyxis$Components$Badge$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Common$Refund$renderBadge = function (customClass) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Badge$withClassList(
			_List_fromArray(
				[
					_Utils_Tuple2(customClass, true)
				])),
		$author$project$Pyxis$Components$Badge$render);
};
var $author$project$Pyxis$Components$Badge$Action = 2;
var $author$project$Pyxis$Components$Badge$action = $author$project$Pyxis$Components$Badge$config(2);
var $author$project$Translations$policyRefundStatusCompleted = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Refunded';
	} else {
		return 'Reembolsado';
	}
};
var $author$project$Translations$policyRefundStatusPending = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Ready to refund';
	} else {
		return 'Listo para reembolsar';
	}
};
var $author$project$Pyxis$Components$Badge$Success = 4;
var $author$project$Pyxis$Components$Badge$success = $author$project$Pyxis$Components$Badge$config(4);
var $author$project$Common$Refund$statusBadge = F2(
	function (i18n, _v0) {
		var status = _v0.b;
		switch (status) {
			case 0:
				return $elm$core$Maybe$Just(
					A2(
						$author$project$Pyxis$Components$Badge$withText,
						$author$project$Translations$policyRefundStatusPending(i18n),
						$author$project$Pyxis$Components$Badge$action));
			case 2:
				return $elm$core$Maybe$Just(
					A2(
						$author$project$Pyxis$Components$Badge$withText,
						$author$project$Translations$policyRefundStatusCompleted(i18n),
						$author$project$Pyxis$Components$Badge$success));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Common$Refund$renderStatusBadge = F3(
	function (customClass, i18n, refund) {
		return A2(
			$elm$core$Maybe$withDefault,
			$elm_community$html_extra$Html$Extra$nothing,
			A2(
				$elm$core$Maybe$map,
				$author$project$Common$Refund$renderBadge(customClass),
				A2($author$project$Common$Refund$statusBadge, i18n, refund)));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$refundBadgeFromRefundData = F2(
	function (i18n, _v0) {
		var refundResult = _v0.cZ;
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Render$empty,
			A2(
				$elm$core$Maybe$map,
				A2($author$project$Common$Refund$renderStatusBadge, 'align-self-flex-start', i18n),
				$krisajenkins$remotedata$RemoteData$toMaybe(refundResult)));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$refundBadge = function (config) {
	if (config.$ === 1) {
		var i18n = config.a.ln;
		var interruptionData = config.c;
		return A2($author$project$Common$ComponentUI$DetailsResultBox$refundBadgeFromRefundData, i18n, interruptionData);
	} else {
		return $author$project$Common$Render$empty;
	}
};
var $author$project$Pyxis$Components$Badge$Brand = 1;
var $author$project$Pyxis$Components$Badge$brand = $author$project$Pyxis$Components$Badge$config(1);
var $author$project$Translations$offerStatusRenewal = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Renewal';
	} else {
		return 'Renovación';
	}
};
var $author$project$Common$Offer$renewalBadge = F2(
	function (customClass, i18n) {
		return $author$project$Pyxis$Components$Badge$render(
			A2(
				$author$project$Pyxis$Components$Badge$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2(customClass, true)
					]),
				A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusRenewal(i18n),
					$author$project$Pyxis$Components$Badge$brand)));
	});
var $elm_community$html_extra$Html$Extra$viewIf = F2(
	function (condition, html) {
		return condition ? html : $elm_community$html_extra$Html$Extra$nothing;
	});
var $author$project$Common$Offer$renderRenewalBadge = F3(
	function (customClass, i18n, offer) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			$author$project$Common$Offer$pickIsRenewal(offer),
			A2($author$project$Common$Offer$renewalBadge, customClass, i18n));
	});
var $author$project$Common$Policy$pickIsARenewal = function (_v0) {
	var policy = _v0;
	return policy.f7;
};
var $author$project$Common$Policy$renewalBadge = F2(
	function (customClass, i18n) {
		return $author$project$Pyxis$Components$Badge$render(
			A2(
				$author$project$Pyxis$Components$Badge$withClassList,
				_List_fromArray(
					[
						_Utils_Tuple2(customClass, true)
					]),
				A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusRenewal(i18n),
					$author$project$Pyxis$Components$Badge$brand)));
	});
var $author$project$Common$Policy$renderRenewalBadge = F3(
	function (customClass, i18n, offer) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			$author$project$Common$Policy$pickIsARenewal(offer),
			A2($author$project$Common$Policy$renewalBadge, customClass, i18n));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$renewalBadge = function (config) {
	switch (config.$) {
		case 2:
			var i18n = config.a.ln;
			var offer = config.b.C;
			return A3($author$project$Common$Offer$renderRenewalBadge, 'align-self-flex-start', i18n, offer);
		case 0:
			var i18n = config.a.ln;
			var policy = config.b.j;
			return A3($author$project$Common$Policy$renderRenewalBadge, 'align-self-flex-start', i18n, policy);
		default:
			var i18n = config.a.ln;
			var policy = config.b.j;
			return A3($author$project$Common$Policy$renderRenewalBadge, 'align-self-flex-start', i18n, policy);
	}
};
var $author$project$Pyxis$Components$Badge$Alert = 5;
var $author$project$Pyxis$Components$Badge$alert = $author$project$Pyxis$Components$Badge$config(5);
var $author$project$Pyxis$Components$Badge$Error = 3;
var $author$project$Pyxis$Components$Badge$error = $author$project$Pyxis$Components$Badge$config(3);
var $author$project$Translations$offerStatusBlocked = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Blocked';
	} else {
		return 'No contratable';
	}
};
var $author$project$Translations$offerStatusExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Expired';
	} else {
		return 'Oferta caducada';
	}
};
var $author$project$Translations$offerStatusMissingDocuments = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Missing documents';
	} else {
		return 'Documentos pendientes';
	}
};
var $author$project$Translations$offerStatusNewPrice = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'New price';
	} else {
		return 'Nuevo precio';
	}
};
var $author$project$Translations$offerStatusNewProduct = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'New product';
	} else {
		return 'Nueva modalidad';
	}
};
var $author$project$Translations$offerStatusPurchasable = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Available';
	} else {
		return 'Oferta disponible';
	}
};
var $author$project$Translations$offerStatusPurchased = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchased';
	} else {
		return 'Póliza contratada';
	}
};
var $author$project$Translations$offerStatusRequoted = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Requoted';
	} else {
		return 'Cotizado nuevamente';
	}
};
var $author$project$Translations$offerStatusStartDateExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Start Date Expired';
	} else {
		return 'Fecha de inicio expirada';
	}
};
var $author$project$Common$Offer$statusBadge = F2(
	function (i18n, _v0) {
		var offer = _v0;
		var _v1 = offer.c1;
		switch (_v1) {
			case 8:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusPurchasable(i18n),
					$author$project$Pyxis$Components$Badge$success);
			case 4:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusExpired(i18n),
					$author$project$Pyxis$Components$Badge$neutral);
			case 3:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusStartDateExpired(i18n),
					$author$project$Pyxis$Components$Badge$alert);
			case 7:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusPurchased(i18n),
					$author$project$Pyxis$Components$Badge$brand);
			case 5:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusBlocked(i18n),
					$author$project$Pyxis$Components$Badge$error);
			case 6:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusRequoted(i18n),
					$author$project$Pyxis$Components$Badge$error);
			case 0:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusNewPrice(i18n),
					$author$project$Pyxis$Components$Badge$success);
			case 1:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusNewProduct(i18n),
					$author$project$Pyxis$Components$Badge$success);
			default:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$offerStatusMissingDocuments(i18n),
					$author$project$Pyxis$Components$Badge$alert);
		}
	});
var $author$project$Common$Offer$renderStatusBadge = F2(
	function (customClass, i18n) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Offer$statusBadge(i18n),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Badge$withClassList(
					_List_fromArray(
						[
							_Utils_Tuple2(customClass, true)
						])),
				$author$project$Pyxis$Components$Badge$render));
	});
var $author$project$Translations$policyStatusActive = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Active';
	} else {
		return 'Activa';
	}
};
var $author$project$Translations$policyStatusExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Expired';
	} else {
		return 'Caducada';
	}
};
var $author$project$Translations$policyStatusGracePeriod = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Grace period';
	} else {
		return 'Periodo de gracia';
	}
};
var $author$project$Translations$policyStatusIssued = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Issued';
	} else {
		return 'Emitida';
	}
};
var $author$project$Translations$policyStatusNotRenewed = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Not renewed';
	} else {
		return 'No renovada';
	}
};
var $author$project$Translations$policyStatusTerminatedByCustomer = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Terminated by customer';
	} else {
		return 'Cliente cancela';
	}
};
var $author$project$Translations$policyStatusTerminatedByPrima = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Terminated by Prima';
	} else {
		return 'Prima cancela';
	}
};
var $author$project$Translations$policyStatusWithdrawn = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Withdrawn';
	} else {
		return 'Cancelada por desistimiento';
	}
};
var $author$project$Common$Policy$statusBadge = F2(
	function (i18n, _v0) {
		var policy = _v0;
		var _v1 = policy.c1;
		switch (_v1) {
			case 0:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusIssued(i18n),
					$author$project$Pyxis$Components$Badge$success);
			case 1:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusActive(i18n),
					$author$project$Pyxis$Components$Badge$success);
			case 2:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusGracePeriod(i18n),
					$author$project$Pyxis$Components$Badge$alert);
			case 3:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusExpired(i18n),
					$author$project$Pyxis$Components$Badge$neutral);
			case 4:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusNotRenewed(i18n),
					$author$project$Pyxis$Components$Badge$neutral);
			case 5:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusWithdrawn(i18n),
					$author$project$Pyxis$Components$Badge$error);
			case 6:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusTerminatedByCustomer(i18n),
					$author$project$Pyxis$Components$Badge$error);
			default:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$policyStatusTerminatedByPrima(i18n),
					$author$project$Pyxis$Components$Badge$error);
		}
	});
var $author$project$Common$Policy$renderStatusBadge = F2(
	function (customClass, i18n) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Policy$statusBadge(i18n),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Badge$withClassList(
					_List_fromArray(
						[
							_Utils_Tuple2(customClass, true)
						])),
				$author$project$Pyxis$Components$Badge$render));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$statusBadge = function (config) {
	switch (config.$) {
		case 0:
			var i18n = config.a.ln;
			var policy = config.b.j;
			return A3($author$project$Common$Policy$renderStatusBadge, 'align-self-flex-start', i18n, policy);
		case 1:
			var i18n = config.a.ln;
			var policy = config.b.j;
			return A3($author$project$Common$Policy$renderStatusBadge, 'align-self-flex-start', i18n, policy);
		default:
			var i18n = config.a.ln;
			var offer = config.b.C;
			return A3($author$project$Common$Offer$renderStatusBadge, 'align-self-flex-start', i18n, offer);
	}
};
var $author$project$Common$ComponentUI$DetailsResultBox$viewStatus = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details__status-row')
			]),
		_List_fromArray(
			[
				$author$project$Common$ComponentUI$DetailsResultBox$statusBadge(config),
				$author$project$Common$ComponentUI$DetailsResultBox$refundBadge(config),
				$author$project$Common$ComponentUI$DetailsResultBox$renewalBadge(config),
				$author$project$Common$ComponentUI$DetailsResultBox$infoBlock(config)
			]));
};
var $author$project$Common$ComponentUI$DetailsResultBox$view = function (config) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('details__result_box details__box')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('details__result_box__general_info_box')
					]),
				_List_fromArray(
					[
						$author$project$Common$ComponentUI$DetailsResultBox$viewHighlightRow(config),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__divider-horizontal')
							]),
						_List_Nil),
						$author$project$Common$ComponentUI$DetailsResultBox$viewStatus(config),
						$author$project$Common$ComponentUI$DetailsResultBox$viewAmendOfferIfAmendable(config),
						$author$project$Common$ComponentUI$DetailsResultBox$viewRenewalsReminderInfoBox(config),
						$author$project$Common$ComponentUI$DetailsResultBox$viewRenewalsCancellationInfoBox(config),
						$author$project$Common$ComponentUI$DetailsResultBox$viewGeneralInfoRow(config),
						$author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionInfo(config),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('details__divider-horizontal')
							]),
						_List_Nil),
						$author$project$Common$ComponentUI$DetailsResultBox$viewInterruptionsRow(config)
					]))
			]));
};
var $author$project$Common$ComponentUI$DetailsResultBox$viewFromOffer = F4(
	function (i18n, currentDate, actions, offer) {
		return $author$project$Common$ComponentUI$DetailsResultBox$view(
			A4($author$project$Common$ComponentUI$DetailsResultBox$newOfferViewConfiguration, i18n, currentDate, actions, offer));
	});
var $author$project$Common$ComponentUI$CustomElements$CopyButton$Config = $elm$core$Basics$identity;
var $author$project$Translations$copyComebackLinkCopiedLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Link copied!';
	} else {
		return 'Link copiado!';
	}
};
var $author$project$Translations$copyComebackLinkCopyLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Copy link of the offer';
	} else {
		return 'Copia link de la oferta';
	}
};
var $author$project$Common$ComponentUI$CustomElements$CopyButton$config = F2(
	function (i18n, copyTarget) {
		return {
			fj: _List_Nil,
			ib: $author$project$Translations$copyComebackLinkCopiedLabel(i18n),
			ic: $author$project$Translations$copyComebackLinkCopyLabel(i18n),
			id: copyTarget
		};
	});
var $author$project$Common$ComponentUI$CustomElements$CopyButton$render = function (_v0) {
	var configuration = _v0;
	return A3(
		$elm$html$Html$node,
		'copy-button',
		_List_fromArray(
			[
				A2($elm$html$Html$Attributes$attribute, 'data-text', configuration.ic),
				A2($elm$html$Html$Attributes$attribute, 'data-text-copied', configuration.ib),
				A2($elm$html$Html$Attributes$attribute, 'data-copy', configuration.id),
				$elm$html$Html$Attributes$classList(configuration.fj)
			]),
		_List_Nil);
};
var $author$project$Common$ComponentUI$CustomElements$CopyButton$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Page$OfferDetails$View$buttonWithCopyComebackLink = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Common$ComponentUI$CustomElements$CopyButton$config(i18n),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Common$ComponentUI$CustomElements$CopyButton$withClassList(
				_List_fromArray(
					[
						_Utils_Tuple2('padding-h-xs', true)
					])),
			$author$project$Common$ComponentUI$CustomElements$CopyButton$render));
};
var $author$project$Page$OfferDetails$Msg$SendEmailModalSubmitted = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$OfferDetails$Msg$SendOfferEmailCheckBoxChecked = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$OfferDetails$Msg$ShowSendEmailModal = function (a) {
	return {$: 17, a: a};
};
var $author$project$Pyxis$Components$IconSet$PaperPlane = 115;
var $author$project$Pyxis$Components$IconSet$paperPlane = 115;
var $author$project$Translations$sendOfferEmailsOpenModalButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Send emails';
	} else {
		return 'Enviar emails';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$renderModalButton = F2(
	function (i18n, showModal) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				showModal(true),
				A2(
					$author$project$Pyxis$Components$Button$withText,
					$author$project$Translations$sendOfferEmailsOpenModalButtonLabel(i18n),
					A2(
						$author$project$Pyxis$Components$Button$withIconPrepend,
						$author$project$Pyxis$Components$IconSet$paperPlane,
						A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeM, $author$project$Pyxis$Components$Button$tertiary)))));
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$SendNewBusinessOfferEmail = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$CheckboxGroup$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$CheckboxGroup$Default = 2;
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionList = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$config = function (name) {
	return {
		e_: $elm$core$Maybe$Nothing,
		fj: _List_Nil,
		fy: _List_Nil,
		fC: false,
		co: $elm$core$Maybe$Nothing,
		f2: $elm$core$Maybe$Nothing,
		iO: 'id-' + name,
		cz: false,
		d3: $elm$core$Maybe$Nothing,
		cD: 2,
		i6: name,
		mU: $author$project$Pyxis$Components$Field$CheckboxGroup$OptionList(_List_Nil),
		eO: $elm$core$Maybe$Nothing,
		dh: $elm$core$Maybe$Nothing
	};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCard = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$OptionCard$AddonSizeMType = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$OptionCard$addonSizeMType = $author$project$Pyxis$Components$Field$OptionCard$AddonSizeMType;
var $author$project$Pyxis$Components$Field$OptionCard$M = 0;
var $author$project$Pyxis$Components$Field$OptionCard$sizeM = 0;
var $author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeM = function (_v0) {
	var addon = _v0.ko;
	var text = _v0.nB;
	var title = _v0.hE;
	var value = _v0.eW;
	return {
		ko: A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$addonSizeMType, addon),
		fC: false,
		ax: $author$project$Pyxis$Components$Field$OptionCard$sizeM,
		nB: text,
		hE: title,
		eW: value
	};
};
var $author$project$Pyxis$Components$Form$FormItem$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$FormItem$config = F2(
	function (fieldConfig, field) {
		return {e_: $elm$core$Maybe$Nothing, is: field, it: fieldConfig, d3: $elm$core$Maybe$Nothing};
	});
var $author$project$Pyxis$Components$Field$Error$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Error$idFromFieldId = function (fieldId) {
	return fieldId + '-error';
};
var $author$project$Pyxis$Components$Field$Error$config = F3(
	function (id, validationResult, showingStrategy) {
		return {
			iO: $author$project$Pyxis$Components$Field$Error$idFromFieldId(id),
			lu: false,
			lw: false,
			cz: false,
			jQ: showingStrategy,
			hI: validationResult
		};
	});
var $author$project$Pyxis$Components$Field$Error$withIsBlurred = F2(
	function (isBlurred, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{lu: isBlurred});
	});
var $author$project$Pyxis$Components$Field$Error$withIsDirty = F2(
	function (isDirty, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{lw: isDirty});
	});
var $author$project$Pyxis$Components$Field$Error$withIsSubmitted = F2(
	function (isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{cz: isSubmitted});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dK;
		var checkedValues = _v0.ao;
		var id = _v1.iO;
		var isSubmitted = _v1.cz;
		var validation = _v1.dh;
		var errorShowingStrategy = _v1.co;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.lw),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.lu),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(checkedValues),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Commons$ValidationResult$getErrorMessage = function (result) {
	if (!result.$) {
		return $elm$core$Maybe$Nothing;
	} else {
		var errorMessage = result.a;
		return $elm$core$Maybe$Just(errorMessage);
	}
};
var $author$project$Pyxis$Components$Field$Error$getErrorIf = F2(
	function (condition, validationResult) {
		return condition ? $author$project$Pyxis$Commons$ValidationResult$getErrorMessage(validationResult) : $elm$core$Maybe$Nothing;
	});
var $author$project$Pyxis$Components$Field$Error$getErrorMessage = F2(
	function (_v0, validationResult) {
		var showingStrategy = _v0.jQ;
		var isSubmitted = _v0.cz;
		var isDirty = _v0.lw;
		var isBlurred = _v0.lu;
		switch (showingStrategy) {
			case 0:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted || isDirty, validationResult);
			case 1:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted || isBlurred, validationResult);
			default:
				return A2($author$project$Pyxis$Components$Field$Error$getErrorIf, isSubmitted, validationResult);
		}
	});
var $author$project$Pyxis$Components$Field$Error$isVisible = function (maybeConfig) {
	return $elm_community$maybe_extra$Maybe$Extra$isJust(
		A2(
			$elm$core$Maybe$andThen,
			function (config_) {
				var validationResult = config_.hI;
				return A2($author$project$Pyxis$Components$Field$Error$getErrorMessage, config_, validationResult);
			},
			maybeConfig));
};
var $author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible = F2(
	function (error, fieldId) {
		return $author$project$Pyxis$Components$Field$Error$isVisible(error) ? $elm$core$Maybe$Just(
			$author$project$Pyxis$Components$Field$Error$idFromFieldId(fieldId)) : $elm$core$Maybe$Nothing;
	});
var $author$project$Pyxis$Components$Field$Label$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Label$withFor = F2(
	function (_for, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				dL: $elm$core$Maybe$Just(_for)
			});
	});
var $author$project$Pyxis$Components$Field$Label$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Form$FormItem$customizeLabel = function (configData) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Field$Label$withId(configData.iO + '-label'),
		$author$project$Pyxis$Components$Field$Label$withFor(configData.iO));
};
var $elm$html$Html$Attributes$for = $elm$html$Html$Attributes$stringProperty('htmlFor');
var $author$project$Pyxis$Components$Field$Label$getFormLabelSizeClass = function (size) {
	if (!size) {
		return 'form-label--s';
	} else {
		return '';
	}
};
var $elm$html$Html$label = _VirtualDom_node('label');
var $author$project$Pyxis$Commons$Render$renderMaybeText = A2(
	$elm$core$Basics$composeR,
	$elm$core$Maybe$map($elm$html$Html$text),
	$author$project$Pyxis$Commons$Render$renderMaybe);
var $elm$html$Html$small = _VirtualDom_node('small');
var $author$project$Pyxis$Components$Field$Label$renderSubText = function (subText) {
	return A2(
		$elm$html$Html$small,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-label__sub')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(subText)
			]));
};
var $author$project$Pyxis$Components$Field$Label$wrapperId = $elm$core$Maybe$map(
	A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Basics$append, '-wrapper'));
var $author$project$Pyxis$Components$Field$Label$render = function (_v0) {
	var _for = _v0.dL;
	var classList = _v0.fj;
	var id = _v0.iO;
	var testId = _v0.eO;
	var size = _v0.ax;
	var text = _v0.nB;
	var subText = _v0.eH;
	var tooltip = _v0.eR;
	var isHidden = _v0.dW;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-label-wrapper'),
				$elm$html$Html$Attributes$classList(classList),
				A2(
				$author$project$Pyxis$Commons$Attributes$maybe,
				$author$project$Pyxis$Commons$Attributes$testId,
				$author$project$Pyxis$Components$Field$Label$wrapperId(id))
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-label', true),
								_Utils_Tuple2('sr-only', isHidden),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$Label$getFormLabelSizeClass(size),
								true)
							])),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$for, _for),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId)
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybeText(text),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$Label$renderSubText, subText))
					])),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					function (tooltipConfig) {
						return A3($author$project$Pyxis$Components$Tooltip$render, tooltipConfig.hz, tooltipConfig.d9, tooltipConfig.h9);
					},
					tooltip))
			]));
};
var $author$project$Pyxis$Components$Field$Error$renderError = F2(
	function (id, error) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-item__error-message'),
					$elm$html$Html$Attributes$id(id),
					$author$project$Pyxis$Commons$Attributes$role('alert')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeS,
						$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$exclamationCircle))),
					$elm$html$Html$text(error)
				]));
	});
var $author$project$Pyxis$Components$Field$Error$render = function (config_) {
	var id = config_.iO;
	var validationResult = config_.hI;
	return $author$project$Pyxis$Commons$Render$renderMaybe(
		A2(
			$elm$core$Maybe$map,
			$author$project$Pyxis$Components$Field$Error$renderError(id),
			A2($author$project$Pyxis$Components$Field$Error$getErrorMessage, config_, validationResult)));
};
var $author$project$Pyxis$Components$Field$Hint$render = function (_v0) {
	var id = _v0.iO;
	var message = _v0.lP;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-item__hint'),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id)
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(message)
			]));
};
var $author$project$Pyxis$Components$Field$Hint$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Hint$toId = function (fieldId) {
	return fieldId + '-hint';
};
var $author$project$Pyxis$Components$Field$Hint$withFieldId = F2(
	function (fieldId, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iO: $elm$core$Maybe$Just(
					$author$project$Pyxis$Components$Field$Hint$toId(fieldId))
			});
	});
var $author$project$Pyxis$Components$Form$FormItem$renderErrorOrHint = F3(
	function (id, errorConfig, hintConfig) {
		return $author$project$Pyxis$Components$Field$Error$isVisible(errorConfig) ? $author$project$Pyxis$Commons$Render$renderMaybe(
			A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$Error$render, errorConfig)) : $author$project$Pyxis$Commons$Render$renderMaybe(
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Hint$withFieldId(id),
					$author$project$Pyxis$Components$Field$Hint$render),
				hintConfig));
	});
var $author$project$Pyxis$Components$Form$FormItem$render = F2(
	function (error, _v0) {
		var label = _v0.d3;
		var field = _v0.is;
		var fieldConfig = _v0.it;
		var additionalContent = _v0.e_;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-item')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							$author$project$Pyxis$Components$Form$FormItem$customizeLabel(fieldConfig),
							$author$project$Pyxis$Components$Field$Label$render),
						label)),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-item__wrapper')
						]),
					_List_fromArray(
						[
							field,
							A3($author$project$Pyxis$Components$Form$FormItem$renderErrorOrHint, fieldConfig.iO, error, fieldConfig.f2)
						])),
					$author$project$Pyxis$Commons$Render$renderMaybe(additionalContent)
				]));
	});
var $author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint = F2(
	function (errorId, hintId) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Pyxis$Commons$Attributes$none,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Attributes$ariaDescribedBy,
				A2($elm_community$maybe_extra$Maybe$Extra$or, errorId, hintId)));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardControlLayoutClass = function (layout) {
	switch (layout) {
		case 2:
			return '';
		case 0:
			return '';
		default:
			return 'form-control-group--column';
	}
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$labelId = function (id) {
	return id + '-label';
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus = {$: 1};
var $author$project$Pyxis$Commons$Attributes$dataAttribute = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return A2($elm$html$Html$Attributes$attribute, 'data-' + key, value);
};
var $author$project$Pyxis$Commons$Attributes$appendDataAttrList = A2(
	$elm$core$Basics$composeR,
	$elm$core$List$map($author$project$Pyxis$Commons$Attributes$dataAttribute),
	$elm$core$List$append);
var $author$project$Pyxis$Commons$Attributes$ariaInvalid = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Commons$String$fromBool,
	$elm$html$Html$Attributes$attribute('aria-invalid'));
var $elm$html$Html$Attributes$checked = $elm$html$Html$Attributes$boolProperty('checked');
var $elm$html$Html$input = _VirtualDom_node('input');
var $elm$html$Html$Attributes$name = $elm$html$Html$Attributes$stringProperty('name');
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetChecked = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'checked']),
	$elm$json$Json$Decode$bool);
var $elm$html$Html$Events$onCheck = function (tagger) {
	return A2(
		$elm$html$Html$Events$on,
		'change',
		A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetChecked));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderCheckbox = F5(
	function (tagger, configData, checkedValues, hasError, _v0) {
		var disabled = _v0.fC;
		var value = _v0.eW;
		var label = _v0.d3;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-control', true),
							_Utils_Tuple2('form-control-option--error', hasError)
						]))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$input,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						configData.fy,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_('checkbox'),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-control__checkbox', true),
										_Utils_Tuple2('form-control--disabled', disabled || configData.fC)
									])),
								$elm$html$Html$Attributes$checked(
								A2($elm$core$List$member, value, checkedValues)),
								$elm$html$Html$Attributes$disabled(disabled || configData.fC),
								$elm$html$Html$Attributes$name(configData.i6),
								$elm$html$Html$Events$onCheck(
								A2(
									$elm$core$Basics$composeR,
									$author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck(value),
									tagger)),
								$elm$html$Html$Events$onFocus(
								tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus)),
								$elm$html$Html$Events$onBlur(
								tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur)),
								$author$project$Pyxis$Commons$Attributes$ariaInvalid(hasError)
							])),
					_List_Nil),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-control__text')
						]),
					_List_fromArray(
						[label]))
				]));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderOptions = F5(
	function (tagger, configData, errorId, checkedValues, options) {
		var renderer = A4(
			$author$project$Pyxis$Components$Field$CheckboxGroup$renderCheckbox,
			tagger,
			configData,
			checkedValues,
			$elm_community$maybe_extra$Maybe$Extra$isJust(errorId));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-control-group', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardControlLayoutClass(configData.cD),
							true)
						])),
					$elm$html$Html$Attributes$classList(configData.fj),
					$elm$html$Html$Attributes$id(configData.iO),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$elm$core$List$length(options) > 1,
					$author$project$Pyxis$Commons$Attributes$role('group')),
					$author$project$Pyxis$Commons$Attributes$ariaDisabled(configData.fC),
					A2(
					$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
					errorId,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always(
							$author$project$Pyxis$Components$Field$Hint$toId(configData.iO)),
						configData.f2)),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					$author$project$Pyxis$Components$Field$CheckboxGroup$labelId(configData.iO))
				]),
			A2($elm$core$List$map, renderer, options));
	});
var $author$project$Pyxis$Components$Field$OptionCard$Config = F4(
	function (name, fieldId, label, groupDisabled) {
		return {iu: fieldId, iH: groupDisabled, d3: label, i6: name};
	});
var $author$project$Pyxis$Components$Field$OptionCard$config = $author$project$Pyxis$Components$Field$OptionCard$Config;
var $author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardGroupLayoutClass = function (layout) {
	switch (layout) {
		case 2:
			return '';
		case 1:
			return 'form-card-group--column';
		default:
			return 'form-card-group--row';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$Checkbox = 1;
var $elm$core$String$replace = F3(
	function (before, after, string) {
		return A2(
			$elm$core$String$join,
			after,
			A2($elm$core$String$split, before, string));
	});
var $author$project$Pyxis$Commons$String$toKebabCase = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$toLower,
	A2($elm$core$String$replace, ' ', '-'));
var $author$project$Pyxis$Components$Field$OptionCard$generateOptionId = F3(
	function (_v0, _v1, index) {
		var fieldId = _v0.iu;
		var text = _v1.nB;
		var title = _v1.hE;
		return A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					fieldId,
					$author$project$Pyxis$Commons$String$toKebabCase(
					A2(
						$elm$core$String$left,
						25,
						A2(
							$elm$core$Maybe$withDefault,
							$elm$core$String$fromInt(index),
							A2($elm_community$maybe_extra$Maybe$Extra$or, title, text)))),
					'option'
				]));
	});
var $author$project$Pyxis$Components$Field$OptionCard$getFormCardSizeClass = function (size) {
	if (size === 1) {
		return 'form-card--l';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumAppendAddon = function (addon) {
	if (!addon.$) {
		var str = addon.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__addon')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderAppendAddon = function (addon) {
	if (!addon.$) {
		var addonSizeM = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumAppendAddon(addonSizeM);
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderContent = F2(
	function (identifier, str) {
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__' + identifier)
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(str)
				]));
	});
var $elm$html$Html$Attributes$alt = $elm$html$Html$Attributes$stringProperty('alt');
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeLargePrependAddon = function (_v0) {
	var url = _v0;
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-card__addon')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src(url),
						$elm$html$Html$Attributes$width(72),
						$elm$html$Html$Attributes$height(72),
						$elm$html$Html$Attributes$alt(''),
						$author$project$Pyxis$Commons$Attributes$ariaHidden(true)
					]),
				_List_Nil)
			]));
};
var $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumPrependAddon = function (addon) {
	if (addon.$ === 1) {
		var icon = addon.a;
		return A2(
			$elm$html$Html$span,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-card__addon form-card__addon--with-icon'),
					$author$project$Pyxis$Commons$Attributes$testId(
					$author$project$Pyxis$Components$IconSet$toLabel(icon))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Icon$render(
					A2(
						$author$project$Pyxis$Components$Icon$withSize,
						$author$project$Pyxis$Components$Icon$sizeM,
						$author$project$Pyxis$Components$Icon$config(icon)))
				]));
	} else {
		return $elm$html$Html$text('');
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$renderPrependAddon = function (addon) {
	if (!addon.$) {
		var addonSizeM = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeMediumPrependAddon(addonSizeM);
	} else {
		var addonSizeL = addon.a;
		return $author$project$Pyxis$Components$Field$OptionCard$renderSizeLargePrependAddon(addonSizeL);
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$toType = function (type_) {
	if (!type_) {
		return 'radio';
	} else {
		return 'checkbox';
	}
};
var $author$project$Pyxis$Components$Field$OptionCard$render = F6(
	function (type_, hasError, attributes, configData, index, option) {
		var name = configData.i6;
		var groupDisabled = configData.iH;
		var id = A3($author$project$Pyxis$Components$Field$OptionCard$generateOptionId, configData, option, index);
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-card', true),
							_Utils_Tuple2('form-card--error', hasError),
							_Utils_Tuple2('form-card--checked', option.lv),
							_Utils_Tuple2('form-card--disabled', option.fC || groupDisabled),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$OptionCard$getFormCardSizeClass(option.ax),
							true)
						]))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$renderPrependAddon, option.ko)),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-card__content-wrapper')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Field$OptionCard$renderContent('title'),
								option.hE)),
							$author$project$Pyxis$Commons$Render$renderMaybe(
							A2(
								$elm$core$Maybe$map,
								$author$project$Pyxis$Components$Field$OptionCard$renderContent('text'),
								option.nB))
						])),
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Field$OptionCard$renderAppendAddon, option.ko)),
					A2(
					$elm$html$Html$input,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						attributes,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$type_(
								$author$project$Pyxis$Components$Field$OptionCard$toType(type_)),
								$elm$html$Html$Attributes$class(
								'form-control__' + $author$project$Pyxis$Components$Field$OptionCard$toType(type_)),
								$elm$html$Html$Attributes$checked(option.lv),
								$elm$html$Html$Attributes$disabled(option.fC || groupDisabled),
								$elm$html$Html$Attributes$id(id),
								$author$project$Pyxis$Commons$Attributes$testId(id),
								$elm$html$Html$Attributes$name(name),
								$elm$html$Html$Events$onCheck(option.ed),
								$elm$html$Html$Events$onFocus(option.gL),
								$elm$html$Html$Events$onBlur(option.gE)
							])),
					_List_Nil)
				]));
	});
var $author$project$Pyxis$Components$Field$OptionCard$renderCheckbox = $author$project$Pyxis$Components$Field$OptionCard$render(1);
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderOptionsCard = F3(
	function (_v0, errorId, options) {
		var name = _v0.i6;
		var layout = _v0.cD;
		var classList = _v0.fj;
		var id = _v0.iO;
		var disabled = _v0.fC;
		var hint = _v0.f2;
		var testId = _v0.eO;
		var dataAttrList = _v0.fy;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-card-group', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Field$CheckboxGroup$getFormCardGroupLayoutClass(layout),
							true)
						])),
					$elm$html$Html$Attributes$classList(classList),
					$elm$html$Html$Attributes$id(id),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					$author$project$Pyxis$Commons$Attributes$role('group'),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
					$author$project$Pyxis$Components$Field$CheckboxGroup$labelId(id)),
					$author$project$Pyxis$Commons$Attributes$ariaDisabled(disabled),
					A2(
					$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
					errorId,
					A2(
						$elm$core$Maybe$map,
						$elm$core$Basics$always(
							$author$project$Pyxis$Components$Field$Hint$toId(id)),
						hint))
				]),
			A2(
				$elm$core$List$indexedMap,
				A3(
					$author$project$Pyxis$Components$Field$OptionCard$renderCheckbox,
					$elm_community$maybe_extra$Maybe$Extra$isJust(errorId),
					dataAttrList,
					A4($author$project$Pyxis$Components$Field$OptionCard$config, name, id, $elm$core$Maybe$Nothing, disabled)),
				options));
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$toInternal = F3(
	function (tagger, checkedValues, _v0) {
		var addon = _v0.ko;
		var text = _v0.nB;
		var title = _v0.hE;
		var value = _v0.eW;
		var size = _v0.ax;
		var disabled = _v0.fC;
		var isChecked = A2($elm$core$List$member, value, checkedValues);
		return {
			ko: addon,
			fC: disabled,
			lv: isChecked,
			gE: tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnBlur),
			ed: A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Field$CheckboxGroup$OnCheck(value),
				tagger),
			gL: tagger($author$project$Pyxis$Components$Field$CheckboxGroup$OnFocus),
			ax: size,
			nB: text,
			hE: title,
			eW: value
		};
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$renderControlGroup = F5(
	function (tagger, configData, errorId, checkedValues, options) {
		if (!options.$) {
			var optionSimples = options.a;
			return A5($author$project$Pyxis$Components$Field$CheckboxGroup$renderOptions, tagger, configData, errorId, checkedValues, optionSimples);
		} else {
			var optionsCard = options.a;
			return A3(
				$author$project$Pyxis$Components$Field$CheckboxGroup$renderOptionsCard,
				configData,
				errorId,
				A2(
					$elm$core$List$map,
					A2($author$project$Pyxis$Components$Field$CheckboxGroup$toInternal, tagger, checkedValues),
					optionsCard));
		}
	});
var $author$project$Pyxis$Components$Form$FormItem$withAdditionalContent = F2(
	function (additionalContent, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{e_: additionalContent});
	});
var $author$project$Pyxis$Components$Form$FormItem$withLabel = F2(
	function (label, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{d3: label});
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$render = F3(
	function (tagger, model, config_) {
		var checkedValues = model.ao;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$CheckboxGroup$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.e_,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.d3,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A5(
							$author$project$Pyxis$Components$Field$CheckboxGroup$renderControlGroup,
							tagger,
							configData,
							A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.iO),
							checkedValues,
							configData.mU)))));
	});
var $author$project$Translations$sendOfferEmailsOfferCheckbox = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Email offer';
	} else {
		return 'Email de la oferta';
	}
};
var $author$project$Translations$sendOfferEmailsSelectionError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'You need to select an email';
	} else {
		return 'Debes seleccionar un email';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$validation = F2(
	function (i18n, selected) {
		if (!selected.b) {
			return $elm$core$Result$Err(
				$author$project$Translations$sendOfferEmailsSelectionError(i18n));
		} else {
			return $elm$core$Result$Ok(selected);
		}
	});
var $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCardList = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Field$CheckboxGroup$withOptionsCard = F2(
	function (options, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				mU: $author$project$Pyxis$Components$Field$CheckboxGroup$OptionCardList(options)
			});
	});
var $author$project$Pyxis$Commons$ValidationResult$Invalid = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Commons$ValidationResult$Valid = {$: 0};
var $author$project$Pyxis$Commons$ValidationResult$fromResult = function (result) {
	if (!result.$) {
		return $author$project$Pyxis$Commons$ValidationResult$Valid;
	} else {
		var error = result.a;
		return $author$project$Pyxis$Commons$ValidationResult$Invalid(error);
	}
};
var $author$project$Pyxis$Components$Field$Error$OnSubmit = 2;
var $author$project$Pyxis$Components$Field$Error$onSubmit = 2;
var $author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				co: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				cz: isSubmitted,
				dh: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$checkboxGroup = F4(
	function (i18n, onCheckBoxChange, offer, model) {
		return A3(
			$author$project$Pyxis$Components$Field$CheckboxGroup$render,
			onCheckBoxChange,
			model.bR,
			A2(
				$author$project$Pyxis$Components$Field$CheckboxGroup$withOptionsCard,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeM(
						{
							ko: $elm$core$Maybe$Nothing,
							nB: $elm$core$Maybe$Nothing,
							hE: $elm$core$Maybe$Just(
								$author$project$Translations$sendOfferEmailsOfferCheckbox(i18n)),
							eW: $author$project$Common$Offer$pickId(offer)
						})
					]),
				A3(
					$author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit,
					$author$project$Common$ComponentUI$Modal$SendOfferEmail$validation(i18n),
					model.ga,
					$author$project$Pyxis$Components$Field$CheckboxGroup$config('checkbox-name'))));
	});
var $author$project$Pyxis$Tokens$TextSize$TextSizeL = 0;
var $author$project$Pyxis$Tokens$TextSize$tokenL = 0;
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$emailAddressText = function (email) {
	return $author$project$Pyxis$Components$Text$render(
		A2(
			$author$project$Pyxis$Components$Text$withFontWeight,
			$author$project$Pyxis$Tokens$FontWeight$tokenBold,
			A2(
				$author$project$Pyxis$Components$Text$withSize,
				$author$project$Pyxis$Tokens$TextSize$tokenL,
				$author$project$Pyxis$Components$Text$config(
					$elm$html$Html$text(email)))));
};
var $author$project$Translations$sendOfferEmailsReceiver = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Emails will be sent to';
	} else {
		return 'El email se enviará a';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$senderReceiverText = function (i18n) {
	return $author$project$Pyxis$Components$Text$render(
		A2(
			$author$project$Pyxis$Components$Text$withSize,
			$author$project$Pyxis$Tokens$TextSize$tokenL,
			$author$project$Pyxis$Components$Text$config(
				$elm$html$Html$text(
					$author$project$Translations$sendOfferEmailsReceiver(i18n)))));
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$renderReceiverBlock = F2(
	function (i18n, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('send-offer-email__receiver-block')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Common$ComponentUI$Modal$SendOfferEmail$senderReceiverText(i18n)
						])),
					A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$Common$ComponentUI$Modal$SendOfferEmail$emailAddressText(model.g9)
						]))
				]));
	});
var $author$project$Translations$sendOfferEmailsWhichEmailText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Which email do you want to send to the customer?';
	} else {
		return '¿Qué email quieres enviarle al cliente?';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$whichEmailText = function (i18n) {
	return $author$project$Pyxis$Components$Text$render(
		A2(
			$author$project$Pyxis$Components$Text$withSize,
			$author$project$Pyxis$Tokens$TextSize$tokenL,
			$author$project$Pyxis$Components$Text$config(
				$elm$html$Html$text(
					$author$project$Translations$sendOfferEmailsWhichEmailText(i18n)))));
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalContent = F4(
	function (i18n, onCheckBoxChange, offer, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('send-offer-email__which-email-text')
					]),
				_List_fromArray(
					[
						$author$project$Common$ComponentUI$Modal$SendOfferEmail$whichEmailText(i18n)
					])),
				A4($author$project$Common$ComponentUI$Modal$SendOfferEmail$checkboxGroup, i18n, onCheckBoxChange, offer, model),
				A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$renderReceiverBlock, i18n, model)
			]);
	});
var $author$project$Translations$sendOfferEmailsBackButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Atrás';
	}
};
var $author$project$Pyxis$Components$Button$sizeL = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{ax: 1});
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$renderBackButton = F2(
	function (i18n, showModal) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withSize,
				$author$project$Pyxis$Components$Button$sizeL,
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					showModal(false),
					A2(
						$author$project$Pyxis$Components$Button$withText,
						$author$project$Translations$sendOfferEmailsBackButtonLabel(i18n),
						$author$project$Pyxis$Components$Button$secondary))));
	});
var $author$project$Translations$sendOfferEmailsSendButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Send emails';
	} else {
		return 'Enviar emails';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$renderSendEmailButton = F3(
	function (i18n, onSubmit, model) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withSize,
				$author$project$Pyxis$Components$Button$sizeL,
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					onSubmit(
						$author$project$Pyxis$Components$Field$CheckboxGroup$getValue(model.bR)),
					A2(
						$author$project$Pyxis$Components$Button$withText,
						$author$project$Translations$sendOfferEmailsSendButtonLabel(i18n),
						$author$project$Pyxis$Components$Button$primary))));
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalFooter = F4(
	function (i18n, onSubmit, showModal, model) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$renderBackButton, i18n, showModal),
					A3($author$project$Common$ComponentUI$Modal$SendOfferEmail$renderSendEmailButton, i18n, onSubmit, model)
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$iconModalHeder = A2(
	$author$project$Pyxis$Components$Icon$withColor,
	$author$project$Pyxis$Tokens$Color$tokenBrandBase,
	$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$paperPlane));
var $author$project$Translations$sendOfferEmailsTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Set up email sending';
	} else {
		return 'Configurar el envío de emails';
	}
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalHeader = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withIcon,
		$author$project$Common$ComponentUI$Modal$SendOfferEmail$iconModalHeder,
		A2(
			$author$project$Pyxis$Components$Modal$Header$withTitle,
			$author$project$Translations$sendOfferEmailsTitle(i18n),
			$author$project$Pyxis$Components$Modal$Header$config));
};
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$renderSendEmailModal = F6(
	function (i18n, onCheckBoxChange, onSubmit, showModal, offer, model) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			model.g3,
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A4($author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalFooter, i18n, onSubmit, showModal, model),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A4($author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalContent, i18n, onCheckBoxChange, offer, model),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Common$ComponentUI$Modal$SendOfferEmail$sendEmailModalHeader(i18n),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							showModal(false),
							'Close',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Common$ComponentUI$Modal$SendOfferEmail$render = F6(
	function (i18n, showModal, onCheckBoxChange, onSubmit, offer, model) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$Common$ComponentUI$Modal$SendOfferEmail$renderModalButton, i18n, showModal),
					A6($author$project$Common$ComponentUI$Modal$SendOfferEmail$renderSendEmailModal, i18n, onCheckBoxChange, onSubmit, showModal, offer, model)
				]));
	});
var $author$project$Page$OfferDetails$View$buttonWithSendEmailModal = F3(
	function (i18n, model, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('feature-flag--sendEmail')
				]),
			_List_fromArray(
				[
					A6($author$project$Common$ComponentUI$Modal$SendOfferEmail$render, i18n, $author$project$Page$OfferDetails$Msg$ShowSendEmailModal, $author$project$Page$OfferDetails$Msg$SendOfferEmailCheckBoxChecked, $author$project$Page$OfferDetails$Msg$SendEmailModalSubmitted, offer, model.y)
				]));
	});
var $author$project$Page$OfferDetails$Msg$RedirectToSearch = function (a) {
	return {$: 5, a: a};
};
var $author$project$Page$OfferDetails$View$navigateToResults = A2(
	$elm$core$Basics$composeR,
	$author$project$Common$Offer$pickPolicyHolder,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Common$Person$pickPersonData,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ai;
			},
			A2(
				$elm$core$Basics$composeR,
				$author$project$Page$Search$SearchQueryParam$DocumentId,
				A2($elm$core$Basics$composeR, $elm$core$Result$Ok, $author$project$Page$OfferDetails$Msg$RedirectToSearch)))));
var $author$project$Common$Offer$pickComebackLink = function (_v0) {
	var offer = _v0;
	return offer.fo;
};
var $author$project$Pyxis$Components$IconSet$ChevronLeft = 31;
var $author$project$Pyxis$Components$IconSet$chevronLeft = 31;
var $author$project$Translations$detailsBackToResultsButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back to results';
	} else {
		return 'Volver a los resultados';
	}
};
var $author$project$Common$ComponentUI$BackToResults$view = F2(
	function (i18n, onClickHandler) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('back-to-results-container')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withIconPrepend,
						$author$project$Pyxis$Components$IconSet$chevronLeft,
						A2(
							$author$project$Pyxis$Components$Button$withSize,
							$author$project$Pyxis$Components$Button$sizeM,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$detailsBackToResultsButton(i18n),
								A2($author$project$Pyxis$Components$Button$withOnClick, onClickHandler, $author$project$Pyxis$Components$Button$ghost)))))
				]));
	});
var $author$project$Page$OfferDetails$View$viewHeaderContainer = F3(
	function (i18n, model, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('offer-details-header-container')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Common$ComponentUI$BackToResults$view,
					i18n,
					$author$project$Page$OfferDetails$View$navigateToResults(offer)),
					A2(
					$author$project$Common$Render$maybe,
					$author$project$Page$OfferDetails$View$buttonWithCopyComebackLink(i18n),
					$author$project$Common$Offer$pickComebackLink(offer)),
					A3($author$project$Page$OfferDetails$View$buttonWithSendEmailModal, i18n, model, offer)
				]));
	});
var $author$project$Page$OfferDetails$Msg$RetryGetPaymentMethod = function (a) {
	return {$: 15, a: a};
};
var $author$project$Translations$paymentDetailsPaymentDetailsError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The payment method information couldn’t be loaded. Please try again in a moment.';
	} else {
		return 'La información del método de pago no pudo ser cargada. Inténtalo de nuevo en un momento.';
	}
};
var $author$project$Pyxis$Components$Card$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Card$M = 1;
var $author$project$Pyxis$Components$IconSet$ChevronRight = 32;
var $author$project$Pyxis$Components$IconSet$chevronRight = 32;
var $author$project$Pyxis$Components$Card$config = {al: $elm$core$Maybe$Nothing, dm: $author$project$Pyxis$Components$IconSet$chevronRight, ko: $elm$core$Maybe$Nothing, fj: _List_Nil, bf: $elm$core$Maybe$Nothing, fO: $elm$core$Maybe$Nothing, f_: $elm$core$Maybe$Nothing, iO: $elm$core$Maybe$Nothing, ax: 1, eO: $elm$core$Maybe$Nothing};
var $author$project$Translations$paymentDetailsPaymentMethodDetails = F2(
	function (data, _v0) {
		var lang = _v0;
		if (!lang) {
			return data.a8 + (' ' + (data.ce + (' | Expire date: ' + data.cq)));
		} else {
			return data.a8 + (' ' + (data.ce + (' | Caducidad: ' + data.cq)));
		}
	});
var $author$project$Common$PaymentMethod$pickName = function (brand) {
	switch (brand.$) {
		case 0:
			return 'Visa';
		case 1:
			return 'MasterCard';
		case 2:
			return 'Maestro';
		default:
			var name = brand.a;
			return name;
	}
};
var $author$project$Common$PaymentMethod$paymentMethodContent = F2(
	function (i18n, paymentMethod) {
		return $author$project$Pyxis$Components$Text$render(
			A2(
				$author$project$Pyxis$Components$Text$withColor,
				$author$project$Pyxis$Tokens$Color$tokenNeutral25,
				$author$project$Pyxis$Components$Text$config(
					$elm$html$Html$text(
						A2(
							$author$project$Translations$paymentDetailsPaymentMethodDetails,
							{
								a8: $author$project$Common$PaymentMethod$pickName(paymentMethod.a8),
								ce: paymentMethod.ce,
								cq: paymentMethod.cq
							},
							i18n)))));
	});
var $author$project$Pyxis$Components$Card$Header = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Card$header = {dq: _List_Nil, bf: $elm$core$Maybe$Nothing, da: $elm$core$Maybe$Nothing, hE: $elm$core$Maybe$Nothing};
var $author$project$Translations$paymentDetailsPaymentMethodExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Expired card';
	} else {
		return 'Tarjeta expirada';
	}
};
var $author$project$Translations$paymentDetailsPaymentMethodTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Payment method';
	} else {
		return 'Método de pago';
	}
};
var $author$project$Pyxis$Components$Title$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Commons$TagBuilder$h2 = 'h2';
var $author$project$Pyxis$Tokens$TitleSize$TitleSizeM = 4;
var $author$project$Pyxis$Tokens$TitleSize$tokenM = 4;
var $author$project$Pyxis$Components$Title$config = function (content) {
	return {fj: _List_Nil, fn: $elm$core$Maybe$Nothing, bf: content, fN: $author$project$Pyxis$Tokens$FontWeight$tokenBook, iO: $elm$core$Maybe$Nothing, gh: _List_Nil, hf: _List_Nil, ax: $author$project$Pyxis$Tokens$TitleSize$tokenM, hy: $author$project$Pyxis$Commons$TagBuilder$h2, eO: $elm$core$Maybe$Nothing};
};
var $author$project$Pyxis$Components$Title$withFontWeight = F2(
	function (fontWeight, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fN: fontWeight});
	});
var $author$project$Pyxis$Components$Title$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Common$PaymentMethod$paymentMethodTitleConfig = function (title) {
	return A2(
		$author$project$Pyxis$Components$Title$withFontWeight,
		$author$project$Pyxis$Tokens$FontWeight$tokenBold,
		A2(
			$author$project$Pyxis$Components$Title$withSize,
			$author$project$Pyxis$Tokens$TitleSize$tokenM,
			$author$project$Pyxis$Components$Title$config(
				$elm$html$Html$text(title))));
};
var $author$project$Pyxis$Tokens$TitleSize$toString = function (titleSize) {
	switch (titleSize) {
		case 0:
			return '3xl';
		case 1:
			return '2xl';
		case 2:
			return 'xl';
		case 3:
			return 'l';
		case 4:
			return 'm';
		default:
			return 's';
	}
};
var $author$project$Pyxis$Components$Title$baseClass = F2(
	function (size, fontWeight) {
		return $elm$html$Html$Attributes$class(
			A2(
				$elm$core$String$join,
				'-',
				_List_fromArray(
					[
						'title',
						$author$project$Pyxis$Tokens$TitleSize$toString(size),
						$author$project$Pyxis$Tokens$FontWeight$toString(fontWeight)
					])));
	});
var $author$project$Pyxis$Components$Title$marginClassList = function (margins) {
	return $elm$html$Html$Attributes$classList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Commons$Attributes$Margin$toClass,
				A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Tuple$pair, true)),
			margins));
};
var $author$project$Pyxis$Components$Title$responsiveSizeClass = function (responsiveSize) {
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			[
				'title',
				$author$project$Pyxis$Tokens$TitleSize$toString(
				$author$project$Pyxis$Commons$Responsive$pickPropertyValue(responsiveSize)),
				'from',
				$author$project$Pyxis$Tokens$Breakpoint$toString(
				$author$project$Pyxis$Commons$Responsive$pickBreakpoint(responsiveSize))
			]));
};
var $author$project$Pyxis$Components$Title$responsiveSizeClassList = function (responsiveSizes) {
	return $elm$html$Html$Attributes$classList(
		A2(
			$elm$core$List$map,
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Title$responsiveSizeClass,
				A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Tuple$pair, true)),
			responsiveSizes));
};
var $author$project$Pyxis$Components$Title$render = function (_v0) {
	var content = _v0.bf;
	var margins = _v0.gh;
	var id = _v0.iO;
	var classList = _v0.fj;
	var testId = _v0.eO;
	var color = _v0.fn;
	var tag = _v0.hy;
	var fontWeight = _v0.fN;
	var size = _v0.ax;
	var responsiveSize = _v0.hf;
	return A3(
		$elm$html$Html$node,
		$author$project$Pyxis$Commons$TagBuilder$unwrap(tag),
		_List_fromArray(
			[
				A2($author$project$Pyxis$Components$Title$baseClass, size, fontWeight),
				$author$project$Pyxis$Components$Title$responsiveSizeClassList(responsiveSize),
				$author$project$Pyxis$Components$Title$marginClassList(margins),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$colorClass, color)
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Pyxis$Components$Title$withColor = F2(
	function (color, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fn: $elm$core$Maybe$Just(color)
			});
	});
var $author$project$Common$PaymentMethod$paymentMethodTitle = F2(
	function (i18n, creditCardExpiration) {
		if (!creditCardExpiration) {
			return $author$project$Pyxis$Components$Title$render(
				$author$project$Common$PaymentMethod$paymentMethodTitleConfig(
					$author$project$Translations$paymentDetailsPaymentMethodTitle(i18n)));
		} else {
			return $author$project$Pyxis$Components$Title$render(
				A2(
					$author$project$Pyxis$Components$Title$withColor,
					$author$project$Pyxis$Tokens$Color$tokenErrorBase,
					$author$project$Common$PaymentMethod$paymentMethodTitleConfig(
						$author$project$Translations$paymentDetailsPaymentMethodExpired(i18n))));
		}
	});
var $author$project$Pyxis$Components$Card$withHeaderTitle = F2(
	function (title, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				hE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Common$PaymentMethod$paymentMethodHeader = F2(
	function (i18n, paymentMethod) {
		return A2(
			$author$project$Pyxis$Components$Card$withHeaderTitle,
			A2($author$project$Common$PaymentMethod$paymentMethodTitle, i18n, paymentMethod.dv),
			$author$project$Pyxis$Components$Card$header);
	});
var $author$project$Pyxis$Components$Card$isAddonLeft = function (placement) {
	if (placement === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Card$isAddonPlacement = F2(
	function (mapper, maybeAddon) {
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.S;
					},
					mapper),
				maybeAddon));
	});
var $author$project$Pyxis$Components$Card$isJustActionCard = function (maybeAction) {
	_v0$2:
	while (true) {
		if (!maybeAction.$) {
			switch (maybeAction.a.$) {
				case 0:
					return true;
				case 2:
					var _v1 = maybeAction.a;
					return true;
				default:
					break _v0$2;
			}
		} else {
			break _v0$2;
		}
	}
	return false;
};
var $author$project$Pyxis$Components$Card$renderAction = function (icon) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card__action')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('card__header__action__icon', true)
						]),
					$author$project$Pyxis$Components$Icon$config(icon)))
			]));
};
var $author$project$Pyxis$Components$Card$renderAddonByType = function (addonType) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card__addon')
			]),
		_List_fromArray(
			[
				function () {
				switch (addonType.$) {
					case 0:
						var iconConfig = addonType.a;
						return $author$project$Pyxis$Components$Icon$render(iconConfig);
					case 1:
						var url = addonType.a;
						return A2(
							$elm$html$Html$img,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$src(url),
									$elm$html$Html$Attributes$height(48),
									$elm$html$Html$Attributes$width(48)
								]),
							_List_Nil);
					default:
						var html = addonType.a;
						return html;
				}
			}()
			]));
};
var $author$project$Pyxis$Components$Card$renderContent = function (content) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card__content')
			]),
		_List_fromArray(
			[content]));
};
var $author$project$Pyxis$Components$Card$renderFooter = function (footer) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card__footer')
			]),
		_List_fromArray(
			[footer]));
};
var $author$project$Pyxis$Components$Card$isAddonHeader = function (placement) {
	if (placement === 2) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Card$isAddonTop = function (placement) {
	if (!placement) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Card$renderAddonIfPlacement = F2(
	function (mapper, addon) {
		return A2(
			$author$project$Pyxis$Commons$Render$renderIf,
			mapper(addon.S),
			$author$project$Pyxis$Components$Card$renderAddonByType(addon.eT));
	});
var $author$project$Pyxis$Components$Button$linkWithTarget = F2(
	function (href, linkTarget) {
		return A2(
			$author$project$Pyxis$Components$Button$Link,
			href,
			$elm$core$Maybe$Just(linkTarget));
	});
var $author$project$Pyxis$Components$Card$renderInlineButton = F3(
	function (label, actionIcon, buttonConfig) {
		return $author$project$Pyxis$Components$Button$render(
			buttonConfig(
				A2(
					$author$project$Pyxis$Components$Button$withIconAppend,
					actionIcon,
					A2(
						$author$project$Pyxis$Components$Button$withSize,
						$author$project$Pyxis$Components$Button$sizeS,
						A2($author$project$Pyxis$Components$Button$withText, label, $author$project$Pyxis$Components$Button$ghost)))));
	});
var $author$project$Pyxis$Components$Card$renderSubtitle = function (subtitle) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('card__header__subtitle')
			]),
		_List_fromArray(
			[subtitle]));
};
var $author$project$Pyxis$Components$Card$renderTitleAndSubtitle = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(configuration.hE),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Card$renderSubtitle, configuration.da))
			]));
};
var $author$project$Pyxis$Components$Card$renderActionByType = F3(
	function (icon, headerConfig, action) {
		var configuration = headerConfig;
		switch (action.$) {
			case 0:
				var msg = action.a;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$button,
							_List_fromArray(
								[
									$elm$html$Html$Events$onClick(msg),
									$elm$html$Html$Attributes$class('card__header__action')
								]),
							_List_fromArray(
								[
									$author$project$Pyxis$Commons$Render$renderMaybe(configuration.hE)
								])),
							$author$project$Pyxis$Commons$Render$renderMaybe(configuration.da)
						]));
			case 2:
				var href = action.a;
				var linkTarget = action.b;
				return A2(
					$elm$html$Html$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$a,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$href(href),
									$elm$html$Html$Attributes$class('card__header__action'),
									$author$project$Pyxis$Commons$Attributes$target(linkTarget)
								]),
							_List_fromArray(
								[
									$author$project$Pyxis$Commons$Render$renderMaybe(configuration.hE)
								])),
							$author$project$Pyxis$Commons$Render$renderMaybe(configuration.da)
						]));
			case 1:
				var label = action.a;
				var msg = action.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('card__header__content__inline-action')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Card$renderTitleAndSubtitle(headerConfig),
							A3(
							$author$project$Pyxis$Components$Card$renderInlineButton,
							label,
							icon,
							$author$project$Pyxis$Components$Button$withOnClick(msg))
						]));
			default:
				var label = action.a;
				var href = action.b;
				var linkTarget = action.c;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('card__header__content__inline-action')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Card$renderTitleAndSubtitle(headerConfig),
							A3(
							$author$project$Pyxis$Components$Card$renderInlineButton,
							label,
							icon,
							$author$project$Pyxis$Components$Button$withType(
								A2($author$project$Pyxis$Components$Button$linkWithTarget, href, linkTarget)))
						]));
		}
	});
var $author$project$Pyxis$Components$Card$renderBadgeList = function (badgeList) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex gap-2xs')
			]),
		A2($elm$core$List$map, $author$project$Pyxis$Components$Badge$render, badgeList));
};
var $author$project$Pyxis$Components$Card$renderInternalHeader = F3(
	function (icon, maybeAction, headerConfig) {
		var configuration = headerConfig;
		return _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				!$elm$core$List$isEmpty(configuration.dq),
				$author$project$Pyxis$Components$Card$renderBadgeList(configuration.dq)),
				A2(
				$elm$core$Maybe$withDefault,
				$author$project$Pyxis$Components$Card$renderTitleAndSubtitle(headerConfig),
				A2(
					$elm$core$Maybe$map,
					A2($author$project$Pyxis$Components$Card$renderActionByType, icon, headerConfig),
					maybeAction)),
				$author$project$Pyxis$Commons$Render$renderMaybe(configuration.bf)
			]);
	});
var $author$project$Pyxis$Components$Card$renderHeader = F4(
	function (maybeAction, maybeAddon, icon, headerConfig) {
		var renderMaybeAddonIfPlacement = function (condition) {
			return $author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					$author$project$Pyxis$Components$Card$renderAddonIfPlacement(condition),
					maybeAddon));
		};
		var internalHeader = A3($author$project$Pyxis$Components$Card$renderInternalHeader, icon, maybeAction, headerConfig);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('card__header')
				]),
			A2($author$project$Pyxis$Components$Card$isAddonPlacement, $author$project$Pyxis$Components$Card$isAddonHeader, maybeAddon) ? _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('card__header__content')
						]),
					_List_fromArray(
						[
							renderMaybeAddonIfPlacement($author$project$Pyxis$Components$Card$isAddonHeader),
							A2($elm$html$Html$div, _List_Nil, internalHeader)
						]))
				]) : A2(
				$elm$core$List$cons,
				renderMaybeAddonIfPlacement($author$project$Pyxis$Components$Card$isAddonTop),
				internalHeader));
	});
var $author$project$Pyxis$Components$Card$render = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('card', true),
						_Utils_Tuple2('card--m', configuration.ax === 1),
						_Utils_Tuple2(
						'card--with-action',
						$author$project$Pyxis$Components$Card$isJustActionCard(configuration.al)),
						_Utils_Tuple2(
						'card--with-addon',
						$elm_community$maybe_extra$Maybe$Extra$isJust(configuration.ko)),
						_Utils_Tuple2(
						'card--with-addon--left',
						A2($author$project$Pyxis$Components$Card$isAddonPlacement, $author$project$Pyxis$Components$Card$isAddonLeft, configuration.ko))
					])),
				$elm$html$Html$Attributes$classList(configuration.fj),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, configuration.iO),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configuration.eO)
			]),
		_List_fromArray(
			[
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				A2($author$project$Pyxis$Components$Card$isAddonPlacement, $author$project$Pyxis$Components$Card$isAddonLeft, configuration.ko),
				$author$project$Pyxis$Commons$Render$renderMaybe(
					A2(
						$elm$core$Maybe$map,
						A2(
							$elm$core$Basics$composeR,
							function ($) {
								return $.eT;
							},
							$author$project$Pyxis$Components$Card$renderAddonByType),
						configuration.ko))),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('card__wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2(
							$elm$core$Maybe$map,
							A3($author$project$Pyxis$Components$Card$renderHeader, configuration.al, configuration.ko, configuration.dm),
							configuration.f_)),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Card$renderContent, configuration.bf)),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Card$renderFooter, configuration.fO))
					])),
				A2(
				$author$project$Pyxis$Commons$Render$renderIf,
				$author$project$Pyxis$Components$Card$isJustActionCard(configuration.al),
				$author$project$Pyxis$Components$Card$renderAction(configuration.dm))
			]));
};
var $author$project$Pyxis$Components$Card$withContent = F2(
	function (content, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				bf: $elm$core$Maybe$Just(content)
			});
	});
var $author$project$Pyxis$Components$Card$withHeader = F2(
	function (header_, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				f_: $elm$core$Maybe$Just(header_)
			});
	});
var $author$project$Pyxis$Components$IconSet$CreditCard = 37;
var $author$project$Pyxis$Components$IconSet$creditCard = 37;
var $author$project$Common$PaymentMethod$buildIconForOtherCard = A2(
	$author$project$Pyxis$Components$Icon$withSize,
	$author$project$Pyxis$Components$Icon$sizeM,
	A2(
		$author$project$Pyxis$Components$Icon$withStyle,
		$author$project$Pyxis$Components$Icon$neutral,
		$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$creditCard)));
var $author$project$Pyxis$Components$Card$IconAddon = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Card$InHeader = 2;
var $author$project$Pyxis$Components$Card$withAddonIconOnHeader = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				ko: $elm$core$Maybe$Just(
					{
						S: 2,
						eT: $author$project$Pyxis$Components$Card$IconAddon(icon)
					})
			});
	});
var $author$project$Pyxis$Components$Card$ImageAddon = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Card$withAddonImageOnHeader = F2(
	function (url, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				ko: $elm$core$Maybe$Just(
					{
						S: 2,
						eT: $author$project$Pyxis$Components$Card$ImageAddon(url)
					})
			});
	});
var $author$project$Common$PaymentMethod$withPaymentImage = F2(
	function (brand, config) {
		switch (brand.$) {
			case 0:
				return A2($author$project$Pyxis$Components$Card$withAddonImageOnHeader, '/assets/visa-s.svg', config);
			case 1:
				return A2($author$project$Pyxis$Components$Card$withAddonImageOnHeader, '/assets/mastercard-s.svg', config);
			case 2:
				return A2($author$project$Pyxis$Components$Card$withAddonImageOnHeader, '/assets/maestro-s.svg', config);
			default:
				return A2($author$project$Pyxis$Components$Card$withAddonIconOnHeader, $author$project$Common$PaymentMethod$buildIconForOtherCard, config);
		}
	});
var $author$project$Common$PaymentMethod$render = F2(
	function (i18n, paymentMethod) {
		return $author$project$Pyxis$Components$Card$render(
			A2(
				$author$project$Pyxis$Components$Card$withContent,
				A2($author$project$Common$PaymentMethod$paymentMethodContent, i18n, paymentMethod),
				A2(
					$author$project$Common$PaymentMethod$withPaymentImage,
					paymentMethod.a8,
					A2(
						$author$project$Pyxis$Components$Card$withHeader,
						A2($author$project$Common$PaymentMethod$paymentMethodHeader, i18n, paymentMethod),
						$author$project$Pyxis$Components$Card$config))));
	});
var $author$project$Common$ComponentUI$PaymentBox$viewPaymentMethodBox = F3(
	function (i18n, paymentMethodRemoteData, onRetryButtonClick) {
		switch (paymentMethodRemoteData.$) {
			case 3:
				var paymentMethod = paymentMethodRemoteData.a;
				return A2($author$project$Common$PaymentMethod$render, i18n, paymentMethod);
			case 1:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('payment-container__payment-method-box__loading')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Loaders$Loader$render($author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM)
						]));
			case 2:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('es-fe-bo-border--neutral2 es-fe-bo-border-radius--2xl')
						]),
					_List_fromArray(
						[
							A3(
							$author$project$Common$ComponentUI$ErrorBox$render,
							i18n,
							_List_fromArray(
								[
									$author$project$Translations$paymentDetailsPaymentDetailsError(i18n)
								]),
							onRetryButtonClick)
						]));
			default:
				return $elm_community$html_extra$Html$Extra$nothing;
		}
	});
var $author$project$Common$ComponentUI$PaymentBox$view = F3(
	function (i18n, paymentMethodRemoteData, onRetryButtonClick) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('payment-container')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$section,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('payment-container__payment-method-box')
						]),
					_List_fromArray(
						[
							A3($author$project$Common$ComponentUI$PaymentBox$viewPaymentMethodBox, i18n, paymentMethodRemoteData, onRetryButtonClick)
						]))
				]));
	});
var $author$project$Page$OfferDetails$View$viewPaymentBox = F3(
	function (i18n, model, offer) {
		return A2(
			$elm_community$html_extra$Html$Extra$viewIf,
			$author$project$Common$Offer$pickIsRenewal(offer),
			A3(
				$author$project$Common$ComponentUI$PaymentBox$view,
				i18n,
				model.jm,
				$author$project$Page$OfferDetails$Msg$RetryGetPaymentMethod(
					$author$project$Common$Offer$pickId(offer))));
	});
var $author$project$Page$OfferDetails$View$viewOffer = F3(
	function (i18n, model, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('offer-details__primary-container container-responsive padding-v-3xl')
				]),
			_List_fromArray(
				[
					A3($author$project$Page$OfferDetails$View$viewHeaderContainer, i18n, model, offer),
					A4(
					$author$project$Common$ComponentUI$DetailsResultBox$viewFromOffer,
					i18n,
					model.fu,
					{
						gB: A2(
							$author$project$Page$OfferDetails$Msg$AmendOfferCoverageEffectiveDate,
							offer,
							$author$project$Common$TimeHelper$tomorrow(model.fu)),
						gH: $author$project$Page$OfferDetails$Msg$ShowCompleteOfferLegalInformationModal(true),
						gS: $author$project$Page$OfferDetails$Msg$ShowRecordingDisclaimerModal(true)
					},
					offer),
					A3(
					$author$project$Common$Portfolio$render,
					i18n,
					model.cW,
					A2($author$project$Page$OfferDetails$View$portfolioConfig, model, offer)),
					A3($author$project$Page$OfferDetails$View$viewPaymentBox, i18n, model, offer)
				]));
	});
var $author$project$Page$OfferDetails$View$renderOfferDetails = F3(
	function (i18n, env, model) {
		var _v0 = model.C;
		switch (_v0.$) {
			case 0:
				return _List_Nil;
			case 1:
				return _List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('offer-details__loading')
							]),
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Loaders$Loader$render($author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM)
							]))
					]);
			case 2:
				return _List_fromArray(
					[$author$project$Common$ErrorContainer$generic]);
			default:
				if (_v0.a.$ === 1) {
					var _v1 = _v0.a;
					return _List_fromArray(
						[$author$project$Common$ErrorContainer$notFound]);
				} else {
					var offer = _v0.a.a;
					return _List_fromArray(
						[
							A3($author$project$Page$OfferDetails$View$viewOffer, i18n, model, offer),
							A5(
							$author$project$Common$ComponentUI$Modal$RecordingDisclaimer$render,
							offer,
							i18n,
							$author$project$Page$OfferDetails$Msg$ShowRecordingDisclaimerModal(false),
							$author$project$Page$OfferDetails$Msg$RedirectToPayment,
							model.eF),
							A5(
							$author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$render,
							offer,
							i18n,
							$author$project$ExternalUrl$FunnelOffer(env),
							$author$project$Page$OfferDetails$Msg$ShowCompleteOfferLegalInformationModal(false),
							model.dA)
						]);
				}
		}
	});
var $author$project$Pyxis$Components$Toasts$Bottom = 2;
var $author$project$Pyxis$Components$Toasts$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Toasts$config = function (id) {
	return {e7: 'Notifications', iO: id, gz: $elm$core$Maybe$Nothing, S: 2, eO: $elm$core$Maybe$Nothing, eP: $author$project$Pyxis$Commons$Properties$Theme$default};
};
var $author$project$Pyxis$Components$Toasts$offsetTopAttribute = A2(
	$elm$core$Basics$composeR,
	$elm$core$String$fromInt,
	A2(
		$elm$core$Basics$composeR,
		A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Basics$append, 'px'),
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Basics$append('--offset-top: '),
			$elm$html$Html$Attributes$attribute('style'))));
var $author$project$Pyxis$Components$Toasts$placementToClass = function (placement) {
	switch (placement) {
		case 0:
			return 'toaster--top';
		case 2:
			return 'toaster--bottom';
		default:
			return 'toaster--top-right';
	}
};
var $elm$virtual_dom$VirtualDom$lazy3 = _VirtualDom_lazy3;
var $elm$html$Html$Lazy$lazy3 = $elm$virtual_dom$VirtualDom$lazy3;
var $author$project$Pyxis$Components$Toasts$AutoRemove = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$Toasts$Hold = function (a) {
	return {$: 4, a: a};
};
var $author$project$Pyxis$Components$Toasts$Resume = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pyxis$Components$Toasts$internalIdToAccessibleId = function (internalUuid) {
	return A2(
		$elm$core$String$join,
		'-',
		_List_fromArray(
			['toast', internalUuid]));
};
var $author$project$Pyxis$Components$Toasts$isAlertDialog = F2(
	function (maybeAction, content) {
		var _v0 = _Utils_Tuple2(maybeAction, content);
		if (!_v0.a.$) {
			return true;
		} else {
			if (_v0.b.$ === 1) {
				var _v1 = _v0.a;
				var _v2 = _v0.b;
				return true;
			} else {
				return false;
			}
		}
	});
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $author$project$Pyxis$Components$Toasts$prefixToastClass = function (variant) {
	return A2(
		$elm$core$String$join,
		'--',
		_List_fromArray(
			['toast', variant]));
};
var $author$project$Pyxis$Components$Toasts$Remove = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Toasts$renderIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Icon$config,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM),
		$author$project$Pyxis$Components$Icon$render));
var $author$project$Pyxis$Components$Toasts$renderCloseButton = F3(
	function (tagger, id, closeButtonLabel) {
		return A2(
			$elm$html$Html$button,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toast__close'),
					$elm$html$Html$Events$onClick(
					tagger(
						$author$project$Pyxis$Components$Toasts$Remove(id))),
					$author$project$Pyxis$Commons$Attributes$ariaLabel(closeButtonLabel)
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Toasts$renderIcon($author$project$Pyxis$Components$IconSet$cross)
				]));
	});
var $author$project$Pyxis$Commons$Properties$Theme$alternative = 1;
var $author$project$Pyxis$Components$Toasts$altThemeString = 'alt';
var $author$project$Pyxis$Components$Toasts$lightThemeString = 'light';
var $author$project$Pyxis$Components$Toasts$invertedThemeToString = function (theme) {
	return $author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme) ? $author$project$Pyxis$Components$Toasts$altThemeString : $author$project$Pyxis$Components$Toasts$lightThemeString;
};
var $author$project$Pyxis$Components$Toasts$renderAction = function (_v0) {
	var label = _v0.a;
	var onClickHandler = _v0.b;
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toast__action'),
					A2(
					$elm$html$Html$Attributes$attribute,
					'data-theme',
					$author$project$Pyxis$Components$Toasts$invertedThemeToString($author$project$Pyxis$Commons$Properties$Theme$alternative))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						onClickHandler,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							label,
							A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeS, $author$project$Pyxis$Components$Button$primary))))
				]))
		]);
};
var $author$project$Pyxis$Components$Toasts$renderText = F2(
	function (toastId, text) {
		if (!text.$) {
			var string = text.a;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toast__text'),
							$elm$html$Html$Attributes$id(
							$author$project$Pyxis$Components$Toasts$internalIdToAccessibleId(toastId))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(string)
						]))
				]);
		} else {
			var content = text.a;
			var title = text.b;
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$h2,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(
							$author$project$Pyxis$Components$Toasts$internalIdToAccessibleId(toastId))
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('toast__text')
						]),
					_List_fromArray(
						[content]))
				]);
		}
	});
var $author$project$Pyxis$Components$Toasts$renderContentWrapper = F3(
	function (id, content, maybeCustomAction) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('toast__content-wrapper')
				]),
			A2(
				$elm$core$List$append,
				A2($author$project$Pyxis$Components$Toasts$renderText, id, content),
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Toasts$renderAction, maybeCustomAction))));
	});
var $author$project$Pyxis$Components$Toasts$renderIconContainer = function (icon) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('toast__icon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Toasts$renderIcon(icon)
			]));
};
var $elm$svg$Svg$Attributes$color = _VirtualDom_attribute('color');
var $elm$svg$Svg$line = $elm$svg$Svg$trustedNode('line');
var $elm$svg$Svg$Attributes$pathLength = _VirtualDom_attribute('pathLength');
var $elm$svg$Svg$Attributes$preserveAspectRatio = _VirtualDom_attribute('preserveAspectRatio');
var $elm$svg$Svg$Attributes$stroke = _VirtualDom_attribute('stroke');
var $elm$svg$Svg$Attributes$strokeLinecap = _VirtualDom_attribute('stroke-linecap');
var $elm$svg$Svg$Attributes$strokeWidth = _VirtualDom_attribute('stroke-width');
var $author$project$Pyxis$Components$Toasts$renderToastLoader = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('toast__loader')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$svg,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$viewBox('0 0 100 2'),
					$elm$svg$Svg$Attributes$preserveAspectRatio('none'),
					$elm$svg$Svg$Attributes$class('toast__loader')
				]),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$line,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$x1('0'),
							$elm$svg$Svg$Attributes$y1('0'),
							$elm$svg$Svg$Attributes$x2('100'),
							$elm$svg$Svg$Attributes$y2('0'),
							$elm$svg$Svg$Attributes$strokeWidth('12'),
							$elm$svg$Svg$Attributes$strokeLinecap('round'),
							$elm$svg$Svg$Attributes$color('transparent'),
							$elm$svg$Svg$Attributes$pathLength('1'),
							$elm$svg$Svg$Attributes$class('toast__loader__background')
						]),
					_List_Nil),
					A2(
					$elm$svg$Svg$line,
					_List_fromArray(
						[
							$elm$svg$Svg$Attributes$x1('0'),
							$elm$svg$Svg$Attributes$y1('0'),
							$elm$svg$Svg$Attributes$x2('100'),
							$elm$svg$Svg$Attributes$y2('0'),
							$elm$svg$Svg$Attributes$stroke('purple'),
							$elm$svg$Svg$Attributes$strokeWidth('12'),
							$elm$svg$Svg$Attributes$strokeLinecap('round'),
							$elm$svg$Svg$Attributes$color('transparent'),
							$elm$svg$Svg$Attributes$pathLength('100'),
							$elm$svg$Svg$Attributes$class('toast__loader__line')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Pyxis$Components$Toasts$toastRole = F2(
	function (maybeAction, content) {
		return A2($author$project$Pyxis$Components$Toasts$isAlertDialog, maybeAction, content) ? 'alertdialog' : 'alert';
	});
var $author$project$Pyxis$Components$Toasts$variantToString = function (variant) {
	switch (variant) {
		case 0:
			return 'neutral';
		case 1:
			return 'brand';
		case 2:
			return 'alert';
		case 3:
			return 'success';
		default:
			return 'error';
	}
};
var $author$project$Pyxis$Components$Toasts$ToastsHelpers$visibilityTimeAsStyleString = A3(
	$primait$prima_elm_extra$PrimaFunction$flip,
	$elm$core$Basics$append,
	'ms',
	$elm$core$String$fromInt($author$project$Pyxis$Components$Toasts$ToastsHelpers$visibilityTimeInMilliseconds));
var $author$project$Pyxis$Components$Toasts$renderToast = F2(
	function (tagger, _v0) {
		var action = _v0.al;
		var classList = _v0.fj;
		var closeButtonLabel = _v0.fk;
		var icon = _v0.iN;
		var id = _v0.iO;
		var isSticky = _v0.d2;
		var testId = _v0.eO;
		var text = _v0.nB;
		var theme = _v0.eP;
		var variant = _v0.hK;
		var onAnimationEnd = _v0.gD;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('toast', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Toasts$prefixToastClass(
								$author$project$Pyxis$Components$Toasts$variantToString(variant)),
							true),
							_Utils_Tuple2(
							'toast--with-icon',
							$elm_community$maybe_extra$Maybe$Extra$isJust(icon))
						])),
					$elm$html$Html$Attributes$classList(classList),
					$author$project$Pyxis$Commons$Attributes$role(
					A2($author$project$Pyxis$Components$Toasts$toastRole, action, text)),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					A2($author$project$Pyxis$Components$Toasts$isAlertDialog, action, text),
					$author$project$Pyxis$Commons$Attributes$ariaLabelledBy(
						$author$project$Pyxis$Components$Toasts$internalIdToAccessibleId(id))),
					A2(
					$author$project$Pyxis$Commons$Attributes$maybe,
					A2(
						$elm$core$Basics$composeR,
						$elm$json$Json$Decode$succeed,
						$elm$html$Html$Events$on('animationend')),
					onAnimationEnd),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					A2($elm$html$Html$Attributes$attribute, 'style', '--duration: ' + $author$project$Pyxis$Components$Toasts$ToastsHelpers$visibilityTimeAsStyleString),
					$elm$html$Html$Events$onMouseEnter(
					tagger(
						$author$project$Pyxis$Components$Toasts$Hold(id))),
					$elm$html$Html$Events$onMouseLeave(
					tagger(
						$author$project$Pyxis$Components$Toasts$Resume(id)))
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Commons$Render$renderMaybe(
					A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Toasts$renderIconContainer, icon)),
					A3($author$project$Pyxis$Components$Toasts$renderContentWrapper, id, text, action),
					A3($author$project$Pyxis$Components$Toasts$renderCloseButton, tagger, id, closeButtonLabel),
					A2($author$project$Pyxis$Commons$Render$renderIf, !isSticky, $author$project$Pyxis$Components$Toasts$renderToastLoader)
				]));
	});
var $author$project$Pyxis$Components$Toasts$withToastAnimationEnd = F2(
	function (tagger, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gD: $elm$core$Maybe$Just(tagger)
			});
	});
var $author$project$Pyxis$Components$Toasts$withToastClassList = F2(
	function (classes, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classes});
	});
var $author$project$Pyxis$Components$Toasts$renderTimedToast = F3(
	function (tagger, id, timedToast) {
		switch (timedToast.$) {
			case 0:
				var toastElement = timedToast.b;
				return A2(
					$author$project$Pyxis$Components$Toasts$renderToast,
					tagger,
					A2(
						$author$project$Pyxis$Components$Toasts$withToastClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('toast--animated', true)
							]),
						toastElement));
			case 1:
				var toastElement = timedToast.a;
				return A2(
					$author$project$Pyxis$Components$Toasts$renderToast,
					tagger,
					A2(
						$author$project$Pyxis$Components$Toasts$withToastAnimationEnd,
						tagger(
							$author$project$Pyxis$Components$Toasts$AutoRemove(id)),
						A2(
							$author$project$Pyxis$Components$Toasts$withToastClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('toast--animated', true),
									_Utils_Tuple2('toast--animation-on-exit', true)
								]),
							toastElement)));
			case 2:
				var toastElement = timedToast.a;
				return A2($author$project$Pyxis$Components$Toasts$renderToast, tagger, toastElement);
			default:
				var toastElement = timedToast.b;
				return A2(
					$author$project$Pyxis$Components$Toasts$renderToast,
					tagger,
					A2(
						$author$project$Pyxis$Components$Toasts$withToastClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('toast--animated', true)
							]),
						toastElement));
		}
	});
var $author$project$Pyxis$Components$Toasts$renderToastList = F2(
	function (tagger, _v0) {
		var uuid = _v0.a;
		var timedToast = _v0.b;
		return _Utils_Tuple2(
			uuid,
			A4($elm$html$Html$Lazy$lazy3, $author$project$Pyxis$Components$Toasts$renderTimedToast, tagger, uuid, timedToast));
	});
var $author$project$Pyxis$Components$Toasts$render = F3(
	function (tagger, _v0, _v1) {
		var toasts = _v0.O;
		var ariaLabel = _v1.e7;
		var id = _v1.iO;
		var placement = _v1.S;
		var offsetTop = _v1.gz;
		var testId = _v1.eO;
		var theme = _v1.eP;
		return A3(
			$elm$html$Html$Keyed$node,
			'div',
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('toaster', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Toasts$placementToClass(placement),
							true)
						])),
					$elm$html$Html$Attributes$id(id),
					$author$project$Pyxis$Commons$Attributes$role('region'),
					$author$project$Pyxis$Commons$Attributes$ariaLabel(ariaLabel),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Toasts$offsetTopAttribute, offsetTop),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
					A2(
					$author$project$Pyxis$Commons$Attributes$renderIf,
					$author$project$Pyxis$Commons$Properties$Theme$isAlternative(theme),
					$author$project$Pyxis$Commons$Attributes$theme(theme))
				]),
			A2(
				$elm$core$List$map,
				$author$project$Pyxis$Components$Toasts$renderToastList(tagger),
				$elm$core$Dict$toList(toasts)));
	});
var $author$project$Pyxis$Components$Toasts$TopRight = 1;
var $author$project$Pyxis$Components$Toasts$topRightPlacement = 1;
var $author$project$Pyxis$Components$Toasts$withPlacement = F2(
	function (placement, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{S: placement});
	});
var $author$project$Page$OfferDetails$View$viewToasts = function (model) {
	return A3(
		$author$project$Pyxis$Components$Toasts$render,
		$author$project$Page$OfferDetails$Msg$ToastMsg,
		model.O,
		A2(
			$author$project$Pyxis$Components$Toasts$withPlacement,
			$author$project$Pyxis$Components$Toasts$topRightPlacement,
			$author$project$Pyxis$Components$Toasts$config('offer-details-toaster')));
};
var $author$project$Page$OfferDetails$View$view = F3(
	function (i18n, env, model) {
		return A2(
			$elm$core$List$cons,
			$author$project$Page$OfferDetails$View$viewToasts(model),
			A3($author$project$Page$OfferDetails$View$renderOfferDetails, i18n, env, model));
	});
var $author$project$Page$OfferDetails$view = F3(
	function (i18n, env, model) {
		return {
			fd: A3($author$project$Page$OfferDetails$View$view, i18n, env, model),
			hE: $author$project$Translations$routeOfferDetails(i18n)
		};
	});
var $author$project$Translations$routePayment = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Payment';
	} else {
		return 'Pago';
	}
};
var $author$project$Common$ErrorContainer$productsNotFound = A2($author$project$Common$ErrorContainer$custom, 1, 'Oops! We couldn\'t find any product for this offer');
var $author$project$Translations$paymentSpinnerText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Loading checkout...';
	} else {
		return 'Procesando...';
	}
};
var $author$project$Pyxis$Components$Loaders$Loader$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nB: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Page$Payment$View$renderLoadingPage = function (i18n) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('justify-content-center display-flex')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Loaders$Loader$render(
				A2(
					$author$project$Pyxis$Components$Loaders$Loader$withText,
					$author$project$Translations$paymentSpinnerText(i18n),
					$author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM))
			]));
};
var $author$project$Page$Payment$Msg$PaymentSubmit = {$: 6};
var $author$project$Pyxis$Components$Form$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$config = {dk: $elm$core$Maybe$Nothing, al: $elm$core$Maybe$Nothing, fb: $elm$core$Maybe$Nothing, fj: _List_Nil, dH: $elm$core$Maybe$Nothing, cv: _List_Nil, iO: $elm$core$Maybe$Nothing, d8: $elm$core$Maybe$Nothing, i6: $elm$core$Maybe$Nothing, eb: $elm$core$Maybe$Nothing, ei: $elm$core$Maybe$Nothing, eM: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing};
var $author$project$Pyxis$Components$Form$Fieldset$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Form$Fieldset$L = 2;
var $author$project$Pyxis$Components$Form$Fieldset$config = {e_: _List_Nil, fj: _List_Nil, bf: _List_Nil, fO: _List_Nil, f_: _List_Nil, iO: $elm$core$Maybe$Nothing, dX: false, aM: $elm$core$Maybe$Nothing, cE: 2};
var $elm$html$Html$Attributes$acceptCharset = $elm$html$Html$Attributes$stringProperty('acceptCharset');
var $elm$html$Html$Attributes$action = function (uri) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'action',
		_VirtualDom_noJavaScriptUri(uri));
};
var $elm$html$Html$Attributes$enctype = $elm$html$Html$Attributes$stringProperty('enctype');
var $elm$html$Html$form = _VirtualDom_node('form');
var $elm$html$Html$Attributes$method = $elm$html$Html$Attributes$stringProperty('method');
var $elm$html$Html$Attributes$novalidate = $elm$html$Html$Attributes$boolProperty('noValidate');
var $elm$html$Html$Events$alwaysPreventDefault = function (msg) {
	return _Utils_Tuple2(msg, true);
};
var $elm$html$Html$Events$onSubmit = function (msg) {
	return A2(
		$elm$html$Html$Events$preventDefaultOn,
		'submit',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysPreventDefault,
			$elm$json$Json$Decode$succeed(msg)));
};
var $elm$html$Html$fieldset = _VirtualDom_node('fieldset');
var $author$project$Pyxis$Components$Grid$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Gap$L = 2;
var $author$project$Pyxis$Components$Grid$Gap$sizeL = 2;
var $author$project$Pyxis$Components$Grid$gapL = function (c) {
	return _Utils_update(
		c,
		{bo: $author$project$Pyxis$Components$Grid$Gap$sizeL});
};
var $author$project$Pyxis$Components$Grid$Gap$M = 1;
var $author$project$Pyxis$Components$Grid$Gap$sizeM = 1;
var $author$project$Pyxis$Components$Grid$initialConfiguration = {X: _List_Nil, bo: $author$project$Pyxis$Components$Grid$Gap$sizeM};
var $author$project$Pyxis$Components$Grid$Gap$isSizeL = $elm$core$Basics$eq(2);
var $author$project$Pyxis$Components$Grid$getRowColumnsFromClass = function (breakpointType) {
	var breakpoint = $author$project$Pyxis$Tokens$Breakpoint$toString(breakpointType);
	return 'grid__row--from-' + breakpoint;
};
var $author$project$Pyxis$Components$Grid$Row$M = 1;
var $author$project$Pyxis$Components$Grid$Row$isSizeM = $elm$core$Basics$eq(1);
var $author$project$Pyxis$Components$Grid$Row$S = 0;
var $author$project$Pyxis$Components$Grid$Row$isSizeS = $elm$core$Basics$eq(0);
var $author$project$Pyxis$Components$Grid$SpanSize$toInt = function (spanSize) {
	switch (spanSize) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		default:
			return 5;
	}
};
var $author$project$Pyxis$Components$Grid$SpanSize$toString = A2($elm$core$Basics$composeR, $author$project$Pyxis$Components$Grid$SpanSize$toInt, $elm$core$String$fromInt);
var $author$project$Pyxis$Components$Grid$getColumnSpanAtBreakpointClass = F2(
	function (spanSize, breakpointType) {
		var span = $author$project$Pyxis$Components$Grid$SpanSize$toString(spanSize);
		var breakpoint = $author$project$Pyxis$Tokens$Breakpoint$toString(breakpointType);
		return 'grid__row__column--span-' + (span + ('-from-' + breakpoint));
	});
var $author$project$Pyxis$Components$Grid$getColumnSpanClass = function (spanSize) {
	return 'grid__row__column--span-' + $author$project$Pyxis$Components$Grid$SpanSize$toString(spanSize);
};
var $author$project$Pyxis$Components$Grid$renderCol = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('grid__row__column', true),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Grid$getColumnSpanClass(configuration.c0),
							true),
							_Utils_Tuple2(
							A2($author$project$Pyxis$Components$Grid$getColumnSpanAtBreakpointClass, configuration.cd, configuration.aD),
							true)
						]),
					configuration.fj))
			]),
		configuration.X);
};
var $author$project$Pyxis$Components$Grid$renderRow = function (_v0) {
	var configuration = _v0;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_Utils_ap(
					_List_fromArray(
						[
							_Utils_Tuple2('grid__row', true),
							_Utils_Tuple2(
							'grid__row--m',
							$author$project$Pyxis$Components$Grid$Row$isSizeM(configuration.ax)),
							_Utils_Tuple2(
							'grid__row--s',
							$author$project$Pyxis$Components$Grid$Row$isSizeS(configuration.ax)),
							_Utils_Tuple2(
							$author$project$Pyxis$Components$Grid$getRowColumnsFromClass(configuration.aD),
							true)
						]),
					configuration.fj))
			]),
		A2($elm$core$List$map, $author$project$Pyxis$Components$Grid$renderCol, configuration.X));
};
var $author$project$Pyxis$Components$Grid$render = F2(
	function (options, children) {
		var configuration = A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, config) {
					var mapper = _v0;
					return mapper(config);
				}),
			$author$project$Pyxis$Components$Grid$initialConfiguration,
			options);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('grid', true),
							_Utils_Tuple2(
							'grid--gap-l',
							$author$project$Pyxis$Components$Grid$Gap$isSizeL(configuration.bo))
						]))
				]),
			A2($elm$core$List$map, $author$project$Pyxis$Components$Grid$renderRow, children));
	});
var $author$project$Pyxis$Components$Grid$Gap$S = 0;
var $author$project$Pyxis$Components$Grid$Gap$sizeS = 0;
var $author$project$Pyxis$Components$Grid$gapS = function (c) {
	return _Utils_update(
		c,
		{bo: $author$project$Pyxis$Components$Grid$Gap$sizeS});
};
var $author$project$Pyxis$Components$Grid$Col = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Col$buildConfiguration = F2(
	function (options, config) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, c) {
					var mapper = _v0;
					return mapper(c);
				}),
			config,
			options);
	});
var $author$project$Pyxis$Components$Grid$SpanSize$Span1 = 0;
var $author$project$Pyxis$Components$Grid$SpanSize$span1 = 0;
var $author$project$Pyxis$Tokens$Breakpoint$Base = 0;
var $author$project$Pyxis$Tokens$Breakpoint$tokenBase = 0;
var $author$project$Pyxis$Components$Grid$initialColConfiguration = {aD: $author$project$Pyxis$Tokens$Breakpoint$tokenBase, cd: $author$project$Pyxis$Components$Grid$SpanSize$span1, X: _List_Nil, fj: _List_Nil, c0: $author$project$Pyxis$Components$Grid$SpanSize$span1};
var $author$project$Pyxis$Components$Grid$col = F2(
	function (options, children) {
		var configuration = A2($author$project$Pyxis$Components$Grid$Col$buildConfiguration, options, $author$project$Pyxis$Components$Grid$initialColConfiguration);
		return {aD: configuration.aD, cd: configuration.cd, X: children, fj: configuration.fj, c0: configuration.c0};
	});
var $author$project$Pyxis$Components$Grid$Row = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Row$buildConfiguration = F2(
	function (options, config) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, c) {
					var mapper = _v0;
					return mapper(c);
				}),
			config,
			options);
	});
var $author$project$Pyxis$Components$Grid$Row$L = 2;
var $author$project$Pyxis$Components$Grid$Row$rowSizeL = 2;
var $author$project$Pyxis$Components$Grid$initialRowConfiguration = {aD: $author$project$Pyxis$Tokens$Breakpoint$tokenBase, X: _List_Nil, fj: _List_Nil, ax: $author$project$Pyxis$Components$Grid$Row$rowSizeL};
var $author$project$Pyxis$Components$Grid$row = F2(
	function (options, children) {
		var configuration = A2($author$project$Pyxis$Components$Grid$Row$buildConfiguration, options, $author$project$Pyxis$Components$Grid$initialRowConfiguration);
		return {aD: configuration.aD, X: children, fj: configuration.fj, ax: configuration.ax};
	});
var $author$project$Pyxis$Components$Grid$oneColRowFullWidth = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Grid$col(_List_Nil),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$author$project$Pyxis$Components$Grid$row(_List_Nil)));
var $author$project$Pyxis$Components$Form$Fieldset$renderContent = function (content) {
	return _List_fromArray(
		[
			$author$project$Pyxis$Components$Grid$oneColRowFullWidth(
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Components$Grid$render,
					_List_fromArray(
						[$author$project$Pyxis$Components$Grid$gapS]),
					content)
				]))
		]);
};
var $author$project$Pyxis$Components$Grid$Row$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$Row$sizeL = function (config) {
	return _Utils_update(
		config,
		{ax: 2});
};
var $author$project$Pyxis$Components$Grid$Row$sizeM = function (config) {
	return _Utils_update(
		config,
		{ax: 1});
};
var $author$project$Pyxis$Components$Grid$Row$sizeS = function (config) {
	return _Utils_update(
		config,
		{ax: 0});
};
var $author$project$Pyxis$Components$Form$Fieldset$getRowSize = function (rowSize) {
	switch (rowSize) {
		case 0:
			return $author$project$Pyxis$Components$Grid$Row$sizeS;
		case 1:
			return $author$project$Pyxis$Components$Grid$Row$sizeM;
		default:
			return $author$project$Pyxis$Components$Grid$Row$sizeL;
	}
};
var $author$project$Pyxis$Components$Form$Fieldset$renderLegendRow = F2(
	function (rowSize, legend) {
		return _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Fieldset$getRowSize(rowSize)
					]),
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Components$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								$author$project$Pyxis$Components$Form$Legend$render(legend)
							]))
					]))
			]);
	});
var $author$project$Pyxis$Components$Form$Fieldset$renderVisualLegend = function (rowSize) {
	return A2(
		$elm$core$Basics$composeR,
		$elm$core$Maybe$map(
			$author$project$Pyxis$Components$Form$Fieldset$renderLegendRow(rowSize)),
		$elm$core$Maybe$withDefault(_List_Nil));
};
var $author$project$Pyxis$Components$Form$Legend$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Form$Fieldset$render = function (_v0) {
	var classList = _v0.fj;
	var header = _v0.f_;
	var content = _v0.bf;
	var footer = _v0.fO;
	var legend = _v0.aM;
	var additionalContent = _v0.e_;
	var isHiddenLegend = _v0.dX;
	var legendRowSize = _v0.cE;
	var id = _v0.iO;
	return A2(
		$elm$html$Html$fieldset,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, id)
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Form$Legend$withClassList(
							_List_fromArray(
								[
									_Utils_Tuple2('sr-only', true)
								])),
						$author$project$Pyxis$Components$Form$Legend$render),
					legend)),
				A2(
				$author$project$Pyxis$Components$Grid$render,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$gapL]),
				_Utils_ap(
					additionalContent,
					_Utils_ap(
						(!isHiddenLegend) ? A2($author$project$Pyxis$Components$Form$Fieldset$renderVisualLegend, legendRowSize, legend) : _List_Nil,
						_Utils_ap(
							header,
							_Utils_ap(
								$author$project$Pyxis$Components$Form$Fieldset$renderContent(content),
								footer)))))
			]));
};
var $author$project$Pyxis$Components$Form$render = function (_v0) {
	var onSubmit = _v0.ei;
	var gridContent = _v0.cv;
	var classList = _v0.fj;
	var id = _v0.iO;
	var testId = _v0.eO;
	var acceptCharset = _v0.dk;
	var action = _v0.al;
	var autocomplete = _v0.fb;
	var encType = _v0.dH;
	var method = _v0.d8;
	var name = _v0.i6;
	var noValidate = _v0.eb;
	var target = _v0.eM;
	return A2(
		$elm$html$Html$form,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form'),
				$elm$html$Html$Attributes$classList(classList),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$id, id),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$acceptCharset, acceptCharset),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$action, action),
				A2(
				$author$project$Pyxis$Commons$Attributes$maybe,
				$elm$html$Html$Attributes$attribute('autocomplete'),
				autocomplete),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$enctype, encType),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$method, method),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$name, name),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$novalidate, noValidate),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$target, target),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, testId),
				A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Events$onSubmit, onSubmit)
			]),
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeR, $elm$core$Tuple$first, $author$project$Pyxis$Components$Form$Fieldset$render),
			A2($elm$core$List$filter, $elm$core$Tuple$second, gridContent)));
};
var $author$project$Page$Payment$View$acceptedCardsStaticInfo = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('payment__static-info')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('payment__credit-cards'),
					$elm$html$Html$Attributes$class('display-flex')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/visa-s.svg'),
							$elm$html$Html$Attributes$class('space-h-xs')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/mastercard-s.svg'),
							$elm$html$Html$Attributes$class('space-h-xs')
						]),
					_List_Nil),
					A2(
					$elm$html$Html$img,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$src('/assets/maestro-s.svg'),
							$elm$html$Html$Attributes$class('space-h-xs')
						]),
					_List_Nil)
				]))
		]));
var $author$project$Pyxis$Components$Grid$oneColRowSizeS = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Grid$col(_List_Nil),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$author$project$Pyxis$Components$Grid$row(
			_List_fromArray(
				[$author$project$Pyxis$Components$Grid$Row$sizeS]))));
var $author$project$Translations$paymentCardExpirationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Expiration Date';
	} else {
		return 'Caducidad';
	}
};
var $author$project$Translations$paymentCardNumberLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card Number';
	} else {
		return 'Número de la tarjeta';
	}
};
var $author$project$Translations$paymentFetchProductsErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Couldn\'t fetch products';
	} else {
		return 'No se pudieron obtener los productos';
	}
};
var $author$project$Page$Payment$View$securePaymentStaticInfo = A2(
	$elm$html$Html$div,
	_List_fromArray(
		[
			$elm$html$Html$Attributes$class('display-flex')
		]),
	_List_fromArray(
		[
			A2(
			$elm$html$Html$img,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src('/assets/secure-payment.svg')
				]),
			_List_Nil)
		]));
var $author$project$Translations$paymentTaxeslabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Including taxes';
	} else {
		return 'Incluye impuestos';
	}
};
var $author$project$Translations$paymentTotalPriceLabel = F2(
	function (refundPrice, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'Total Price: ' + refundPrice;
		} else {
			return 'Precio total: ' + refundPrice;
		}
	});
var $author$project$Common$Price$toStringNoSpaceSignFirst = F2(
	function (i18n, _v0) {
		var amount = _v0.a;
		var currency = _v0.b;
		return _Utils_ap(
			$author$project$Common$Price$pickSign(currency),
			A2($author$project$Common$Price$toAmountString, i18n, amount));
	});
var $author$project$Common$Price$zeroEuro = $author$project$Common$Price$zero(0);
var $author$project$Page$Payment$View$totalPrice = F2(
	function (i18n, _v0) {
		var gotOfferProductResult = _v0.fW;
		var sumProductsPrices = function (offerProducts) {
			return $author$project$Common$Price$sumAll(
				A2(
					$elm$core$List$map,
					function (product) {
						return product.kr.la;
					},
					offerProducts));
		};
		var calculatedPrice = A3($krisajenkins$remotedata$RemoteData$unwrap, $author$project$Common$Price$zeroEuro, sumProductsPrices, gotOfferProductResult);
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title-m-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							A2(
								$author$project$Translations$paymentTotalPriceLabel,
								A2($author$project$Common$Price$toStringNoSpaceSignFirst, i18n, calculatedPrice),
								i18n))
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-l-book c-neutral-25')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$paymentTaxeslabel(i18n))
						]))
				]));
	});
var $author$project$Pyxis$Components$Field$Label$M = 1;
var $author$project$Pyxis$Components$Field$Label$config = {fj: _List_Nil, dL: $elm$core$Maybe$Nothing, iO: $elm$core$Maybe$Nothing, dW: false, ax: 1, eH: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, nB: $elm$core$Maybe$Nothing, eR: $elm$core$Maybe$Nothing};
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $author$project$Pyxis$Components$Field$Label$withText = F2(
	function (text, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				nB: $elm$core$Maybe$Just(text)
			});
	});
var $author$project$Page$Payment$View$viewCardField = F2(
	function (label, _v0) {
		var id = _v0.iO;
		var isValid = _v0.by;
		var isPristine = _v0.d_;
		return A2(
			$author$project$Pyxis$Components$Grid$col,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Field$Label$render(
					A2(
						$author$project$Pyxis$Components$Field$Label$withFor,
						id,
						A2($author$project$Pyxis$Components$Field$Label$withText, label, $author$project$Pyxis$Components$Field$Label$config))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$id(id),
							A2($elm$html$Html$Attributes$style, 'padding', '0'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-field form-field__text', true),
									_Utils_Tuple2('form-field--error', !(isValid || isPristine))
								]))
						]),
					_List_Nil)
				]));
	});
var $author$project$Page$Payment$View$viewProduct = F2(
	function (i18n, _v0) {
		var bundleSlug = _v0.kA;
		var annualPrice = _v0.kr;
		return A2(
			$author$project$Pyxis$Components$Grid$row,
			_List_fromArray(
				[$author$project$Pyxis$Components$Grid$Row$sizeS]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Components$Grid$col,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('product-card product-card--checkout product-card--selected')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('product-card__header')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$img,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$src('/assets/motorcycle-car.svg')
												]),
											_List_Nil),
											A2(
											$elm$html$Html$div,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('title-s-bold')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Common$Product$bundleSlugToString, i18n, bundleSlug))
														])),
													A2(
													$elm$html$Html$div,
													_List_fromArray(
														[
															$elm$html$Html$Attributes$class('product-card__price')
														]),
													_List_fromArray(
														[
															$elm$html$Html$text(
															A2($author$project$Common$Price$toStringSignFirst, i18n, annualPrice.la))
														]))
												]))
										]))
								]))
						]))
				]));
	});
var $author$project$Page$Payment$View$viewProducts = F2(
	function (i18n, offerProducts) {
		return A2(
			$elm$core$List$map,
			function (product) {
				return A2($author$project$Page$Payment$View$viewProduct, i18n, product);
			},
			offerProducts);
	});
var $author$project$Page$Payment$View$renderFormContent = F2(
	function (i18n, model) {
		var viewStaticFields = _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$Row$sizeS]),
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Components$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('display-flex justify-content-space-between align-items-center')
									]),
								_List_fromArray(
									[$author$project$Page$Payment$View$acceptedCardsStaticInfo, $author$project$Page$Payment$View$securePaymentStaticInfo]))
							]))
					]))
			]);
		var viewSelectedProductsCards = A3(
			$krisajenkins$remotedata$RemoteData$unwrap,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Grid$oneColRowSizeS(
					_List_fromArray(
						[
							$elm$html$Html$text(
							$author$project$Translations$paymentFetchProductsErrorMessage(i18n))
						]))
				]),
			function (offerProducts) {
				return A2($author$project$Page$Payment$View$viewProducts, i18n, offerProducts);
			},
			model.fW);
		var viewPaymentFields = _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$Row$sizeS]),
				_List_fromArray(
					[
						A2(
						$author$project$Page$Payment$View$viewCardField,
						$author$project$Translations$paymentCardNumberLabel(i18n),
						model.du.ce)
					])),
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$Row$sizeS]),
				_List_fromArray(
					[
						A2(
						$author$project$Page$Payment$View$viewCardField,
						$author$project$Translations$paymentCardExpirationDateLabel(i18n),
						model.du.cq)
					])),
				$author$project$Pyxis$Components$Grid$oneColRowSizeS(
				_List_fromArray(
					[
						A2($author$project$Page$Payment$View$totalPrice, i18n, model)
					]))
			]);
		return $elm$core$List$concat(
			_List_fromArray(
				[viewSelectedProductsCards, viewPaymentFields, viewStaticFields]));
	});
var $author$project$Page$Payment$Msg$GoBackToPreviousPage = {$: 2};
var $author$project$Translations$paymentBackButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Regresar';
	}
};
var $author$project$Page$Payment$View$renderBackButton = function (i18n) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('payment__back-button')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Button$render(
				A2(
					$author$project$Pyxis$Components$Button$withIconPrepend,
					$author$project$Pyxis$Components$IconSet$chevronLeft,
					A2(
						$author$project$Pyxis$Components$Button$withSize,
						$author$project$Pyxis$Components$Button$sizeM,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$paymentBackButtonText(i18n),
							A2($author$project$Pyxis$Components$Button$withOnClick, $author$project$Page$Payment$Msg$GoBackToPreviousPage, $author$project$Pyxis$Components$Button$ghost)))))
			]));
};
var $author$project$Page$Payment$Model$hasValidCreditCardField = function (fields) {
	return fields.ce.by && fields.cq.by;
};
var $author$project$Pyxis$Components$Button$inherit = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{di: 2});
};
var $author$project$Page$Payment$Model$isStatusPayingWithCreditCard = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.c1;
	},
	$elm$core$Basics$eq(3));
var $author$project$Page$Payment$Model$isStatusPaymentReady = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.c1;
	},
	$elm$core$Basics$eq(1));
var $author$project$Translations$paymentPurchaseButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Pay now';
	} else {
		return 'Pagar ahora';
	}
};
var $author$project$Pyxis$Components$Button$Submit = {$: 1};
var $author$project$Pyxis$Components$Button$submit = $author$project$Pyxis$Components$Button$Submit;
var $author$project$Pyxis$Components$Button$withLoading = F2(
	function (isLoading, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{bz: isLoading});
	});
var $author$project$Page$Payment$View$renderPayNowButton = F2(
	function (i18n, model) {
		var cardFields = model.du;
		var isLoading = $author$project$Page$Payment$Model$isStatusPayingWithCreditCard(model);
		var isEnabled = $author$project$Page$Payment$Model$hasValidCreditCardField(cardFields) && $author$project$Page$Payment$Model$isStatusPaymentReady(model);
		var isDisabled = !isEnabled;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('payment__pay-now-button')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withTestId,
						'payment-button',
						A2(
							$author$project$Pyxis$Components$Button$withId,
							'payment-button',
							A2(
								$author$project$Pyxis$Components$Button$withType,
								$author$project$Pyxis$Components$Button$submit,
								A2(
									$author$project$Pyxis$Components$Button$withWidth,
									$author$project$Pyxis$Components$Button$inherit,
									A2(
										$author$project$Pyxis$Components$Button$withSize,
										$author$project$Pyxis$Components$Button$sizeXL,
										A2(
											$author$project$Pyxis$Components$Button$withDisabled,
											isDisabled,
											A2(
												$author$project$Pyxis$Components$Button$withLoading,
												isLoading,
												A2(
													$author$project$Pyxis$Components$Button$withText,
													$author$project$Translations$paymentPurchaseButtonText(i18n),
													$author$project$Pyxis$Components$Button$primary)))))))))
				]));
	});
var $author$project$Page$Payment$View$renderFormFooter = F2(
	function (i18n, model) {
		return _List_fromArray(
			[
				A2(
				$author$project$Pyxis$Components$Grid$row,
				_List_fromArray(
					[$author$project$Pyxis$Components$Grid$Row$sizeS]),
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Components$Grid$col,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('display-flex justify-content-space-between align-items-center')
									]),
								_List_fromArray(
									[
										$author$project$Page$Payment$View$renderBackButton(i18n),
										A2($author$project$Page$Payment$View$renderPayNowButton, i18n, model)
									]))
							]))
					]))
			]);
	});
var $author$project$Pyxis$Components$Message$coloredBackground = 1;
var $author$project$Translations$paymentCurrentRiskAlreadyCoveredLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy with current risk already covered';
	} else {
		return 'Una póliza para el mismo vehículo ya existe';
	}
};
var $author$project$Translations$paymentFailedCardCallIssuer = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card declined - Please try again or contact your bank';
	} else {
		return 'Transacción denegada - Inténtelo nuevamente o contactar al banco';
	}
};
var $author$project$Translations$paymentFailedCardDeclined = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card declined - Please contact bank';
	} else {
		return 'Transacción denegada - Por favor contactar al banco';
	}
};
var $author$project$Translations$paymentFailedCardExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card expired';
	} else {
		return 'Tarjeta expirada';
	}
};
var $author$project$Translations$paymentFailedCardInsufficientFunds = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card declined(insufficient funds) - Please contact your bank';
	} else {
		return 'Transacción denegada (fondos insuf.) - Contactar al banco';
	}
};
var $author$project$Translations$paymentFailedCardLimitExceeded = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card declined(limit exceeded) - Please contact your bank';
	} else {
		return 'Transacción denegada (límite excedido) - Contactar al banco';
	}
};
var $author$project$Page$Payment$PaymentFailed$errorMessage = F2(
	function (i18n, code) {
		switch (code) {
			case 'ALREADY_COVERED':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentCurrentRiskAlreadyCoveredLabel(i18n));
			case 'Limit Exceeded':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardLimitExceeded(i18n));
			case 'Insufficient Funds':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardInsufficientFunds(i18n));
			case 'Declined - Call Issuer':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardCallIssuer(i18n));
			case 'Do Not Honor':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardDeclined(i18n));
			case 'Processor Declined':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardDeclined(i18n));
			case 'Expired Card':
				return $elm$core$Maybe$Just(
					$author$project$Translations$paymentFailedCardExpired(i18n));
			default:
				return $elm$core$Maybe$Nothing;
		}
	});
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $author$project$Page$Payment$PaymentFailed$jsonToErrorCode = function (json) {
	return $elm$core$Result$toMaybe(
		A2(
			$elm$json$Json$Decode$decodeValue,
			A2($elm$json$Json$Decode$field, 'code', $elm$json$Json$Decode$string),
			json));
};
var $author$project$Translations$paymentFailedContent = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Something went wrong. Purchase not available.';
	} else {
		return 'Algo ha salido mal. La compra no está disponible.';
	}
};
var $author$project$Page$Payment$PaymentFailed$content = F2(
	function (i18n, errorList) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Translations$paymentFailedContent(i18n),
			A2(
				$elm$core$Maybe$andThen,
				$author$project$Page$Payment$PaymentFailed$errorMessage(i18n),
				A2(
					$elm$core$Maybe$andThen,
					$author$project$Page$Payment$PaymentFailed$jsonToErrorCode,
					A2(
						$elm$core$Maybe$andThen,
						$elm$core$Dict$get('extensions'),
						A2(
							$elm$core$Maybe$map,
							function ($) {
								return $.kT;
							},
							$elm$core$List$head(errorList))))));
	});
var $author$project$Pyxis$Components$Message$Error = 4;
var $author$project$Pyxis$Components$Message$error = function (style) {
	return A2($author$project$Pyxis$Components$Message$config, 4, style);
};
var $krisajenkins$remotedata$RemoteData$isFailure = function (data) {
	if (data.$ === 2) {
		var x = data.a;
		return true;
	} else {
		return false;
	}
};
var $author$project$Page$Payment$Model$isStatusPaymentFailed = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.c1;
	},
	$elm$core$Basics$eq(4));
var $author$project$Pyxis$Components$Grid$oneColRowSizeM = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Grid$col(_List_Nil),
	A2(
		$elm$core$Basics$composeR,
		$elm$core$List$singleton,
		$author$project$Pyxis$Components$Grid$row(
			_List_fromArray(
				[$author$project$Pyxis$Components$Grid$Row$sizeM]))));
var $author$project$Translations$paymentCardDeclinedContent = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please re-enter your card details and try again.';
	} else {
		return 'No se ha podido realizar la operación. Inténtalo de nuevo.';
	}
};
var $author$project$Translations$paymentCardDeclinedTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Card declined';
	} else {
		return 'Tarjeta denegada';
	}
};
var $author$project$Translations$paymentFailedTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchase failed';
	} else {
		return 'Compra fallida';
	}
};
var $author$project$Translations$paymentFormHeader = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Make Payment';
	} else {
		return 'Pago de la póliza';
	}
};
var $author$project$Pyxis$Components$Message$withTitle = F2(
	function (title, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				hE: $elm$core$Maybe$Just(title)
			});
	});
var $author$project$Page$Payment$View$renderFormHeader = F2(
	function (i18n, model) {
		var paymentClientError = model.g_;
		var gotPolicyResult = model.fX;
		var viewPaymentFormHead = _List_fromArray(
			[
				$author$project$Pyxis$Components$Grid$oneColRowSizeM(
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Legend$render(
						A2(
							$author$project$Pyxis$Components$Form$Legend$withTitle,
							$author$project$Translations$paymentFormHeader(i18n),
							$author$project$Pyxis$Components$Form$Legend$config))
					]))
			]);
		var renderErrorMessageContainer = function () {
			var paymentFailedContent = function () {
				if (gotPolicyResult.$ === 2) {
					var payload = gotPolicyResult.a;
					if (!payload.$) {
						var errorList = payload.b;
						return A2($author$project$Page$Payment$PaymentFailed$content, i18n, errorList);
					} else {
						return $author$project$Translations$paymentFailedContent(i18n);
					}
				} else {
					return $author$project$Translations$paymentFailedContent(i18n);
				}
			}();
			var _v0 = $author$project$Page$Payment$Model$isStatusPaymentFailed(model) ? _Utils_Tuple2(
				$author$project$Translations$paymentFailedTitle(i18n),
				paymentFailedContent) : _Utils_Tuple2(
				$author$project$Translations$paymentCardDeclinedTitle(i18n),
				$author$project$Translations$paymentCardDeclinedContent(i18n));
			var title = _v0.a;
			var content = _v0.b;
			return _List_fromArray(
				[
					$author$project$Pyxis$Components$Grid$oneColRowSizeM(
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Message$render(
							A2(
								$author$project$Pyxis$Components$Message$withContent,
								_List_fromArray(
									[
										$elm$html$Html$text(content)
									]),
								A2(
									$author$project$Pyxis$Components$Message$withTitle,
									title,
									$author$project$Pyxis$Components$Message$error($author$project$Pyxis$Components$Message$coloredBackground))))
						]))
				]);
		}();
		var viewErrorMessage = function () {
			var hasPaymentFailed = $krisajenkins$remotedata$RemoteData$isFailure(gotPolicyResult);
			var hasPaymentClientError = $elm_community$maybe_extra$Maybe$Extra$isJust(paymentClientError);
			return (hasPaymentClientError || hasPaymentFailed) ? renderErrorMessageContainer : _List_Nil;
		}();
		return $elm$core$List$concat(
			_List_fromArray(
				[viewErrorMessage, viewPaymentFormHead]));
	});
var $author$project$Pyxis$Components$Form$Fieldset$withContent = F2(
	function (grid, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{bf: grid});
	});
var $author$project$Pyxis$Components$Form$withFieldsets = F2(
	function (gridContent, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				cv: A2(
					$elm$core$List$map,
					A2($primait$prima_elm_extra$PrimaFunction$flip, $elm$core$Tuple$pair, true),
					gridContent)
			});
	});
var $author$project$Pyxis$Components$Form$Fieldset$withFooter = F2(
	function (rows, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fO: rows});
	});
var $author$project$Pyxis$Components$Form$Fieldset$withHeader = F2(
	function (rows, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{f_: rows});
	});
var $author$project$Pyxis$Components$Form$withOnSubmit = F2(
	function (onSubmit, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				ei: $elm$core$Maybe$Just(onSubmit)
			});
	});
var $author$project$Page$Payment$View$renderPaymentForm = F2(
	function (i18n, model) {
		return $author$project$Pyxis$Components$Form$render(
			A2(
				$author$project$Pyxis$Components$Form$withFieldsets,
				_List_fromArray(
					[
						A2(
						$author$project$Pyxis$Components$Form$Fieldset$withFooter,
						A2($author$project$Page$Payment$View$renderFormFooter, i18n, model),
						A2(
							$author$project$Pyxis$Components$Form$Fieldset$withContent,
							A2($author$project$Page$Payment$View$renderFormContent, i18n, model),
							A2(
								$author$project$Pyxis$Components$Form$Fieldset$withHeader,
								A2($author$project$Page$Payment$View$renderFormHeader, i18n, model),
								$author$project$Pyxis$Components$Form$Fieldset$config)))
					]),
				A2($author$project$Pyxis$Components$Form$withOnSubmit, $author$project$Page$Payment$Msg$PaymentSubmit, $author$project$Pyxis$Components$Form$config)));
	});
var $author$project$Page$Payment$View$view = F2(
	function (i18n, model) {
		var viewFetchBraintreeToken = function () {
			var _v1 = model.fV;
			switch (_v1.$) {
				case 2:
					return _List_fromArray(
						[$author$project$Common$ErrorContainer$generic]);
				case 3:
					return _List_fromArray(
						[
							A2($author$project$Page$Payment$View$renderPaymentForm, i18n, model)
						]);
				default:
					return _List_fromArray(
						[
							$author$project$Page$Payment$View$renderLoadingPage(i18n)
						]);
			}
		}();
		var content = function () {
			var _v0 = model.fW;
			switch (_v0.$) {
				case 0:
					return _List_fromArray(
						[
							$author$project$Page$Payment$View$renderLoadingPage(i18n)
						]);
				case 1:
					return _List_fromArray(
						[
							$author$project$Page$Payment$View$renderLoadingPage(i18n)
						]);
				case 2:
					return _List_fromArray(
						[$author$project$Common$ErrorContainer$generic]);
				default:
					if (!_v0.a.b) {
						return _List_fromArray(
							[$author$project$Common$ErrorContainer$productsNotFound]);
					} else {
						return viewFetchBraintreeToken;
					}
			}
		}();
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-responsive padding-v-3xl')
					]),
				content)
			]);
	});
var $author$project$Page$Payment$view = F2(
	function (i18n, model) {
		return {
			fd: A2($author$project$Page$Payment$View$view, i18n, model),
			hE: $author$project$Translations$routePayment(i18n)
		};
	});
var $author$project$Translations$routePolicyDetails = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy details';
	} else {
		return 'Detalles de Poliza';
	}
};
var $author$project$Page$PolicyDetails$Msg$IssueRefundConfirmation = function (a) {
	return {$: 24, a: a};
};
var $author$project$Page$PolicyDetails$Msg$IssueRefundDismissed = {$: 23};
var $author$project$Page$PolicyDetails$ChangeEmailModal$ChangeEmailInputMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$Validate = {$: 3};
var $author$project$Pyxis$Components$Field$Input$Email = 0;
var $author$project$Pyxis$Components$Field$Input$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Input$M = 1;
var $author$project$Pyxis$Components$Field$Input$config = F2(
	function (inputType, name) {
		return {e_: $elm$core$Maybe$Nothing, ko: $elm$core$Maybe$Nothing, fb: $elm$core$Maybe$Nothing, fj: _List_Nil, fy: _List_Nil, fC: false, co: $elm$core$Maybe$Nothing, f2: $elm$core$Maybe$Nothing, iO: 'id-' + name, cz: false, d3: $elm$core$Maybe$Nothing, gi: $elm$core$Maybe$Nothing, gn: $elm$core$Maybe$Nothing, i6: name, g0: $elm$core$Maybe$Nothing, ax: 1, hp: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, eT: inputType, dh: $elm$core$Maybe$Nothing, hJ: $elm$core$Basics$identity};
	});
var $author$project$Pyxis$Components$Field$Input$email = $author$project$Pyxis$Components$Field$Input$config(0);
var $author$project$Page$PolicyDetails$ChangeEmailModal$formId = 'change-form-email';
var $author$project$Pyxis$Components$Field$Input$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dK;
		var value = _v0.eW;
		var id = _v1.iO;
		var isSubmitted = _v1.cz;
		var validation = _v1.dh;
		var errorShowingStrategy = _v1.co;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.lw),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.lu),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Field$Input$addonTypeToString = function (addonType) {
	if (!addonType.$) {
		return 'icon';
	} else {
		return 'text';
	}
};
var $author$project$Pyxis$Components$Field$Input$placementToString = function (placement) {
	if (!placement) {
		return 'prepend';
	} else {
		return 'append';
	}
};
var $author$project$Pyxis$Components$Field$Input$addonToAttribute = function (_v0) {
	var type_ = _v0.eT;
	var placement = _v0.S;
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			'-',
			_List_fromArray(
				[
					'form-field--with',
					$author$project$Pyxis$Components$Field$Input$placementToString(placement),
					$author$project$Pyxis$Components$Field$Input$addonTypeToString(type_)
				])));
};
var $author$project$Pyxis$Components$Field$Input$isAppend = function (placement) {
	if (placement === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$Input$isPrepend = function (placement) {
	if (!placement) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$Input$iconSizeByInputSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Input$renderAddonByType = F2(
	function (size, type_) {
		if (!type_.$) {
			var icon = type_.a;
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__addon')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Icon$render(
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Field$Input$iconSizeByInputSize(size),
							$author$project$Pyxis$Components$Icon$config(icon)))
					]));
		} else {
			var str = type_.a;
			return A2(
				$elm$html$Html$span,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__addon')
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(str)
					]));
		}
	});
var $author$project$Pyxis$Components$Field$Input$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$Input$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$Input$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$Input$getFormFieldTextSizeClass = function (size) {
	if (!size) {
		return 'form-field__text--s';
	} else {
		return '';
	}
};
var $elm$html$Html$Attributes$max = $elm$html$Html$Attributes$stringProperty('max');
var $elm$html$Html$Attributes$min = $elm$html$Html$Attributes$stringProperty('min');
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $elm$html$Html$Attributes$step = function (n) {
	return A2($elm$html$Html$Attributes$stringProperty, 'step', n);
};
var $author$project$Pyxis$Components$Field$Input$typeToAttribute = function (a) {
	switch (a) {
		case 0:
			return $elm$html$Html$Attributes$type_('email');
		case 1:
			return $elm$html$Html$Attributes$type_('number');
		case 2:
			return $elm$html$Html$Attributes$type_('password');
		default:
			return $elm$html$Html$Attributes$type_('text');
	}
};
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Pyxis$Components$Field$Input$renderInput = F3(
	function (error, _v0, _v1) {
		var modelData = _v0;
		var configData = _v1;
		return A2(
			$elm$html$Html$input,
			A2(
				$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
				configData.fy,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(configData.iO),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field__text', true),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$Input$getFormFieldTextSizeClass(configData.ax),
								true)
							])),
						$elm$html$Html$Attributes$disabled(configData.fC),
						$elm$html$Html$Attributes$value(modelData.eW),
						$author$project$Pyxis$Components$Field$Input$typeToAttribute(configData.eT),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
						$elm$html$Html$Attributes$name(configData.i6),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$placeholder, configData.g0),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$min, configData.gn),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$max, configData.gi),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$step, configData.hp),
						A2(
						$author$project$Pyxis$Commons$Attributes$maybe,
						$elm$html$Html$Attributes$attribute('autocomplete'),
						configData.fb),
						$author$project$Pyxis$Commons$Attributes$ariaInvalid(
						$author$project$Pyxis$Components$Field$Error$isVisible(error)),
						A2(
						$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
						A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.iO),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(
								$author$project$Pyxis$Components$Field$Hint$toId(configData.iO)),
							configData.f2)),
						$elm$html$Html$Events$onInput(
						A2($elm$core$Basics$composeR, configData.hJ, $author$project$Pyxis$Components$Field$Input$OnInput)),
						$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$Input$OnFocus),
						$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$Input$OnBlur)
					])),
			_List_Nil);
	});
var $author$project$Pyxis$Components$Field$Input$renderAddon = F4(
	function (error, model, configuration, addon) {
		var size = configuration.ax;
		return A2(
			$elm$html$Html$label,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-field__wrapper')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$author$project$Pyxis$Components$Field$Input$isPrepend(addon.S),
					A2($author$project$Pyxis$Components$Field$Input$renderAddonByType, size, addon.eT)),
					A3($author$project$Pyxis$Components$Field$Input$renderInput, error, model, configuration),
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$author$project$Pyxis$Components$Field$Input$isAppend(addon.S),
					A2($author$project$Pyxis$Components$Field$Input$renderAddonByType, size, addon.eT))
				]));
	});
var $author$project$Pyxis$Components$Field$Input$renderField = F3(
	function (error, model, configuration) {
		var disabled = configuration.fC;
		var addon = configuration.ko;
		var classList = configuration.fj;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field', true),
								_Utils_Tuple2(
								'form-field--error',
								$author$project$Pyxis$Components$Field$Error$isVisible(error)),
								_Utils_Tuple2('form-field--disabled', disabled)
							]),
						classList)),
					A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Components$Field$Input$addonToAttribute, addon)
				]),
			_List_fromArray(
				[
					A2(
					$elm$core$Maybe$withDefault,
					A3($author$project$Pyxis$Components$Field$Input$renderInput, error, model, configuration),
					A2(
						$elm$core$Maybe$map,
						A3($author$project$Pyxis$Components$Field$Input$renderAddon, error, model, configuration),
						addon))
				]));
	});
var $author$project$Pyxis$Components$Field$Input$render = F3(
	function (tagger, model, config_) {
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Input$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.e_,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.d3,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$map,
							tagger,
							A3($author$project$Pyxis$Components$Field$Input$renderField, error, model, config_))))));
	});
var $author$project$Pyxis$Components$Grid$Col$Option = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Grid$SpanSize$Span5 = 4;
var $author$project$Pyxis$Components$Grid$SpanSize$span5 = 4;
var $author$project$Pyxis$Components$Grid$Col$span5 = function (config) {
	return _Utils_update(
		config,
		{c0: $author$project$Pyxis$Components$Grid$SpanSize$span5});
};
var $author$project$Translations$changeEmailModalInputError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid email address.';
	} else {
		return 'Por favor, introduce una dirección de correo electrónico válida.';
	}
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$validateInputFn = F2(
	function (i18n, userInput) {
		return $author$project$Page$PolicyDetails$ChangeEmailModal$isInputValid(userInput) ? $elm$core$Result$Ok(userInput) : $elm$core$Result$Err(
			$author$project$Translations$changeEmailModalInputError(i18n));
	});
var $author$project$Pyxis$Components$Form$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				iO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Form$withNoValidate = F2(
	function (noValidate, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eb: $elm$core$Maybe$Just(noValidate)
			});
	});
var $author$project$Pyxis$Components$Field$Input$withValidationOnSubmit = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				co: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onSubmit),
				cz: isSubmitted,
				dh: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$changeEmailFormConfiguration = F2(
	function (i18n, _v0) {
		var emailInput = _v0.aH;
		var tagger = _v0.hz;
		var isSubmitted = _v0.cz;
		return A2(
			$author$project$Pyxis$Components$Form$withOnSubmit,
			tagger($author$project$Page$PolicyDetails$ChangeEmailModal$Validate),
			A2(
				$author$project$Pyxis$Components$Form$withNoValidate,
				true,
				A2(
					$author$project$Pyxis$Components$Form$withId,
					$author$project$Page$PolicyDetails$ChangeEmailModal$formId,
					A2(
						$author$project$Pyxis$Components$Form$withFieldsets,
						_List_fromArray(
							[
								A2(
								$author$project$Pyxis$Components$Form$Fieldset$withContent,
								_List_fromArray(
									[
										A2(
										$author$project$Pyxis$Components$Grid$row,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$author$project$Pyxis$Components$Grid$col,
												_List_fromArray(
													[$author$project$Pyxis$Components$Grid$Col$span5]),
												_List_fromArray(
													[
														A3(
														$author$project$Pyxis$Components$Field$Input$render,
														A2($elm$core$Basics$composeR, $author$project$Page$PolicyDetails$ChangeEmailModal$ChangeEmailInputMsg, tagger),
														emailInput,
														A3(
															$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
															$author$project$Page$PolicyDetails$ChangeEmailModal$validateInputFn(i18n),
															isSubmitted,
															$author$project$Pyxis$Components$Field$Input$email('change-email')))
													]))
											]))
									]),
								$author$project$Pyxis$Components$Form$Fieldset$config)
							]),
						$author$project$Pyxis$Components$Form$config))));
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$content = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Page$PolicyDetails$ChangeEmailModal$changeEmailFormConfiguration(i18n),
		$author$project$Pyxis$Components$Form$render);
};
var $author$project$Translations$changeEmailModalBackButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Atrás';
	}
};
var $author$project$Translations$changeEmailModalConfirmButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Confirm';
	} else {
		return 'Confirmar';
	}
};
var $author$project$Pyxis$Components$Button$withTargetFormId = F2(
	function (formId, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fR: $elm$core$Maybe$Just(formId)
			});
	});
var $author$project$Page$PolicyDetails$ChangeEmailModal$footer = F2(
	function (i18n, tagger) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						tagger($author$project$Page$PolicyDetails$ChangeEmailModal$Dismiss),
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$changeEmailModalBackButtonLabel(i18n),
								$author$project$Pyxis$Components$Button$secondary)))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withTargetFormId,
						$author$project$Page$PolicyDetails$ChangeEmailModal$formId,
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$changeEmailModalConfirmButtonLabel(i18n),
								A2($author$project$Pyxis$Components$Button$withType, $author$project$Pyxis$Components$Button$submit, $author$project$Pyxis$Components$Button$primary)))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Translations$changeEmailModalTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Change email address';
	} else {
		return 'Cambia el dirección de email';
	}
};
var $author$project$Pyxis$Components$IconSet$Mail = 103;
var $author$project$Pyxis$Components$IconSet$mail = 103;
var $author$project$Page$PolicyDetails$ChangeEmailModal$modalIcon = A2(
	$author$project$Pyxis$Components$Icon$withColor,
	$author$project$Pyxis$Tokens$Color$tokenBrandBase,
	$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$mail));
var $author$project$Page$PolicyDetails$ChangeEmailModal$header = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withTitle,
		$author$project$Translations$changeEmailModalTitle(i18n),
		A2(
			$author$project$Pyxis$Components$Modal$Header$withIcon,
			$author$project$Page$PolicyDetails$ChangeEmailModal$modalIcon,
			A2($author$project$Pyxis$Components$Modal$Header$withIcon, $author$project$Page$PolicyDetails$ChangeEmailModal$modalIcon, $author$project$Pyxis$Components$Modal$Header$config)));
};
var $author$project$Page$PolicyDetails$ChangeEmailModal$render = F2(
	function (i18n, model) {
		var tagger = model.hz;
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			$author$project$Page$PolicyDetails$ChangeEmailModal$modalVisibility(model),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A2($author$project$Page$PolicyDetails$ChangeEmailModal$footer, i18n, tagger),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					_List_fromArray(
						[
							A2($author$project$Page$PolicyDetails$ChangeEmailModal$content, i18n, model)
						]),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Page$PolicyDetails$ChangeEmailModal$header(i18n),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							tagger($author$project$Page$PolicyDetails$ChangeEmailModal$Dismiss),
							'',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Common$Render$maybe2 = F3(
	function (fn, maybeValue1, maybeValue2) {
		return A2(
			$elm$core$Maybe$withDefault,
			$author$project$Common$Render$empty,
			A3($elm$core$Maybe$map2, fn, maybeValue1, maybeValue2));
	});
var $author$project$Page$PolicyDetails$Msg$InterruptionDismissed = {$: 16};
var $author$project$Common$ReasonDescriptionStatus$couldDescriptionBeSet = A2(
	$primait$prima_elm_extra$PrimaFunction$flip,
	$elm$core$List$member,
	_List_fromArray(
		[1, 0]));
var $author$project$Page$PolicyDetails$InterruptionModal$Data$pickDescriptionStatus = function (data) {
	switch (data.$) {
		case 0:
			var descriptionStatus = data.a.z;
			return descriptionStatus;
		case 1:
			var descriptionStatus = data.a.z;
			return descriptionStatus;
		default:
			var descriptionStatus = data.a.z;
			return descriptionStatus;
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$Data$couldDescriptionBeSet = A2($elm$core$Basics$composeR, $author$project$Page$PolicyDetails$InterruptionModal$Data$pickDescriptionStatus, $author$project$Common$ReasonDescriptionStatus$couldDescriptionBeSet);
var $author$project$Page$PolicyDetails$Msg$InterruptionReasonDescriptionChanged = function (a) {
	return {$: 14, a: a};
};
var $author$project$Pyxis$Components$Field$Textarea$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Textarea$M = 1;
var $author$project$Pyxis$Components$Field$Textarea$config = function (name) {
	return {e_: $elm$core$Maybe$Nothing, fb: $elm$core$Maybe$Nothing, fj: _List_Nil, fy: _List_Nil, fC: false, co: $elm$core$Maybe$Nothing, f2: $elm$core$Maybe$Nothing, iO: 'id-' + name, cz: false, d3: $elm$core$Maybe$Nothing, i6: name, g0: $elm$core$Maybe$Nothing, ax: 1, eO: $elm$core$Maybe$Nothing, dh: $elm$core$Maybe$Nothing, hJ: $elm$core$Basics$identity};
};
var $author$project$Translations$policyInterruptionModalReasonDescriptionLabelMandatory = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Additional note (mandatory)';
	} else {
		return 'Notas adicionales (obligatório)';
	}
};
var $author$project$Translations$policyInterruptionModalReasonDescriptionLabelOptional = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Additional note (optional)';
	} else {
		return 'Notas adicionales (opcional)';
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$descriptionTextareaLabel = F2(
	function (i18n, data) {
		var _v0 = $author$project$Page$PolicyDetails$InterruptionModal$Data$pickDescriptionStatus(data);
		switch (_v0) {
			case 0:
				return $author$project$Translations$policyInterruptionModalReasonDescriptionLabelMandatory(i18n);
			case 1:
				return $author$project$Translations$policyInterruptionModalReasonDescriptionLabelOptional(i18n);
			default:
				return '';
		}
	});
var $author$project$Pyxis$Components$Field$Textarea$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dK;
		var value = _v0.eW;
		var id = _v1.iO;
		var isSubmitted = _v1.cz;
		var validation = _v1.dh;
		var errorShowingStrategy = _v1.co;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.lw),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.lu),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Field$Label$sizeM = 1;
var $author$project$Pyxis$Components$Field$Label$S = 0;
var $author$project$Pyxis$Components$Field$Label$sizeS = 0;
var $author$project$Pyxis$Components$Field$Textarea$mapLabelSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Field$Label$sizeS;
	} else {
		return $author$project$Pyxis$Components$Field$Label$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$Textarea$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$Textarea$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$Textarea$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$Textarea$getFormFieldTextareaSizeClass = function (size) {
	if (!size) {
		return 'form-field__textarea--s';
	} else {
		return '';
	}
};
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Pyxis$Components$Field$Textarea$renderTextarea = F3(
	function (error, _v0, _v1) {
		var modelData = _v0;
		var configData = _v1;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-field', true),
							_Utils_Tuple2(
							'form-field--error',
							$author$project$Pyxis$Components$Field$Error$isVisible(error)),
							_Utils_Tuple2('form-field--disabled', configData.fC)
						])),
					$elm$html$Html$Attributes$classList(configData.fj)
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$textarea,
					A2(
						$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
						configData.fy,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$id(configData.iO),
								$elm$html$Html$Attributes$classList(
								_List_fromArray(
									[
										_Utils_Tuple2('form-field__textarea', true),
										_Utils_Tuple2(
										$author$project$Pyxis$Components$Field$Textarea$getFormFieldTextareaSizeClass(configData.ax),
										true)
									])),
								$elm$html$Html$Attributes$disabled(configData.fC),
								$elm$html$Html$Attributes$name(configData.i6),
								$elm$html$Html$Attributes$value(modelData.eW),
								A2(
								$author$project$Pyxis$Commons$Attributes$maybe,
								$elm$html$Html$Attributes$attribute('autocomplete'),
								configData.fb),
								A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
								A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$placeholder, configData.g0),
								$author$project$Pyxis$Commons$Attributes$ariaInvalid(
								$author$project$Pyxis$Components$Field$Error$isVisible(error)),
								A2(
								$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
								A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.iO),
								A2(
									$elm$core$Maybe$map,
									$elm$core$Basics$always(
										$author$project$Pyxis$Components$Field$Hint$toId(configData.iO)),
									configData.f2)),
								$elm$html$Html$Events$onInput(
								A2($elm$core$Basics$composeR, configData.hJ, $author$project$Pyxis$Components$Field$Textarea$OnInput)),
								$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$Textarea$OnFocus),
								$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$Textarea$OnBlur)
							])),
					_List_Nil)
				]));
	});
var $author$project$Pyxis$Components$Field$Label$withSize = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: a});
	});
var $author$project$Pyxis$Components$Field$Textarea$render = F3(
	function (tagger, model, config_) {
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Textarea$generateErrorConfig, model, config_);
		var customizedLabel = A2(
			$elm$core$Maybe$map,
			$author$project$Pyxis$Components$Field$Label$withSize(
				$author$project$Pyxis$Components$Field$Textarea$mapLabelSize(configData.ax)),
			configData.d3);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.e_,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					customizedLabel,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$map,
							tagger,
							A3($author$project$Pyxis$Components$Field$Textarea$renderTextarea, error, model, config_))))));
	});
var $author$project$Pyxis$Components$Field$Textarea$S = 0;
var $author$project$Pyxis$Components$Field$Textarea$sizeS = 0;
var $author$project$Pyxis$Components$Field$Textarea$withLabel = F2(
	function (label, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d3: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$Textarea$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$descriptionInput = F3(
	function (i18n, textAreaModel, kindData) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('policy-details__policy-interruption__reason-description')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$Textarea$render,
					$author$project$Page$PolicyDetails$Msg$InterruptionReasonDescriptionChanged,
					textAreaModel,
					A2(
						$author$project$Pyxis$Components$Field$Textarea$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withText,
							A2($author$project$Page$PolicyDetails$InterruptionModal$View$descriptionTextareaLabel, i18n, kindData),
							$author$project$Pyxis$Components$Field$Label$config),
						A2(
							$author$project$Pyxis$Components$Field$Textarea$withSize,
							$author$project$Pyxis$Components$Field$Textarea$sizeS,
							$author$project$Pyxis$Components$Field$Textarea$config('reason-description'))))
				]));
	});
var $author$project$Translations$policyInterruptionModalExpectedNoRefundAmountMessage = F3(
	function (data, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Customer should not be eligible for a '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.jz + (' ' + data.jy))
						])),
					$elm$html$Html$text(' refund.')
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('El cliente no tendría derecho a un reembolso de '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.jy + (' ' + data.jz))
						])),
					$elm$html$Html$text('.')
				]);
		}
	});
var $author$project$Translations$policyInterruptionModalExpectedRefundAmountMessage = F3(
	function (data, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Customer\'s eligible for a '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.jz + (' ' + data.jy))
						])),
					$elm$html$Html$text(' refund.')
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('El cliente tiene derecho a un reembolso de '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.jy + (' ' + data.jz))
						])),
					$elm$html$Html$text('.')
				]);
		}
	});
var $author$project$Translations$policyInterruptionModalExpectedRefundNotAvailableMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'The refund estimate could not be processed';
	} else {
		return 'No se pudo procesar la estimación del reembolso';
	}
};
var $author$project$Pyxis$Components$Message$Success = 3;
var $author$project$Pyxis$Components$Message$success = function (style) {
	return A2($author$project$Pyxis$Components$Message$config, 3, style);
};
var $author$project$Pyxis$Components$Loaders$Loader$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Message$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$expectedRefundMessage = F2(
	function (i18n, remoteData) {
		switch (remoteData.$) {
			case 0:
				return $elm_community$html_extra$Html$Extra$nothing;
			case 1:
				return $author$project$Pyxis$Components$Loaders$Loader$render(
					A2(
						$author$project$Pyxis$Components$Loaders$Loader$withClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('margin-t-l es-fe-bo--full-width', true)
							]),
						$author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM));
			case 2:
				return $author$project$Pyxis$Components$Message$render(
					A2(
						$author$project$Pyxis$Components$Message$withClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('policy-details__policy-interruption__refund-amount-message', true)
							]),
						A2(
							$author$project$Pyxis$Components$Message$withContent,
							_List_fromArray(
								[
									$elm$html$Html$text(
									$author$project$Translations$policyInterruptionModalExpectedRefundNotAvailableMessage(i18n))
								]),
							$author$project$Pyxis$Components$Message$alert)));
			default:
				if (!remoteData.a.$) {
					var price = remoteData.a.a;
					return $author$project$Pyxis$Components$Message$render(
						A2(
							$author$project$Pyxis$Components$Message$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('policy-details__policy-interruption__refund-amount-message', true)
								]),
							A2(
								$author$project$Pyxis$Components$Message$withContent,
								A3(
									$author$project$Translations$policyInterruptionModalExpectedNoRefundAmountMessage,
									{
										jy: A2($author$project$Common$Price$amountString, i18n, price),
										jz: $author$project$Common$Price$pickCurrencySign(price)
									},
									_List_Nil,
									i18n),
								$author$project$Pyxis$Components$Message$error($author$project$Pyxis$Components$Message$coloredBackground))));
				} else {
					var price = remoteData.a.a;
					return $author$project$Pyxis$Components$Message$render(
						A2(
							$author$project$Pyxis$Components$Message$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('policy-details__policy-interruption__refund-amount-message', true)
								]),
							A2(
								$author$project$Pyxis$Components$Message$withContent,
								A3(
									$author$project$Translations$policyInterruptionModalExpectedRefundAmountMessage,
									{
										jy: A2($author$project$Common$Price$amountString, i18n, price),
										jz: $author$project$Common$Price$pickCurrencySign(price)
									},
									_List_Nil,
									i18n),
								$author$project$Pyxis$Components$Message$success($author$project$Pyxis$Components$Message$coloredBackground))));
				}
		}
	});
var $author$project$Page$PolicyDetails$Msg$InterruptionReasonSelected = function (a) {
	return {$: 11, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$Autocomplete$M = 1;
var $author$project$Pyxis$Components$Field$Autocomplete$config = function (name) {
	return {e_: $elm$core$Maybe$Nothing, fj: _List_Nil, fy: _List_Nil, fC: false, co: $elm$core$Maybe$Nothing, fP: $elm$core$Maybe$Nothing, f0: $elm$core$Maybe$Nothing, f2: $elm$core$Maybe$Nothing, iO: 'id-' + name, cz: false, d3: $elm$core$Maybe$Nothing, i6: name, gx: 'No results found.', g0: '', he: 'Reset', ax: 1, eJ: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, dh: $elm$core$Maybe$Nothing};
};
var $author$project$Translations$policyInterruptionModalReasonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Reason';
	} else {
		return 'Motivo';
	}
};
var $author$project$Translations$policyInterruptionModalReasonPlaceholder = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Select the reason';
	} else {
		return 'Seleccionar el motivo';
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dK;
		var value = _v0.eW;
		var id = _v1.iO;
		var isSubmitted = _v1.cz;
		var validation = _v1.dh;
		var errorShowingStrategy = _v1.co;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.lw),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.lu),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$Form$Dropdown$HeaderAndOptions = function (a) {
	return {$: 1, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$headerAndOptions = $author$project$Pyxis$Components$Form$Dropdown$HeaderAndOptions;
var $author$project$Pyxis$Components$Form$Dropdown$M = 1;
var $author$project$Pyxis$Components$Form$Dropdown$sizeM = 1;
var $author$project$Pyxis$Components$Form$Dropdown$S = 0;
var $author$project$Pyxis$Components$Form$Dropdown$sizeS = 0;
var $author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Form$Dropdown$sizeS;
	} else {
		return $author$project$Pyxis$Components$Form$Dropdown$sizeM;
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$NoResult = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$noResult = $author$project$Pyxis$Components$Form$Dropdown$NoResult;
var $author$project$Pyxis$Components$Form$Dropdown$Options = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$options = $author$project$Pyxis$Components$Form$Dropdown$Options;
var $author$project$Pyxis$Components$Form$Dropdown$getDropdownWrapperSizeClass = function (size) {
	if (!size) {
		return 'form-dropdown-wrapper--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$hasHeader = function (content_) {
	if (content_.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$renderHeader = function (content_) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__header')
			]),
		_List_fromArray(
			[content_]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderNoResult = function (_v0) {
	var label = _v0.d3;
	var action = _v0.al;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__no-results')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(label),
				$author$project$Pyxis$Commons$Render$renderMaybe(
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$elm$core$List$singleton,
						$elm$html$Html$div(
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('form-dropdown__no-results__action')
								]))),
					action))
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionIcon = A2(
	$elm$core$Basics$composeR,
	$author$project$Pyxis$Components$Icon$config,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Pyxis$Components$Icon$withSize($author$project$Pyxis$Components$Icon$sizeM),
		A2(
			$elm$core$Basics$composeR,
			$author$project$Pyxis$Components$Icon$withClassList(
				_List_fromArray(
					[
						_Utils_Tuple2('form-dropdown__suggestion__icon', true)
					])),
			$author$project$Pyxis$Components$Icon$render)));
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionSubtitle = function (subtitle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion__subtitle')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(subtitle)
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionTitle = function (title) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion__title')
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(title)
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderSuggestion = function (_v0) {
	var icon = _v0.iN;
	var title = _v0.hE;
	var subtitle = _v0.da;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-dropdown__suggestion')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Form$Dropdown$renderSuggestionIcon(icon),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-dropdown__suggestion__wrapper')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Form$Dropdown$renderSuggestionTitle(title),
						$author$project$Pyxis$Commons$Render$renderMaybe(
						A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Dropdown$renderSuggestionSubtitle, subtitle))
					]))
			]));
};
var $author$project$Pyxis$Components$Form$Dropdown$renderContent = function (content_) {
	switch (content_.$) {
		case 1:
			var config = content_.a;
			return A2(
				$elm$core$List$cons,
				$author$project$Pyxis$Components$Form$Dropdown$renderHeader(config.f_),
				config.mU);
		case 2:
			var options_ = content_.a;
			return options_;
		case 0:
			var noResultData = content_.a;
			return _List_fromArray(
				[
					$author$project$Pyxis$Components$Form$Dropdown$renderNoResult(noResultData)
				]);
		default:
			var config = content_.a;
			return _List_fromArray(
				[
					$author$project$Pyxis$Components$Form$Dropdown$renderSuggestion(config)
				]);
	}
};
var $author$project$Pyxis$Components$Form$Dropdown$render = F3(
	function (id, size, content_) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-dropdown-wrapper'),
					$elm$html$Html$Attributes$class(
					$author$project$Pyxis$Components$Form$Dropdown$getDropdownWrapperSizeClass(size))
				]),
			_List_fromArray(
				[
					A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							A2($elm$html$Html$Attributes$attribute, 'role', 'listbox'),
							$elm$html$Html$Attributes$classList(
							_List_fromArray(
								[
									_Utils_Tuple2('form-dropdown', true),
									_Utils_Tuple2(
									'form-dropdown--with-header',
									$author$project$Pyxis$Components$Form$Dropdown$hasHeader(content_))
								])),
							$elm$html$Html$Attributes$id(id + '-dropdown-list')
						]),
					A2(
						$elm$core$List$indexedMap,
						F2(
							function (index, item) {
								return _Utils_Tuple2(
									$elm$core$String$fromInt(index),
									item);
							}),
						$author$project$Pyxis$Components$Form$Dropdown$renderContent(content_)))
				]));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$OnHover = function (a) {
	return {$: 2, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnSelect = function (a) {
	return {$: 5, a: a};
};
var $author$project$Pyxis$Commons$Events$preventDefaultOn = F2(
	function (event, msg) {
		return A2(
			$elm$html$Html$Events$preventDefaultOn,
			event,
			A2(
				$elm$json$Json$Decode$map,
				function (msg_) {
					return _Utils_Tuple2(msg_, true);
				},
				$elm$json$Json$Decode$succeed(msg)));
	});
var $elm$html$Html$strong = _VirtualDom_node('strong');
var $author$project$Pyxis$Components$Field$Autocomplete$renderOptionText = F2(
	function (filteringValue, label) {
		if (!$elm$core$String$length(filteringValue)) {
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-m-book')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(label)
						]))
				]);
		} else {
			var matchStartIndex = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$List$head(
					A2(
						$elm$core$String$indexes,
						$elm$core$String$toLower(filteringValue),
						$elm$core$String$toLower(label))));
			var matchEndIndex = matchStartIndex + $elm$core$String$length(filteringValue);
			var labelStart = A3($elm$core$String$slice, 0, matchStartIndex, label);
			var labelEnd = A3(
				$elm$core$String$slice,
				matchEndIndex,
				$elm$core$String$length(label),
				label);
			var labelCenter = A3($elm$core$String$slice, matchStartIndex, matchEndIndex, label);
			return _List_fromArray(
				[
					A2(
					$elm$html$Html$strong,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-m-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(labelStart)
						])),
					A2(
					$elm$html$Html$span,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-m-book')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(labelCenter)
						])),
					A2(
					$elm$html$Html$strong,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('text-m-bold')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(labelEnd)
						]))
				]);
		}
	});
var $elm$core$String$trim = _String_trim;
var $author$project$Pyxis$Components$Field$Autocomplete$renderOptionsItem = F3(
	function (msgMapper, _v0, option_) {
		var modelData = _v0;
		var value = option_.eW;
		var label = option_.d3;
		var id = option_.iO;
		var isActiveOption = A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					A2(
						$elm$core$Basics$composeR,
						function ($) {
							return $.eW;
						},
						$elm$core$Basics$eq(value))),
				modelData.E));
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('form-dropdown__item'),
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2(
							'form-dropdown__item--active',
							_Utils_eq(
								modelData.eW,
								$elm$core$Maybe$Just(value))),
							_Utils_Tuple2('form-dropdown__item--hover', isActiveOption)
						])),
					$elm$html$Html$Attributes$id(id),
					$author$project$Pyxis$Commons$Attributes$role('option'),
					A2(
					$author$project$Pyxis$Commons$Events$preventDefaultOn,
					'mousedown',
					msgMapper(
						$author$project$Pyxis$Components$Field$Autocomplete$OnSelect(value))),
					$elm$html$Html$Events$onMouseEnter(
					msgMapper(
						$author$project$Pyxis$Components$Field$Autocomplete$OnHover(option_)))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$span,
					_List_Nil,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$renderOptionText,
						modelData.B,
						$elm$core$String$trim(label)))
				]));
	});
var $author$project$Pyxis$Components$Form$Dropdown$Suggestion = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Components$Form$Dropdown$suggestion = $author$project$Pyxis$Components$Form$Dropdown$Suggestion;
var $author$project$Pyxis$Components$Field$Autocomplete$renderDropdown = F3(
	function (msgMapper, model, _v0) {
		var modelData = model;
		var configData = _v0;
		var renderedOptions = A2(
			$elm$core$List$map,
			A2($author$project$Pyxis$Components$Field$Autocomplete$renderOptionsItem, msgMapper, model),
			$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model));
		var noAvailableOptions = (!$elm$core$List$length(
			$author$project$Pyxis$Components$Field$Autocomplete$getOptions(model))) && $krisajenkins$remotedata$RemoteData$isSuccess(modelData.ak);
		return ($elm$core$String$isEmpty(modelData.B) && $elm$core$List$isEmpty(renderedOptions)) ? A2(
			$elm$core$Maybe$map,
			A2(
				$author$project$Pyxis$Components$Form$Dropdown$render,
				configData.iO,
				$author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize(configData.ax)),
			A2($elm$core$Maybe$map, $author$project$Pyxis$Components$Form$Dropdown$suggestion, configData.eJ)) : (modelData.dO ? $elm$core$Maybe$Just(
			A3(
				$author$project$Pyxis$Components$Form$Dropdown$render,
				configData.iO,
				$author$project$Pyxis$Components$Field$Autocomplete$mapDropdownSize(configData.ax),
				function () {
					if (noAvailableOptions) {
						return $author$project$Pyxis$Components$Form$Dropdown$noResult(
							{al: configData.fP, d3: configData.gx});
					} else {
						var _v1 = _Utils_Tuple2(configData.f0, configData.eJ);
						if (!_v1.a.$) {
							var headerLabel = _v1.a.a;
							return $author$project$Pyxis$Components$Form$Dropdown$headerAndOptions(
								{
									f_: $elm$html$Html$text(headerLabel),
									mU: renderedOptions
								});
						} else {
							if (!_v1.b.$) {
								var _v2 = _v1.a;
								var suggestionConfig = _v1.b.a;
								return ((!$krisajenkins$remotedata$RemoteData$isSuccess(modelData.ak)) && $elm$core$String$isEmpty(modelData.B)) ? $author$project$Pyxis$Components$Form$Dropdown$suggestion(suggestionConfig) : $author$project$Pyxis$Components$Form$Dropdown$options(renderedOptions);
							} else {
								var _v3 = _v1.a;
								var _v4 = _v1.b;
								return $author$project$Pyxis$Components$Form$Dropdown$options(renderedOptions);
							}
						}
					}
				}())) : $elm$core$Maybe$Nothing);
	});
var $author$project$Pyxis$Components$Field$Autocomplete$OnBlur = {$: 0};
var $author$project$Pyxis$Components$Field$Autocomplete$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$Autocomplete$OnInput = function (a) {
	return {$: 3, a: a};
};
var $author$project$Pyxis$Commons$Attributes$ariaActiveDescendant = $elm$html$Html$Attributes$attribute('aria-activedescendant');
var $author$project$Pyxis$Commons$Attributes$ariaAutocomplete = $elm$html$Html$Attributes$attribute('aria-autocomplete');
var $author$project$Pyxis$Commons$Attributes$ariaOwns = $elm$html$Html$Attributes$attribute('aria-owns');
var $elm$html$Html$Attributes$autocomplete = function (bool) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'autocomplete',
		bool ? 'on' : 'off');
};
var $author$project$Pyxis$Components$Field$Autocomplete$getFormFieldSizeClass = function (size) {
	if (!size) {
		return 'form-field__autocomplete--s';
	} else {
		return '';
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnKeyDown = function (a) {
	return {$: 6, a: a};
};
var $author$project$Pyxis$Components$Field$Autocomplete$handleSelectKeydown = function (key) {
	return _Utils_Tuple2(
		$author$project$Pyxis$Components$Field$Autocomplete$OnKeyDown(key),
		A2(
			$elm$core$List$any,
			function (check) {
				return check(key);
			},
			_List_fromArray(
				[$author$project$Pyxis$Commons$Events$Keyboard$isArrowUp, $author$project$Pyxis$Commons$Events$Keyboard$isArrowDown, $author$project$Pyxis$Commons$Events$Keyboard$isEnter])));
};
var $author$project$Pyxis$Components$Field$Autocomplete$OnReset = {$: 4};
var $author$project$Pyxis$Components$IconSet$MagnifyingGlass = 102;
var $author$project$Pyxis$Components$IconSet$magnifyingGlass = 102;
var $author$project$Pyxis$Components$Field$Autocomplete$getFieldAddonIcon = function (_v0) {
	var modelData = _v0;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(modelData.eW) ? $author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$cross) : $author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$magnifyingGlass);
};
var $author$project$Pyxis$Components$Field$Autocomplete$iconSizeByAutocompleteSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $krisajenkins$remotedata$RemoteData$isLoading = function (data) {
	if (data.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$Autocomplete$renderFieldIconAddon = F4(
	function (size, resetLabel, disabled, model) {
		var modelData = model;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_List_fromArray(
						[
							_Utils_Tuple2('form-field__addon', true),
							_Utils_Tuple2(
							'form-field__addon--loading',
							$krisajenkins$remotedata$RemoteData$isLoading(modelData.ak))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$elm_community$maybe_extra$Maybe$Extra$isNothing(modelData.eW),
					$author$project$Pyxis$Components$Icon$render(
						A2(
							$author$project$Pyxis$Components$Icon$withSize,
							$author$project$Pyxis$Components$Field$Autocomplete$iconSizeByAutocompleteSize(size),
							$author$project$Pyxis$Components$Field$Autocomplete$getFieldAddonIcon(model)))),
					A2(
					$author$project$Pyxis$Commons$Render$renderIf,
					$elm_community$maybe_extra$Maybe$Extra$isJust(modelData.eW) && (!disabled),
					$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withIconOnly,
							$author$project$Pyxis$Components$IconSet$cross,
							A2(
								$author$project$Pyxis$Components$Button$withOnClick,
								$author$project$Pyxis$Components$Field$Autocomplete$OnReset,
								A2(
									$author$project$Pyxis$Components$Button$withSize,
									$author$project$Pyxis$Components$Button$sizeS,
									A2($author$project$Pyxis$Components$Button$withAriaLabel, resetLabel, $author$project$Pyxis$Components$Button$ghost))))))
				]));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$renderField = F4(
	function (error, msgMapper, model, _v0) {
		var modelData = model;
		var configData = _v0;
		var activeOptionId = A2(
			$elm$core$Maybe$withDefault,
			'',
			A2(
				$elm$core$Maybe$map,
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Autocomplete$getOptionData,
					function ($) {
						return $.iO;
					}),
				modelData.E));
		return A2(
			$elm$html$Html$map,
			msgMapper,
			A2(
				$elm$html$Html$label,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('form-field__wrapper')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						A2(
							$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
							configData.fy,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-field__autocomplete', true),
											_Utils_Tuple2(
											'form-field__autocomplete--filled',
											$elm_community$maybe_extra$Maybe$Extra$isJust(modelData.eW)),
											_Utils_Tuple2(
											$author$project$Pyxis$Components$Field$Autocomplete$getFormFieldSizeClass(configData.ax),
											true)
										])),
									A2($author$project$Pyxis$Commons$Events$Keyboard$onPreventDefaultOn, 'keydown', $author$project$Pyxis$Components$Field$Autocomplete$handleSelectKeydown),
									$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$Autocomplete$OnFocus),
									$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$Autocomplete$OnBlur),
									$elm$html$Html$Events$onInput($author$project$Pyxis$Components$Field$Autocomplete$OnInput),
									$elm$html$Html$Attributes$id(configData.iO),
									A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
									$elm$html$Html$Attributes$name(configData.i6),
									$author$project$Pyxis$Commons$Attributes$ariaAutocomplete('both'),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									modelData.bv,
									$author$project$Pyxis$Commons$Attributes$ariaExpanded(true)),
									$author$project$Pyxis$Commons$Attributes$role('combobox'),
									$author$project$Pyxis$Commons$Attributes$ariaOwns(configData.iO + '-dropdown-list'),
									$author$project$Pyxis$Commons$Attributes$ariaActiveDescendant(activeOptionId),
									$elm$html$Html$Attributes$autocomplete(false),
									$elm$html$Html$Attributes$disabled(configData.fC),
									$elm$html$Html$Attributes$placeholder(configData.g0),
									$elm$html$Html$Attributes$type_('text'),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									!modelData.bw,
									$elm$html$Html$Attributes$value(modelData.B)),
									A2(
									$author$project$Pyxis$Commons$Attributes$renderIf,
									modelData.bw,
									$elm$html$Html$Attributes$value(modelData.B)),
									$author$project$Pyxis$Commons$Attributes$ariaInvalid(
									$author$project$Pyxis$Components$Field$Error$isVisible(error)),
									A2(
									$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
									A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.iO),
									A2(
										$elm$core$Maybe$map,
										$elm$core$Basics$always(
											$author$project$Pyxis$Components$Field$Hint$toId(configData.iO)),
										configData.f2))
								])),
						_List_Nil),
						A4($author$project$Pyxis$Components$Field$Autocomplete$renderFieldIconAddon, configData.ax, configData.he, configData.fC, model)
					])));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$render = F3(
	function (msgMapper, model, config_) {
		var modelData = model;
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$Autocomplete$generateErrorConfig, model, config_);
		var dropdown = A3($author$project$Pyxis$Components$Field$Autocomplete$renderDropdown, msgMapper, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.e_,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.d3,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$classList(
									_List_fromArray(
										[
											_Utils_Tuple2('form-field', true),
											_Utils_Tuple2(
											'form-field--with-opened-dropdown',
											modelData.bv && ($elm_community$maybe_extra$Maybe$Extra$isJust(dropdown) && $krisajenkins$remotedata$RemoteData$isSuccess(modelData.ak))),
											_Utils_Tuple2(
											'form-field--error',
											$author$project$Pyxis$Components$Field$Error$isVisible(error)),
											_Utils_Tuple2('form-field--disabled', configData.fC)
										])),
									$elm$html$Html$Attributes$classList(configData.fj)
								]),
							_List_fromArray(
								[
									A4($author$project$Pyxis$Components$Field$Autocomplete$renderField, error, msgMapper, model, config_),
									$author$project$Pyxis$Commons$Render$renderMaybe(dropdown)
								]))))));
	});
var $author$project$Pyxis$Components$Field$Autocomplete$S = 0;
var $author$project$Pyxis$Components$Field$Autocomplete$sizeS = 0;
var $author$project$Pyxis$Components$Field$Autocomplete$withLabel = F2(
	function (label, _v0) {
		var configData = _v0;
		return _Utils_update(
			configData,
			{
				d3: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder = F2(
	function (placeholder, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{g0: placeholder});
	});
var $author$project$Pyxis$Components$Field$Autocomplete$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$reasonSelect = F2(
	function (i18n, autoCompleteModel) {
		return A3(
			$author$project$Pyxis$Components$Field$Autocomplete$render,
			$author$project$Page$PolicyDetails$Msg$InterruptionReasonSelected,
			autoCompleteModel,
			A2(
				$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
				A2(
					$author$project$Pyxis$Components$Field$Label$withText,
					$author$project$Translations$policyInterruptionModalReasonLabel(i18n),
					$author$project$Pyxis$Components$Field$Label$config),
				A2(
					$author$project$Pyxis$Components$Field$Autocomplete$withSize,
					$author$project$Pyxis$Components$Field$Autocomplete$sizeS,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
						$author$project$Translations$policyInterruptionModalReasonPlaceholder(i18n),
						$author$project$Pyxis$Components$Field$Autocomplete$config('reason-kind')))));
	});
var $author$project$Page$PolicyDetails$Msg$InterruptionDateChanged = function (a) {
	return {$: 10, a: a};
};
var $author$project$Pyxis$Components$Field$DateInput$Config = $elm$core$Basics$identity;
var $author$project$Pyxis$Components$Field$DateInput$M = 1;
var $author$project$Pyxis$Components$Field$DateInput$config = function (name) {
	return {e_: $elm$core$Maybe$Nothing, fj: _List_Nil, fy: _List_Nil, fC: false, co: $elm$core$Maybe$Nothing, f2: $elm$core$Maybe$Nothing, iO: 'id-' + name, cz: false, d3: $elm$core$Maybe$Nothing, gi: $elm$core$Maybe$Nothing, gn: $elm$core$Maybe$Nothing, i6: name, ax: 1, hp: $elm$core$Maybe$Nothing, eO: $elm$core$Maybe$Nothing, dh: $elm$core$Maybe$Nothing, hJ: $elm$core$Basics$identity};
};
var $justinmimbs$date$Date$isBetween = F3(
	function (_v0, _v1, _v2) {
		var a = _v0;
		var b = _v1;
		var x = _v2;
		return A3($justinmimbs$date$Date$isBetweenInt, a, b, x);
	});
var $author$project$Translations$policyInterruptionModalInvalidTerminationDateError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Invalid termination date';
	} else {
		return 'Fecha de interrupción no válida';
	}
};
var $author$project$Translations$policyInterruptionModalTerminationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Termination date';
	} else {
		return 'Fecha de interrupción';
	}
};
var $author$project$Pyxis$Components$Field$DateInput$generateErrorConfig = F2(
	function (_v0, _v1) {
		var fieldStatus = _v0.dK;
		var value = _v0.eW;
		var id = _v1.iO;
		var isSubmitted = _v1.cz;
		var validation = _v1.dh;
		var errorShowingStrategy = _v1.co;
		var getErrorConfig = function (validationResult) {
			return A2(
				$elm$core$Basics$composeR,
				A2($author$project$Pyxis$Components$Field$Error$config, id, validationResult),
				A2(
					$elm$core$Basics$composeR,
					$author$project$Pyxis$Components$Field$Error$withIsDirty(fieldStatus.lw),
					A2(
						$elm$core$Basics$composeR,
						$author$project$Pyxis$Components$Field$Error$withIsBlurred(fieldStatus.lu),
						$author$project$Pyxis$Components$Field$Error$withIsSubmitted(isSubmitted))));
		};
		return A3(
			$elm$core$Maybe$map2,
			getErrorConfig,
			A2(
				$elm$core$Maybe$map,
				$author$project$Pyxis$Commons$Basics$forwardValue(value),
				validation),
			errorShowingStrategy);
	});
var $author$project$Pyxis$Components$IconSet$Calendar = 16;
var $author$project$Pyxis$Components$IconSet$calendar = 16;
var $author$project$Pyxis$Components$Field$DateInput$iconSizeByInputSize = function (size) {
	if (!size) {
		return $author$project$Pyxis$Components$Icon$sizeS;
	} else {
		return $author$project$Pyxis$Components$Icon$sizeM;
	}
};
var $author$project$Pyxis$Components$Field$DateInput$renderAddon = function (_v0) {
	var size = _v0.ax;
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('form-field__addon')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Field$DateInput$iconSizeByInputSize(size),
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$calendar)))
			]));
};
var $author$project$Pyxis$Components$Field$DateInput$OnBlur = {$: 2};
var $author$project$Pyxis$Components$Field$DateInput$OnFocus = {$: 1};
var $author$project$Pyxis$Components$Field$DateInput$OnInput = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$DateInput$getFormFieldDateSizeClass = function (size) {
	if (!size) {
		return 'form-field__date--s';
	} else {
		return '';
	}
};
var $elm_community$result_extra$Result$Extra$isOk = function (x) {
	if (!x.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Pyxis$Components$Field$DateInput$renderInput = F3(
	function (error, _v0, _v1) {
		var modelData = _v0;
		var configData = _v1;
		return A2(
			$elm$html$Html$input,
			A2(
				$author$project$Pyxis$Commons$Attributes$appendDataAttrList,
				configData.fy,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$id(configData.iO),
						$elm$html$Html$Attributes$classList(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field__date', true),
								_Utils_Tuple2(
								'form-field__date--filled',
								$elm_community$result_extra$Result$Extra$isOk(
									$justinmimbs$date$Date$fromIsoString(modelData.eW))),
								_Utils_Tuple2(
								$author$project$Pyxis$Components$Field$DateInput$getFormFieldDateSizeClass(configData.ax),
								true)
							])),
						$elm$html$Html$Attributes$disabled(configData.fC),
						$elm$html$Html$Attributes$value(modelData.eW),
						$elm$html$Html$Attributes$type_('date'),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $author$project$Pyxis$Commons$Attributes$testId, configData.eO),
						$elm$html$Html$Attributes$name(configData.i6),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$min, configData.gn),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$max, configData.gi),
						A2($author$project$Pyxis$Commons$Attributes$maybe, $elm$html$Html$Attributes$step, configData.hp),
						$author$project$Pyxis$Commons$Attributes$ariaInvalid(
						$author$project$Pyxis$Components$Field$Error$isVisible(error)),
						A2(
						$author$project$Pyxis$Commons$Attributes$ariaDescribedByErrorOrHint,
						A2($author$project$Pyxis$Components$Field$Error$idFromFieldIdIfVisible, error, configData.iO),
						A2(
							$elm$core$Maybe$map,
							$elm$core$Basics$always(
								$author$project$Pyxis$Components$Field$Hint$toId(configData.iO)),
							configData.f2)),
						$elm$html$Html$Events$onInput(
						A2($elm$core$Basics$composeR, configData.hJ, $author$project$Pyxis$Components$Field$DateInput$OnInput)),
						$elm$html$Html$Events$onFocus($author$project$Pyxis$Components$Field$DateInput$OnFocus),
						$elm$html$Html$Events$onBlur($author$project$Pyxis$Components$Field$DateInput$OnBlur)
					])),
			_List_Nil);
	});
var $author$project$Pyxis$Components$Field$DateInput$renderField = F3(
	function (error, model, configuration) {
		var disabled = configuration.fC;
		var classList = configuration.fj;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$classList(
					_Utils_ap(
						_List_fromArray(
							[
								_Utils_Tuple2('form-field', true),
								_Utils_Tuple2(
								'form-field--error',
								$author$project$Pyxis$Components$Field$Error$isVisible(error)),
								_Utils_Tuple2('form-field--disabled', disabled),
								_Utils_Tuple2('form-field--with-prepend-icon', true)
							]),
						classList))
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$label,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('form-field__wrapper')
						]),
					_List_fromArray(
						[
							$author$project$Pyxis$Components$Field$DateInput$renderAddon(configuration),
							A3($author$project$Pyxis$Components$Field$DateInput$renderInput, error, model, configuration)
						]))
				]));
	});
var $author$project$Pyxis$Components$Field$DateInput$render = F3(
	function (tagger, model, config_) {
		var configData = config_;
		var error = A2($author$project$Pyxis$Components$Field$DateInput$generateErrorConfig, model, config_);
		return A2(
			$author$project$Pyxis$Components$Form$FormItem$render,
			error,
			A2(
				$author$project$Pyxis$Components$Form$FormItem$withAdditionalContent,
				configData.e_,
				A2(
					$author$project$Pyxis$Components$Form$FormItem$withLabel,
					configData.d3,
					A2(
						$author$project$Pyxis$Components$Form$FormItem$config,
						configData,
						A2(
							$elm$html$Html$map,
							tagger,
							A3($author$project$Pyxis$Components$Field$DateInput$renderField, error, model, config_))))));
	});
var $author$project$Pyxis$Components$Field$DateInput$S = 0;
var $author$project$Pyxis$Components$Field$DateInput$sizeS = 0;
var $author$project$Pyxis$Components$Field$DateInput$withLabel = F2(
	function (a, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				d3: $elm$core$Maybe$Just(a)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withMax = F2(
	function (max, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gi: $elm$core$Maybe$Just(max)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withMin = F2(
	function (min, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gn: $elm$core$Maybe$Just(min)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Pyxis$Components$Field$DateInput$withStep = F2(
	function (step, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				hp: $elm$core$Maybe$Just(step)
			});
	});
var $author$project$Pyxis$Components$Field$DateInput$withTestId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				eO: $elm$core$Maybe$Just(id)
			});
	});
var $author$project$Pyxis$Components$Field$Error$OnInput = 0;
var $author$project$Pyxis$Components$Field$Error$onInput = 0;
var $author$project$Pyxis$Components$Field$DateInput$withValidationOnInput = F3(
	function (validation, isSubmitted, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				co: $elm$core$Maybe$Just($author$project$Pyxis$Components$Field$Error$onInput),
				cz: isSubmitted,
				dh: $elm$core$Maybe$Just(
					A2($elm$core$Basics$composeR, validation, $author$project$Pyxis$Commons$ValidationResult$fromResult))
			});
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$renderDateInput = F3(
	function (i18n, dateInputModel, _v0) {
		var minDate = _v0.go;
		var maxDate = _v0.gj;
		var validation = function (date) {
			return A2(
				$elm$core$Result$andThen,
				function (value) {
					return A3($justinmimbs$date$Date$isBetween, minDate, maxDate, value) ? $elm$core$Result$Ok(value) : $elm$core$Result$Err(
						$author$project$Translations$policyInterruptionModalInvalidTerminationDateError(i18n));
				},
				$justinmimbs$date$Date$fromIsoString(date));
		};
		return A3(
			$author$project$Pyxis$Components$Field$DateInput$render,
			$author$project$Page$PolicyDetails$Msg$InterruptionDateChanged,
			dateInputModel,
			A2(
				$author$project$Pyxis$Components$Field$DateInput$withTestId,
				'interruption-date',
				A2(
					$author$project$Pyxis$Components$Field$DateInput$withStep,
					'1',
					A2(
						$author$project$Pyxis$Components$Field$DateInput$withMax,
						$justinmimbs$date$Date$toIsoString(maxDate),
						A2(
							$author$project$Pyxis$Components$Field$DateInput$withMin,
							$justinmimbs$date$Date$toIsoString(minDate),
							A3(
								$author$project$Pyxis$Components$Field$DateInput$withValidationOnInput,
								validation,
								false,
								A2(
									$author$project$Pyxis$Components$Field$DateInput$withLabel,
									A2(
										$author$project$Pyxis$Components$Field$Label$withText,
										$author$project$Translations$policyInterruptionModalTerminationDateLabel(i18n),
										$author$project$Pyxis$Components$Field$Label$config),
									A2(
										$author$project$Pyxis$Components$Field$DateInput$withSize,
										$author$project$Pyxis$Components$Field$DateInput$sizeS,
										$author$project$Pyxis$Components$Field$DateInput$config('interruption-date')))))))));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$viewInterruptionDateInput = F4(
	function (i18n, policy, kind, dateInputModel) {
		var dates = function () {
			switch (kind.$) {
				case 0:
					return $elm$core$Maybe$Nothing;
				case 1:
					return $elm$core$Maybe$Just(
						{
							gj: $author$project$Common$Policy$pickEndDate(policy),
							go: $author$project$Common$Locale$posixToCustomerDate(
								$author$project$Common$Policy$earliestCustomerTerminationDate(policy))
						});
				default:
					return $elm$core$Maybe$Just(
						{
							gj: $author$project$Common$Policy$pickEndDate(policy),
							go: $author$project$Common$Locale$posixToCustomerDate(
								$author$project$Common$Policy$earliestPrimaTerminationDate(policy))
						});
			}
		}();
		return A2(
			$author$project$Common$Render$maybe,
			A2($author$project$Page$PolicyDetails$InterruptionModal$View$renderDateInput, i18n, dateInputModel),
			dates);
	});
var $justinmimbs$time_extra$Time$Extra$Minute = 13;
var $justinmimbs$time_extra$Time$Extra$Day = 11;
var $justinmimbs$time_extra$Time$Extra$Millisecond = 15;
var $justinmimbs$time_extra$Time$Extra$Month = 2;
var $justinmimbs$time_extra$Time$Extra$add = F4(
	function (interval, n, zone, posix) {
		add:
		while (true) {
			switch (interval) {
				case 15:
					return $elm$time$Time$millisToPosix(
						$elm$time$Time$posixToMillis(posix) + n);
				case 14:
					var $temp$interval = 15,
						$temp$n = n * 1000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 13:
					var $temp$interval = 15,
						$temp$n = n * 60000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 12:
					var $temp$interval = 15,
						$temp$n = n * 3600000,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 11:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							3,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 2:
					return A3(
						$justinmimbs$time_extra$Time$Extra$posixFromDateTime,
						zone,
						A3(
							$justinmimbs$date$Date$add,
							1,
							n,
							A2($justinmimbs$date$Date$fromPosix, zone, posix)),
						A2($justinmimbs$time_extra$Time$Extra$timeFromPosix, zone, posix));
				case 0:
					var $temp$interval = 2,
						$temp$n = n * 12,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 1:
					var $temp$interval = 2,
						$temp$n = n * 3,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				case 3:
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
				default:
					var weekday = interval;
					var $temp$interval = 11,
						$temp$n = n * 7,
						$temp$zone = zone,
						$temp$posix = posix;
					interval = $temp$interval;
					n = $temp$n;
					zone = $temp$zone;
					posix = $temp$posix;
					continue add;
			}
		}
	});
var $author$project$Common$Policy$startDateTime = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Locale$midnightAtCustomerDate(policy.np);
};
var $author$project$Common$Policy$isBeforeStartDate = F2(
	function (policy, time) {
		return _Utils_cmp(
			$elm$time$Time$posixToMillis(time),
			$elm$time$Time$posixToMillis(
				$author$project$Common$Policy$startDateTime(policy))) < 0;
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$pickInterruptionAt = function (data) {
	switch (data.$) {
		case 0:
			var requestAt = data.a.nc;
			return requestAt;
		case 1:
			var interruptionAt = data.a.aL;
			return interruptionAt;
		default:
			var interruptionAt = data.a.aL;
			return interruptionAt;
	}
};
var $author$project$Translations$policyInterruptionModalActivityLabel = F3(
	function (data, bAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					$elm$html$Html$text('Policy will be active until '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.kj)
						])),
					$elm$html$Html$text(' at '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.kk)
						]))
				]);
		} else {
			return _List_fromArray(
				[
					$elm$html$Html$text('Póliza en vigor hasta el '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.kj)
						])),
					$elm$html$Html$text(' a las '),
					A3(
					$elm$html$Html$node,
					'b',
					_Utils_ap(_List_Nil, bAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text(data.kk)
						]))
				]);
		}
	});
var $author$project$Translations$policyInterruptionModalNoActivityLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy will never be active';
	} else {
		return 'Póliza no entrará en vigor';
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$viewPolicyActivityRow = F3(
	function (i18n, policy, kindData) {
		var interruptionAt = $author$project$Page$PolicyDetails$InterruptionModal$Data$pickInterruptionAt(kindData);
		var activeUntil = A4($justinmimbs$time_extra$Time$Extra$add, 13, -1, $author$project$Common$Locale$customerTimeZone, interruptionAt);
		var activeUntilDate = A2($author$project$Common$Locale$formatPosixDate, $author$project$Common$Locale$customerTimeZone, activeUntil);
		var activeUntilTime = A2($author$project$Common$Locale$formatPosixTime, $author$project$Common$Locale$customerTimeZone, activeUntil);
		var activityMessage = A2($author$project$Common$Policy$isBeforeStartDate, policy, activeUntil) ? _List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Translations$policyInterruptionModalNoActivityLabel(i18n))
			]) : A3(
			$author$project$Translations$policyInterruptionModalActivityLabel,
			{kj: activeUntilDate, kk: activeUntilTime},
			_List_Nil,
			i18n);
		if (!kindData.$) {
			return $author$project$Pyxis$Commons$Render$empty;
		} else {
			return A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('policy-details__interruptions_modal__activity-row')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('policy-details__interruptions_modal__text')
							]),
						activityMessage)
					]));
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$content = F7(
	function (i18n, policy, kindData, expectedRefundRemote, dateInputModel, autocompleteModel, textAreaModel) {
		return _List_fromArray(
			[
				A4($author$project$Page$PolicyDetails$InterruptionModal$View$viewInterruptionDateInput, i18n, policy, kindData, dateInputModel),
				A3($author$project$Page$PolicyDetails$InterruptionModal$View$viewPolicyActivityRow, i18n, policy, kindData),
				A2($author$project$Page$PolicyDetails$InterruptionModal$View$reasonSelect, i18n, autocompleteModel),
				A2(
				$author$project$Common$Render$ifCondition,
				A3($author$project$Page$PolicyDetails$InterruptionModal$View$descriptionInput, i18n, textAreaModel, kindData),
				$author$project$Page$PolicyDetails$InterruptionModal$Data$couldDescriptionBeSet(kindData)),
				A2($author$project$Page$PolicyDetails$InterruptionModal$View$expectedRefundMessage, i18n, expectedRefundRemote)
			]);
	});
var $author$project$Common$ReasonDescriptionStatus$isDescriptionValid = F2(
	function (text, status) {
		return (!(!status)) || ((!_Utils_eq(text, $elm$core$Maybe$Nothing)) && (!_Utils_eq(
			text,
			$elm$core$Maybe$Just(''))));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$canInterrupt = F2(
	function (data, fn) {
		switch (data.$) {
			case 0:
				var requestAt = data.a.nc;
				var maybeReason = data.a.i3;
				var description = data.a.kR;
				var descriptionStatus = data.a.z;
				return fn(requestAt) && ($elm_community$maybe_extra$Maybe$Extra$isJust(maybeReason) && A2($author$project$Common$ReasonDescriptionStatus$isDescriptionValid, description, descriptionStatus));
			case 1:
				var interruptionAt = data.a.aL;
				var maybeReason = data.a.i3;
				var description = data.a.kR;
				var descriptionStatus = data.a.z;
				return fn(interruptionAt) && ($elm_community$maybe_extra$Maybe$Extra$isJust(maybeReason) && A2($author$project$Common$ReasonDescriptionStatus$isDescriptionValid, description, descriptionStatus));
			default:
				var interruptionAt = data.a.aL;
				var maybeReason = data.a.i3;
				var description = data.a.kR;
				var descriptionStatus = data.a.z;
				return fn(interruptionAt) && ($elm_community$maybe_extra$Maybe$Extra$isJust(maybeReason) && A2($author$project$Common$ReasonDescriptionStatus$isDescriptionValid, description, descriptionStatus));
		}
	});
var $author$project$Common$Policy$isInterrupted = A2($elm$core$Basics$composeL, $elm_community$maybe_extra$Maybe$Extra$isJust, $author$project$Common$Policy$pickInterruption);
var $author$project$Common$Policy$isRenewalCancelled = A2($elm$core$Basics$composeL, $elm_community$maybe_extra$Maybe$Extra$isJust, $author$project$Common$Policy$pickRenewalCancellation);
var $author$project$Common$Policy$isStatusIn = F2(
	function (_v0, statuses) {
		var status = _v0.c1;
		return A2($elm$core$List$member, status, statuses);
	});
var $author$project$Common$Policy$canBeInterrupted = function (policy) {
	return A2(
		$author$project$Common$Policy$isStatusIn,
		policy,
		_List_fromArray(
			[1, 0])) && ((!$author$project$Common$Policy$isInterrupted(policy)) && (!$author$project$Common$Policy$isRenewalCancelled(policy)));
};
var $author$project$Common$Policy$endDateTime = function (_v0) {
	var policy = _v0;
	return $author$project$Common$Locale$midnightAtCustomerDate(policy.dI);
};
var $author$project$Common$Policy$isBeforeOrEqualEndDate = F2(
	function (policy, time) {
		return _Utils_cmp(
			$elm$time$Time$posixToMillis(time),
			$elm$time$Time$posixToMillis(
				$author$project$Common$Policy$endDateTime(policy))) < 1;
	});
var $author$project$Common$Policy$isInsideCoolingOffPeriod = F2(
	function (_v0, time) {
		var policy = _v0;
		var timeMillis = $elm$time$Time$posixToMillis(time);
		return A2(
			$elm$core$Maybe$withDefault,
			false,
			A2(
				$elm$core$Maybe$map,
				$elm$core$Basics$le(timeMillis),
				A2($elm$core$Maybe$map, $elm$time$Time$posixToMillis, policy.ci)));
	});
var $author$project$Common$Policy$canBeTerminatedByCustomer = F2(
	function (policy, requestAt) {
		return $author$project$Common$Policy$canBeInterrupted(policy) && ((!A2($author$project$Common$Policy$isInsideCoolingOffPeriod, policy, requestAt)) && A2($author$project$Common$Policy$isBeforeOrEqualEndDate, policy, requestAt));
	});
var $author$project$Common$Policy$canBeTerminatedByPrima = F2(
	function (policy, requestAt) {
		return $author$project$Common$Policy$canBeInterrupted(policy) && A2($author$project$Common$Policy$isBeforeOrEqualEndDate, policy, requestAt);
	});
var $author$project$Common$Policy$isBeforeEndDate = F2(
	function (policy, time) {
		return _Utils_cmp(
			$elm$time$Time$posixToMillis(time),
			$elm$time$Time$posixToMillis(
				$author$project$Common$Policy$endDateTime(policy))) < 0;
	});
var $author$project$Common$Policy$canBeWithdrawn = F2(
	function (policy, requestAt) {
		return $author$project$Common$Policy$canBeInterrupted(policy) && (A2($author$project$Common$Policy$isInsideCoolingOffPeriod, policy, requestAt) && A2($author$project$Common$Policy$isBeforeEndDate, policy, requestAt));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Data$canInterruptMapper = function (data) {
	switch (data.$) {
		case 0:
			return $author$project$Common$Policy$canBeWithdrawn;
		case 1:
			return $author$project$Common$Policy$canBeTerminatedByCustomer;
		default:
			return $author$project$Common$Policy$canBeTerminatedByPrima;
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$isInterruptionButtonDisabled = F2(
	function (policy, kindData) {
		return !A2(
			$author$project$Page$PolicyDetails$InterruptionModal$Data$canInterrupt,
			kindData,
			A2($author$project$Page$PolicyDetails$InterruptionModal$Data$canInterruptMapper, kindData, policy));
	});
var $author$project$Page$PolicyDetails$Msg$CustomerTerminationConfirmationButtonPressed = function (a) {
	return {$: 18, a: a};
};
var $author$project$Page$PolicyDetails$Msg$PrimaTerminationConfirmationButtonPressed = function (a) {
	return {$: 19, a: a};
};
var $author$project$Page$PolicyDetails$Msg$WithdrawalConfirmationButtonPressed = function (a) {
	return {$: 17, a: a};
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$onSubmit = F2(
	function (id, inputData) {
		switch (inputData.$) {
			case 0:
				var maybeReason = inputData.a.i3;
				var requestAt = inputData.a.nc;
				var description = inputData.a.kR;
				return $author$project$Page$PolicyDetails$Msg$WithdrawalConfirmationButtonPressed(
					{
						kR: description,
						iO: id,
						g8: A2($elm$core$Maybe$withDefault, 16, maybeReason),
						p: requestAt
					});
			case 1:
				var maybeReason = inputData.a.i3;
				var requestAt = inputData.a.nc;
				var interruptionAt = inputData.a.aL;
				var description = inputData.a.kR;
				return $author$project$Page$PolicyDetails$Msg$CustomerTerminationConfirmationButtonPressed(
					{
						kR: description,
						iO: id,
						aL: interruptionAt,
						g8: A2($elm$core$Maybe$withDefault, 18, maybeReason),
						p: requestAt
					});
			default:
				var maybeReason = inputData.a.i3;
				var requestAt = inputData.a.nc;
				var interruptionAt = inputData.a.aL;
				var description = inputData.a.kR;
				return $author$project$Page$PolicyDetails$Msg$PrimaTerminationConfirmationButtonPressed(
					{
						kR: description,
						iO: id,
						aL: interruptionAt,
						g8: A2($elm$core$Maybe$withDefault, 4, maybeReason),
						p: requestAt
					});
		}
	});
var $author$project$Translations$policyInterruptionModalCancelButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Atrás';
	}
};
var $author$project$Translations$policyInterruptionModalProceedButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Proceed';
	} else {
		return 'Confirmar';
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$footer = F3(
	function (i18n, policy, kindData) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						$author$project$Page$PolicyDetails$Msg$InterruptionDismissed,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$policyInterruptionModalCancelButton(i18n),
							$author$project$Pyxis$Components$Button$secondary))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withDisabled,
						A2($author$project$Page$PolicyDetails$InterruptionModal$View$isInterruptionButtonDisabled, policy, kindData),
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							A2(
								$author$project$Page$PolicyDetails$InterruptionModal$View$onSubmit,
								$author$project$Common$Policy$pickId(policy),
								kindData),
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$policyInterruptionModalProceedButton(i18n),
								$author$project$Pyxis$Components$Button$primary))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Translations$policyInterruptionModalTerminationTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Confirm policy termination';
	} else {
		return 'Confirmar terminación de póliza';
	}
};
var $author$project$Translations$policyInterruptionModalWithdrawTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Confirm Policy Withdraw';
	} else {
		return 'Confirmar Desistimiento';
	}
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$headerTitle = F2(
	function (i18n, kindData) {
		switch (kindData.$) {
			case 0:
				return $author$project$Translations$policyInterruptionModalWithdrawTitle(i18n);
			case 1:
				return $author$project$Translations$policyInterruptionModalTerminationTitle(i18n);
			default:
				return $author$project$Translations$policyInterruptionModalTerminationTitle(i18n);
		}
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$header = F2(
	function (i18n, kindData) {
		return A2(
			$author$project$Pyxis$Components$Modal$Header$withTitle,
			A2($author$project$Page$PolicyDetails$InterruptionModal$View$headerTitle, i18n, kindData),
			$author$project$Pyxis$Components$Modal$Header$config);
	});
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickDateInput = function (_v0) {
	var model = _v0;
	return model.dR;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickDescriptionInput = function (_v0) {
	var model = _v0;
	return model.dF;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickExpectedRefund = function (_v0) {
	var model = _v0;
	return model.k0;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickReasonInput = function (_v0) {
	var model = _v0;
	return model.bN;
};
var $author$project$Page$PolicyDetails$InterruptionModal$Model$pickVisibilityState = function (_v0) {
	var model = _v0;
	return model.eX;
};
var $author$project$Page$PolicyDetails$InterruptionModal$View$renderModal = F4(
	function (i18n, model, policy, kindData) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			$author$project$Page$PolicyDetails$InterruptionModal$Model$pickVisibilityState(model),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A3($author$project$Page$PolicyDetails$InterruptionModal$View$footer, i18n, policy, kindData),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A7(
						$author$project$Page$PolicyDetails$InterruptionModal$View$content,
						i18n,
						policy,
						kindData,
						$author$project$Page$PolicyDetails$InterruptionModal$Model$pickExpectedRefund(model),
						$author$project$Page$PolicyDetails$InterruptionModal$Model$pickDateInput(model),
						$author$project$Page$PolicyDetails$InterruptionModal$Model$pickReasonInput(model),
						$author$project$Page$PolicyDetails$InterruptionModal$Model$pickDescriptionInput(model)),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						A2($author$project$Page$PolicyDetails$InterruptionModal$View$header, i18n, kindData),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							$author$project$Page$PolicyDetails$Msg$InterruptionDismissed,
							'',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Page$PolicyDetails$InterruptionModal$View$render = F2(
	function (i18n, modalState) {
		return A3(
			$author$project$Common$Render$maybe2,
			A2($author$project$Page$PolicyDetails$InterruptionModal$View$renderModal, i18n, modalState),
			$author$project$Page$PolicyDetails$InterruptionModal$Model$pickPolicy(modalState),
			$author$project$Page$PolicyDetails$InterruptionModal$Model$pickKindData(modalState));
	});
var $author$project$Translations$refundIssuingModalContent = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'By confirming, you cannot undo this action.';
	} else {
		return 'Al confirmar, no podrás deshacer esta acción.';
	}
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$content = function (i18n) {
	return _List_fromArray(
		[
			A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('details__refund-modal')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Translations$refundIssuingModalContent(i18n))
				]))
		]);
};
var $author$project$Translations$refundIssuingModalNoButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'No';
	} else {
		return 'No';
	}
};
var $author$project$Translations$refundIssuingModalYesButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Yes';
	} else {
		return 'Sí';
	}
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$footer = F4(
	function (i18n, dismissMsg, confirmMsg, refund) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						dismissMsg,
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$refundIssuingModalNoButton(i18n),
								$author$project$Pyxis$Components$Button$secondary)))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						confirmMsg(refund),
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$refundIssuingModalYesButton(i18n),
								$author$project$Pyxis$Components$Button$primary))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Translations$refundIssuingModalTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Do you want to proceed with the refund?';
	} else {
		return '¿Quieres proceder con el reembolso?';
	}
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$header = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withTitle,
		$author$project$Translations$refundIssuingModalTitle(i18n),
		$author$project$Pyxis$Components$Modal$Header$config);
};
var $author$project$Page$PolicyDetails$RefundIssuingModal$renderModal = F5(
	function (i18n, dismissMsg, confirmMsg, visibility, refund) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			visibility,
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A4($author$project$Page$PolicyDetails$RefundIssuingModal$footer, i18n, dismissMsg, confirmMsg, refund),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					$author$project$Page$PolicyDetails$RefundIssuingModal$content(i18n),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Page$PolicyDetails$RefundIssuingModal$header(i18n),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							dismissMsg,
							'',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Page$PolicyDetails$RefundIssuingModal$render = F4(
	function (i18n, dismissMsg, confirmMsg, _v0) {
		var visibility = _v0.eX;
		var refund = _v0.er;
		return A2(
			$author$project$Common$Render$maybe,
			A4($author$project$Page$PolicyDetails$RefundIssuingModal$renderModal, i18n, dismissMsg, confirmMsg, visibility),
			refund);
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$pickPolicy = function (_v0) {
	var model = _v0;
	return model.j;
};
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationDismissed = {$: 30};
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationReasonDescriptionChanged = function (a) {
	return {$: 32, a: a};
};
var $author$project$Translations$renewalCancellationModalReasonDescriptionLabelMandatory = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Additional note (mandatory)';
	} else {
		return 'Notas adicionales (obligatório)';
	}
};
var $author$project$Translations$renewalCancellationModalReasonDescriptionLabelOptional = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Additional note (optional)';
	} else {
		return 'Notas adicionales (opcional)';
	}
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$descriptionTextareaLabel = F2(
	function (i18n, status) {
		switch (status) {
			case 0:
				return $author$project$Translations$renewalCancellationModalReasonDescriptionLabelMandatory(i18n);
			case 1:
				return $author$project$Translations$renewalCancellationModalReasonDescriptionLabelOptional(i18n);
			default:
				return '';
		}
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$descriptionInput = F2(
	function (i18n, _v0) {
		var model = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('policy-details__renewal-cancellation__additional-notes')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$Textarea$render,
					$author$project$Page$PolicyDetails$Msg$RenewalCancellationReasonDescriptionChanged,
					model.dF,
					A2(
						$author$project$Pyxis$Components$Field$Textarea$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withText,
							A2($author$project$Page$PolicyDetails$RenewalCancellationModal$descriptionTextareaLabel, i18n, model.z),
							$author$project$Pyxis$Components$Field$Label$config),
						A2(
							$author$project$Pyxis$Components$Field$Textarea$withSize,
							$author$project$Pyxis$Components$Field$Textarea$sizeS,
							$author$project$Pyxis$Components$Field$Textarea$config('additional-notes'))))
				]));
	});
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationReasonSelected = function (a) {
	return {$: 31, a: a};
};
var $author$project$Translations$renewalCancellationModalReasonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Reason';
	} else {
		return 'Motivo';
	}
};
var $author$project$Translations$renewalCancellationModalReasonPlaceholder = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Select the reason';
	} else {
		return 'Seleccionar el motivo';
	}
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$reasonSelect = F2(
	function (i18n, _v0) {
		var model = _v0;
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('policy-details__renewal-cancellation__reason')
				]),
			_List_fromArray(
				[
					A3(
					$author$project$Pyxis$Components$Field$Autocomplete$render,
					$author$project$Page$PolicyDetails$Msg$RenewalCancellationReasonSelected,
					model.bN,
					A2(
						$author$project$Pyxis$Components$Field$Autocomplete$withLabel,
						A2(
							$author$project$Pyxis$Components$Field$Label$withText,
							$author$project$Translations$renewalCancellationModalReasonLabel(i18n),
							$author$project$Pyxis$Components$Field$Label$config),
						A2(
							$author$project$Pyxis$Components$Field$Autocomplete$withSize,
							$author$project$Pyxis$Components$Field$Autocomplete$sizeS,
							A2(
								$author$project$Pyxis$Components$Field$Autocomplete$withPlaceholder,
								$author$project$Translations$renewalCancellationModalReasonPlaceholder(i18n),
								$author$project$Pyxis$Components$Field$Autocomplete$config('reason-kind')))))
				]));
	});
var $author$project$Translations$renewalCancellationModalDescriptionLabel = F2(
	function (expiryDate, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'The cancellation date corresponds to the original expiry date of the policy: ' + (expiryDate + '.');
		} else {
			return 'La fecha de cancelación corresponde a la fecha de vencimiento original de la póliza: ' + (expiryDate + '.');
		}
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$content = F3(
	function (i18n, model, policy) {
		var isDescriptionEditable = function (_v0) {
			var descriptionStatus = _v0.z;
			return $author$project$Common$ReasonDescriptionStatus$couldDescriptionBeSet(descriptionStatus);
		};
		var descriptionLabel = A2(
			$author$project$Translations$renewalCancellationModalDescriptionLabel,
			$author$project$Common$Locale$formatDate(
				$author$project$Common$Policy$pickEndDate(policy)),
			i18n);
		return _List_fromArray(
			[
				$elm$html$Html$text(descriptionLabel),
				A2($author$project$Page$PolicyDetails$RenewalCancellationModal$reasonSelect, i18n, model),
				A2(
				$author$project$Common$Render$ifCondition,
				A2($author$project$Page$PolicyDetails$RenewalCancellationModal$descriptionInput, i18n, model),
				isDescriptionEditable(model))
			]);
	});
var $author$project$Common$Policy$canRenewalBeCancelled = function (policy) {
	return A2(
		$author$project$Common$Policy$isStatusIn,
		policy,
		_List_fromArray(
			[1, 2])) && ((!$author$project$Common$Policy$isInterrupted(policy)) && (!$author$project$Common$Policy$isRenewalCancelled(policy)));
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$isDataInputValid = function (_v0) {
	var selectedReason = _v0.eC;
	var additionalNotes = _v0.kn;
	var descriptionStatus = _v0.z;
	return $elm_community$maybe_extra$Maybe$Extra$isJust(selectedReason) && A2($author$project$Common$ReasonDescriptionStatus$isDescriptionValid, additionalNotes, descriptionStatus);
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$isConfirmButtonDisabled = F2(
	function (model, policy) {
		return !($author$project$Common$Policy$canRenewalBeCancelled(policy) && $author$project$Page$PolicyDetails$RenewalCancellationModal$isDataInputValid(model));
	});
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationConfirmationButtonPressed = function (a) {
	return {$: 33, a: a};
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$onSubmit = F2(
	function (policy, _v0) {
		var selectedReason = _v0.eC;
		var additionalNotes = _v0.kn;
		return $author$project$Page$PolicyDetails$Msg$RenewalCancellationConfirmationButtonPressed(
			{
				kn: additionalNotes,
				iO: $author$project$Common$Policy$pickId(policy),
				g8: A2($elm$core$Maybe$withDefault, 19, selectedReason),
				p: $author$project$Common$Locale$midnightAtCustomerDate(
					$author$project$Common$Policy$pickEndDate(policy))
			});
	});
var $author$project$Translations$renewalCancellationModalBackButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Atrás';
	}
};
var $author$project$Translations$renewalCancellationModalConfirmButton = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Confirm';
	} else {
		return 'Confirmar';
	}
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$footer = F3(
	function (i18n, model, policy) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						$author$project$Page$PolicyDetails$Msg$RenewalCancellationDismissed,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$renewalCancellationModalBackButton(i18n),
							$author$project$Pyxis$Components$Button$secondary))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withDisabled,
						A2($author$project$Page$PolicyDetails$RenewalCancellationModal$isConfirmButtonDisabled, model, policy),
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							A2($author$project$Page$PolicyDetails$RenewalCancellationModal$onSubmit, policy, model),
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$renewalCancellationModalConfirmButton(i18n),
								$author$project$Pyxis$Components$Button$primary))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Translations$renewalCancellationModalHeaderTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Renewal’s cancellation';
	} else {
		return 'Cancelación de la renovación';
	}
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$header = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withTitle,
		$author$project$Translations$renewalCancellationModalHeaderTitle(i18n),
		$author$project$Pyxis$Components$Modal$Header$config);
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$pickVisibilityState = function (_v0) {
	var model = _v0;
	return model.eX;
};
var $author$project$Page$PolicyDetails$RenewalCancellationModal$renderModal = F3(
	function (i18n, model, policy) {
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			$author$project$Page$PolicyDetails$RenewalCancellationModal$pickVisibilityState(model),
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A3($author$project$Page$PolicyDetails$RenewalCancellationModal$footer, i18n, model, policy),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A3($author$project$Page$PolicyDetails$RenewalCancellationModal$content, i18n, model, policy),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Page$PolicyDetails$RenewalCancellationModal$header(i18n),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							$author$project$Page$PolicyDetails$Msg$RenewalCancellationDismissed,
							'',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Page$PolicyDetails$RenewalCancellationModal$render = F2(
	function (i18n, modalState) {
		return A2(
			$author$project$Common$Render$maybe,
			A2($author$project$Page$PolicyDetails$RenewalCancellationModal$renderModal, i18n, modalState),
			$author$project$Page$PolicyDetails$RenewalCancellationModal$pickPolicy(modalState));
	});
var $author$project$Page$PolicyDetails$SendEmailModal$ModalDismissed = {$: 1};
var $author$project$Page$PolicyDetails$SendEmailModal$CheckboxGroupUpdate = function (a) {
	return {$: 0, a: a};
};
var $author$project$Page$PolicyDetails$SendEmailModal$PolicyContract = 0;
var $author$project$Translations$sendPolicyEmailModalPolicyContractEmailLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy documents';
	} else {
		return 'Documentos de la póliza';
	}
};
var $author$project$Translations$sendPolicyEmailModalSelectionErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'You need to select an email';
	} else {
		return 'Debes seleccionar un email';
	}
};
var $author$project$Page$PolicyDetails$SendEmailModal$validation = F2(
	function (i18n, selected) {
		if (!selected.b) {
			return $elm$core$Result$Err(
				$author$project$Translations$sendPolicyEmailModalSelectionErrorMessage(i18n));
		} else {
			return $elm$core$Result$Ok(selected);
		}
	});
var $author$project$Page$PolicyDetails$SendEmailModal$emailCheckBoxGroup = F3(
	function (i18n, tagger, _v0) {
		var isSubmitted = _v0.cz;
		var checkboxModel = _v0.aE;
		return A3(
			$author$project$Pyxis$Components$Field$CheckboxGroup$render,
			A2($elm$core$Basics$composeL, tagger, $author$project$Page$PolicyDetails$SendEmailModal$CheckboxGroupUpdate),
			checkboxModel,
			A2(
				$author$project$Pyxis$Components$Field$CheckboxGroup$withOptionsCard,
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Field$CheckboxGroup$optionCardSizeM(
						{
							ko: $elm$core$Maybe$Nothing,
							nB: $elm$core$Maybe$Nothing,
							hE: $elm$core$Maybe$Just(
								$author$project$Translations$sendPolicyEmailModalPolicyContractEmailLabel(i18n)),
							eW: 0
						})
					]),
				A3(
					$author$project$Pyxis$Components$Field$CheckboxGroup$withValidationOnSubmit,
					$author$project$Page$PolicyDetails$SendEmailModal$validation(i18n),
					isSubmitted,
					$author$project$Pyxis$Components$Field$CheckboxGroup$config('policy-email-selection'))));
	});
var $author$project$Page$PolicyDetails$SendEmailModal$emailRecipientText = function (email) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('text-l-bold', true)
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(email)
			]));
};
var $author$project$Translations$sendPolicyEmailModalEmailRecipientLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Emails will be sent to';
	} else {
		return 'El email se enviará a';
	}
};
var $author$project$Page$PolicyDetails$SendEmailModal$emailRecipientBlock = F2(
	function (i18n, emailRecipient) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('send-policy-email-modal__receiver-block')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Translations$sendPolicyEmailModalEmailRecipientLabel(i18n)),
					A2($author$project$Common$Render$maybe, $author$project$Page$PolicyDetails$SendEmailModal$emailRecipientText, emailRecipient)
				]));
	});
var $author$project$Translations$sendPolicyEmailModalCheckboxSelectionTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Which email do you want to send to the customer?';
	} else {
		return '¿Qué email quieres enviarle al cliente?';
	}
};
var $author$project$Page$PolicyDetails$SendEmailModal$content = F3(
	function (i18n, tagger, model) {
		var emailRecipient = model.fG;
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('send-policy-email-modal')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('send-policy-email-modal__which-email-text')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text(
								$author$project$Translations$sendPolicyEmailModalCheckboxSelectionTitle(i18n))
							])),
						A3($author$project$Page$PolicyDetails$SendEmailModal$emailCheckBoxGroup, i18n, tagger, model),
						A2($author$project$Page$PolicyDetails$SendEmailModal$emailRecipientBlock, i18n, emailRecipient)
					]))
			]);
	});
var $author$project$Page$PolicyDetails$SendEmailModal$Validate = {$: 2};
var $author$project$Translations$sendPolicyEmailModalDismissButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Back';
	} else {
		return 'Atrás';
	}
};
var $author$project$Translations$sendPolicyEmailModalSubmitButtonText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Send email';
	} else {
		return 'Enviar email';
	}
};
var $author$project$Page$PolicyDetails$SendEmailModal$footer = F2(
	function (i18n, tagger) {
		return A2(
			$author$project$Pyxis$Components$Modal$Footer$withButtons,
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						tagger($author$project$Page$PolicyDetails$SendEmailModal$ModalDismissed),
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$sendPolicyEmailModalDismissButtonText(i18n),
								$author$project$Pyxis$Components$Button$secondary)))),
					$author$project$Pyxis$Components$Button$render(
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						tagger($author$project$Page$PolicyDetails$SendEmailModal$Validate),
						A2(
							$author$project$Pyxis$Components$Button$withWidth,
							$author$project$Pyxis$Components$Button$inherit,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$sendPolicyEmailModalSubmitButtonText(i18n),
								$author$project$Pyxis$Components$Button$primary))))
				]),
			$author$project$Pyxis$Components$Modal$Footer$config);
	});
var $author$project$Page$PolicyDetails$SendEmailModal$modalIcon = A2(
	$author$project$Pyxis$Components$Icon$withColor,
	$author$project$Pyxis$Tokens$Color$tokenBrandBase,
	$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$paperPlane));
var $author$project$Translations$sendPolicyEmailModalTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Set up email sending';
	} else {
		return 'Configurar el envío de emails';
	}
};
var $author$project$Page$PolicyDetails$SendEmailModal$header = function (i18n) {
	return A2(
		$author$project$Pyxis$Components$Modal$Header$withTitle,
		$author$project$Translations$sendPolicyEmailModalTitle(i18n),
		A2(
			$author$project$Pyxis$Components$Modal$Header$withIcon,
			$author$project$Page$PolicyDetails$SendEmailModal$modalIcon,
			A2($author$project$Pyxis$Components$Modal$Header$withIcon, $author$project$Page$PolicyDetails$SendEmailModal$modalIcon, $author$project$Pyxis$Components$Modal$Header$config)));
};
var $author$project$Page$PolicyDetails$SendEmailModal$render = F2(
	function (i18n, model) {
		var visibility = model.eX;
		var tagger = model.hz;
		return A2(
			$author$project$Pyxis$Components$Modal$render,
			visibility,
			A2(
				$author$project$Pyxis$Components$Modal$withFooter,
				A2($author$project$Page$PolicyDetails$SendEmailModal$footer, i18n, tagger),
				A2(
					$author$project$Pyxis$Components$Modal$withContent,
					A3($author$project$Page$PolicyDetails$SendEmailModal$content, i18n, tagger, model),
					A2(
						$author$project$Pyxis$Components$Modal$withHeader,
						$author$project$Page$PolicyDetails$SendEmailModal$header(i18n),
						A3(
							$author$project$Pyxis$Components$Modal$withCloseMsg,
							tagger($author$project$Page$PolicyDetails$SendEmailModal$ModalDismissed),
							'',
							A2($author$project$Pyxis$Components$Modal$withSize, $author$project$Pyxis$Components$Modal$sizeS, $author$project$Pyxis$Components$Modal$config))))));
	});
var $author$project$Page$PolicyDetails$Msg$CreateRefundButtonPressed = function (a) {
	return {$: 21, a: a};
};
var $author$project$Page$PolicyDetails$Msg$InterruptionButtonPressed = function (a) {
	return {$: 15, a: a};
};
var $author$project$Page$PolicyDetails$Msg$IssueRefundRequest = function (a) {
	return {$: 22, a: a};
};
var $author$project$Page$PolicyDetails$Msg$RenewalCancellationButtonPressed = function (a) {
	return {$: 29, a: a};
};
var $author$project$Page$PolicyDetails$Msg$RenewalsLinkClicked = function (a) {
	return {$: 35, a: a};
};
var $author$project$Page$PolicyDetails$Msg$RetryGetPaymentMethod = function (a) {
	return {$: 38, a: a};
};
var $author$project$Page$PolicyDetails$Msg$ShowTooltip = function (a) {
	return {$: 5, a: a};
};
var $author$project$Common$CoverContinuity$PreviousInsuranceInfo = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$PolicyDetails$Msg$ChangeEmail = function (a) {
	return {$: 44, a: a};
};
var $author$project$Common$User$hasChangeEmailPermission = function (user) {
	return A2($author$project$Common$User$hasPermission, user, 'policy:update');
};
var $author$project$Common$Portfolio$withOnChangeEmailAction = F2(
	function (action, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				gF: $elm$core$Maybe$Just(action)
			});
	});
var $author$project$Page$PolicyDetails$View$maybeAddChangeEmailActionToPortfolioConfig = F2(
	function (user, conf) {
		return $author$project$Common$User$hasChangeEmailPermission(user) ? A2($author$project$Common$Portfolio$withOnChangeEmailAction, $author$project$Page$PolicyDetails$Msg$ChangeEmail, conf) : conf;
	});
var $author$project$Page$PolicyDetails$Model$pickCustomerId = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.fw;
	},
	A2($elm$core$Basics$composeR, $krisajenkins$remotedata$RemoteData$toMaybe, $elm_community$maybe_extra$Maybe$Extra$join));
var $author$project$Common$Policy$pickData = function (_v0) {
	var policy = _v0;
	return policy;
};
var $author$project$Page$PolicyDetails$View$portfolioConfig = F3(
	function (user, policy, model) {
		var _v0 = $author$project$Common$Policy$pickData(policy);
		var vehicleOwner = _v0.j7;
		var mainDriver = _v0.d5;
		var additionalDriver = _v0.hS;
		var policyHolder = _v0.g2;
		var vehicle = _v0.hL;
		var coverContinuity = _v0.$8;
		var selectedBundle = _v0.hg;
		return A2(
			$author$project$Page$PolicyDetails$View$maybeAddChangeEmailActionToPortfolioConfig,
			user,
			$author$project$Common$Portfolio$config(vehicleOwner)(mainDriver)(additionalDriver)(policyHolder)(vehicle)(model.cf)(
				$author$project$Common$CoverContinuity$PreviousInsuranceInfo(coverContinuity))(selectedBundle)($author$project$Page$PolicyDetails$Msg$ShowTooltip)(
				$author$project$Page$PolicyDetails$Model$pickCustomerId(model)));
	});
var $author$project$Translations$policyCreateRefundSuccessMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Refund has been correctly created.';
	} else {
		return 'Reembolso creado correctamente.';
	}
};
var $author$project$Translations$policyGeneralErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'An error has occurred, please contact the development team.';
	} else {
		return 'Se ha detectado un error, contacta con el equipo de desarrollo.';
	}
};
var $author$project$Page$PolicyDetails$View$viewResultMessage = F3(
	function (remoteData, errorMessage, successMessage) {
		switch (remoteData.$) {
			case 0:
				return $elm_community$html_extra$Html$Extra$nothing;
			case 1:
				return $elm_community$html_extra$Html$Extra$nothing;
			case 2:
				return $author$project$Pyxis$Components$Message$render(
					A2(
						$author$project$Pyxis$Components$Message$withContent,
						_List_fromArray(
							[
								$elm$html$Html$text(errorMessage)
							]),
						A2(
							$author$project$Pyxis$Components$Message$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('policy-details__action-result-toast', true)
								]),
							$author$project$Pyxis$Components$Message$error($author$project$Pyxis$Components$Message$coloredBackground))));
			default:
				return $author$project$Pyxis$Components$Message$render(
					A2(
						$author$project$Pyxis$Components$Message$withContent,
						_List_fromArray(
							[
								$elm$html$Html$text(successMessage)
							]),
						A2(
							$author$project$Pyxis$Components$Message$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('policy-details__action-result-toast', true)
								]),
							$author$project$Pyxis$Components$Message$success($author$project$Pyxis$Components$Message$coloredBackground))));
		}
	});
var $author$project$Page$PolicyDetails$View$viewCreateRefundResultMessage = F2(
	function (i18n, _v0) {
		var createRefundResult = _v0.fr;
		return A3(
			$author$project$Page$PolicyDetails$View$viewResultMessage,
			createRefundResult,
			$author$project$Translations$policyGeneralErrorMessage(i18n),
			$author$project$Translations$policyCreateRefundSuccessMessage(i18n));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$InterruptedPolicy = F3(
	function (a, b, c) {
		return {$: 1, a: a, b: b, c: c};
	});
var $author$project$Common$ComponentUI$DetailsResultBox$newInterruptedPolicyConfiguration = F5(
	function (i18n, actions, refundData, policy, interruption) {
		return A3(
			$author$project$Common$ComponentUI$DetailsResultBox$InterruptedPolicy,
			{
				ln: i18n,
				aR: $author$project$Common$Policy$gross(policy)
			},
			{bH: actions.eg, j: policy},
			{cw: interruption, gK: actions.gJ, gQ: actions.gP, bG: actions.bG, cZ: refundData});
	});
var $author$project$Common$ComponentUI$DetailsResultBox$DetailsResultInterruptableData = F4(
	function (onInterruptionButtonClicked, interruptionStatus, onRenewalCancellationButtonClicked, canRenewalBeCancelled) {
		return {h_: canRenewalBeCancelled, f6: interruptionStatus, jg: onInterruptionButtonClicked, ji: onRenewalCancellationButtonClicked};
	});
var $author$project$Common$ComponentUI$DetailsResultBox$Policy = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $author$project$Common$ComponentUI$DetailsResultBox$InterruptionStatus = F3(
	function (canBeWithdrawn, canBeTerminatedByCustomer, canBeTerminatedByPrima) {
		return {hY: canBeTerminatedByCustomer, hZ: canBeTerminatedByPrima, ff: canBeWithdrawn};
	});
var $author$project$Common$ComponentUI$DetailsResultBox$newInterruptionStatus = F2(
	function (time, policy) {
		return A3(
			$author$project$Common$ComponentUI$DetailsResultBox$InterruptionStatus,
			A2($author$project$Common$Policy$canBeWithdrawn, policy, time),
			A2($author$project$Common$Policy$canBeTerminatedByCustomer, policy, time),
			A2($author$project$Common$Policy$canBeTerminatedByPrima, policy, time));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$newPolicyConfiguration = F5(
	function (i18n, actions, user, time, policy) {
		return A4(
			$author$project$Common$ComponentUI$DetailsResultBox$Policy,
			{
				ln: i18n,
				aR: $author$project$Common$Policy$gross(policy)
			},
			{bH: actions.eg, j: policy},
			A4(
				$author$project$Common$ComponentUI$DetailsResultBox$DetailsResultInterruptableData,
				actions.gO,
				A2($author$project$Common$ComponentUI$DetailsResultBox$newInterruptionStatus, time, policy),
				actions.gU,
				$author$project$Common$Policy$canRenewalBeCancelled(policy)),
			user);
	});
var $author$project$Common$ComponentUI$DetailsResultBox$newPolicyViewConfiguration = F6(
	function (i18n, actions, user, time, refundData, policy) {
		return A2(
			$elm$core$Maybe$withDefault,
			A5($author$project$Common$ComponentUI$DetailsResultBox$newPolicyConfiguration, i18n, actions, user, time, policy),
			A2(
				$elm$core$Maybe$map,
				A4($author$project$Common$ComponentUI$DetailsResultBox$newInterruptedPolicyConfiguration, i18n, actions, refundData, policy),
				$author$project$Common$Policy$pickInterruption(policy)));
	});
var $author$project$Common$ComponentUI$DetailsResultBox$viewFromPolicy = F6(
	function (i18n, actions, user, time, refundData, policy) {
		return $author$project$Common$ComponentUI$DetailsResultBox$view(
			A6($author$project$Common$ComponentUI$DetailsResultBox$newPolicyViewConfiguration, i18n, actions, user, time, refundData, policy));
	});
var $author$project$Page$PolicyDetails$Msg$SendEmailButtonClicked = F2(
	function (a, b) {
		return {$: 40, a: a, b: b};
	});
var $author$project$Page$PolicyDetails$View$emailSendButton = F3(
	function (i18n, policyId, email) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				A2($author$project$Page$PolicyDetails$Msg$SendEmailButtonClicked, policyId, email),
				A2(
					$author$project$Pyxis$Components$Button$withIconPrepend,
					$author$project$Pyxis$Components$IconSet$paperPlane,
					A2(
						$author$project$Pyxis$Components$Button$withSize,
						$author$project$Pyxis$Components$Button$sizeM,
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$sendPolicyEmailModalSubmitButtonText(i18n),
							$author$project$Pyxis$Components$Button$tertiary)))));
	});
var $author$project$Page$PolicyDetails$Msg$RedirectToSearch = function (a) {
	return {$: 4, a: a};
};
var $author$project$Page$PolicyDetails$View$navigateToResults = A2(
	$elm$core$Basics$composeR,
	$author$project$Common$Policy$pickPolicyHolder,
	A2(
		$elm$core$Basics$composeR,
		$author$project$Common$Person$pickPersonData,
		A2(
			$elm$core$Basics$composeR,
			function ($) {
				return $.ai;
			},
			A2($elm$core$Basics$composeR, $author$project$Page$Search$SearchQueryParam$DocumentId, $author$project$Page$PolicyDetails$Msg$RedirectToSearch))));
var $author$project$Page$PolicyDetails$View$viewHeaderContainer = F3(
	function (i18n, model, policy) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('policy-details-header-container')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Common$ComponentUI$BackToResults$view,
					i18n,
					$author$project$Page$PolicyDetails$View$navigateToResults(policy)),
					A3(
					$author$project$Page$PolicyDetails$View$emailSendButton,
					i18n,
					$author$project$Common$Policy$pickId(policy),
					$author$project$Page$PolicyDetails$Model$pickEmailRecipient(model))
				]));
	});
var $author$project$Translations$policyInterruptionSuccessMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy has been correctly interrupted.';
	} else {
		return 'Póliza interrumpida correctamente.';
	}
};
var $author$project$Page$PolicyDetails$View$viewInterruptionResultMessage = F2(
	function (i18n, _v0) {
		var interruptionResult = _v0.dT;
		return A3(
			$author$project$Page$PolicyDetails$View$viewResultMessage,
			interruptionResult,
			$author$project$Translations$policyGeneralErrorMessage(i18n),
			$author$project$Translations$policyInterruptionSuccessMessage(i18n));
	});
var $author$project$Page$PolicyDetails$Model$pickRefund = A2(
	$elm$core$Basics$composeR,
	function ($) {
		return $.er;
	},
	$krisajenkins$remotedata$RemoteData$toMaybe);
var $author$project$Translations$policyIssueRefundSuccessMessage = F2(
	function (refundAmount, _v0) {
		var lang = _v0;
		if (!lang) {
			return 'The refund was issued successfully. The customer will receive ' + (refundAmount + '.');
		} else {
			return 'El reembolso ha sido efectuado correctamente. El cliente recibirá ' + (refundAmount + '.');
		}
	});
var $author$project$Page$PolicyDetails$View$viewIssueRefundResultMessage = F2(
	function (i18n, model) {
		var issueRefundResult = model.ly;
		return A2(
			$elm$core$Maybe$withDefault,
			$elm_community$html_extra$Html$Extra$nothing,
			A2(
				$elm$core$Maybe$map,
				A2(
					$author$project$Page$PolicyDetails$View$viewResultMessage,
					issueRefundResult,
					$author$project$Translations$policyGeneralErrorMessage(i18n)),
				A2(
					$elm$core$Maybe$map,
					A2(
						$elm$core$Basics$composeR,
						$author$project$Common$Price$toStringSignFirst(i18n),
						A2($primait$prima_elm_extra$PrimaFunction$flip, $author$project$Translations$policyIssueRefundSuccessMessage, i18n)),
					A2(
						$elm$core$Maybe$map,
						$author$project$Common$Refund$pickPrice,
						$author$project$Page$PolicyDetails$Model$pickRefund(model)))));
	});
var $author$project$Translations$policyRenewalCancellationErrorMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'We were unable to cancel the renewal. Please refresh the page to try again.';
	} else {
		return 'No pudimos cancelar la renovación. Actualiza la página para intentarlo nuevamente.';
	}
};
var $author$project$Translations$policyRenewalCancellationSuccessMessage = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Renewal has been correctly cancelled.';
	} else {
		return 'La renovación se ha cancelado correctamente.';
	}
};
var $author$project$Page$PolicyDetails$View$viewRenewalCancellationResultMessage = F2(
	function (i18n, _v0) {
		var renewalCancellationResult = _v0.jB;
		return A3(
			$author$project$Page$PolicyDetails$View$viewResultMessage,
			renewalCancellationResult,
			$author$project$Translations$policyRenewalCancellationErrorMessage(i18n),
			$author$project$Translations$policyRenewalCancellationSuccessMessage(i18n));
	});
var $author$project$Page$PolicyDetails$View$viewPolicy = F5(
	function (i18n, user, model, policy, time) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('policy-details__primary-container container-responsive padding-v-3xl')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$PolicyDetails$View$viewInterruptionResultMessage, i18n, model),
					A2($author$project$Page$PolicyDetails$View$viewCreateRefundResultMessage, i18n, model),
					A2($author$project$Page$PolicyDetails$View$viewIssueRefundResultMessage, i18n, model),
					A2($author$project$Page$PolicyDetails$View$viewRenewalCancellationResultMessage, i18n, model),
					A3($author$project$Page$PolicyDetails$View$viewHeaderContainer, i18n, model, policy),
					A6(
					$author$project$Common$ComponentUI$DetailsResultBox$viewFromPolicy,
					i18n,
					{gJ: $author$project$Page$PolicyDetails$Msg$CreateRefundButtonPressed, eg: $author$project$Page$PolicyDetails$Msg$ShowTooltip, gO: $author$project$Page$PolicyDetails$Msg$InterruptionButtonPressed, gP: $author$project$Page$PolicyDetails$Msg$IssueRefundRequest, gU: $author$project$Page$PolicyDetails$Msg$RenewalCancellationButtonPressed, bG: $author$project$Page$PolicyDetails$Msg$RenewalsLinkClicked},
					user,
					time,
					model.er,
					policy),
					A3(
					$author$project$Common$Portfolio$render,
					i18n,
					model.cW,
					A3($author$project$Page$PolicyDetails$View$portfolioConfig, user, policy, model)),
					A3(
					$author$project$Common$ComponentUI$PaymentBox$view,
					i18n,
					model.jm,
					$author$project$Page$PolicyDetails$Msg$RetryGetPaymentMethod(
						$author$project$Common$Policy$pickId(policy)))
				]));
	});
var $author$project$Page$PolicyDetails$View$viewToasts = function (model) {
	return A3(
		$author$project$Pyxis$Components$Toasts$render,
		$author$project$Page$PolicyDetails$Msg$ToastMsg,
		model.O,
		A2(
			$author$project$Pyxis$Components$Toasts$withPlacement,
			$author$project$Pyxis$Components$Toasts$topRightPlacement,
			$author$project$Pyxis$Components$Toasts$config('policy-details-toaster')));
};
var $author$project$Page$PolicyDetails$View$view = F3(
	function (i18n, user, model) {
		var _v0 = _Utils_Tuple2(model.j, model.ik);
		_v0$3:
		while (true) {
			switch (_v0.a.$) {
				case 3:
					if (!_v0.a.a.$) {
						if (!_v0.b.$) {
							var policy = _v0.a.a.a;
							var time = _v0.b.a;
							return _List_fromArray(
								[
									$author$project$Page$PolicyDetails$View$viewToasts(model),
									A5($author$project$Page$PolicyDetails$View$viewPolicy, i18n, user, model, policy, time),
									A2($author$project$Page$PolicyDetails$InterruptionModal$View$render, i18n, model.o),
									A2($author$project$Page$PolicyDetails$RenewalCancellationModal$render, i18n, model.ab),
									A2($author$project$Page$PolicyDetails$SendEmailModal$render, i18n, model.bS),
									A4($author$project$Page$PolicyDetails$RefundIssuingModal$render, i18n, $author$project$Page$PolicyDetails$Msg$IssueRefundDismissed, $author$project$Page$PolicyDetails$Msg$IssueRefundConfirmation, model.bO),
									A2($author$project$Page$PolicyDetails$ChangeEmailModal$render, i18n, model.ba)
								]);
						} else {
							break _v0$3;
						}
					} else {
						var _v1 = _v0.a.a;
						return _List_fromArray(
							[$author$project$Common$ErrorContainer$notFound]);
					}
				case 2:
					return _List_fromArray(
						[$author$project$Common$ErrorContainer$generic]);
				default:
					break _v0$3;
			}
		}
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('policy-details__loading')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Loaders$Loader$render($author$project$Pyxis$Components$Loaders$Loader$spinnerSizeM)
					]))
			]);
	});
var $author$project$Page$PolicyDetails$view = F3(
	function (i18n, user, model) {
		return {
			fd: A3($author$project$Page$PolicyDetails$View$view, i18n, user, model),
			hE: $author$project$Translations$routePolicyDetails(i18n)
		};
	});
var $author$project$Translations$routeSearch = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Search';
	} else {
		return 'Busqueda';
	}
};
var $author$project$Page$Search$Msg$RedirectToPayment = function (a) {
	return {$: 3, a: a};
};
var $author$project$Page$Search$Msg$ShowLegalInformationModal = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Page$Search$Msg$ShowRecordingDisclamerModal = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Page$Search$Msg$ChangedPersonalIdNumber = function (a) {
	return {$: 1, a: a};
};
var $author$project$Page$Search$Msg$Submitted = {$: 0};
var $author$project$Translations$searchBarInputDniError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid DNI/NIE';
	} else {
		return 'Por favor, introduce un DNI/NIE válido.';
	}
};
var $author$project$Translations$searchBarInputEmailError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid email address.';
	} else {
		return 'Por favor, introduce una dirección de correo electrónico válida.';
	}
};
var $author$project$Translations$searchBarInputGenericError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid value and search again.';
	} else {
		return 'Por favor, introduce un valor válido y busca de nuevo.';
	}
};
var $author$project$Translations$searchBarInputOfferError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid offer ID.';
	} else {
		return 'Por favor, introduce un número de oferta válido.';
	}
};
var $author$project$Translations$searchBarInputPhoneError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid phone number.';
	} else {
		return 'Por favor, introduce un número de teléfono válido.';
	}
};
var $author$project$Translations$searchBarInputPlateNumberError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid plate number.';
	} else {
		return 'Por favor, introduce un número de matrícula válido.';
	}
};
var $author$project$Translations$searchBarInputPolicyError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid policy ID.';
	} else {
		return 'Por favor, introduce un número de póliza válido.';
	}
};
var $author$project$Translations$searchBarInputQuoteError = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Please enter a valid quote ID.';
	} else {
		return 'Por favor, introduce un número de presupuesto válido.';
	}
};
var $author$project$Page$Search$SearchBar$validationErrorMessage = F2(
	function (i18n, error) {
		switch (error.$) {
			case 1:
				return $author$project$Translations$searchBarInputPolicyError(i18n);
			case 2:
				return $author$project$Translations$searchBarInputOfferError(i18n);
			case 3:
				return $author$project$Translations$searchBarInputQuoteError(i18n);
			case 0:
				return $author$project$Translations$searchBarInputGenericError(i18n);
			case 4:
				return $author$project$Translations$searchBarInputEmailError(i18n);
			case 5:
				return $author$project$Translations$searchBarInputDniError(i18n);
			case 6:
				return $author$project$Translations$searchBarInputPlateNumberError(i18n);
			case 7:
				return $author$project$Translations$searchBarInputPhoneError(i18n);
			case 8:
				return $author$project$Translations$searchBarInputGenericError(i18n);
			default:
				return $author$project$Translations$searchBarInputGenericError(i18n);
		}
	});
var $author$project$Page$Search$SearchBar$inputFieldValidations = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Page$Search$SearchQueryParam$fromStringInputToSearchInput,
		A2(
			$elm$core$Basics$composeR,
			$author$project$Page$Search$SearchQueryParam$validateSearchInput,
			$elm$core$Result$mapError(
				$author$project$Page$Search$SearchBar$validationErrorMessage(i18n))));
};
var $author$project$Page$Search$SearchBar$isLoading = function (query) {
	return $krisajenkins$remotedata$RemoteData$isLoading(query);
};
var $author$project$Translations$searchBarInputHint = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'You can search for: DNI/NIE, policy, offer, quote, email, phone or registration number';
	} else {
		return 'Puedes buscar por: DNI/NIE, póliza, oferta, presupuesto, email, teléfono o matrícula';
	}
};
var $author$project$Translations$searchBarInputPlaceholder = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Enter the customer\'s details to start searching';
	} else {
		return 'Ingresa los datos del cliente para empezar la búsqueda';
	}
};
var $author$project$Pyxis$Components$IconSet$DocumentSigned = 48;
var $author$project$Pyxis$Components$IconSet$documentSigned = 48;
var $author$project$Pyxis$Components$IconSet$Plate = 122;
var $author$project$Pyxis$Components$IconSet$plate = 122;
var $author$project$Page$Search$SearchQueryParam$searchInputIcon = function (searchInput) {
	var _v0 = $author$project$Page$Search$SearchQueryParam$fromStringInputToSearchInput(searchInput);
	switch (_v0.$) {
		case 0:
			return $author$project$Pyxis$Components$IconSet$user;
		case 1:
			return $author$project$Pyxis$Components$IconSet$documentSigned;
		case 2:
			return $author$project$Pyxis$Components$IconSet$mail;
		case 3:
			return $author$project$Pyxis$Components$IconSet$magnifyingGlass;
		case 4:
			return $author$project$Pyxis$Components$IconSet$plate;
		default:
			return $author$project$Pyxis$Components$IconSet$phone;
	}
};
var $author$project$Pyxis$Components$Field$Input$sizeM = 1;
var $author$project$Pyxis$Components$Grid$Col$span1 = function (config) {
	return _Utils_update(
		config,
		{c0: $author$project$Pyxis$Components$Grid$SpanSize$span1});
};
var $author$project$Pyxis$Components$Field$Input$Text = 3;
var $author$project$Pyxis$Components$Field$Input$text = $author$project$Pyxis$Components$Field$Input$config(3);
var $author$project$Pyxis$Components$Field$Input$withClassList = F2(
	function (classes, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classes});
	});
var $author$project$Pyxis$Components$Form$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Pyxis$Components$Field$Hint$config = function (message) {
	return {iO: $elm$core$Maybe$Nothing, lP: message};
};
var $author$project$Pyxis$Components$Field$Input$withHint = F2(
	function (hintMessage, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				f2: $elm$core$Maybe$Just(
					A2(
						$author$project$Pyxis$Components$Field$Hint$withFieldId,
						configuration.iO,
						$author$project$Pyxis$Components$Field$Hint$config(hintMessage)))
			});
	});
var $author$project$Pyxis$Components$Field$Input$IconAddon = function (a) {
	return {$: 0, a: a};
};
var $author$project$Pyxis$Components$Field$Input$Prepend = 0;
var $author$project$Pyxis$Components$Field$Input$withIconPrepend = F2(
	function (icon, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				ko: $elm$core$Maybe$Just(
					{
						S: 0,
						eT: $author$project$Pyxis$Components$Field$Input$IconAddon(icon)
					})
			});
	});
var $author$project$Pyxis$Components$Field$Input$withId = F2(
	function (id, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{iO: id});
	});
var $author$project$Pyxis$Components$Field$Input$withPlaceholder = F2(
	function (placeholder, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				g0: $elm$core$Maybe$Just(placeholder)
			});
	});
var $author$project$Pyxis$Components$Field$Input$withSize = F2(
	function (size, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{ax: size});
	});
var $author$project$Page$Search$SearchBar$render = F2(
	function (i18n, model) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-bar')
				]),
			_List_fromArray(
				[
					$author$project$Pyxis$Components$Form$render(
					A2(
						$author$project$Pyxis$Components$Form$withOnSubmit,
						$author$project$Page$Search$Msg$Submitted,
						A2(
							$author$project$Pyxis$Components$Form$withFieldsets,
							_List_fromArray(
								[
									A2(
									$author$project$Pyxis$Components$Form$Fieldset$withContent,
									_List_fromArray(
										[
											A2(
											$author$project$Pyxis$Components$Grid$row,
											_List_Nil,
											_List_fromArray(
												[
													A2(
													$author$project$Pyxis$Components$Grid$col,
													_List_fromArray(
														[$author$project$Pyxis$Components$Grid$Col$span5]),
													_List_fromArray(
														[
															A3(
															$author$project$Pyxis$Components$Field$Input$render,
															$author$project$Page$Search$Msg$ChangedPersonalIdNumber,
															model.c_,
															A2(
																$author$project$Pyxis$Components$Field$Input$withHint,
																$author$project$Translations$searchBarInputHint(i18n),
																A2(
																	$author$project$Pyxis$Components$Field$Input$withSize,
																	$author$project$Pyxis$Components$Field$Input$sizeM,
																	A3(
																		$author$project$Pyxis$Components$Field$Input$withValidationOnSubmit,
																		A2(
																			$elm$core$Basics$composeR,
																			$elm$core$String$trim,
																			$author$project$Page$Search$SearchBar$inputFieldValidations(i18n)),
																		model.d0,
																		A2(
																			$author$project$Pyxis$Components$Field$Input$withPlaceholder,
																			$author$project$Translations$searchBarInputPlaceholder(i18n),
																			A2(
																				$author$project$Pyxis$Components$Field$Input$withClassList,
																				_List_fromArray(
																					[
																						_Utils_Tuple2('search-bar__input', true)
																					]),
																				A2(
																					$author$project$Pyxis$Components$Field$Input$withIconPrepend,
																					$author$project$Page$Search$SearchQueryParam$searchInputIcon(
																						$author$project$Pyxis$Components$Field$Input$getValue(model.c_)),
																					A2(
																						$author$project$Pyxis$Components$Field$Input$withId,
																						model.jH,
																						$author$project$Pyxis$Components$Field$Input$text('')))))))))
														])),
													A2(
													$author$project$Pyxis$Components$Grid$col,
													_List_fromArray(
														[$author$project$Pyxis$Components$Grid$Col$span1]),
													_List_fromArray(
														[
															A2(
															$elm$html$Html$div,
															_List_fromArray(
																[
																	$elm$html$Html$Attributes$class('search-bar__button-container')
																]),
															_List_fromArray(
																[
																	A2(
																	$elm$html$Html$div,
																	_List_fromArray(
																		[
																			$elm$html$Html$Attributes$class('search-bar__button-spacer')
																		]),
																	_List_Nil),
																	$author$project$Pyxis$Components$Button$render(
																	A2(
																		$author$project$Pyxis$Components$Button$withLoading,
																		$author$project$Page$Search$SearchBar$isLoading(model.aW),
																		A2(
																			$author$project$Pyxis$Components$Button$withType,
																			$author$project$Pyxis$Components$Button$submit,
																			A2(
																				$author$project$Pyxis$Components$Button$withSize,
																				$author$project$Pyxis$Components$Button$sizeL,
																				A2($author$project$Pyxis$Components$Button$withIconOnly, $author$project$Pyxis$Components$IconSet$magnifyingGlass, $author$project$Pyxis$Components$Button$primary)))))
																]))
														]))
												]))
										]),
									$author$project$Pyxis$Components$Form$Fieldset$config)
								]),
							A2(
								$author$project$Pyxis$Components$Form$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('es-fe-bo--full-width', true)
									]),
								$author$project$Pyxis$Components$Form$config))))
				]));
	});
var $author$project$Page$Search$View$renderEmptyTitle = function (title) {
	return $author$project$Pyxis$Components$Title$render(
		A2(
			$author$project$Pyxis$Components$Title$withFontWeight,
			$author$project$Pyxis$Tokens$FontWeight$tokenBold,
			A2(
				$author$project$Pyxis$Components$Title$withSize,
				$author$project$Pyxis$Tokens$TitleSize$tokenM,
				$author$project$Pyxis$Components$Title$config(
					A2($elm$html$Html$div, _List_Nil, title)))));
};
var $author$project$Translations$searchResultsBeforeSearchText = F2(
	function (divAttrs, _v0) {
		var lang = _v0;
		if (!lang) {
			return _List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'div',
					_Utils_ap(_List_Nil, divAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text('Hey, it\'s a bit empty here.')
						])),
					A3(
					$elm$html$Html$node,
					'div',
					_Utils_ap(_List_Nil, divAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text('Ready to change that?')
						]))
				]);
		} else {
			return _List_fromArray(
				[
					A3(
					$elm$html$Html$node,
					'div',
					_Utils_ap(_List_Nil, divAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text('Oye, esto está algo vacío.')
						])),
					A3(
					$elm$html$Html$node,
					'div',
					_Utils_ap(_List_Nil, divAttrs),
					_List_fromArray(
						[
							$elm$html$Html$text('¿Te apetece transformarlo?')
						]))
				]);
		}
	});
var $author$project$Page$Search$View$renderBeforeSearch = function (i18n) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-result__no-search-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('/assets/bag.svg'),
						$elm$html$Html$Attributes$class('search-result-status-image')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('search-result__no-search-text')
					]),
				_List_fromArray(
					[
						$author$project$Page$Search$View$renderEmptyTitle(
						A2($author$project$Translations$searchResultsBeforeSearchText, _List_Nil, i18n))
					]))
			]));
};
var $author$project$Page$Search$Msg$ResetSearch = {$: 11};
var $author$project$Translations$searchResultsClearSearchButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Clear search';
	} else {
		return 'Buscar nuevamente';
	}
};
var $author$project$Translations$searchResultsNoResultsText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'We couldn’t find any result. Try again.';
	} else {
		return 'No hemos encontrado ningun resultado con que coincida con esos datos. Asegúrate de que son correctos y busca de nuevo.';
	}
};
var $author$project$Translations$searchResultsNoResultsTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Sorry, no results!';
	} else {
		return 'Lo siento, no hay resultados a la vista.';
	}
};
var $author$project$Pyxis$Components$Title$withClassList = F2(
	function (classList, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classList});
	});
var $author$project$Page$Search$View$renderNoResults = function (i18n) {
	return A2(
		$elm$html$Html$section,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-result__no-results-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$img,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$src('/assets/box.svg'),
						$elm$html$Html$Attributes$class('search-result-status-image')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('search-result__no-results-info')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Title$render(
						A2(
							$author$project$Pyxis$Components$Title$withFontWeight,
							$author$project$Pyxis$Tokens$FontWeight$tokenBold,
							A2(
								$author$project$Pyxis$Components$Title$withSize,
								$author$project$Pyxis$Tokens$TitleSize$tokenM,
								A2(
									$author$project$Pyxis$Components$Title$withClassList,
									_List_fromArray(
										[
											_Utils_Tuple2('no-results-info-typo', true)
										]),
									$author$project$Pyxis$Components$Title$config(
										$elm$html$Html$text(
											$author$project$Translations$searchResultsNoResultsTitle(i18n))))))),
						$author$project$Pyxis$Components$Text$render(
						A2(
							$author$project$Pyxis$Components$Text$withFontWeight,
							$author$project$Pyxis$Tokens$FontWeight$tokenBook,
							A2(
								$author$project$Pyxis$Components$Text$withSize,
								$author$project$Pyxis$Tokens$TextSize$tokenL,
								A2(
									$author$project$Pyxis$Components$Text$withClassList,
									_List_fromArray(
										[
											_Utils_Tuple2('no-results-info-typo', true)
										]),
									A2(
										$author$project$Pyxis$Components$Text$withColor,
										$author$project$Pyxis$Tokens$Color$tokenNeutral25,
										$author$project$Pyxis$Components$Text$config(
											$elm$html$Html$text(
												$author$project$Translations$searchResultsNoResultsText(i18n)))))))),
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							$author$project$Page$Search$Msg$ResetSearch,
							A2(
								$author$project$Pyxis$Components$Button$withText,
								$author$project$Translations$searchResultsClearSearchButtonLabel(i18n),
								A2($author$project$Pyxis$Components$Button$withSize, $author$project$Pyxis$Components$Button$sizeL, $author$project$Pyxis$Components$Button$ghost))))
					]))
			]));
};
var $elm_community$list_extra$List$Extra$filterNot = F2(
	function (pred, list) {
		return A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, pred),
			list);
	});
var $author$project$Common$Policy$hasApplicationId = F2(
	function (applicationId, _v0) {
		var policy = _v0;
		return _Utils_eq(policy.e1, applicationId);
	});
var $author$project$Page$Search$View$isOfferApplicationCompletedByPolicies = F2(
	function (policies, applicationId) {
		return A2(
			$elm$core$List$any,
			$author$project$Common$Policy$hasApplicationId(applicationId),
			policies);
	});
var $author$project$Common$Offer$pickApplicationId = function (_v0) {
	var offer = _v0;
	return offer.e1;
};
var $author$project$Page$Search$View$removeOffersWithApplicationsCompletedByPolicies = function (policies) {
	return $elm_community$list_extra$List$Extra$filterNot(
		A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Offer$pickApplicationId,
			$author$project$Page$Search$View$isOfferApplicationCompletedByPolicies(policies)));
};
var $author$project$Page$Search$Msg$RedirectToOfferDetails = function (a) {
	return {$: 7, a: a};
};
var $author$project$Page$Search$OfferResults$coverageName = function (i18n) {
	return A2(
		$elm$core$Basics$composeR,
		$author$project$Common$Offer$pickFirstProduct,
		A2(
			$elm$core$Basics$composeR,
			$elm$core$Maybe$map(
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.kA;
					},
					$author$project$Common$Product$bundleSlugToString(i18n))),
			$elm$core$Maybe$withDefault('--')));
};
var $author$project$Page$Search$OfferResults$offerSourceLabel = function (source) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('text-l-bold', true),
						_Utils_Tuple2(
						'c-brand-base',
						$author$project$Common$Source$isPrimaSource(source))
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Common$Source$toString(source))
			]));
};
var $author$project$Common$Person$pickBirthDate = function (person) {
	return $author$project$Common$Person$pickPersonData(person).ag;
};
var $author$project$Common$Offer$pickMainDriver = function (_v0) {
	var offer = _v0;
	return offer.d5;
};
var $author$project$Common$Person$pickRecentInsuranceCompanyName = function (person) {
	switch (person.$) {
		case 1:
			return $elm$core$Maybe$Nothing;
		case 0:
			var recentInsuranceCompanyName = person.c;
			return $elm$core$Maybe$Just(recentInsuranceCompanyName);
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $author$project$Common$Offer$pickRecentInsuranceCompanyName = function (_v0) {
	var offer = _v0;
	return offer.d$ ? '' : A2(
		$elm$core$Maybe$withDefault,
		'',
		$author$project$Common$Person$pickRecentInsuranceCompanyName(offer.d5));
};
var $author$project$Page$Search$OfferResults$renderBottomRowItem = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__bottomrow__cell')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title-xs-book')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__offer__bottomrow__cell__value')
						]),
					_List_fromArray(
						[value]))
				]));
	});
var $author$project$Page$Search$OfferResults$renderCompleteButton = F2(
	function (i18n, msg) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withTestId,
				'complete-btn',
				A2(
					$author$project$Pyxis$Components$Button$withId,
					'complete-btn',
					A2(
						$author$project$Pyxis$Components$Button$withOnClick,
						msg,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('search-result__offer__bottomrow__buttons__cta', true)
									]),
								A2(
									$author$project$Pyxis$Components$Button$withText,
									$author$project$Translations$detailsCompleteOfferLabel(i18n),
									$author$project$Pyxis$Components$Button$secondary)))))));
	});
var $author$project$Translations$searchResultsPurchaseButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Purchase offer';
	} else {
		return 'Comprar Oferta';
	}
};
var $author$project$Page$Search$OfferResults$renderPurchaseButton = F2(
	function (i18n, ctaMsg) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withOnClick,
				ctaMsg,
				A2(
					$author$project$Pyxis$Components$Button$withType,
					$author$project$Pyxis$Components$Button$button,
					A2(
						$author$project$Pyxis$Components$Button$withClassList,
						_List_fromArray(
							[
								_Utils_Tuple2('search-result__offer__bottomrow__buttons__cta', true)
							]),
						A2(
							$author$project$Pyxis$Components$Button$withText,
							$author$project$Translations$searchResultsPurchaseButtonLabel(i18n),
							$author$project$Pyxis$Components$Button$primary)))));
	});
var $author$project$Pyxis$Components$Button$Min = 0;
var $author$project$Pyxis$Components$Button$min = function (_v0) {
	var configuration = _v0;
	return _Utils_update(
		configuration,
		{di: 0});
};
var $author$project$Translations$searchResultsViewOfferButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'View offer';
	} else {
		return 'Ver oferta';
	}
};
var $author$project$Page$Search$OfferResults$renderViewButton = F2(
	function (i18n, actions) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withWidth,
				$author$project$Pyxis$Components$Button$min,
				A2(
					$author$project$Pyxis$Components$Button$withOnClick,
					actions.ek,
					A2(
						$author$project$Pyxis$Components$Button$withSize,
						$author$project$Pyxis$Components$Button$sizeL,
						A2(
							$author$project$Pyxis$Components$Button$withType,
							$author$project$Pyxis$Components$Button$button,
							A2(
								$author$project$Pyxis$Components$Button$withClassList,
								_List_fromArray(
									[
										_Utils_Tuple2('search-result__offer__bottomrow__buttons__cta', true)
									]),
								A2(
									$author$project$Pyxis$Components$Button$withText,
									$author$project$Translations$searchResultsViewOfferButtonLabel(i18n),
									$author$project$Pyxis$Components$Button$tertiary)))))));
	});
var $author$project$Page$Search$OfferResults$renderButtons = F3(
	function (i18n, actions, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__bottomrow__cell search-result__offer__bottomrow__buttons')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Search$OfferResults$renderViewButton, i18n, actions),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					$author$project$Common$Offer$canBePurchased(offer),
					A2($author$project$Page$Search$OfferResults$renderPurchaseButton, i18n, actions.gT)),
					A2(
					$elm_community$html_extra$Html$Extra$viewIf,
					$author$project$Common$Offer$mayBeCompleted(offer),
					A2($author$project$Page$Search$OfferResults$renderCompleteButton, i18n, actions.gI))
				]));
	});
var $author$project$Translations$searchResultsCoverTitle = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Cover selected';
	} else {
		return 'Cobertura seleccionada';
	}
};
var $author$project$Translations$searchResultsCurrentInsuranceLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Current insurance';
	} else {
		return 'Aseguradora actual';
	}
};
var $author$project$Translations$searchResultsDateOfBirthLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Driver date of birth';
	} else {
		return 'Fecha de nacimiento';
	}
};
var $author$project$Translations$searchResultsOfferSourceLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Offer by: ';
	} else {
		return 'Oferta de: ';
	}
};
var $author$project$Page$Search$OfferResults$bottomRow = F3(
	function (i18n, actions, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex search-result__bottomrow')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Page$Search$OfferResults$renderBottomRowItem,
					$author$project$Translations$searchResultsCoverTitle(i18n),
					$elm$html$Html$text(
						A2($author$project$Page$Search$OfferResults$coverageName, i18n, offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderBottomRowItem,
					$author$project$Translations$searchResultsOfferSourceLabel(i18n),
					$author$project$Page$Search$OfferResults$offerSourceLabel(
						$author$project$Common$Offer$pickSource(offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderBottomRowItem,
					$author$project$Translations$searchResultsCurrentInsuranceLabel(i18n),
					$elm$html$Html$text(
						$author$project$Common$Offer$pickRecentInsuranceCompanyName(offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderBottomRowItem,
					$author$project$Translations$searchResultsDateOfBirthLabel(i18n),
					$elm$html$Html$text(
						$author$project$Common$Locale$formatDate(
							$author$project$Common$Person$pickBirthDate(
								$author$project$Common$Offer$pickMainDriver(offer))))),
					A3($author$project$Page$Search$OfferResults$renderButtons, i18n, actions, offer)
				]));
	});
var $author$project$Page$Search$OfferResults$renderCustomerFacingIdLabel = A2($elm$core$Basics$composeL, $elm$html$Html$text, $author$project$Common$Offer$pickCustomerFacingId);
var $author$project$Common$Vehicle$pickMaker = function (_v0) {
	var vehicle = _v0;
	return vehicle.d6;
};
var $author$project$Common$Vehicle$pickModel = function (_v0) {
	var vehicle = _v0;
	return vehicle.d9;
};
var $author$project$Page$Search$OfferResults$renderVehicleInfo = function (vehicle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointed-text')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Common$Vehicle$pickPlateNumber(vehicle))
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									$author$project$Common$Vehicle$pickMaker(vehicle),
									$author$project$Common$Vehicle$pickModel(vehicle)
								])))
					]))
			]));
};
var $author$project$Page$Search$OfferResults$renderInfoBlock = function (offer) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-results__offer__info-block')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Icon$sizeL,
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$car))),
				$author$project$Page$Search$OfferResults$renderVehicleInfo(
				$author$project$Common$Offer$pickVehicle(offer))
			]));
};
var $author$project$Page$Search$OfferResults$identifierRow = F2(
	function (i18n, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__identifier-row')
				]),
			_List_fromArray(
				[
					$author$project$Page$Search$OfferResults$renderCustomerFacingIdLabel(offer),
					A3($author$project$Common$Offer$renderStatusBadge, 'search-results__offer__status-badge', i18n, offer),
					A3($author$project$Common$Offer$renderRenewalBadge, 'search-results__offer__status-badge', i18n, offer),
					$author$project$Page$Search$OfferResults$renderInfoBlock(offer)
				]));
	});
var $author$project$Common$Person$pickDocumentId = function (person) {
	return $author$project$Common$Person$pickPersonData(person).ai;
};
var $author$project$Page$Search$OfferResults$renderSecondaryRowItem = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__secondary-row__cell')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title),
					$elm$html$Html$text(value)
				]));
	});
var $author$project$Translations$searchResultsCreationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Creation date: ';
	} else {
		return 'Fecha de la oferta: ';
	}
};
var $author$project$Translations$searchResultsDocumentLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'DNI/NIE: ';
	} else {
		return 'DNI/NIE: ';
	}
};
var $author$project$Translations$searchResultsProvinceLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Province: ';
	} else {
		return 'Provincia: ';
	}
};
var $author$project$Translations$searchResultsStartDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Start date: ';
	} else {
		return 'Fecha de inicio: ';
	}
};
var $author$project$Page$Search$OfferResults$secondaryRow = F2(
	function (i18n, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex search-result__secondary-row')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Page$Search$OfferResults$renderSecondaryRowItem,
					$author$project$Translations$searchResultsDocumentLabel(i18n),
					$author$project$Common$Person$pickDocumentId(
						$author$project$Common$Offer$pickPolicyHolder(offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderSecondaryRowItem,
					$author$project$Translations$searchResultsStartDateLabel(i18n),
					$author$project$Common$Locale$formatDate(
						$author$project$Common$Offer$pickStartDate(offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderSecondaryRowItem,
					$author$project$Translations$searchResultsCreationDateLabel(i18n),
					$author$project$Common$Locale$formatDate(
						$author$project$Common$Offer$pickCreationDate(offer))),
					A2(
					$author$project$Page$Search$OfferResults$renderSecondaryRowItem,
					$author$project$Translations$searchResultsProvinceLabel(i18n),
					$author$project$Common$Person$pickAddress(
						$author$project$Common$Offer$pickMainDriver(offer)).m4)
				]));
	});
var $author$project$Page$Search$OfferResults$detailsColumn = F2(
	function (i18n, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__details-column')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Search$OfferResults$identifierRow, i18n, offer),
					A2($author$project$Page$Search$OfferResults$secondaryRow, i18n, offer)
				]));
	});
var $author$project$Page$Search$OfferResults$priceColumn = F2(
	function (i18n, offer) {
		var price = $author$project$Common$Offer$totalPrice(offer);
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__price-column')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__offer__currency')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('search-result__offer__currency__integers-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									_Utils_ap(
										$author$project$Common$Price$pickCurrencySign(price),
										$author$project$Common$Price$toStringIntegers(price)))
								])),
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('search-result__offer__currency__decimals-text')
								]),
							_List_fromArray(
								[
									$elm$html$Html$text(
									_Utils_ap(
										$author$project$Common$Price$decimalSeparator(i18n),
										A2($author$project$Common$Price$toStringDecimals, i18n, price)))
								]))
						]))
				]));
	});
var $author$project$Page$Search$OfferResults$detailsAndPriceRow = F2(
	function (i18n, offer) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__offer__details-and-price-row')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Search$OfferResults$detailsColumn, i18n, offer),
					A2($author$project$Page$Search$OfferResults$priceColumn, i18n, offer)
				]));
	});
var $author$project$Common$Offer$pickStatus = function (_v0) {
	var offer = _v0;
	return offer.c1;
};
var $author$project$Common$Offer$isCustomerInformationComplete = function (_v0) {
	var offer = _v0;
	var _v1 = offer.ec;
	switch (_v1) {
		case 0:
			return false;
		case 2:
			return false;
		case 1:
			return true;
		case 3:
			return true;
		case 4:
			return true;
		case 5:
			return true;
		case 6:
			return true;
		default:
			return true;
	}
};
var $author$project$Translations$searchResultsCustomerInformationMissing = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Customer information missing';
	} else {
		return 'Información del cliente pendiente';
	}
};
var $author$project$Page$Search$OfferResults$renderBadgeForIncompleteCustomerInfo = function (i18n) {
	return $author$project$Pyxis$Components$Badge$render(
		A2(
			$author$project$Pyxis$Components$Badge$withClassList,
			_List_fromArray(
				[
					_Utils_Tuple2('search-result__offer-tag-missing-row', true)
				]),
			A2(
				$author$project$Pyxis$Components$Badge$withText,
				$author$project$Translations$searchResultsCustomerInformationMissing(i18n),
				$author$project$Pyxis$Components$Badge$error)));
};
var $author$project$Page$Search$OfferResults$renderOfferInfoTag = F2(
	function (i18n, offer) {
		return A2(
			$author$project$Pyxis$Commons$Render$renderUnless,
			$author$project$Common$Offer$isCustomerInformationComplete(offer),
			$author$project$Page$Search$OfferResults$renderBadgeForIncompleteCustomerInfo(i18n));
	});
var $author$project$Page$Search$OfferResults$labelRow = F2(
	function (i18n, offer) {
		var _v0 = $author$project$Common$Offer$pickStatus(offer);
		switch (_v0) {
			case 8:
				return A2($author$project$Page$Search$OfferResults$renderOfferInfoTag, i18n, offer);
			case 4:
				return $author$project$Pyxis$Commons$Render$empty;
			case 3:
				return A2($author$project$Page$Search$OfferResults$renderOfferInfoTag, i18n, offer);
			case 7:
				return $author$project$Pyxis$Commons$Render$empty;
			case 5:
				return $author$project$Pyxis$Commons$Render$empty;
			case 6:
				return $author$project$Pyxis$Commons$Render$empty;
			case 0:
				return A2($author$project$Page$Search$OfferResults$renderOfferInfoTag, i18n, offer);
			case 1:
				return A2($author$project$Page$Search$OfferResults$renderOfferInfoTag, i18n, offer);
			default:
				return A2($author$project$Page$Search$OfferResults$renderOfferInfoTag, i18n, offer);
		}
	});
var $author$project$Page$Search$OfferResults$viewResult = F3(
	function (i18n, actions, offer) {
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-results__offer-result radius-xl padding-h-m padding-v-xs')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Search$OfferResults$labelRow, i18n, offer),
					A2($author$project$Page$Search$OfferResults$detailsAndPriceRow, i18n, offer),
					A3($author$project$Page$Search$OfferResults$bottomRow, i18n, actions, offer)
				]));
	});
var $author$project$Page$Search$OfferResults$toOfferResults = F2(
	function (i18n, offer) {
		return A3(
			$author$project$Page$Search$OfferResults$viewResult,
			i18n,
			{
				gI: A2($author$project$Page$Search$Msg$ShowLegalInformationModal, true, offer),
				gT: A2($author$project$Page$Search$Msg$ShowRecordingDisclamerModal, true, offer),
				ek: $author$project$Page$Search$Msg$RedirectToOfferDetails(
					$author$project$Common$Offer$pickCustomerFacingId(offer))
			},
			offer);
	});
var $author$project$Page$Search$OfferResults$view = function (i18n) {
	return $elm$core$List$map(
		$author$project$Page$Search$OfferResults$toOfferResults(i18n));
};
var $author$project$Page$Search$Msg$OnViewPolicyClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$Page$Search$PolicyResults$onClickViewPolicy = function (policy) {
	return $author$project$Page$Search$Msg$OnViewPolicyClicked(
		$author$project$Common$Policy$pickId(policy));
};
var $author$project$Page$Search$PolicyResults$policySourceLabel = function (source) {
	return A2(
		$elm$html$Html$span,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2('text-l-bold', true),
						_Utils_Tuple2(
						'c-brand-base',
						$author$project$Common$Source$isPrimaSource(source))
					]))
			]),
		_List_fromArray(
			[
				$elm$html$Html$text(
				$author$project$Common$Source$toString(source))
			]));
};
var $author$project$Translations$searchResultsEndDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'End date: ';
	} else {
		return 'Fecha de finalización: ';
	}
};
var $author$project$Translations$searchResultsPolicySourceLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy by: ';
	} else {
		return 'Póliza de: ';
	}
};
var $author$project$Translations$searchResultsPolicyholderDateOfBirthLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Customer date of birth: ';
	} else {
		return 'Fecha de nacimiento del cliente: ';
	}
};
var $author$project$Translations$searchResultsPolicyholderName = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy holder name: ';
	} else {
		return 'Nombre del tomador: ';
	}
};
var $author$project$Page$Search$PolicyResults$viewBottomRowItem = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__bottomrow__cell')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('title-xs-book')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__bottomrow__cell__value')
						]),
					_List_fromArray(
						[value]))
				]));
	});
var $author$project$Page$Search$PolicyResults$renderVehicleInfo = function (vehicle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointed-text')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Common$Vehicle$pickPlateNumber(vehicle))
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									$author$project$Common$Vehicle$pickMaker(vehicle),
									$author$project$Common$Vehicle$pickModel(vehicle)
								])))
					]))
			]));
};
var $author$project$Page$Search$PolicyResults$viewInfoBlock = function (policy) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-results__policy__info-block')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Icon$render(
				A2(
					$author$project$Pyxis$Components$Icon$withSize,
					$author$project$Pyxis$Components$Icon$sizeL,
					$author$project$Pyxis$Components$Icon$config($author$project$Pyxis$Components$IconSet$car))),
				$author$project$Page$Search$PolicyResults$renderVehicleInfo(
				$author$project$Common$Policy$pickVehicle(policy))
			]));
};
var $author$project$Translations$searchResultsViewPolicyButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'View policy';
	} else {
		return 'Ver póliza';
	}
};
var $author$project$Page$Search$PolicyResults$viewPolicyButton = F2(
	function (i18n, action) {
		return $author$project$Pyxis$Components$Button$render(
			A2(
				$author$project$Pyxis$Components$Button$withWidth,
				$author$project$Pyxis$Components$Button$min,
				A2(
					$author$project$Pyxis$Components$Button$withTestId,
					'view-policy',
					A2(
						$author$project$Pyxis$Components$Button$withId,
						'view-policy',
						A2(
							$author$project$Pyxis$Components$Button$withOnClick,
							action.el,
							A2(
								$author$project$Pyxis$Components$Button$withType,
								$author$project$Pyxis$Components$Button$button,
								A2(
									$author$project$Pyxis$Components$Button$withText,
									$author$project$Translations$searchResultsViewPolicyButtonLabel(i18n),
									$author$project$Pyxis$Components$Button$tertiary)))))));
	});
var $author$project$Translations$searchResultsPolicyBadgeText = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Policy';
	} else {
		return 'Póliza';
	}
};
var $author$project$Page$Search$PolicyResults$viewPolicyTag = function (i18n) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('display-flex flex-direction-row-reverse')
			]),
		_List_fromArray(
			[
				$author$project$Pyxis$Components$Badge$render(
				A2(
					$author$project$Pyxis$Components$Badge$withClassList,
					_List_fromArray(
						[
							_Utils_Tuple2('search-result__tag-policy-row', true)
						]),
					A2(
						$author$project$Pyxis$Components$Badge$withText,
						$author$project$Translations$searchResultsPolicyBadgeText(i18n),
						$author$project$Pyxis$Components$Badge$brandGradient)))
			]));
};
var $author$project$Page$Search$PolicyResults$viewSecondaryRowItem = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__secondary-row__cell')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(title),
					$elm$html$Html$text(value)
				]));
	});
var $author$project$Page$Search$PolicyResults$viewResult = F3(
	function (i18n, action, policy) {
		var policyHolder = $author$project$Common$Person$pickPersonData(
			$author$project$Common$Policy$pickPolicyHolder(policy));
		var secondaryRow = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__policy__secondary-row')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Page$Search$PolicyResults$viewSecondaryRowItem,
					$author$project$Translations$searchResultsDocumentLabel(i18n),
					policyHolder.ai),
					A2(
					$author$project$Page$Search$PolicyResults$viewSecondaryRowItem,
					$author$project$Translations$searchResultsPolicyholderDateOfBirthLabel(i18n),
					$author$project$Common$Locale$formatDate(policyHolder.ag)),
					A2(
					$author$project$Page$Search$PolicyResults$viewSecondaryRowItem,
					$author$project$Translations$searchResultsPolicyholderName(i18n),
					policyHolder.aq + (' ' + policyHolder.at))
				]));
		var labelRow = $author$project$Page$Search$PolicyResults$viewPolicyTag(i18n);
		var identifierRow = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__policy__identifier-row')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text(
					$author$project$Common$Policy$pickCustomerFacingId(policy)),
					A3($author$project$Common$Policy$renderStatusBadge, 'search-results__policy__status-badge', i18n, policy),
					A3($author$project$Common$Policy$renderRenewalBadge, 'search-results__policy__status-badge', i18n, policy),
					$author$project$Page$Search$PolicyResults$viewInfoBlock(policy)
				]));
		var _v0 = A2(
			$elm$core$Maybe$withDefault,
			_Utils_Tuple2('--', '--'),
			A2(
				$elm$core$Maybe$map,
				function (product) {
					return _Utils_Tuple2(
						A2($author$project$Common$Product$bundleSlugToString, i18n, product.kA),
						A2($author$project$Common$Price$toStringSignFirst, i18n, product.kr.la));
				},
				$author$project$Common$Policy$pickFirstProduct(policy)));
		var coverageName = _v0.a;
		var coveragePrice = _v0.b;
		var bottomRow = A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('display-flex')
				]),
			_List_fromArray(
				[
					A2(
					$author$project$Page$Search$PolicyResults$viewBottomRowItem,
					coverageName,
					$elm$html$Html$text(coveragePrice)),
					A2(
					$author$project$Page$Search$PolicyResults$viewBottomRowItem,
					$author$project$Translations$searchResultsPolicySourceLabel(i18n),
					$author$project$Page$Search$PolicyResults$policySourceLabel(
						$author$project$Common$Policy$pickSource(policy))),
					A2(
					$author$project$Page$Search$PolicyResults$viewBottomRowItem,
					$author$project$Translations$searchResultsStartDateLabel(i18n),
					$elm$html$Html$text(
						$author$project$Common$Locale$formatDate(
							$author$project$Common$Policy$pickStartDate(policy)))),
					A2(
					$author$project$Page$Search$PolicyResults$viewBottomRowItem,
					$author$project$Translations$searchResultsEndDateLabel(i18n),
					$elm$html$Html$text(
						$author$project$Common$Locale$formatDate(
							$author$project$Common$Policy$pickEndDate(policy)))),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__bottomrow__cell display-flex')
						]),
					_List_fromArray(
						[
							A2($author$project$Page$Search$PolicyResults$viewPolicyButton, i18n, action)
						]))
				]));
		return A2(
			$elm$html$Html$li,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-results__policy-result radius-xl padding-h-xl padding-v-l')
				]),
			_List_fromArray(
				[labelRow, identifierRow, secondaryRow, bottomRow]));
	});
var $author$project$Page$Search$PolicyResults$toPolicyResult = F2(
	function (i18n, policy) {
		return A3(
			$author$project$Page$Search$PolicyResults$viewResult,
			i18n,
			{
				el: $author$project$Page$Search$PolicyResults$onClickViewPolicy(policy)
			},
			policy);
	});
var $author$project$Page$Search$PolicyResults$view = F2(
	function (i18n, results) {
		return A2(
			$elm$core$List$map,
			$author$project$Page$Search$PolicyResults$toPolicyResult(i18n),
			results);
	});
var $author$project$Page$Search$View$renderOffersAndPoliciesResults = F3(
	function (i18n, policies, offers) {
		return A2(
			$elm$html$Html$ul,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-results display-grid')
				]),
			A2(
				$elm$core$List$append,
				A2($author$project$Page$Search$PolicyResults$view, i18n, policies),
				A2(
					$author$project$Page$Search$OfferResults$view,
					i18n,
					A2($author$project$Page$Search$View$removeOffersWithApplicationsCompletedByPolicies, policies, offers))));
	});
var $author$project$Translations$searchResultsCreateOfferButtonLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Create offer';
	} else {
		return 'Crear oferta';
	}
};
var $author$project$Page$Search$QuoteResults$createOfferButton = F2(
	function (i18n, quote) {
		var _v0 = quote.c1;
		if (!_v0) {
			return A2(
				$elm$html$Html$a,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$href(quote.k8),
						$elm$html$Html$Attributes$target('_blank')
					]),
				_List_fromArray(
					[
						$author$project$Pyxis$Components$Button$render(
						A2(
							$author$project$Pyxis$Components$Button$withIconAppend,
							$author$project$Pyxis$Components$IconSet$externalLink,
							A2(
								$author$project$Pyxis$Components$Button$withSize,
								$author$project$Pyxis$Components$Button$sizeM,
								A2(
									$author$project$Pyxis$Components$Button$withText,
									$author$project$Translations$searchResultsCreateOfferButtonLabel(i18n),
									$author$project$Pyxis$Components$Button$tertiary))))
					]));
		} else {
			return $author$project$Common$Render$empty;
		}
	});
var $author$project$Page$Search$QuoteResults$rowItem = F2(
	function (title, value) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-result__quote-result__content__cell')
				]),
			_List_fromArray(
				[
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__quote-result__content__cell__title')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(title)
						])),
					A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('search-result__quote-result__content__cell__value')
						]),
					_List_fromArray(
						[
							$elm$html$Html$text(value)
						]))
				]));
	});
var $author$project$Translations$searchResultsQuoteCreationDateLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Creation date';
	} else {
		return 'Fecha del presupuesto';
	}
};
var $author$project$Page$Search$QuoteResults$creationDateRow = function (i18n) {
	return A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Page$Search$QuoteResults$rowItem(
				$author$project$Translations$searchResultsQuoteCreationDateLabel(i18n)),
			$author$project$Common$Locale$formatDate),
		function ($) {
			return $.fs;
		});
};
var $author$project$Translations$searchResultsQuoteEndLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'End date';
	} else {
		return 'Fecha de finalización';
	}
};
var $author$project$Page$Search$QuoteResults$endDateRow = function (i18n) {
	return A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Page$Search$QuoteResults$rowItem(
				$author$project$Translations$searchResultsQuoteEndLabel(i18n)),
			$author$project$Common$Locale$formatDate),
		function ($) {
			return $.dI;
		});
};
var $author$project$Translations$searchResultsQuoteSourceLabel = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Source by';
	} else {
		return 'Presupuesto de';
	}
};
var $author$project$Page$Search$QuoteResults$sourceRow = function (i18n) {
	return A2(
		$elm$core$Basics$composeL,
		A2(
			$elm$core$Basics$composeL,
			$author$project$Page$Search$QuoteResults$rowItem(
				$author$project$Translations$searchResultsQuoteSourceLabel(i18n)),
			$author$project$Common$Source$toString),
		function ($) {
			return $.hm;
		});
};
var $author$project$Page$Search$QuoteResults$quoteDetailsContent = F2(
	function (i18n, quote) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-results__quote-result__content')
				]),
			_List_fromArray(
				[
					A2($author$project$Page$Search$QuoteResults$sourceRow, i18n, quote),
					A2($author$project$Page$Search$QuoteResults$creationDateRow, i18n, quote),
					A2($author$project$Page$Search$QuoteResults$endDateRow, i18n, quote)
				]));
	});
var $author$project$Pyxis$Tokens$TitleSize$TitleSizeS = 5;
var $author$project$Pyxis$Tokens$TitleSize$tokenS = 5;
var $author$project$Page$Search$QuoteResults$quoteIdentifier = function (quote) {
	return $author$project$Pyxis$Components$Title$render(
		A2(
			$author$project$Pyxis$Components$Title$withSize,
			$author$project$Pyxis$Tokens$TitleSize$tokenS,
			A2(
				$author$project$Pyxis$Components$Title$withFontWeight,
				$author$project$Pyxis$Tokens$FontWeight$tokenBold,
				$author$project$Pyxis$Components$Title$config(
					A2(
						$elm$html$Html$span,
						_List_Nil,
						_List_fromArray(
							[
								$elm$html$Html$text(quote.kN)
							]))))));
};
var $author$project$Translations$quoteStatusActive = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Active';
	} else {
		return 'Disponible';
	}
};
var $author$project$Translations$quoteStatusBlocked = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Blocked';
	} else {
		return 'No contratable';
	}
};
var $author$project$Translations$quoteStatusExpired = function (_v0) {
	var lang = _v0;
	if (!lang) {
		return 'Expired';
	} else {
		return 'Caducado';
	}
};
var $author$project$Common$Quote$statusBadge = F2(
	function (i18n, quote) {
		var _v0 = quote.c1;
		switch (_v0) {
			case 0:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$quoteStatusActive(i18n),
					$author$project$Pyxis$Components$Badge$success);
			case 1:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$quoteStatusExpired(i18n),
					$author$project$Pyxis$Components$Badge$neutral);
			default:
				return A2(
					$author$project$Pyxis$Components$Badge$withText,
					$author$project$Translations$quoteStatusBlocked(i18n),
					$author$project$Pyxis$Components$Badge$error);
		}
	});
var $author$project$Common$Quote$renderStatusBadge = F2(
	function (customClass, i18n) {
		return A2(
			$elm$core$Basics$composeR,
			$author$project$Common$Quote$statusBadge(i18n),
			A2(
				$elm$core$Basics$composeR,
				$author$project$Pyxis$Components$Badge$withClassList(
					_List_fromArray(
						[
							_Utils_Tuple2(customClass, true)
						])),
				$author$project$Pyxis$Components$Badge$render));
	});
var $author$project$Page$Search$QuoteResults$generalInfo = F2(
	function (i18n, quote) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('search-results__quote-result__header__general-info')
				]),
			_List_fromArray(
				[
					A3($author$project$Common$Quote$renderStatusBadge, '', i18n, quote),
					$author$project$Page$Search$QuoteResults$quoteIdentifier(quote)
				]));
	});
var $author$project$Page$Search$QuoteResults$vehicleInfo = function (vehicle) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('pointed-text')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						$author$project$Common$Vehicle$pickPlateNumber(vehicle))
					])),
				A2(
				$elm$html$Html$span,
				_List_Nil,
				_List_fromArray(
					[
						$elm$html$Html$text(
						A2(
							$elm$core$String$join,
							' ',
							_List_fromArray(
								[
									$author$project$Common$Vehicle$pickMaker(vehicle),
									$author$project$Common$Vehicle$pickModel(vehicle)
								])))
					]))
			]));
};
var $author$project$Page$Search$QuoteResults$infoBlock = function (quote) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('search-results__quote-result__content__vehicle-info')
			]),
		_List_fromArray(
			[
				$author$project$Page$Search$QuoteResults$vehicleInfo(quote.hL)
			]));
};
var $author$project$Page$Search$QuoteResults$quoteDetailsHeader = F2(
	function (i18n, quote) {
		return A2(
			$author$project$Pyxis$Components$Card$withHeaderTitle,
			A2(
				$elm$html$Html$div,
				_List_Nil,
				_List_fromArray(
					[
						A2($author$project$Page$Search$QuoteResults$generalInfo, i18n, quote),
						$author$project$Page$Search$QuoteResults$infoBlock(quote)
					])),
			$author$project$Pyxis$Components$Card$header);
	});
var $author$project$Pyxis$Components$Card$withClassList = F2(
	function (classes, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{fj: classes});
	});
var $author$project$Pyxis$Components$Card$withFooter = F2(
	function (footer, _v0) {
		var configuration = _v0;
		return _Utils_update(
			configuration,
			{
				fO: $elm$core$Maybe$Just(footer)
			});
	});
var $author$project$Page$Search$QuoteResults$viewQuoteDetails = F2(
	function (i18n, quote) {
		return $author$project$Pyxis$Components$Card$render(
			A2(
				$author$project$Pyxis$Components$Card$withFooter,
				A2($author$project$Page$Search$QuoteResults$createOfferButton, i18n, quote),
				A2(
					$author$project$Pyxis$Components$Card$withContent,
					A2($author$project$Page$Search$QuoteResults$quoteDetailsContent, i18n, quote),
					A2(
						$author$project$Pyxis$Components$Card$withHeader,
						A2($author$project$Page$Search$QuoteResults$quoteDetailsHeader, i18n, quote),
						A2(
							$author$project$Pyxis$Components$Card$withClassList,
							_List_fromArray(
								[
									_Utils_Tuple2('search-results__quote-result', true)
								]),
							$author$project$Pyxis$Components$Card$config)))));
	});
var $author$project$Page$Search$QuoteResults$view = function (i18n) {
	return $elm$core$List$map(
		$author$project$Page$Search$QuoteResults$viewQuoteDetails(i18n));
};
var $author$project$Page$Search$View$renderResults = F2(
	function (i18n, model) {
		var _v0 = model.aW;
		switch (_v0.$) {
			case 3:
				var policies = _v0.a.mZ;
				var offers = _v0.a.l8;
				var quotes = _v0.a.m6;
				return ($elm$core$List$isEmpty(offers) && ($elm$core$List$isEmpty(policies) && $elm$core$List$isEmpty(quotes))) ? $author$project$Page$Search$View$renderNoResults(i18n) : A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('container-responsive search-results-container')
						]),
					A2(
						$elm$core$List$append,
						A2($author$project$Page$Search$QuoteResults$view, i18n, quotes),
						_List_fromArray(
							[
								A3($author$project$Page$Search$View$renderOffersAndPoliciesResults, i18n, policies, offers)
							])));
			case 1:
				return $author$project$Common$Render$empty;
			case 0:
				return $author$project$Page$Search$View$renderBeforeSearch(i18n);
			default:
				return $author$project$Common$Render$empty;
		}
	});
var $author$project$Page$Search$View$view = F3(
	function (i18n, env, model) {
		return _List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('container-responsive padding-v-3xl search-page')
					]),
				_List_fromArray(
					[
						A2($author$project$Page$Search$SearchBar$render, i18n, model),
						A2(
						$author$project$Common$Render$maybe,
						function (selectedOffer) {
							return A5(
								$author$project$Common$ComponentUI$Modal$RecordingDisclaimer$render,
								selectedOffer,
								i18n,
								A2($author$project$Page$Search$Msg$ShowRecordingDisclamerModal, false, selectedOffer),
								$author$project$Page$Search$Msg$RedirectToPayment,
								model.eF);
						},
						model.eB),
						A2(
						$author$project$Common$Render$maybe,
						function (selectedOffer) {
							return A5(
								$author$project$Common$ComponentUI$Modal$CompleteOfferLegalInformation$render,
								selectedOffer,
								i18n,
								$author$project$ExternalUrl$FunnelOffer(env),
								A2($author$project$Page$Search$Msg$ShowLegalInformationModal, false, selectedOffer),
								model.dA);
						},
						model.eB),
						A2($author$project$Page$Search$View$renderResults, i18n, model),
						A3(
						$author$project$Pyxis$Components$Toasts$render,
						$author$project$Page$Search$Msg$ToastMsg,
						model.O,
						A2(
							$author$project$Pyxis$Components$Toasts$withPlacement,
							$author$project$Pyxis$Components$Toasts$topRightPlacement,
							$author$project$Pyxis$Components$Toasts$config('search-page-toaster')))
					]))
			]);
	});
var $author$project$Page$Search$view = F3(
	function (i18n, env, model) {
		return {
			fd: A3($author$project$Page$Search$View$view, i18n, env, model),
			hE: $author$project$Translations$routeSearch(i18n)
		};
	});
var $author$project$Main$view = function (model) {
	var i18n = model.ln;
	var user = model.eV;
	var env = model.kY;
	var _v0 = model.mX;
	switch (_v0.$) {
		case 0:
			var notFoundModel = _v0.a;
			return A2(
				$author$project$Main$mapDocument,
				$author$project$Msg$NotFound,
				A2($author$project$Page$NotFound$view, i18n, notFoundModel));
		case 1:
			var notAuthorizedModel = _v0.a;
			return A2(
				$author$project$Main$mapDocument,
				$author$project$Msg$NotAuthorized,
				A2($author$project$Page$NotAuthorized$view, i18n, notAuthorizedModel));
		case 2:
			var loginModel = _v0.a;
			return A2(
				$author$project$Main$mapDocument,
				$author$project$Msg$Login,
				A3($author$project$Page$Login$view, i18n, model.kY, loginModel));
		case 3:
			var searchModel = _v0.a;
			return A2(
				$author$project$Main$addHeaderToBody,
				model,
				A2(
					$author$project$Main$mapDocument,
					$author$project$Msg$Search,
					A3($author$project$Page$Search$view, i18n, model.kY, searchModel)));
		case 4:
			var paymentModel = _v0.a;
			return A2(
				$author$project$Main$addHeaderToBody,
				model,
				A2(
					$author$project$Main$mapDocument,
					$author$project$Msg$Payment,
					A2($author$project$Page$Payment$view, i18n, paymentModel)));
		case 5:
			var policyModel = _v0.a;
			return A2(
				$author$project$Main$addHeaderToBody,
				model,
				A2(
					$author$project$Main$mapDocument,
					$author$project$Msg$PolicyDetails,
					A3($author$project$Page$PolicyDetails$view, i18n, user, policyModel)));
		default:
			var offerDetailsModel = _v0.a;
			return A2(
				$author$project$Main$addHeaderToBody,
				model,
				A2(
					$author$project$Main$mapDocument,
					$author$project$Msg$OfferDetails,
					A3($author$project$Page$OfferDetails$view, i18n, env, offerDetailsModel)));
	}
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{lp: $author$project$Main$init, mQ: $author$project$Msg$OnUrlChange, mR: $author$project$Msg$OnUrlRequest, nw: $author$project$Main$subscriptions, nI: $author$project$Main$handle, nK: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (logoutPath) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (loginPath) {
					return A2(
						$elm$json$Json$Decode$andThen,
						function (localTimeZone) {
							return A2(
								$elm$json$Json$Decode$andThen,
								function (languages) {
									return A2(
										$elm$json$Json$Decode$andThen,
										function (funnelUrl) {
											return A2(
												$elm$json$Json$Decode$andThen,
												function (externalApiUrl) {
													return A2(
														$elm$json$Json$Decode$andThen,
														function (apiUrl) {
															return $elm$json$Json$Decode$succeed(
																{cb: apiUrl, cr: externalApiUrl, cu: funnelUrl, gb: languages, cF: localTimeZone, cG: loginPath, cH: logoutPath});
														},
														A2($elm$json$Json$Decode$field, 'apiUrl', $elm$json$Json$Decode$string));
												},
												A2($elm$json$Json$Decode$field, 'externalApiUrl', $elm$json$Json$Decode$string));
										},
										A2($elm$json$Json$Decode$field, 'funnelUrl', $elm$json$Json$Decode$string));
								},
								A2(
									$elm$json$Json$Decode$field,
									'languages',
									$elm$json$Json$Decode$list($elm$json$Json$Decode$string)));
						},
						A2($elm$json$Json$Decode$field, 'localTimeZone', $elm$json$Json$Decode$string));
				},
				A2($elm$json$Json$Decode$field, 'loginPath', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'logoutPath', $elm$json$Json$Decode$string)))(0)}});}(this));